export const ToolbarActionsConstants = {
  FAVORITE_FILTER: 'favoriteFiltersButton',
  FILTER: 'filtersButton',
  OPEN_FILTER: 'openFiltersButton',
  DOWNLOAD_PDF: 'downloadPdfButton',
  NEW_INTERVENTION_REQUEST: 'newInterventionRequestButton',
  INTERVENTIONS_HOME: 'interventionsHome',
  EMPTY_CART: 'emptyCartButton',
  EDIT_INSTRUMENTS: 'openEditInstrumentsDialogButton',
  CALENDAR_FILTER: 'filterByCalendar',
  SET_CSVDATA: 'setCSVData',
  SET_INVENTORY_CSVDATA: 'setInventoryCSVData',
  INTERVENTION_SUBMIT_INFO: 'interventionSubmitInfo',
  OPEN_HELP_DIALOG: 'openHelpDialog',
  START_TUTORIAL: 'startTutorial',
  MULTIPLE_INTERVENTION_INSTRUMENTS: 'multipleInterventionInstruments'
};
