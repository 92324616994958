const ENVIRONMENTS = {
  LOCAL: 'https://localhost:8443/api',
  DEV: 'https://192.93.167.54/api',
  PREPROD: 'https://adm-test.digit-ts.io/api',
  PROD: 'https://adm.bythales.com/api'
};

let serverUrl;
let imagesUrl = 'https://adm-test.digit-ts.io/images';
// Prod
if (process.env.REACT_APP_ENV === 'prod') {
  serverUrl = ENVIRONMENTS.PROD;
  imagesUrl = 'https://adm.bythales.com/images';
}
// Test
else if (process.env.REACT_APP_ENV === 'preprod') {
  serverUrl = ENVIRONMENTS.PREPROD;
}
// Dev
else if (process.env.REACT_APP_ENV === 'dev') {
  serverUrl = ENVIRONMENTS.DEV;
}
// Default
else {
  serverUrl = ENVIRONMENTS.LOCAL;
}

const baseRoutes = {
  instruments: serverUrl + '/instruments',
  inventory: serverUrl + '/inventory',
  interventionRequest: serverUrl + '/intervention-requests',
  users: serverUrl + '/users',
  feedback: serverUrl + '/feedbacks',
  scans: serverUrl + '/scans',
  interventions: serverUrl + '/interventions',
  preferences: serverUrl + '/preferences/interventions',
  sso: serverUrl + '/sso',
  messageScan: serverUrl + '/message-scan',
  date: serverUrl + '/date',
  bugs: serverUrl + '/bugs',
  banner: serverUrl + '/banner'
};

const routes = {
  imagesUrl: imagesUrl,

  // the Server URL
  serverUrl: serverUrl,

  // Users Routes
  login: baseRoutes.users + '/login',
  userInfo: baseRoutes.users + '/my-info',
  getInfosFromName: baseRoutes.users + '/infos/fromName',
  refreshAccessToken: baseRoutes.users + '/access-token/refresh/',

  //Inventory Routes
  getLastInventory: baseRoutes.inventory + '/my/inventory',
  lastInventory: baseRoutes.inventory + '/last-inventory',
  isLastInventory: baseRoutes.inventory + '/exist',

  // Version Routes
  checkVersion: serverUrl + '/version/check?version=1.10',
  checkVersionSync: version => `${serverUrl}/version/check?version=${version}`,

  // Instrument
  getInstrumentByBarcode: baseRoutes.instruments + '/barcode',
  getInstrumentsByBarcode: baseRoutes.instruments + '/any',
  favoriteInstruments: baseRoutes.instruments + '/favorite',
  myInstruments: baseRoutes.instruments + '/my',
  exportExcel: baseRoutes.instruments + '/export',
  listUpdateInstruments: timestamp => `${baseRoutes.instruments}/list-update?timestamp=${timestamp}`,
  listFromMarquagesInstruments: baseRoutes.instruments + '/list-from-marquages',
  favoriteInstrumentsSync: baseRoutes.instruments + '/favorite/sync',
  updateInstrumentByMarquage: baseRoutes.instruments + '/barcode',
  getBorrowedInstruments: baseRoutes.instruments + '/user',
  getStatuses: baseRoutes.instruments + '/statuses',
  getStates: baseRoutes.instruments + '/states',
  getUrlDigitalTestBench: baseRoutes.instruments + '/info/url-dtb',
  getLastVersion: instrumentMarquage => `${baseRoutes.instruments}/last-version?marquages=${instrumentMarquage}`,

  // Scan
  createScan: baseRoutes.scans,
  createManyScans: baseRoutes.scans + '/many',
  getAllScans: baseRoutes.scans + '/fetch',

  // Update
  updateSyncPush: serverUrl + '/update/sync/push',

  // Connection
  syncConnection: serverUrl + '/connection/sync',
  lastConnection: serverUrl + '/connection/last',

  // Notification
  getNotifications: serverUrl + '/instrument-notification/my',
  deleteNotifications: serverUrl + '/instrument-notification/many',

  // Intervention
  getAllInterventions: baseRoutes.interventionRequest,
  getAllRequestIntervention: baseRoutes.interventionRequest + '/all-request',
  getAllInterventionTypes: baseRoutes.interventions + '/types',

  // Intervention Request
  getExternalProviders: baseRoutes.interventionRequest + '/configuration/external-recipients',
  getInternalProviders: baseRoutes.interventionRequest + '/configuration/internal-recipients',
  getEligibleInstruments: baseRoutes.interventionRequest + '/elligibles-instruments',
  getServiceLocations: baseRoutes.interventionRequest + '/locations',
  getProviderTypes: baseRoutes.interventionRequest + '/provider-types',
  getInterventionTypes: baseRoutes.interventionRequest + '/types',
  getProviderDetails: baseRoutes.interventionRequest + '/external-provider-details',
  getCarrierModes: baseRoutes.interventionRequest + '/carrier-modes',
  getCarriers: baseRoutes.interventionRequest + '/carriers',
  getShippers: baseRoutes.interventionRequest + '/senders',
  createInterventionRequest: baseRoutes.interventionRequest,
  deleteInterventionRequest: id => `${baseRoutes.interventionRequest}/${id}`,
  updateInterventionRequest: id => `${baseRoutes.interventionRequest}/${id}/expedition-date`,
  getMultipleEnabled: baseRoutes.interventionRequest + '/multiple-enabled',

  // Interventions
  createIntervention: baseRoutes.interventions,
  createGenericIntervention: baseRoutes.interventions + '/generic',
  getInterventions: baseRoutes.interventions + '/all-interventions',
  // Feedback
  sendFeedback: baseRoutes.feedback,

  // Preferences
  userPreferences: baseRoutes.preferences,

  // SSO
  checkTokenSSO: baseRoutes.sso + '/check-token',
  ssoLogin: baseRoutes.sso + '/login',

  // Message-scan
  getMessagesScans: baseRoutes.messageScan,
  getmessageScanById: id => `${baseRoutes.messageScan}/${id}`,

  // Dates
  getCurrentDateFromServer: baseRoutes.date,

  // Bugs
  postNewBug: baseRoutes.bugs,

  // Banner
  getBanner: baseRoutes.banner,
};

export default routes;
