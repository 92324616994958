import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Typography, } from '@mui/material';

import { styles } from './DPSCard.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import DPSList from './DPSList/DPSList';
import { theme } from '../../../../../Utils/Theme';

class DPSCard extends Component {

  state = {
    loading: false
  };


  render() {
    return (
      <>
        <Card id="DPSCard" sx={{...styles(theme).dpsCard}}>
          <CardHeader
            sx={{...styles(theme).titleContainer}}
            title={
              <Typography variant='h6' sx={{...styles(theme).cardTitle}}>
                {strings.intervention.DPSCard.title}
              </Typography>
            }
            action={this.state.loading && <CircularProgress size={20}/>}
          />
          <CardContent sx={{...styles(theme).dpsCardContent}}>
            <DPSList
              updateInstrumentsWithInterventions={this.props.updateInstrumentsWithInterventions}
              instruments={this.props.instruments}
              intervention={this.props.intervention}
            />
          </CardContent>
        </Card>
      </>
    );
  }
}

DPSCard.propTypes = {
  instruments: PropTypes.array.isRequired,
  updateInstrumentsWithInterventions: PropTypes.func,
  intervention: PropTypes.object.isRequired
};

export default DPSCard;
