import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import Scrollbar from 'react-scrollbars-custom';

import SimpleDialog from '../../SimpleDialog/SimpleDialog';
import { styles } from '../StringDialog.style';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import InputAdornmentWithCamera from "../../../Utils/InputAdornmentCamera/InputAdornmentWithCamera";
import { isNotComputer } from "../../../Utils/ResponsiveSelect/ResponsiveSelect";
import { theme } from '../../../../Utils/Theme';

class FilterableListDialog extends Component {

  state = {
    currentFilter: '',
    selectedValues: [],
    inputHasError: false
  };

  componentDidMount() {
    if (this.props.selectedValues) {
      this.setState({ selectedValues: this.props.selectedValues });
    }
  }

  componentDidUpdate(prevProps) {
    // Update default value if needed
    if (this.props.open && prevProps.open !== this.props.open && this.props.defaultValue) {
      this.setState({ currentFilter: this.props.defaultValue });
    }
  }

  handleFilterChange = event => {
    this.setState({ currentFilter: event.target.value });
  };

  onElementChecked = (item, checked) => {

    this.setState(prevState => {
      if (checked) {
        return { selectedValues: prevState.selectedValues.concat(item) };
      } else {
        return { selectedValues: prevState.selectedValues.filter(val => val !== item) };
      }
    });
  };

  onListItemClick = selectedItem => {
    if (this.props.singleSelection) {
      this.setState({ currentFilter: selectedItem });
    }
  };

  generateList = () => {
    let itemCount = 0;

    const listContent = this.props.list.map(item => {
      if ((!this.props.maxItemsToShow || itemCount < this.props.maxItemsToShow)
        && (!item || item.toUpperCase().includes(this.state.currentFilter.toUpperCase()))) {

        itemCount++;
        return (
          <ListItem
            button={this.props.singleSelection}
            key={item ? item : 'empty'}
            onClick={() => this.onListItemClick(item)}
          >
            {this.props.singleSelection ? item :
              <>
                <ListItemText
                  style={{ fontStyle: !item && 'italic' }}
                  primary={item ? item : strings.general.emptyField}
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    onChange={event => this.onElementChecked(item, event.target.checked)}
                    checked={this.state.selectedValues.indexOf(item) >= 0}/>
                </ListItemSecondaryAction>
              </>}
          </ListItem>
        );
      }

      return null;
    });

    return itemCount > 0 ? <List>{listContent}</List>
      : <Typography variant="body2">{this.props.emptyListMessage}</Typography>;
  };

  onValidate = () => {

    if (!this.props.regexToMatch || new RegExp(this.props.regexToMatch).test(this.state.currentFilter)) {
      this.setState({ currentFilter: '' });
      this.props.onAccept(this.props.singleSelection ? this.state.currentFilter : this.state.selectedValues);
    } else {
      this.setState({ inputHasError: true });
    }
  };

  render() {
    return (
      <SimpleDialog
        id='listDialog'
        styledDialog={styles(theme).smallDialog}
        open={this.props.open}
        onRefuse={this.props.onRefuse}
        title={this.props.title}
        onAccept={this.onValidate}
        refuseLabel={this.props.refuseLabel}
        acceptLabel={this.props.acceptLabel}
      >
        {this.state.inputHasError && <Box component="b" sx={{...styles(theme).error}}>{this.props.inputErrorMessage}</Box>}
        <TextField
          id='textfieldListDialog'
          variant="standard"
          autoFocus
          error={this.state.inputHasError}
          label={this.props.text}
          value={this.state.currentFilter}
          onChange={this.handleFilterChange}
          sx={{ "&.MuiTextField-root": {...styles(theme).textField}}}
          InputProps={{
            sx: {
              input: {...(isNotComputer && styles(theme).tabletFontSize)}
            }
          }}
          InputLabelProps={{
            sx: {
              root: {...(isNotComputer && styles(theme).tabletFontSize)}
            }
          }}
        />

        <Scrollbar
          id='list'
          style={{...styles(theme).scrollArea}}
          noScrollX>
          {
            this.generateList()
          }
        </Scrollbar>
      </SimpleDialog>);

  }
}

FilterableListDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onRefuse: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  refuseLabel: PropTypes.string.isRequired,
  acceptLabel: PropTypes.string,
  list: PropTypes.array,
  selectedValues: PropTypes.array,
  singleSelection: PropTypes.bool,
  maxItemsToShow: PropTypes.number,
  emptyListMessage: PropTypes.string,
  regexToMatch: PropTypes.string,
  inputErrorMessage: PropTypes.string,
  defaultValue: PropTypes.string
};

export default FilterableListDialog;
