import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import ScanHelper from '../../../Helpers/ScanHelper';
import ScanChoiceDialog from '../../Dialogs/ScanChoiceDialog/ScanChoiceDialog';
import { ScanAnalysis } from '../../../Utils/Constants/ScanAnalysis';
import { SnackbarMessages } from '../../../Utils/Constants/SnackbarMessages';
import { ToastService } from '../Toast/Toast';
import { TOAST_TYPES } from '../../../Utils/Constants/ToastTypes';
import ScanDialog from './ScanDialog/ScanDialog';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import ScanAlertDialog from '../../Dialogs/ScanAlertDialog/ScanAlertDialog';
import MessageScanProvider from '../../../Providers/Database/MessageScanProvider';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';

// maps interventionReducer's state to Component's props
const mapStateToProps = state => {
  return {
    loggedUser: state.generalReducer.loggedUser
  };
};

export const scannable = (ScannableComponent, serverOnly) => {
  class withScan extends Component {

    state = {
      displayChoicePopup: false,
      instruments: [],
      lastScannedInstrument: null,
      displayInformativePopup: false,
      analysis: '',
      selectedInstrument: {},
      displayMessageScanPopup: false,
      messageScan: {
        title: '',
        message: ''
      }
    }

    // Close Informative Pop-up
    onClose = () => {
      this.setState({ displayInformativePopup: false });
    };

    handleMessageScan = async instrument => {
      const messageScan = await ScanHelper.instrumentMessageScanMatched(instrument);
      // Add information in messageScanHistory table on indexedDB
      if (!messageScan) {
        return Promise.resolve(instrument);
      }
      await MessageScanProvider.insertMessageScanHistory({
        messageScan: messageScan,
        marquage: instrument.marquage,
        userLogin: this.props.loggedUser.userLogin,
        createdAt: dayjs().format(strings.general.dateFormat)
      });
      // Display alert pop-up and save messageScan state
      this.setState({ displayMessageScanPopup: true, messageScan });
      return Promise.resolve(instrument);
    }

    handleAnalysis = async (scan, instrument, time) => {
      switch (scan.analysis) {
        case ScanAnalysis.OK:
          ToastService.enqueueToast(TOAST_TYPES.SUCCESS, instrument, null, time);
          return Promise.resolve(instrument);
        case ScanAnalysis.LOST:
          ToastService.enqueueToast(TOAST_TYPES.WARNING, instrument, null, time);
          return Promise.resolve(instrument);
        case ScanAnalysis.NOT_FOUND:
          ToastService.enqueueToast(
            TOAST_TYPES.ERROR,
            null,
            strings.toastMessage.notFound(scan.scannedBarcode),
            time);
          break;
        case ScanAnalysis.OUT_OF_BOUND:
          this.setState({ displayInformativePopup: true, analysis: scan.analysis, selectedInstrument: instrument });
          return Promise.resolve({ ...instrument, isOoB: true });
        case ScanAnalysis.ARCHIVED:
        case ScanAnalysis.NONE:
          if (instrument) {
            ToastService.enqueueToast(TOAST_TYPES.SUCCESS, instrument, null, time);
            return Promise.resolve(instrument);
          } else {
            this.setState({ displayInformativePopup: true, analysis: scan.analysis, selectedInstrument: instrument });
          }
          break;
        default:
          ToastService.enqueueToast(TOAST_TYPES.WARNING, instrument, null, time);
          break;
      }
      return Promise.resolve();
    }
    /**
     * Create a scan (on server and locally) from:
     * @param {string} barcode: the scanned barcode
     * @param {string} scanType: the method used to scan
     * @param {string} instrument: the corresponding instrument (can be null)
     * @param {string} page: the page where the scan occured
     */
    createScan = (barcode, scanType, instrument, page, time) => {
      return ScanHelper.createScan(barcode, scanType, instrument, page, serverOnly)
        .then(scan => this.handleAnalysis(scan, instrument, time))
        .then(analyzedInstrument => this.handleMessageScan(analyzedInstrument))
        .catch(() => {
          enqueueSnackbar(SnackbarMessages.scanCreationError.msg, SnackbarMessages.scanCreationError.type);
        });
    }

    /**
     * Launch process of scan
     * data must contains:
     * @param {string} barcode
     * @param {string} scanType
     * @param {string} page
     */
    processScan = async data => {
      this.data = data;
      if (!data.barcode || (data.barcode && data.barcode.length === 0) ||
        (data.barcode && data.barcode.length > 0 && data.barcode.trim().length === 0)) {
        return Promise.resolve();
      }
      const instruments = await ScanHelper.verifyInstrument(data.barcode, serverOnly);
      if (instruments && instruments.length > 1) {
        // Give user choice
        this.setState({ instruments, displayChoicePopup: true });
        return Promise.resolve();
      }
      else {
        // create scan
        const instrument = instruments ? instruments[0] : instruments;
        this.createScan(data.barcode, data.scanType, instrument, data.page, ToastService.getToastTime(data.page)).then(result => {
          if (result && result.isOoB) {
            this.setState({ lastScannedOoBInstrument: result });
          }
          else {
            this.setState({ lastScannedInstrument: result });
          }
          return Promise.resolve();
        });
        return Promise.resolve();
      }
    }

    /**
     * Callback method after user selected an instrument from dialog (in case of ambiguous scan)
     */
    handleSelection = instrument => {
      this.createScan(this.data.barcode, this.data.scanType, instrument, this.data.page, ToastService.getToastTime(this.data.page)).then((result => {
        this.setState({ displayChoicePopup: false, lastScannedInstrument: result });
      }));
    }

    render() {
      return (
        <>
          <ScanChoiceDialog
            open={this.state.displayChoicePopup}
            instruments={this.state.instruments}
            onSelect={this.handleSelection} />
          <ScannableComponent {...this.props}
            processScan={data => this.processScan(data)}
            lastScannedInstrument={this.state.lastScannedInstrument}
            lastScannedOoBInstrument={this.state.lastScannedOoBInstrument} />
          <ScanDialog
            displayInformativePopup={this.state.displayInformativePopup}
            type={this.state.analysis}
            selectedInstrument={this.state.selectedInstrument}
            onClose={this.onClose}
          />
          <ScanAlertDialog
            open={this.state.displayMessageScanPopup}
            messageScan={this.state.messageScan}
            onRefuse={() => this.setState({ displayMessageScanPopup: false })}
          />
        </>);
    }
  }

  withScan.propTypes = {
    loggedUser: PropTypes.object.isRequired,
  };

  return compose(
    connect(mapStateToProps)
  )(withScan);
};

export default scannable;
