// REACT libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Generate the image's URL of the instrument
import InstrumentService from '../../../Providers/WebService/InstrumentService';
// Assets libraries
import DefaultInstrumentImage from '../../../Assets/default_instrument.png';
// Material-UI libraries
import { styles } from './InstrumentImage.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { Box, Modal } from '@mui/material';
import { theme } from '../../../Utils/Theme';

const imageSize = {
  SMALL: '1',
  MEDIUM: '2',
  LARGE: '3'
};

class InstrumentImage extends Component {
  state = {
    imgUrl: DefaultInstrumentImage,
    showBig: false
  };

  componentDidMount() {
    this.setState({ imgUrl: InstrumentService.getInstrumentImage(this.props.instrument, imageSize.LARGE) });
  }

  addDefaultSrc = () => {
    this.setState({
      imgUrl: DefaultInstrumentImage
    });
  }

  onImgClick = (event, show) => {
    event.stopPropagation();
    this.setState({ showBig: show });
  }

  getImg = showOnClick => {
    return (<Box component="img"
      id='instrumentImg'
      onClick={e => this.onImgClick(e, showOnClick)}
      sx={{...styles(theme).image}}
      src={this.state.imgUrl}
      onError={this.addDefaultSrc}
      alt={strings.instrumentCard.chargementImage}
    />);
  }

  render() {
    return (
      <>
        <Modal
          open={this.state.showBig}
          onClose={e => this.onImgClick(e, false)}>
          <Box component="div" sx={{...styles(theme).modalContent}}>
            {this.getImg(false)}
          </Box>
        </Modal>
        {this.getImg(true)}
      </>
    );
  }
}

InstrumentImage.propTypes = {
  instrument: PropTypes.object,
};

export default InstrumentImage;
