export let styles = theme => ({
  emptyStyle: {
    display: 'inline',
    color: theme.palette.greyTheme.lighter,
    fontStyle: 'italic'
  },
  otherInformation: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2) - 2
    }
  }
});
