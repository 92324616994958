// REACT libraries
import React from 'react';
import PropTypes from 'prop-types';

// Material-UI libraries
import { InputAdornment, IconButton } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

const InputAdornmentWithCamera = props => {
  let idValue = null;
  if(props.name) {
    idValue='buttonCamera' + props.name;
  } else {
    idValue='buttonCamera';
  }
  return (
    <InputAdornment position="end">
      <IconButton
        id={idValue}
        color="primary"
        key={props.name}
        onClick={props.camera}
        size="large">
        <PhotoCamera />
      </IconButton>
    </InputAdornment>
  );
};

InputAdornmentWithCamera.propTypes= {
  name: PropTypes.string,
  camera: PropTypes.func,
};
export default InputAdornmentWithCamera;
