import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';
import { BarcodeType } from '../../Utils/Constants/BarcodeType';
import { logger } from '../../Utils/Logger';

class InstrumentService {

  /** Update instrument in remote database
   * instrumentToUpdateData format:
   *  - instrumentsToUpdateMarquage : array of string marquages
   *  - instrumentsToUpdateFields :
   *    - utilisateur: string
   *    - localisation: string
   */
  static updateInstrument(instrumentToUpdateData) {
    return HttpClient.put(routes.updateInstrumentByMarquage, instrumentToUpdateData)
      .catch(logger);
  }

  /**
   * Get instruments marquage from last Inventory
   * @returns {Promise<T | unknown>}
   */
  static async getLastInventory() {
    return HttpClient.get(routes.getLastInventory)
      .then(res => Promise.resolve(res.data))
      .catch(() => Promise.resolve(null));
  }

  static async isLastInventory() {
    return HttpClient.get(routes.isLastInventory)
      .then(res => Promise.resolve(res.data))
      .catch(() => Promise.resolve(null));
  }

  static async deleteAndSaveNewLastInventaire(marquages) {
    return HttpClient.post(routes.lastInventory, marquages)
      .then(res => Promise.resolve(res.data))
      .catch(() => Promise.resolve(null));
  }

  /** Get instrument in remote database */
  static async getInstrumentByBarcode(barcode, barcodeType = BarcodeType.ALL) {
    const query = { attribute: 'type', value: barcodeType };
    const url = HttpClient.buildUrl(
      routes.getInstrumentByBarcode,
      [barcode],
      [query]);
    return HttpClient.get(url)
      .then(res => Promise.resolve(res.data))
      .catch(() => Promise.resolve(null));
  }

  /** Get instruments in remote database */
  static async getInstrumentsByBarcode(barcode) {
    const url = HttpClient.buildUrl(
      routes.getInstrumentsByBarcode,
      [barcode],
      []);
    return HttpClient.get(url)
      .then(res => Promise.resolve(res.data))
      .catch(() => Promise.resolve(null));
  }

  static async getBorrowedInstrument(fullName){
    const url = HttpClient.buildUrl(routes.getBorrowedInstruments, [fullName], []);
    return HttpClient.get(url)
      .then(res => {
        return Promise.resolve(res.data);
      })
      .catch(() => Promise.resolve(null));
  }

  static getInstrumentImage(instrument, scaleFactor) {
    const fileName = (instrument.constructeur + '__' + instrument.referenceConstructeur)
      .toLowerCase().replace('/', '--');
    return routes.imagesUrl + `/${scaleFactor}/${fileName}.jpg`;
  }

  /** Get states or statuses enabled (from ADM Front Administration) */
  static async getStatesOrStatus(route) {
    return HttpClient.get(route).then(response => {
      // Only keep items that are isEnabled to true
      const filteredData = response.data.filter(item => item.isEnabled);
      return Promise.resolve(filteredData);
    }).catch(error => Promise.reject(error));
  }

}

export default InstrumentService;
