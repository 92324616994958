import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  mainPage_root: {
    display: 'flex',
    padding: "0px",
    overflow: 'hidden',
    backgroundColor: `${theme.palette.mainPageBackground}!important`,
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }

  },
  mainPage_toolbar: {
    height: '6vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(),
      borderBottom: `1px solid ${theme.palette.MainMenu.topMenu}`,
    },
  },
  mainPage_toolbar_with_banner: {
    height: '6vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '100px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(),
      borderBottom: `1px solid ${theme.palette.MainMenu.topMenu}`,
    },
  },
  mainPage_content: {
    height: globalConstants.style.FULL_PERCENTAGE,
    flexGrow: 1,
    [theme.breakpoints.only('xs')]: {
      padding: "0px"
    },
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  mobileScanPage: {
    padding: '0 !important',
    overflowY: 'hidden!important'
  },
  scanDrawer: {
    borderTopLeftRadius: "12px"
  },
  scanDrawerContent: {
    width: '33vw',
    height: '100%'
  },
  maintenanceBanner: {
    width: '100%',
    backgroundColor: '#dd464e',
    padding: "8px",
    marginBottom: "8px",
    borderRadius: "8px",
    textAlign: 'center',
    color: '#FFF'
  },
  maintenanceBannerMobile: {
    width: '95%',
    backgroundColor: '#dd464e',
    padding: "8px",
    margin: '12px 8px 8px 8px',
    borderRadius: "8px",
    textAlign: 'center',
    color: '#FFF'
  }
});

