import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { updateFormServiceShipping } from '../../../../../store/actions/exportActions';
import InterventionRequestService from '../../../../../Providers/WebService/InterventionRequestService';
import { styles } from '../ServiceShippingStep.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import { InterventionRequestConstants as constants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import ResponsiveSelect from '../../../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    activeStep: state.interventionReducer.activeStep,
    onMobile: state.generalReducer.onMobile
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    handleChange: (name, value) => dispatch(updateFormServiceShipping(name, value))
  };
};

const defaultCarrierMode = 'personally';

class CarrierCard extends Component {

  state = {
    carrierModes: [],
    carriers: [],
    needCarrier: false
  };

  /** Load carrier modes */
  async componentDidMount() {
    const carrierModes = await InterventionRequestService.getCarrierModes();
    const carriers = await InterventionRequestService.getCarriers();
    const needCarrier = this.props.serviceShippingPage.carrierMode.id === constants.CARRIER_MODE.OTHER;

    this.setState({ carrierModes, carriers, needCarrier });
    if (this.props.serviceShippingPage.carrierMode.id.length === 0) {
      this.props.handleChange({
        name: constants.SERVICE_SHIPPING_PAGE_NAMES.CARRIER_MODE,
        value: carrierModes.filter(c => c.id === defaultCarrierMode)[0]
      });
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    return (prevProps.serviceShippingPage.carrierMode !== this.props.serviceShippingPage.carrierMode)
      || (prevProps.serviceShippingPage.carrier !== this.props.serviceShippingPage.carrier)
      || (prevState !== this.state)
      || (prevProps.activeStep.multipleInterventionIndex !== this.props.activeStep.multipleInterventionIndex);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeStep.multipleInterventionIndex !== this.props.activeStep.multipleInterventionIndex
      && this.props.serviceShippingPage.carrierMode.id.length === 0) {
      this.props.handleChange({
        name: constants.SERVICE_SHIPPING_PAGE_NAMES.CARRIER_MODE,
        value: this.state.carrierModes.filter(c => c.id === defaultCarrierMode)[0]
      });
    }
  }

  /** Load carriers and display carrier select if carrierMode is 'other' */
  handleCarrierModeChange = e => {
    const selectedCarrierMode = this.state.carrierModes.filter(c => c.id === e.target.value)[0];
    this.props.handleChange({ name: e.target.name, value: selectedCarrierMode });
    const needCarrier = e.target.value === constants.CARRIER_MODE.OTHER;
    this.setState({ needCarrier });
  };

  render() {
    const { serviceShippingPage } = this.props;

    return (
      <Paper elevation={2} id="carrier" sx={{...styles(theme).container}}>
        <Typography variant="h6" sx={{...styles(theme).area_title}} gutterBottom>
          {strings.interventionRequest.carrierTitle}
        </Typography>
        <Grid container spacing={this.props.onMobile ? 0 : 0.5}>
          <Grid item xs={12} sx={{...styles(theme).input_grid}}>
            <FormControl fullWidth required sx={{...styles(theme).formControl}}>
              <InputLabel htmlFor={constants.SERVICE_SHIPPING_PAGE_NAMES.CARRIER_MODE} sx={{...styles(theme).input_label, ...styles(theme).select_label}}>
                {strings.interventionRequest.carrierModeLabel}
              </InputLabel>
              <ResponsiveSelect
                id="carrierModeSelect"
                value={serviceShippingPage.carrierMode.id}
                onChange={e => this.handleCarrierModeChange(e)}
                name={constants.SERVICE_SHIPPING_PAGE_NAMES.CARRIER_MODE}
                data={this.state.carrierModes}
                native={this.props.onMobile}
                styledSelect={styles(theme).input_content}
              />
            </FormControl>
          </Grid>
          {this.state.needCarrier &&
            (<Grid item xs={12} sx={{...styles(theme).input_grid}}>
              <FormControl required fullWidth sx={{...styles(theme).formControl}}>
                <InputLabel htmlFor={constants.SERVICE_SHIPPING_PAGE_NAMES.CARRIER} sx={{...styles(theme).input_label, ...styles(theme).select_label}}>
                  {strings.interventionRequest.carrierLabel}
                </InputLabel>
                <ResponsiveSelect
                  id="carrierSelect"
                  value={serviceShippingPage.carrier}
                  onChange={e => this.props.handleChange({ name: e.target.name, value: e.target.value })}
                  name={constants.SERVICE_SHIPPING_PAGE_NAMES.CARRIER}
                  data={this.state.carriers}
                  native={this.props.onMobile}
                  styledSelect={styles(theme).input_content}
                />
              </FormControl>
            </Grid>)
          }
        </Grid>
      </Paper>
    );
  }
}

CarrierCard.propTypes = {

  //region props from redux
  activeStep: PropTypes.shape({
    index: PropTypes.number,
    isValid: PropTypes.bool,
    multipleInterventionIndex: PropTypes.number
  }),
  onMobile: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  serviceShippingPage: PropTypes.object.isRequired,
  //endregion

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(CarrierCard);
