import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
