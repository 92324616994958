import { globalConstants } from '../Utils/Constants/GlobalConstants';
import { isFilterEmpty } from '../Utils/FilterUtils';

class ExternalFilterHelper {
  /**
   * Save the external filter of instruments in the session storage
   * @param filter : Object - object containing Arrays of Strings (ex: { marquage: ['002'], banc: ['B1'] })
   */
  static saveExternalFilter(filter) {
    if(isFilterEmpty(filter)) {
      this.removeExternalFilter();
    } else {
      sessionStorage.setItem(globalConstants.externalFilterStorageKey, JSON.stringify(filter));
    }
  }

  /**
   * Retrieve the external filter (list of instruments' ids) from the session storage
   * @return string | null
   */
  static getExternalFilter() {
    return sessionStorage.getItem(globalConstants.externalFilterStorageKey);
  }

  /**
   * Remove the external filter key & list of instruments' ids from the session storage
   */
  static removeExternalFilter() {
    sessionStorage.removeItem(globalConstants.externalFilterStorageKey);
  }

  /**
   * Determine whether the external filter exists in the session storage
   * @return {boolean}
   */
  static isExternalFilterSet() {
    const extFilter = sessionStorage.getItem(globalConstants.externalFilterStorageKey);
    return extFilter !== null && extFilter !== 'undefined';
  }
}
export default ExternalFilterHelper;
