export let styles = theme => ({
  img: {
    margin: "8px",
    width: "56px",
    height: "56px",
    [theme.breakpoints.down('md')]: {
      width: "40px",
      height: "40px",
    }
  },
  instrument_item: {
    padding: "8px",
    backgroundColor: theme.palette.pureWhite
  },
  item_title: {
    fontSize: "14px",
    lineHeight: 1.71,
    color: theme.palette.MainMenu.pageTitles,
    letterSpacing: 'normal'
  },
  item_subtitle: {
    color: theme.palette.lightGrayTheme.secondary,
  },
  plainIcon: {
    alignItems: 'right',
    color: 'var(--icon-color)',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'unset'
    }
  },
  title_with_btn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '90%'
  },
  row_display: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%'
  },
  horizontal_space_between: {
    justifyContent: 'space-between'
  },
  column_display: {
    display: 'flex',
    flexDirection: 'column',
  },
  side_action_button: {
    padding: "0px",
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  orange_button: {
    color: theme.palette.orangeTheme.main,
    '&:hover': {
      backgroundColor: 'unset',
      color: theme.palette.orangeTheme.hover
    },
    '&:disabled': {
      color: theme.palette.orangeTheme.disabled
    }
  },
  blue_button: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.hover
    }
  },
  listItemText: {
    margin: "0px",
    padding: "0px 16px"
  },
  listItemSecondaryAction: {
    right: theme.spacing(0.5)
  }
});

