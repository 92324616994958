export let styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: "20px",
    textAlign: 'center',
    minHeight: '50%'
  }
});
