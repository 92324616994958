import React, { Component } from 'react';
import Instrument from '../Instrument/Instrument';
import { styles } from './InstrumentsList.style';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import Divider from '@mui/material/Divider';
import InstrumentCard from '../../../../InstrumentCard/InstrumentCard';
import InstrumentService from '../../../../../Providers/WebService/InstrumentService';
import { ToastService } from '../../../../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../../../../Utils/Constants/ToastTypes';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import { theme } from '../../../../../Utils/Theme';
import { merge } from 'lodash';

class InstrumentsList extends Component {

  state = {
    selectedInstrument: null,
    isOpen: false
  };

  onClick = async selectedInstrument => {
    if (selectedInstrument.marquage) {
      await InstrumentService.getInstrumentByBarcode(selectedInstrument.marquage)
        .then((instrument) => {
          this.setState({ selectedInstrument: instrument, isOpen: true });
        })
        .catch(() => {
          this.setState({ selectedInstrument, isOpen: true });
          ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.notUpdatedInstrument);
        });
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.notUpdatedInstrument);
    }
  };

  closeInstrumentCard = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { typeOfDisplay, } = this.props;
    const instruments = this.props.instruments.map(inst =>
      <div key={inst.id}>
        <Instrument
          key={inst.id}
          item={inst}
          typeOfDisplay={typeOfDisplay}
          addOrRemoveInstrument={this.props.addOrRemoveInstruments}
          onClick={this.onClick}
        />
        <Divider sx={{...styles(theme).classic_divider}}/>
      </div>
    );
    let customStyle = {};
    if (theme.breakpoints.down('md')) {
      customStyle = styles(theme).scrollAreaDownMd;
    }
    if (theme.breakpoints.only('xs')) {
      customStyle = merge({}, customStyle, styles(theme).scrollAreaOnlyXs);
    }
    return (
      <>
        <InstrumentCard
          id="instrumentCard"
          open={this.state.isOpen}
          instrument={this.state.selectedInstrument}
          onClose={this.closeInstrumentCard}/>
        {
          instruments.length !== 0 &&
          <Scrollbar
            style={{...merge({}, styles(theme).scrollArea, customStyle)}}
            noScrollX
          >
            <List sx={{ "&.MuiList-root": {...styles(theme).instrumentsList}}}>
              {instruments}
            </List>
          </Scrollbar>
        }
      </>
    );
  }
}

InstrumentsList.propTypes = {
  instruments: PropTypes.array.isRequired,
  typeOfDisplay: PropTypes.string,
  addOrRemoveInstruments: PropTypes.func
};

export default InstrumentsList;
