import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  item: {
    width: '22%',
    margin: "8px",
    border: `1px solid ${theme.palette.intervention.mobile_divider}`,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      margin: "0px",
    },
  },
  listItemText: {
    marginTop: "0px",
    marginBottom: "0px",
    padding: "0px 16px"
  }
});
