import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';

import { styles } from './WizardHeader.style';
import { theme } from '../../../Utils/Theme';

const WizardHeader = props => {

  return (
    <CardHeader
      sx={{ "&.MuiCardHeader-root": {...styles(theme).cardHeader}, "& .MuiTypography-root": {...styles(theme).headerTitle}, "& .MuiCardHeader-action": {...styles(theme).action} }}
      avatar={
        <IconButton sx={{ "&.MuiIconButton-root": {...styles(theme).plainIcon}}} size="large">
          {props.icon}
        </IconButton>
      }
      title={props.title}
      action={props.renderActions()}
    />
  );
};

WizardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  renderActions: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired
};

export default WizardHeader;
