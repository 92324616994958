import {
  SET_FILTER,
  RESET_FILTERS,
  UPDATE_TOOLBAR_INFO,
  TOGGLE_FAVORITE_FILTER,
  TOGGLE_FILTER_PAGE,
  TOGGLE_SAVED_FILTERS,
  HANDLE_CHANGE_DATEPICKER,
  SET_CSVDATA,
  INTERVENTION_SUBMIT_INFO
} from './actionTypes';

export function updateToolbarInfo(payload) {
  return { type: UPDATE_TOOLBAR_INFO, payload };
}

export function toggleFavorites(payload) {
  return { type: TOGGLE_FAVORITE_FILTER, payload };
}

export function toggleFilterPage(payload) {
  return { type: TOGGLE_FILTER_PAGE, payload };
}

export function toggleSavedFilter(payload) {
  return { type: TOGGLE_SAVED_FILTERS, payload };
}

export function setFilter(payload) {
  return { type: SET_FILTER, payload };
}

export function resetFilters(payload) {
  return { type: RESET_FILTERS, payload };
}

export function handleChangeDatePicker(payload) {
  return { type: HANDLE_CHANGE_DATEPICKER, payload };
}

export function setCSVData(payload) {
  return { type: SET_CSVDATA, payload };
}

export function toggleInterventionSubmitInfo(payload) {
  return { type: INTERVENTION_SUBMIT_INFO, payload };
}
