import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import DeleteSweep from '@mui/icons-material/DeleteSweep';

import { styles } from './ScanListHeader.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { theme } from '../../../Utils/Theme';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

class ScanListHeader extends Component {

  render() {
    return (
      <Box component="div" id='scanListHeaderContainer' sx={{...styles(theme).header}}>
        <Box component="div" sx={{...styles(theme).headerContent}}>
          <Typography variant="body2" sx={{...styles(theme).scanCount}}>{this.props.count}{strings.scanPage.scannedInstrumentsLabel}</Typography>
          <Box component="div" sx={{...styles(theme).headerActions}}>
            <IconButton
              id="deleteAllButton"
              onClick={this.props.onDeleteAll}
              sx={{...merge({}, styles(theme).iconButton, styles(theme).darkBlueColor)}}
              size="large">
              <DeleteSweep />
            </IconButton>
            <IconButton
              id="editAllButton"
              onClick={this.props.onEditAll}
              sx={{...merge({}, styles(theme).iconButton, styles(theme).darkBlueColor)}}
              size="large">
              <Edit />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  }
}

ScanListHeader.propTypes = {
  count: PropTypes.number.isRequired,
  onDeleteAll: PropTypes.func.isRequired,
  onEditAll: PropTypes.func.isRequired
};

export default ScanListHeader;
