import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  scanList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  scrollArea: {
    display: 'flex',
    height: '100%',
  }
});
