import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isNotComputer } from "../../ResponsiveSelect/ResponsiveSelect";
import { styles } from "../../../Authentication/AuthenticationPage.style";
import { theme } from '../../../../Utils/Theme';
import { Box, ListItemButton } from '@mui/material';

class ExpandableTreeElement extends Component {
  state = {
    open: false
  }

  // If item is selected: we open it (if expandable) and we let parent know that this is the selected item
  itemSelected = () => {
    if (this.props.expandable) {
      this.setState(state => ({ open: !state.open }));
    }
    this.props.click({ id: this.props.id, name: this.props.name });
  };

  render() {
    const element =
      <>
        <ListItemButton onClick={() => this.itemSelected()}>
          <ListItemIcon>
            {this.props.checked ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
          </ListItemIcon>
          <ListItemText inset primary={this.props.name} sx={{ "& .MuiListItemText-primary": {...(isNotComputer && styles(theme).tabletFontSize)} }}/>
          {this.props.expandable ? (this.state.open ? <ExpandLess /> : <ExpandMore />) : null}
        </ListItemButton>
        {
          this.props.expandable && (
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {this.props.children}
              </List>
            </Collapse>
          )
        }
      </>;

    return (
      <Box component="div" style={{ marginLeft: `${20 * this.props.level}px` }} sx={{...(isNotComputer && styles(theme).tabletFontSize)}}>
        {element}
      </Box>
    );
  }
}

ExpandableTreeElement.propTypes = {
  name: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  click: PropTypes.func,
  children: PropTypes.array,
  checked: PropTypes.bool,
  id: PropTypes.number.isRequired,
  expandable: PropTypes.bool
};

ExpandableTreeElement.defaultProps = {
  children: [],
  checked: false,
  expandable: false
};

export default ExpandableTreeElement;
