import React, { Component } from 'react';
import { Radio } from '@mui/material';
import PropTypes from 'prop-types';

class ToggleFilterField extends Component {

  state = {
    selectedValue: 0
  };

  setValue = value => {
    this.setState({ selectedValue: value });
    this.props.onValueChange(this.props.fieldName, value);
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ selectedValue: this.props.defaultValue });
    }
  }

  render() {

    return (
      <>
        {this.props.firstValue.text}
        <Radio
          checked={this.state.selectedValue === this.props.firstValue.value}
          onChange={event => this.setValue(event.target.value)}
          value={this.props.firstValue.value}
          color="primary"
        />
        <Radio
          checked={this.state.selectedValue === this.props.secondValue.value}
          onChange={event => this.setValue(event.target.value)}
          value={this.props.secondValue.value}
          color="primary"
        />
        {this.props.secondValue.text}
      </>
    );
  }
}

ToggleFilterField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
  firstValue: PropTypes.object,
  secondValue: PropTypes.object,
  defaultValue: PropTypes.string
};

export default ToggleFilterField;
