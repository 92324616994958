import dayjs from 'dayjs';
import { InstrumentConstants } from './InstrumentConstants';

/**
 * Types of filters
 */
export const FILTER_TYPES = {
  // free string
  STRING: 0,
  // list of possible values
  LIST: 1,
  // choice between two elements
  TOGGLE: 2,
  // range of 2 dates (from & to)
  DATE_RANGE: 3
};

export const FILTER_DATE_FROM = 'from';
export const FILTER_DATE_TO = 'to';

/**
 * All filters available
 * To add a new filter, add it in this object
 *
 * name: {
 *  type: // type of filter
 *  name: // name of the filter (used for the filter key and for the key in localized string)
 *  dbKey: // key in the local database
 *  values: // possible values for the filter
 *  default: // default value of the filter
 * }
 */
export const FILTER_FIELDS = {
  marquage: {
    type: FILTER_TYPES.STRING,
    name: 'marquage',
    dbKey: InstrumentConstants.marquageDB,
    filterOnEquality: true
  },
  sousFamille: {
    type: FILTER_TYPES.LIST,
    name: 'sousFamille',
    dbKey: InstrumentConstants.sousFamilleDB
  },
  marque: {
    type: FILTER_TYPES.LIST,
    name: 'marque',
    dbKey: InstrumentConstants.constructeurDB
  },
  modele: {
    type: FILTER_TYPES.LIST,
    name: 'modele',
    dbKey: InstrumentConstants.referenceConstructeurDB
  },
  lieuUtilisation: {
    type: FILTER_TYPES.LIST,
    name: 'lieuUtilisation',
    dbKey: InstrumentConstants.siteDB
  },
  status: {
    type: FILTER_TYPES.LIST,
    name: 'status',
    dbKey: InstrumentConstants.etatDB
  },
  banc: {
    type: FILTER_TYPES.STRING,
    name: 'banc',
    dbKey: InstrumentConstants.banc,
  },
  soumisAPlanning: {
    type: FILTER_TYPES.TOGGLE,
    name: 'soumisAPlanning',
    dbKey: InstrumentConstants.planningDB,
    values: [{ value: 'OUI', text: 'SOUMIS' }, { value: 'NON', text: 'NON SOUMIS' }],
    default: 'NON'
  },
  proprietaire: {
    type: FILTER_TYPES.TOGGLE,
    name: 'proprietaire',
    dbKey: InstrumentConstants.parcDB,
    values: [{ value: '611', text: '611' }, { value: '617', text: '617' }],
    default: '611'
  },
  dateProchaineOpe: {
    type: FILTER_TYPES.DATE_RANGE,
    name: 'dateProchaineOpe',
    dbKey: InstrumentConstants.periodiciteProchaineOperationDB,
    default: { from: dayjs(), to: dayjs() }
  },
  localisationComplementaire: {
    type: FILTER_TYPES.LIST,
    name: 'localisationComplementaire',
    dbKey: InstrumentConstants.localisationDB
  },
  affectationFinanciere: {
    type: FILTER_TYPES.LIST,
    name: 'affectationFinanciere',
    dbKey: InstrumentConstants.affectationDB
  },
  section: {
    type: FILTER_TYPES.LIST,
    name: 'section',
    dbKey: InstrumentConstants.sectionDB
  },
  sousSection: {
    type: FILTER_TYPES.LIST,
    name: 'sousSection',
    dbKey: InstrumentConstants.sousSectionDB
  },
  destinataire: {
    type: FILTER_TYPES.LIST,
    name: 'destinataire',
    dbKey: InstrumentConstants.destinataireDB
  },
  utilisateurFinal: {
    type: FILTER_TYPES.STRING,
    name: 'utilisateurFinal',
    dbKey: InstrumentConstants.utilisateurDB
  },
  typeProchaineIntervention: {
    type: FILTER_TYPES.LIST,
    name: 'typeProchaineIntervention',
    dbKey: InstrumentConstants.motifEnvoiDB
  },
  numeroDeSerie: {
    type: FILTER_TYPES.STRING,
    name: 'numeroDeSerie',
    dbKey: InstrumentConstants.numeroSerieDB
  },
  etatMetrologique: {
    type: FILTER_TYPES.LIST,
    name: 'etatMetrologique',
    dbKey: InstrumentConstants.etatMetrologiqueDB
  },
  designation: {
    type: FILTER_TYPES.LIST,
    name: 'designation',
    dbKey: InstrumentConstants.designationDB
  },
  organisationProprietaire: {
    type: FILTER_TYPES.LIST,
    name: 'organisationProprietaire',
    dbKey: InstrumentConstants.organisationProprietaireDB
  },
};

export const FILTER_NAME_VALIDATION_REGEX = '^[a-zA-ZÀ-ú0-9_ -]+$';
