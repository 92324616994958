import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  card: {
    margin: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      margin: "0px",
      marginTop: "1px",
      padding: theme.spacing(),
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: theme.spacing(),
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  right: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    textAlign: 'right',
    marginLeft: "8px",
  },
  favorite: {
    right: theme.spacing()
  },
  instrumentImage: {
    width: "70px",
    height: "70px",
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: "45px",
      height: "45px"
    }
  },
  icons: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },
  defaultColorText: {
    color: theme.palette.MainMenu.pageTitles,
  },
});

