import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputLabel,
  Typography
} from '@mui/material';

import { styles } from './ConclusionCard.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import ResponsiveSelect from '../../../../Utils/ResponsiveSelect/ResponsiveSelect';
import InterventionConstants from '../../../../../Utils/Constants/InterventionConstants';
import InstrumentService from '../../../../../Providers/WebService/InstrumentService';
import routes from '../../../../../Utils/Routes';
import { int } from '../../../../../Utils/MathUtils';
import { ValidatorRules } from '../../../../../Utils/Validator';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

class ConclusionCard extends Component {

  state = {
    statuses: [],
    states: [],
    loading: false
  };

  async componentDidMount() {
    this.setState({ loading: true });
    const statuses = await InstrumentService.getStatesOrStatus(routes.getStatuses);
    const states = await InstrumentService.getStatesOrStatus(routes.getStates);
    this.setState({ statuses, states, loading: false });
  }

  generateResponsiveSelect = (name, label, value, data, canSelected) => {
    return (
      <FormControl fullWidth required={canSelected}>
        <InputLabel htmlFor={name} sx={{...styles(theme).input_label, ...styles(theme).select_label}}>
          {label}
        </InputLabel>
        <ResponsiveSelect
          value={value}
          name={name}
          required={canSelected}
          submitted={this.props.submitted}
          types={[ValidatorRules.NOT_EMPTY]}
          onChange={e => this.props.updateInterventionStateField(e.target.name,
            { id: int(e.target.value), name: data.find(element => element.id === int(e.target.value)).name })}
          data={data}
          native={this.props.onMobile}
          noDefaultValue={this.props.onMobile}
          styledSelect={styles(theme).input_content}
          // when canSelected is false, set to true for disable the current field
          disabled={!canSelected}
          tooltip={canSelected}
        />
      </FormControl>
    );
  };

  render() {
    return (
      <Card id="conclusionCard" sx={{...(!this.props.onMobile && styles(theme).container)}}>
        <CardHeader
          sx={{...styles(theme).titleContainer}}
          title={
            <Typography variant='h6' sx={{...styles(theme).cardTitle}}>
              {strings.intervention.conclusionCard.title}
            </Typography>}
          action={this.state.loading && <CircularProgress size={20}/>}
        />
        <CardContent>
          {/* État */}
          {this.generateResponsiveSelect(
            InterventionConstants.INTERVENTIONCARD_NAMES.STATE,
            strings.intervention.conclusionCard.state,
            this.props.intervention.state.id,
            this.state.states,
            this.props.intervention.type.canSelectState
          )}
          {/* Status  */}
          {this.generateResponsiveSelect(
            InterventionConstants.INTERVENTIONCARD_NAMES.STATUS,
            strings.intervention.conclusionCard.status,
            this.props.intervention.status.id,
            this.state.statuses,
            this.props.intervention.type.canSelectStatus
          )}
        </CardContent>
      </Card>
    );
  }
}

ConclusionCard.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  updateInterventionStateField: PropTypes.func.isRequired,
  intervention: PropTypes.object.isRequired,
  submitted: PropTypes.bool
};

export default compose(
  connect(mapStateToProps, null)
)(ConclusionCard);
