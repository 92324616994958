import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.dialogTheme.header,
    paddingTop: "15px",
    paddingBottom: "20px",
    borderRadius: '5px 5px 0px 0px',
    minHeight: "120px",
    [theme.breakpoints.only('xs')]: {
      minHeight: 'unset',
      paddingBottom: "8px",
      paddingTop: "8px",
    }
  },
  interventionHeader: {
    backgroundColor: theme.palette.pureWhite,
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.orangeTheme.main,
      borderRadius: "0px"
    }
  },
  inventoryHeader: {
    backgroundColor: theme.palette.pureWhite,
    borderRadius: '8px 8px 0 0',
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.greenTheme.main,
      borderRadius: "0px"
    }
  },
  headerTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '90%',
    alignItems: 'center'
  },
  headerBottom: {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
    paddingTop: "10px"
  },
  barcodeInput: {
    width: '100%',
    backgroundColor: theme.palette.lightGrayTheme.main,
    borderRadius: "25px"
  },
  iconColor: {
    color: theme.palette.dialogTheme.header
  },
  inputPlaceholder: {
    '&::placeholder': {
      color: theme.palette.dialogTheme.header,
      fontStyle: 'italic',
      fontSize: '0.9em'
    }
  },
  selectedScanButton: {
    backgroundColor: theme.palette.lightGrayTheme.main,
    color: theme.palette.dialogTheme.header,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.up('xs')]: {
      marginRight: "5px"
    }
  },
  interventionSelectedScanButton: {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.secondary.main}!important`,
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.secondary.light,
      color: `${theme.palette.orangeTheme.main}!important`,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      [theme.breakpoints.only('xs')]: {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  inventorySelectedScanButton: {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.secondary.main}!important`,
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.secondary.light,
      color: `${theme.palette.greenTheme.main}!important`,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      [theme.breakpoints.only('xs')]: {
        backgroundColor: theme.palette.secondary.main
      }
    }
  },
  scanButton: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('xs')]: {
      marginRight: "5px"
    }
  },
  headerTitle: {
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: "0px",
    }
  },
  interventionHeaderTitle: {
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      color: theme.palette.secondary.main,
    },
    paddingLeft: "0px",
    fontSize: "18px",
    lineHeight: 'normal!important'
  },
  inventoryHeaderTitle: {
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      color: theme.palette.secondary.main,
    },
    paddingLeft: "0px",
    fontSize: "18px",
    lineHeight: 'normal!important'
  },
  inputLeftIcon: {
    paddingLeft: theme.spacing(2)
  },
  backButton: {
    color: theme.palette.secondary.main,
    marginRight: '-3px'
  },
  interventionMainColor: {
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      color: theme.palette.secondary.main,
    },
  },
  inventoryMainColor: {
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      color: theme.palette.secondary.main,
    },
  }
});

