import sharedStyles from './../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  dialog: {
    [theme.breakpoints.up('md')]: {
      width: '50vw',
      height: '80vh'
    },
    [theme.breakpoints.down('lg')]: {
      width: '75vw'
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw'
    }
  },
  header: {
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.spacing()} 0px ${theme.spacing()} 0px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing()} ${theme.spacing()} ${theme.spacing()} ${theme.spacing()}`,
    },
    backgroundColor: theme.palette.dialogTheme.header
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('lg')]: {
      paddingRight: "0px",
      paddingLeft: "0px",
      margin: "0px",
      fontSize: "14px",
      fontWeight: 'bold'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: "16px",
    }
  },
  filterCards: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  addFilterButton: {
    marginBottom: theme.spacing(2)
  },
  addFilterContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  dialogActionContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  dialogActionButton: {
    flex: 1
  }
});

