import {
  UPDATE_ACTIVE_STEP,
  UPDATE_FORM_SERVICE_SHIPPING,
  UPDATE_VALID_INSTRUMENTS,
  INIT_OPEN_CONFIRMATION_DIALOG,
  RESET_INTERVENTION_REQUEST,
  ENABLE_MULTIPLE_INTERVENTION,
  SET_MULTIPLE_INTERVENTIONS,
  OPEN_MULTIPLE_INTERVENTIONS_INSTRUMENTS
} from './actionTypes';

export function updateActiveStep(payload) {
  return { type: UPDATE_ACTIVE_STEP, payload };
}

export function updateFormServiceShipping(payload) {
  return { type: UPDATE_FORM_SERVICE_SHIPPING, payload };
}

export function updateValidInstruments(payload) {
  return { type: UPDATE_VALID_INSTRUMENTS, payload };
}

export function initOpenConfirmationDialog(payload) {
  return { type: INIT_OPEN_CONFIRMATION_DIALOG, payload };
}

export function resetInterventionRequest(payload) {
  return { type: RESET_INTERVENTION_REQUEST, payload };
}

export function enableMultipleIntervention(payload) {
  return { type: ENABLE_MULTIPLE_INTERVENTION, payload };
}

export function setMultipleInterventions(payload) {
  return { type: SET_MULTIPLE_INTERVENTIONS, payload };
}

export function openMultipleInterventionsInstruments(payload) {
  return { type: OPEN_MULTIPLE_INTERVENTIONS_INSTRUMENTS, payload };
}