import React from 'react';
import PropTypes from 'prop-types';
import SimpleDialog from '../../../Dialogs/SimpleDialog/SimpleDialog';
import StaticField from '../../../InstrumentCard/StaticField/StaticField';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import {
  Warning,
  NotInterested,
  AssignmentReturned
} from '@mui/icons-material';
import { ScanAnalysis } from '../../../../Utils/Constants/ScanAnalysis';
import sharedStyles from '../../../../Utils/SharedStyles';
import { theme } from '../../../../Utils/Theme';
import { Box } from '@mui/material';

const ScanDialog = props => {
  const styles = sharedStyles;

  let title, icon, content;

  switch (props.type) {
    case ScanAnalysis.ARCHIVED:
      title = strings.dialog.title.archived;
      icon = <AssignmentReturned />;
      content =
        <>
          <StaticField name={strings.instrumentCard.idContent.marquage}
            value={props.selectedInstrument.marquage} />
          <StaticField name={strings.instrumentCard.idContent.designation}
            value={props.selectedInstrument.designation} />
          <StaticField name={strings.instrumentCard.idContent.marque}
            value={props.selectedInstrument.constructeur} />
          <StaticField name={strings.instrumentCard.idContent.modele}
            value={props.selectedInstrument.referenceConstructeur} />
          <StaticField name={strings.instrumentCard.idContent.numeroSerie}
            value={props.selectedInstrument.numeroSerie} />
          <Box component="div" sx={{...styles(theme).centerText}}>
            <p>{strings.scanMessage.messageArchived(props.selectedInstrument.marquage)}</p>
          </Box>
        </>;
      break;
    case ScanAnalysis.OUT_OF_BOUND:
      title = strings.dialog.title.out_of_bound;
      icon = <Warning />;
      content =
        <>
          <StaticField name={strings.instrumentCard.idContent.marquage}
            value={props.selectedInstrument.marquage} />
          <StaticField name={strings.instrumentCard.idContent.designation}
            value={props.selectedInstrument.designation} />
          <StaticField name={strings.instrumentCard.idContent.marque}
            value={props.selectedInstrument.constructeur} />
          <StaticField name={strings.instrumentCard.idContent.modele}
            value={props.selectedInstrument.referenceConstructeur} />

          <StaticField name={strings.instrumentCard.adminContent.destinataire}
            value={props.selectedInstrument.destinataire} />
          <StaticField name={strings.instrumentCard.idContent.status}
            value={props.selectedInstrument.etat} />
          <StaticField name={strings.instrumentCard.adminContent.parc}
            value={props.selectedInstrument.parc} />
          <StaticField name={strings.instrumentCard.adminContent.site}
            value={props.selectedInstrument.site} />
        </>;
      break;
    case ScanAnalysis.NONE:
      title = strings.dialog.title.not_analyzed;
      icon = <NotInterested />;
      content =
        <Box component="div" sx={{...styles(theme).centerText}}>
          {strings.scanMessage.messageNotAnalyzed}
        </Box>;
      break;
    default:
      title = strings.dialog.title.warningProblem;
      icon = <Warning />;
      content =
        <>
          {strings.scanMessage.messageError}
        </>;
      break;
  }

  return (
    <SimpleDialog
      icon={icon}
      open={props.displayInformativePopup}
      onRefuse={props.onClose}
      title={title}
      refuseLabel={strings.general.ok}
    >
      {content}
    </SimpleDialog>);
};

ScanDialog.propTypes = {
  type: PropTypes.string.isRequired,
  selectedInstrument: PropTypes.object,
  displayInformativePopup: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ScanDialog;

