import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  content: {
    width: '100%',
    height: '100%',
    padding: "8px",
    overflowY: 'auto'
  },
  headerStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: "8px"
  },
  statusLabel: {
    paddingLeft: "8px"
  },
  divider: {
    width: '95%',
    margin: 'auto',
    marginBottom: "8px"
  }
});
