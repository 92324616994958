import _ from 'lodash';

import db from '../../Utils/Database/Database';
import DBUtils from '../../Utils/Database/DBUtils';
import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';
import Favorite from '../../Utils/Database/Models/FavoriteModel';
import { JwtHelperService } from '../../Utils/JwtHelperService';
import UserProvider from '../Database/UserProvider';
import ScanProvider from '../Database/ScanProvider';
import ScanService from '../WebService/ScanService';
import InterventionProvider from '../Database/InterventionProvider';
import InterventionService from '../WebService/InterventionService';
import { UserConstants } from '../../Utils/Constants/UserConstants';
import { logger, LogTypes } from '../../Utils/Logger';

const LAST_SYNCHRO_PROP = 'lastSynchro';
const BEFORE_LAST_SYNCHRO_PROP = 'beforeLastSynchro';

/**
 * All steps available for synchronization
 */
export const SynchronizationStep = {
  Step__download_instruments: 1,
  Step__upload_instruments: 2,
  Step__sync_connections: 3,
  Step__sync_scans: 4,
  Step__sync_interventions: 5,
  Step__sync_notification: 8,
  Step__sync_favorites: 9,
  Step__sync_messages_scans: 10
};

const LIMIT = 500;

class SynchroProvider {

  constructor() {
    this.onChange = () => {
    };
    this.onChangeStep = () => {
    };

    this.syncStopped = false;
  }

  /// Synchronize:
  ///  - Connections And Utilizations
  ///  - Scans
  ///  - Inventories
  ///  - Favorites
  ///  - Notifications
  ///  - Messages Scans
  ///  - Instruments
  async synchronize(onChange, onChangeStep) {
    const userLogin = JwtHelperService.getUserLogin();
    this.lastSync = await DBUtils.getPropertyValue(LAST_SYNCHRO_PROP);
    const isFirstSynchro = this.lastSync === 0;

    this.onChange = onChange;
    this.onChangeStep = onChangeStep;

    await this.syncConnections();

    await this.syncScans(userLogin);

    await this.syncInterventions();

    await this.syncFavorites(userLogin, isFirstSynchro);

    await this.syncNotifications(userLogin);

    await this.syncMessagesScans();

    await this.syncInstruments(userLogin, isFirstSynchro);

    await DBUtils.setPropertyValue(BEFORE_LAST_SYNCHRO_PROP, this.lastSync);
    await DBUtils.setPropertyValue(LAST_SYNCHRO_PROP, Date.now());
  }

  stopSync() {
    this.syncStopped = true;
  }

  // Synchronize all connections while user was offline
  async syncConnections() {
    this.onChangeStep(SynchronizationStep.Step__sync_connections);
    // find all connections that happened in the local database
    const connectionsCollection = db.connections.where('deconnectionHour').notEqual(DBUtils.getNull());

    let connections = await connectionsCollection.toArray();

    this.onChange(0, connections.length);


    connections = connections.map(item => (
      {
        id: item.serverId,
        connectionHour: item.connectionHour,
        deconnectionHour: item.deconnectionHour,
        lastOperation: item.lastOperation
      }
    ));

    if (connections.length === 0) {
      this.onChange(0, 0);
    } else {
      this.onChange(connections.length, connections.length);
    }

    // send all connections to the server
    return HttpClient.post(routes.syncConnection, { connections })
      // Delete all entities from the connection table except the current one
      .then(() => connectionsCollection.delete());
  }

  /// Send scans made offline and update the 'analysis' field locally.
  /// Fetch recents scans (30 days old max).
  async syncScans(userLogin) {
    this.onChangeStep(SynchronizationStep.Step__sync_scans);
    this.onChange(0, 0);

    // Send not analyzed scans made offline
    const notAnalyzedScans = await ScanProvider.getNotAnalyzedScans(userLogin);

    if (notAnalyzedScans.length > 0) {
      this.onChange(0, notAnalyzedScans.length);
      const length = notAnalyzedScans.length;
      let analyzedScans = [];
      let i = 1;
      let offset = 0;
      let syncInProgress = true;
      const limit = 50;

      // Send scans by chunk of 50
      while (syncInProgress) {
        const overrideLimit = length - offset < limit ? length - offset : limit;

        const notAnalyzedScansSliced = notAnalyzedScans.slice(offset, offset + overrideLimit);

        let createdScans = await ScanService.createManyScans(notAnalyzedScansSliced);
        analyzedScans = analyzedScans.concat(createdScans);
        i++;

        this.onChange(limit * i, length);

        syncInProgress = (offset + overrideLimit) < length;
        offset += overrideLimit;
      }

      analyzedScans = analyzedScans.map(scan => {
        return {
          ...scan,
          serverId: scan.id,
          date: new Date(scan.date)
        };
      });

      await db.scans.bulkPut(analyzedScans);
      this.onChange(notAnalyzedScans.length, notAnalyzedScans.length);
    }

    // Get all scans serverId in local database
    const localScans = await ScanProvider.getAllScans(userLogin);
    const localScanServerIds = localScans.map(scan => scan.serverId);

    // Fetch all missing scans
    let missingScans = await ScanService.getAllScans(localScanServerIds);
    missingScans = missingScans.map(scan => {
      return {
        ...scan,
        serverId: scan.id,
        date: new Date(scan.date)
      };
    });

    // Insert them in local database
    if (missingScans.length > 0) {
      await db.scans.bulkPut(missingScans);
    }

    // Clean local Database:
    // Remove scans that are more than 30 days old
    // Remove older scans to keep 1000 scans
    await ScanProvider.cleanLocaleDatabase(userLogin);

    return Promise.resolve();
  }

  /// Synchronize local inventories on server.
  async syncInterventions() {
    this.onChangeStep(SynchronizationStep.Step__sync_interventions);
    this.onChange(0, 0);
    // Get all local interventions
    const interventions = await InterventionProvider.getAllInterventions();
    this.onChange(0, interventions.length);

    // Get inventories grouped by user and location to update instruments if needed
    const groupedInterventions = _.groupBy(interventions, function (item) {
      return item.updateInstrumentLocation + '-' + item.updateInstrumentUser;
    });

    for (const groupedIntervention in groupedInterventions) {
      if (groupedInterventions.hasOwnProperty(groupedIntervention)) {
        const location = groupedInterventions[groupedIntervention][0].updateInstrumentLocation;
        const user = groupedInterventions[groupedIntervention][0].updateInstrumentUser;
        const interventionsGroup = groupedInterventions[groupedIntervention];

        await InterventionService.pushInterventions(interventionsGroup, location, user);
        await InterventionProvider.deleteInterventions(interventionsGroup);
      }
    }

    this.onChange(interventions.length, interventions.length);

    // update the instruments associated with an inventory to the lastest version
    return Promise.resolve();
  }

  /// Synchronize all favorites stored locally.
  async syncFavorites(userLogin, isFirstSynchro) {
    this.onChangeStep(SynchronizationStep.Step__sync_favorites);

    this.onChange(0, 0);

    if (isFirstSynchro) {
      const favoriteInstrument = await HttpClient.get(routes.favoriteInstruments);
      favoriteInstrument.data.forEach(async (marquage, index, array) => {
        this.onChange(index + 1, array.length);
        const favorite = new Favorite();
        favorite.setValues(userLogin, marquage);
        await favorite.save();
      });
      // else send all favorites to the server
    } else {
      return db.favorites.where({ userLogin }).toArray()
        .then(favorites => favorites.map(value => value.instrumentMarquage))
        .then(favorites => HttpClient.post(routes.favoriteInstrumentsSync, favorites))
        .then(response => this.onChange(response.data.length, response.data.length));
    }
  }

  async syncNotifications(userLogin) {
    this.onChangeStep(SynchronizationStep.Step__sync_notification);

    // Get Notifications with IsDeleted == true
    // Send request to the server with the previous list to delete them
    await db.instrumentNotifications.where({ userLogin, isDeleted: 1 }).toArray(async deletedNotifications => {
      // Remove notifications from database and server
      await this.deleteNotifications(deletedNotifications, true);
    });

    let allServerNotifications = [];
    let allDatabaseNotifications = [];

    // Get all notifications from the server
    const serverPromise = HttpClient.get(routes.getNotifications);

    // get local notifications
    const localDatabasePromise = db
      .instrumentNotifications.where(UserConstants.userLoginDB).equals(userLogin).toArray();

    let notificationsToInsert = [];
    let notificationsToDelete = [];

    return Promise.all([serverPromise, localDatabasePromise]).then(async values => {
      allServerNotifications = values[0].data;
      allDatabaseNotifications = values[1];

      const databaseNotificationsServerIds = allDatabaseNotifications
        .map(dataNotification => dataNotification.serverId);

      const serverNotificationsServerIds = allServerNotifications.map(serverNotification => serverNotification.id);

      // Return a list of notifications presents in the server but not in the database
      notificationsToInsert = allServerNotifications
        .filter(notification => databaseNotificationsServerIds.indexOf(notification.serverId) <= -1);
      // Return a list of notifications presents in the database but not in the server
      notificationsToDelete = allDatabaseNotifications
        .filter(notification => serverNotificationsServerIds.indexOf(notification.serverId) <= -1);

      notificationsToInsert = notificationsToInsert.map(notification => {
        return {
          userLogin,
          serverId: notification.id,
          marquage: notification.marquage,
          instrumentId: 1,
          createAt: notification.created_at,
          field: notification.field,
          oldValue: notification.old_value,
          isDeleted: 0
        };
      });

      await db.instrumentNotifications.bulkPut(notificationsToInsert);

      this.onChange(notificationsToInsert.length, notificationsToInsert.length + notificationsToDelete.length);

      // Remove the old notifications from the server to the local database
      await this.deleteNotifications(notificationsToDelete);

      this.onChange(notificationsToDelete, notificationsToInsert.length + notificationsToDelete.length);
    });

  }

  async deleteNotifications(deletedNotifications, removeOnServer = false) {
    // Delete notifications to database
    await db
      .instrumentNotifications.where('serverId')
      .anyOf(deletedNotifications.map(value => value.serverId)).delete();

    if (removeOnServer) {
      // Delete notifications to server
      await HttpClient.delete(routes.deleteNotifications, {
        data: deletedNotifications.map(notif => notif.serverId.toString())
      });
    }
  }

  /// Pull modification from the server and push locale instrument update (made offline)
  /// to the server.
  async syncInstruments(userLogin, isFirstSynchro) {
    // Push instruments
    await this.pushInstruments(userLogin);

    // pull instruments
    await this.pullInstruments(userLogin, isFirstSynchro);
  }

  async pushInstruments(userLogin) {
    this.onChangeStep(SynchronizationStep.Step__upload_instruments);

    // get items to push from the local database
    return db.instrumentsUpdates.toArray()
      .then(instrumentsUpdates => {

        // build the json with all items to push
        return instrumentsUpdates.map(instrumentUpdate => {

          const item = {
            status: instrumentUpdate.status,
            user: {
              login: userLogin
            },
            instruments: instrumentUpdate.marquagesInstrument
          };

          if (instrumentUpdate.localisation != null) {
            item.localisation = instrumentUpdate.localisation;
          }

          if (instrumentUpdate.utilisateur != null) {
            item.utilisateur = instrumentUpdate.utilisateur;
          }

          return item;
        });
      })
      // Send the json to the server
      .then(itemsToPush => HttpClient.post(routes.updateSyncPush, { items: itemsToPush }))
      .then(response => this.onChange(response.data.updated, response.data.updated))
      // Delete all updates sent from local db
      .then(() => db.instrumentsUpdates.clear());
  }

  async pullInstruments(userLogin, isFirstSynchro) {
    this.onChangeStep(SynchronizationStep.Step__download_instruments);

    // In case of a Thales administrator, who has access to every instruments
    // the value of user.instruments must contains every instruments in the local database
    // So we don't need to fetch all marquages

    // Get user's data
    /**
     * @type {UserModel}
     */
    const user = await db.usersInformations.where(UserConstants.userLoginDB).equals(userLogin).first();

    /**
     *  Get all marquages for current user from local db
     * @type {Set<string>}
     */
    const usersMarquages = user.instruments;

    /** @type {Promise<Set<string>>} */
    let instrumentsInDbPromise = db.instruments.toCollection().primaryKeys().then(pks => new Set(pks));

    // If the user is "Administrateur entité Thales", bypass fetching all instruments in database.
    if (UserProvider.isThalesAdmin(user)) {
      instrumentsInDbPromise = Promise.resolve(usersMarquages);
    }

    /**
     * Get all marquages for current user from the server
     * @type {Promise<Set<string>>}
     */
    const marquagesFromServerPromise = HttpClient.get(routes.myInstruments).then(res => new Set(res.data));

    return Promise.all([marquagesFromServerPromise, instrumentsInDbPromise]).then(async results => {
      const usersMarquagesFromServer = results[0];
      const allMarquagesInDatabase = results[1];

      const promisesToWait = [
        new Promise((resolve, reject) => {

          try {
            /**
             * Get instruments that are not linked to the user or not present in the locale database.
             * @type {Set<string>}
             */
            const notLinkedOrNotInDB = new Set([...usersMarquagesFromServer]
              .filter(x => !usersMarquages.has(x) || !allMarquagesInDatabase.has(x)));

            /**
             * Get instruments that only need a link with the user. These instrument are already in the locale database.
             * @type {Set<string>}
             */
            const needLinkInstrument = new Set([...notLinkedOrNotInDB]
              .filter(value => allMarquagesInDatabase.has(value)));
            /**
             * Get instruments that are not in the locale database.
             * @type {Set<string>}
             */
            const missingInstruments = new Set([...notLinkedOrNotInDB].filter(value => !needLinkInstrument.has(value)));

            resolve({ needLinkInstrument, missingInstruments });
          } catch (e) {
            reject(e);
          }
        }),
        new Promise((resolve, reject) => {

          try {
            /**
             * Get instruments that are linked to the user but not accessible by the user.
             * @type {Array<string>}
             */
            const needRemoveLinkInstrument = [...usersMarquages].filter(x => !usersMarquagesFromServer.has(x));

            resolve(needRemoveLinkInstrument);
          } catch (e) {
            reject(e);
          }
        })
      ];

      return Promise.all(promisesToWait).then(async newResults => {
        /** @type {Set<string>} */
        const needLinkInstrument = newResults[0].needLinkInstrument;
        /** @type {Set<string>} */
        const missingInstruments = newResults[0].missingInstruments;
        /** @type {Array<string>} */
        const needRemoveLinkInstrument = newResults[1];

        // Update links
        await db.usersInformations.where(UserConstants.userLoginDB).equals(userLogin)
          .modify(user2 => {
            // Delete links where we do not need them from local db
            for (const marquage of needRemoveLinkInstrument) {
              user2.instruments.delete(marquage);
            }

            // Create link for instrument that need it from local db
            for (const marquage of needLinkInstrument) {
              user2.instruments.add(marquage);
            }
          });
        // -> Fetch instruments
        return this.fetchInstruments(userLogin, allMarquagesInDatabase, missingInstruments, isFirstSynchro);
      });
    });
  }

  /**
   * Fetch instruments whenever they need to be updated or added.
   *
   * Process :
   * If this is the first synchronization, fetch missingInstruments.
   * Else fetch all marquages from the server that need to be update, bases on the timestamp of
   * the last synchronization, whenever if instruments are in user's perimeter.
   *
   * Then we have two workers : one to insert/update instruments in database and
   * another to add link between user and instruments.
   * We loop all marquages by chunk of 500, fetch instruments infos from server
   * and add a task to the insert worker.
   * Once the insert worker is done, the update link worker has a new task added.
   *
   * This function is completed all tasks has started and comleted.
   *
   * @param {string} userLogin Login to fetch the instruments for
   * @param {Set<string>} allMarquagesInDatabase All marquages available in the local database
   * @param {Set<string>} missingInstruments Instrument missing in the local database to be fetch
   * @param {boolean} isFirstSynchro If true, we don't fetch updated instruments but all instruments for the user.
   */
  fetchInstruments(userLogin, allMarquagesInDatabase, missingInstruments, isFirstSynchro) {

    return new Promise(async resolve => {
      /** @type {Array<string>} */
      const instrumentsToFetch = Array.from(missingInstruments);

      // not the first log
      if (!isFirstSynchro) {
        /**
         * get list of instruments to update
         * @type {Set<string>}
         */
        const completeListOfMarquagesToUpdate = await HttpClient.get(routes.listUpdateInstruments(this.lastSync))
          .then(response => new Set(response.data));

        /**
         * Get the intersection of marquages from all marquages present in the locale database,
         * to make sure every instruments will be up to date, even the ones that do not belongs to the current user
         * @type {Array<string>}
         */
        const marquagesToUpdate = [...allMarquagesInDatabase]
          .filter(value => completeListOfMarquagesToUpdate.has(value));

        // Concat the list of update instruments with missing one, in order to fetch them at the same time.
        for (const marquage of marquagesToUpdate) {
          instrumentsToFetch.push(marquage);
        }
      }

      const numberOfInstrumentsToSync = instrumentsToFetch.length;
      // If there is no instrument to insert or update, stop here
      if (numberOfInstrumentsToSync === 0) {
        this.onChange(0, 0);
        return resolve();
      }

      /** @type {number} */
      let syncInProgress = true;
      let offset = 0;

      /**
       * Progression of tasks for instruments insert
       * @type {Array<{i: number, insertedInstruments: Array<string>}>}
       */
      const syncProgressTasksInsertBulk = [];

      // download instruments
      let i = 1;
      while (syncInProgress) {
        const overrideLimit = numberOfInstrumentsToSync - offset < LIMIT ? numberOfInstrumentsToSync - offset : LIMIT;

        const currentRangeInstrumentToFetch = instrumentsToFetch.slice(offset, offset + overrideLimit);

        // Compute newly inserted instruments
        const insertedInstruments = new Set(currentRangeInstrumentToFetch.filter(x => missingInstruments.has(x)));

        // Add elements to the sync progress to to keep track of the task
        syncProgressTasksInsertBulk.push({ id: i, insertedInstruments });
        i++;

        // Fetch instruments from their marquages
        const reqFetchedInstruments = await HttpClient
          .post(routes.listFromMarquagesInstruments, currentRangeInstrumentToFetch);

        // Start a task to the web worker
        await db.instruments.bulkPut(reqFetchedInstruments.data);

        this.onChange(LIMIT * i, numberOfInstrumentsToSync);
        await db.usersInformations.where(UserConstants.userLoginDB).equals(userLogin)
          .modify(user => {
            for (const marquage of insertedInstruments) {
              user.instruments.add(marquage);
            }
          });

        syncInProgress = (offset + overrideLimit) < numberOfInstrumentsToSync;
        offset += overrideLimit;
      }
      return resolve();
    });
  }

  async syncMessagesScans() {
    this.onChangeStep(SynchronizationStep.Step__sync_messages_scans);

    let allServerMessagesScans = [];
    let allDatabaseMessagesScans = [];

    // Get all messages scans from the server
    const serverPromise = HttpClient.get(routes.getMessagesScans);

    // get local messages scans
    const localDatabasePromise = db.messagesScans.toArray();

    let messagesScansToInsert = [];
    let messagesScansToDelete = [];

    return Promise.all([serverPromise, localDatabasePromise]).then(async values => {
      allServerMessagesScans = values[0].data;
      allDatabaseMessagesScans = values[1];

      const databaseMessagesScansIds = allDatabaseMessagesScans.map(dataMessagesScans => dataMessagesScans.serverId);

      const serverMessagesScansServerIds = allServerMessagesScans.map(serverMessagesScans => serverMessagesScans.id);

      // Return a list of messages scans presents in the server but not in the database
      messagesScansToInsert = allServerMessagesScans
        .filter(messageScan => databaseMessagesScansIds.indexOf(messageScan.serverId) <= -1);
      // Return a list of notifications presents in the database but not in the server
      messagesScansToDelete = allDatabaseMessagesScans
        .filter(notification => serverMessagesScansServerIds.indexOf(notification.serverId) <= -1);

      messagesScansToInsert = messagesScansToInsert.map(messageScan => {
        let labelStatuses = messageScan.statuses.map(object => object.name);
        let labelStates = messageScan.states.map(object => object.name);
        return {
          serverId: messageScan.id,
          title: messageScan.title,
          message: messageScan.message,
          statuses: labelStatuses,
          states: labelStates,
        };
      });

      await db.messagesScans.bulkPut(messagesScansToInsert);


      this.onChange(messagesScansToInsert.length, messagesScansToInsert.length + messagesScansToDelete.length);

      // Remove the old messages-scans from the server to the local database
      await db.messagesScans.where('serverId').anyOf(messagesScansToDelete.map(value => value.serverId)).delete();

      this.onChange(messagesScansToDelete, messagesScansToInsert.length + messagesScansToDelete.length);
    });
  }

  static async resetSynchronizableTables() {
    try {
      // Clear every synchronizable table
      await db.connections.clear();
      await db.scans.clear();
      await db.favorites.clear();
      await db.instrumentNotifications.clear();
      await db.messagesScans.clear();
      await db.instrumentsUpdates.clear();
      await db.instruments.clear();

      // Empty assocation between current user and instruments
      await db.usersInformations.where(UserConstants.userLoginDB)
        .equals(JwtHelperService.getUserLogin()).modify(user => {
          user.instruments = new Set();
        });

      // Empty last synchro date
      await DBUtils.setPropertyValue(LAST_SYNCHRO_PROP, undefined);
    } catch (error) {
      logger(error, LogTypes.ERROR);
    }
  }
}

export default SynchroProvider;
