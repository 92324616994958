import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { styles } from './LoadingDialog.style';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../Utils/Theme';

const LoadingDialog = props => {
  return (
    <SimpleDialog
      open={props.open}
      title={props.title}
      addContentPadding>
      <Box component="div" sx={{...styles(theme).content}}>
        <Typography variant={isNotComputer ? 'h6' : 'subtitle1'}>{props.label}</Typography>
        <CircularProgress/>
      </Box>
    </SimpleDialog>
  );
};

LoadingDialog.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  open: PropTypes.bool
};

export default LoadingDialog;
