import InstrumentsProvider from '../Providers/Database/InstrumentsProvider';
import { ConnectivityChecker } from '../Utils/ConnectivityChecker';
import DBUtils from '../Utils/Database/DBUtils';
import { InstrumentConstants } from '../Utils/Constants/InstrumentConstants';
import InstrumentService from '../Providers/WebService/InstrumentService';
import HttpClient from '../Providers/HttpClient';
import routes from '../Utils/Routes';
import { JwtHelperService } from '../Utils/JwtHelperService';
import Favorite from '../Utils/Database/Models/FavoriteModel';
import db from '../Utils/Database/Database';
import { BarcodeType } from '../Utils/Constants/BarcodeType';
import { ETAT_METROLOGIQUE_TYPES, ETATS, ETATS_HORS_DELAI, INSTRUMENTCOLORS } from '../Utils/Constants/EtatsTypes';
import dayjs from 'dayjs';
import { LocalizedString, LocalizedString as strings } from '../Utils/Constants/LocalizedString';
import { logger } from '../Utils/Logger';
import { globalConstants } from '../Utils/Constants/GlobalConstants';
import { HttpConstants } from '../Utils/Constants/HttpConstants';
import { Validator } from '../Utils/Validator';
import { UserConstants } from '../Utils/Constants/UserConstants';
import UserProvider from '../Providers/Database/UserProvider';
import { getDateToTimestamp, getDayDiff } from '../Utils/DateUtils';


class InstrumentHelper {

  /**
   * Create an instrument update
   *
   * @param {object} instrument - instrument to update
   * @param {string} localisation
   * @param {string} user
   *
   * **/
  static createInstrumentUpdate(instrument, localisation, user) {
    return this.createInstrumentsUpdate([instrument], localisation, user);
  }

  /**
   * Create multiple instrument updates
   *
   * @param {[object]} instrumentsToUpdate - list of instruments to update
   * @param {string} localisation
   * @param {string} user
   *
   * **/
  static createInstrumentsUpdate(instrumentsToUpdate, localisation, user) {
    // Create let containing status, localisation, utilisateur
    const newInstrumentsUpdate = {
      localisation,
      status: InstrumentConstants.newStatus,
      date: DBUtils.getCurrentDateTime(),
      utilisateur: user,
      marquagesInstrument: []
    };

    // Foreach instrument in instrumentsToUpdate's list
    newInstrumentsUpdate.marquagesInstrument = instrumentsToUpdate.map(instrument => instrument.marquage);

    // If the user is connected to the internet and ADM server
    return ConnectivityChecker.isConnected().then(isConnected => {
      const instrumentToUpdateData = {
        instrumentsToUpdateMarquage: newInstrumentsUpdate.marquagesInstrument,
        instrumentsToUpdateFields: {}
      };
      if (isConnected) {
        // Only send the modified parameter
        if (user != null) {
          instrumentToUpdateData.instrumentsToUpdateFields.utilisateur = user;
        }
        if (localisation != null) {
          instrumentToUpdateData.instrumentsToUpdateFields.localisation = localisation;
        }

        // Update of the instruments by marquage on remote database
        return InstrumentService.updateInstrument(instrumentToUpdateData)
          .then(() => {
            // Update of the instruments by marquage on local database
            return InstrumentsProvider.updateInstrument(instrumentToUpdateData)
              .then(() => Promise.resolve(InstrumentConstants.updateInstrumentsAnswer.done))
              .catch(() => Promise.reject());
          })
          .catch(() => Promise.reject());
      } else {
        return InstrumentsProvider.addOrReplaceInstrumentUpdate(newInstrumentsUpdate)
          .then(() => Promise.resolve(InstrumentConstants.updateInstrumentsAnswer.pending))
          .catch(() => Promise.reject());
      }
    });
  }

  /**
   * Add the given instrument to favorites
   *
   * @param {string} marquage - marquage of the instrument to add to favorites
   *
   * **/
  static addInstrumentToFavorite(marquage) {
    // Post in the server
    HttpClient.post(routes.favoriteInstruments, { marquage });
    // And locally
    const favorite = new Favorite();
    favorite.setValues(JwtHelperService.getUserLogin(), marquage);
    favorite.save();
  }

  /**
   * Remove the given instrument from favorites
   *
   * @param {string} marquage - marquage of the instrument to remove from favorites
   *
   * **/
  static removeInstrumentFromFavorites(marquage) {
    // Delete in the server
    HttpClient.delete(routes.favoriteInstruments, {
      data: {
        marquage
      }
    });
    // And locally
    db.favorites.where({ userLogin: JwtHelperService.getUserLogin(), instrumentMarquage: marquage })
      .delete()
      .catch(logger);

  }

  /**
   * Get barcode type from given instrument
   *
   * @param {object} instrument
   * @param {string} barcode
   *
   * **/
  static getBarcodeType(instrument, barcode) {
    let barcodeType;

    if (instrument.marquage != null && barcode === instrument.marquage) {
      barcodeType = BarcodeType.MARQUAGE;
    } else if (instrument.ancienMarquage != null && barcode === instrument.ancienMarquage) {
      barcodeType = BarcodeType.OLDMARQUAGE;
    } else if (instrument.idCris != null && barcode === instrument.idCris) {
      barcodeType = BarcodeType.IDCRIS;
    } else if (instrument.rfid != null && barcode === instrument.rfid) {
      barcodeType = BarcodeType.RFID;
    } else {
      barcodeType = BarcodeType.NONE;
    }

    return barcodeType.toUpperCase();
  }


  /**
   * Check if the instrument is over the deadline
   *
   * @param {object} instrument
   *
   * **/
  static isHorsDelai(instrument) {
    /**
     * Check the state of the instrument is :
     *  - Utilisable
     *  - Stock Appelé
     *  - Introuvable
     *  - En attente de décision client
     *  - En attente de confirmation métrologique
     */
    const horsDelai = Object.values(ETATS_HORS_DELAI).indexOf(instrument.etat) <= -1;

    return (horsDelai
      // Verify that instrument is being inspected
      && instrument.planning === ETATS.SOUMIS_A_VERIFICATION
      // Next inspection date < ou = today's date
      && dayjs(instrument.periodiciteProchaineOperationDatetime, strings.general.usDateFormat).isBefore(dayjs()));
  }


  /**
   * Get the right color according to the instrument's state
   *
   * @param {object} etatInstrument
   * @param {object} themeColor
   *
   * **/
  static generateState(etatInstrument, themeColor) {
    let etatColor;
    const { blueTheme, orangeTheme, blackTheme, validationGreenTheme } = themeColor;
    switch (etatInstrument) {
      case ETATS.UTILISABLE:
        etatColor = validationGreenTheme.main;
        break;
      case ETATS.DORMANT:
      case ETATS.STOCK_APPELE:
        etatColor = blueTheme.main;
        break;
      case ETATS.INTROUVABLE:
        etatColor = orangeTheme.main;
        break;
      case ETATS.EN_ATTENTE_DECISION_CLIENT:
      case ETATS.EN_ATTENTE_CONFIRMATION_METROLOGIQUE:
      default:
        etatColor = blackTheme.main;
        break;
    }
    return etatColor;
  }

  /**
   * Create a array of string according to several parameters.
   * For afterwards define the right color
   * (Only used in InstrumentItemListTemplate)
   *
   * @param {object} instrument
   *
   * **/
  static instrumentColorArray(instrument) {
    const colorList = [];
    // Verify the next intervention date of the instrument
    if (this.isHorsDelai(instrument)) {
      // Add HorsDelai into the list
      colorList.push(INSTRUMENTCOLORS.HORS_DATE_VERIF);
    }

    // Verify the state of the instrument
    if (instrument.etat) {
      if (instrument.etat === ETATS.INTROUVABLE) {
        colorList.push(INSTRUMENTCOLORS.INTROUVABLE);

      }
      if (instrument.etat === ETATS.DORMANT
        || instrument.etat === ETATS.STOCK_APPELE) {
        colorList.push(INSTRUMENTCOLORS.DORMANT);
      }
    }

    if (instrument.finPrevisionnelleLocation) {
      const finPrevisionnelleLocation = dayjs(instrument.finPrevisionnelleLocation, strings.general.usDateFormat);
      const today = dayjs();
      if (today.diff(finPrevisionnelleLocation, 'days') < 30) {
        // Ajouter finPrevsionnelleLocation à la list
        colorList.push(INSTRUMENTCOLORS.FIN_LOCATION_PREVISIONNELLE);
      }
    }
    return colorList;
  }
  static getStatusOperation = (instrument) => {
    const dateOperation = new Date(instrument.periodiciteProchaineOperation);
    const todayDate = new Date(dayjs().format('YYYY-MM-DD'));

    if (dateOperation < todayDate) {
      return LocalizedString.tracking.borrowingStateOperationOne;
    } else {
      if(getDayDiff(todayDate, dateOperation) > 60){
        return LocalizedString.tracking.borrowingStateOperationTwo;
      } else if (getDayDiff(todayDate, dateOperation) > 30){
        return LocalizedString.tracking.borrowingStateOperationThree;
      } else if (getDayDiff(todayDate, dateOperation) < 30){
        return LocalizedString.tracking.borrowingStateOperationFour;
      }
    }
  }

  static getColorStatusOperation = (instrument, theme) => {
    const dateOperation = new Date(instrument.periodiciteProchaineOperation);
    const todayDate = new Date(dayjs().format('YYYY-MM-DD'));

    if (dateOperation < todayDate) {
      return theme.palette.greenTheme.main;
    } else {
      if(getDayDiff(todayDate, dateOperation) > 60){
        return theme.palette.greenTheme.main;
      } else if (getDayDiff(todayDate, dateOperation) > 30){
        return theme.palette.orangeTheme.main;
      } else if (getDayDiff(todayDate, dateOperation) < 30){
        return theme.palette.redTheme.main;
      }
    }
  }

  static getColorStatusInstrument = (instrument, theme) => {
    const colorList = InstrumentHelper.instrumentColorArray(instrument);
    if (colorList.length > 1) {
      return theme.palette.blackTheme.main;
    }
    if (colorList.includes(INSTRUMENTCOLORS.DORMANT)) {
      return theme.palette.blueTheme.main;
    }
    if (colorList.includes(INSTRUMENTCOLORS.INTROUVABLE)) {
      return theme.palette.orangeTheme.main;
    }
    if (colorList.includes(INSTRUMENTCOLORS.HORS_DATE_VERIF)) {
      return theme.palette.redTheme.main;
    }
    if (colorList.includes(INSTRUMENTCOLORS.FIN_LOCATION_PREVISIONNELLE)) {
      return theme.palette.purpleTheme.main;
    }
    return null;
  };

  /**
   * Get the right color according to the metrological state of the instrument
   *
   * @param {object} etatMetrologiqueInstrument
   * @param {object} themeColor
   *
   * **/
  static generateEtatMetrologiqueColor(etatMetrologiqueInstrument, themeColor) {
    let etatMetrologiqueColor;
    const { orangeTheme, validationGreenTheme, redTheme } = themeColor;
    switch (etatMetrologiqueInstrument) {
      case ETAT_METROLOGIQUE_TYPES.NON_CONFORME:
        etatMetrologiqueColor = redTheme.main;
        break;
      case ETAT_METROLOGIQUE_TYPES.CONFORME_AVEC_DECLASSEMENT:
        etatMetrologiqueColor = orangeTheme.main;
        break;
      case ETAT_METROLOGIQUE_TYPES.NON_ASSOCIE:
      case ETAT_METROLOGIQUE_TYPES.CONFORME:
      case ETAT_METROLOGIQUE_TYPES.INDEFINI:
      case ETAT_METROLOGIQUE_TYPES.CONFORME_AVEC_RESTRICTION:
      default:
        etatMetrologiqueColor = validationGreenTheme.main;
        break;
    }
    return etatMetrologiqueColor;
  }

  /** Get URL digital test bench */
  static getURLdigitalTestBench(route) {
    return HttpClient.get(route)
      .then(response => {
        const { data: URLdtb } = response;
        this.saveURLdigitalTestBenchLocal(URLdtb);
        return URLdtb;
      }).catch(error => error);
  }

  /** save URL digital test bench in localstorage */
  static saveURLdigitalTestBenchLocal(dtbURL) {
    localStorage.setItem(globalConstants.pathToDigitalTestBenchKey, dtbURL);
  }

  /** save URL digital test bench in localstorage */
  static getURLdigitalTestBenchLocal() {
    return localStorage.getItem(globalConstants.pathToDigitalTestBenchKey);
  }

  static async getLastInformationInstrument(currentInstrumentMarquage) {
    try {
      const responseRequest = await HttpClient.get(routes.getLastVersion(currentInstrumentMarquage));
      if (responseRequest.status !== HttpConstants.SUCCESS) {
        throw new Error('Unable to get data');
      }

      // User has requested the update of an instrument that he no longer has access to
      // so we need to remove this instrument from the user list to preserve rightful access
      if (responseRequest.data.shouldReSync) {
        // if the user is a local admin (which means there is only his instruments in the local db)
        // we need to remove the instruments that he no longer has access to
        if (await UserProvider.isAdmin) {
          await db.instruments.where(InstrumentConstants.marquageDB).anyOf(responseRequest.data.instrumentsToRemove)
            .delete();
        }
        await db.usersInformations.where(UserConstants.userLoginDB).equals(JwtHelperService.getUserLogin())
          .modify(user2 => {
            // Delete links where we do not need them from local db
            for (const marquage of responseRequest.data.instrumentsToRemove) {
              user2.instruments.delete(marquage);
            }
          });
        return { shouldReSync: true };
      }

      if (responseRequest.data.instruments.length === 0) {
        return false;
      }

      const [{ lastModificationDate: lastUpdateDB }] = responseRequest.data.instruments;
      const [responseDBLocal] = await InstrumentsProvider.getInstrumentsFromMarquages(currentInstrumentMarquage);
      const { lastModificationDate: lastUpdateDBLocal } = responseDBLocal;

      // Compare the last update (dbLocal : lastModificationDate)  and (adm DB : lastModificationDate)
      const shouldUpdate = Math.abs(getDateToTimestamp(lastUpdateDB) - getDateToTimestamp(lastUpdateDBLocal));
      
      if (shouldUpdate > 0) {
        const newInstrumentsUpdate = {
          instrumentsToUpdateMarquage: [currentInstrumentMarquage],
          instrumentsToUpdateFields: {}
        };

        const [result] = responseRequest.data.instruments;

        //Check the difference of two data.
        const fieldToUpdate = Validator.difference(result, responseDBLocal);

        const entries = Object.entries(fieldToUpdate);

        //Populate the newInstrumentsUpdate for update.
        for(const [key, value] of entries) {
          if (key === 'lastModificationDate') {
            newInstrumentsUpdate.instrumentsToUpdateFields[key] =
              dayjs(value).tz(strings.general.timeZoneParis).format(strings.general.usDateFormatWithTime);
          } else {
            newInstrumentsUpdate.instrumentsToUpdateFields[key] = value;
          }

        }

        // Update instrument with new values
        await InstrumentsProvider.updateInstrument(newInstrumentsUpdate);
        return true;
      } else {
        //Nothing to update
        return false;
      }
    } catch (e) {
      return false;
    }
  }

}

export default InstrumentHelper;
