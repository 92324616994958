import { Barcode } from 'scandit-sdk';

export const ScanConstants = {
  SUPPORTED_CHARACTERS_REGEXP: '^[a-zA-Z0-9-_()]$',
  BARCODE_REGEXP: '^[a-zA-Z0-9-_()/]+$',
  ENTER_KEY: 'Enter',
  CAMERA_SCAN_TIME: 5000,
  SCAN_ENGINE: 'https://unpkg.com/scandit-sdk@^3.1.0/build',
  SCANDIT_LICENCE_KEY: 'AWZNTIuBH+mOAj5WPTefUbAXwJZoCGubBFGDtlVJARXRLJWPwTW1lrxNI3Rxa3bfuEgV+vdJ8fMHeKBbRWW/UHZk1dTWQCpDJBS6f8xkWSyEcj1NwH6ejEsbO2mULQQy5xwn1tfeXM4QdaLgXudGrcPNWfYom6/CgnzKuXQ8cdF8ERsai4UfjOd1w7Mw9woSsn/Pn2SsVJrgXXhLrxXzS3Zt4awkbyMw7A1FylLx2NdYWPeptBMf+AxOWSmsiQFB70hG7NkiiH7kie2ozFTdYoE/WMse1UNq+V7u/vTswORTfQ7uEC8NdZVBa5RF0dgUMBM/QnZ7y9H/ef7KigGaBXQFWEYISNaa7xm3dxHIFje0kVddnYMd3jZfKtBoYGl0r2jQ+9+enhCfrSd0Kv5uEyJKSNDlinMO+n4XAE48Cg5gyWu5s3GYx6x143t6Q15L5L9gcB6H1ISACYlunGA7brJiinigcUF1nd5kSW3pAkyvOOcTH7FrV/ImJXCeHHpdAHLmz2FoaO+9vOxI/dmSSGqCDkQaCWFFiUvp9p5u/2P0mUCkjy0/D2XUSZpxj5WGlZAty7su738Gq6XCDnYpZ64gxIDJOls3+ZfU5XAKOjoOCppRE9DPyAkxOR325mhwCMLw4pb/qGkBLOiFS9/kXBpd+i4/7SE1TQuTUJKDty0jjZXvG6N6sscksDJAFgnTabiigHjbmohzXUIaPwijH3eEpTl3MN13ur0QdfW2h8wgPF5vj+JbTBxLj8PoxSMtvPCSp8cEqUepr3+6k1d/6njjBOhG/IJg3h7GQlbrmeXHmhMp',
  ENABLED_SYMBOLOGIES: [
    Barcode.Symbology.CODE128,
    Barcode.Symbology.AZTEC,
    Barcode.Symbology.CODABAR,
    Barcode.Symbology.CODE11,
    Barcode.Symbology.CODE25,
    Barcode.Symbology.CODE39,
    Barcode.Symbology.CODE93,
    Barcode.Symbology.DATA_MATRIX,
    Barcode.Symbology.EAN13,
    Barcode.Symbology.EAN8,
    Barcode.Symbology.QR
  ],
  SYMBOL_COUNTS: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
  SCAN_ZONE: { x: 0.1, y: 0.35, width: 0.8, height: 0.3 },
  SCAN_FPS: 30
};
