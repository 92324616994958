import {
  RESET_REDUX,
  UPDATE_ON_MOBILE,
  SAVE_USER_INFORMATIONS,
  UPDATE_NETWORK_STATUS,
  SHOULD_REDIRECT_TO_LOGIN,
  UPDATE_CURRENT_PAGE,
  SET_HELP_DIALOG_OPEN,
  SHOULD_FORCE_SYNC
} from './actionTypes';

export function resetReduxState(payload) {
  return { type: RESET_REDUX, payload };
}

export function updateShouldForceSync(payload) {
  return { type: SHOULD_FORCE_SYNC, payload };
}

export function updateOnMobile(payload) {
  return { type: UPDATE_ON_MOBILE, payload };
}

export function saveUserInformations(payload) {
  return { type: SAVE_USER_INFORMATIONS, payload };
}

export function updateNetworkStatus(payload) {
  return { type: UPDATE_NETWORK_STATUS, payload };
}

export function shouldRedirectToLogin(payload) {
  return { type: SHOULD_REDIRECT_TO_LOGIN, payload };
}

export function updateCurrentPage(payload) {
  return { type: UPDATE_CURRENT_PAGE, payload };
}

export function setHelpDialogOpen(payload) {
  return { type: SET_HELP_DIALOG_OPEN, payload };
}
