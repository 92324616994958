import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { styles } from './DateFilterInfo.style';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { Close } from '@mui/icons-material';
import { theme } from '../../../Utils/Theme';

export const DateFilterInfo = props => {

  return (
    <Box component="div" sx={{...styles(theme).selectedDateContainer}}>
      <Box component="div" sx={{...styles(theme).selectedDateText}}>
        <Typography variant="body2">{strings.historyPage.selectedDate}</Typography>
        <Typography variant="body2">{props.date.format(strings.general.dateFormat)}</Typography>
      </Box>
      <IconButton onClick={() => props.resetDatePicker()} size="large">
        <Close />
      </IconButton>
    </Box>
  );
};

DateFilterInfo.propTypes = {
  resetDatePicker: PropTypes.func.isRequired,
  date: PropTypes.object
};

export default DateFilterInfo;
