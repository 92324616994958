import { InterventionRequestConstants } from './Constants/InterventionRequestConstants';

export const downloadMultiplePdfs = binaries => {
  const urls = binaries.map(binary => InterventionRequestConstants.PDF_DATA + binary);
  var link = document.createElement('a');

  link.style.display = 'none';

  document.body.appendChild(link);

  for (var i = 0; i < urls.length; i++) {
    link.setAttribute('download', 'intervention_' + (i + 1) + '.pdf');
    link.setAttribute('href', urls[i]);
    link.click();
  }

  document.body.removeChild(link);
};
