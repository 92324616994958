import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Button from '@mui/material/Button';

import { SnackbarProvider, closeSnackbar } from 'notistack';
import App from './App';
import store from './store/store';
import { register } from './serviceWorker';
import { LocalizedString as strings } from './Utils/Constants/LocalizedString';
import ErrorBoundary from './Containers/ErrorBoundary/ErrorBoundary';
import { theme } from './Utils/Theme';

const app = (
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={3}
      action={(snackbarId) =>
        <Button 
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.veryDarkBlueTheme.main,
            '&:hover': {
              backgroundColor: theme.palette.pureWhite,
            }
          }}
          variant='contained'
          color='info'
          onClick={() => closeSnackbar(snackbarId)}
          size="small">
          {strings.general.ok}
        </Button>
      }>
      <BrowserRouter basename={process.env.REACT_APP_ENV !== 'local' ? '/app' : ''}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </BrowserRouter>
    </SnackbarProvider>
  </Provider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
register();
