// REACT libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Material-UI libraries
import { Typography, CardHeader, Box } from '@mui/material';
import { styles } from './InstrumentCardAdministration.style';


// Material icons
import Flag from '@mui/icons-material/Flag';
// Components libraries
import StaticField from '../../StaticField/StaticField';
import DynamicField from '../../DynamicField/DynamicField';
import EditButton from '../../EditButton/EditButton';
// Utils libraries
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import InstrumentHelper from '../../../../Helpers/InstrumentHelper';
import { usDateToFrDate } from '../../../../Utils/DateUtils';
import { instrumentListNeedsUpdate } from '../../../../store/actions/exportActions';
import { SnackbarMessages } from '../../../../Utils/Constants/SnackbarMessages';
import { InstrumentConstants } from '../../../../Utils/Constants/InstrumentConstants';
import LoadingSpinner from '../../../Utils/LoadingSpinner/LoadingSpinner';
import RoleHelper from '../../../../Helpers/RoleHelper';
import CameraScan from '../../../CameraScan/CameraScan';
import { ToastService } from '../../../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../../../Utils/Constants/ToastTypes';
import { isNotComputer } from '../../../Utils/ResponsiveSelect/ResponsiveSelect';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../../Utils/Theme';
import merge from 'lodash/merge';
// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    loggedUser: state.generalReducer.loggedUser
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    instrumentListNeedsUpdate: (value) => dispatch(instrumentListNeedsUpdate(value))
  };
};

const { localisation, utilisateur } = strings.instrumentCard.adminContent;

class InstrumentCardAdministration extends Component {
  state = {
    isEditTextChecked: false,
    newLocalisation: null,
    newUtilisateur: null,
    loading: false,
    cameraOpen: false,
    editingField: null
  };

  handleCameraScan = barcode => {
    if (this.state.editingField === localisation && barcode.length > 0) {
      this.setState({ newLocalisation: barcode });
    } else if (this.state.editingField === utilisateur && barcode.length > 0) {
      this.setState({ newUtilisateur: barcode });
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.wrongBarcode);
    }
  };

  onCameraClose = () => {
    this.setState({ cameraOpen: false });
  };

  // Function allowing to flip the icon
  handleClickEditButton = async () => {
    // Store state of isEditTextChecked's state 
    const isEditTextChecked = this.state.isEditTextChecked;
    this.setState(state => ({
      isEditTextChecked: !state.isEditTextChecked
    }));

    if (isEditTextChecked &&
      (this.state.newLocalisation != null || this.state.newUtilisateur != null)
      && (this.props.instrument.utilisateur !== this.state.newUtilisateur
        || this.props.instrument.localisation !== this.state.newLocalisation)) {
      // Check if there are modification
      this.setState({ loading: true });
      const updateResult = await InstrumentHelper
        .createInstrumentUpdate(this.props.instrument, this.state.newLocalisation, this.state.newUtilisateur);
      this.setState({ loading: false });
      if (updateResult === InstrumentConstants.updateInstrumentsAnswer.done) {
        enqueueSnackbar(SnackbarMessages.instrumentModificationSuccess.msg,
          SnackbarMessages.instrumentModificationSuccess.type);
        this.props.instrumentListNeedsUpdate(true);
      } else if (updateResult === InstrumentConstants.updateInstrumentsAnswer.pending) {
        enqueueSnackbar(SnackbarMessages.instrumentModificationWarning.msg,
          SnackbarMessages.instrumentModificationWarning.type);
      } else {
        enqueueSnackbar(SnackbarMessages.instrumentModificationError.msg,
          SnackbarMessages.instrumentModificationError.type);
      }
    }
  };

  getTextFieldValue = event => {
    if (event.target.name === localisation) {
      this.setState({ newLocalisation: event.target.value });
    } else if (event.target.name === utilisateur) {
      this.setState({ newUtilisateur: event.target.value });
    }
  };

  handleCamera = (choice) => {
    if (choice === localisation || choice === utilisateur) {
      this.setState({
        cameraOpen: true,
        editingField: choice
      });
    }
  };

  render() {
    return (
      <Box component="div" sx={{...styles(theme).administrationContent}}>
        {this.state.loading && <LoadingSpinner/>}
        <div>
          {this.props.showTitle &&
          <CardHeader
            sx={{...merge({}, styles(theme).cardHeader,
              (isNotComputer && styles(theme).tabletCardHeader)),
              "& .MuiCardHeader-action": {...styles(theme).cardHeaderAction} }}
            title={
              <Typography
                color="secondary"
                variant="h6"
                sx={{...merge({}, styles(theme).titleCardHeader,
                  (isNotComputer && styles(theme).tabletTitleFontSize))}}
              >
                {strings.instrumentCard.administration}
              </Typography>}
            avatar={<Flag color="secondary" sx={{...merge({}, styles(theme).iconCardHeader,
              (isNotComputer && styles(theme).tabletIconCardHeader))}}/>}
            action={RoleHelper.hasCRight(this.props.loggedUser) 
              ? <EditButton
                isEditTextChecked={this.state.isEditTextChecked}
                handleClickEditButton={this.handleClickEditButton}
                colorIcon="secondary"
              /> : null}
          />
          }
          <StaticField
            name={strings.instrumentCard.adminContent.site}
            value={this.props.instrument.site}/>
          <DynamicField
            name={localisation}
            value={this.state.newLocalisation != null ? this.state.newLocalisation : this.props.instrument.localisation}
            isEditTextChecked={this.state.isEditTextChecked}
            getValue={this.getTextFieldValue}
            camera={() => this.handleCamera(localisation)}/>
          <StaticField
            name={strings.instrumentCard.adminContent.identifiant3}
            value={this.props.instrument.identifiant3}/>
          <DynamicField
            name={utilisateur}
            value={this.state.newUtilisateur != null ? this.state.newUtilisateur : this.props.instrument.utilisateur}
            isEditTextChecked={this.state.isEditTextChecked}
            getValue={this.getTextFieldValue}
            camera={() => this.handleCamera(utilisateur)}
          />
          <StaticField
            name={strings.instrumentCard.adminContent.proprietaire}
            value={this.props.instrument.proprietaire}/>
          <StaticField
            name={strings.instrumentCard.adminContent.parc}
            value={this.props.instrument.parc}/>
          <StaticField
            name={strings.instrumentCard.idContent.banc}
            value={this.props.instrument.banc}/>
          <StaticField
            name={strings.instrumentCard.adminContent.affectation}
            value={this.props.instrument.affectation}/>
          <StaticField
            name={strings.instrumentCard.adminContent.section}
            value={this.props.instrument.section}/>
          <StaticField
            name={strings.instrumentCard.adminContent.sousSection}
            value={this.props.instrument.sousSection}/>
          <StaticField
            name={strings.instrumentCard.adminContent.destinataire}
            value={this.props.instrument.destinataire}/>
          <StaticField
            name={strings.instrumentCard.adminContent.finPrevisionnelleLocation}
            value={usDateToFrDate(this.props.instrument.finPrevisionnelleLocation)}/>
          <StaticField
            name={strings.instrumentCard.adminContent.prixLocation}
            value={this.props.instrument.coutJour}/>
          <StaticField
            name={strings.instrumentCard.adminContent.dateCreation}
            value={usDateToFrDate(this.props.instrument.dateCreation)}/>
          <StaticField
            name={strings.instrumentCard.adminContent.dateDerniereMiseAjour}
            value={usDateToFrDate(this.props.instrument.lastModificationDate, true, true)}/>
        </div>
        {
          this.props.onMobile &&
          <Box component="div" sx={{...styles(theme).editButtonContainer}}>
            <EditButton
              id='editButton'
              isEditTextChecked={this.state.isEditTextChecked}
              handleClickEditButton={this.handleClickEditButton}
              colorIcon="primary"
            />
          </Box>
        }
        {this.state.cameraOpen &&
        <CameraScan
          id="cameraScan"
          open={this.state.cameraOpen}
          onClose={this.onCameraClose}
          onScan={this.handleCameraScan}/>
        }
      </Box>
    );
  }
}

InstrumentCardAdministration.propTypes = {
  instrument: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  onMobile: PropTypes.bool.isRequired,
  loggedUser: PropTypes.object.isRequired,
  instrumentListNeedsUpdate: PropTypes.func.isRequired
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(InstrumentCardAdministration);
