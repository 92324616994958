export const RoleConstants = {
  GLOBAL_ADMINISTRATOR: 'Administrateur THALES',
  ENTITY_ADMINISTRATOR: 'Administrateur entité Thales',
  SITE_CORRESPONDENT: 'Correspondant Site',

  VALIDATOR: 'Valideur',
  MONO_SECTION_USER: 'Utilisateur mono-section',
  METROLOGY_CORRESPONDENT: 'Correspondant métrologie',
  TECHNICIAN: 'Technicien',

  TGS_BASE_RIGHTS: 'Droits de base sur parc TGS',
  TGS: 'THALES GLOBAL SERVICES',
  EXTERNAL_RENTER: 'Externe Loueur',

  NO_ACCESS: 0,
  U_LEVEL: 1,
  C_LEVEL: 2,
  T_LEVEL: 3,
};
