export let styles = theme => ({
  editButton: {
    padding: theme.spacing(0.5),
    margin: theme.spacing(),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
      margin: theme.spacing(2)
    },
  },
});
