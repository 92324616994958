import db from '../../Utils/Database/Database';
import InstrumentsProvider from './InstrumentsProvider';
import { UserConstants } from '../../Utils/Constants/UserConstants';
import { HttpConstants } from '../../Utils/Constants/HttpConstants';
import { JwtHelperService } from '../../Utils/JwtHelperService';
import { logger } from '../../Utils/Logger';
import DBUtils from '../../Utils/Database/DBUtils';

const userLoginField = UserConstants.userLoginDB;
const ADMIN_THALES = 'Administrateur entité Thales';
const THALES = 'Thales';

class UserProvider {

  /**
   * Return true if the user has the role of Thales Administrator.
   * It means it can access all instruments.
   * @param {UserModel} user
   */
  static isThalesAdmin(user) {
    for (const role of user.roles) {
      if (role.value === ADMIN_THALES &&
        role.financialUnit === THALES &&
        role.geographicalUnit === THALES &&
        role.organizationalUnit === THALES
      ) {
        return true;
      }
    }

    return false;
  }

  /**
   * Return in a promise if the user is local admin
   * user is local admin if his instrument count is equals to the local database instrument count
   */
  static userIsLocalAdmin() {
    if (UserProvider.isAdmin) {
      return Promise.resolve(UserProvider.isAdmin);
    }

    return new Promise((resolve, reject) => {
      db.usersInformations.count().then(userCount => {
        // If the user is the only user in the database, he is the local admin
        if (userCount === 1) {
          UserProvider.isAdmin = true;
          resolve(UserProvider.isAdmin);
        }
        // else we check his instrument count
        else {
          Promise.all([InstrumentsProvider.getUserInstrumentCount(), InstrumentsProvider.getInstrumentCount()])
            .then(values => {
              UserProvider.isAdmin = values[0] === values[1];
              resolve(UserProvider.isAdmin);
            }).catch(reject);
        }
      }).catch(reject);
    });
  }

  /** Get to know if instrument is in user perimeter */
  static async isInUserPerimeter(marquage, userLogin) {
    const currentUser = await db.usersInformations.where(userLoginField).equals(userLogin).first();
    const instruments = currentUser.instruments;
    return instruments.has(marquage);
  }

  static checkLocalUserCredentials(login, password) {
    return new Promise((resolve, reject) => {

      db.usersInformations.where(userLoginField).equalsIgnoreCase(login).first()
        .then(res => {
          if (res.pwdHash === password) {
            resolve();
          } else {
            reject(HttpConstants.BAD_REQUEST);
          }
        })
        .catch(() => reject(HttpConstants.NOT_FOUND));
    });

  }

  // Get User information from local database
  static getUserInfosFromDatabase() {
    // return user informations
    return db.usersInformations.where(userLoginField).equals(JwtHelperService.getUserLogin()).first()
      .then(value => value)
      .catch(logger);
  }

  static hasLockedLandscapeMode() {
    return DBUtils.getPropertyValue('landscapeLock');
  }

  static setLockedLandscapeMode(value) {
    return DBUtils.setPropertyValue('landscapeLock', value);
  }

}

export default UserProvider;
