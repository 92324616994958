import sharedStyles from '../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  chip: {
    margin: '0px 8px 8px',
    padding: '20px 16px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    fontSize: "14px",
    [theme.breakpoints.only('xs')]: {
      margin: "5px"
    }
  },
  link: {
    backgroundColor: theme.palette.primary.light,
  },
  chipsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'start',
  },
  input: {
    display: 'none'
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1
  },
  linkField: {
    margin: "4px"
  }
});
