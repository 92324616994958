// REACT libraries
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
// Material-UI libraries
import { styles } from './HomePage.style';
import { Box, Button } from '@mui/material';
import { DevicesOther, Build, PlaylistAddCheck, History, Home } from '@mui/icons-material';
// Others libraries
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { updateToolbarInfo } from '../../store/actions/exportActions';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import ScanButton from '../MainPage/ScanButton/ScanButton';
import RoleHelper from '../../Helpers/RoleHelper';
import { withGuide } from '../Guides/Guidable';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import { theme } from '../../Utils/Theme';
import merge from 'lodash/merge';

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};

class HomePage extends Component {

  componentDidMount() {
    // Display toolbar's content (icon, title, iconButtons)
    this.props.updateToolbarInfo({
      title: strings.mainMenu.home,
      icon: <Home />,
      actions: [ToolbarActionsConstants.START_TUTORIAL]
    });
  }

  generateButtons = (classes, pagesIds, icon, text, interventionForbidden = false) => {
    let colorTheme;
    const { blueTheme, orangeTheme, greenTheme, purpleTheme } = theme.palette;
    switch (pagesIds) {
      case PAGES_IDS.INSTRUMENTS:
        colorTheme = blueTheme.main;
        break;
      case PAGES_IDS.INTERVENTION:
        colorTheme = orangeTheme.main;
        break;
      case PAGES_IDS.INVENTORY:
        colorTheme = greenTheme.main;
        break;
      case PAGES_IDS.HISTORY:
        colorTheme = purpleTheme.main;
        break;
      default:
        colorTheme = blueTheme.main;
        break;
    }
    return (
      <Button
        id={'button-' + pagesIds}
        variant="contained"
        style={{ '--background-color': colorTheme }}
        sx={{...merge({}, classes.button, classes.spaceButton, classes.defaultButton)}}
        onClick={() => this.props.handleMenuItemClick(pagesIds)}
        disabled={interventionForbidden}
      >
        {icon}
        {text}
      </Button>
    );
  }

  render() {
    const interventionForbidden = !this.props.activeUser.roles || !RoleHelper.hasCRight(this.props.activeUser);
    const classes = styles(theme);
    return (
      <>
        {this.props.notificationCount > 0 &&
          <Box component="div"
            id="notificationsButton"
            sx={{...styles(theme).notificationPanel}}
            onClick={() => this.props.handleMenuItemClick(PAGES_IDS.FOLLOWED_INSTRUMENTS)}>
            {strings.notifications.notificationsHome(this.props.notificationCount)}
          </Box>}

        <Box component="div" sx={{...styles(theme).buttons}}>
          {this.generateButtons(classes,
            PAGES_IDS.INSTRUMENTS,
            <DevicesOther sx={{...styles(theme).icon}} />,
            strings.mainMenu.instruments)}

          {this.generateButtons(classes,
            PAGES_IDS.INTERVENTION,
            <Build sx={{...styles(theme).icon}} />,
            strings.mainMenu.interventions,
            interventionForbidden)}

          {this.generateButtons(classes,
            PAGES_IDS.INVENTORY,
            <PlaylistAddCheck sx={{...styles(theme).icon}} />,
            strings.mainMenu.inventaire,
            interventionForbidden)}

          {this.generateButtons(classes,
            PAGES_IDS.HISTORY,
            <History sx={{...styles(theme).icon}} />,
            strings.mainMenu.history)}
        </Box>
        <ScanButton handleScanButtonClick={this.props.handleScanButtonClick} />
      </>
    );
  }
}

HomePage.propTypes = {
  updateToolbarInfo: PropTypes.func.isRequired,
  notificationCount: PropTypes.number,
  handleMenuItemClick: PropTypes.func.isRequired,
  handleScanButtonClick: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired
};

export default compose(
  connect(null, mapDispatchToProps))(withGuide(HomePage));
