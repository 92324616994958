import React from 'react';
import { IconButton } from '@mui/material';
import { VerticalAlignBottom } from '@mui/icons-material';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import CsvDownloader from 'react-csv-downloader';
import PropTypes from 'prop-types';
import { styles } from './CsvIcon.style';
import { theme } from '../../../Utils/Theme';

const CsvIcon = props => {
  return (
    <IconButton
      sx={{ "&.MuiIconButton-root": {...styles(theme).csvIcon}}}
      style={{ color: props.color }}
      size="large">
      <CsvDownloader
        id="csvDownloader"
        datas={props.datas}
        columns={props.headers}
        separator={strings.general.csvSeparator}
        filename={props.fileName}>
        <VerticalAlignBottom sx={{...(props.marginTop && styles(theme).downloadIcon)}} />
      </CsvDownloader>
    </IconButton >
  );
};

CsvIcon.propTypes = {
  marginTop: PropTypes.bool,
  color: PropTypes.string,
  datas: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
};

CsvIcon.defaultProps = {
  color: '#fafafa'
};

export default CsvIcon;
