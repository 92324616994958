import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { CheckCircle, Warning, Error as ErrorIcon, PlaylistAddCheck } from '@mui/icons-material';
import { Typography, Divider, Box } from '@mui/material';

import { styles } from './InventoryAnswerPage.style';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import InstrumentsAnswerList from '../InventoryAnswerList/InstrumentsAnswerList';
import { InventoryStatus } from '../../../../Utils/Constants/InventoryStatus';
import { InventoryResultStatus } from '../../../../Utils/Constants/InventoryResultStatus';
import { ToolbarActionsConstants } from '../../../../Utils/Constants/ToolbarActionsConstants';
import { updateToolbarInfo, setCSVData } from '../../../../store/actions/exportActions';
import { connect } from 'react-redux';
import InventoryHelper from '../../../../Helpers/InventoryHelper';
import { theme } from '../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    activeUser: state.generalReducer.loggedUser,
    onMobile: state.generalReducer.onMobile
  };
};
// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    setCSVData: csvData => dispatch(setCSVData(csvData)),
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};
class InventoryAnswerPage extends Component {

  componentDidMount() {
    if (this.props.onMobile) {
      this.props.setCSVData(InventoryHelper.csvDownloadData(this.props.notVerified.concat(this.props.verified), this.props.activeUser));
      this.props.updateToolbarInfo({
        title: strings.inventory.inventoryTitle,
        icon: (<PlaylistAddCheck />),
        actions: [ToolbarActionsConstants.SET_INVENTORY_CSVDATA]
      });
    } else {
      this.props.updateToolbarInfo({
        title: '',
        icon: (null),
        actions: []
      });
    }
  }

  getHeaderStatus = status => {
    switch (status) {
      case InventoryResultStatus.SUCCESS:
        return (
          <>
            <CheckCircle style={{ color: theme.palette.validationGreenTheme.main }} />
            <Typography variant='h6' sx={{...styles(theme).statusLabel}}>{strings.inventory.creationSuccess}</Typography>
          </>
        );
      case InventoryResultStatus.SAVED_LOCALLY:
        return (
          <>
            <Warning style={{ color: theme.palette.toasts.warning }} />
            <Typography variant='h6' sx={{...styles(theme).statusLabel}}>{strings.inventory.creationWarning}</Typography>
          </>
        );
      case InventoryResultStatus.FAIL:
      default:
        return (
          <>
            <ErrorIcon style={{ color: theme.palette.toasts.error }} />
            <Typography variant='h6' sx={{...styles(theme).statusLabel}}>{strings.inventory.creationFail}</Typography>
          </>
        );
    }
  };

  render() {
    const validatedList = this.props.verified.filter(instr => instr.inventoryStatus === InventoryStatus.VALIDATED);
    const unexpectedList = this.props.verified.filter(instr => instr.inventoryStatus === InventoryStatus.UNEXPECTED);

    return (
      <div id="inventoryAnswer" sx={{...styles(theme).content}}>
        <Box component="div" sx={{...styles(theme).headerStatus}}>
          {this.getHeaderStatus(this.props.status)}
        </Box>
        <Divider sx={{...styles(theme).divider}} />
        <InstrumentsAnswerList
          title={strings.inventory.notVerified(this.props.notVerified.length)}
          instruments={this.props.notVerified} />
        <InstrumentsAnswerList
          title={strings.inventory.verified(validatedList.length)}
          instruments={validatedList} />
        <InstrumentsAnswerList
          title={strings.inventory.unexpected(unexpectedList.length)}
          instruments={unexpectedList} />
        <InstrumentsAnswerList
          title={strings.inventory.outOfBound}
          instruments={this.props.verified.filter(instr => instr.inventoryStatus === InventoryStatus.OUT_OF_BOUND)} />
        {this.props.inventoryResult && (
          <InstrumentsAnswerList
            title={strings.inventory.advesoError}
            instruments={this.props.inventoryResult.notUpdated} />)
        }
      </div>
    );
  }
}

InventoryAnswerPage.propTypes = {
  notVerified: PropTypes.array,
  verified: PropTypes.array,
  status: PropTypes.string.isRequired,
  onMobile: PropTypes.bool,
  updateToolbarInfo: PropTypes.func,
  setCSVData: PropTypes.func,
  activeUser: PropTypes.object.isRequired,
  inventoryResult: PropTypes.object
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(InventoryAnswerPage);
