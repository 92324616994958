import sharedStyles from '../../Utils/SharedStyles';

const SMALL_MARGIN_DIALOG_POPUP = '24px';
const STANDARD_MARGIN_DIALOG_POPUP = '96px';


export let styles = theme => ({
  ...sharedStyles(theme),
  /*** Contained the pop-up ***/
  popUp: {
    [theme.breakpoints.only('sm')]: {
      maxWidth: '80vw',
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: '60vw',
    },
    [theme.breakpoints.only('xl')]: {
      maxWidth: '50vw',
    }
  },
  tabletPopUp: {
    [theme.breakpoints.only('lg')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.only('xl')]: {
      maxWidth: '70vw',
    }
  },

  paperScrollPaper: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: `calc(100% - ${SMALL_MARGIN_DIALOG_POPUP})`,
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: `calc(100% - ${STANDARD_MARGIN_DIALOG_POPUP})`,
    },
  },
  paper: {
    [theme.breakpoints.up('sm')]: {
      margin: SMALL_MARGIN_DIALOG_POPUP,
    },
    [theme.breakpoints.up('lg')]: {
      margin: STANDARD_MARGIN_DIALOG_POPUP,
    },
  },
  /*** Material-UI's block contained the DialogTitle (so the header) or the DialogContent ***/
  dialogTitle: {
    padding: "0px",
  },
  dialogContent: {
    backgroundColor: theme.palette.lightGrayTheme.main,
    [theme.breakpoints.only('xs')]: {
      overflowX: 'hidden',
    },
    [theme.breakpoints.down('lg')]: {
      padding: "0px"
    },
  },
  /*** Block containing the 4 cards (Identification, Metrologie, Picture, Administration) ***/
  cards: {
    display: 'flex',
  },
  /*** Block containing 1 tab ***/
  card: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(),
    },
  },
  /*** Block containing 2 tabs with a form of column ***/
  column: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(),
      marginLeft: theme.spacing(),
    },
  },
  rightColumn: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(),
    },
  },
  /*** Bottom Navigation bar for mobile format ***/
  dialogActions: {
    margin: "0px",
  },
  bottomNavigation: {
    width: '100%',
    backgroundColor: theme.palette.primary.main
  },
  bottomNavigationAction: {
    color: theme.palette.secondary.main
  },
  bottomNavigationActionSelected: {
    color: `${theme.palette.blackTheme.main}!important`,
  },
  /*** Block containing instrument's image ***/
  imageBlock: {
    marginBottom: theme.spacing(2),
    maxHeight: "125px",
    display: 'flex'
  }
});
