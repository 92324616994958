import PropTypes from 'prop-types';
import { styles } from './Banner.style';
import React, { Component } from 'react';
import {
  Typography,
  Paper
} from '@mui/material';
import { BannerSeverity} from '../../Utils/Constants/BannerSeverity';
import { theme } from '../../Utils/Theme';
import merge from 'lodash/merge';


class Banner extends Component {

  render() {
    const { bannerData } = this.props;
    let colorSeverity;

    switch(bannerData.severity) {
      case 2:
        colorSeverity = styles(theme).severity_critical;
        break;
      case 1:
        colorSeverity = styles(theme).severity_medium;
        break;
      case 0:
      default:
        colorSeverity = styles(theme).severity_info;
        break;
    }

    return (
      <Paper elevation={2} sx={{...merge({}, styles(theme).banner_root, colorSeverity)}}>
          <Typography variant="h4" sx={{...styles(theme).banner_text}}>
            {BannerSeverity[bannerData.severity] + " " + bannerData.contentBanner}
          </Typography>
      </Paper>
    );
  }
}

Banner.propTypes = {
  bannerData: PropTypes.object.isRequired
};

export default Banner;
