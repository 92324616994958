import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Box } from '@mui/material';
import Close from '@mui/icons-material/Close';

import InstrumentImage from '../../InstrumentCard/InstrumentImage/InstrumentImage';
import { styles } from './ScanItem.style';
import { theme } from '../../../Utils/Theme';

const ScanItem = props => {

  return <>
    <Box component="div" id="scanItem" sx={{...styles(theme).card}} onClick={() => props.onClick(props.instrument)}>
      <Box component="div"  sx={{...styles(theme).instrumentImage}}><InstrumentImage instrument={props.instrument} /></Box>
      <Box component="div" sx={{...styles(theme).center}}>
        <Typography variant='body1' sx={{...styles(theme).designation}}>
          {props.instrument.designation}
        </Typography>
        <Typography variant='body1' sx={{...styles(theme).referenceConstructeur}}>
          {props.instrument.referenceConstructeur}
        </Typography>
        <Typography variant='body1' sx={{...styles(theme).marquage}}>{props.instrument.marquage}</Typography>
      </Box>
      <Box component="div" sx={{...styles(theme).middle}}>
        <div>
          <IconButton
            id="deleteButton"
            sx={{...styles(theme).delete}}
            onClick={e => {
              e.stopPropagation();
              props.onDelete(props.instrument);
            }}
            size="large">
            <Close />
          </IconButton>
        </div>
        <Typography sx={{...styles(theme).secondaryInformation}} variant='body1'>
          {props.instrument.constructeur}
        </Typography>
      </Box>
    </Box>
  </>;
};

ScanItem.propTypes = {
  instrument: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ScanItem;
