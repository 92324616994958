// REACT Library
import PropTypes from 'prop-types';
import React from 'react';
// Material-UI libraries
import { styles } from './EditButton.style';
import { IconButton } from '@mui/material';
import { Check, Edit } from '@mui/icons-material';
import { theme } from '../../../Utils/Theme';

const EditButton = props => (
  <IconButton
    sx={{ "&.MuiIconButton-root": {...styles(theme).editButton}}}
    onClick={props.handleClickEditButton}
    size="large">
    {
      props.isEditTextChecked ?
        <Check color={props.colorIcon} /> :
        <Edit color={props.colorIcon} />
    }
  </IconButton>
);

EditButton.propTypes = {
  isEditTextChecked: PropTypes.bool.isRequired,
  handleClickEditButton: PropTypes.func.isRequired,
  colorIcon: PropTypes.string.isRequired
};

export default EditButton;
