import { LocalizedString as strings } from './LocalizedString';

// As severity is a int => it can be use as an index
// 0 = info 1 = medium 2 = critical

// Display the text for the adequate severity
export const BannerSeverity = [
  strings.banner.infoSeverity,
  strings.banner.mediumSeverity,
  strings.banner.criticalSeverity
];