import React from 'react';
import {
  Box,
  Icon,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Notifications, NotificationsNone } from '@mui/icons-material';
import { connect } from 'react-redux';

import { styles } from './ItemTemplate.style';
import InstrumentImage from '../../../InstrumentCard/InstrumentImage/InstrumentImage';
import { addFavorite, removeFavorite } from '../../../../store/actions/exportActions';
import InstrumentHelper from '../../../../Helpers/InstrumentHelper';
import { theme } from '../../../../Utils/Theme';

// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    favorites: state.instrumentReducer.favorites
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    addFavorite: favorites => dispatch(addFavorite(favorites)),
    removeFavorite: favorites => dispatch(removeFavorite(favorites))
  };
};

const ItemTemplate = props => {

  const isFavorite = () => props.favorites.indexOf(props.item.marquage) >= 0;

  const onFavoriteClick = event => {
    // prevent the whole card to catch the event
    event.stopPropagation();

    if (isFavorite()) {
      InstrumentHelper.removeInstrumentFromFavorites(props.item.marquage);
      props.removeFavorite(props.item.marquage);
    }
    else {
      InstrumentHelper.addInstrumentToFavorite(props.item.marquage);
      props.addFavorite(props.item.marquage);
    }
  };

  return (
    <ListItem id="item" sx={{ "&.MuiListItem-root": {...styles(theme).item}}} onClick={props.openInstrumentCard}>
      <div style={{ height: '50px', width: '50px' }}>
        <InstrumentImage instrument={props.item} />
      </div>
      <ListItemText
        disableTypography
        sx={{...styles(theme).listItemText}}
        primary={
          <>
            <label>{props.item.designation}</label>
          </>
        }
        secondary={
          <>
            <Typography variant="subtitle2">{props.item.referenceConstructeur}</Typography>
            <Typography variant="subtitle2">{props.item.marquage}</Typography>
            <Typography variant="subtitle2">{props.item.constructeur}</Typography>
          </>
        }
      />
      {props.isNotGuestMode && (<ListItemSecondaryAction>
        <>
          <IconButton
            id="favoriteButton"
            sx={{ "&.MuiIconButton-root": {...styles(theme).actionButton}}}
            onClick={e => onFavoriteClick(e)}
            size="large">
            {isFavorite() ? <Notifications /> : <NotificationsNone />}
          </IconButton>
        </>
      </ListItemSecondaryAction> )}
      {!props.isNotGuestMode && (
        <Box component="div" sx={{...styles(theme).right}}>
          <Box component="div" sx={{...styles(theme).icons}}>
            {props.dotColor &&
              <Icon
                id="dot"
                style={{ '--backgroundColor': props.dotColor }}
                sx={{ "&.MuiIcon-root": {...styles(theme).dot}}}
                onClick={e => {
                  e.stopPropagation();
                  props.onDotClick(props.item);
                }} />
            }
          </Box>
          <Typography sx={{...styles(theme).secondaryInformation}} variant="body2">
            {props.stateOperation}
          </Typography>
        </Box>
      )}
    </ListItem>
  );
};

ItemTemplate.propTypes = {
  item: PropTypes.object.isRequired,
  openInstrumentCard: PropTypes.func,
  addFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
  favorites: PropTypes.array,
  isNotGuestMode: PropTypes.bool,
  dotColor: PropTypes.string,
  stateOperation: PropTypes.string,
  onDotClick: PropTypes.func,

};
ItemTemplate.defaultProps = {
  isNotGuestMode: true
};
export default connect(mapStateToProps, mapDispatchToProps)(ItemTemplate);
