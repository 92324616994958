import db from '../../Utils/Database/Database';
import { JwtHelperService } from '../../Utils/JwtHelperService';
import { InstrumentConstants } from '../../Utils/Constants/InstrumentConstants';
import { BarcodeType } from '../../Utils/Constants/BarcodeType';
import { logger } from '../../Utils/Logger';
import { UserConstants } from '../../Utils/Constants/UserConstants';
import { createInstrumentsCsv } from '../../Utils/Excel/exportCsv.service';


class InstrumentsProvider {

  /**
   * Get instrument by marquage from locale database
   *
   * @param {string} marquage - marquage of the instrument
   *
   * **/
  static getInstrumentByMarquage(marquage) {
    return db.instruments.where(InstrumentConstants.marquageDB).equals(marquage);
  }

  /**
   * Get instrument by barcode from locale database
   *
   * @param {string} barcode
   * @param {string} [barcodeType] - type of the barcode from Enum BarcodeType
   *
   * **/
  static getInstrumentByBarcode(barcode, barcodeType = BarcodeType.ALL) {
    switch (barcodeType) {
      case BarcodeType.IDCRIS:
        return db.instruments.where(InstrumentConstants.idCrisDB).equalsIgnoreCase(barcode).first();
      case BarcodeType.MARQUAGE:
        return db.instruments.where(InstrumentConstants.marquageDB).equalsIgnoreCase(barcode).first();
      case BarcodeType.OLDMARQUAGE:
        return db.instruments.where(InstrumentConstants.oldMarquageDB).equalsIgnoreCase(barcode).first();
      case BarcodeType.RFID:
        return db.instruments.where(InstrumentConstants.rfidDB).equalsIgnoreCase(barcode).first();
      case BarcodeType.ALL:
      default:
        return InstrumentsProvider.fetchInstrumentsByBarcode(barcode).first();
    }
  }

  /**
   * Get instruments by barcode from locale database
   *
   * @param {string} barcode
   *
   * **/
  static getInstrumentsByBarcode(barcode) {
    return InstrumentsProvider.fetchInstrumentsByBarcode(barcode);
  }

  static async fetchInstrumentsByBarcode(barcode) {
    // NOT WORKING ANYMORE WITH FIREFOX v67, waiting for a fix
    // return db.instruments
    //   .where(InstrumentConstants.marquageDB).equalsIgnoreCase(barcode)
    //   .or(InstrumentConstants.idCrisDB).equalsIgnoreCase(barcode)
    //   .or(InstrumentConstants.oldMarquageDB).equalsIgnoreCase(barcode);

    const getByMarquage = await db.instruments
      .where(InstrumentConstants.marquageDB).equalsIgnoreCase(barcode).first();

    const getByIdCris = await db.instruments
      .where(InstrumentConstants.idCrisDB).equalsIgnoreCase(barcode).first();

    const getByOldMarquage = await db.instruments
      .where(InstrumentConstants.oldMarquageDB).equalsIgnoreCase(barcode).first();
    // Wait for all requests to finish
    const results = await Promise.all([getByMarquage, getByIdCris, getByOldMarquage]);
    // Remove undefined value from results array
    const instruments = results.filter(e => e);
    return instruments;
  }

  /**
   * Get list of instruments by list of marquages from locale database
   *
   * @param {[string]} marquages - list of instruments marquages
   *
   * **/
  static getInstrumentsFromListOfMarquages(marquages) {
    return db.instruments.where(InstrumentConstants.marquageDB).anyOf(marquages).toArray();
  }

  /**
   * Create or replace an instrument update in locale database
   *
   * @param {object} newInstrumentsUpdate - custom data format
   *
   * @param {string} newInstrumentsUpdate.status
   * @param {string} newInstrumentsUpdate.date
   * @param {string} newInstrumentsUpdate.localisation
   * @param {string} newInstrumentsUpdate.utilisateur
   * @param {[string]} newInstrumentsUpdate.marquagesInstrument - list of instruments marquages
   *
   * **/
  static addOrReplaceInstrumentUpdate(newInstrumentsUpdate) {
    return db.instrumentsUpdates
      .put(newInstrumentsUpdate)
      .catch(logger);
  }

  /**
   * Update instrument in remote database
   *
   * @param {object} instrumentToUpdateData - custom data format for update
   *
   * @param {[string]} instrumentToUpdateData.instrumentsToUpdateMarquage - array of string marquages
   * @param {object} instrumentToUpdateData.instrumentsToUpdateFields
   * @param {string} instrumentToUpdateData.instrumentsToUpdateFields.utilisateur
   * @param {string} instrumentToUpdateData.instrumentsToUpdateFields.localisation
   * @param {string} instrumentToUpdateData.instrumentsToUpdateFields.identifiant3
   *
   */
  static async updateInstrument(instrumentToUpdateData) {
    return db.transaction('rw', db.instruments,
      () => {
        instrumentToUpdateData.instrumentsToUpdateMarquage.forEach(marquage => {
          db.instruments.where(InstrumentConstants.marquageDB)
            .equals(marquage)
            .modify(instrumentToUpdateData.instrumentsToUpdateFields);
        });
      }).then(() => Promise.resolve())
      .catch(() => Promise.reject());
  }

  /**
   * Return a with the favorite element if the instrument is favorite
   *
   * @param {string} marquage - marquage of the instrument to check
   *
   */
  static isInstrumentFavorite(marquage) {
    return db.favorites.where({ userLogin: JwtHelperService.getUserLogin(), instrumentMarquage: marquage })
      .first();
  }

  /**
   * Return all the user instruments
   */
  static getUserInstruments() {
    return db.usersInformations
      .where(UserConstants.userLoginDB)
      .equals(JwtHelperService.getUserLogin())
      .first().then(user => user.instruments);
  }

  /**
   *
   * @param {string[]} instMarkings
   * @return {Promise<String[]>}
   */
  static filterInstrumentsBelongingToUser(instMarkings) {
    return db.usersInformations
      .where(UserConstants.userLoginDB)
      .equals(JwtHelperService.getUserLogin())
      .first().then(user => {
        const notBelongingInstruments = [];
        const userInstrumentsArr = Array.from(user.instruments);
        instMarkings.forEach(marking => {
          if(!userInstrumentsArr.some(instMk => instMk === marking)) {
            notBelongingInstruments.push(marking);
          }
        });
        return notBelongingInstruments;
      });
  }

  /**
   * Return the logged user instrument count
   */
  static getUserInstrumentCount() {
    if (InstrumentsProvider.userInstrumentCount) {
      return Promise.resolve(InstrumentsProvider.userInstrumentCount);
    }

    return InstrumentsProvider.getUserInstruments().then(instruments => {
      InstrumentsProvider.userInstrumentCount = instruments.size;
      return InstrumentsProvider.userInstrumentCount;
    });
  }

  /**
   * Return the local database  instrument count
   */
  static getInstrumentCount() {
    return db.instruments.count();
  }

  /**
   * Build the dexie request to get instruments from database
   *
   * @param {[object]} userInstruments - list of the user instruments. If null, use all instruments in database
   * @param {object} filter - FilterProvider to use
   * @param {int} [offset] - the current offset in the list
   * @param {int} [limit] - max instrument count to get
   *
   */
  static buildGetInstrumentsRequest(userInstruments, filter, offset, limit) {
    let request = db.instruments;
    // if instrument list is provided
    if (userInstruments) {
      request = request.where(InstrumentConstants.marquageDB).anyOf(userInstruments);
    }
    else {
      if (filter) {
        request = filter.optimize(request);
      }
    }

    if (offset) {
      request = request.offset(offset);
    }
    if (limit) {
      request = request.limit(limit);
    }

    if (filter) {
      request = request.filter(filter.apply);
    }

    return request;
  }

  /**
   * Release the filter and resolve the value
   * @param {filter to release} filter
   * @param {value to resolve} value
   */
  static releaseAndResolve(value,filter) {
    if (filter) {
      filter.release();
    }
    return Promise.resolve(value);
  }

  /**
   * Get Filtered List Count
   *
   * @param {[object]} userInstruments - list of the user instruments. If null, use all instruments in database
   * @param {object} filter - FilterProvider to use
   *
   */
  static getFilteredListCount(userInstruments, filter) {
    return InstrumentsProvider.buildGetInstrumentsRequest(userInstruments, filter)
      .count().then(value => InstrumentsProvider.releaseAndResolve(value, filter));
  }

  /**
   * Get All instrument or filtered instrument
   *
   * @param userInstruments
   * @param filter
   * @returns {*}
   */
  static getUserAllInstrumentsFiltered(userInstruments, filter){
    return InstrumentsProvider.buildGetInstrumentsRequest(userInstruments, filter)
      .toArray().then(value => InstrumentsProvider.releaseAndResolve(value, filter));
  }

  static getUserAllInstruments(){
    return;
  }

  /**
   * Get More User Instruments
   *
   * @param {[object]} userInstruments - list of the user instruments. If null, use all instruments in database
   * @param {int | null} offset - the current offset in the list
   * @param {int | null} limit - max instrument count to get
   * @param {object} filter - FilterProvider to use
   *
   */
  static getMoreUserInstruments(userInstruments, offset, limit, filter) {
    return InstrumentsProvider.buildGetInstrumentsRequest(userInstruments, filter, offset, limit)
      .toArray().then(value => InstrumentsProvider.releaseAndResolve(value, filter));
  }

  /**
   * Return promise with the user favorites instruments marquages
   * promise fail if there is no favorites
   */
  static getUserFavorites() {
    return new Promise((resolve, reject) => {
      db.favorites.where(UserConstants.userLoginDB).equals(JwtHelperService.getUserLogin()).toArray(favoritesTable => {
        if (favoritesTable !== undefined) {
          resolve(favoritesTable.map(favorite => favorite.instrumentMarquage));
        } else {
          reject();
        }
      }).catch(logger);
    });
  }

  static getInstrumentsFromMarquages(marquages) {
    return db.instruments.where(InstrumentConstants.marquageDB).anyOf(marquages).toArray();
  }


  static getExportExcel = async (instruments) => {
    return createInstrumentsCsv(instruments);

  };
}

export default InstrumentsProvider;
