const { SheetRow } = require('./export.dt');
const xlsx = require('xlsx');

export function createInstrumentsCsv(instruments){
  const rows = instruments.map(instrument => generateRow(instrument));
  const wb = xlsx.utils.book_new();
  const ws = xlsx.utils.json_to_sheet(rows);
  xlsx.utils.book_append_sheet(wb, ws);
  return wb;
}


function generateRow(instrument){
  return new SheetRow(instrument);
}
