import React, { Component } from 'react';
import { Box, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { PlaylistAddCheck } from '@mui/icons-material';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import InstrumentList from '../../../Containers/Lists/InstrumentList/InstrumentList';
import { updateToolbarInfo } from '../../../store/actions/exportActions';
import { styles } from './InventoryListingPage.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { ToolbarActionsConstants } from '../../../Utils/Constants/ToolbarActionsConstants';
import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import { theme } from '../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    activeFilter: state.toolbarReducer.activeFilter,
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};

class InventoryListingPage extends Component {

  componentDidMount() {
    const toolbarInfo = {
      title: '',
      icon: null,
      actions: []
    };
    if (this.props.onMobile) {
      toolbarInfo.title = strings.inventory.inventoryList;
      toolbarInfo.icon = (<PlaylistAddCheck />);
      toolbarInfo.actions = [
        ToolbarActionsConstants.START_TUTORIAL, 
        ToolbarActionsConstants.OPEN_FILTER,
        ToolbarActionsConstants.FAVORITE_FILTER
      ];
    } 
    this.props.updateToolbarInfo(toolbarInfo);
  }

  render() {
    return (
      <Box component="div" sx={{...styles(theme).content}}>
        <Paper elevation={2} sx={{...styles(theme).paper}} id="inventoryListingContainer">
          <InstrumentList
            id="instrumentsList"
            headerFromViewType={true}
            viewType={globalConstants.viewTypes.inventory}
            onFiltering={this.props.onFiltering}
            styledTheme={styles(theme)}
          />
        </Paper>
      </Box>
    );
  }
}

InventoryListingPage.propTypes = {
  //region redux
  onMobile: PropTypes.bool,
  updateToolbarInfo: PropTypes.func.isRequired,
  //endregion
  
  onFiltering: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(InventoryListingPage);
