import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import ScanHeader from './ScanHeader/ScanHeader';
import ScanListHeader from './ScanListHeader/ScanListHeader';
import ScanList from './ScanList/ScanList';
import Scannable from './../Utils/Scannable/Scannable';
import { ScanPages } from '../../Utils/Constants/ScanPage';
import { ToastService } from '../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../Utils/Constants/ToastTypes';
import { ScanMethod } from '../../Utils/Constants/ScanMethod';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import UpdateInstrumentsDialog from '../Dialogs/UpdateInstrumentsDialog/UpdateInstrumentsDialog';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import sharedStyles from '../../Utils/SharedStyles';
import { mobileScanPageTheme, theme } from '../../Utils/Theme';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import ScanHelper from '../../Helpers/ScanHelper';
import { setInstrumentMarquageToRefresh } from '../../store/actions/exportActions';
import { Box } from '@mui/material';

const currentScanPage = ScanPages.SCAN;

// maps instrumentReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    shouldForceSync: state.generalReducer.shouldForceSync,
    shouldUpdateInstrumentList: state.instrumentReducer.shouldUpdateInstrumentList,
    instrumentMarquageToRefresh: state.instrumentReducer.instrumentMarquageToRefresh,
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    setInstrumentMarquageToRefresh: favorites => dispatch(setInstrumentMarquageToRefresh(favorites)),
  };
};

export class ScanPage extends Component {

  state = {
    scannedInstruments: [],
    currentScanMethod: ScanMethod.QUICKSCAN,
    editDialogOpen: false,
    isLoading: false,
  };

  async componentDidUpdate(prevProps) {
    if (this.props.instrumentMarquageToRefresh && this.props.instrumentMarquageToRefresh.length > 0) {
      const refreshedInstrument = await ScanHelper
        .verifyInstrument(this.props.instrumentMarquageToRefresh, false, true);
      const instruments = [...this.state.scannedInstruments];
      if (refreshedInstrument && refreshedInstrument.length > 0) {
        const instIndex = this.state.scannedInstruments
          .findIndex(inst => inst.marquage === this.props.instrumentMarquageToRefresh);
        instruments[instIndex] = refreshedInstrument[0];
        this.setState({
          scannedInstruments: instruments,
        }, () => {
          this.props.setInstrumentMarquageToRefresh(null);
        });
        // if the refreshed instrument is not found, it means the user no longer has access to it
      } else {
        this.setState({
          scannedInstruments: instruments.filter(inst => inst.marquage !== this.props.instrumentMarquageToRefresh),
        }, () => {
          this.props.setInstrumentMarquageToRefresh(null);
        });
      }
    }
    if (prevProps.lastScannedInstrument !== this.props.lastScannedInstrument && this.props.lastScannedInstrument) {
      this.setState(previousState => ({
        scannedInstruments: previousState.scannedInstruments.concat(this.props.lastScannedInstrument)
      }));
    }
  }

  scan = barcode => {
    if (!this.state.scannedInstruments.some(instr =>
      [instr.marquage, instr.ancienMarquage, instr.idCris].includes(barcode))) {
      this.setState({ isLoading: true });
      const data = { barcode, scanType: this.state.currentScanMethod, page: currentScanPage };
      // Scan will be created, user will be prompt with a dialog if an ambiguous case occurs
      this.props.processScan(data).then(() => {
        this.setState({ isLoading: false });
      });
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.duplicate);
    }
  };

  handleDelete = instrument => {
    this.setState(previousState => ({
      scannedInstruments: previousState.scannedInstruments.filter(instr => instr !== instrument)
    }));
  };

  deleteAllScans = () => {
    this.setState({ scannedInstruments: [] });
  };

  handleScanMethodChange = method => {
    this.setState({ currentScanMethod: method });
  };

  /**
   * update the fields localization and/or user of each instrument in the scanned instruments' list
   * according to the modifications from the UpdateInstrumentsDialog
   * @param {string|null} localization
   * @param {string|null} user
   */
  updateScannedInstrumentsFields = (user, localization) => {
    const scannedInst = [...this.state.scannedInstruments];
    scannedInst.forEach(inst => {
      if (localization != null && user != null) {
        inst.localisation = localization;
        inst.utilisateur = user;
      } else if (localization != null) {
        inst.localisation = localization;
      } else {
        // since there has to be at least one field to update
        // if the localization field has not been updated, then we have to update the user field
        inst.utilisateur = user;
      }
    });
  }

  render() {
    const styles = sharedStyles;
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mobileScanPageTheme}>
          <Box component="div" id="scanPage" sx={{...styles(theme).scanPage}}>
            <ScanHeader
              id="scanHeader"
              onScan={this.scan}
              onSelectScanMethod={this.handleScanMethodChange}
              currentScanMethod={this.state.currentScanMethod}
              onClose={this.props.onClose}
              onMobile={this.props.onMobile}
              currentPage={PAGES_IDS.SCAN}/>
            <ScanListHeader
              id="scanListHeader"
              count={this.state.scannedInstruments.length}
              onDeleteAll={this.deleteAllScans}
              onEditAll={() => this.setState(prevState => ({ editDialogOpen: prevState.scannedInstruments.length > 0 }))}
            />
            <ScanList
              id="scanList"
              instruments={this.state.scannedInstruments}
              onDelete={this.handleDelete}
              isLoading={this.state.isLoading}/>
          </Box>
          <UpdateInstrumentsDialog
            id="updateInstrumentsDialog"
            instrumentsToUpdate={this.state.scannedInstruments}
            onAccept={this.updateScannedInstrumentsFields}
            displayDialog={this.state.editDialogOpen}
            handleDialog={() => this.setState({ editDialogOpen: false })}/>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

}

ScanPage.propTypes = {
  lastScannedInstrument: PropTypes.object,
  processScan: PropTypes.func.isRequired,
  onMobile: PropTypes.bool,
  onClose: PropTypes.func,
  instrumentMarquageToRefresh: PropTypes.string,
  setInstrumentMarquageToRefresh: PropTypes.func,
};

export default compose(
  Scannable,
  connect(mapStateToProps, mapDispatchToProps),
)(ScanPage);
