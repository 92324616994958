import React, { Component } from 'react';
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from '@mui/material';
import { Help } from '@mui/icons-material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import {
  enableMultipleIntervention,
  setMultipleInterventions,
  setStatus,
  updateFormServiceShipping
} from '../../../../../store/actions/exportActions';
import InterventionRequestService from '../../../../../Providers/WebService/InterventionRequestService';
import { addBusinessDays } from '../../../../../Utils/DateUtils';
import { styles } from '../ServiceShippingStep.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import { InterventionRequestConstants as constants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import ResponsiveSelect from '../../../../Utils/ResponsiveSelect/ResponsiveSelect';
import PreferencesService from '../../../../../Providers/WebService/PreferencesService';
import InterventionRequestHelper from '../../../../../Helpers/InterventionRequestHelper';
import SimpleDialog from '../../../../Dialogs/SimpleDialog/SimpleDialog';
import { STATUSES } from '../../../../../Utils/Constants/GuideConstants';
import { theme } from '../../../../../Utils/Theme';
import merge from 'lodash/merge';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    multipleInterventionEnabled: state.interventionReducer.multipleInterventionEnabled,
    onMobile: state.generalReducer.onMobile,
    multipleInterventions: state.interventionReducer.multipleInterventions,
    activeStep: state.interventionReducer.activeStep,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    handleChange: (name, value) => dispatch(updateFormServiceShipping(name, value)),
    enableMultipleIntervention: () => dispatch(enableMultipleIntervention()),
    setMultipleInterventions: data => dispatch(setMultipleInterventions(data)),
    setStatus: status => dispatch(setStatus(status))
  };
};

class ServiceTypeCard extends Component {

  state = {
    types: [],
    currentPreferences: [],
    currentPreference: '',
    loading: true,
    dialogOpen: false
  };

  /** Get intervention types and load default intervention type */
  componentDidMount() {
    InterventionRequestService.getInterventionTypes().then(types => {
      this.setState({ types, loading: false }, () => {
        if (!this.props.fromPreferencesPage && !this.props.multipleInterventionEnabled) {
          const currentValue = types.find(e => e.id === constants.STANDARD_CHECK_ID);
          this.handleServiceTypeChanged(currentValue.id);
        }
      });
    });
  }

  shouldComponentUpdate(prevProps, prevState) {
    return (prevProps.serviceShippingPage.serviceType !== this.props.serviceShippingPage.serviceType)
      || prevState !== this.state
      || prevProps.multipleInterventionEnabled !== this.props.multipleInterventionEnabled
      || prevProps.multipleInterventions !== this.props.multipleInterventions
      || prevProps.activeStep.multipleInterventionIndex !== this.props.activeStep.multipleInterventionIndex;
  }

  /** Update return date accordingly to selected service type */
  handleServiceTypeChanged = async (value) => {
    const currentValue = parseInt(value, 10);
    const currentValueName = this.state.types.find(item => item.id === currentValue).name;
    this.props.handleChange(
      {
        name: constants.SERVICE_SHIPPING_PAGE_NAMES.SERVICE_TYPE_NAME,
        value: currentValueName
      }
    );
    this.props.handleChange({ name: constants.SERVICE_SHIPPING_PAGE_NAMES.SERVICE_TYPE, value: currentValue });
    if (currentValue === constants.STANDARD_CHECK_ID) {
      const newReturnDate =
        addBusinessDays(this.props.serviceShippingPage.shippingDate, constants.STANDARD_CHECK_DAYS_TO_ADD);
      this.props.handleChange(
        {
          name: constants.SERVICE_SHIPPING_PAGE_NAMES.RETURN_DATE,
          value: newReturnDate
        }
      );
    } else if (currentValue === constants.REPAIR_ID) {
      const newReturnDate = addBusinessDays(this.props.serviceShippingPage.shippingDate, constants.REPAIR_DAYS_TO_ADD);
      this.props.handleChange(
        {
          name: constants.SERVICE_SHIPPING_PAGE_NAMES.RETURN_DATE,
          value: newReturnDate
        }
      );
    }

    PreferencesService.getUserPreferences(currentValue).then(preferences => {
      this.setState({ currentPreferences: preferences });
    });

    // Check if instruments match for multiple intervention (if multiple intervention is enabled on user site)
    if (!this.props.multipleInterventionEnabled && this.props.multipleInterventionEnabledOnSite) {
      const matchedInstrumentGroups =
        await InterventionRequestHelper.instrumentsMatchInterventionType(
          this.props.selectedInstruments,
          currentValueName);
      if (matchedInstrumentGroups.length > 0) {
        // Stop guide 
        this.props.setStatus(STATUSES.SKIPPED);
        // Load multiple intervention data
        this.props.setMultipleInterventions(matchedInstrumentGroups);
        // Open confirmation modale
        this.setState({ dialogOpen: true });
      }
    }
  };

  handlePreferenceChanged = async pref => {
    if (!pref.target.value || pref.target.value.length === 0) {
      return;
    }
    this.props.setLoading(true);
    this.setState({ currentPreference: pref.target.value });

    const id = parseInt(pref.target.value, 10);
    const preferences = this.state.currentPreferences;
    const data = preferences.filter(p => p.id === id)[0];
    await InterventionRequestHelper.fillServicePageWithPreferences(
      data,
      this.props.multipleInterventionEnabled
      && this.props.multipleInterventions[this.props.activeStep.multipleInterventionIndex]?.provider?.id !== '');
    this.props.setLoading(false);
  };

  handleUndefinedProviderName = (intervention, index) => {
    if (intervention.provider.name) {
      return strings.interventionRequest.instrumentsGrouped(
        index + 1,
        intervention.provider.id !== ''
          ? intervention.provider.name
          : strings.interventionRequest.instrumentNotGrouped);
    }

    return strings.interventionRequest.instrumentGroupInactive(index + 1);
  }

  render() {
    const { serviceShippingPage } = this.props;

    return (
      <>
        <Paper elevation={2} id="serviceType" sx={{...styles(theme).container}}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{...styles(theme).area_title}} gutterBottom>
              {strings.interventionRequest.serviceTypeTitle}
            </Typography>
            {this.state.loading && <CircularProgress size={20}/>}
          </div>
          <Grid container spacing={this.props.onMobile ? 0 : 0.5}>
            <Grid item xs={12} sx={{...styles(theme).input_grid}}>
              <FormControl fullWidth required sx={{...styles(theme).formControl}} disabled={this.props.fromPreferencesPage}>
                <InputLabel
                  htmlFor={constants.SERVICE_SHIPPING_PAGE_NAMES.SERVICE_TYPE}
                  sx={{...merge({}, styles(theme).input_label, styles(theme).select_label)}}
                >
                  {strings.interventionRequest.serviceTypeLabel}
                </InputLabel>
                <ResponsiveSelect
                  id="serviceTypeSelect"
                  value={serviceShippingPage.serviceType}
                  onChange={e => this.handleServiceTypeChanged(e.target.value)}
                  name={constants.SERVICE_SHIPPING_PAGE_NAMES.SERVICE_TYPE}
                  data={this.state.types}
                  native={this.props.onMobile}
                  styledSelect={this.props.multipleInterventionEnabled
                    ? styles(theme).input_content_disabled
                    : styles(theme).input_content}
                  disabled={this.props.multipleInterventionEnabled}
                />
              </FormControl>
              {(this.state.currentPreferences.length > 0 && !this.props.fromPreferencesPage) && (
                <FormControl fullWidth sx={{...styles(theme).formControl}}>
                  <InputLabel
                    htmlFor={constants.SERVICE_SHIPPING_PAGE_NAMES.SERVICE_TYPE}
                    sx={{...merge({}, styles(theme).input_label, styles(theme).select_label)}}
                  >
                    {strings.interventionRequest.loadPreference}
                  </InputLabel>
                  <ResponsiveSelect
                    id="preferencesSelect"
                    value={this.state.currentPreference}
                    onChange={e => this.handlePreferenceChanged(e)}
                    name={constants.SERVICE_SHIPPING_PAGE_NAMES.SERVICE_TYPE}
                    data={this.state.currentPreferences.map(pref => {
                      return { ...pref, name: pref.saveName };
                    })}
                    native={this.props.onMobile}
                    styledSelect={styles(theme).input_content}
                    noDefaultValue
                  />
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Paper>
        {this.state.dialogOpen &&
        <SimpleDialog
          open={this.state.dialogOpen}
          title={strings.interventionRequest.interventionMultipleEnabling}
          acceptLabel={strings.dialog.answer.accept}
          refuseLabel={strings.dialog.answer.cancel}
          onRefuse={() => this.setState({ dialogOpen: false })}
          onAccept={() => {
            this.props.enableMultipleIntervention();
            this.setState({ dialogOpen: false });
          }}
          tooltip={strings.interventionRequest.interventionMultipleEnablingDesc}
          tooltipIcon={<Help/>}
          addContentPadding
        >
          <Typography>
            {strings.interventionRequest.interventionMultipleEnablingInterventionSelected}
            <strong>{this.props.serviceShippingPage.serviceTypeName}</strong> <br/>
          </Typography>
          {this.props.multipleInterventions?.map((intervention, index) =>
            <div key={index}>
              <Typography variant="subtitle2">
                <strong>
                  {this.handleUndefinedProviderName(intervention, index)}
                </strong>
              </Typography>
              <List>
                {intervention?.instruments.map(instrument =>
                  <ListItem key={instrument.marquage} divider dense>
                    <ListItemText primary={instrument.marquage}/>
                  </ListItem>
                )}
              </List>
            </div>
          )}
        </SimpleDialog>
        }
      </>
    );
  }
}

ServiceTypeCard.propTypes = {
  handleChange: PropTypes.func.isRequired,
  serviceShippingPage: PropTypes.object.isRequired,
  onMobile: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  fromPreferencesPage: PropTypes.bool,
  activeStep: PropTypes.shape({
    index: PropTypes.number,
    isValid: PropTypes.bool,
    multipleInterventionIndex: PropTypes.number,
  }),
  multipleInterventionEnabled: PropTypes.bool,
  multipleInterventions: PropTypes.array,
  multipleInterventionEnabledOnSite: PropTypes.bool,
  selectedInstruments: PropTypes.array,
  enableMultipleIntervention: PropTypes.func,
  setMultipleInterventions: PropTypes.func,
  setStatus: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ServiceTypeCard);
