import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  paperRounded: {
    borderRadius: theme.spacing(),
    marginTop: theme.spacing(3),
    [theme.breakpoints.only('xs')]: {
      marginTop: "0px",
      height: '100%'
    },
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)}`,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '90vh'
  },
  pageCard: {
    width: '100%',
    height: '91%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      borderRadius: "0px"
    },
  },
  contentCard: {
    padding: "0px",
    height: '92%',
    overflow: 'auto',
    backgroundColor: theme.palette.dialogTheme.background,
    '&:last-child': {
      paddingBottom: "0px",
      flex: 1,
    },
    [theme.breakpoints.only('xs')]: {
      overflowY: 'hidden',
    }
  },
});
