import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  platformsContainer: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  platform: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      flex: 1,
    },
  },
  platformIcon: {
    fontSize: '150px',
    color: theme.palette.primary.main,
    [theme.breakpoints.only('xs')]: {
      fontSize: '50px',
    },
  },
  divider: {
    marginTop: "4px",
    marginBottom: "4px"
  },
  bottomTextsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: "8px",
    },
  },
  textsWithIconsContainer: {
    paddingTop: "10px"
  },
  textWithIcon: {
    display: 'flex',
    alignItems: 'center'
  },
  textIcon: {
    marginRight: "4px"
  },
  title: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      fontSize: "15px"
    }
  }
});
