// HOW TO USE shared styles
// * Here: define a reusable custom class (like 'example')
// * In ...style.js files:
// Import this file,
// After defining your styles variable, merge its content with sharedStyles this way:
// export let styles = theme => ({
//  ...sharedStyles(theme),
// ####YOUR LOCAL CLASSES GO THERE, AS USUAL####
// * Then, in component file:
// <Button sx={{...styles(theme).example}}></Button>

const sharedStyles = theme => ({
  tabletTitleFontSize: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.75rem !important',
    }
  },
  tabletFontSize: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem !important',
    }
  },
  scan_sized: {
    [theme.breakpoints.only('xs')]: {
      minHeight: '68vh'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      minHeight: '78vh'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '82vh'
    },
    '@media (orientation: landscape)': {
      [theme.breakpoints.between('sm', 'lg')]: {
        minHeight: '70vh'
      },
    }
  },
  classic_sized: {
    [theme.breakpoints.only('xs')]: {
      minHeight: '72vh'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      minHeight: '82vh'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '86vh'
    },
    '@media (orientation: landscape)': {
      [theme.breakpoints.between('sm', 'lg')]: {
        minHeight: '74vh'
      },
    }
  },
  scanPage: {
    borderRadius: "5px 5px 0px 0px",
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  noBoxShadow: {
    boxShadow: 'unset!important'
  },
  hide: {
    display: 'none'
  },
  whiteColor: {
    color: `${theme.palette.secondary.main}!important`
  },
  whiteBackground: {
    backgroundColor: theme.palette.secondary.main
  },
  darkBlueColor: {
    color: theme.palette.MainMenu.pageTitles
  },
  bold: {
    fontWeight: 'bold'
  },
  fullHeight: {
    height: '100%'
  },

  /** For Instrument list on mobile **/
  secondaryInformation: {
    color: theme.palette.greyTheme.dark,
    marginRight: "8px"
  },
  designation: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2) - 2
    }
  },
  boldMarquage: {
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold'
    }
  },
  /** Shape of Paper for profile page **/
  paperShape: {
    borderRadius: theme.spacing(),
    margin: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
  },

  /** Styling for scan toasts **/
  toast_success: {
    background: theme.palette.toasts.success,
    fontSize: 14,
    color: theme.palette.secondary.main,
    justifyContent: 'center !important'
  },
  toast_warning: {
    background: theme.palette.toasts.warning,
    fontSize: 14,
    color: theme.palette.secondary.main,
    justifyContent: 'space-evenly !important'
  },
  toast_error: {
    background: theme.palette.toasts.error,
    fontSize: theme.spacing(2),
    color: theme.palette.secondary.main,
    justifyContent: 'space-evenly !important'
  },
  toast_content: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: "3px",
    '& p': {
      paddingLeft: theme.spacing(),
      textAlign: 'center'
    }
  },
  toast_message: {
    display: 'flex',
    flexDirection: 'row'
  },
  toast_text: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(),
    '& p': {
      margin: "2px"
    },
    color: theme.palette.secondary.main
  },
  /** Classes for Dialog (pop-up) **/
  smallDialog: {
    height: '70vh',
    maxHeight: '95%',
    [theme.breakpoints.down('md')]: {
      // landscape mobile
      width: '90vw'
    },
    [theme.breakpoints.up('sm')]: {
      // landscape mobile
      width: '50vw'
    },
    [theme.breakpoints.up('md')]: {
      // landscape tablet and desktop window
      width: '25vw'
    }
  },
  dialogTitle: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing()} ${theme.spacing(3)}`,
    }
  },
  dialogTitleAlternate: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing()} ${theme.spacing(3)}`,
    },
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  dialogContent: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden'
    }
  },
  rightPosition: {
    textAlign: 'right'
  },
  centerText: {
    textAlign: 'center'
  },
  /** Cards for InstrumentCard tabs or FilterCard **/
  cardHeader: {
    backgroundColor: theme.palette.dialogTheme.header,
    height: "32px",
    padding: `0px ${theme.spacing()} 0px ${theme.spacing(2)}`
  },
  tabletCardHeader:{
    [theme.breakpoints.up('lg')]: {
      height: '48px !important',
    }
  },
  cardContent: {
    padding: "0px",
    '&:last-child': {
      paddingBottom: "0px"
    }
  },
  iconCardHeader: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  tabletIconCardHeader: {
    [theme.breakpoints.up('lg')]: {
      width: `${theme.spacing(3)} !important`,
      height: `${theme.spacing(3)} !important`,
      marginLeft: `${theme.spacing(3)} !important`,
    }
  },
  titleCardHeader: {
    fontSize: 14,
    margin: theme.spacing(),
  },
  /** StaticField && DynamicField **/
  endTextAlign: {
    textAlign: 'right',
  },
  verticalAlign: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  textLabel: {
    fontSize: 13,
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.67,
    letterSpacing: 'normal',
    color: theme.palette.blackTheme.transparent,
    [theme.breakpoints.only('xs')]: {
      fontSize: '1rem'
    },
  },
  container: {
    margin: "0px",
    padding: theme.spacing(0.5)
  },
  smallFontSize: {
    fontSize: 12
  },
  alt_divider: {
    backgroundColor: theme.palette.orangeTheme.main,
    width: '96%',
    margin: '0 auto'
  },
  classic_divider: {
    backgroundColor: theme.palette.lightGrayTheme.main,
    width: '98%',
    margin: '0 auto',
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.intervention.mobile_divider,
    }
  },
  divider: {
    width: '98%',
    margin: 'auto'
  },
  instrumentsNbLabel: {
    fontSize: 16,
    [theme.breakpoints.only('md')]: {
      fontSize: 14,
    },
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.palette.blackTheme.transparent,
    fontWeight: 500,
    paddingBottom: "8px"
  },
  dot: {
    width: "24px",
    height: "24px",
    minHeight: 0,
    borderRadius: '50%',
    boxShadow: 'none',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: "24px",
    backgroundColor: 'var(--backgroundColor)!important',
    [theme.breakpoints.down('sm')]: {
      width: "16px",
      height: "16px",
    }
  },
  instrumentsNbLabel_alt: {
    marginLeft: '5%'
  },
  title_with_sub: {
    display: 'flex',
    flexDirection: 'column'
  },
  scrollbar_thumb: {
    backgroundColor: theme.palette.primary.main
  },
  area_title: {
    color: theme.palette.intervention.formText,
    fontSize: 18
  },
  input_grid: {
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  input_label: {
    color: theme.palette.intervention.inputLabel,
    fontStyle: 'italic'
  },
  select_label: {
    left: "-14px",
    top: "8px"
  },
  input_content: {
    color: theme.palette.intervention.formText,
    fontSize: "14px",
    borderColor: theme.palette.intervention.formText,
  },
  input_content_disabled: {
    color: theme.palette.intervention.formTextDisabled,
    fontSize: 14,
    borderColor: theme.palette.intervention.formText
  },

  // intervention theme variant
  intervention_bg_theme: {
    backgroundColor: `${theme.palette.orangeTheme.main}!important`
  },
  cart_step_toolbar: {
    [theme.breakpoints.only('xs')]: {
      boxShadow: 'none'
    }
  },

  // inventory theme variant
  inventory_bg_theme: {
    backgroundColor: `${theme.palette.greenTheme.main}!important`
  },

  // intervention request containers
  stepper_pages_paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    overflow: 'hidden',
    backgroundColor: theme.palette.lightGrayTheme.main,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)} 0px`,
      borderRadius: "0px"
    },
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.secondary.secondary,
      padding: "0px"
    }
  },
  stepper_pages_scrollArea: {
    height: '82vh'
  },
  ios_sizing: {
    height: '72vh!important'
  },
  // Toasts
  toastContainer: {
    padding: "0px",
    minHeight: "45px"
  },
  toastProgress: {
    height: "3px",
    background: theme.palette.pureWhite
  },

  // Historic Page
  downloadIcon: {
    marginTop: "5px"
  },

  // Instruments List Component
  centerElement: {
    display: 'flex',
    justifyContent: 'center'
  },
  // Creaton of Intervention Page
  cardTitle: {
    color: theme.palette.primary.main,
  },
  titleContainer: {
    padding: '16px 16px 0px'
  },
  // Used in Feedback page & StringDialog component 
  scrollArea: {
    minHeight: '40vh',
    height: 'calc(100% - 48px)',
    overflowY: 'visible'
  },
  menuAction: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: "0px",
      padding: theme.spacing(),
    }
  },
});

export default sharedStyles;
