export const globalConstants = {
  tooltipDelay: 500,
  mainMenuItems: {
    DEFAULT: 'default',
    INSTRUMENTS: 'instruments',
    INTERVENTIONS: 'interventions',
    INVENTORY: 'inventory',
  },
  style: {
    FULL_VIEWPORT_WIDTH: '100vw',
    FULL_VIEWPORT_HEIGHT: '100vh',
    FULL_PERCENTAGE: '100%',
    CENTER_MARGIN: '0 auto',
    DRAWER_WIDTH: 250,
    DRAWER_WIDTH_MOBILE: '68vw',
    CLOSED_DRAWER_WIDTH: 70,
    TABLET_CLOSED_DRAWER_WIDTH: 85
  },
  orientation: {
    landscape: 'landscape-primary',
    portrait: 'portrait-primary'
  },
  scan: {
    SUPPORTED_CHARACTERS: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-()',
    ENTER_KEY: 'Enter'
  },
  pathToDigitalTestBenchKey: 'pathToDigitalTestBench',
  externalFilterStorageKey: 'external_filter',
  externalFilterUrlKey: 'external-filter',
  viewTypes: {
    selection: 'selection',
    inventory: 'inventory'
  },
};
