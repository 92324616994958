import React, { Component } from 'react';
import { Box, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { PlaylistAddCheck } from '@mui/icons-material';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { styles } from './InventoryCheckingPage.style';
import ToVerifyItemTemplate from './ToVerify/ToVerifyItemTemplate';
import VerifiedItemTemplate from './Verified/VerifiedItemTemplate';
import ScanHeader from '../../Scan/ScanHeader/ScanHeader';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { ScanPages } from '../../../Utils/Constants/ScanPage';
import { ScanMethod } from '../../../Utils/Constants/ScanMethod';
import Scannable from '../../Utils/Scannable/Scannable';
import { ToastService } from '../../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../../Utils/Constants/ToastTypes';
import { InventoryStatus } from '../../../Utils/Constants/InventoryStatus';
import InstrumentCard from '../../InstrumentCard/InstrumentCard';
import { unlock, updateToolbarInfo } from '../../../store/actions/exportActions';
import { ToolbarActionsConstants } from '../../../Utils/Constants/ToolbarActionsConstants';
import VirtualizedList from '../../../Containers/Lists/VirtualizedList/VirtualizedList';
import PAGES_IDS from '../../../Utils/Constants/PagesIDs';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

const currentScanPage = ScanPages.INVENTORY;

export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
    unlock: () => dispatch(unlock())
  };
};

const itemHeight = 100;
const mobileItemHeight = 80;

export class InventoryCheckingPage extends Component {

  state = {
    currentScanMethod: ScanMethod.QUICKSCAN,
    instrumentCardOpen: false,
    selectedInstrument: {},
    tab: 0,
  };

  componentDidMount() {
    if (this.props.onMobile) {
      this.props.updateToolbarInfo({
        title: strings.inventory.inventoryTitle,
        icon: (<PlaylistAddCheck/>),
        actions: [ToolbarActionsConstants.EDIT_INSTRUMENTS]
      });
    } else {
      this.props.updateToolbarInfo({
        title: '',
        icon: (null),
        actions: []
      });
    }
  }

  componentWillUnmount() {
    if (this.props.onMobile) {
      this.props.updateToolbarInfo({
        title: strings.inventory.inventoryTitle,
        icon: (<PlaylistAddCheck/>),
        actions: []
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lastScannedInstrument !== this.props.lastScannedInstrument && this.props.lastScannedInstrument) {
      if (this.props.instruments.some(instr => this.props.lastScannedInstrument.marquage === instr.marquage)) {
        this.props.verifyInstrument(this.props.lastScannedInstrument, InventoryStatus.VALIDATED);
      } else {
        this.props.verifyInstrument(this.props.lastScannedInstrument, InventoryStatus.UNEXPECTED);
      }
    }

    if (prevProps.lastScannedOoBInstrument !== this.props.lastScannedOoBInstrument && this.props.lastScannedOoBInstrument) {
      this.props.verifyInstrument(this.props.lastScannedOoBInstrument, InventoryStatus.OUT_OF_BOUND);
    }
  }

  handleScan = barcode => {
    if (!this.props.verifiedInstruments.some(instr => [instr.marquage, instr.ancienMarquage, instr.idCris].includes(barcode))) {
      const data = { barcode, scanType: this.state.currentScanMethod, page: currentScanPage };
      // Scan will be created, user will be prompt with a dialog if an ambiguous case occurs
      this.props.processScan(data);
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.duplicate);
    }
  };

  handleScanMethodChange = method => {
    this.setState({ currentScanMethod: method });
  };

  addManually = instrument => {
    this.setState({ currentScanMethod: ScanMethod.QUICKSCAN },
      this.handleScan(instrument.marquage)
    );
  };

  onItemClick = instrument => {
    this.setState({ selectedInstrument: instrument, instrumentCardOpen: true });
  };

  closeInstrumentCard = () => {
    this.setState({ instrumentCardOpen: false });
  };

  handleChangeTab = (event, tab) => {
    this.setState({ tab });
    // If we're in a Guide and current step need to click on Verified Tabs
    this.props.unlock('#verifiedTab');
  };

  handleChangeIndex = (index, indexLatest, meta) => {
    if (meta && meta.reason === 'focus') {
      return;
    }
    this.setState({ tab: index });
  };

  rowRenderer = ({ index, _isScrolling, _key, style }) => {
    return (
      <ToVerifyItemTemplate
        style={style}
        key={this.props.instruments[index].marquage}
        onMobile={this.props.onMobile}
        item={this.props.instruments[index]}
        onAdd={this.addManually}
        onRemove={this.props.removeFromInventoryList}
        openInstrumentCard={this.onItemClick}
      />);
  };

  verifiedRowRenderer = ({ index, _isScrolling, _key, style }) => {
    return (
      <VerifiedItemTemplate
        style={style}
        key={this.props.verifiedInstruments[index].marquage}
        onMobile={this.props.onMobile}
        item={this.props.verifiedInstruments[index]}
        onRemove={this.props.removeFromVerifiedList}
        openInstrumentCard={this.onItemClick}
      />
    );
  };

  render() {
    const NB_OF_INSTRUMENTS = strings.interventionRequest.nbOfInstruments(this.props.verifiedInstruments.length);

    const leftPage = <Paper elevation={2} sx={{...styles(theme).paper}} id="toVerifyList">
      <Box component="div" sx={{...styles(theme).header}}>
        <Box component="div" sx={{...styles(theme).headerLeft}}>
          <Typography variant="h6" sx={{...styles(theme).title}}>{strings.inventory.toVerifyTitle}</Typography>
          <Typography variant="subtitle2">{strings.inventory.toVerifyLabel(this.props.instruments.length)}</Typography>
        </Box>
      </Box>
      <Divider sx={{...merge({}, styles(theme).inventory_bg_theme, styles(theme).divider)}}/>
      <Box component="div" sx={{...styles(theme).listContainer}}>
        <VirtualizedList
          rowRenderer={this.rowRenderer}
          count={this.props.instruments.length}
          rowHeight={this.props.onMobile ? mobileItemHeight : itemHeight}
        />
      </Box>
    </Paper>;

    const rightPage =
      <Paper elevation={2} sx={{...styles(theme).paper}}>
        <ScanHeader
          id="scanHeader"
          onScan={this.handleScan}
          onSelectScanMethod={this.handleScanMethodChange}
          currentScanMethod={this.state.currentScanMethod}
          alternativeStyling={!this.props.onMobile}
          underLabel={NB_OF_INSTRUMENTS}
          onMobile={this.props.onMobile}
          currentPage={PAGES_IDS.INVENTORY}
          title={!this.props.onMobile ? strings.inventory.verifiedTitle : null}
        />
        {!this.props.onMobile &&
        <Divider sx={{...merge({}, styles(theme).inventory_bg_theme, styles(theme).divider)}}/>}
        <Box component="div" sx={{...styles(theme).verifiedListContainer}}>
          <VirtualizedList
            rowRenderer={this.verifiedRowRenderer}
            count={this.props.verifiedInstruments.length}
            rowHeight={this.props.onMobile ? mobileItemHeight : itemHeight}
          />
        </Box>
      </Paper>;

    if (this.props.onMobile) {
      return (
        <>
          <Divider sx={{...styles(theme).classic_divider}}/>
          <Box component="div" sx={{...styles(theme).container}}>
            <AppBar position="static" style={{ backgroundColor: theme.palette.greenTheme.main }}>
              <Tabs
                id="tabs"
                value={this.state.tab}
                onChange={this.handleChangeTab}
                sx={{
                  "& .MuiTabs-indicator": {...styles(theme).tabsIndicator}
                }}
                variant="fullWidth"
              >
                <Tab id="toVerifyTab" label={strings.inventory.toVerifyTitle}/>
                <Tab id="verifiedTab" label={strings.inventory.verifiedTitle}/>
              </Tabs>
            </AppBar>
            <SwipeableViews
              id="swipes"
              containerStyle={{ height: '100%' }}
              axis='x'
              sx={{...styles(theme).fullHeight}}
              index={this.state.tab}
              onChangeIndex={this.handleChangeIndex}
            >
              {leftPage}
              {rightPage}
            </SwipeableViews>
          </Box>
        </>
      );
    } else {
      return (
        <Box component="div" id="inventoryCheckingContainer" sx={{...styles(theme).content}}>
          {leftPage}
          {rightPage}
          <InstrumentCard
            id="instrumentCard"
            open={this.state.instrumentCardOpen}
            instrument={this.state.selectedInstrument}
            onClose={this.closeInstrumentCard}/>
        </Box>
      );
    }
  }
}

InventoryCheckingPage.propTypes = {
  removeFromInventoryList: PropTypes.func.isRequired,
  instruments: PropTypes.array.isRequired,
  processScan: PropTypes.func.isRequired,
  lastScannedInstrument: PropTypes.object,
  verifiedInstruments: PropTypes.array,
  verifyInstrument: PropTypes.func.isRequired,
  removeFromVerifiedList: PropTypes.func.isRequired,
  lastScannedOoBInstrument: PropTypes.object,
  onMobile: PropTypes.bool,
  updateToolbarInfo: PropTypes.func.isRequired,
  unlock: PropTypes.func.isRequired,

};

export default compose(
  connect(null, mapDispatchToProps),
  Scannable)(InventoryCheckingPage);
