export let styles = () => ({
  root: {
    textAlign: 'center'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: "20px",
  },
  content: {
    display: 'flex',
    justifyContent: 'center'
  },
  rightPanel: {
    margin: "8px",
    padding: "8px",
    width: '25vw',
    height: '100%',
    minWidth: "300px"
  },
  infoPaper: {
    margin: "8px",
    padding: "8px",
    width: '100%',
    height: '100%'
  },
  validButton: {
    width: '100%',
    margin: "8px"
  },
  scanPaper: {
    margin: "8px",
    width: '30vw',
    minWidth: "400px",
    height: '80vh',
    display: 'flex',
    flexDirection: 'column'
  },
  instList: {
    overflowY: 'auto'
  },
  hiddenTooltip: {
    visibility: 'hidden'
  }

});
