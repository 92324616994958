import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, Typography } from '@mui/material';
import { CheckCircle, Warning, Error as ErrorIcon } from '@mui/icons-material';

import { styles } from './InstrumentAnswerItem.style';
import { InventoryStatus } from '../../../../Utils/Constants/InventoryStatus';
import { theme } from '../../../../Utils/Theme';

const InstrumentAnswerItem = props => {
  let iconStatus = <CheckCircle />;
  switch (props.instrument.inventoryStatus) {
    case InventoryStatus.VALIDATED:
      iconStatus = <CheckCircle style={{ color: theme.palette.validationGreenTheme.main }} />;
      break;
    case InventoryStatus.OUT_OF_BOUND:
      iconStatus = <Warning style={{ color: theme.palette.toasts.warning }} />;
      break;
    case InventoryStatus.UNEXPECTED:
      iconStatus = <Warning />;
      break;
    default:
      iconStatus = <ErrorIcon style={{ color: theme.palette.toasts.error }} />;
      break;
  }

  return (
    <ListItem sx={{ "&.MuiListItem-root": {...styles(theme).item} }} >
      {iconStatus}
      <ListItemText
      sx={{...styles(theme).listItemText}}
        disableTypography
        primary={
          <>
            <Typography variant="subtitle2" noWrap>{props.instrument.designation}</Typography>
          </>
        }
        secondary={
          <>
            <Typography variant="subtitle2" noWrap>{props.instrument.referenceConstructeur}</Typography>
            <Typography variant="subtitle2" noWrap>{props.instrument.marquage}</Typography>
          </>
        }
      />
    </ListItem>
  );
};

InstrumentAnswerItem.propTypes = {
  instrument: PropTypes.object.isRequired
};

export default InstrumentAnswerItem;
