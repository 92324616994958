export let styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden'
  }
});
