// REACT libraries
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI libraries
import { Typography, IconButton, Box } from '@mui/material';
import { styles } from './NotificationItem.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import InstrumentImage from '../../InstrumentCard/InstrumentImage/InstrumentImage';
import { Clear } from '@mui/icons-material';
import InstrumentHelper from '../../../Helpers/InstrumentHelper';
import StylizedField from '../../Utils/StylizedField/StylizedField';
import { theme } from '../../../Utils/Theme';

const NotificationItem = props => {
  return (
    <Box component="div" id="notificationContainer" sx={{...styles(theme).listItem}} onClick={props.onClick}>
      <Box component="div" sx={{...styles(theme).instrumentImage}}>
        <InstrumentImage instrument={props.notification.instrument} />
      </Box>
      <Box component="div" sx={{...styles(theme).informations}}>
        <Typography
          variant="h6"
          sx={{...styles(theme).marquage}}>
          {props.notification.marquage}
        </Typography>
        <Typography variant="body2"
          sx={{...styles(theme).otherInformation}}>
          {props.notification.date}
        </Typography>
        <div>
          <StylizedField
            field={props.notification.oldValue}
            color={InstrumentHelper.generateState(props.notification.instrument.etat, theme.palette)}
          />
          {strings.notifications.modification}
          <StylizedField
            field={props.notification.instrument[props.notification.field]}
            color={InstrumentHelper.generateState(props.notification.instrument.etat, theme.palette)}
          />
        </div>
      </Box>
      <IconButton
        sx={{...styles(theme).removeBtn}}
        onClick={event => props.onRemove(event, props.notification)}
        size="large">
        <Clear color="primary" />
      </IconButton>
    </Box>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default NotificationItem;
