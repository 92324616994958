import sharedStyles from '../../../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  textLabel: {
    ...sharedStyles(theme).textLabel,
    textAlign: 'left',
    fontSize: "14px"
  },
  dpsItem: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.secondary,
      cursor: 'pointer',
      borderRadius: "4px"
    }
  },
  container: {
    ...sharedStyles(theme).container,
    padding: "6px",
    [theme.breakpoints.only('xs')]: {
      marginBottom: "8px"
    },
  }
});
