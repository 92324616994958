import packagejson from '../../../package.json';
import HttpClient from '../HttpClient';
import routes from '../../Utils/Routes';

const notOk = 'ko';

class VersionService {

  static async getCurrentVersion() {
    return packagejson.version;
  }

  static async compareLocalAndRemoteVersion() {
    const localVersion = await VersionService.getCurrentVersion();
    return HttpClient.simpleGet(routes.checkVersionSync(localVersion));
  }

  static async compareVersions() {
    const areVersionsSynced = await VersionService.compareLocalAndRemoteVersion();
    return areVersionsSynced?.code === notOk;
  }

  static async emptyCacheVersionAndReload() {
    if (caches) {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    window.location.reload(true);
  }

}

export default VersionService;
