import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';

import { styles } from './ScanList.style';
import ScanItem from '../ScanItem/ScanItem';
import InstrumentCard from '../../InstrumentCard/InstrumentCard';
import LoadingSpinner from '../../Utils/LoadingSpinner/LoadingSpinner';
import { theme } from '../../../Utils/Theme';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

class ScanList extends Component {

  state = {
    isOpen: false,
    currentInstrument: {}
  };

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.instruments !== this.props.instruments) {
      const inst = this.props.instruments.find( inst => inst.marquage === this.state.currentInstrument.marquage);
      this.setState({
        currentInstrument: inst || {},
        isOpen: !!inst
      });
    }
  }

  buildList = instruments => {
    return instruments.map(instrument => {
      return <ScanItem
        key={instrument.marquage}
        instrument={instrument}
        onDelete={this.props.onDelete}
        onClick={item => this.setState({ isOpen: true, currentInstrument: item })}
      />;
    });
  };

  render() {
    return (
      <div id='scanListContainer' style={styles(theme).scanList}>
        <Scrollbar
          noScrollX
          style={{...merge({}, styles(theme).scrollArea, styles(theme).scan_sized)}}
          momentum
        >
          <Box component="div" sx={{...styles(theme).scanList}}>
            {this.buildList(this.props.instruments)}
          </Box>
          {this.props.isLoading && <LoadingSpinner/>}
        </Scrollbar>

        <InstrumentCard
          id="instrumentCard"
          open={this.state.isOpen}
          instrument={this.state.currentInstrument}
          onClose={() => this.setState({ isOpen: false })}
        />
      </div>
    );
  }
}

ScanList.propTypes = {
  instruments: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ScanList;
