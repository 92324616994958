import db from '../Database';
import { logger } from '../../Logger';

const Connection = db.connections.defineClass({
  serverId: Number,
  connectionHour: Date,
  deconnectionHour: Date,
  lastOperation: Date,
  utilizations: [Utilization]
});

function Utilization(serverId, beginning, end) {
  this.serverId = serverId;
  this.beginning = beginning;
  this.end = end;
}

Connection.prototype.setValues = function (serverId, connectionHour, deconnectionHour, lastOperation, utilizations) {
  this.serverId = serverId;
  this.connectionHour = connectionHour;
  this.deconnectionHour = deconnectionHour;
  this.lastOperation = lastOperation;
  this.utilizations = utilizations;
};

Connection.prototype.log = function () {
  logger(this);
};

Connection.prototype.save = function () {
  return db.connections.put(this);
};

export { Connection, Utilization };
