import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styles } from './DPSList.style';
import DPSItem from './DPSItem/DPSItem';
import Scrollbar from 'react-scrollbars-custom';
import { isIOS } from 'react-device-detect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { theme } from '../../../../../../Utils/Theme';
import merge from 'lodash/merge';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

class DPSList extends Component {

  render() {
    if(this.props.onMobile) {
      return (
        this.props.instruments.map(inst => {
          return (
            <DPSItem
              key={`inst-item-${inst.marquage}`}
              updateInstrumentsWithInterventions={this.props.updateInstrumentsWithInterventions}
              instrument={inst}
            />
          );
        })
      );
    } else {
      return (
        <Scrollbar
          noScrollX
          style={{...merge({}, styles(theme).scrollArea, (isIOS && styles(theme).classic_sized)) }}
        >
          {
            this.props.instruments.map(inst => {
              return (
                <DPSItem
                  key={`inst-item-${inst.marquage}`}
                  updateInstrumentsWithInterventions={this.props.updateInstrumentsWithInterventions}
                  instrument={inst}
                />
              );
            })
          }
        </Scrollbar>
      );
    }
    
  }
}

export default compose(connect(mapStateToProps, null))(DPSList);

DPSList.propTypes = {

  //region props from parent
  instruments: PropTypes.array.isRequired,
  updateInstrumentsWithInterventions: PropTypes.func,
  //endregion

  // props from redux
  onMobile: PropTypes.bool
};
