import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  datePickerWithText: {
    display: 'none'
  },
  colorIcon: {
    color: 'inherit'
  }
});
