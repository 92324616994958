import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  paper: {
    width: '48%',
    borderRadius: '8px 8px 0 0',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      borderRadius: "0px",
      height: '100%'
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '0 !important'
  },
  content: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: '8px 8px 0 8px',
    justifyContent: 'space-around',
  },
  listContainer: {
    flex: 1
  },
  verifiedListContainer: {
    display: 'flex',
    height: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '95%',
    margin: 'auto',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    paddingTop: "10px"
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },
  tabsIndicator: {
    backgroundColor: theme.palette.greenTheme.dark,
    height: "3px"
  },
  slideContainer: {
    height: '100%',
    // iOS momentum scrolling
    WebkitOverflowScrolling: 'touch'
  },
});
