import { LocalizedString as strings } from './LocalizedString';

const errorVariant = {variant : 'error' };
const successVariant = {variant : 'success' };
const warningVariant = {variant : 'warning' };
const infoVariant = {variant : 'info' };

const SnackbarMessages = {
  authError: { msg: strings.snackbars.authErrorMsg, type: errorVariant },
  authErrorServer: { msg: strings.snackbars.authErrorServerMsg, type: errorVariant },
  authOfflineError: { msg: strings.snackbars.authOfflineErrorMsg, type: errorVariant },
  networkError: { msg: strings.snackbars.networkErrorMsg, type: errorVariant },
  authSuccess: { msg: strings.snackbars.authSuccessMsg, type: successVariant },
  capacityError: { msg: strings.snackbars.capacityErrorMsg, type: errorVariant },
  expiredTokenError: { msg: strings.snackbars.expiredTokenErrorMsg, type: errorVariant },
  stepperValidationError: [
    { msg: strings.snackbars.noInstrumentsErrorMsg, type: errorVariant },
    { msg: strings.snackbars.allFieldsErrorMsg, type: errorVariant },
    { msg: strings.snackbars.noInstrumentsErrorMsg, type: errorVariant },
  ],
  interventionRequestError: { msg: strings.snackbars.interventionRequestErrorMsg, type: errorVariant },
  expiredSessionError: { msg: strings.snackbars.expiredSessionErrorMsg, type: errorVariant },
  feedbackError: { msg: strings.snackbars.feedbackError, type: errorVariant },
  feedbackSuccess: { msg: strings.snackbars.feedbackSuccess, type: successVariant },
  scanCreationError: { msg: strings.snackbars.scanCreationError, type: errorVariant },
  instrumentModificationSuccess: { msg: strings.snackbars.instrumentModificationSuccess, type: successVariant },
  instrumentSynchronizationSuccess: { msg: strings.snackbars.instrumentSynchronizationSuccess, type: successVariant },
  instrumentSynchronizationInfo: { msg: strings.snackbars.instrumentSynchronizationInfo, type: infoVariant },
  instrumentModificationWarning: { msg: strings.snackbars.instrumentModificationWarning, type: warningVariant },
  instrumentModificationError: { msg: strings.snackbars.instrumentModificationError, type: errorVariant },
  inventoryFiltering: { msg: strings.snackbars.inventoryFiltering, type: errorVariant },
  inventoryNeedFiltering: { msg: strings.snackbars.inventoryNeedFiltering, type: errorVariant },
  preferenceDuplicateName: { msg: strings.snackbars.preferenceDuplicateName, type: errorVariant },
  preferenceEmptyField: { msg: strings.snackbars.preferenceEmptyField, type: errorVariant },
  eligibleInstrumentsError: { msg: strings.snackbars.eligibleInstrumentsError, type: errorVariant },
  inventoryNeedToVerify: { msg: strings.snackbars.inventoryNeedToVerify, type: errorVariant },
  emptyLocation: { msg: strings.snackbars.emptyLocation, type: errorVariant },
  fileTooBig: { msg: strings.snackbars.fileTooBig, type: errorVariant },
  alreadyExistFile: { msg: strings.snackbars.alreadyExistFile, type: errorVariant },
  tooManyUploadedFiles: { msg: strings.snackbars.tooManyUploadedFiles, type: errorVariant },
  deleteInterventionSuccess: { msg: strings.snackbars.deleteInterventionSuccess, type: successVariant },
  updateInterventionSuccess: { msg: strings.snackbars.updateInterventionSuccess, type: successVariant },
  interventionUpdateError: { msg: strings.snackbars.interventionUpdateError, type: errorVariant },
  errorAddInstrumentsToIntervention: { msg: strings.snackbars.errorAddInstrumentsToIntervention, type: errorVariant },
  guideSkipped: { msg: strings.snackbars.guideSkipped, type: infoVariant },
  noLongerAccessToInstrument: { msg: strings.snackbars.noLongerAccessToInstrument, type: warningVariant },
  instrumentDownloadPrepare: { msg: strings.snackbars.downloadPrep, type: warningVariant },
  instrumentDownloadStart: { msg: strings.snackbars.downloadStart, type: successVariant },
  instrumentDownloadFailed: { msg: strings.snackbars.downloadFailed, type: errorVariant },
};

export { SnackbarMessages, successVariant, errorVariant, warningVariant };
