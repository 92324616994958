import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';
import { HttpConstants } from '../../Utils/Constants/HttpConstants';
import InterventionRequestProvider from '../Database/InterventionRequestProvider';
import { InterventionRequestConstants as constants } from '../../Utils/Constants/InterventionRequestConstants';

class InterventionRequestService {

  /** Get eligible instruments from remote database */
  static async getEligibleInstruments() {
    return HttpClient.get(routes.getEligibleInstruments)
      .then(res => Promise.resolve(res.data))
      .catch(error => {
        const statusCode = (error.error.response && error.error.response.status)
          ? error.error.response.status : HttpConstants.INTERNAL_SERVER_ERROR;
        return Promise.reject(statusCode);
      });
  }

  /** Create an intervention request on remote database */
  static async createInterventionRequest(interventionRequestData) {
    return HttpClient.post(routes.createInterventionRequest, interventionRequestData).then(res => {
      // Update local instruments
      if (!res.data?.instruments) {
        throw res.data;
      }
      InterventionRequestProvider.updateValidatedInstruments(res.data,
        interventionRequestData.dispatchProperties.dispatchProjected === constants.DISPATCH_NOW);
      return Promise.resolve(res.data);
    }).catch(error => {
      const errorMessage = error.message ?? HttpConstants.INTERNAL_SERVER_ERROR;
      return Promise.reject(errorMessage);
    });
  }

  /** Get service locations from remote database */
  static async getServiceLocations() {
    return HttpClient.get(routes.getServiceLocations).then(res => {
      // Only keep supported locations
      const filteredData = res.data.filter(item => constants.SUPPORTED_LOCATION_IDS.includes(item.id));
      return Promise.resolve(filteredData);
    }).catch(error => Promise.reject(error));
  }

  /** Get provider types from remote database */
  static async getProviderTypes() {
    return HttpClient.simpleGet(routes.getProviderTypes);
  }

  /** Get external providers from remote database */
  static async getExternalProviders() {
    return HttpClient.simpleGet(routes.getExternalProviders);
  }

  /** Get internal providers from remote database */
  static async getInternalProviders() {
    return HttpClient.simpleGet(routes.getInternalProviders);
  }

  /** Get intervention types from remote database */
  static async getInterventionTypes() {
    return HttpClient.get(routes.getInterventionTypes).then(res => {
      // Only keep items that are checked (from ADM Front Administration)
      const filteredData = res.data.filter(item => item.checked);
      return Promise.resolve(filteredData);
    }).catch(error => Promise.reject(error));
  }

  /** Get a provider details from remote database */
  static async getProviderDetails(id) {
    return HttpClient.simpleGet(routes.getProviderDetails + '/' + id);
  }

  /** Get carrier modes from remote database */
  static async getCarrierModes() {
    return HttpClient.simpleGet(routes.getCarrierModes);
  }

  /** Get carriers from remote database */
  static async getCarriers() {
    return HttpClient.simpleGet(routes.getCarriers);
  }

  /** Get shippers from remote database */
  static async getShippers() {
    return HttpClient.simpleGet(routes.getShippers);
  }

  /** Return true if Multiple Interventions feature is enabled for user's site */
  static isMultipleInterventionEnabled() {
    return HttpClient.simpleGet(routes.getMultipleEnabled);
  }
}

export default InterventionRequestService;
