import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  listItem: {
    margin: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `2px solid ${theme.palette.mainPageBackground}`
  },
  informations: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: theme.spacing(),
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    textOverflow: 'ellipsis'
  },
  instrumentImage: {
    width: "100px",
    height: "100px",
    minWidth: "75px",
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: "50px",
      height: "50px"
    }
  }
});
