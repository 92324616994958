import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useMediaQuery, useTheme } from '@mui/material';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';

import { updateFormServiceShipping } from '../../../../../store/actions/exportActions';
import InterventionRequestService from '../../../../../Providers/WebService/InterventionRequestService';
import { styles } from '../ServiceShippingStep.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import { InterventionRequestConstants as constants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import ResponsiveSelect from '../../../../Utils/ResponsiveSelect/ResponsiveSelect';
import ProvidersDialog from '../../../../Dialogs/ProvidersDialog/ProvidersDialog';
import SmartTextField from '../../../../Utils/SmartTextField/SmartTextField';
import { ValidatorRules } from '../../../../../Utils/Validator';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    onMobile: state.generalReducer.onMobile,
    multipleInterventionEnabled: state.interventionReducer.multipleInterventionEnabled,
    activeStep: state.interventionReducer.activeStep,
    multipleInterventions: state.interventionReducer.multipleInterventions,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    handleChange: (name, value) => dispatch(updateFormServiceShipping(name, value))
  };
};

export class ServiceCard extends Component {

  state = {
    open: false,
    locations: [],
    providerTypes: [],
    providers: [],
    loading: true
  };

  /** Load service locations, provider types and external recipient by default */
  async componentDidMount() {
    const locations = await InterventionRequestService.getServiceLocations();

    const providerTypes = await InterventionRequestService.getProviderTypes();

    const providers = await InterventionRequestService.getExternalProviders();

    this.setState({ locations, providerTypes, providers, loading: false });
  }

  async componentDidUpdate(_prevProps) {
    const { multipleInterventions, activeStep, serviceShippingPage } = this.props;

    const multipleInterventionActive = multipleInterventions[activeStep.multipleInterventionIndex];
    const multipleInterventionEnabled =
      this.props.multipleInterventionEnabled &&
      (multipleInterventionActive?.provider?.id !== '') &&
      multipleInterventionActive?.provider?.name;

    if (multipleInterventionEnabled && !this.state.loading &&
      (_prevProps.serviceShippingPage.providerAddress !== serviceShippingPage.providerAddress
        || _prevProps.serviceShippingPage.providerMail !== serviceShippingPage.providerMail)) {
      await this.generateAutoCompletionForm();
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    // Only update component if one of these fields change
    return (prevProps.serviceShippingPage.provider !== this.props.serviceShippingPage.provider)
      || (prevProps.serviceShippingPage.serviceLocation !== this.props.serviceShippingPage.serviceLocation)
      || (prevProps.serviceShippingPage.providerType !== this.props.serviceShippingPage.providerType)
      || (prevProps.serviceShippingPage.recipient !== this.props.serviceShippingPage.recipient)
      || (prevProps.serviceShippingPage.providerAddress !== this.props.serviceShippingPage.providerAddress)
      || (prevProps.serviceShippingPage.providerPhone !== this.props.serviceShippingPage.providerPhone)
      || (prevProps.serviceShippingPage.providerFax !== this.props.serviceShippingPage.providerFax)
      || (prevProps.serviceShippingPage.providerMail !== this.props.serviceShippingPage.providerMail)
      || (prevProps.submitted !== this.props.submitted)
      || (prevState !== this.state) || prevProps.multipleInterventionEnabled !== this.props.multipleInterventionEnabled
      || prevProps.activeStep.multipleInterventionIndex !== this.props.activeStep.multipleInterventionIndex;
  }

  openDialog = () => {
    const multipleInterventionActive =
      this.props.multipleInterventions[this.props.activeStep.multipleInterventionIndex];

    if (this.props.multipleInterventionEnabled
      && multipleInterventionActive?.provider?.id !== ''
      && multipleInterventionActive?.provider?.name) {
      return;
    }
    this.setState({ open: true });
  };

  /** Update provider informations after closing provider selection dialog */
  generateAutoCompletionForm = () => {
    this.setState({ open: false, loading: true });
    if (this.props.serviceShippingPage.provider.id && this.props.serviceShippingPage.provider.name) {
      InterventionRequestService.getProviderDetails(this.props.serviceShippingPage.provider.id)
        .then(details => {
          const { SERVICE_SHIPPING_PAGE_NAMES } = constants;
          const cleanedAddress = details.addresses[0].address.replace(/\r\n/g, ' ');
          this.updateProviderField(SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_PHONE, details.workPhone);
          this.updateProviderField(SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_FAX, details.fax);
          this.updateProviderField(SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_MAIL, details.emailAddress);
          this.updateProviderField(SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_ADDRESS, cleanedAddress);
        });
    }
    this.setState({ loading: false });
  };

  updateProviderField = (name, value) => {
    this.props.handleChange({ name, value });
  };

  /** Update provider informations after closing provider selection dialog */
  handleProviderTypeChange = value => {
    this.setState({ loading: true });
    this.props.handleChange({ name: constants.SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_TYPE, value: value });
    this.props.handleChange({ name: constants.SERVICE_SHIPPING_PAGE_NAMES.PROVIDER, value: { name: '', id: '' } });
    const providersPromise = (value === constants.PROVIDER_TYPE.EXTERNAL) ?
      InterventionRequestService.getExternalProviders() : InterventionRequestService.getInternalProviders();
    providersPromise.then(providers => {
      this.setState({ providers, loading: false });
    });
  };

  generateTextfieldItem = (name, label, value, isRequired = false, types = []) => {
    return (
      <Grid item xs={12} sx={{...styles(theme).input_grid}}>
        <SmartTextField
          id={name}
          name={name}
          label={label}
          onChange={e => this.props.handleChange({ name: e.target.name, value: e.target.value })}
          fullWidth
          value={value}
          required={isRequired}
          submitted={this.props.submitted}
          types={types}
          InputProps={{
            sx: {...styles(theme).input_content}
          }}
          InputLabelProps={{
            sx: {...styles(theme).input_label}
          }}
        />
      </Grid>);
  };

  render() {
    const { serviceShippingPage, activeStep } = this.props;
    const { SERVICE_SHIPPING_PAGE_NAMES } = constants;
    const multipleInterventionActive =
      this.props.multipleInterventions[activeStep.multipleInterventionIndex];
    const multipleInterventionEnabled =
      this.props.multipleInterventionEnabled &&
      (multipleInterventionActive?.provider?.id !== '') &&
      multipleInterventionActive?.provider?.name;

    return (
      <>
        <Paper elevation={2} id="service" sx={{...styles(theme).container}}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" sx={{...styles(theme).area_title}} gutterBottom>
              {strings.interventionRequest.serviceTitle}
            </Typography>
            {this.state.loading && <CircularProgress size={20} />}
          </div>
          <Grid container spacing={this.props.onMobile ? 0 : 0.5}>
            <Grid item xs={12} sx={{...styles(theme).input_grid}}>
              <FormControl fullWidth required sx={{...styles(theme).formControl}}>
                <InputLabel htmlFor={SERVICE_SHIPPING_PAGE_NAMES.SERVICE_LOCATION} sx={{...styles(theme).input_label, ...styles(theme).select_label}}>
                  {strings.interventionRequest.serviceLocationLabel}
                </InputLabel>
                <ResponsiveSelect
                  id="serviceLocationSelect"
                  value={serviceShippingPage.serviceLocation}
                  onChange={e => this.props.handleChange({ name: e.target.name, value: e.target.value })}
                  name={constants.SERVICE_SHIPPING_PAGE_NAMES.SERVICE_LOCATION}
                  data={this.state.locations}
                  native={this.props.onMobile}
                  styledSelect={styles(theme).input_content}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{...styles(theme).input_grid}}>

              <FormControl required fullWidth sx={{...styles(theme).formControl}}>
                <InputLabel htmlFor={SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_TYPE} sx={{...styles(theme).ut_label, ...styles(theme).select_label}}>
                  {strings.interventionRequest.providerTypeLabel}
                </InputLabel>
                <ResponsiveSelect
                  id="providerSelect"
                  value={serviceShippingPage.providerType}
                  onChange={e => this.handleProviderTypeChange(e.target.value)}
                  name={constants.SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_TYPE}
                  data={this.state.providerTypes}
                  native={this.props.onMobile}
                  styledSelect={multipleInterventionEnabled
                    ? styles(theme).input_content_disabled
                    : styles(theme).input_content}
                  disabled={multipleInterventionEnabled}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{...styles(theme).input_grid}}>
              <SmartTextField
                id="providerTextfield"
                required
                submitted={this.props.submitted}
                name={SERVICE_SHIPPING_PAGE_NAMES.PROVIDER}
                label={strings.interventionRequest.providerLabel}
                onClick={this.openDialog}
                fullWidth
                types={[ValidatorRules.NOT_EMPTY]}
                value={this.props.serviceShippingPage.provider.name}
                disabled={multipleInterventionEnabled}
                InputProps={{
                  sx: {...styles(theme).input_content}
                }}
                InputLabelProps={{
                  sx: {...styles(theme).input_label}
                }}
              />
            </Grid>
            {this.generateTextfieldItem(SERVICE_SHIPPING_PAGE_NAMES.RECIPIENT,
              strings.interventionRequest.recipientLabel, serviceShippingPage.recipient)}
            {this.generateTextfieldItem(SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_ADDRESS,
              strings.interventionRequest.addressLabel, serviceShippingPage.providerAddress,
              !this.props.fromPreferencesPage, [ValidatorRules.NOT_EMPTY])}
            {this.generateTextfieldItem(
              SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_PHONE,
              strings.interventionRequest.phoneLabel, serviceShippingPage.providerPhone)}
            {this.generateTextfieldItem(
              SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_FAX,
              strings.interventionRequest.faxLabel, serviceShippingPage.providerFax)}
            {this.generateTextfieldItem(
              SERVICE_SHIPPING_PAGE_NAMES.PROVIDER_MAIL,
              strings.interventionRequest.emailLabel, serviceShippingPage.providerMail,
              !this.props.fromPreferencesPage, [ValidatorRules.NOT_EMPTY, ValidatorRules.EMAIL])}
          </Grid>
        </Paper>

        {/* Display providers Dialog according to providers state */}
        <ProvidersDialog
          id="providersDialog"
          openDialog={this.state.open}
          onCloseDialog={this.generateAutoCompletionForm}
          data={this.state.providers}
          onValueSelected={value => {
            this.props.handleChange({ name: SERVICE_SHIPPING_PAGE_NAMES.PROVIDER, value: value });
          }}
        />

      </>
    );
  }
}

ServiceCard.propTypes = {
  handleChange: PropTypes.func.isRequired,
  serviceShippingPage: PropTypes.object.isRequired,
  onMobile: PropTypes.bool,
  fromPreferencesPage: PropTypes.bool,
  submitted: PropTypes.bool,
  activeStep: PropTypes.shape({
    index: PropTypes.number,
    isValid: PropTypes.bool,
    multipleInterventionIndex: PropTypes.number,
  }),
  multipleInterventionEnabled: PropTypes.bool,
  multipleInterventions: PropTypes.array,
};

function withResponsiveDialog() {
  return function(Component) {
    return function(props) {
      const themeUsed = useTheme();
      const fullScreen = useMediaQuery(themeUsed.breakpoints.down('md'));

      return <Component fullScreen={fullScreen} {...props} />;
    };
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withResponsiveDialog()
)(ServiceCard);
