// React libraries
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Material-UI libraries
import {
  Typography,
  Paper,
  Button,
  CircularProgress,
  Box
} from '@mui/material';
import {
  AccountCircle,
  PowerSettingsNew
} from '@mui/icons-material';
// Others libraries
import { styles } from './MainInformationProfile.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import ValidationDialog from '../../Dialogs/ValidationDialog/ValidationDialog';
import { AuthenticationHelper } from '../../../Helpers/AuthenticationHelper';
import { shouldRedirectToLogin } from '../../../store/actions/exportActions';
import { logger } from '../../../Utils/Logger';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

// maps interventionReducer's state to Component's props
const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    shouldRedirectToLogin: value => dispatch(shouldRedirectToLogin(value))
  };
};

class MainInformationProfile extends Component {
  state = {
    open: false,
    loading: false,
  };

  // Display the Logout Pop-up
  setDisplayPopUp = isOpen => {
    this.setState({ open: isOpen });
  };

  // Update Connection table
  // Synchronize a few tables
  // Remove access_token located in localStorage
  // Redirect to the LoginPage
  logoutUser = () => {
    // Close Pop-up and display a LoadingSpinner while syncing
    this.setState({ open: false, loading: true });
    AuthenticationHelper.logout(true, this.props.activeUser.userLogin)
      .then(() => {
        // Redirect to the LoginPage
        this.props.shouldRedirectToLogin(true);
      })
      .catch(logger);
  }

  // Separate the street number and the postal code
  separateAdress = address => {
    const fontSizeChange = this.props.onMobile ? 'body2' : 'body1';
    if (address) {
      const streetNumber = address.match(/[^ \n]*?(?= \d{5})/);
      const postalCode = address.match(/\d{5}?.*/);
      return (
        <>
          <Typography variant={fontSizeChange}>{streetNumber}</Typography>
          <Typography variant={fontSizeChange}>{postalCode}</Typography>
        </>
      );
    }

    return null;
  }

  render() {
    const { activeUser, onMobile } = this.props;
    const style = onMobile ? styles(theme).logoutIconOnMobile : styles(theme).logoutIcon;
    return (
      <Paper elevation={2} sx={{ "&.MuiPaper-root": {...styles(theme).paperShape}}}>
        <Typography
          id='name'
          variant={onMobile ? 'h6' : 'h5'}
          sx={{...styles(theme).username}}
        >
          <b>{activeUser.firstName + ' ' + activeUser.lastName}</b>
        </Typography>
        <Box component="div" sx={{...styles(theme).mainInformation}}>
          <Box component="div" sx={{...styles(theme).leftBloc}}>
            <Typography
              id='profile'
              variant={onMobile ? 'body1' : 'h6'}
              sx={{...merge({}, styles(theme).doubleSeparateText, styles(theme).profile)}}
            >
              {activeUser.profile}
            </Typography>
            <Typography
              variant='body1'
              sx={{...styles(theme).classicSeparateText}}
            >
              {activeUser.email}
            </Typography>
            {this.separateAdress(activeUser.address)}
          </Box>
          <Box component="div" sx={{...styles(theme).rightBlock}}>
            <Box component="div" sx={{...styles(theme).mainInformationProfileIcon}}>
              <div>
                <AccountCircle sx={{...styles(theme).userAvatar}} />
                <Typography
                  sx={{...styles(theme).userLogin}}
                >
                  {activeUser.userLogin}
                </Typography>
              </div>
            </Box>
            <Button
              variant={onMobile ? 'text' : 'outlined'}
              size="large"
              onClick={() => this.setDisplayPopUp(true)}
              sx={{...styles(theme).logoutButton}}
            >
              {this.state.loading ? <CircularProgress
                id="circularProgress-style"
                variant="indeterminate"
                disableShrink
                size={onMobile ? 40 : 24}
                thickness={4}
                sx={{...style}}
              />
                :
                <PowerSettingsNew id="icon-style" sx={{...style}} />}
              <b>{!onMobile && strings.profile.logout}</b>
            </Button>
          </Box>
        </Box>
        <ValidationDialog
          id='validationDialog'
          open={this.state.open}
          title={strings.dialog.title.logoutTitle}
          text={strings.dialog.content.logoutQuestion}
          refuseLabel={strings.dialog.answer.no}
          acceptLabel={strings.dialog.answer.yes}
          onRefuse={() => this.setDisplayPopUp(false)}
          onAccept={this.logoutUser}
        />
      </Paper>
    );
  }
}

MainInformationProfile.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  activeUser: PropTypes.object.isRequired,
  shouldRedirectToLogin: PropTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(MainInformationProfile);
