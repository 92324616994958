import AuthenticationService from '../Providers/WebService/AuthenticationService';
import HttpClient from '../Providers/HttpClient';
import db from '../Utils/Database/Database';
import DBUtils from '../Utils/Database/DBUtils';
import { Connection } from '../Utils/Database/Models/ConnectionModel';
import routes from '../Utils/Routes';
import { JwtHelperService } from '../Utils/JwtHelperService';
import SynchroProvider from '../Providers/SynchroProvider/SynchroProvider';
import { ConnectivityChecker } from '../Utils/ConnectivityChecker';
import UserProvider from '../Providers/Database/UserProvider';
import crypto from 'crypto';
import { logger } from '../Utils/Logger';
import ExternalFilterHelper from './ExternalFilterHelper';
import { CryptoConstants } from '../Utils/Constants/CryptoConstants';

const TokenStatus = {
  OK: 0,
  ACCESS_TOKEN_INVALID: 1,
  UNAUTHENTICATED: 2
};

const authToken = 'auth_token';
const authTokenParam = '?auth_token=';
const expirationParam = 'expiration=';

class AuthenticationHelper {

  static async authenticateUser(login, password) {
    const userLogin = login.trim();
    const userPassword = password.trim();
    const isConnected = await ConnectivityChecker.isConnected();
    if (isConnected) {
      return this.logOnline(userLogin, userPassword);
    } else {
      return this.logOffline(userLogin, userPassword);
    }
  }

  static async logOnline(login, password) {
    return AuthenticationService.authenticate(login, password).then(async token => {
      // Store Access Token and Refresh token in local storage
      AuthenticationHelper.saveTokens(token);
      HttpClient.defaults.headers.common['Authorization'] = `Bearer ${JwtHelperService.getToken()}`;
      return AuthenticationHelper.manageConnection();
    });
  }

  static async logOffline(login, password) {
    const hash = crypto.createHash(CryptoConstants.HASH);
    hash.update(password);
    const encryptedPassword = hash.digest('base64');
    return UserProvider.checkLocalUserCredentials(login, encryptedPassword);
  }

  static manageConnection() {
    const dateTime = DBUtils.getCurrentDateTime();

    // set deconnectionHour to the last connections
    db.connections.where('deconnectionHour')
      .equals(DBUtils.getNull()).modify(value => value.deconnectionHour = dateTime);

    // get last co from server
    return HttpClient.get(routes.lastConnection).then(res => {
      // store connection info in local database
      const connection = new Connection();
      connection.setValues(res.data.id, dateTime, DBUtils.getNull(), dateTime, []);
      connection.save();
      Promise.resolve();
    }).catch(logger);
  }

  static saveTokens(token) {
    localStorage.setItem(JwtHelperService.STORAGE_ACCESS_TOKEN_KEY, token.access_token);
    localStorage.setItem(JwtHelperService.STORAGE_ACCESS_TOKEN_EXP_KEY, token.access_token_exp);
    localStorage.setItem(JwtHelperService.STORAGE_REFRESH_TOKEN_KEY, token.refresh_token);
    localStorage.setItem(JwtHelperService.STORAGE_REFRESH_TOKEN_EXP_KEY, token.refresh_token_exp);
  }

  static checkTokenValidity() {
    const today = new Date();
    let accessTokenExp = localStorage.getItem(JwtHelperService.STORAGE_ACCESS_TOKEN_EXP_KEY);
    accessTokenExp = new Date(accessTokenExp);
    let refreshTokenExp = localStorage.getItem(JwtHelperService.STORAGE_REFRESH_TOKEN_EXP_KEY);
    refreshTokenExp = new Date(refreshTokenExp);

    if (today >= refreshTokenExp) {
      return TokenStatus.UNAUTHENTICATED;
    }

    if (today >= accessTokenExp) {
      return TokenStatus.ACCESS_TOKEN_INVALID;
    }

    return TokenStatus.OK;
  }

  static refreshAccessToken() {
    const oldToken = localStorage.getItem(JwtHelperService.STORAGE_ACCESS_TOKEN_KEY);
    const refreshToken = localStorage.getItem(JwtHelperService.STORAGE_REFRESH_TOKEN_KEY);
    return AuthenticationService
      .getRefreshedAccessToken(refreshToken, oldToken)
      .then(newToken => {
        AuthenticationHelper.saveTokens(newToken);
        return Promise.resolve(newToken.access_token);
      })
      .catch(e => Promise.reject(e));
  }

  static async logout(withSynchro = true, userLogin) {
    if (JwtHelperService.isUserOffline()) {
      JwtHelperService.removeOfflineUserData();
    } else {
      const isConnected = await ConnectivityChecker.isConnected();
      const synchroProvider = new SynchroProvider();

      try {

        // Find the last connection
        const lastConnection = await db.connections
          .where('deconnectionHour')
          .equals(DBUtils.getNull())
          .first();

        const utilizations = lastConnection?.utilizations.map(utilization => {
          // Find the one which the field "end" equals null
          if (utilization.end === null) {
            // Save the current date in the field "end" for this utilization
            const currentUtilization = {
              id: utilization.serverId,
              beginning: utilization.beginning,
              end: DBUtils.getCurrentDateTime()
            };
            return currentUtilization;
          }
          // Else no modification
          return utilization;
        });

        await db.connections
          .where('deconnectionHour')
          .equals(DBUtils.getNull())
          .modify({ utilizations, deconnectionHour: DBUtils.getCurrentDateTime() });
      } catch (err) {
        logger(err);
      }

      if (withSynchro && isConnected && userLogin) {
        try {
          await synchroProvider.syncConnections();
          await synchroProvider.syncScans(userLogin);
          await synchroProvider.syncInterventions();
        } catch (err) {
          logger(err);
        }
      }

      // remove external filter if set
      ExternalFilterHelper.removeExternalFilter();
      AuthenticationHelper.removeTokens();
    }
  }

  static removeTokens() {
    localStorage.removeItem(JwtHelperService.STORAGE_ACCESS_TOKEN_KEY);
    localStorage.removeItem(JwtHelperService.STORAGE_ACCESS_TOKEN_EXP_KEY);
    localStorage.removeItem(JwtHelperService.STORAGE_LOGIN_TYPE_KEY);
    localStorage.removeItem(JwtHelperService.STORAGE_REFRESH_TOKEN_KEY);
    localStorage.removeItem(JwtHelperService.STORAGE_REFRESH_TOKEN_EXP_KEY);
    localStorage.removeItem(JwtHelperService.STORAGE_URL_DTB);
  }

  static ssoRedirectMiddleware(location) {
    if (location && location.search && location.search.length > 0 && location.search.includes(authToken)) {
      const url = location.search;
      const values = url.split('&');
      const token = values[0].replace(authTokenParam, '');
      const expiration = values[1].replace(expirationParam, '');

      HttpClient.setToken(token, JwtHelperService.EXTERNAL_SSO_THALES_LOGIN_TYPE, expiration);
      return true;
    }
    return false;
  }

}

export { AuthenticationHelper, TokenStatus };
