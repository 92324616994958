import React from 'react';
import PropTypes from 'prop-types';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { Warning } from '@mui/icons-material';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';

export const ScanAlertDialog = props => {

  return (
    <SimpleDialog
      icon={<Warning />}
      open={props.open}
      title={props.messageScan.title}
      refuseLabel={strings.general.ok}
      onRefuse={props.onRefuse}
      addContentPadding
    >
      {props.messageScan.message}
    </SimpleDialog>
  );

};

ScanAlertDialog.propTypes = {
  open: PropTypes.bool,
  messageScan: PropTypes.object,
  onRefuse: PropTypes.func.isRequired,
};

ScanAlertDialog.defaultProps = {
  messageScan: {
    title: '',
    message: ''
  }
};

export default ScanAlertDialog;
