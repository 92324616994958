import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  container: {
    display: 'flex',
    height: '100%'
  },
  progressContainer: {
    flex: '0 0 60%',
    margin: 'auto'
  }
});
