import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { DevicesOther } from '@mui/icons-material';
import InstrumentList from '../../Containers/Lists/InstrumentList/InstrumentList';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { resetFilters, updateToolbarInfo } from '../../store/actions/exportActions';
import ScanButton from '../MainPage/ScanButton/ScanButton';
import { withGuide } from '../Guides/Guidable';

// maps toolbarReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
    resetFilters: () => dispatch(resetFilters())
  };
};

class InstrumentsPage extends Component {

  //region REACT LIFECYCLE METHODS
  componentDidMount() {
    this.props.updateToolbarInfo({
      title: strings.mainMenu.instruments,
      icon: (<DevicesOther />),
      actions: [
        ToolbarActionsConstants.FAVORITE_FILTER,
        ToolbarActionsConstants.FILTER,
        ToolbarActionsConstants.OPEN_FILTER,
        ToolbarActionsConstants.START_TUTORIAL]
    });
  }

  componentWillUnmount() {
    this.props.resetFilters();
  }
  //endregion

  render() {
    return (
      <>
        <InstrumentList />
        {!this.props.onMobile && <ScanButton handleScanButtonClick={this.props.handleScanButtonClick} />}
      </>
    );
  }
}

InstrumentsPage.propTypes = {
  //region props from redux
  onMobile: PropTypes.bool.isRequired,
  updateToolbarInfo: PropTypes.func,
  resetFilters: PropTypes.func,
  //endregion

  //region props from parent
  handleScanButtonClick: PropTypes.func.isRequired
  //endregion
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withGuide)(InstrumentsPage);
