// REACT library
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Material-UI libraries
import sharedStyles from '../../../../Utils/SharedStyles';
import LocalOffer from '@mui/icons-material/LocalOffer';
import { Typography, CardHeader } from '@mui/material';
// Components Libraries
import StaticField from '../../StaticField/StaticField';
// Utils libraries
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { isNotComputer } from '../../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../../Utils/Theme';
import merge from 'lodash/merge';

class InstrumentCardIdentification extends Component {
  render() {
    const styles = sharedStyles;
    return (
      <>
        {this.props.showTitle &&
          <CardHeader
            sx={{...merge({}, styles(theme).cardHeader,
              (isNotComputer && styles(theme).tabletCardHeader))}}
            title={
              <Typography color="secondary"
                sx={{...merge({}, styles(theme).titleCardHeader,
                  (isNotComputer && styles(theme).tabletTitleFontSize))}}>
                {strings.instrumentCard.identification}
              </Typography>
            }
            avatar={<LocalOffer color="secondary"
              sx={{...merge({}, styles(theme).iconCardHeader,
                (isNotComputer && styles(theme).tabletIconCardHeader))}} />}
          />
        }
        <StaticField
          sx={{...styles(theme).FirstStaticField}}
          name={strings.instrumentCard.idContent.marquage}
          value={this.props.instrument.marquage} />
        <StaticField
          name={strings.instrumentCard.idContent.designation}
          value={this.props.instrument.designation} />
        <StaticField
          name={strings.instrumentCard.idContent.marque}
          value={this.props.instrument.constructeur} />
        <StaticField
          name={strings.instrumentCard.idContent.modele}
          value={this.props.instrument.referenceConstructeur} />
        <StaticField
          name={strings.instrumentCard.idContent.numeroDeSerie}
          value={this.props.instrument.numeroSerie} />
        <StaticField
          name={strings.instrumentCard.idContent.options}
          value={this.props.instrument.options} />
        <StaticField
          name={strings.instrumentCard.idContent.ancienMarquage}
          value={this.props.instrument.ancienMarquage} />
        <StaticField
          name={strings.instrumentCard.idContent.idCris}
          value={this.props.instrument.idCris} />
        <StaticField
          name={strings.instrumentCard.idContent.rfid}
          value={this.props.instrument.rfid} />
        <StaticField
          name={strings.instrumentCard.idContent.observation}
          value={this.props.instrument.application} />
      </>
    );
  }
}

InstrumentCardIdentification.propTypes = {
  instrument: PropTypes.object,
  showTitle: PropTypes.bool
};

export default InstrumentCardIdentification;
