import sharedStyles from './../../Utils/SharedStyles';
import { globalConstants } from '../../Utils/Constants/GlobalConstants';

const {
  DRAWER_WIDTH,
  DRAWER_WIDTH_MOBILE,
  CLOSED_DRAWER_WIDTH
} = globalConstants.style;

export let styles = theme => ({
  ...sharedStyles(theme),
  menuButton: {
    marginLeft: "12px",
    marginRight: "36px",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  drawerOpen: {
    height: '100%',
    overflow: 'hidden',
    width: DRAWER_WIDTH,
    padding: `0px ${theme.spacing()}`,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.MainMenu.menu,
    borderTopRightRadius: "24px",
    [theme.breakpoints.only('xs')]: {
      borderTopRightRadius: "0px",
      width: DRAWER_WIDTH_MOBILE
    },
  },
  drawerClose: {
    width: CLOSED_DRAWER_WIDTH,
    backgroundColor: theme.palette.MainMenu.menu,
    height: '100%',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      padding: '0 5px',
      borderTopRightRadius: "24px"
    },
    [theme.breakpoints.only('xs')]: {
      width: "0px",
      backgroundColor: 'transparent'
    },
  },
  tabletDrawerClose:{
    [theme.breakpoints.up('lg')]: {
      width: '85px !important',
    }
  },
  itemIconClosed:{
    [theme.breakpoints.up('lg')]: {
      paddingLeft: "6px",
    }
  },
  tabletIconSize:{
    [theme.breakpoints.up('lg')]: {
      fontSize: '30px !important',
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 4px',
    ...theme.mixins.toolbar,
  },
  topContent: {
    backgroundColor: theme.palette.MainMenu.topMenu,
    height: "200px",
    color: theme.palette.secondary.main,
    margin: `0px -${parseInt(theme.spacing(), 10) + 2}px`,
    padding: `0px ${parseInt(theme.spacing(), 10) + 2}px`
  },
  userInfo: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'var(--align-items)'
  },
  tabletListItemFontSize: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.20rem !important',
      
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  userAvatar: {
    width: "40px",
    height: "40px",
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.MainMenu.topMenu,
    border: '1px solid rgba(255,255,255,0.2)'
  },
  userAvatarOpen: {
    marginTop: "0px",
  },
  userAvatarClose: {
    marginTop: "8px",
    marginBottom: "8px",
  },
  defaultBtn: {
    backgroundColor: 'var(--background-color)!important',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: theme.spacing()
  },
  divider: {
    backgroundColor: '#979797'
  },
  menuItem: {
    '&:hover': {
      borderRadius: theme.spacing()
    }
  },
  userMainMenuBorder: {
    border: '1px solid',
    borderRadius: theme.spacing(),
    backgroundColor: theme.palette.MainMenu.otherMenuOptions
  },
  userButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  badge: {
    backgroundColor: theme.palette.redTheme.main
  },
  scrollArea: {
    height: '100%',
    overflowY: 'auto'
  }
});

