import React from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Clear, CheckCircle, Warning } from '@mui/icons-material';

import { styles } from '../ItemTemplate.style';
import { InventoryStatus } from '../../../../Utils/Constants/InventoryStatus';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { theme } from '../../../../Utils/Theme';

const VerifiedItemTemplate = props => {

  let iconStatus = <CheckCircle />;
  let labelStatus = '';
  switch (props.item.inventoryStatus) {
    case InventoryStatus.VALIDATED:
      iconStatus = <CheckCircle style={{ color: theme.palette.validationGreenTheme.main }} />;
      labelStatus = strings.inventory.itemValidated;
      break;
    case InventoryStatus.OUT_OF_BOUND:
      iconStatus = <Warning style={{ color: theme.palette.toasts.warning }} />;
      labelStatus = strings.inventory.itemOoB;
      break;
    case InventoryStatus.UNEXPECTED:
    default:
      iconStatus = <Warning />;
      labelStatus = strings.inventory.itemUnexpected;
  }

  const variant = props.onMobile ? 'body2' : 'subtitle2';

  return (
    <Box component="div" id="verifiedItem" sx={{...styles(theme).item}} onClick={() => props.openInstrumentCard(props.item)} style={props.style}>
      <Box component="div" sx={{...styles(theme).leftContainer}}>
        {iconStatus}
        {labelStatus}
      </Box>
      <Box component="div" sx={{...styles(theme).middleContainer}}>
        <Typography variant={variant} noWrap>{props.item.designation}</Typography>
        <Typography variant={variant} noWrap>{props.item.referenceConstructeur}</Typography>
        <Typography variant={variant} noWrap>{props.item.marquage}</Typography>
        {!props.onMobile && (
          <Typography variant="subtitle2">{props.item.constructeur}</Typography>)}
      </Box>
      <Box component="div" sx={{...styles(theme).rightContainer}}>
        <IconButton
          id="removeButton"
          sx={{ "&.MuiIconButton-root": {...styles(theme).actionButton} }}
          onClick={e => {
            e.stopPropagation();
            props.onRemove(props.item);
          }}
          size="large">
          <Clear />
        </IconButton>
      </Box>
    </Box >
  );
};

VerifiedItemTemplate.propTypes = {
  item: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  openInstrumentCard: PropTypes.func,
  style: PropTypes.object,
  onMobile: PropTypes.bool
};

export default VerifiedItemTemplate;
