import sharedStyles from '../../../Utils/SharedStyles';

export const styles = theme => ({
  ...sharedStyles(theme),
  textfields_container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: "16px"
  },
  headerIcon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
});
