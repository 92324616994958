import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { styles } from './DocumentsRepository.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, TextField, Typography } from '@mui/material';
import { Add, Link, PictureAsPdf } from '@mui/icons-material';
import { SnackbarMessages } from '../../../../../Utils/Constants/SnackbarMessages';
import InterventionConstants from '../../../../../Utils/Constants/InterventionConstants';
import SimpleDialog from '../../../../Dialogs/SimpleDialog/SimpleDialog';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

const maxDocCharacters = 16;

// Link attributes
const nameAttr = 'name';
const urlAttr = 'linkUrl';

class DocumentsRepository extends Component {

  constructor(props) {
    super(props);
    this.state = {
      linkDialogIsOpen: false,
      currentLink: {
        name: '',
        linkUrl: ''
      },
      currentLinkList: [],
      errMsg: '',
      inputHasError: false,
    };
    this.pickerRef = React.createRef();
  }

  openPicker = e => {
    if (this.pickerRef.current) {
      this.pickerRef.current.click(e);
    }
  };

  openDialogLink = () => {
    this.setState({ linkDialogIsOpen: true });
  };

  getUploadedFiles = e => {
    const filesList = e.target.files;
    this.fileChecker(filesList);
  };

  onValidate = () => {
    if (!this.state.currentLink.name || !this.state.currentLink.linkUrl) {
      this.setState({
        inputHasError: true,
        errMsg: strings.intervention.documentRepository.fieldsCannotBeEmpty,
      });
    } else if (this.props.documents.some(doc => doc.name === this.state.currentLink.name)) {
      this.setState({
        inputHasError: true,
        errMsg: strings.intervention.documentRepository.noDuplicateName,
      });
    } else {
      this.props.update(InterventionConstants.CONCLUSIONCARD.DOCUMENTS, this.state.currentLink, true);
      this.setState(state => ({
        currentLinkList: [...state.currentLinkList, state.currentLink],
        linkDialogIsOpen: false,
        currentLink: '',
        inputHasError: false
      }));
    }
  };


  fileChecker = list => {
    for (let i = 0; i < list.length; i++) {
      const nbOfDocumentsWithSameName = this.props.documents.filter(doc => doc.name === list[i].name).length;
      if (InterventionConstants.CONCLUSIONCARD.MAX_FILES_NUMBER > this.props.documents.length + list.length - 1) {
        if (nbOfDocumentsWithSameName > 0) {
          enqueueSnackbar(SnackbarMessages.alreadyExistFile.msg(list[i].name),
            SnackbarMessages.alreadyExistFile.type);
        } else if (list[i].size < InterventionConstants.CONCLUSIONCARD.FILE_MAX_SIZE) {
          this.props.update(InterventionConstants.CONCLUSIONCARD.DOCUMENTS, list[i], true);
        } else {
          enqueueSnackbar(SnackbarMessages.fileTooBig.msg(list[i].name), SnackbarMessages.fileTooBig.type);
        }
      } else {
        enqueueSnackbar(SnackbarMessages.tooManyUploadedFiles.msg,
          SnackbarMessages.tooManyUploadedFiles.type);
        return;
      }
    }
  };

  removeChip = file => {
    const files = [...this.props.documents];
    const index = files.indexOf(file);
    // Check the file to remove
    if (index > -1) {
      files.splice(index, 1);
    }
    this.props.update(InterventionConstants.CONCLUSIONCARD.DOCUMENTS, files);
  };

  handleLinkChange = (attribute, value) => {
    const currentLink = { ...this.state.currentLink };
    currentLink[attribute] = value;
    this.setState({ currentLink });
  };

  generateChips = (docs, isLink = false) => {
    return docs.map(file =>
      <Chip
        id="chip"
        label={this.props.onMobile && file.name.length > maxDocCharacters ?
          file.name.substring(0, maxDocCharacters) + strings.general.ellipsis : file.name
        }
        icon={isLink ? <Link fontSize="small"/> : <PictureAsPdf fontSize="small"/>}
        key={file.name}
        sx={{ "&.MuiChip-root": {...styles(theme).chip}, iconColorPrimary: {...styles(theme).whiteColor}}}
        color="primary"
        onDelete={() => this.removeChip(file)}
      />,
    );
  };

  render() {
    const binaryDocs = this.props.documents.filter(doc => !doc.linkUrl);
    const urlDocs = this.props.documents.filter(doc => doc.linkUrl);

    return (
      <Card id="documentRepository">
        {!this.props.noTitle && (
          <CardHeader
            sx={{...styles(theme).titleContainer}}
            title={
              <Typography variant='h6' sx={{...styles(theme).cardTitle}}>
                {strings.intervention.documentRepository.title}
              </Typography>}
          />)}
        <CardContent sx={{...styles(theme).chipsContainer}}>
          {binaryDocs && this.generateChips(binaryDocs)}
          {urlDocs && this.generateChips(urlDocs, true)}
        </CardContent>
        <CardActions>
          <Button
            id="openPickerButton"
            variant="contained"
            color="primary"
            onClick={this.openPicker}
          >
            <Add/>{strings.intervention.documentRepository.addDocumentLabel}
          </Button>
          <Box component="input"
            id="fileInput"
            accept={InterventionConstants.EXTENSION_FILES_ACCEPTED}
            sx={{...styles(theme).input}}
            ref={this.pickerRef}
            multiple
            type="file"
            onChange={this.getUploadedFiles}
          />
          <Typography variant="caption">{strings.intervention.documentRepository
            .limitFilesNumber(InterventionConstants.CONCLUSIONCARD.MAX_FILES_NUMBER)}</Typography>
        </CardActions>
        <CardActions>
          <Button
            id="openPickerLinkButton"
            variant="contained"
            color="primary"
            onClick={this.openDialogLink}
          >
            <Add/>{strings.intervention.documentRepository.addLienLabel}
          </Button>
          <Box component="input"
            id="inputLink"
            sx={{...styles(theme).input}}
            type="url"
            onChange={e => this.setState({ currentLink: e.target.value })}
          />
        </CardActions>

        <SimpleDialog
          id="linkDialog"
          title={strings.intervention.documentRepository.updateLink}
          open={this.state.linkDialogIsOpen}
          onRefuse={() => this.setState({ linkDialogIsOpen: false })}
          onAccept={this.onValidate}
          refuseLabel={strings.dialog.answer.close}
          acceptLabel={strings.dialog.answer.submit}
          addContentPadding={true}
        >
          <Box component="div" sx={{...styles(theme).column}}>
            <TextField
              id='textfieldLinkName'
              variant="standard"
              sx={{...styles(theme).linkField}}
              autoFocus
              error={this.state.inputHasError}
              placeholder={strings.intervention.documentRepository.linkNamePlaceholder}
              value={this.state.currentLink.name}
              label={strings.intervention.documentRepository.name}
              onChange={(e) => this.handleLinkChange(nameAttr, e.target.value)}
            />
            <TextField
              id='textfieldLink'
              variant="standard"
              sx={{...styles(theme).linkField}}
              error={this.state.inputHasError}
              placeholder={strings.intervention.documentRepository.linkPlaceholder}
              label={strings.intervention.documentRepository.link}
              value={this.state.currentLink.linkUrl}
              onChange={(e) => this.handleLinkChange(urlAttr, e.target.value)}
            />
            {this.state.inputHasError &&
            <Typography color={'error'}>{this.state.errMsg}</Typography>
            }
          </Box>

        </SimpleDialog>
      </Card>
    );
  }
}

DocumentsRepository.propTypes = {
  update: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  noTitle: PropTypes.bool,
  onMobile: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps)
)(DocumentsRepository);
