export let styles = theme => ({
  /// HEADER
  /*** Block contained the header ***/
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.primary.main,
    padding: `${theme.spacing()} ${theme.spacing(3)}`,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: "0px",
      paddingRight: theme.spacing(),
      paddingTop: theme.spacing(),
    },
  },
  /*** One text line inside the header ***/
  headerDesignation: {
    height: theme.spacing() * 3,
    display: 'inline-block',
    lineHeight: 1.33,
    fontSize: "18px",
    [theme.breakpoints.only('xs')]: {
      lineHeight: 1,
      fontSize: "16px"
    },
  },
  tabletHeaderDesignation: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.75rem !important'
    },
  },
  headerMarquage: {
    height: theme.spacing(3),
    opacity: 0.7,
    fontSize: "14px",
  },
  tabletHeaderMarquage: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem !important'
    },
  },
  favoriteInstrumentIcon: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      marginRight: theme.spacing(0.5),
    },
  },
  icons: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  icon: {
    marginBottom: 'auto',
    marginTop: 'auto',
  },
  instrumentImage: {
    display: 'flex',
    flex: '0 1 auto',
    padding: "4px"
  },
  leftComponent: {
    display: 'flex'
  },
  thinnerPadding: {
    padding: '8px!important'
  }
});

