import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  scrollArea: {
    height: '100%',
  },
  virtualizedListContainer: {
    flex: 1
  },
  genericCardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  csvIcon: {
    height: "48px",
    color: theme.palette.primary.main,
  },
});
