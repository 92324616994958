import db from '../../Utils/Database/Database';

class InterventionProvider {

  static async createManyInterventions(interventions, location, user) {
    const updatedInterventions = interventions.map(intervention => {
      intervention.updateInstrumentUser = user;
      intervention.updateInstrumentLocation = location;
      return intervention;
    });

    return await db.interventions.bulkPut(updatedInterventions);
  }

  static async getAllInterventions() {
    return db.interventions.toArray();
  }

  static async deleteInterventions(interventions) {
    const interventionIds = interventions.map(i => i.id);
    await db.interventions.where('id').anyOf(interventionIds).delete();
  }

}

export default InterventionProvider;
