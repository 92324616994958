import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  paper: {
    width: '70%',
    borderRadius: '8px 8px 0 0',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      borderRadius: "0px",
      height: '100%'
    },
  },
  content: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: '16px 8px 0 8px',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      padding: "0px"
    },
  },
  list: {
    flex: 1
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '95%',
    margin: 'auto',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
    },
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    paddingTop: "10px",
    [theme.breakpoints.only('xs')]: {
      paddingTop: "0px",
    },
  },
  title: {
    paddingLeft: "4px",
    lineHeight: 1,
    color: theme.palette.MainMenu.pageTitles,
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    },
  },
  scrollArea: {
    height: '100%'
  },
  headerRight: {
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    },
  }
});
