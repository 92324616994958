export let styles = theme => ({
  chip: {
    marginRight: "8px",
    marginBottom: "8px",
    color: theme.palette.blackTheme.main,
    borderColor: theme.palette.tertiary.main
  },
  chipIcon: {
    color: theme.palette.tertiary.main
  },
  addButton: {
    marginBottom: "8px",
    width: "32px",
    height: "32px",
    minHeight: "32px",
    boxShadow: 'none',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.tertiary.main
  }
});
