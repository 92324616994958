import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import sharedStyles from './../../Utils/SharedStyles';

const {
  DRAWER_WIDTH,
  DRAWER_WIDTH_MOBILE,
  CLOSED_DRAWER_WIDTH
} = globalConstants.style;

export let styles = theme => ({
  ...sharedStyles(theme),
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.MainMenu.menu,
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.mainPageBackground,
      marginBottom: theme.spacing(),
      color: theme.palette.MainMenu.pageTitles,
      boxShadow: 'none',
      zIndex: theme.zIndex.drawer - 1,
    },
  },
  appBar_closed: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - ' + (CLOSED_DRAWER_WIDTH + 20) + 'px)'
    }
  },
  mainToolbar_toolbar: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2)
    }
  },
  appBar_open: {
    marginLeft: DRAWER_WIDTH,
    width: 'calc(100% - ' + DRAWER_WIDTH + 'px)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.only('xs')]: {
      width: 'calc(100% - ' + DRAWER_WIDTH_MOBILE + ')',
    }
  },
  menuButton: {
    marginLeft: "12px",
    marginRight: "36px",
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
  },
  typo: {
    color: theme.palette.secondary.main,
    textTransform: 'uppercase',
    lineHeight: 1.5,
    fontSize: '1.1rem',
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.MainMenu.pageTitles,
      fontSize: '1.25rem'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  grow: {
    flexGrow: 1,
  },
  pageIcon: {
    marginRight: theme.spacing(2),
    marginTop: '5px',
    color: theme.palette.secondary.main,
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.MainMenu.pageTitles,
      fill: theme.palette.MainMenu.pageTitles,
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.5),
    }
  },
});
