import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { styles } from './DetailsProfile.style';
import Role from '../Role/Role';
import { theme } from '../../../Utils/Theme';
import { Box } from '@mui/material';

// maps interventionReducer's state to Component's props
const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile
  };
};

class DetailsProfile extends Component {
  render() {
    const { icon, titleText, value, rolesProps, onMobile } = this.props;
    const fontSizeChange = onMobile ? 'body2' : 'body1';
    // If rolesProps is defined && contains something
    if (rolesProps && rolesProps.length !== 0) {
      const roles = rolesProps.map(role => <Role role={role} key={role.id} />);
      return (
        <Box component="div" sx={{...styles(theme).detailsProfile}}>
          {icon}
          <Box component="div" sx={{...styles(theme).text}}>
            <Typography id="typo" variant={fontSizeChange} color="primary">{titleText}</Typography>
            {roles}
          </Box>
        </Box>
      );
    }
    else {
      return (
        <Box component="div" sx={{...styles(theme).detailsProfile}}>
          <Box component="div" sx={{...styles(theme).icon}}>
            {icon}
          </Box>
          <Box component="div" sx={{...styles(theme).text}}>
            <Typography variant={fontSizeChange} color="primary">{titleText}</Typography>
            <Typography variant={fontSizeChange}>{value}</Typography>
          </Box>
        </Box>
      );
    }
  }
}

DetailsProfile.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  icon: PropTypes.object.isRequired,
  titleText: PropTypes.string.isRequired,
  value: PropTypes.string,
  rolesProps: PropTypes.array
};

export default compose(
  connect(mapStateToProps))(DetailsProfile);
