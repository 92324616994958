import React, { Component, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'
import 'dayjs/locale/fr';
import { LocalizationProvider } from '@mui/x-date-pickers';

import MainPage from './Components/MainPage/MainPage';
import AuthenticationPage from './Components/Authentication/AuthenticationPage';
import SynchronizationPage from './Components/SynchronizationPage/SynchronizationPage';
import PageNotFound from './Components/Misc/PageNotFound/PageNotFound';
import { PagesPaths } from './Utils/Constants/PagesPaths';
import { LoggedUserGuard } from './Utils/Guards/LoggedUserGuard';
import SSOGuard from './Utils/Guards/SSOGuard';
import { theme } from './Utils/Theme';
import './Utils/BrowserFix';
import GuestPage from './Components/GuestPage/GuestPage';
import VersionService from './Providers/WebService/VersionService';
import VersionDialog from './Components/Dialogs/VersionDialog/VersionDialog';
import { globalConstants } from './Utils/Constants/GlobalConstants';

dayjs.extend(utc);
dayjs.extend(timezone);

class App extends Component {

  state = {
    needUpdate: false
  };

  async componentDidMount() {
    const needUpdate = await VersionService.compareVersions();
    this.setState({ needUpdate });
    disableBrowserBackButton();
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <VersionDialog
            open={this.state.needUpdate}
            update={() => VersionService.emptyCacheVersionAndReload()}
            skip={() => this.setState({ needUpdate: false })}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} locale="fr">
          <Routes>
            <Route path={PagesPaths.root} element={<AuthenticationPageRoute />} />
            <Route path={PagesPaths.externalFilter} element={<ExternalFilterRoute />} />
            <Route path={PagesPaths.home} element={<HomeRoute />} />
            <Route path={PagesPaths.sync} element={<SynchronizationPageRoute />} />
            <Route path={PagesPaths.guest} element={<GuestRoute />} />
            <Route path="*" element={<PageNotFoundRoute />} />
          </Routes>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

function HomeRoute() {
  return LoggedUserGuard.shouldRoute() ? <MainPageRoute /> : <Navigate to={PagesPaths.root} replace />;
}

function GuestRoute() {
  return SSOGuard.shouldRoute() ? <GuestPageRoute /> : <Navigate to={PagesPaths.root} replace />;
}

function PageNotFoundRoute() {
  let navigate = useNavigate();
  let location = useLocation();
  return <PageNotFound navigate={navigate} location={location} />;
}

function MainPageRoute() {
  let navigate = useNavigate();
  let location = useLocation();
  return <MainPage navigate={navigate} location={location} />;
}

function GuestPageRoute() {
  let navigate = useNavigate();
  let location = useLocation();
  return <GuestPage navigate={navigate} location={location} />;
}

function SynchronizationPageRoute() {
  let navigate = useNavigate();
  let location = useLocation();
  return <SynchronizationPage navigate={navigate} location={location} />;
}

function AuthenticationPageRoute() {
  let navigate = useNavigate();
  let location = useLocation();
  return <AuthenticationPage navigate={navigate} location={location} />;
}

function ExternalFilterRoute() {
  let { marquages } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    navigate(`${PagesPaths.root}?${globalConstants.externalFilterUrlKey}=${marquages}`);
  }, [marquages, navigate]);

  return null;
}

export default App;
