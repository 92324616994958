import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import { InterventionRequestConstants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import SimpleDialog from '../../../../Dialogs/SimpleDialog/SimpleDialog';

class CommentDialog extends Component {

  state = {
    comment: ''
  };

  handleChange = e => {
    this.setState({
      comment: e.target.value
    });
  };

  validateComment = () => {
    const { handleCommentDialog } = this.props;
    const { comment } = this.state;
    this.props.item.interventionComment = comment;
    handleCommentDialog();
  };

  render() {
    const { handleCommentDialog, item, open } = this.props;
    const { commentDialog, cancel, submit } = strings.interventionRequest;
    const { COMMENT_MAX_LINES } = InterventionRequestConstants;
    return (
      <SimpleDialog
        id="dialog"
        open={open}
        title={commentDialog.title}
        refuseLabel={cancel}
        onRefuse={handleCommentDialog}
        acceptLabel={submit}
        onAccept={this.validateComment}
      >
        <TextField
          autoFocus
          variant="standard"
          margin="dense"
          id="comment"
          label={commentDialog.label}
          type="text"
          fullWidth
          multiline
          rows={COMMENT_MAX_LINES}
          onChange={e => this.handleChange(e)}
          defaultValue={item.interventionComment}
        />
      </SimpleDialog>
    );
  }
}

CommentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCommentDialog: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired
};

export default CommentDialog;
