import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  column: {
    display: 'flex',
    flexFlow: 'column nowrap',
    flex: 1,
    margin: "24px",
  },
  row: {
    display: 'flex',
    flexFlow: 'row nowrap',
    flex: 1,
  },
  mobileContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  separateCards: {
    margin: "24px"
  },
  separateCardsTop: {
    marginTop: "24px"
  }
});
