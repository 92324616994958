import React from 'react';
import { Info, NotInterested, TouchApp } from '@mui/icons-material';

const classes = {
  list: {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start'
  },
  listItems: {
    display: 'flex'
  },
  icon: {
    marginRight: "8px"
  }
};

export const CommonFirstStep = {
  title: 'Fonctionnement des tutoriels',
  target: '#main',
  content:
    <>
      <p>Un tutoriel va se lancer afin de vous guider pas à pas sur le fonctionnement de la page en cours. </p>
      <p>Durant ce tutoriel, les informations que vous validez seront factices. Les inventaires, demandes
        d'interventions, etc..., que vous créerez <b>ne seront pas réellement validées</b>. </p>
      <p>A tout moment, vous pouvez quitter le mode "tutoriel" en cliquant sur "Passer".</p>
      <ul style={classes.list}>
        <li><p style={classes.listItems}><NotInterested style={classes.icon}/>Indique que le clic est désactivé à
          l'endroit pointé.</p></li>
        <li><p style={classes.listItems}><Info style={classes.icon}/>Aucune action de votre part n'est attendue, un
          texte explicatif est affiché. Cliquer sur "Suivant" amène à la prochaine étape.</p></li>
        <li><p style={classes.listItems}><TouchApp style={classes.icon}/>Une action de votre part est attendue pour
          aller à l'étape suivante.</p></li>
      </ul>
    </>,
  placement: 'center',
  listContent: true
};

export const AfterChoiceFirstStep = {
  ...CommonFirstStep,
  content:
    <>
      <p>Un tutoriel va se lancer afin de vous guider pas à pas sur le fonctionnement de la page en cours. </p>
      <p>A tout moment, vous pouvez quitter le mode "tutoriel" en cliquant sur "Passer".</p>
      <ul style={classes.list}>
        <li><p style={classes.listItems}><NotInterested style={classes.icon}/>Indique que le clic est désactivé à
          l'endroit pointé.</p></li>
        <li><p style={classes.listItems}><Info style={classes.icon}/>Aucune action de votre part n'est attendue, un
          texte explicatif est affiché. Cliquer sur "Suivant" amène à la prochaine étape.</p></li>
        <li><p style={classes.listItems}><TouchApp style={classes.icon}/>Une action de votre part est attendue pour
          aller à l'étape suivante.</p></li>
      </ul>
    </>
};
