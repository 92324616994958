import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ExpandableTreeElement from './ExpandableTreeElement/ExpandableTreeElement';
import { styles } from './SelectableTree.style';
import { theme } from '../../../Utils/Theme';

class SelectableTree extends Component {

  state = {
    selected:
    {
      id: null,
      name: ''
    },
    open: true
  };

  // On selection, we set the 'selected' state value to the element the user selected, and we transmit this value
  // to the parent component.
  handleItemClick = node => {
    this.setState({
      selected: {
        id: node.id,
        name: node.name
      }
    });
    this.props.onValueSelected(node);
  };

  // Recursively create a tree from input data, formatted like a JSON object
  // Each node must have a 'name', 'id' and a 'children' property
  createTree(data, level = 0) {
    return data.map(node => {
      let localLevel = level;

      // Branch attributes, common for leaf or subtrees
      const params = ({
        level,
        name: node.name,
        key: node.id,
        id: node.id,
        checked: this.state.selected.id === node.id,
        click: this.handleItemClick
      });

      const element = (node.children === null || node.children.length === 0) ?
        // If no children, it's a leaf...
        <ExpandableTreeElement {...params} /> :
        // ...else, it's a subtree: it is expandable and we do recursion inside it !
        <ExpandableTreeElement {...params} expandable>
          {this.createTree(node.children, ++localLevel)}
        </ExpandableTreeElement>;
      return element;
    });
  }

  render() {
    return (
      <List
        component="nav"
        subheader={<ListSubheader component="div">{this.props.title}</ListSubheader>}
        sx={{ "&.MuiListSubheader-root": {...styles(theme).root}}}
      >
        {this.createTree(this.props.data)}
      </List>
    );
  }
}

SelectableTree.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired
  })).isRequired,
  onValueSelected: PropTypes.func.isRequired
};

SelectableTree.defaultProps = {
  title: '',
};

export default SelectableTree;
