import {
  UPDATE_ON_MOBILE,
  SAVE_USER_INFORMATIONS,
  UPDATE_NETWORK_STATUS,
  SHOULD_REDIRECT_TO_LOGIN,
  UPDATE_CURRENT_PAGE,
  SET_HELP_DIALOG_OPEN,
  SHOULD_FORCE_SYNC
} from '../actions/actionTypes';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';

const initialState = {
  onMobile: false,
  loggedUser: {},
  networkStatus: 1,
  notifications: [],
  shouldRedirectToLogin: false,
  currentPage: PAGES_IDS.INSTRUMENTS,
  helpDialogOpen: true,
  shouldForceSync: false
};

const updateNetworkStatus = (state, action) => {
  return { ...state, networkStatus: action.payload };
};

const updateShouldForceSync = (state, action) => {
  return { ...state, shouldForceSync: action.payload };
};

const updateOnMobile = (state, action) => {
  return { ...state, onMobile: action.payload };
};

const saveUserInformations = (state, action) => {
  return { ...state, loggedUser: action.payload };
};


const shouldRedirectToLogin = (state, action) => {
  return {
    ...state,
    shouldRedirectToLogin: action.payload
  };
};

const updateCurrentPage = (state, action) => {
  return {
    ...state,
    currentPage: action.payload
  };
};

const setHelpDialogOpen = (state, action) => {
  return {
    ...state,
    helpDialogOpen: action.payload
  };
};

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    // updates the active step on the stepper
    case UPDATE_ON_MOBILE: return updateOnMobile(state, action);
    case SAVE_USER_INFORMATIONS: return saveUserInformations(state, action);
    case UPDATE_NETWORK_STATUS: return updateNetworkStatus(state, action);
    case SHOULD_REDIRECT_TO_LOGIN: return shouldRedirectToLogin(state, action);
    case UPDATE_CURRENT_PAGE: return updateCurrentPage(state, action);
    case SET_HELP_DIALOG_OPEN: return setHelpDialogOpen(state, action);
    case SHOULD_FORCE_SYNC: return updateShouldForceSync(state, action);
    default: return state;
  }
};

export default generalReducer;
