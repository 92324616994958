import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { styles } from './CameraScan.style';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import Scanner from './Scanner';
import {
  Barcode,
  BarcodePicker,
  ScanSettings,
  CameraAccess
} from 'scandit-sdk';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ScanConstants } from '../../Utils/Constants/ScanConstants';
import { logger } from '../../Utils/Logger';
import { theme } from '../../Utils/Theme';

class CameraScan extends Component {

  state = {
    firstScan: true,
    accessCamera: false
  };

  constructor(props) {
    super(props);
    this.scanSettings = new ScanSettings({
      codeDuplicateFilter: ScanConstants.CAMERA_SCAN_TIME,
      enabledSymbologies: ScanConstants.ENABLED_SYMBOLOGIES
    });

    this.scanSettings.symbologySettings.get(Barcode.Symbology.CODE128)
      .setActiveSymbolCounts(ScanConstants.SYMBOL_COUNTS);

    this.scanSettings.setSearchArea(ScanConstants.SCAN_ZONE);
  }

  componentDidMount() {
    CameraAccess.getCameras().then(() => {
      this.setState({ accessCamera: true });
    }).catch(err => {
      logger(err);
      this.setState({ accessCamera: false });
    }); 
  }

  onScan = result => {
    if (!this.state.firstScan) {
      this.props.onScan(result.barcodes[0].data);
    }
  }

  startScan = () => {
    this.setState({ firstScan: false });
  }

  onClose = () => {
    this.setState({ firstScan: true });
    this.props.onClose();
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={() => this.props.onClose()}
        sx={{ "& .MuiDialog-paper": {...styles(theme).cameraDialog} }}>
        <Box component="div" sx={{...styles(theme).cameraContainer}}>
          <Scanner
            // Library licensing & configuration options
            // (see https://docs.scandit.com/stable/web/globals.html#configure)
            licenseKey={ScanConstants.SCANDIT_LICENCE_KEY}
            engineLocation={ScanConstants.SCAN_ENGINE}
            paused={this.state.firstScan}

            // Picker events
            onScan={this.onScan}
            onScanError={logger}

            //
            accessCamera={this.state.accessCamera}

            // Picker options
            scanSettings={this.scanSettings}
            enableCameraSwitcher={false}
            enablePinchToZoom
            enableTapToFocus
            enableTorchToggle
            guiStyle={BarcodePicker.GuiStyle.VIEWFINDER}
            playSoundOnScan
            vibrateOnScan
            targetScanningFPS={ScanConstants.SCAN_FPS}
            singleImageMode={{
              desktop: { always: false, allowFallback: true },
              mobile: { always: false, allowFallback: true }
            }}
          />
        </Box>
        <IconButton
          id="closeIcon"
          sx={{...styles(theme).closeBtn}}
          onClick={this.onClose}
          size="large">
          <Close sx={{...styles(theme).startText}} />
        </IconButton>
        <Dialog
          open={this.state.firstScan}
          onClose={this.startScan}
          sx={{ "& .MuiDialog-paper": {...styles(theme).startDialog} }}
        >
          <Typography
            id="startScanMessage"
            sx={{...styles(theme).startText}}
            variant='h6'
            onClick={this.startScan}>{strings.scanMessage.startScan}
          </Typography>
        </Dialog>
      </Dialog>
    );
  }
}

CameraScan.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onScan: PropTypes.func.isRequired
};

export default CameraScan;
