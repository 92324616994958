import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  plainIcon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'unset'
    }
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    padding: "8px",
    height: '10%',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    },
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: 500,
    color: theme.palette.secondary.main,
    letterSpacing: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  action: {
    margin: "0px",
    alignSelf: 'center'
  }
});
