import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: theme.spacing(),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  center: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'first baseline',
    flexGrow: 1,
    marginLeft: theme.spacing(),
    paddingLeft: theme.spacing(2)
  },
  middle: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    textAlign: 'right'
  },
  right: {
    display: 'flex'
  },
  instrumentImage: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    maxWidth: "50px",
    maxHeight: "50px"
  },
  designation: {
    color: theme.palette.MainMenu.pageTitles
  },
  referenceConstructeur: {
    color: theme.palette.lightGrayTheme.secondary
  },
  marquage: {
    color: theme.palette.lightGrayTheme.secondary,
    fontWeight: 'bold'
  },
  delete: {
    color: theme.palette.veryDarkBlueTheme.main
  }
});
