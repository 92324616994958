import React from 'react';
import ToastContent from './ToastContent/ToastContent';
import { ScanPages } from '../../../Utils/Constants/ScanPage';
import { toast } from 'react-toastify';

export class ToastService {

  static DEFAULT_TOAST_TIME = 3000;

  static enqueueToast(type, instrument, message, time = ToastService.DEFAULT_TOAST_TIME) {
    return toast(
      <ToastContent type={type} instrument={instrument} message={message} />, { autoClose: time }
    );
  }

  /**
   * Get the time use for toast for the screen if it's a specific one. (Default is 3000)
   * @param {string} screen 
   * @returns the time use for the toast
   */
  static getToastTime = (screen) => {
    if (screen === ScanPages.INVENTORY) {
      return 1500;
    }
    return ToastService.DEFAULT_TOAST_TIME;
  }
}
