export let styles = theme => ({
  displayValue: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  icon: {
    fontSize: '12px',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  valueRole: {
    marginLeft: theme.spacing()
  },
  displayUnit: {
    marginLeft: theme.spacing(3),
  }
});
