import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { InsertInvitation } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { styles } from './DatePickerIcon.style';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import { theme } from '../../../Utils/Theme';

dayjs.extend(utc);

// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile
  };
};

class DatePickerIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {

    return <>
      <Tooltip title={strings.tooltips.dateFilter} enterDelay={globalConstants.tooltipDelay}>
        <IconButton
          color="primary"
          onClick={this.handleOpen}
          sx={this.props.onMobile ? { "&.MuiIconButton-root": {...styles(theme).colorIcon} } : null}
          size="large">
          <InsertInvitation />
        </IconButton>
      </Tooltip>
      <MobileDatePicker
        sx={{...styles(theme).datePickerWithText}}
        maxDate={dayjs()}
        keyboard={!this.props.onMobile}
        selected={this.props.selectedDate}
        onAccept={this.props.handleChangeDatePicker}
        animateYearScrolling
        format={strings.general.dateFormat}
        defaultValue={dayjs()}
        open={this.state.open}
        onClose={this.handleClose}
      />
    </>;
  }
}

DatePickerIcon.propTypes = {
  selectedDate: PropTypes.object,
  onMobile: PropTypes.bool,
  handleChangeDatePicker: PropTypes.func.isRequired
};


export default compose(
  connect(mapStateToProps, null))(DatePickerIcon);
