import React from 'react';
import PropTypes from 'prop-types';
import { styles } from './SwitchToggleButton.style';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { theme } from '../../Utils/Theme';

const SwitchToggleButton = props => {

  return (
    <ToggleButtonGroup
      id="displayCardListButton"
      sx={{ "&.MuiToggleButtonGroup-root": {...styles(theme).toggleButtonGroup}}}
      selected
      value={props.value}
      exclusive
      onChange={props.onValueChanged}
    >
      <ToggleButton
        sx={{ "&.MuiToggleButton-root": {...styles(theme).toggleButton}, "&.Mui-selected": {...styles(theme).toggleButtonSelected}}}
        value={props.firstValue}>
        {props.firstValueIcon}
      </ToggleButton>
      <ToggleButton
        sx={{ "&.MuiToggleButton-root": {...styles(theme).toggleButton}, "&.Mui-selected": {...styles(theme).toggleButtonSelected}}}
        value={props.secondValue}>
        {props.secondValueIcon}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

SwitchToggleButton.propTypes = {
  value: PropTypes.string.isRequired,
  onValueChanged: PropTypes.func.isRequired,
  firstValue: PropTypes.string.isRequired,
  firstValueIcon: PropTypes.element,
  secondValue: PropTypes.string.isRequired,
  secondValueIcon: PropTypes.element
};

export default SwitchToggleButton;
