

/** Remove space at the beginning and the end of text and apply lowercase */
const cleanString = text => {
  return text.trim().toLowerCase();
};

const arrayFindMatchingStrings = (array, instrumentLabel) => {
  if (!instrumentLabel || instrumentLabel.length === 0) {
    return null;
  }
  return array.find(name => cleanString(name) === cleanString(instrumentLabel));
};

export {
  cleanString,
  arrayFindMatchingStrings
};
