
const Column = {
  MARQUAGE: 'Marquage',
  DESIGNATION: 'Désignation',
  MARQUE: 'Marque',
  MODELE: 'Modèle',
  NUMBERSERIES: 'Numéro de série',
  STATUS: 'Statut',
  STATE: 'Etat métrologique',
  SITE: 'Site',
  LOCALISAZATION: 'Localisation',
  PROPRIETAIRE: 'Propriétaire',
  IDCRIS: 'Id cris',
  UTILISATEUR: 'utilisateur',
  PARC: 'Parc',
  BANC: 'Banc',
  AFFECTATION: 'Affectation',
  SECTION: 'Section',
  SOUSSECTION: 'Sous-section',
  DESTINATAIRE: 'Destinataire',
  COUTJOUR: 'Coût de location journalier',
  LASTUPDATE: 'Dernière mise à jour de l\'instrument',
  OPERATION: 'Opération',
  NEXTECHEANCE: 'Prochaine échéance',
  PERIODICITE: 'Périodicité',
  PLANNING: 'Planning',
  LASTOPERATION: 'Opération précédente',


};

export class SheetRow {

  constructor(instrument) {
    this[Column.MARQUAGE]= instrument.marquage;
    this[Column.DESIGNATION] = instrument.designation;
    this[Column.MARQUE] = instrument.constructeur;
    this[Column.MODELE] = instrument.referenceConstructeur;
    this[Column.NUMBERSERIES] = instrument.numeroSerie;
    this[Column.STATUS] = instrument.etat;
    this[Column.STATE] = instrument.etatMetrologique;
    this[Column.SITE] = instrument.site;
    this[Column.LOCALISAZATION] = instrument.localisation;
    this[Column.PROPRIETAIRE] = instrument.proprietaire;
    this[Column.IDCRIS] = instrument.idCris;
    this[Column.UTILISATEUR] = instrument.utilisateur;
    this[Column.PARC] = instrument.parc;
    this[Column.BANC] = instrument.banc;
    this[Column.AFFECTATION] = instrument.affectation;
    this[Column.SECTION] = instrument.section;
    this[Column.SOUSSECTION] = instrument.sousSectionLibelle;
    this[Column.DESTINATAIRE] = instrument.destinataire;
    this[Column.COUTJOUR] = instrument.coutJour;
    this[Column.LASTUPDATE] = instrument.lastModificationDate;
    this[Column.OPERATION] = instrument.operationEvaluation;
    this[Column.NEXTECHEANCE] = instrument.periodiciteProchaineOperation;
    this[Column.PERIODICITE] = instrument.periodicitePeriode;
    this[Column.PLANNING] = instrument.planning;
    this[Column.LASTOPERATION] = instrument.motifEnvoi;
  }
}