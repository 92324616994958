import db from '../../Utils/Database/Database';
import { JwtHelperService } from '../../Utils/JwtHelperService';

class MessageScanProvider {

  static getAllMessagesScansHistory() {
    return db.messageScanHistory.where({ userLogin: JwtHelperService.getUserLogin() }).toArray();
  }

  static async insertMessageScanHistory(messageScanHistory) {
    await db.messageScanHistory.add(messageScanHistory);
  }

}

export default MessageScanProvider;
