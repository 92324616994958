import React, { Component } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircle';
import CommentIcon from '@mui/icons-material/Comment';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/HighlightOff';
import PropTypes from 'prop-types';
import { styles } from './Instrument.style';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Typography from '@mui/material/Typography';
import CommentDialog from './CommentDialog';
import { InterventionRequestConstants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import Badge from '@mui/material/Badge';
import InstrumentImage from '../../../../InstrumentCard/InstrumentImage/InstrumentImage';
import { theme } from '../../../../../Utils/Theme';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile
  };
};

class Instrument extends Component {

  state = {
    dialogOpen: false
  };

  handleItemClick = item => {
    this.props.addOrRemoveInstrument(item);
  };

  handleRemoveItem = item => {
    this.props.addOrRemoveInstrument(item, false);
  };

  closeCommentDialog = () => {
    this.setState({ dialogOpen: false });
  };

  openCommentDialog = e => {
    e.stopPropagation();
    this.setState({ dialogOpen: true });
  };

  render() {
    const { typeOfDisplay, item, onMobile } = this.props;
    const { dialogOpen } = this.state;
    const { RESPONSE_DISPLAY_VALID, RESPONSE_DISPLAY_UNVALID, CART_DISPLAY_REMOVE, SELECTION_DISPLAY }
    = InterventionRequestConstants.TYPES_OF_DISPLAY;
    let actionButton, smallButton;
    const hasComment = item.interventionComment && item.interventionComment.length !== 0;
    // mobile view variants
    const variant = onMobile ? 'overline' : 'subtitle2';

    switch (typeOfDisplay) {
      case RESPONSE_DISPLAY_VALID:
        actionButton = (
          <IconButton
            sx={{ "&.MuiIconButton-root": {...styles(theme).plainIcon} }}
            style={{ '--icon-color': theme.palette.toasts.success }}
            size="large">
            <CheckIcon fontSize="large" />
          </IconButton>

        );
        break;
      case RESPONSE_DISPLAY_UNVALID:
        actionButton = (
          <IconButton
            sx={{ "&.MuiIconButton-root": {...styles(theme).plainIcon} }}
            style={{ '--icon-color': theme.palette.toasts.error }}
            size="large">
            <ErrorIcon fontSize="large" />
          </IconButton>

        );
        break;
      case CART_DISPLAY_REMOVE:
        smallButton = (
          <IconButton
            id="openCommentButton"
            sx={{ "&.MuiIconButton-root": {...styles(theme).side_action_button}, ...styles(theme).blue_button }}
            onClick={e => this.openCommentDialog(e)}
            size="large">
            <Badge color="primary" style={{ padding: "2px" }} invisible={!hasComment} variant="dot">
              <CommentIcon />
            </Badge>
          </IconButton>
        );
        actionButton = (
          <IconButton
            id="removeButton"
            onClick={() => this.handleRemoveItem(item)}
            sx={{...styles(theme).orange_button}}
            size="large">
            <RemoveIcon fontSize="large" />
          </IconButton>
        );
        break;
      case SELECTION_DISPLAY:
      default:
        actionButton = (
          <IconButton
            id="addButton"
            onClick={() => this.handleItemClick(item)}
            disabled={this.props.disableAddBtn}
            sx={{...styles(theme).orange_button}}
            size="large">
            <AddIcon fontSize="large" />
          </IconButton>
        );
    }

    return (
      <ListItem 
        id="interventionItem" 
        sx={{ "&.MuiListItem-root": {...styles(theme).instrument_item} }} 
        onClick={e => { e.stopPropagation();
          this.props.onClick(item);
        }}>
        <Box component="div" sx={{...styles(theme).img}}>
          <InstrumentImage instrument={this.props.item} />
        </Box>
        <ListItemText
          id="listItemText"
          sx={{...styles(theme).listItemText}}
          disableTypography
          primary={
            <>
              <Box component="div" sx={{...styles(theme).title_with_btn}}>
                <Typography variant="h6" sx={{...styles(theme).item_title}}>{item.designation}</Typography>
                {smallButton}
              </Box>
              <Box component="div" sx={{...styles(theme).column_display}}>
                <Typography
                  variant={variant}
                  sx={{...styles(theme).item_subtitle}}
                >
                  {item.referenceConstructeur}
                </Typography>
                <Box component="div" sx={{...merge({}, styles(theme).row_display, styles(theme).horizontal_space_between)}}>
                  <Typography variant={variant} sx={{...styles(theme).item_subtitle}}>{item.marquage}</Typography>
                  <Typography variant={variant} sx={{...styles(theme).item_subtitle}}>{item.constructeur}</Typography>
                </Box>
              </Box>
            </>
          }
        />
        <ListItemSecondaryAction sx={{...styles(theme).listItemSecondaryAction}}>
          {actionButton}
          <CommentDialog
            id="commentDialog"
            open={dialogOpen}
            handleCommentDialog={this.closeCommentDialog}
            item={item} />
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

Instrument.propTypes = {

  //region props from redux
  onMobile: PropTypes.bool.isRequired,
  //endregion

  //region props from parent
  item: PropTypes.object.isRequired,
  typeOfDisplay: PropTypes.string.isRequired,
  disableAddBtn: PropTypes.bool,
  addOrRemoveInstrument: PropTypes.func,
  onClick: PropTypes.func
  //endregion
};

export default compose(connect(mapStateToProps))(Instrument);
