import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';

import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { styles } from './VersionDialog.style';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../Utils/Theme';

function VersionDialog(props) {
  return (
    <SimpleDialog title={strings.versionDialog.title} open={props.open} AddContentPadding>
      <Box component="div" sx={{...styles(theme).root}}>
        {process.env.REACT_APP_ENV === 'dev' && (
          <Box component="h3" sx={{...styles(theme).center}}>{strings.versionDialog.devMesssage}</Box>
        )}
        <Button variant="contained"
          color="primary"
          size="large"
          sx={{...(isNotComputer && styles(theme).tabletFontSize)}}
          onClick={props.update}>{strings.versionDialog.update}</Button>
        {strings.help.or}
        <Button
          variant="text"
          size="small"
          sx={{...(isNotComputer && styles(theme).tabletFontSize)}}
          onClick={props.skip}>
          {strings.versionDialog.skip}</Button>
      </Box>
    </SimpleDialog>
  );
}

VersionDialog.propTypes = {
  open: PropTypes.bool,
  update: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired
};

export default VersionDialog;
