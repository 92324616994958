import React from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { styles } from './DPSItem.style';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../../../../../Utils/Constants/LocalizedString';
import { theme } from '../../../../../../../Utils/Theme';
import merge from 'lodash/merge';

const DPSItem = (props) => {

  const EMPTY_NAME = '[nom vide]';

  const showInstrumentLabel = () => {
    return props.alternateStyling
      ? `${strings.intervention.conclusionCard.requestId}: `
      : `${props.instrument.designation || EMPTY_NAME} - ${props.instrument.marquage}`;
  };

  const showDpsValue = () => {
    return props.instrument.intervention && props.instrument.intervention.requestIdentifier
      ? props.instrument.intervention.requestIdentifier : '';
  };

  const DPS_FIELD = 'requestIdentifier';

  return (
    <Grid
      container
      sx={{ "&.MuiGrid-container": {...styles(theme).container}, ...styles(theme).dpsItem}}>
      <Grid
        item
        xs={props.onMobile ?
          12 :
          props.alternateStyling ? 2 : 8}
        sx={{...merge({}, styles(theme).verticalAlign, styles(theme).endTextAlign)}}
      >
        <Typography variant="body2" sx={{...styles(theme).textLabel}}>
          {showInstrumentLabel()}
        </Typography>
      </Grid>

      <Grid item
        xs={props.onMobile ?
          12 :
          props.alternateStyling ? 10 : 4}
        sx={{...styles(theme).verticalAlign}}>
        <TextField
          id={`${props.instrument.marquage}-DpsTextField`}
          variant="standard"
          sx={{ "&.MuiTextField-root": {...styles(theme).textField}}}
          InputProps={{
            classes: {
              input: {...styles(theme).input}
            }
          }}
          value={showDpsValue()}
          onChange={e => props.updateInstrumentsWithInterventions(DPS_FIELD, e.target.value, props.instrument)}
        />
      </Grid>
    </Grid>
  );
};

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

export default compose(
  connect(mapStateToProps,null)
)(DPSItem);

DPSItem.defaultPropTypes = {
  alternateStyling: false,
};

DPSItem.propTypes = {
  onMobile: PropTypes.bool.isRequired,

  //region props from parent
  instrument: PropTypes.object.isRequired,
  updateInstrumentsWithInterventions: PropTypes.func,
  alternateStyling: PropTypes.bool
  //endregion
};
