import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  toggleButtonGroup: {
    margin: "3px",
    borderRadius: "20px",
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  toggleButtonSelected: {
    backgroundColor: `${theme.palette.primary.main}!important`,
    color: `${theme.palette.secondary.main}!important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light}!important`,
    },
  },
  toggleButton: {
    paddingTop: "4px",
    paddingBottom: "4px",
    borderRadius: "20px",
    color: `${theme.palette.primary.main}`,
  }
});
