import sharedStyled from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyled(theme),
  input: {
    color: theme.palette.MainMenu.pageTitles,
    fontSize: "13px",
    [theme.breakpoints.only('xs')]: {
      // Standard font-size for input inside a Textfield
      fontSize: '1rem'
    },
  },
  textField: {
    marginLeft: theme.spacing(1.5),
    width: '85%',
  },
});

