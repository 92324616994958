import db from '../Database';
import { logger } from '../../Logger';

const Favorite = db.favorites.defineClass({
  userLogin: String,
  instrumentMarquage: String
});

Favorite.prototype.setValues = function (userLogin, instrumentMarquage) {
  this.userLogin = userLogin;
  this.instrumentMarquage = instrumentMarquage;
};

Favorite.prototype.log = function () {
  logger(this);
};

Favorite.prototype.save = function () {
  return db.favorites.put(this);
};

export default Favorite;
