import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Paper, Box } from '@mui/material';

import { styles } from './InterventionSummaryCard.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import { theme } from '../../../../../Utils/Theme';

const InterventionSummaryCard = props => {

  function generateFields(label, value) {
    return (
      <>
        <Grid item xs={6} sx={{...styles(theme).verticalAlignContainer}}>
          <Typography sx={{...styles(theme).input_content}}><strong>{label}</strong></Typography>
        </Grid>
        <Grid item xs={6} sx={{...styles(theme).verticalAlignContainer}}>
          <Typography sx={{...styles(theme).input_content}}>{value}</Typography>
        </Grid>
      </>
    );
  }

  return (
    <Paper elevation={2} id="summaryCard" sx={{...styles(theme).container}}>
      <Typography sx={{...styles(theme).sectionTitle}} variant="subtitle1">
        {strings.intervention.formStep}
      </Typography>
      <Box component="div" sx={{...styles(theme).sectionContainer}}>
        <Grid container spacing={1}>
          {generateFields(
            strings.intervention.interventionCard.serviceTypeLabel,
            props.intervention.type.name
          )}
          {generateFields(
            strings.intervention.interventionCard.interventionDate,
            props.intervention.date.format(strings.general.dateFormat
            ))}
          {generateFields(
            strings.intervention.interventionCard.providerLabel,
            props.intervention.provider.name
          )}
        </Grid>
      </Box>

      <Typography sx={{...styles(theme).sectionTitle}} variant="subtitle1">
        {strings.intervention.submitStep}
      </Typography>
      <Box component="div" sx={{...styles(theme).sectionContainer}}>
        <Grid container spacing={1}>
          {/* display ETAT field according to canSelectState  */}
          {props.intervention.type.canSelectState ?
            generateFields(
              strings.intervention.conclusionCard.state,
              props.intervention.state.name) : null
          }
          {/* display STATUS field according to canSelectState  */}
          {props.intervention.type.canSelectStatus ?
            generateFields(strings.intervention.conclusionCard.status,
              props.intervention.status.name) : null}
        </Grid>
      </Box>
    </Paper>
  );
};

InterventionSummaryCard.propTypes = {
  intervention: PropTypes.object.isRequired
};

export default InterventionSummaryCard;
