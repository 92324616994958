import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import InstrumentAnswerItem from '../InventoryAnswerItem/InstrumentAnswerItem';
import { styles } from './InstrumentAnswerList.style';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { theme } from '../../../../Utils/Theme';

const InstrumentsAnswerList = props => {

  return (
    <Accordion sx={{...styles(theme).paper}} disabled={props.instruments.length === 0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box component="div" sx={{...styles(theme).header}}>
          <Typography sx={{...styles(theme).title}} variant="h6">{props.title}</Typography>
          <Typography variant="subtitle1">{props.instruments.length} {strings.inventory.instrument(props.instruments.length)}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ "&.MuiAccordionDetails-root": {...styles(theme).expandedPanel}}}>
        <Box component="div" sx={{...styles(theme).content}}>
          {props.instruments.map((instr, index) => {
            if (instr) {
              if (Object.keys(instr).length > 2) {
                return <InstrumentAnswerItem id="item" key={instr.marquage} instrument={instr} />;
              } else if (instr.marquage) {
                return <Typography id="marquage" variant="subtitle1" key={instr.marquage}>{index !== props.instruments.length - 1 ? instr.marquage + ',' : instr.marquage}</Typography>;
              }
              else {
                return null;
              }
            }
            return null;
          })}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

InstrumentsAnswerList.propTypes = {
  title: PropTypes.string.isRequired,
  instruments: PropTypes.array
};

export default InstrumentsAnswerList;
