import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  instrumentsNbLabel: {
    fontSize: theme.spacing(2),
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: theme.palette.blackTheme.transparent,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(),
    }
  },
  top_label: {
    textAlign: 'center',
    margin: "16px",
    fontWeight: 500
  },
  noHorizontalScroll: {
    overflowX: 'hidden',
    height: '100%'
  },
  container: {
    margin: "16px",
    height: '90%'
  },
  gridContainer: {
    margin: "-20px",
    width: 'calc(100% + 40px)'
  },
  inputGrid: {
    padding: '20px'
  }
});

