// REACT libraries
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI libraries
import { Typography, IconButton, Box } from '@mui/material';
import { styles } from '../NotificationItem/NotificationItem.style';
import InstrumentImage from '../../InstrumentCard/InstrumentImage/InstrumentImage';
import { Help } from '@mui/icons-material';
import InstrumentHelper from '../../../Helpers/InstrumentHelper';
import StylizedField from '../../Utils/StylizedField/StylizedField';
import { theme } from '../../../Utils/Theme';

const MessageScanItem = props => {
  return (
    <Box component="div" sx={{...styles(theme).listItem}} onClick={props.onClick}>
      <Box component="div" sx={{...styles(theme).instrumentImage}}>
        <InstrumentImage instrument={props.item.instrument} />
      </Box>
      <Box component="div" sx={{...styles(theme).informations}}>
        <Typography variant="h6"
          sx={{...styles(theme).marquage}}>{props.item.marquage}</Typography>
        <Typography variant="subtitle2" sx={{...styles(theme).otherInformation}}>{props.item.date}</Typography>
        <StylizedField
          field={props.item.instrument.etat}
          color={InstrumentHelper.generateState(props.item.instrument.etat, theme.palette)}
        />
        <StylizedField
          field={props.item.instrument.etatMetrologique}
          color={InstrumentHelper.generateEtatMetrologiqueColor(props.item.instrument.etatMetrologique, theme.palette)}
        />
      </Box>
      <IconButton
        sx={{...styles(theme).removeBtn}}
        onClick={event => props.onHelp(event, props.item)}
        size="large">
        <Help color="primary" />
      </IconButton>
    </Box>
  );
};

MessageScanItem.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onHelp: PropTypes.func.isRequired
};

export default MessageScanItem;
