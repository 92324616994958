import PropTypes from 'prop-types';
import React from 'react';
import { Box, Button } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

import { styles } from './ScanButton.style';
import { svgConstants } from '../../../Utils/Constants/SvgConstants';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

const ScanButton = props => {
  return (
    <>
      <Box component="div" sx={{...styles(theme).scanButtonArea}}>
        <Button
          id="scanButton"
          type="submit"
          size="medium"
          variant="contained"
          color="primary"
          sx={{...styles(theme).scanButton}}
          onClick={props.handleScanButtonClick}
        >
          <SvgIcon sx={{ "&.MuiSvgIcon-root": {...merge({}, styles(theme).whiteColor, styles(theme).scanIcon)}}}>
            <path d={svgConstants.barcode} />
          </SvgIcon>
        </Button>
      </Box>
    </>
  );
};

ScanButton.propTypes = {
  handleScanButtonClick: PropTypes.func.isRequired
};

export default ScanButton;
