import React from 'react';
import { Typography, Button } from '@mui/material';
import { Help } from '@mui/icons-material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { STATUSES } from '../../../Utils/Constants/GuideConstants';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { setStatus } from '../../../store/actions/exportActions';

export const mapDispatchToProps = dispatch => {
  return {
    setStatus: status => dispatch(setStatus(status))
  };
};

const StartGuideButton = props => {
  return (
    <Button onClick={() => props.setStatus(STATUSES.INITIATED)}>
      {props.withLabel && <Typography variant="body2" color="secondary">{strings.guides.startGuideLabel}</Typography>}
      <Help color="secondary" style={{marginLeft: "4px"}} />
    </Button>
  );
};

StartGuideButton.propTypes = {
  withLabel: PropTypes.bool,
  setStatus: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(StartGuideButton);