import axios from 'axios';

import routes from '../Utils/Routes';
import { JwtHelperService } from '../Utils/JwtHelperService';
import { AuthenticationHelper, TokenStatus } from '../Helpers/AuthenticationHelper';
import store from '../store/store';
import { shouldRedirectToLogin } from '../store/actions/generalActions';
import { HttpConstants } from '../Utils/Constants/HttpConstants';
import { TrescalConstants } from '../Utils/Constants/TrescalConstants';
import { SnackbarMessages } from '../Utils/Constants/SnackbarMessages';
import { enqueueSnackbar } from 'notistack';

const HttpClient = axios.create({
  baseURL: routes.serverUrl,
});

if (JwtHelperService.isUserConnected()) {
  HttpClient.defaults.headers.common['Authorization'] = `Bearer ${JwtHelperService.getToken()}`;
  HttpClient.defaults.headers.common['X-Login-Type'] = JwtHelperService.getLoginType();
}

// INTERCEPTORS

/** Response interceptor */
HttpClient.interceptors.response.use(response => response, error => {
  let formattedError = error.response;

  if (!formattedError) {
    enqueueSnackbar(SnackbarMessages.networkError.msg, SnackbarMessages.networkError.type);
    formattedError = { status: HttpConstants.INTERNAL_SERVER_ERROR };
  } else if (HttpClient.tokenRefreshConditions(formattedError)) {
    const originalRequest = error.config;

    return new Promise(function (resolve, reject) {
      const tokenStatus = AuthenticationHelper.checkTokenValidity();
      switch (tokenStatus) {
        // Refresh the access token then update the request
        case TokenStatus.ACCESS_TOKEN_INVALID: {
          return AuthenticationHelper.refreshAccessToken()
            .then(res => {
              originalRequest.headers['Authorization'] = 'Bearer ' + res;
              resolve(HttpClient(originalRequest));
            })
            .catch(() => reject(HttpClient.expiredSession()));
        }
        // Logout and redirect to login page
        case TokenStatus.UNAUTHENTICATED: {
          return reject(HttpClient.expiredSession());
        }
        default:
          return Promise.reject();
      }
    });
  } else {
    return Promise.reject(formattedError);
  }
  return Promise.reject(formattedError);
});

HttpClient.expiredSession = async () => {
  await AuthenticationHelper.logout(false);
  store.dispatch(shouldRedirectToLogin(true));
  enqueueSnackbar(SnackbarMessages.expiredSessionError.msg, SnackbarMessages.networkError.type);
};

/** Request interceptor */
HttpClient.interceptors.request.use(request => {

  if (JwtHelperService.isUserConnected()) {
    request.headers['Authorization'] = `Bearer ${JwtHelperService.getToken()}`;
  }

  return request;
}, error => Promise.reject({ error }));

// HELPER METHODS

/** Simple get boilerplate */
HttpClient.simpleGet = route => {
  return HttpClient.get(route)
    .then(res => {
      return res ? Promise.resolve(res.data) : Promise.reject();
    })
    .catch(error => Promise.reject(error));
};

/** Simple post with response boilerplate */
HttpClient.postWithResponse = (route, data) => {
  return HttpClient.post(route, data)
    .then(res => Promise.resolve(res.data))
    .catch(error => Promise.reject(error));
};

/** Build an url with params and queries
 *  - baseUrl : the base url
 *  - params : the params to add to the url
 *  - queries: the queries to add to the url
 * Example: baseUrl/param1/param2?queryType=queryValue&queryType2=queryValue2
 */
HttpClient.buildUrl = (baseUrl, params, queries) => {
  let builtUrl = baseUrl;

  params.forEach(param => {
    builtUrl += '/' + param;
  });

  queries.forEach((query, index) => {
    builtUrl += '?' + query.attribute + '=' + query.value;
    if (index !== queries.length - 1) {
      builtUrl += '&';
    }
  });

  return builtUrl;
};

HttpClient.tokenRefreshConditions = formattedError => {
  if (!formattedError) {
    return false;
  }
  if (formattedError.data && formattedError.data.code
    && formattedError.data.code === TrescalConstants.TRESCAL_LOGIN_FAILED) {
    return false;
  }
  return (
    formattedError && formattedError.status &&
    formattedError.status === HttpConstants.UNAUTHORIZED
  );
};

HttpClient.setToken = (token, type, expirationDate) => {
  // Store Access Token in local storage
  localStorage.setItem(JwtHelperService.STORAGE_ACCESS_TOKEN_KEY, token);
  localStorage.setItem(JwtHelperService.STORAGE_LOGIN_TYPE_KEY, type);
  if (expirationDate) {
    localStorage.setItem(JwtHelperService.EXTERNAL_SSO_THALES_EXPIRATION, expirationDate);
  }

  HttpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  HttpClient.defaults.headers.common['X-Login-Type'] = type;
};

export default HttpClient;
