import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FilterableListDialog from '../../Dialogs/StringDialog/FilterableListDialog/FilterableListDialog';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import FilterProvider from '../../../Providers/Database/FilterProvider';
import ValidationDialog from '../../Dialogs/ValidationDialog/ValidationDialog';
import { FILTER_NAME_VALIDATION_REGEX } from '../../../Utils/Constants/FilterConstants';

class SaveFilterDialog extends Component {

  state = {
    filterList: [],
    showValidation: false,
    filterNameToValidate: ''
  };

  componentDidMount() {
    this.loadFilterList();
  }

  loadFilterList = () => {
    FilterProvider.getSavedFilters().then(list => {
      const filterNames = list.map(filter => filter.name);

      this.setState({ filterList: filterNames, showValidation: false });
    });
  }

  saveFilter = (filterName, validated) => {
    // if we need to ask for validation
    if (!validated && this.state.filterList.indexOf(filterName) >= 0) {
      this.setState({ showValidation: true, filterNameToValidate: filterName });
    } else {
      this.props.filterToSave.save(filterName);
      this.props.onSave();

      // update filterlist
      this.loadFilterList();
    }
  }

  render() {
    return (
      <>
        <FilterableListDialog
          id="dialogList"
          singleSelection
          open={this.props.open}
          title={strings.filter.saveFilter}
          text={strings.filter.filterName}
          refuseLabel={strings.dialog.answer.cancel}
          onRefuse={() => this.props.onClose()}
          acceptLabel={strings.dialog.answer.save}
          onAccept={value => this.saveFilter(value)}
          list={this.state.filterList}
          regexToMatch={FILTER_NAME_VALIDATION_REGEX}
          inputErrorMessage={strings.filter.filterNameError}
          defaultValue={this.props.defaultSaveName}
        />

        <ValidationDialog
          id="validationDialog"
          open={this.state.showValidation}
          title={strings.dialog.title.areYouSure}
          text={strings.filter.overrideFilter}
          refuseLabel={strings.dialog.answer.cancel}
          onRefuse={() => this.setState({ showValidation: false })}
          acceptLabel={strings.dialog.answer.yes}
          onAccept={() => this.saveFilter(this.state.filterNameToValidate, true)}
        />
      </>
    );
  }

}

SaveFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  filterToSave: PropTypes.instanceOf(FilterProvider).isRequired,
  defaultSaveName: PropTypes.string,
};

export default SaveFilterDialog;
