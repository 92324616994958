import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ScanProvider from '../../Providers/Database/ScanProvider';
import ScanHistoryList from './ScanHistoryList/ScanHistoryList';
import { JwtHelperService } from '../../Utils/JwtHelperService';
import { updateToolbarInfo } from '../../store/actions/exportActions';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { History } from '@mui/icons-material';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import { styles } from './ScanHistoryPage.style';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { withGuide } from '../Guides/Guidable';
import { theme } from '../../Utils/Theme';

// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};

const ALLSCANSTAB = 0;
const OOBSCANSTAB = 1;

class ScanHistoryPage extends Component {

  state = {
    allScans: [],
    oobScans: [],
    tab: 0
  };

  toolbarUpdate = () => {
    this.props.updateToolbarInfo({
      title: strings.mainMenu.history,
      icon: (<History/>),
      actions: this.props.onMobile
        ? [ToolbarActionsConstants.CALENDAR_FILTER, ToolbarActionsConstants.SET_CSVDATA,
          ToolbarActionsConstants.START_TUTORIAL]
        : [ToolbarActionsConstants.START_TUTORIAL]
    });
  };

  async componentDidMount() {
    this.toolbarUpdate();
    const userLogin = JwtHelperService.getUserLogin();

    // Get all user's scans
    const allScans = await ScanProvider.getAllScansWithInstruments(userLogin);
    // Get all out of bound scans of the user
    const oobScans = await ScanProvider.getOobScansWithInstruments(userLogin);

    this.setState({ allScans, oobScans });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onMobile !== this.props.onMobile) {
      this.toolbarUpdate();
    }
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab });
  };

  handleChangeIndex = index => {
    this.setState({ tab: index });
  };

  render() {
    // Mobile format
    if (this.props.onMobile) {
      return (
        <>
          <AppBar position="static">
            <Tabs
              value={this.state.tab}
              onChange={this.handleChangeTab}
              variant="fullWidth"
            >
              <Tab label={strings.historyPage.allScans}/>
              <Tab label={strings.historyPage.oobScans}/>
            </Tabs>
          </AppBar>
          <SwipeableViews
            containerStyle={{ height: '100%' }}
            sx={{...styles(theme).fullHeight}}
            axis="x"
            index={this.state.tab}
            onChangeIndex={this.handleChangeIndex}
          >
            <ScanHistoryList scans={this.state.allScans} actifForFilter={this.state.tab === ALLSCANSTAB}/>
            <ScanHistoryList scans={this.state.oobScans} actifForFilter={this.state.tab === OOBSCANSTAB}/>
          </SwipeableViews>
        </>
      );
    } else {
      return (
        <Box component="div" id="historyContainer"
          sx={{...styles(theme).historicContainer}}>
          <Box component="div" sx={{...styles(theme).column}}>
            <ScanHistoryList
              cardHeader
              titleList={strings.historyPage.allScans}
              scans={this.state.allScans}
            />
          </Box>
          <Box component="div" sx={{...styles(theme).column}}>
            <ScanHistoryList
              cardHeader
              titleList={strings.historyPage.oobScans}
              scans={this.state.oobScans}
            />
          </Box>
        </Box>
      );
    }
  }

}

ScanHistoryPage.propTypes = {
  onMobile: PropTypes.bool,
  updateToolbarInfo: PropTypes.func,
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps))(withGuide(ScanHistoryPage));
