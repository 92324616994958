import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  mainInformation: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: "12px"
    },
  },
  leftBloc: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "0px"
    },
  },
  profile: {
    color: '#717171'
  },
  classicSeparateText: {
    marginBottom: theme.spacing(2),
  },
  doubleSeparateText: {
    marginBottom: theme.spacing(3),
  },
  username: {
    padding: theme.spacing(),
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.MainMenu.topMenu,
    borderRadius: `${theme.spacing()} ${theme.spacing()} 0px 0px`,
    color: theme.palette.secondary.main,
  },
  rightBlock: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
  mainInformationProfileIcon: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-end',
  },
  userAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginBottom: theme.spacing(),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    color: theme.palette.MainMenu.topMenu,
  },
  userLogin: {
    textAlign: 'center'
  },
  logoutButton: {
    maxWidth: "190px",
    maxHeight: "40px",
    padding: theme.spacing(),
    borderRadius: theme.spacing(),
    color: '#d95555',
  },
  logoutIcon: {
    marginRight: theme.spacing(2),
    position: 'relative'
  },
  logoutIconOnMobile: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  }
});
