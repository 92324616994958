import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  icon: {
    marginRight: "5px",
    opacity: 0.8
  },
  item: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'start'
  }
});

