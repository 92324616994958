import {
  LOCK_GUIDE,
  UNLOCK_GUIDE,
  SET_STATUS,
  SET_MODE
} from './actionTypes';

export function lock(payload) {
  return { type: LOCK_GUIDE, payload };
}

export function unlock(payload) {
  return { type: UNLOCK_GUIDE, payload };
}

export function setStatus(payload) {
  return { type: SET_STATUS, payload };
}

export function setMode(payload) {
  return { type: SET_MODE, payload };
}