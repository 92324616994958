import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ArrowBack, PhotoCamera, Keyboard, Add } from '@mui/icons-material';
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  SvgIcon,
  Box
} from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { ScanMethod } from '../../../Utils/Constants/ScanMethod';
import { svgConstants } from '../../../Utils/Constants/SvgConstants';
import { styles } from './ScanHeader.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { ScanConstants } from '../../../Utils/Constants/ScanConstants';
import { ToastService } from '../../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../../Utils/Constants/ToastTypes';
import PAGES_IDS from '../../../Utils/Constants/PagesIDs';
import CameraScan from '../../CameraScan/CameraScan';
import { JwtHelperService } from '../../../Utils/JwtHelperService';
import { STATUSES } from '../../../Utils/Constants/GuideConstants';
import { theme } from '../../../Utils/Theme';
import { merge } from 'lodash';

let scanMethods = [ScanMethod.MANUAL, ScanMethod.QUICKSCAN, ScanMethod.CAMERA];

export const mapStateToProps = state => {
  return {
    guideStatus: state.guideReducer.status
  };
};

class ScanHeader extends Component {

  constructor(props) {
    super(props);
    // Disable Camera in Guest mode
    if (JwtHelperService.isLoggedAsGuest()) {
      scanMethods = [ScanMethod.MANUAL, ScanMethod.QUICKSCAN];
    }
    this.scanDivRef = React.createRef();
  }

  state = {
    typedBarcode: '',
    quickscanValue: '',
    cameraOpen: false
  };

  componentDidMount() {
    this.scanDivRef.current.focus();
  }

  componentDidUpdate(prevProps, prevState) {
    this.scanDivRef.current.focus();
    if (this.props.currentScanMethod === ScanMethod.MANUAL && this.textField && this.state.typedBarcode !== prevState.typedBarcode) {
      this.textField.focus();
    }
  }

  getTheme() {
    const newTheme = {};
    switch (this.props.currentPage) {
      case PAGES_IDS.INTERVENTION_CREATION:
      case PAGES_IDS.INTERVENTION:
      case PAGES_IDS.INTERVENTION_REQUEST:
        newTheme.selectedScanButton = merge({}, styles(theme).selectedScanButton, styles(theme).interventionSelectedScanButton);
        newTheme.mainColor = merge({}, styles(theme).scanButton, styles(theme).interventionMainColor);
        newTheme.header = merge({}, styles(theme).header, styles(theme).interventionHeader);
        newTheme.headerTitle = merge({}, styles(theme).headerTitle, styles(theme).interventionHeaderTitle);
        break;
      case PAGES_IDS.INVENTORY:
        newTheme.selectedScanButton = merge({}, styles(theme).selectedScanButton, styles(theme).inventorySelectedScanButton);
        newTheme.mainColor = merge({}, styles(theme).scanButton, styles(theme).inventoryMainColor);
        newTheme.header = merge({}, styles(theme).header, styles(theme).inventoryHeader);
        newTheme.headerTitle = merge({}, styles(theme).headerTitle, styles(theme).inventoryHeaderTitle);
        break;
      default:
        newTheme.selectedScanButton = styles(theme).selectedScanButton;
        newTheme.mainColor = styles(theme).scanButton;
        newTheme.header = styles(theme).header;
        newTheme.headerTitle = styles(theme).headerTitle;
    }
    return newTheme;
  }

  handleScan = () => {
    const barcode = this.state.typedBarcode;
    if (barcode.length > 0 && new RegExp(ScanConstants.BARCODE_REGEXP).test(barcode)) {
      this.props.onScan(barcode);
      this.setState({ typedBarcode: '' });
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.wrongBarcode);
    }
    this.textField.focus();
  };

  handleCameraScan = barcode => {
    if (barcode.length > 0 && new RegExp(ScanConstants.BARCODE_REGEXP).test(barcode)) {
      this.props.onScan(barcode);
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.wrongBarcode);
    }
  }

  handleInputChange = e => {
    this.setState({ typedBarcode: e.target.value });
  };

  handleKeyPress = e => {
    if (e.key === ScanConstants.ENTER_KEY) {
      this.handleScan();
    }
  };

  onKeyPressed = e => {
    if (this.props.currentScanMethod !== ScanMethod.QUICKSCAN) {
      return;
    }
    const char = e.key;
    if (new RegExp(ScanConstants.SUPPORTED_CHARACTERS_REGEXP).test(char)) {
      this.setState(prevState => ({
        quickscanValue: prevState.quickscanValue.concat(char)
      }));
    } else if (char === ScanConstants.ENTER_KEY) {
      this.state.quickscanValue.length > 0 && this.props.onScan(this.state.quickscanValue);
      this.setState({
        quickscanValue: ''
      });
    }
  };

  onCameraClose = () => {
    this.setState({ cameraOpen: false });
    this.onSelectScanMethod(ScanMethod.QUICKSCAN);
  };

  onSelectScanMethod = scanMethod => {
    this.props.onSelectScanMethod(scanMethod);
    if (scanMethod === ScanMethod.CAMERA && this.props.guideStatus !== STATUSES.RUNNING) {
      this.setState({ cameraOpen: true });
    }
    if (scanMethod === ScanMethod.MANUAL) {
      this.scanDivRef.current.focus();
    }
  }

  generateScanButtons(newTheme) {
    return scanMethods.map(scanMethod => {
      return (
        <IconButton
          id={'button-' + scanMethod}
          key={scanMethod}
          sx={this.props.currentScanMethod === scanMethod ? {...newTheme.selectedScanButton} : {...newTheme.mainColor}}
          onClick={() => this.onSelectScanMethod(scanMethod)}
          size="large">
          {scanMethod === ScanMethod.MANUAL && <Keyboard />}
          {scanMethod === ScanMethod.QUICKSCAN && <SvgIcon>
            <path d={svgConstants.quickscan} />
          </SvgIcon>}
          {scanMethod === ScanMethod.CAMERA && <PhotoCamera />}
        </IconButton>
      );
    });
  }

  render() {
    const newTheme = this.getTheme();
    return (
      <Box component="div" id="scanContainer" ref={this.scanDivRef} sx={{...newTheme.header}} tabIndex={0} onKeyDown={e => this.onKeyPressed(e)}>
        <Box component="div" sx={{...styles(theme).headerTop}}>
          <Box component="div" sx={{...styles(theme).title_with_sub}}>
            <Typography variant="h6" sx={{...newTheme.headerTitle}}>
              {([PAGES_IDS.SCAN, PAGES_IDS.INSTRUMENTS].includes(this.props.currentPage)) && (
                <IconButton
                  id="closeScan"
                  onClick={this.props.onClose}
                  sx={{...styles(theme).backButton}}
                  size="large">
                  <ArrowBack />
                </IconButton>)}
              {this.props.title ? this.props.title : strings.scanPage.scanTitle}
            </Typography>
            <Box component="label"
              sx={{...styles(theme).instrumentsNbLabel}}
              style={{ display: this.props.underLabel ? 'inline' : 'none' }}
            >
              {this.props.underLabel}
            </Box>
          </Box>
          <div id="scanButtons">
            {
              this.generateScanButtons(newTheme)
            }
          </div>
        </Box>
        <Box component="div" sx={{...styles(theme).headerBottom}}>
          <TextField
            id="textfield"
            inputRef={field => this.textField = field}
            autoFocus={this.props.currentScanMethod === ScanMethod.MANUAL}
            sx={{...styles(theme).barcodeInput}}
            placeholder={this.props.currentScanMethod === ScanMethod.MANUAL ?
              strings.scanPage.manualScanPlaceholder : strings.scanPage.quickscanPlaceholder}
            type='text'
            value={this.props.currentScanMethod === ScanMethod.MANUAL ? this.state.typedBarcode : this.state.quickscanValue}
            disabled={this.props.currentScanMethod !== ScanMethod.MANUAL}
            onChange={e => this.handleInputChange(e)}
            onKeyPress={this.handleKeyPress}
            variant='standard'
            InputProps={{
              disableUnderline: true,
              sx: { input: {...styles(theme).inputPlaceholder} },
              startAdornment:
                <InputAdornment position="start" sx={{...styles(theme).inputLeftIcon}}>
                  {this.props.currentScanMethod === ScanMethod.MANUAL ?
                    <Keyboard sx={{ ...styles(theme).iconColor }} /> :
                    <SvgIcon sx={{ ...styles(theme).iconColor }}>
                      <path d={svgConstants.quickscan} />
                    </SvgIcon>
                  }
                </InputAdornment>,
              endAdornment:
                <InputAdornment position="end" >
                  <IconButton
                    disabled={this.props.currentScanMethod !== ScanMethod.MANUAL}
                    onClick={this.handleScan}
                    sx={{ "&.MuiIconButton-root": {...styles(theme).iconColor}}}
                    size="large">
                    <Add />
                  </IconButton>
                </InputAdornment>
            }}
          />
        </Box>

        {this.state.cameraOpen &&
          <CameraScan
            id="cameraScan"
            open={this.state.cameraOpen}
            onClose={this.onCameraClose}
            onScan={this.handleCameraScan} />
        }
      </Box >
    );
  }
}

ScanHeader.propTypes = {
  currentScanMethod: PropTypes.string,
  onSelectScanMethod: PropTypes.func.isRequired,
  guideStatus: PropTypes.number,
  onClose: PropTypes.func,
  underLabel: PropTypes.string,
  title: PropTypes.string,
  currentPage: PropTypes.number
};

export default compose(
  connect(mapStateToProps, null)
)(ScanHeader);
