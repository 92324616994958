import ScanService from '../Providers/WebService/ScanService';
import InstrumentsProvider from '../Providers/Database/InstrumentsProvider';
import InstrumentHelper from '../Helpers/InstrumentHelper';
import InstrumentService from '../Providers/WebService/InstrumentService';
import UserProvider from '../Providers/Database/UserProvider';
import Scan from '../Utils/Database/Models/ScanModel';
import { ConnectivityChecker } from '../Utils/ConnectivityChecker';
import { ScanAnalysis } from '../Utils/Constants/ScanAnalysis';
import { BarcodeType } from '../Utils/Constants/BarcodeType';
import { JwtHelperService } from '../Utils/JwtHelperService';
import { LocalizedString as strings } from '../Utils/Constants/LocalizedString';
import { logger } from '../Utils/Logger';
import db from '../Utils/Database/Database';
import { arrayFindMatchingStrings } from '../Utils/StringUtils';

class ScanHelper {

  /**
   * Create scan both locally and on the server
   */
  static async createScan(barcode, scanType, instrument, page, serverOnly = false) {
    const date = new Date();
    let barcodeType = BarcodeType.NONE.toUpperCase();
    const login = JwtHelperService.getUserLogin();

    const scan = new Scan();
    if (serverOnly) {
      if (instrument) {
        barcodeType = InstrumentHelper.getBarcodeType(instrument, barcode);
      }

      scan.setValues(
        date.getTime(),
        date,
        scanType,
        true,
        page,
        instrument ? ScanAnalysis.OK : ScanAnalysis.NOT_FOUND,
        barcodeType,
        barcode,
        login,
        instrument ? instrument.marquage : null
      );

      await ScanService.createScan(scan);

      return scan;
    }

    // Initialize values
    let isInUserPerimeter = false;
    let marquage = null;

    // Set a few more values
    if (instrument) {
      marquage = instrument.marquage;
      isInUserPerimeter = serverOnly ? true : await UserProvider.isInUserPerimeter(instrument.marquage, login);
      barcodeType = InstrumentHelper.getBarcodeType(instrument, barcode);
    }

    // Insuring data is correct before sending to server
    if (!scanType || !barcode || !page || !login) {
      throw new Error();
    }

    scan.setValues(
      date.getTime(),
      date,
      scanType,
      isInUserPerimeter,
      page,
      ScanAnalysis.NONE,
      barcodeType,
      barcode,
      login,
      marquage
    );

    const hasInternetAccess = await ConnectivityChecker.isConnected();

    // Create scan on server if internet access
    if (hasInternetAccess) {
      try {
        const createdScan = await ScanService.createScan(scan);
        scan.serverId = createdScan.id;
        scan.analysis = createdScan.analysis;
      } catch (error) {
        logger(error);
        return null;
      }
    }

    // Create scan locally
    scan.save();

    return scan;
  }

  /**
   * Try to get corresponding instrument from barcode
   * In ambiguous case, return list of ambiguous instruments
   */
  static async verifyInstrument(barcode, serverOnly = false, disallowUnauthorized = false) {
    const cleanBarcode = barcode.trim();

    let instruments = null;

    if (serverOnly) {
      instruments = await InstrumentService.getInstrumentsByBarcode(encodeURIComponent(cleanBarcode));
      return instruments;
    }

    const hasInternetAccess = await ConnectivityChecker.isConnected();
    let instrument = null;

    // Get list of corresponding instruments for the current barcode from local database
    instruments = await InstrumentsProvider.getInstrumentsByBarcode(cleanBarcode);

    // if it is set to true, disallow sending back an instrument that the user should not have access to
    if (disallowUnauthorized) {
      const unauthorizedInstrument = await InstrumentsProvider.filterInstrumentsBelongingToUser([barcode]);
      if (unauthorizedInstrument && unauthorizedInstrument.length > 0) {
        return null;
      }
    }

    if (instruments.length >= 1) {
      // If more than 1 instrument, return them all
      return instruments;
    } else if (instruments.length === 0 && hasInternetAccess && !disallowUnauthorized) {
      // If no instrument are found in local database, try to find one on the server
      // if disallowUnauthorized is set to true, prevent searching on server
      // because it means the user no longer has access to instrument
      instrument = await InstrumentService.getInstrumentByBarcode(encodeURIComponent(cleanBarcode));
      return [instrument];
    }
    else {
      // If no condition are met, instrument stays null
      return instrument;
    }


  }

  /**
   * Return scan result message from analysis
   */
  static getMessageFromAnalysis(scan) {
    const barcode = scan.scannedBarcode;

    switch (scan.analysis) {
      case ScanAnalysis.ARCHIVED:
        return strings.scanMessage.messageArchived(barcode);
      case ScanAnalysis.OUT_OF_BOUND:
        return strings.scanMessage.messageOutOfBounds(barcode);
      case ScanAnalysis.NOT_FOUND:
        return strings.scanMessage.messageNotFound(barcode);
      case ScanAnalysis.LOST:
        return strings.scanMessage.messageLost(barcode);
      default:
        return strings.scanMessage.messageFallback;
    }
  }

  static async instrumentMessageScanMatched(instrument) {
    const allMessagesScans = await db.messagesScans.toArray();

    for(let index = 0; index < allMessagesScans.length; index++) {
      const messageScan = allMessagesScans[index];
      const dataToCompare = [
        { array: messageScan.statuses, label: instrument.etat },
        { array: messageScan.states, label: instrument.etatMetrologique },
      ];

      dataToCompare.sort((a, b) => a.array.length > b.array.length);

      let i = 0;
      let match = true;
      while (i < dataToCompare.length) {
        if (dataToCompare[i].array.length === 0) {
          i++;
          continue;
        }
        if (!arrayFindMatchingStrings(dataToCompare[i].array, dataToCompare[i].label)) {
          match = false;
          break;
        }
        i++;
      }

      if (match) {
        return messageScan;
      }
    }

    return null;
  }
}

export default ScanHelper;
