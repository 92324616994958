import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon, Icon, Typography, Box } from '@mui/material';
import { styles } from './ScanHistoryItem.style';
import { ScanMethod } from '../../../Utils/Constants/ScanMethod';
import { Keyboard, PhotoCamera, CheckCircle, Warning, Help, Archive, Cancel, Error as ErrorIcon } from '@mui/icons-material';
import { svgConstants } from '../../../Utils/Constants/SvgConstants';
import { ScanAnalysis } from '../../../Utils/Constants/ScanAnalysis';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import dayjs from 'dayjs';
import { theme } from '../../../Utils/Theme';

const generateMethodIcon = prop => {
  let icon;
  switch (prop.scan.method) {
    case ScanMethod.MANUAL:
      icon = <Icon>
        <Keyboard />
      </Icon>;
      break;
    case ScanMethod.QUICKSCAN:
      icon = <SvgIcon>
        <path d={svgConstants.quickscan} />
      </SvgIcon>;
      break;
    case ScanMethod.CAMERA:
      icon = <Icon>
        <PhotoCamera />
      </Icon>;
      break;
    case ScanMethod.NONE:
    default:
      icon = <Icon>
        <ErrorIcon />
      </Icon>;
      break;
  }
  return icon;
};

const generateStatusIcon = prop => {
  let iconStatus = <CheckCircle />;
  switch (prop.scan.analysis) {
    case ScanAnalysis.OK:
      iconStatus = <CheckCircle style={{ color: theme.palette.validationGreenTheme.main }} />;
      break;
    case ScanAnalysis.OUT_OF_BOUND:
      iconStatus = <Warning style={{ color: theme.palette.blackTheme.main }} />;
      break;
    case ScanAnalysis.ARCHIVED:
      iconStatus = <Archive style={{ color: theme.palette.purpleTheme.main }} />;
      break;
    case ScanAnalysis.LOST:
      iconStatus = <Help style={{ color: theme.palette.orangeTheme.main }} />;
      break;
    case ScanAnalysis.NOT_FOUND:
      iconStatus = <Cancel style={{ color: theme.palette.redTheme.main }} />;
      break;
    case ScanAnalysis.NONE:
    default:
      iconStatus = <Icon>
        <ErrorIcon />
      </Icon>;
      break;
  }
  return <Icon>{iconStatus}</Icon>;
};

const generateTypography = (classe, content) => {
  return (
    <Typography
      variant="body1"
      sx={{ "&.MuiTypography-root": {...classe} }}
      noWrap
    >
      {content}
    </Typography>
  );
};

const ScanHistoryItem = props => {

  return (
    <Box component="div"
      sx={{...styles(theme).instrumentContainer}}
      style={props.style}
      onClick={props.scan.analysis === ScanAnalysis.OK ? () => props.onItemClick(props.scan.instrument) : null}
    >
      <Box component="div" sx={{...styles(theme).status}}>
        {generateStatusIcon(props)}
        {generateTypography(styles(theme).labelStatus,
          props.generateStatusLabel)}
      </Box>
      <Box component="div" sx={{...styles(theme).instrumentInfos}}>
        {generateTypography(styles(theme).center,
          props.scan.scannedBarcode)}
        {generateTypography(styles(theme).center,
          props.scan.instrument ? props.scan.instrument.constructeur : strings.general.noInformation)}
        {generateTypography(styles(theme).center,
          props.scan.instrument ? props.scan.instrument.referenceConstructeur : strings.general.noInformation)}
        {generateTypography(styles(theme).center,
          props.scan.instrument ? props.scan.instrument.utilisateur
            ? props.scan.instrument.utilisateur : strings.general.noInformation : strings.general.noInformation)}
      </Box>
      <Box component="div" sx={{...styles(theme).scanInfos}}>
        {generateTypography(styles(theme).right,
          dayjs(props.scan.date).format(strings.general.dateFormat))}
        {generateTypography(styles(theme).right,
          dayjs(props.scan.date).format(strings.general.timeFormat))}
        {generateTypography(styles(theme).right,
          props.generateScanPage)}
        {generateMethodIcon(props)}
      </Box>
    </Box>
  );

};

ScanHistoryItem.propTypes = {
  style: PropTypes.object,
  scan: PropTypes.object.isRequired,
  generateScanPage: PropTypes.string.isRequired,
  generateStatusLabel: PropTypes.string.isRequired,
  onItemClick: PropTypes.func
};

export default ScanHistoryItem;
