import React from 'react';
import { Paper, IconButton, Box } from '@mui/material';
import { styles } from './InterventionItem.style';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { LocalShipping, Close } from '@mui/icons-material';
import { InterventionRequestConstants } from '../../../Utils/Constants/InterventionRequestConstants';
import { theme } from '../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    activeUser: state.generalReducer.loggedUser
  };
};

const InterventionItem = props => {


  const getRemoveButton = (item, user) => {
    if (item.applicant.includes(user.firstName) && item.applicant.includes(user.lastName)) {
      return (
        <IconButton
          id="removeButton"
          sx={{...styles(theme).iconButton}}
          onClick={event => {
            event.stopPropagation();
            props.onRemove(props.interventionInfo);
          }}
          size="large">
          <Close />
        </IconButton>
      );
    }
  };
  
  const getTruckButton = item => {
    if (item.status === InterventionRequestConstants.AWAITING_DISPATCH && dayjs(item.expectedDate).isAfter(item.creationDate)) {
      return (
        <IconButton
          id="truckButton"
          sx={{...styles(theme).iconButton}}
          onClick={event => {
            event.stopPropagation();
            props.onSend(props.interventionInfo);
          }}
          size="large">
          <LocalShipping />
        </IconButton>
      );
    }
  };

  return (
    <Paper elevation={2} id="container" sx={{...styles(theme).listItem}} onClick={() => props.onClick(props.interventionInfo)}>
      <Box component="div" sx={{...styles(theme).header}}>
        <Box component="div"  sx={{...styles(theme).dps}}>{props.interventionInfo.interventionRequestNumber}</Box>
        <div>
          {getRemoveButton(props.interventionInfo, props.activeUser)}
          {getTruckButton(props.interventionInfo)}
        </div>
      </Box>
      <Box component="div" sx={{...styles(theme).body}}>
        <Box component="div" sx={{...styles(theme).line}}>
          <b>{props.interventionInfo.interventionType}</b>
          <Box component="div"  sx={{...styles(theme).grayText}}>{props.interventionInfo.applicant}</Box>
        </Box>
        <Box component="div" sx={{...styles(theme).line}}>
          <Box component="div"  sx={{...styles(theme).grayText}}>{strings.interventionHome.instrumentCount(props.interventionInfo.instruments.length)}</Box>
          <Box component="div"  sx={{...styles(theme).grayText}}>{dayjs(props.interventionInfo.creationDate).format(strings.general.dateFormat)}</Box>
        </Box>
        <Box component="div" sx={{...styles(theme).line}}>
          <Box component="div" sx={{...styles(theme).grayText}}>{strings.interventionHome.expedition +
            dayjs(props.interventionInfo.expectedDate).format(strings.general.dateFormat)}</Box>
          <Box component="div"  sx={{...styles(theme).grayText}}>{dayjs(props.interventionInfo.creationDate).format(strings.general.timeFormat)}</Box>
        </Box>
      </Box>
    </Paper>
  );
};

InterventionItem.propTypes = {
  interventionInfo: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default compose(
  connect(mapStateToProps, null)
)(InterventionItem);
