import sharedStyles from '../../../../../Utils/SharedStyles';

export const styles = theme => ({
  ...sharedStyles(theme),
  pageCard: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing()
  },
  contentCard: {
    backgroundColor: theme.palette.secondary.main,
    flex: 1,
    '&:last-child': {
      padding: "0px"
    },
  },
  mobileCartContent: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
      height: '100%',
      width: '100%'
    },
    overflow: 'hidden'
  },
  floating_button: {
    position: 'fixed',
    bottom: '12vh',
    right: theme.spacing(2),
  },
  listContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
});
