import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6%',
  },
  spaceButton: {
    marginBottom: theme.spacing(4),
  },
  icon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    fontSize: '29px',
  },
  button: {
    color: 'white',
    width: '100%',
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: theme.spacing(2.5),
    height: theme.spacing(8),
    fontSize: theme.spacing(2.5),
  },
  defaultButton: {
    backgroundColor: 'var(--background-color)!important',
  },
  notificationPanel: {
    backgroundColor: theme.palette.toasts.warning,
    color: 'white',
    padding: "15px",
    fontWeight: 'bold',
    cursor: 'pointer'
  }
});

