export let styles = () => ({
  content: {
    display: 'flex',
    width: '95%',
    maxHeight: '100%',
    margin: 'auto',
    flexFlow: 'row wrap',
    justifyContent: 'start',
    borderRadius: "8px",
  },
  header: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'flex-end',
    padding: "8px"
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  title: {
    marginRight: "16px"
  },
  expandedPanel: {
    padding: "0px"
  }
});
