import React from 'react';
import PropTypes from 'prop-types';
import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { styles } from './ScanChoiceDialog.style';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { theme } from '../../../Utils/Theme';

const ScanChoiceDialog = props => {

  return (
    <SimpleDialog
      open={props.open}
      title={strings.dialog.title.chooseInstruments}
    >
      <List sx={{ "&.MuiList-root": {...styles(theme).list}}}>
        {props.instruments.map(instrument => (
          <ListItemButton
            onClick={() => props.onSelect(instrument)}
            key={instrument.marquage}
            sx={{...styles(theme).verticalInformation}}
          >
            <ListItemText primary={instrument.designation} />
            <ListItemText sx={{ "&.MuiListItemText-root": {...styles(theme).root}}} secondary={instrument.referenceConstructeur} />
            <Box component="div" sx={{...styles(theme).inlineInformation}}>
              <ListItemText sx={{ "&.MuiListItemText-root": {...styles(theme).root}}}
                secondary={instrument.marquage} />
              <ListItemText sx={{...styles(theme).rightPosition}} secondary={instrument.constructeur} />
            </Box>
          </ListItemButton>
        ))}
      </List>
    </SimpleDialog >
  );
};

ScanChoiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  instruments: PropTypes.array.isRequired
};

export default ScanChoiceDialog;
