export let styles = theme => ({
  card: {
    margin: theme.spacing(),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    maxWidth: "500px"
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.blueTheme.main}`,
    paddingBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  bottom: {
    display: 'flex',
    flexDirection: 'column',
    margin: `${theme.spacing()} ${theme.spacing(2)}`,
    marginTop: "0px",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(),
  },
  favorite: {
    position: 'relative',
  },
  left: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  instrumentImage: {
    width: "100px",
    height: "100px",
    lineHeight: '100px',
    textAlign: 'center'
  },
  borderCard: {
    border: `2px solid ${theme.palette.redTheme.main}`
  },
  defaultColorText: {
    color: theme.palette.MainMenu.pageTitles,
  },
});

