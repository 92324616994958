import {
  SET_FILTER,
  RESET_FILTERS,
  UPDATE_TOOLBAR_INFO,
  TOGGLE_FAVORITE_FILTER,
  TOGGLE_FILTER_PAGE,
  TOGGLE_SAVED_FILTERS,
  HANDLE_CHANGE_DATEPICKER,
  SET_CSVDATA,
  INTERVENTION_SUBMIT_INFO
} from '../actions/actionTypes';

const initialState = {
  toolbarInfo: { actions: [] },
  showFavorites: false,
  showFilterPage: false,
  showSavedFilters: false,
  activeFilter: undefined,
  selectedDate: null,
  csvData: [],
  showInterventionSubmitInfo: false,
  isActiveFilterSet: false,
};

const updateToolbarInfo = (state, action) => {
  return { ...state, toolbarInfo: action.payload };
};

const toggleFavorites = state => {
  return { ...state, showFavorites: !state.showFavorites };
};

const toggleFilterPage = state => {
  return { ...state, showFilterPage: !state.showFilterPage };
};

const toggleSavedFilter = (state, action) => {
  return { ...state, showSavedFilters: action.payload };
};

const setFilter = (state, action) => {
  return {
    ...state,
    activeFilter: action.payload,
    isActiveFilterSet: action.payload != null && !action.payload.isEmpty()
  };
};

const resetFilters = state => {
  return {
    ...state,
    showFavorites: false,
    activeFilter: undefined,
    isActiveFilterSet: false
  };
};

const handleChangeDatePicker = (state, action) => {
  return { ...state, selectedDate: action.payload };
};

const setCSVData = (state, action) => {
  return { ...state, csvData: action.payload };
};

const toggleInterventionSubmitInfo = (state) => {
  return { ...state, showInterventionSubmitInfo: !state.showInterventionSubmitInfo };
};

const toolbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TOOLBAR_INFO:
      return updateToolbarInfo(state, action);
    case TOGGLE_FAVORITE_FILTER:
      return toggleFavorites(state);
    case TOGGLE_FILTER_PAGE:
      return toggleFilterPage(state);
    case TOGGLE_SAVED_FILTERS:
      return toggleSavedFilter(state, action);
    case SET_FILTER:
      return setFilter(state, action);
    case RESET_FILTERS:
      return resetFilters(state);
    case HANDLE_CHANGE_DATEPICKER:
      return handleChangeDatePicker(state, action);
    case SET_CSVDATA:
      return setCSVData(state, action);
    case INTERVENTION_SUBMIT_INFO:
      return toggleInterventionSubmitInfo(state);
    default:
      return state;
  }
};

export default toolbarReducer;
