import React from 'react';
import { styles } from './GenericCard.style';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent, Divider, Typography } from '@mui/material';
import { theme } from '../../Utils/Theme';


const GenericCard = props => {

  return (
    <Card sx={{...styles(theme).card}}>
      {props.cardHeader &&
        <>
          <CardHeader
            sx={{
              "& .MuiCardHeader-action": {...styles(theme).actionsHeader},
              "&.MuiCardHeader-root": {...styles(theme).cardHeader}
            }}
            title={
              props.title &&
              <Typography
                variant="h5"
                sx={{ "& .Mui-h5": {...styles(theme).title} }}
              >
                {props.title}
              </Typography>}
            subheader={props.subheader}
            action={props.actionsHeader}
          />
          <Divider
            variant="middle"
            sx={{...styles(theme).divider}} />
        </>}
      <CardContent sx={{ "&.MuiCardContent-root": {...styles(theme).cardContent}}}>
        {props.children}
      </CardContent>
    </Card>
  );

};

GenericCard.propTypes = {
  cardHeader: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subheader: PropTypes.string,
  actionsHeader: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.element,
    PropTypes.object
  ]).isRequired
};

export default GenericCard;
