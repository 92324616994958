import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  verticalInformation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start'
  },
  root: {
    padding: "0px"
  },
  inlineInformation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  list: {
    [theme.breakpoints.down('sm')]: {
      padding: "0px",
    }
  }
});
