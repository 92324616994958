import { Box, Button, FormControl, Input, InputLabel, Paper, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Help } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import logo from '../../Assets/applicationIcon.png';
import VersionService from '../../Providers/WebService/VersionService';
import { AuthenticationHelper, TokenStatus } from '../../Helpers/AuthenticationHelper';
import { HttpConstants } from '../../Utils/Constants/HttpConstants';
import LoadingSpinner from '../Utils/LoadingSpinner/LoadingSpinner';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { PagesPaths } from '../../Utils/Constants/PagesPaths';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import { SnackbarMessages } from '../../Utils/Constants/SnackbarMessages';
import { styles } from './AuthenticationPage.style';
import PasswordForgottenModalContent from './PasswordForgottenModalContent';
import UserProvider from '../../Providers/Database/UserProvider';
import {
  resetReduxState,
  saveUserInformations,
  shouldRedirectToLogin
} from '../../store/actions/exportActions';
import routes from '../../Utils/Routes';
import InstrumentsProvider from '../../Providers/Database/InstrumentsProvider';
import store from '../../store/store';
import { ConnectivityChecker } from '../../Utils/ConnectivityChecker';
import { JwtHelperService } from '../../Utils/JwtHelperService';
import UserHelper from '../../Helpers/UserHelper';
import crypto from 'crypto';
import FilterProvider from '../../Providers/Database/FilterProvider';
import HttpClient from '../../Providers/HttpClient';
import ExternalFilterHelper from '../../Helpers/ExternalFilterHelper';
import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import { isNotComputer } from '../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../Utils/Theme';
import { CryptoConstants } from '../../Utils/Constants/CryptoConstants';
import { enqueueSnackbar } from 'notistack';
import merge from 'lodash/merge';

export const mapDispatchToProps = dispatch => {
  return {
    saveUserInformations: value => dispatch(saveUserInformations(value)),
    shouldRedirectToLogin: value => dispatch(shouldRedirectToLogin(value))
  };
};

class AuthenticationPage extends Component {

  _isMounted = true;

  state = {
    openDialog: false,
    appVersion: '',
    identifier: '',
    password: '',
    loading: false,
    formError: false,
    alreadyConnected: false,
    isOffline: false,
    isSSOConnected: false,
  };

  componentDidMount() {
    this._isMounted = true;
    // If an external filter is set, act accordingly
    if(this.props.location.search.includes(globalConstants.externalFilterUrlKey)) {

      const rawMarquages = this.props.location.search.split('=')[1];
      const marquages = rawMarquages.split(',');

      const extFilter = {
        marquage: marquages
      };
      ExternalFilterHelper.saveExternalFilter(extFilter);

      const path = JwtHelperService.isUserConnected() ? PagesPaths.home : PagesPaths.root;
      this.props.navigate(path);
    }
    // If we are logged as guest and trying to access auth page, redirect to guest page
    if (JwtHelperService.getLoginType() === JwtHelperService.EXTERNAL_SSO_THALES_LOGIN_TYPE) {
      this.setState({ isSSOConnected: true});
      return;
    }

    this.hasLogin = false;
    this.login = false;

    const isRedirectedFromSSO = AuthenticationHelper.ssoRedirectMiddleware(this.props.location);

    if (isRedirectedFromSSO) {
      this.setState({ isSSOConnected: true});
      return;
    }

    UserHelper.saveDeviceInformations();

    if (this.props.location && this.props.location.state && this.props.location.state.login) {
      this.login = this.props.location.state.login;
      this.hasLogin = true;
    }

    ConnectivityChecker.isConnected().then(res => {
      if (this._isMounted) {
        this.setState({ isOffline: !res });
      }
    });

    // Offline network events
    window.addEventListener('offline', () => {
      this.setState({ isOffline: true });
    }, false);

    // Online network events
    window.addEventListener('online', () => {
      this.setState({ isOffline: false });
    }, false);

    // reset all in case of disconnection
    store.dispatch(resetReduxState());
    UserProvider.isAdmin = null;
    InstrumentsProvider.userInstrumentCount = null;
    FilterProvider.isInitialized = false;

    // check if user is already connected
    if (AuthenticationHelper.checkTokenValidity() === TokenStatus.OK) {
      this.setState({ alreadyConnected: true });
      return;
    }
    /// get application current version
    VersionService.getCurrentVersion()
      .then(version => {
        this.setState({
          appVersion: version
        });
      });
    // Reset redirection to login to false
    this.props.shouldRedirectToLogin(false);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /// save input values in the state
  handleInputChange = e => {
    const value = e.target.value.replace(/\s/g, '');
    const name = e.target.name;
    this.setState({
      [name]: value
    });
  };

  /// Handle click on connection button
  handleSubmit = e => {
    e.preventDefault();
    const login = this.hasLogin ? this.login : this.state.identifier;
    this.setState({ loading: true }, () => this.authenticateUser(login));
  };

  ssoButtonClicked = e => {
    e.preventDefault();
    HttpClient.get(routes.ssoLogin).then(ssoUrl => {
      window.location.assign(ssoUrl.data);
    });
  };

  // Try to log user in
  authenticateUser(login) {
    JwtHelperService.setOfflineUserStatus(false);
    AuthenticationHelper.authenticateUser(login, this.state.password)
      .then(async () => {
        const isOnline = await ConnectivityChecker.isConnected();
        enqueueSnackbar(SnackbarMessages.authSuccess.msg, SnackbarMessages.authSuccess.type);
        if (isOnline) {
          const hash = crypto.createHash(CryptoConstants.HASH);
          hash.update(this.state.password);
          const encryptedPassword = hash.digest('base64');
          UserHelper.getUserInfosFromServerAndUpdateDatabase(encryptedPassword, value => {
            this.props.saveUserInformations(value);
          });
          this.props.navigate(PagesPaths.sync);
        } else {
          JwtHelperService.setOfflineUserLogin(this.state.identifier);
          JwtHelperService.setOfflineUserStatus(true);
          this.props.navigate(PagesPaths.home);
        }

      })
      .catch(error => {
        this.setState({ loading: false, formError: true });
        // when the user is trying to log offline for the first time
        if (error === HttpConstants.NOT_FOUND) {
          enqueueSnackbar(SnackbarMessages.authOfflineError.msg, SnackbarMessages.authOfflineError.type);
        }
        if (error.status === HttpConstants.NOT_FOUND || error === HttpConstants.BAD_REQUEST
          || error.status === HttpConstants.UNAUTHORIZED) {
          enqueueSnackbar(SnackbarMessages.authError.msg, SnackbarMessages.authError.type);
        }
        if (error.status === HttpConstants.INTERNAL_SERVER_ERROR) {
          enqueueSnackbar(SnackbarMessages.authErrorServer.msg, SnackbarMessages.authErrorServer.type);
        }
      });
  }

  /// open forgot password dialog
  forgotPassword = () => {
    this.setState({
      openDialog: !this.state.openDialog
    });
  };

  render() {
    const content =
      <PasswordForgottenModalContent
        text={strings.auth.modalForgotPassword.text}
        mail={strings.auth.modalForgotPassword.mail}
      />;

    if (this.state.isSSOConnected) {
      return <Navigate exact to={PagesPaths.guest} />;
    }
  
    if (this.state.alreadyConnected) {
      return <Navigate exact to={PagesPaths.home} />;
    }

    return (
      <>
        {this.state.isOffline &&
          <Box component="div" sx={{...styles(theme).offlinePanel}}>
            {strings.auth.offlineLabel}
          </Box>
        }
        <Box component="main" sx={{...styles(theme).main}}>
          {this.state.loading && <LoadingSpinner />}
          <Paper sx={{...styles(theme).paper}} elevation={2}>
            <Box component="div" sx={{...styles(theme).logoContainer}}>
              <Box component="img" src={logo} alt={strings.alt.admLogo}
                sx={{...merge({}, styles(theme).logoADM, (isNotComputer && styles(theme).tabletLogoADM))}}
              />
              <Typography component="h1" variant="h5"
                sx={{...merge({}, styles(theme).authTitle, (isNotComputer && styles(theme).tabletTitleFontSize))}}>
                {strings.auth.authTitle}
              </Typography>
              <Box component="p" sx={{...merge({}, styles(theme).version, (isNotComputer && styles(theme).tabletFontSize))}}>
                {this.state.appVersion}
              </Box>
            </Box>
            <Box component="form" sx={{...styles(theme).form}} onSubmit={e => this.handleSubmit(e)} id="form">
              <FormControl margin="normal" required fullWidth sx={{ ...styles(theme).formInput }}>
                <InputLabel htmlFor="identifier"
                  sx={{...merge({}, styles(theme).input, (isNotComputer && styles(theme).tabletTitleFontSize), styles(theme).input_label)}}
                  error={this.state.formError}>{strings.auth.userLabel}</InputLabel>
                <Input
                  sx={{...merge({}, styles(theme).input, (isNotComputer && styles(theme).tabletTitleFontSize))}}
                  id="identifier"
                  name="identifier"
                  autoComplete="identifier"
                  autoFocus
                  value={this.login ? this.login : this.state.identifier}
                  onChange={this.handleInputChange}
                  disabled={this.hasLogin}
                  error={this.state.formError}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth sx={{ "&.MuiFormControl-root": {...styles(theme).formInput} }}>
                <InputLabel htmlFor="password"
                  sx={{...merge({}, styles(theme).input, (isNotComputer && styles(theme).tabletTitleFontSize), styles(theme).input_label)}}
                  error={this.state.formError}>{strings.auth.passwordLabel}</InputLabel>
                <Input
                  sx={{...merge({}, styles(theme).input, (isNotComputer && styles(theme).tabletTitleFontSize))}}
                  name="password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this.handleInputChange}
                  error={this.state.formError}
                />
              </FormControl>
              <Box component="div" sx={{...styles(theme).link}}>
                <Box component="label"
                  sx={{...merge({}, styles(theme).textLink, (isNotComputer && styles(theme).tabletFontSize))}}
                  id="forgotPassword"
                  onClick={this.forgotPassword}
                >
                  {strings.auth.forgotPwdLabel}
                </Box>
              </Box>
              <Box component="div" sx={{...styles(theme).buttonsContainer}}>
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  color="primary"
                  sx={{...merge({}, styles(theme).submit, (isNotComputer && styles(theme).tabletFontSize))}}
                  id="submit"
                >
                  {strings.auth.connectLabel}
                </Button>
                {!this.hasLogin && (
                  <>
                    <Typography
                      variant="body2"
                      sx={{...merge({}, styles(theme).orLabel, (isNotComputer && styles(theme).tabletFontSize))}}>
                      {strings.auth.orLabel}
                    </Typography>
                    <Button
                      onClick={e => this.ssoButtonClicked(e)}
                      size="medium"
                      variant="contained"
                      color="primary"
                      sx={{...merge({}, styles(theme).submitGuest, (isNotComputer && styles(theme).tabletFontSize))}}
                      id="ssoButton"
                    >
                      {strings.auth.guestModeLabel}
                      <Tooltip 
                        sx={{...merge({}, styles(theme).ssoHelp, (isNotComputer && styles(theme).tabletFontSize))}}
                        disableFocusListener
                        title={
                          <Box component="div" sx={{...(isNotComputer && merge({}, styles(theme).tabletFontSize, styles(theme).tabletLineHeight))}}>
                            <Typography
                              color="inherit">
                              {strings.auth.guestModeLabel}
                            </Typography>
                            <b>{strings.auth.ssoHelp}</b>
                          </Box>
                        }
                      >
                        <Help sx={{...merge({}, styles(theme).ssoHelp, (isNotComputer && styles(theme).tabletTitleFontSize))}} />
                      </Tooltip>
                    </Button>
                  </>
                )}
                {this.hasLogin && (
                  <Typography variant="body1" sx={{...styles(theme).reconnectLabel}}>{strings.auth.reconnect}</Typography>)}
              </Box>
              <SimpleDialog
                open={this.state.openDialog}
                title={strings.auth.modalForgotPassword.title}
                onRefuse={this.forgotPassword}
                acceptLabel={strings.general.ok}
                onAccept={this.forgotPassword}
                addContentPadding
              >
                {content}
              </SimpleDialog>
            </Box>
          </Paper>
        </Box>
      </>
    );
  }
}

AuthenticationPage.propTypes = {
  navigate: PropTypes.func,
  saveUserInformations: PropTypes.func,
  shouldRedirectToLogin: PropTypes.func,
  location: PropTypes.object
};

export default compose(
  connect(null, mapDispatchToProps)
)(AuthenticationPage);
