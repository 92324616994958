import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import React, { PureComponent } from 'react';
import {
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  SvgIcon,
  Tooltip,
  Box
} from '@mui/material';
import {
  Menu,
  NotificationsNone,
  Notifications,
  FilterList,
  Edit,
  Home,
  Info,
  Help
} from '@mui/icons-material';
import { styles } from './MainToolbar.style';
import {
  toggleFavorites,
  toggleFilterPage,
  toggleSavedFilter,
  resetInterventionRequest,
  toggleEditInstrumentsDialog,
  handleChangeDatePicker,
  updateCurrentPage,
  toggleInterventionSubmitInfo,
  setHelpDialogOpen,
  setStatus,
  unlock,
  openMultipleInterventionsInstruments
} from '../../store/actions/exportActions';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import { InterventionRequestConstants } from '../../Utils/Constants/InterventionRequestConstants';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { svgConstants } from '../../Utils/Constants/SvgConstants';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import DatePickerIcon from '../../Components/Utils/DatePickerIcon/DatePickerIcon';
import HistoryHelper from '../../Helpers/HistoryHelper';
import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import { STATUSES } from '../../Utils/Constants/GuideConstants';
import InventoryHelper from '../../Helpers/InventoryHelper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import CsvIcon from '../Utils/CsvIcon/CsvIcon';
import PdfDownloadButton from '../Utils/PdfDownloadButton/PdfDownloadButton';
import Badge from '@mui/material/Badge';
import Banner from '../Banner/Banner';
import { theme } from '../../Utils/Theme';
import merge from 'lodash/merge';

dayjs.extend(utc);

// maps toolbarReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    toolbarInfo: state.toolbarReducer.toolbarInfo,
    showFavorites: state.toolbarReducer.showFavorites,
    showFilterPage: state.toolbarReducer.showFilterPage,
    showSavedFilters: state.toolbarReducer.showSavedFilters,
    pdfDocuments: state.interventionReducer.pdfDocuments,
    onMobile: state.generalReducer.onMobile,
    currentPage: state.generalReducer.currentPage,
    activeStep: state.interventionReducer.activeStep,
    selectedDate: state.toolbarReducer.selectedDate,
    csvData: state.toolbarReducer.csvData,
    showInterventionSubmitInfo: state.toolbarReducer.showInterventionSubmitInfo,
    isActiveFilterSet: state.toolbarReducer.isActiveFilterSet,
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    toggleFavorites: showFavorites => dispatch(toggleFavorites(showFavorites)),
    toggleFilterPage: showFilterPage => dispatch(toggleFilterPage(showFilterPage)),
    toggleSavedFilter: showSavedFilters => dispatch(toggleSavedFilter(showSavedFilters)),
    resetInterventionRequest: values => dispatch(resetInterventionRequest(values)),
    toggleEditInstrumentsDialog: value => dispatch(toggleEditInstrumentsDialog(value)),
    handleChangeDatePicker: selectedDate => dispatch(handleChangeDatePicker(selectedDate)),
    updateCurrentPage: pageID => dispatch(updateCurrentPage(pageID)),
    toggleInterventionSubmitInfo: showInterventionSubmitInfo =>
      dispatch(toggleInterventionSubmitInfo(showInterventionSubmitInfo)),
    setHelpDialogOpen: value => dispatch(setHelpDialogOpen(value)),
    setStatus: value => dispatch(setStatus(value)),
    unlock: () => dispatch(unlock()),
    openMultipleInterventionsInstruments: value => dispatch(openMultipleInterventionsInstruments(value))
  };
};

class MainToolbar extends PureComponent {

  buildActionsButtons = () => {
    return <>
      {/* OPEN TUTORIAL DIALOG */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.MULTIPLE_INTERVENTION_INSTRUMENTS) >= 0 &&
        (<Tooltip title={strings.tooltips.helpInfo} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="openMultipleInterventionsInstruments"
            sx={{...styles(theme).menuAction}}
            color="inherit"
            onClick={() => this.props.openMultipleInterventionsInstruments(true)}
            size="large">
            <Help />
          </IconButton>
        </Tooltip>)}

      {/* OPEN TUTORIAL DIALOG */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.START_TUTORIAL) >= 0 &&
        (<Tooltip title={strings.tooltips.helpInfo} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="toolbarOpenGuide"
            sx={{...styles(theme).menuAction}}
            color="inherit"
            onClick={() => this.props.setStatus(STATUSES.INITIATED)}
            size="large">
            <Help />
          </IconButton>
        </Tooltip>)}

      {/* FILTER ON FAVORITES */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.FAVORITE_FILTER) >= 0 &&
        (<Tooltip title={strings.tooltips.favoriteFilter} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="toolbarFavorite"
            sx={{...styles(theme).menuAction}}
            color="inherit"
            onClick={() => this.props.toggleFavorites()}
            size="large">
            {this.props.showFavorites ? <Notifications /> : <NotificationsNone />}
          </IconButton>
        </Tooltip>)}

      {/* LOAD A FILTER */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.FILTER) >= 0 &&
        (<Tooltip title={strings.filter.openSavedFilter} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="toolbarLoadFilter"
            sx={{...styles(theme).menuAction}}
            color="inherit"
            onClick={() => this.props.toggleSavedFilter(!this.props.showSavedFilters)}
            size="large">
            <SvgIcon sx={this.props.onMobile ? { "&.MuiIconButton-root": {...styles(theme).whiteColor} } : { "&.MuiSvgIcon-root": {...styles(theme).darkBlueColor} }}>
              <path d={svgConstants.customFilter} />
            </SvgIcon>
          </IconButton>
        </Tooltip>)}

      {/* CREATE A FILTER */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.OPEN_FILTER) >= 0 &&
        (<Tooltip title={strings.tooltips.createFilter} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="toolbarCreateFilter"
            sx={{...styles(theme).menuAction}}
            color="inherit"
            onClick={() => {
              this.props.toggleFilterPage(!this.props.showFilterPage);
              this.props.unlock();
            }}
            size="large">
            <Badge variant="dot" color="primary" invisible={!this.props.isActiveFilterSet}>
              <FilterList />
            </Badge>
          </IconButton>
        </Tooltip>)}

      {/* PDF DOCUMENT DOWNLOAD */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.DOWNLOAD_PDF) >= 0 &&
        <PdfDownloadButton pdfDocuments={this.props.pdfDocuments} onMobile={this.props.onMobile} />
      }

      {/* RESET INTERVENTION REQUEST */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.NEW_INTERVENTION_REQUEST) >= 0 &&
        (<Tooltip title={strings.tooltips.resetInterventionRequest} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="newInterventionButton"
            sx={{ "&.MuiIconButton-root": {...styles(theme).menuAction}}}
            onClick={this.props.resetInterventionRequest}
            color="secondary"
            size="large">
            <SvgIcon>
              <path d={svgConstants.newIntervention} />
            </SvgIcon>
          </IconButton>
        </Tooltip>)}

      {/* REDIRECT TO INTERVENTIONS HOME PAGE */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.INTERVENTIONS_HOME) >= 0 &&
        (<Tooltip title={strings.tooltips.redirectInterventionsHome} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="homeButton"
            sx={{ "&.MuiIconButton-root": {...styles(theme).menuAction}}}
            onClick={() => this.props.updateCurrentPage(PAGES_IDS.INTERVENTION)}
            color="secondary"
            size="large">
            <Home />
          </IconButton>
        </Tooltip>)}

      {/* OPEN EDIT INSTRUMENTS DIALOG */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.EDIT_INSTRUMENTS) >= 0 &&
        (<Tooltip title={strings.tooltips.editInstrument} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="toolbar-edit-instrument"
            sx={{...styles(theme).menuAction}}
            color="inherit"
            onClick={() => this.props.toggleEditInstrumentsDialog(true)}
            size="large">
            <Edit />
          </IconButton>
        </Tooltip>)}

      {/* CALENDAR FILTER */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.CALENDAR_FILTER) >= 0 &&
        (
          <Tooltip title={strings.tooltips.dateFilter} enterDelay={globalConstants.tooltipDelay}>
            <Box component="div" sx={{...styles(theme).menuAction}}>
              <DatePickerIcon
                id="datePicker"
                selectedDate={this.props.selectedDate}
                handleChangeDatePicker={date => this.props.handleChangeDatePicker(date)}
              />
            </Box>
          </Tooltip>)}

      {/* CSV DOWNLOAD */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.SET_CSVDATA) >= 0 &&
        (<Tooltip title={strings.tooltips.downloadCSV} enterDelay={globalConstants.tooltipDelay}>
          <Box component="div" sx={{...styles(theme).menuAction}}>
            <CsvIcon
              datas={this.props.csvData}
              marginTop
              headers={HistoryHelper.headers}
              fileName={HistoryHelper.getCSVFileName()}
            />
          </Box>
        </Tooltip>)}

      {/* SHOW INTERVENTION SUBMIT INFO */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.INTERVENTION_SUBMIT_INFO) >= 0 &&
        (<Tooltip title={strings.tooltips.interventionInfo} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="interventionInfos"
            sx={{...styles(theme).menuAction}}
            color="inherit"
            onClick={() => this.props.toggleInterventionSubmitInfo(!this.props.showInterventionSubmitInfo)}
            size="large">
            <Info />
          </IconButton>
        </Tooltip>)}

      {/* OPEN HELP DIALOG */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.OPEN_HELP_DIALOG) >= 0 &&
        (<Tooltip title={strings.tooltips.helpInfo} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            sx={{...styles(theme).menuAction}}
            color="inherit"
            onClick={() => this.props.setHelpDialogOpen(true)}
            size="large">
            <Info />
          </IconButton>
        </Tooltip>)}

      {/* INVENTORY CSV DOWNLOAD */}
      {this.props.toolbarInfo.actions.indexOf(ToolbarActionsConstants.SET_INVENTORY_CSVDATA) >= 0 &&
        (<Tooltip title={strings.tooltips.downloadCSV} enterDelay={globalConstants.tooltipDelay}>
          <Box component="div" sx={{...styles(theme).menuAction}}>
            <CsvIcon
              datas={this.props.csvData}
              headers={InventoryHelper.headers}
              marginTop
              fileName={strings.inventory.csvInventoryTitle(dayjs().format(strings.general.dateFormatWithTime))}
            />
          </Box>
        </Tooltip>)}
    </>;
  };

  render() {
    const { menuIsOpen, bannerData, showBanner } = this.props;
    const menuIsOpenStyle = menuIsOpen ? styles(theme).appBar_open : styles(theme).appBar_closed;
    const interventionBgTheme = ((this.props.currentPage === PAGES_IDS.INTERVENTION && this.props.onMobile)
      || this.props.currentPage === PAGES_IDS.INTERVENTION_REQUEST
      || this.props.currentPage === PAGES_IDS.INTERVENTION_CREATION)
      ? styles(theme).intervention_bg_theme : {};
    const cartStepToolbar = (this.props.currentPage === PAGES_IDS.INTERVENTION_REQUEST
    && this.props.activeStep.index === InterventionRequestConstants.CART_STEP)
      ? styles(theme).cart_step_toolbar : {};
    const inventoryBgTheme = this.props.currentPage === PAGES_IDS.INVENTORY ? styles(theme).inventory_bg_theme : {};
    return (
      <AppBar
        id="appbar"
        sx={{...merge({}, styles(theme).appBar, 
          menuIsOpenStyle,
          interventionBgTheme,
          cartStepToolbar,
          inventoryBgTheme)
        }}
      >
        { showBanner &&
            <Banner
              bannerData={bannerData}
            />
          }
        <Toolbar disableGutters={!menuIsOpen} sx={{ "&.MuiToolbar-root": {...styles(theme).mainToolbar_toolbar} }} id="top-toolbar">
          <IconButton
            id="toggleMenuButton"
            color="inherit"
            aria-label="Open drawer"
            onClick={() => { this.props.toggleMenu(); this.props.unlock(); }}
            sx={{...styles(theme).menuButton,
              [{...styles(theme).hide}]: menuIsOpen,
            }}
            size="large">
            <Menu />
          </IconButton>

          {!this.props.onMobile &&
            <Box component="div" sx={{...styles(theme).pageIcon}}>
              {this.props.toolbarInfo.icon}
            </Box>}

          <Typography variant="h6" sx={{ "&.MuiTypography-root": {...styles(theme).typo}}} noWrap>
            {this.props.toolbarInfo.title}
          </Typography>

          <Box component="div" sx={{...styles(theme).grow}} />

          {this.buildActionsButtons()}
        </Toolbar>
      </AppBar>
    );
  }
}

MainToolbar.propTypes = {

  //region props from redux
  pdfDocuments: PropTypes.arrayOf(PropTypes.string),
  csvData: PropTypes.array,
  toggleEditInstrumentsDialog: PropTypes.func,
  handleChangeDatePicker: PropTypes.func,
  setHelpDialogOpen: PropTypes.func,
  setStatus: PropTypes.func,
  unlock: PropTypes.func,
  openMultipleInterventionsInstruments: PropTypes.func,
  toggleInterventionSubmitInfo: PropTypes.func,
  activeStep: PropTypes.object,
  isActiveFilterSet: PropTypes.bool.isRequired,
  toolbarInfo: PropTypes.object,
  toggleFavorites: PropTypes.func,
  showFavorites: PropTypes.bool,
  toggleFilterPage: PropTypes.func,
  showFilterPage: PropTypes.bool,
  toggleSavedFilter: PropTypes.func,
  updateCurrentPage: PropTypes.func,
  showSavedFilters: PropTypes.bool,
  onMobile: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  selectedDate: PropTypes.object,
  showInterventionSubmitInfo: PropTypes.bool,
  resetInterventionRequest: PropTypes.func,
  //endregion

  //region props from parent
  menuIsOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  //endregion
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(MainToolbar);
