import React from 'react';
import PropTypes from 'prop-types';
import { DialogContentText } from '@mui/material';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import { styles } from '../SimpleDialog/SimpleDialog.style';
import { theme } from '../../../Utils/Theme';

const SynchronizationInstrumentDialog = props => {

  return (
    <SimpleDialog
      open={props.open}
      onClose={props.onRefuse}
      title={props.title}
      acceptLabel={props.acceptLabel}
      refuseLabel={props.refuseLabel}
      onAccept={props.onAccept}
      onRefuse={props.onRefuse}
      addContentPadding
    >
      <DialogContentText
        sx={{ "&.MuiDialogContentText-root": {...(isNotComputer && styles(theme).tabletFontSize)} }}>
        {props.text}
      </DialogContentText>
    </SimpleDialog>
  );
};

SynchronizationInstrumentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onRefuse: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  refuseLabel: PropTypes.string.isRequired,
  acceptLabel: PropTypes.string
};

export default SynchronizationInstrumentDialog;
