import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  img: {
    margin: "8px",
    width: "56px",
    height: "56px",
    [theme.breakpoints.only('xs')]: {
      margin: "4px"
    },
  },
  item: {
    padding: "8px",
    margin: "8px",
    backgroundColor: theme.palette.pureWhite,
    borderBottom: `1px solid ${theme.palette.intervention.mobile_divider}`,
    [theme.breakpoints.only('xs')]: {
      margin: "0px",
      padding: "4px",
    },
    cursor: 'pointer'
  },
  listItemText: {
    padding: "0px 16px",
    margin: "0px"
  },
  actionButton: {
    alignItems: 'right',
    color: theme.palette.greenTheme.main
  },

  right: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    textAlign: 'right',
    marginLeft: "8px",
  },

  icons: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-end',
  },

});
