// REACT libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Material-UI Libraries
import { styles } from './InstrumentCardHeader.style';
import { Close, Link, Notifications, NotificationsNone, Sync } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
// Components libraries
import InstrumentImage from '../../InstrumentImage/InstrumentImage';
import InstrumentHelper from '../../../../Helpers/InstrumentHelper';
// Redux helpers
import {
  addFavorite,
  instrumentListNeedsUpdate,
  removeFavorite,
  updateShouldForceSync,
  setInstrumentMarquageToRefresh
} from '../../../../store/actions/exportActions';
//Constants
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { globalConstants } from '../../../../Utils/Constants/GlobalConstants';
import routes from '../../../../Utils/Routes';
import { ConnectivityChecker } from '../../../../Utils/ConnectivityChecker';
import { SnackbarMessages } from '../../../../Utils/Constants/SnackbarMessages';
import { isNotComputer } from '../../../Utils/ResponsiveSelect/ResponsiveSelect';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../../Utils/Theme';
import merge from 'lodash/merge';

// maps instrumentReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    favorites: state.instrumentReducer.favorites
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    addFavorite: favorites => dispatch(addFavorite(favorites)),
    removeFavorite: favorites => dispatch(removeFavorite(favorites)),
    instrumentListNeedsUpdate: (msg, type) => dispatch(instrumentListNeedsUpdate(msg, type)),
    forceSync: shouldForceSync => dispatch(updateShouldForceSync(shouldForceSync)),
    setInstrumentMarquageToRefresh: marquage => dispatch(setInstrumentMarquageToRefresh(marquage)),
  };
};

const MARQUAGE = ':marquage';

class InstrumentCardHeader extends Component {
  state = {
    isEditFavoriteChecked: false,
    URL_DTB: null,
    msgTooltipSynchro: true
  };

  async componentDidMount() {
    this.setState({ isEditFavoriteChecked: (this.props.favorites.indexOf(this.props.instrument.marquage) >= 0) });
    await this.digitalTestBenchUrl(this.props.instrument.marquage);
  }

  favoriteInstrument = () => {
    this.setState({ isEditFavoriteChecked: !this.state.isEditFavoriteChecked },
      this.handleFavoriteInstrument);
  };

  synchronizeInstrument = async (currentInstrumentMarquage) => {
    const isOnline = await ConnectivityChecker.isConnected();
    if (isOnline) {
      //Load the spinner change to TRUE
      this.props.toggleInstrumentUpdateLoader();
      
      const getLastInformation = await InstrumentHelper.getLastInformationInstrument(currentInstrumentMarquage);
      if (getLastInformation && getLastInformation.shouldReSync) {
        this.props.forceSync(true);
        this.props.setInstrumentMarquageToRefresh(currentInstrumentMarquage);
        enqueueSnackbar(SnackbarMessages.noLongerAccessToInstrument.msg,
          SnackbarMessages.noLongerAccessToInstrument.type);
      } else if (getLastInformation) {
        this.props.instrumentListNeedsUpdate(getLastInformation);
        this.props.setInstrumentMarquageToRefresh(currentInstrumentMarquage);

        enqueueSnackbar(SnackbarMessages.instrumentSynchronizationSuccess.msg,
          SnackbarMessages.instrumentSynchronizationSuccess.type);
      } else {
        enqueueSnackbar(SnackbarMessages.instrumentSynchronizationInfo.msg,
          SnackbarMessages.instrumentSynchronizationInfo.type);
      }
      if (!this.state.msgTooltipSynchro) this.setState({ msgTooltipSynchro: true });
      //Load the spinner change to FALSE.
      this.props.toggleInstrumentUpdateLoader();

    } else {
      this.setState({ msgTooltipSynchro: false });
    }
  };

  handleFavoriteInstrument = () => {

    if (this.state.isEditFavoriteChecked) {
      InstrumentHelper.addInstrumentToFavorite(this.props.instrument.marquage);
      this.props.addFavorite(this.props.instrument.marquage);
    } else {
      InstrumentHelper.removeInstrumentFromFavorites(this.props.instrument.marquage);
      this.props.removeFavorite(this.props.instrument.marquage);
    }
  };

  transformDigitalTestBenchURLForInstrument = (UrlToTransform, marquages) => {
    return UrlToTransform.replace(MARQUAGE, marquages);
  };

  digitalTestBenchUrl = async (marquages) => {

    const isOnline = await ConnectivityChecker.isConnected();
    let newUrl = null;
    if (isOnline) {
      await InstrumentHelper.getURLdigitalTestBench(routes.getUrlDigitalTestBench)
        .then((URL) => {
          newUrl = this.transformDigitalTestBenchURLForInstrument(URL, marquages);
        });
    } else {
      const digitalTestBenchURLocal = InstrumentHelper.getURLdigitalTestBenchLocal();
      if (digitalTestBenchURLocal) {
        newUrl = this.transformDigitalTestBenchURLForInstrument(digitalTestBenchURLocal, marquages);
      }
    }
    this.setState({ URL_DTB: newUrl });
  };


  render() {
    return (
      <Box component="div" sx={{...styles(theme).header}}>
        <Box component="div" sx={{...styles(theme).leftComponent}}>
          {this.props.showImage && (
            <Box component="div" sx={{...styles(theme).instrumentImage}}>
              <InstrumentImage instrument={this.props.instrument}/>
            </Box>
          )}
          <Box component="div" sx={{...styles(theme).headerContent}}>
            <Typography variant="h6" color="secondary"
              sx={{...merge({}, styles(theme).headerDesignation,
                (isNotComputer && styles(theme).tabletHeaderDesignation))}}>
              {this.props.instrument.designation}
            </Typography>
            <Typography variant="h6" color="secondary"
              sx={{...merge({}, styles(theme).headerMarquage,
                (isNotComputer && styles(theme).tabletHeaderMarquage))}}>
              {this.props.instrument.marquage}
            </Typography>
          </Box>
        </Box>
        <Box component="div" sx={{...styles(theme).icons}}>

          <Box component="div" sx={{...styles(theme).icon}}>
            {
              <Tooltip
                title={this.state.URL_DTB ?
                  strings.tooltips.digitalTestBenchLinkInfo : strings.tooltips.digitalTestBenchLinkNotAvailable}
                enterDelay={this.state.URL_DTB ?
                  globalConstants.tooltipDelay : null}
              >
                <IconButton
                  id='linkIcon'
                  sx={{...merge({}, styles(theme).favoriteInstrumentIcon, styles(theme).thinnerPadding)}}
                  href={this.state.URL_DTB}
                  rel="noreferrer"
                  target="_blank"
                  size="large">
                  <Link color="secondary"/>
                </IconButton>
              </Tooltip>
            }
          </Box>

          <Box component="div" sx={{...styles(theme).icon}}>
            {
              <Tooltip
                title={this.state.msgTooltipSynchro ?
                  strings.tooltips.updateInstrumentInformation :
                  strings.tooltips.updateInstrumentInformationNotAvailable}
              >
                <IconButton
                  id={`synchronization${this.props.instrument.marquage}`}
                  sx={{...merge({}, styles(theme).favoriteInstrumentIcon, styles(theme).thinnerPadding)}}
                  onClick={() => this.synchronizeInstrument(this.props.instrument.marquage)}
                  size="large">
                  <Sync color="secondary"/>
                </IconButton>
              </Tooltip>
            }
          </Box>

          <Box component="div" sx={{...styles(theme).icon}}>
            <IconButton
              id='notifIcon'
              onClick={this.favoriteInstrument}
              sx={{...merge({}, styles(theme).favoriteInstrumentIcon, styles(theme).thinnerPadding)}}
              size="large">
              {this.state.isEditFavoriteChecked ? <Notifications color="secondary"/> :
                <NotificationsNone color="secondary"/>}
            </IconButton>
          </Box>
          <Box component="div" sx={{...styles(theme).icon}}>
            <IconButton
              id='closeIcon'
              onClick={() => this.props.onClose()}
              sx={{...styles(theme).thinnerPadding}}
              size="large">
              <Close color="secondary"/>
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  }
}

InstrumentCardHeader.propTypes = {
  favorites: PropTypes.array,
  addFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
  onClose: PropTypes.func,
  instrument: PropTypes.object.isRequired,
  showImage: PropTypes.bool,
  instrumentListNeedsUpdate: PropTypes.func,
  toggleInstrumentUpdateLoader: PropTypes.func,
  forceSync: PropTypes.func.isRequired,
  setInstrumentMarquageToRefresh: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(InstrumentCardHeader);
