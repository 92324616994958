import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SelectableTree from '../../Utils/SelectableTree/SelectableTree';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import SimpleDialog from '../SimpleDialog/SimpleDialog';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

export class ProvidersDialog extends Component {

  render() {
    return (
      <SimpleDialog
        id="simpleDialog"
        fullScreen={this.props.onMobile}
        open={this.props.openDialog}
        acceptLabel={strings.interventionRequest.validateLabel}
        onAccept={this.props.onCloseDialog}
        onRefuse={this.props.onCloseDialog}
        title={strings.interventionRequest.providerSelectTitle}
      >
        <SelectableTree
          id="selectableTree"
          data={this.props.data}
          onValueSelected={this.props.onValueSelected} />
      </SimpleDialog>
    );
  }
}
ProvidersDialog.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  openDialog: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  onValueSelected: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(ProvidersDialog);
