import db from '../../Utils/Database/Database';
import { JwtHelperService } from '../../Utils/JwtHelperService';

class NotificationsProvider {

  static getNotificationsQuery() {
    return db.instrumentNotifications.where({ userLogin: JwtHelperService.getUserLogin(), isDeleted: 0 });
  }

  static getNotifications() {
    return NotificationsProvider.getNotificationsQuery().toArray();
  }

  static getNotificationsCount() {
    return NotificationsProvider.getNotificationsQuery().count();
  }

  static deleteNotifications(serverIDs) {
    db.instrumentNotifications.where('serverId').anyOf(serverIDs).modify({ isDeleted: 1 });
  }
}

export default NotificationsProvider;
