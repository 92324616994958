import PropTypes from 'prop-types';
import React, { Component } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  CircularProgress,
  FormControl,
  InputLabel,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
} from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';

import { styles } from './InterventionCard.style';
import InterventionConstants from '../../../../../Utils/Constants/InterventionConstants';
import InterventionService from '../../../../../Providers/WebService/InterventionService';
import { InterventionRequestConstants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import InterventionRequestService from '../../../../../Providers/WebService/InterventionRequestService';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import ResponsiveSelect from '../../../../Utils/ResponsiveSelect/ResponsiveSelect';
import ProvidersDialog from '../../../../Dialogs/ProvidersDialog/ProvidersDialog';
import { int } from '../../../../../Utils/MathUtils';
import SmartTextField from '../../../../Utils/SmartTextField/SmartTextField';
import { ValidatorRules } from '../../../../../Utils/Validator';
import { theme } from '../../../../../Utils/Theme';

dayjs.extend(utc);

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

class InterventionCard extends Component {

  state = {
    open: false,
    locations: [],
    providerTypes: [],
    providers: [],
    loading: false,
    types: [],
  };

  /** Load service locations, provider types and external recipient by default */
  async componentDidMount() {
    this.setState({ loading: true });
    const locations = await InterventionRequestService.getServiceLocations();
    const providerTypes = await InterventionRequestService.getProviderTypes();
    const providers = await InterventionRequestService.getExternalProviders();
    const types = await InterventionService.getAllInterventionTypes();
    this.setState({ locations, providerTypes, providers, types, loading: false });
  }

  toggleDialog = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  /** Update provider information after closing provider selection dialog */
  handleProviderTypeChange = e => {
    this.setState({ loading: true });
    this.props.updateInterventionStateField(e.target.name, e.target.value);
    // Reset provider when provider's type change
    this.props.updateInterventionStateField(
      InterventionConstants.INTERVENTIONCARD_NAMES.PROVIDER, { id: '', name: '' });
    const providersPromise = (e.target.value === InterventionRequestConstants.PROVIDER_TYPE.EXTERNAL) ?
      InterventionRequestService.getExternalProviders() : InterventionRequestService.getInternalProviders();
    providersPromise.then(providers => {
      this.setState({ providers, loading: false });
    });
  };

  generateResponsiveSelect = (name, label, value, data, onChange) => {
    return (
      <FormControl fullWidth required >
        <InputLabel htmlFor={name} sx={{...styles(theme).input_label, ...styles(theme).select_label}}>
          {label}     
        </InputLabel>
        <ResponsiveSelect
          id={name}
          required
          value={value}
          name={name}
          submitted={this.props.submitted}
          types={[ValidatorRules.NOT_EMPTY]}
          onChange={onChange}
          data={data}
          native={this.props.onMobile}
          styledSelect={styles(theme).input_content}
          noDefaultValue={this.props.onMobile}
        />
      </FormControl>
    );
  }

  handleInterventionSelected = e => {
    if (!e.target.value || e.target.value === 0) {
      return;
    }
    this.props.updateInterventionStateField(
      e.target.name,
      {
        id: int(e.target.value),
        name: this.state.types.find(element => element.id === int(e.target.value)).name,
        canSelectStatus: this.state.types.find(element => element.id === int(e.target.value)).statusIsRequired,
        canSelectState: this.state.types.find(element => element.id === int(e.target.value)).resultIsRequired,
      }
    );
  };

  render() {
    return (
      <>
        <Card id="interventionCard">
          <CardHeader
            sx={{...styles(theme).titleContainer}}
            title={
              <Typography variant='h6' sx={{...styles(theme).cardTitle}}>
                {strings.intervention.interventionCard.title}</Typography>}
            action={this.state.loading && <CircularProgress size={20} />}
          />
          <CardContent>
            {/* Nature de l'intervention */}
            {this.generateResponsiveSelect(
              InterventionConstants.INTERVENTIONCARD_NAMES.SERVICE_TYPE,
              strings.intervention.interventionCard.serviceTypeLabel,
              this.props.intervention.type.id,
              this.state.types,
              e => this.handleInterventionSelected(e)
            )}
            {/* Localisation */}
            {this.generateResponsiveSelect(
              InterventionConstants.INTERVENTIONCARD_NAMES.LOCATION,
              strings.intervention.interventionCard.location,
              this.props.intervention.location,
              this.state.locations,
              e => this.props.updateInterventionStateField(e.target.name, int(e.target.value))
            )}
            {/* Type de Prestataire */}
            {this.generateResponsiveSelect(
              InterventionConstants.INTERVENTIONCARD_NAMES.PROVIDER_TYPE,
              strings.intervention.interventionCard.providerTypeLabel,
              this.props.intervention.providerType,
              this.state.providerTypes,
              e => this.handleProviderTypeChange(e)
            )}
            {/* Prestataire */}
            <SmartTextField
              required
              id={InterventionConstants.INTERVENTIONCARD_NAMES.PROVIDER}
              name={InterventionConstants.INTERVENTIONCARD_NAMES.PROVIDER}
              label={strings.intervention.interventionCard.providerLabel}
              onClick={this.toggleDialog}
              fullWidth
              submitted={this.props.submitted}
              types={[ValidatorRules.NOT_EMPTY]}
              value={this.props.intervention.provider.name}
              InputProps={{
                sx: {...styles(theme).input_content}
              }}
              InputLabelProps={{
                sx: {...styles(theme).input_label}
              }}
            />
            {/* Technicien */}
            <SmartTextField
              id={InterventionConstants.INTERVENTIONCARD_NAMES.TECHNICIAN}
              name={InterventionConstants.INTERVENTIONCARD_NAMES.TECHNICIAN}
              label={strings.intervention.interventionCard.technician}
              onChange={e => this.props.updateInterventionStateField(e.target.name, e.target.value)}
              fullWidth
              value={this.props.intervention.technician}
              required
              submitted={this.props.submitted}
              types={[ValidatorRules.NOT_EMPTY]}
              InputProps={{
                sx: {...styles(theme).input_content}
              }}
              InputLabelProps={{
                sx: {...styles(theme).input_label}
              }}
            />
            {/* DatePicker */}
            <MobileDatePicker
              id="datePicker"
              sx={{...styles(theme).datePickerSeparator}}
              keyboard
              slotProps={{ textField: { variant: 'standard' } }}
              maxDate={dayjs()}
              value={this.props.intervention.date}
              defaultValue={this.props.intervention.date}
              onAccept={date => this.props.updateInterventionStateField(
                InterventionConstants.INTERVENTIONCARD_NAMES.DATE,
                date
              )}
              animateYearScrolling
              format={strings.general.dateFormat}
            />
            {/* Commentaire */}
            <TextField
              id={InterventionConstants.INTERVENTIONCARD_NAMES.COMMENT}
              name={InterventionConstants.INTERVENTIONCARD_NAMES.COMMENT}
              label={strings.intervention.interventionCard.comments}
              multiline
              value={this.props.intervention.comment}
              fullWidth
              rows="4"
              variant="outlined"
              onChange={e => this.props.updateInterventionStateField(e.target.name, e.target.value)}
              margin="normal"
              InputProps={{
                sx: {...styles(theme).input_content}
              }}
              InputLabelProps={{
                sx: {...styles(theme).input_label}
              }}
            />
          </CardContent>
        </Card>

        {/* Display providers Dialog according to providers state */}
        <ProvidersDialog
          id="providersDialog"
          openDialog={this.state.open}
          onCloseDialog={this.toggleDialog}
          data={this.state.providers}
          onValueSelected={value => {
            this.props.updateInterventionStateField(InterventionConstants.INTERVENTIONCARD_NAMES.PROVIDER, value);
          }}
        />
      </>
    );
  }
}

InterventionCard.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  updateInterventionStateField: PropTypes.func.isRequired,
  intervention: PropTypes.object.isRequired,
  submitted: PropTypes.bool
};

export default compose(
  connect(mapStateToProps, null)
)(InterventionCard);
