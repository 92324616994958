import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Help } from '@mui/icons-material';

import { setHelpDialogOpen, updateToolbarInfo } from '../../store/actions/exportActions';
import { styles } from './HelpPage.style';
import userGuide from '../../Assets/userGuide.pdf';
import appUserGuide from '../../Assets/appUserGuide.pdf';
import quickscanGuide from '../../Assets/quickscanGuide.pdf';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import HelpDialogContent from './HelpDialogContent/HelpDialogContent';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import { theme } from '../../Utils/Theme';
import { Box } from '@mui/material';

// maps Reducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    helpDialogOpen: state.generalReducer.helpDialogOpen
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
    setHelpDialogOpen: value => dispatch(setHelpDialogOpen(value))
  };
};

const HelpPage = props => {

  // ComponentDidMount
  React.useEffect(() => {
    props.updateToolbarInfo({
      title: strings.mainMenu.help,
      icon: (<Help />),
      actions: [ToolbarActionsConstants.OPEN_HELP_DIALOG]
    });
  }, [props]);

  return (
    <>
      <Box component="div" sx={{...styles(theme).container}}>
        <Button variant="contained" color="primary" size="large" target="_blank" href={userGuide}>
          {strings.help.flyerDownload}
        </Button>
        <Button variant="contained" color="primary" size="large" target="_blank" href={appUserGuide}>
          {strings.help.appUserGuideDownload}
        </Button>
        <Button variant="contained" color="primary" size="large" target="_blank" href={quickscanGuide}>
          {strings.help.quickscanGuideDownload}
        </Button>
        <Button variant="contained" color="primary" size="large" onClick={props.redirectUpdatePage}>
          {strings.help.update}
        </Button>
      </Box>
      <SimpleDialog
        fullScreen={props.onMobile}
        title={strings.help.welcomeTitle}
        open={props.helpDialogOpen}
        acceptLabel={strings.help.continue}
        onAccept={() => props.setHelpDialogOpen(false)}
        addContentPadding
      >
        <HelpDialogContent />
      </SimpleDialog>
    </>
  );
};

HelpPage.propTypes = {
  updateToolbarInfo: PropTypes.func.isRequired,
  onMobile: PropTypes.bool,
  helpDialogOpen: PropTypes.bool,
  setHelpDialogOpen: PropTypes.func,
  redirectUpdatePage: PropTypes.func.isRequired
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(HelpPage);
