const SCROLL_LOAD_OFFSET = 8;

/**
 * Use this function with onScroll of a scrollbar.
 * The onBottomReach function is called when the scroll reach the bottom of the bar.
 */
export let trackScrolling = (scrollValues, onBottomReach) => {
  const bottom = scrollValues.scrollHeight - scrollValues.scrollTop <= (scrollValues.clientHeight + SCROLL_LOAD_OFFSET);

  if (bottom) {
    onBottomReach();
  }
};
