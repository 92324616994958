import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: '100%',
    padding: "8px"
  },
  mobileContainer: {
    display: 'flex',
    height: '100%'
  },
});
