import sharedStyles from '../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  container: {
    flex: '0 0 30%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: theme.spacing(),
    backgroundColor: theme.palette.pureWhite,
    height: 'max-content'
  },
  sectionContainer: {
    padding: theme.spacing()
  },
  sectionTitle: {
    color: theme.palette.veryDarkBlueTheme.main,
    fontSize: '0.95rem'
  },
  verticalAlignContainer: {
    display: 'flex',
    alignItems: 'center'
  },
});
