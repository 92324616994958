import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import React, { Component } from 'react';
import { styles } from './ProfilePage.style';
import Paper from '@mui/material/Paper';
import {
  Phone,
  PhoneAndroid,
  People,
  Public,
  Business,
  Label,
  AccountCircle
} from '@mui/icons-material';
import MainInformationProfile from './MainInformationProfile/MainInformationProfile';
import DetailsProfile from './DetailsProfile/DetailsProfile';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { updateToolbarInfo } from '../../store/actions/exportActions';
import { theme } from '../../Utils/Theme';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

// maps redux's actions to Component's props
const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};

class ProfilePage extends Component {

  componentDidMount() {
    // Display toolbar's content (icon, title, iconButtons)
    this.props.updateToolbarInfo({
      title: strings.mainMenu.profile,
      icon: <AccountCircle />,
      actions: []
    });
  }

  render() {
    const { activeUser } = this.props;
    return (
      <Box component="div" sx={{...styles(theme).profileContent}}>
        <MainInformationProfile activeUser={activeUser} />

        <Paper elevation={2} sx={{ "&.MuiPaper-root": {...merge({}, styles(theme).paperShape, styles(theme).paddingPaper)}}}>
          <DetailsProfile
            icon={<Phone />}
            titleText={strings.profile.professionalPhone}
            value={activeUser.phone}
          />
          <DetailsProfile
            icon={<PhoneAndroid />}
            titleText={strings.profile.mobilePhone}
            value={activeUser.mobile}
          />
          <DetailsProfile
            icon={<People />}
            titleText={strings.profile.organzationalUnitUser}
            value={activeUser.organizationalUnitUser}
          />
          <DetailsProfile
            icon={<Public />}
            titleText={strings.profile.geographicalUnitUser}
            value={activeUser.geographicalUnitUser}
          />
          <DetailsProfile
            icon={<Business />}
            titleText={strings.profile.financialUnitUser}
            value={activeUser.financialUnitUser}
          />
          <DetailsProfile
            icon={<Label />}
            titleText={strings.profile.complementaryRoles}
            rolesProps={activeUser.roles}
          />
        </Paper>
      </Box>
    );
  }
}

ProfilePage.propTypes = {
  updateToolbarInfo: PropTypes.func,
  activeUser: PropTypes.object.isRequired
};

export default compose(
  connect(null, mapDispatchToProps))(ProfilePage);
