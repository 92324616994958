import sharedStyles from '../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  container: {
    flex: '0 0 60%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.pureWhite,
  },
  scrollArea: {
    height: '100%'
  },
  mobileContainer: {
    flex: 1,
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: '8px 4px',
    backgroundColor: theme.palette.pureWhite,
  },
  responsiveSelectSeparator: {
    marginBottom: "8px"
  }
});
