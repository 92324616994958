import React from 'react';
import PropTypes from 'prop-types';
import { styles } from './ScannedInstrumentItem.style';
import InstrumentImage from '../../../InstrumentCard/InstrumentImage/InstrumentImage';
import { Typography, Checkbox, Box } from '@mui/material';
import { theme } from '../../../../Utils/Theme';

const ScannedInstrumentItem = props => {

  return (
    <Box component="div" id="scanItem" sx={{...styles(theme).listItem}}>
      <Box component="div" id="instrumentImage" sx={{...styles(theme).instrumentImage}} onClick={() => props.onClick()}>
        <InstrumentImage instrument={props.instrument} />
      </Box>
      <Box component="div" id="instrumentInfos" sx={{...styles(theme).informations}} onClick={() => props.onClick()}>
        <Typography variant="h6">{props.instrument.marquage}</Typography>
        <Typography variant="subtitle2">{props.instrument.constructeur}</Typography>
        <Typography variant="subtitle2">{props.instrument.destinataire}</Typography>
      </Box>
      <div>
        <Checkbox 
          id="checkbox" 
          checked={props.selected} 
          onChange={e => {
            e.stopPropagation();
            props.onSelected(props.instrument);
          }} 
        />
        <Typography variant="subtitle2">{props.instrument.etat}</Typography>
      </div>
    </Box>
  );
};

ScannedInstrumentItem.propTypes = {
  instrument: PropTypes.object,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
  onClick: PropTypes.func
};

export default ScannedInstrumentItem;
