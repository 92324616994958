import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip } from '@mui/material';

import { styles } from './SimpleDialog.style';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

const SimpleDialog = props => {

  const [isTooltipOpen, openTooltip] = useState(false);

  if (!props.open) {
    return null;
  }

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={props.onRefuse}
      sx={{ "& .MuiDialog-paperWidthSm": {...(props.styledDialog)}, "&.MuiDialog-root": {...(props.highPriority && styles(theme).priority)} }}
      PaperProps={{ id: props.id }}
    >
      <DialogTitle sx={{ "&.MuiDialogTitle-root": props.alternateStyling ?
        {...styles(theme).dialogTitleAlternate} : {...styles(theme).dialogTitle} }}
        style={{ backgroundColor: props?.style ? props.style : theme.palette.dialogTheme.header }}>
        <Box component="div" sx={{...merge({}, styles(theme).inlineBlock, styles(theme).whiteColor)}}>
          <Box component="div" style={{ display: 'flex' }} sx={{...(isNotComputer && styles(theme).tabletTitleFontSize)}}>
            {props.icon &&
              <Box component="div" sx={{...styles(theme).icon}}>
                {props.icon}
              </Box>
            }
            {props.title}
          </Box>
          {props.tooltip &&
            <Tooltip
              onClick={() => openTooltip(!isTooltipOpen)}
              disableFocusListener
              onClose={() => openTooltip(false)}
              onOpen={() => openTooltip(true)}
              title={props.tooltip}
              open={isTooltipOpen}>
              {props.tooltipIcon}
            </Tooltip>
          }
        </Box>
      </DialogTitle  >
      <DialogContent
        style={{ 'padding': props.addContentPadding ? '10px' : '1px' }}
        sx={{ "&.MuiDialogContent-root": {...merge({}, styles(theme).dialogContent, (isNotComputer && styles(theme).tabletFontSize))} }}>
        {props.children}
      </DialogContent>

      {!props.noActions && (
        <DialogActions>
          {props.refuseLabel &&
          <Button
            id="cancelButton"
            variant="contained"
            color="primary"
            onClick={props.onRefuse}
            sx={{...(isNotComputer && styles(theme).tabletFontSize)}}
          >
            {props.refuseLabel}
          </Button>
          }
          {props.acceptLabel &&
          <Button
            id="acceptButton"
            variant="contained"
            color="primary"
            onClick={props.onAccept}
            sx={{...(isNotComputer && styles(theme).tabletFontSize)}}
            style={{ backgroundColor: props?.style ? props.style : theme.palette.dialogTheme.header,
              width: props?.redirectLabel ? '190px' : null, height: props?.redirectLabel ? '60px' : null }}>
            {props.acceptLabel}
          </Button>
          }
          {props.redirectLabel &&
          <Button
            id="redirectButton"
            variant="contained"
            color="primary"
            onClick={props.onRedirect}
            sx={{...(isNotComputer && styles(theme).tabletFontSize)}}
            style={{ backgroundColor: props?.style ? props.style : theme.palette.dialogTheme.header }}>
            {props.redirectLabel}
          </Button>
          }
        </DialogActions>
      )}

    </Dialog>
  );
};

SimpleDialog.propTypes = {
  icon: PropTypes.object,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  fullScreen: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.element
  ]).isRequired,
  styledDialog: PropTypes.object,
  acceptLabel: PropTypes.string,
  refuseLabel: PropTypes.string,
  redirectLabel: PropTypes.string,
  onAccept: PropTypes.func,
  onRefuse: PropTypes.func,
  onRedirect: PropTypes.func,
  noActions: PropTypes.bool,
  alternateStyling: PropTypes.bool,
  tooltipIcon: PropTypes.element,
  tooltip: PropTypes.string,
  addContentPadding: PropTypes.bool,
  highPriority: PropTypes.bool,
  id: PropTypes.string,
  style: PropTypes.string,
};

export default SimpleDialog;
