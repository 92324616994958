import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {
  Check as CheckIcon
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  Fab,
  Dialog,
  DialogContent,
} from '@mui/material';
import { connect } from 'react-redux';
import { styles } from './InstrumentsSelection.style';
import { InterventionRequestConstants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import { SnackbarMessages } from '../../../../../Utils/Constants/SnackbarMessages';
import InstrumentList from '../../../../../Containers/Lists/InstrumentList/InstrumentList';
import InstrumentsProvider from '../../../../../Providers/Database/InstrumentsProvider';
import { globalConstants } from '../../../../../Utils/Constants/GlobalConstants';
import LoadingSpinner from '../../../../Utils/LoadingSpinner/LoadingSpinner';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    showFilterPage: state.toolbarReducer.showFilterPage,
    activeFilter: state.toolbarReducer.activeFilter
  };
};

class InstrumentsSelection extends Component {

  state = {
    loading: false
  };

  addAllInstruments = async filteredCount => {
    if (filteredCount <= InterventionRequestConstants.MAX_INSTRUMENTS_TO_ADD) {
      this.setState({ loading: true });
      const instruments = await InstrumentsProvider
        .getMoreUserInstruments(this.props.instruments, null, null, this.props.activeFilter);
      const selectedInstrumentsMarquages = this.props.selectedInstruments.map(instr => instr.marquage);
      const instrumentsToAdd = instruments.filter(instr => !selectedInstrumentsMarquages.includes(instr.marquage));
      this.props.addOrRemoveInstruments(instrumentsToAdd);
      this.setState({ loading: false });
    } else {
      enqueueSnackbar(SnackbarMessages.capacityError.msg, SnackbarMessages.capacityError.type);
    }
  };

  render() {
    const shouldShowInstrumentList =
      (this.props.instruments && this.props.instruments.length > 0) || (!this.props.instruments);
    const instrumentsList = shouldShowInstrumentList &&
      <InstrumentList
        id="instrumentList"
        instruments={this.props.instruments}
        selectedInstruments={this.props.selectedInstruments}
        headerFromViewType={true}
        viewType={globalConstants.viewTypes.selection}
        onFiltering={this.onFiltering}
        styledTheme={styles(theme)}
        onAddOrRemoveInst={this.props.addOrRemoveInstruments}
        addAllInstruments={this.addAllInstruments}
      />;

    if (this.props.onMobile) {
      return (
        <Dialog
          id="dialog"
          fullScreen
          open={this.props.open && !this.props.showFilterPage}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent sx={{ "&.MuiDialogContent-root": {...styles(theme).dialogContent}}}>
            <Card id="instrumentsListHolder" sx={{ "&.MuiCard-root": {...styles(theme).pageCard}}}>
              <CardContent sx={{ "&.MuiCardContent-root": {...styles(theme).contentCard}}}>
                {this.state.loading && <LoadingSpinner/>}
                {instrumentsList}
                <Fab
                  color="primary"
                  aria-label="Check"
                  sx={{ "&.MuiFab-root": {...styles(theme).validateBtn}}}
                  onClick={this.props.handleMobileSearchView}>
                  <CheckIcon/>
                </Fab>
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>

      );
    } else {
      return (
        <Card id="instrumentsListHolder" sx={{ "&.MuiCard-root": {...styles(theme).pageCard}}}>
          <CardContent sx={{ "&.MuiCardContent-root": {...styles(theme).contentCard}}}>
            {this.state.loading && <LoadingSpinner/>}
            {instrumentsList}
          </CardContent>
        </Card>
      );
    }
  }
}

InstrumentsSelection.propTypes = {

  //region props from redux
  onMobile: PropTypes.bool.isRequired,
  activeFilter: PropTypes.object,
  showFilterPage: PropTypes.bool,

  //endregion

  //region props from parent
  handleMobileSearchView: PropTypes.func,
  open: PropTypes.bool,
  addOrRemoveInstruments: PropTypes.func,
  selectedInstruments: PropTypes.array,
  instruments: PropTypes.array,
  //endregion
};

export default compose(
  connect(mapStateToProps)
)(InstrumentsSelection);
