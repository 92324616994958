const InterventionConstants = {
  INTERVENTIONCARD_NAMES: {
    SERVICE_TYPE: 'type',
    LOCATION: 'location',
    PROVIDER_TYPE: 'providerType',
    PROVIDER: 'provider',
    TECHNICIAN: 'technician',
    DATE: 'date',
    COMMENT: 'comment',
    STATE: 'state',
    STATUS: 'status',
  },
  CONCLUSIONCARD: {
    // file size in bytes (10000000 bytes = 10Mb)
    FILE_MAX_SIZE: 10000000,
    MAX_FILES_NUMBER: 5,
    DOCUMENTS: 'documents',
    MAX_LINKS_NUMBER: 5,
  },
  EXTENSION_FILES_ACCEPTED: 'image/*, .odt, .doc, .docx, .pdf',
};

export default InterventionConstants;
