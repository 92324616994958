import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';

const LIMIT_INTERVENTIONS = 20;
const updateParam = '?updateInstruments';
const interventionDefaultStatus = 'NEW';

class InterventionService {

  static async createManyInterventions(interventions, location, user, typeID, technician) {
    const needToUpdateInstruments = (user && user.length > 0) || (location && location.length > 0);

    let route = routes.createIntervention;

    if (needToUpdateInstruments) {
      route += updateParam;
    }

    const instrumentsIntervention = interventions.map(intervention => {
      return { marquage: intervention.marquage, status: intervention.resultingStatus };
    });

    const data = {
      status: interventionDefaultStatus,
      instruments: instrumentsIntervention,
      generalComments: interventions[0].comment,
      updateFields: {}
    };

    if (user && user.length > 0) {
      data.updateFields.utilisateur = user;
    }

    if (location && location.length > 0) {
      data.updateFields.localisation = location;
    }

    if (typeID) {
      data.typeID = typeID;
    }

    if (technician) {
      data.technician = technician;
    }

    return HttpClient.post(route, data).then(res => res.data);

  }

  static async pushInterventions(interventions, location, user, typeID, technician) {
    const totalCountInterventions = interventions.length;
    let currentCountInterventions = LIMIT_INTERVENTIONS > totalCountInterventions
      ? totalCountInterventions : LIMIT_INTERVENTIONS;
    let result = { updated: [], notUpdated: [] };

    for (let i = 0; i < totalCountInterventions; i += LIMIT_INTERVENTIONS) {
      if ((i > (totalCountInterventions - LIMIT_INTERVENTIONS)) && (totalCountInterventions > LIMIT_INTERVENTIONS)) {
        currentCountInterventions = totalCountInterventions - i;
      }
      const sectionOfInterventions = interventions.slice(i, i + currentCountInterventions);
      const tempResult = await InterventionService
        .createManyInterventions(sectionOfInterventions, location, user, typeID, technician);
      result = { ...result, updated: result.updated.concat(tempResult.updatedInstruments) };
      result = { ...result, notUpdated: result.notUpdated.concat(tempResult.notUpdatedInstruments) };
    }

    return result;
  }

  static getAllInterventionsRequests() {
    return HttpClient.get(routes.getAllInterventions);
  }

  static getAllElligibleInterventionsRequests() {
    return HttpClient.get(routes.getAllRequestIntervention);
  }

  static getAllInterventions(){
    return HttpClient.get(routes.getInterventions);
  }
  static deleteInterventionRequest(id) {
    return HttpClient.delete(routes.deleteInterventionRequest(id));
  }

  static updateInterventionRequestDate(id) {
    return HttpClient.put(routes.updateInterventionRequest(id));
  }

  static async createGenericIntervention(interventionData) {
    const route = routes.createGenericIntervention;
    return HttpClient.post(route, interventionData).then(res => res.data);
  }

  /** Get intervention types from remote database */
  static async getAllInterventionTypes() {
    return HttpClient.get(routes.getAllInterventionTypes).then(res => {
      // Only keep items that are checked (from ADM Front Administration)
      const filteredData = res.data.filter(item => item.checked);
      return Promise.resolve(filteredData);
    }).catch(error => Promise.reject(error));
  }
}

export default InterventionService;
