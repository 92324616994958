import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  intervention_headerIcons: {
    pointerEvents: 'auto!important',
    color: `${theme.palette.secondary.main}!important`,
    marginRight: "32px",
    cursor: 'pointer',
  },
  intervention_headerIcons_disabled: {
    color: `${theme.palette.secondary.dark}!important`,
    cursor: 'not-allowed!important'
  },
});
