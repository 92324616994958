import React, { Component } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Help, Info, TouchApp } from '@mui/icons-material';

import GuideProvider from '../../Providers/Database/GuideProvider';
import { logger, LogTypes } from '../../Utils/Logger';
import { lock, setMode, setStatus, unlock } from '../../store/actions/exportActions';
import { GUIDES, LOCK, MODE, PAGES_WITH_FILTER, STATUSES, TYPE } from '../../Utils/Constants/GuideConstants';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import { localization, style } from './GuideConfig';
import { theme } from '../../Utils/Theme';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { SnackbarMessages } from '../../Utils/Constants/SnackbarMessages';
import Portal from '../Utils/Portal';
import Indicator from './Indicator/Indicator';
import ThemeHelper from '../../Helpers/ThemeHelper';
import { AfterChoiceFirstStep, CommonFirstStep } from '../../Utils/Constants/Guides/common/common.js';
import SequentialDialog from '../Dialogs/SequentialDialog/SequentialDialog';
import { enqueueSnackbar } from 'notistack';

const mapStateToProps = state => {
  return {
    locked: state.guideReducer.locked,
    status: state.guideReducer.status,
    onMobile: state.generalReducer.onMobile,
    currentPage: state.generalReducer.currentPage,
    showFilterPage: state.toolbarReducer.showFilterPage
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    lock: type => dispatch(lock(type)),
    setStatus: status => dispatch(setStatus(status)),
    unlock: () => dispatch(unlock()),
    setMode: mode => dispatch(setMode(mode))
  };
};

/**
 * High-order component adding guide logic
 * @param {JSXElement} WrappedComponent - the component to wrap
 * @param {boolean} ready - either the wrapped component is directly ready or not
 * @param forcePage
 */
export const withGuide = (WrappedComponent, ready = true, forcePage = null) => {
  class WithGuide extends Component {

    state = {
      steps: [],
      stepIndex: 0,
      simpleGuideData: null,
      displayChoiceDialog: false
    };

    componentDidMount() {
      // If the component is ready, we directly try to display the guide (if needed)
      if (ready) {
        this.tryDisplayGuide();
      }
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
      if ((prevProps.status !== this.props.status && this.props.status === STATUSES.READY)
        || (PAGES_WITH_FILTER.includes(this.props.currentPage)
          && prevProps.showFilterPage !== this.props.showFilterPage)
        || (this.props.currentPage === PAGES_IDS.PREFERENCES
          && prevProps.showFilterPage !== this.props.showFilterPage)) {
        this.tryDisplayGuide();
      }
      if (prevProps.status !== this.props.status && this.props.status === STATUSES.INITIATED) {
        this.loadGuideData();
      }

      if ([LOCK.SOFT, LOCK.HARD].includes(prevProps.locked) && this.props.locked === LOCK.NONE) {
        this.setState(prevState => ({
          stepIndex: prevState.stepIndex + 1
        }));
      }
    }

    /**
     * Close SimpleDialog
     */
    closeSimpleGuide = () => {
      this.props.setStatus(STATUSES.FINISHED);
      this.endGuide();
    };

    /**
     * Check if displaying a guide is needed then display it or not.
     */
    tryDisplayGuide = () => {
      if (this.props.status === STATUSES.RUNNING) {
        return;
      }
      if (forcePage) {
        this.currentGuide = GUIDES.find(g => g.id === forcePage);
      } else if (this.props.currentPage === PAGES_IDS.PREFERENCES && this.props.showFilterPage) {
        this.currentGuide = GUIDES.find(g => g.id === strings.filter.preference_filter_id);
      } else if (PAGES_WITH_FILTER.includes(this.props.currentPage) && this.props.showFilterPage) {
        this.currentGuide = GUIDES.find(g => g.id === strings.filter.filter_id);
      } else {
        this.currentGuide = GUIDES.find(g => g.id === this.props.currentPage);
      }

      if (!this.currentGuide) {
        return;
      }
      this.primaryColor = ThemeHelper.getPrimaryColor(this.props.currentPage);
      // Check if guide has already been achieved
      GuideProvider.shouldDisplayGuide(this.currentGuide.name).then(displayGuide => {
        if (displayGuide) {
          this.loadGuideData();
        }
      });
    };

    /**
     * Create path for provided guide
     * @param {string} guideName - the guide's name
     * @param {boolean} onMobile - either user is currently on mobile or not
     */
    buildPath(guideName, onMobile) {
      return guideName + '/' + guideName + (onMobile ? '-mobile' : '') + '.js';
    }

    /**
     * Return an icon based on current step
     * @param {any} step
     */
    buildCustomTitle(step) {
      let icon;
      if (step.lock) {
        icon = <TouchApp/>;
      } else {
        icon = <Info/>;
      }
      return icon;
    }

    /**
     * Load the data file for the given guide, then start the guide process
     */
    loadGuideData = () => {
      if (!this.currentGuide) {
        return;
      }
      const path = this.buildPath(this.currentGuide.name, this.props.onMobile);
      // Retrieve guide data based on guide name
      import('../../Utils/Constants/Guides/' + path).then(async (data) => {
        if (this.currentGuide.type === TYPE.SIMPLE) {
          this.setState({ simpleGuideData: data.default, steps: [], stepIndex: 0 }, () => this.startGuide());
        } else {
          const steps = data.default.map((curr, index) => {
            // Add extra config for each step
            return {
              ...curr,
              disableBeacon: true,
              hideBackButton: index > 0 && [LOCK.HARD, LOCK.SOFT].includes(data.default[index - 1]?.lock),
              hide: curr.hide || (!curr.content && !curr.title),
              title: curr.title ? curr.title : this.buildCustomTitle(curr)
            };
          });
          this.setState(
            {
              steps: this.currentGuide.choice ? steps : [CommonFirstStep].concat(steps),
              stepIndex: 0,
              simpleGuideData: null,
              displayChoiceDialog: this.currentGuide.choice
            },
            () => this.startGuide());
        }
      }).catch(logger);
    };

    /**
     * Guide mode selection
     */
    selectMode(mode) {
      this.setState(state => ({
        displayChoiceDialog: false, steps: [AfterChoiceFirstStep].concat(state.steps)
      }),
      () => {
        this.props.setMode(mode);
        this.startGuide();
      });
    }

    /**
     * Start the guide process
     */
    startGuide() {
      if (!this.state.displayChoiceDialog) {
        this.props.setStatus(STATUSES.RUNNING);
      }
    }

    /**
     * End the guide in locale db, and update status
     */
    endGuide = async (status = STATUS.FINISHED) => {
      await GuideProvider.endGuide(this.currentGuide.name);
      this.props.setStatus(STATUSES.FINISHED);
      if (status === STATUS.SKIPPED && !this.currentGuide.disableMessageOnSkip) {
        enqueueSnackbar(SnackbarMessages.guideSkipped.msg, SnackbarMessages.guideSkipped.type);
      }
    };

    /**
     * Click event handler on soft-locked spotlight
     */
    handleSpotlightClick = () => {
      const currStep = this.state.steps[this.state.stepIndex];
      const currElement = document.getElementById(currStep?.target.substring(1));
      currElement.click();
      this.props.unlock();
    };

    /**
     * Callback function from Joyride after each event.
     * @param {joyride internal state} data
     */
    handleJoyrideCallback = async (data) => {
      const { action, index, status, type } = data;

      const spotLight = document.getElementsByClassName('react-joyride__spotlight');

      if (spotLight.length === 1 && this.state.steps[this.state.stepIndex]?.lock === LOCK.SOFT) {
        spotLight[0].addEventListener('click', () => this.handleSpotlightClick());
      }

      // If current step needs to be locked and is currently displayed, we set a lock variable into redux
      if (data.step && data.step.lock && action === 'update' && type === 'tooltip') {
        this.props.lock({ type: data.step.lock, target: data.step.target });
      }

      if ([EVENTS.STEP_AFTER].includes(type) && [ACTIONS.PREV, ACTIONS.NEXT].includes(action)) {
        // Update state to advance the tour
        this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      }

      // No existing target, go to previous step.
      if ([EVENTS.TARGET_NOT_FOUND].includes(type)) {
        if (process.env.REACT_APP_ENV === 'dev') {
          logger('No element found with target: ' + this.state.steps[this.state.stepIndex].target, LogTypes.ERROR);
        }
        // Stay at current step
        this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      }

      // Guide has been finished and or skipped
      if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        await this.endGuide(status);
      }
    };

    render() {
      const { steps, stepIndex } = this.state;
      const currentStep = steps[stepIndex];
      const isCurrentStepLocked = ([LOCK.SOFT, LOCK.HARD].includes(currentStep?.lock));
      const mobilePadding = '8px';

      const nextButtonStyleOverride = {
        display: isCurrentStepLocked && 'none'
      };

      const tooltipStyleOverride = {
        display: (currentStep?.hide) && 'none',
        maxHeight: '100vh',
        fontSize: this.props.onMobile ? '13px' : '16px',
        padding: this.props.onMobile ? mobilePadding : '12px',
        overflow: 'auto'
      };

      const tooltipContainerStyleOverride = {
        textAlign: (currentStep?.listContent) ? 'start' : 'center'
      };

      const tooltipContentStyleOverride = {
        padding: this.props.onMobile ? mobilePadding : '10px'
      };

      const tooltipFooterStyleOverride = {
        marginTop: this.props.onMobile ? mobilePadding : '10px'
      };

      const overlayStyleOverride = {
        cursor: (currentStep?.lock || currentStep?.allowClick) ? 'pointer' : 'not-allowed'
      };

      const locale = this.props.onMobile ? localization.mobile : localization.web;
      const displayStartLabel = !this.state.simpleGuideData && stepIndex === 0;

      return (
        <>
          {/* Wrapped Component */}
          <WrappedComponent {...this.props} step={currentStep}/>

          {/* ADVANCED GUIDE */}
          {this.state.steps.length > 0 &&
          <Joyride
            continuous
            showSkipButton
            disableCloseOnEsc
            disableOverlayClose
            run={this.props.status === STATUSES.RUNNING}
            steps={this.state.steps}
            callback={this.handleJoyrideCallback}
            stepIndex={this.state.stepIndex}
            locale={{ ...locale, next: displayStartLabel ? localization.start : locale.next }}
            spotlightClicks={currentStep?.hide || currentStep?.allowClick || currentStep?.lock === LOCK.HARD}
            spotlightPadding={(currentStep?.strictSpotlight) ? 2 : 10}
            styles={{
              ...style,
              options: {
                ...style.options,
                arrowColor: currentStep?.hide ? theme.palette.transparent : style.options.arrowColor,
                primaryColor: this.primaryColor
              },
              buttonNext: nextButtonStyleOverride,
              tooltip: tooltipStyleOverride,
              tooltipContainer: tooltipContainerStyleOverride,
              overlay: overlayStyleOverride,
              tooltipContent: tooltipContentStyleOverride,
              tooltipFooter: tooltipFooterStyleOverride
            }}
          />
          }

          {/* SEQUENTIAL GUIDE */}
          {(this.state.simpleGuideData && this.props.status === STATUSES.RUNNING
            && 'steps' in this.state.simpleGuideData) &&
          <SequentialDialog
            icon={<Help/>}
            open={this.props.status === STATUSES.RUNNING}
            title={this.state.simpleGuideData.title}
            onClose={this.closeSimpleGuide}
            steps={this.state.simpleGuideData.steps}/>
          }

          {/* SIMPLE GUIDE */}
          {(this.state.simpleGuideData && this.props.status === STATUSES.RUNNING
            && 'content' in this.state.simpleGuideData) &&
          <SimpleDialog
            open={this.props.status === STATUSES.RUNNING}
            title={this.state.simpleGuideData.title}
            acceptLabel={strings.general.ok}
            onAccept={this.closeSimpleGuide}
            addContentPadding
            style={this.primaryColor}>
            {this.state.simpleGuideData.content}
          </SimpleDialog>
          }

          {this.state.steps.length > 0 && this.props.status === STATUSES.RUNNING && currentStep?.hide && (
            <Portal>
              <Indicator
                action={steps[stepIndex - 1].content}
                leave={() => this.endGuide(STATUS.SKIPPED)}
                onMobile={this.props.onMobile}
              />
            </Portal>
          )}

          {/* MODE SELECTION */}
          {this.state.displayChoiceDialog &&
          <SimpleDialog
            open={this.state.displayChoiceDialog}
            title={strings.guides.modeSelectionTitle}
            acceptLabel={strings.guides.modeClassic}
            onAccept={() => this.selectMode(MODE.CLASSIC)}
            refuseLabel={strings.guides.modeReal}
            onRefuse={() => this.selectMode(MODE.REAL)}
            addContentPadding
            style={this.primaryColor}>
            <h3>{strings.guides.modeSelectionDesc}</h3>
            <ul>
              <li><p>{strings.guides.modeClassicDesc}</p></li>
              <li><p>{strings.guides.modeRealDesc}</p></li>
            </ul>
          </SimpleDialog>
          }
        </>
      );
    }
  }

  WithGuide.propTypes = {
    lock: PropTypes.func.isRequired,
    locked: PropTypes.number.isRequired,
    setStatus: PropTypes.func.isRequired,
    status: PropTypes.number,
    onMobile: PropTypes.bool,
    currentPage: PropTypes.number,
    showFilterPage: PropTypes.bool,
    setMode: PropTypes.func,
    unlock: PropTypes.func,
  };

  return connect(mapStateToProps, mapDispatchToProps)(WithGuide);
};
