import React, { Component } from 'react';
import { styles } from './FeedbackPage.style';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { QuestionAnswer } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { updateToolbarInfo, unlock } from '../../store/actions/exportActions';
import { SnackbarMessages } from '../../Utils/Constants/SnackbarMessages';
import { ConnectivityChecker, ConnectivityCheckerStatus } from '../../Utils/ConnectivityChecker';
import FeedbackService from '../../Providers/WebService/FeedbackService';
import Scrollbar from 'react-scrollbars-custom';
import { STATUSES } from '../../Utils/Constants/GuideConstants';
import { withGuide } from '../Guides/Guidable';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../Utils/Theme';
import { Box } from '@mui/material';

export const mapStateToProps = state => {
  return {
    guideStatus: state.guideReducer.status
  };
};

// maps redux's actions to Component's props
const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
    unlock: () => dispatch(unlock()),
  };
};

const COMMENT_MAX_ROWS = 21;

class FeedbackPage extends Component {

  state = {
    comment: ''
  };

  componentDidMount() {
    // Display toolbar's content (icon, title, iconButtons)
    this.props.updateToolbarInfo({
      title: strings.mainMenu.feedback,
      icon: (<QuestionAnswer />),
      actions: [ToolbarActionsConstants.START_TUTORIAL]
    });
  }

  handleFeedbackChange = e => {
    this.setState({
      comment: e.target.value
    });
  };

  handleSendFeedback = () => {
    ConnectivityChecker.getStatus().then(async value => {
      if (value === ConnectivityCheckerStatus.ONLINE) {
        const commentWithDeviceInfos = FeedbackService.addDeviceInformationsToComment(this.state.comment);
        if (this.props.guideStatus !== STATUSES.RUNNING) {
          await FeedbackService.sendFeedback(commentWithDeviceInfos);
        }
        this.setState({ comment: '' });
        enqueueSnackbar(SnackbarMessages.feedbackSuccess.msg, SnackbarMessages.feedbackSuccess.type);
        this.props.unlock();
      } else {
        enqueueSnackbar(SnackbarMessages.feedbackError.msg, SnackbarMessages.feedbackError.type);
      }
    });
  };


  render() {
    return (
      <Scrollbar
        style={{...styles(theme).scrollArea}}
        noScrollX>
        <Box component="div" sx={{...styles(theme).feedbackPageContent}}>
          <Typography
            variant="h6"
            sx={{ "&.MuiTypography-root": {...styles(theme).darkBlueColor} }}
          >
            {strings.feedback.yourFeedback}
          </Typography>
          <TextField
            id="textField"
            placeholder={strings.feedback.placeholder}
            autoFocus
            multiline
            maxRows={COMMENT_MAX_ROWS}
            rows={COMMENT_MAX_ROWS}
            value={this.state.comment}
            onChange={e => this.handleFeedbackChange(e)}
            margin="normal"
            variant="outlined"
            sx={{ "&.MuiTextField-root": {...styles(theme).commentArea} }}
            InputProps={{
              sx: {
                root: {...styles(theme).cssOutlinedInput},
                focused: {...styles(theme).cssFocused},
                notchedOutline: {...styles(theme).notchedOutline},
              }
            }}
          />
          <Button
            id="fbButton"
            disabled={this.state.comment.length === 0}
            size="medium"
            variant="contained"
            sx={{ "&.MuiButton-root": {...styles(theme).submit} }}
            onClick={() => this.handleSendFeedback()}>
            {strings.feedback.send}
          </Button>
        </Box>
      </Scrollbar>
    );
  }
}

FeedbackPage.propTypes = {
  updateToolbarInfo: PropTypes.func,
  unlock: PropTypes.func.isRequired,
  guideStatus: PropTypes.number
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(withGuide(FeedbackPage));
