import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  textField: {
    display: 'flex'
  },
  error: {
    color: theme.palette.error.main
  }
});
