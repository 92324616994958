import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import InstrumentHelper from '../../../Helpers/InstrumentHelper';
import PropTypes from 'prop-types';
import { instrumentType } from '../../../Utils/CentralizedPropTypes/CentralizedPropTypes';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { connect } from 'react-redux';
import { SnackbarMessages } from '../../../Utils/Constants/SnackbarMessages';
import { InstrumentConstants } from '../../../Utils/Constants/InstrumentConstants';
import { compose } from 'redux';
import { styles } from './UpdateInstrumentsDialog.style';
import Typography from '@mui/material/Typography';
import { Help } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornmentWithCamera from '../../Utils/InputAdornmentCamera/InputAdornmentWithCamera';
import { ToastService } from '../../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../../Utils/Constants/ToastTypes';
import CameraScan from '../../CameraScan/CameraScan';
import { instrumentListNeedsUpdate } from '../../../store/actions/exportActions';
import { isNotComputer } from "../../Utils/ResponsiveSelect/ResponsiveSelect";
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../Utils/Theme';
import { Box } from '@mui/material';

export const mapDispatchToProps = dispatch => {
  return {
    instrumentListNeedsUpdate: (msg, type) => dispatch(instrumentListNeedsUpdate(msg, type)),
  };
};

const { localisation, utilisateur } = strings.updateInstruments;

class UpdateInstrumentsDialog extends Component {

  state = {
    Localisation: null, // values NEED to be null so that we don't overwrite the instrument if they are not set
    Utilisateur: null, // values NEED to be null so that we don't overwrite the instrument if they are not set
    noEmptyFieldsMsg: false,
    cameraOpen: false,
    editingField: null
  };

  handleCameraScan = barcode => {
    if (this.state.editingField === localisation && barcode.length > 0) {
      this.setState({ Localisation: barcode });
    } else if (this.state.editingField === utilisateur && barcode.length > 0) {
      this.setState({ Utilisateur: barcode });
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.wrongBarcode);
    }
  };

  onCameraClose = () => {
    this.setState({ cameraOpen: false });
  };

  handleCamera = (choice) => {
    if (choice === localisation || choice === utilisateur) {
      this.setState({
        cameraOpen: true,
        editingField: choice
      });
    }
  };

  handleChange = e => {
    const elementToUpdate = e.target.name;
    if (elementToUpdate === localisation) {
      this.setState({ Localisation : e.target.value });
    } else {
      this.setState({ Utilisateur : e.target.value });
    }
  };

  handleDialog = () => {
    // values NEED to be set to null on cancel for the same reason that they are null at initialization
    this.setState({ noEmptyFieldsMsg: false, Utilisateur: null, Localisation: null });
    this.props.handleDialog();
  };

  updateInstruments = () => {
    if (this.state.Localisation == null && this.state.Utilisateur == null) {
      this.setState({ noEmptyFieldsMsg: true });
    } else {
      this.setState({ noEmptyFieldsMsg: false });
      if (this.props.shouldForceUpdate) {
        InstrumentHelper.createInstrumentsUpdate(this.props.instrumentsToUpdate, this.state.Localisation,
          this.state.Utilisateur)
          .then(answer => {
            let snackbar = SnackbarMessages.instrumentModificationWarning;
            if (answer === InstrumentConstants.updateInstrumentsAnswer.done) {
              snackbar = SnackbarMessages.instrumentModificationSuccess;
              // update the instruments list
              this.props.instrumentListNeedsUpdate(true);

              // update the scanned instruments list
              this.props.onAccept(this.state.Utilisateur, this.state.Localisation);
            }
            enqueueSnackbar(snackbar.msg, snackbar.type);
          })
          .catch(() => {
            enqueueSnackbar(SnackbarMessages.instrumentModificationError.msg,
              SnackbarMessages.instrumentModificationError.type);
          });
      } else {
        this.props.onAccept(this.state.Utilisateur, this.state.Localisation);
        enqueueSnackbar(SnackbarMessages.instrumentModificationSuccess.msg,
          SnackbarMessages.instrumentModificationSuccess.type);
      }
      this.props.handleDialog();
    }
  };

  render() {
    if (!this.props.displayDialog) {
      return null;
    }

    const headerIcon =
      <IconButton sx={{ "&.MuiIconButton-root": {...styles(theme).headerIcon}}} size="large">
        <Help/>
      </IconButton>;

    const content =
      <Box component="div" sx={{...styles(theme).textfields_container}}>
        <Typography
          variant="body1"
          color="error"
          style={{
            display: this.state.noEmptyFieldsMsg ? 'inline' : 'none'
          }}
        >
          {strings.updateInstruments.noEmptyFieldsMsg}
        </Typography>
        <TextField
          id={`${localisation}TextField`}
          variant="standard"
          error={this.state.noEmptyFieldsMsg}
          name={localisation}
          label={localisation}
          onChange={e => this.handleChange(e)}
          margin="normal"
          value={this.state.Localisation || this.props.defaultLocation}
          InputProps={{
            endAdornment:
              <InputAdornmentWithCamera name={localisation}
                camera={() => this.handleCamera(localisation)}/>,
            sx: {
              input: {...(isNotComputer && styles(theme).tabletFontSize)}
            }
          }}
          InputLabelProps={{
            sx: {
              root: {...(isNotComputer && styles(theme).tabletFontSize)}
            }
          }}
        />
        <TextField
          id={`${utilisateur}TextField`}
          variant="standard"
          error={this.state.noEmptyFieldsMsg}
          name={utilisateur}
          label={utilisateur}
          onChange={e => this.handleChange(e)}
          margin="normal"
          value={this.state.Utilisateur || this.props.defaultUser}
          InputProps={{
            endAdornment:
              <InputAdornmentWithCamera name={utilisateur}
                camera={() => this.handleCamera(utilisateur)}/>,
            sx: {
              input: {...(isNotComputer && styles(theme).tabletFontSize)}
            }
          }}
          InputLabelProps={{
            sx: {
              root: {...(isNotComputer && styles(theme).tabletFontSize)}
            }
          }}
        />
      </Box>;

    return (
      <>
        <SimpleDialog
          open={this.props.displayDialog}
          onRefuse={this.handleDialog}
          onAccept={this.updateInstruments}
          title={strings.updateInstruments.title}
          acceptLabel={strings.dialog.answer.submit}
          refuseLabel={strings.dialog.answer.cancel}
          tooltipIcon={headerIcon}
          tooltip={strings.updateInstruments.tooltip}
          addContentPadding
        >
          {content}
        </SimpleDialog>
        {this.state.cameraOpen &&
        <CameraScan
          id="cameraScan"
          open={this.state.cameraOpen}
          onClose={this.onCameraClose}
          onScan={this.handleCameraScan}/>
        }
      </>
    );
  }
}

UpdateInstrumentsDialog.propTypes = {
  instrumentsToUpdate: PropTypes.arrayOf(instrumentType),
  displayDialog: PropTypes.bool.isRequired,
  handleDialog: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  defaultLocation: PropTypes.string,
  defaultUser: PropTypes.string,
  instrumentListNeedsUpdate: PropTypes.func,
  shouldForceUpdate: PropTypes.bool,
};

UpdateInstrumentsDialog.defaultProps = {
  defaultLocation: '',
  defaultUser: '',
  shouldForceUpdate: true,
};

export default compose(
  connect(null, mapDispatchToProps)
)(UpdateInstrumentsDialog);
