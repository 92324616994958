import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { isIOS } from 'react-device-detect';

import { updateActiveStep, updateToolbarInfo } from '../../../../store/actions/exportActions';
import ServiceCard from './ServiceCard/ServiceCard';
import ShippingCard from './ShippingCard/ShippingCard';
import ServiceTypeCard from './ServiceTypeCard/ServiceTypeCard';
import ShipperCard from './ShipperCard/ShipperCard';
import CarrierCard from './CarrierCard/CarrierCard';
import { styles } from './ServiceShippingStep.style';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import BuildIcon from '@mui/icons-material/Build';
import Paper from '@mui/material/Paper';
import LoadingSpinner from '../../../Utils/LoadingSpinner/LoadingSpinner';
import PreferenceNameCard from '../../../Preferences/PreferenceNameCard';
import { Validator, ValidatorRules } from '../../../../Utils/Validator';
import { ToolbarActionsConstants } from '../../../../Utils/Constants/ToolbarActionsConstants';
import { style } from '../../../Guides/GuideConfig';
import { theme } from '../../../../Utils/Theme';
import merge from 'lodash/merge';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    activeStep: state.interventionReducer.activeStep,
    onMobile: state.generalReducer.onMobile,
    multipleInterventionEnabled: state.interventionReducer.multipleInterventionEnabled,
    multipleInterventions: state.interventionReducer.multipleInterventions,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    updateActiveStep: value => dispatch(updateActiveStep(value)),
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo))
  };
};

class ServiceShippingStep extends Component {

  state = {
    loading: false
  };

  constructor(props) {
    super(props);
    this.reducer = (acc, curr) => acc && curr;
  }

  componentDidMount() {
    if (this.props.fromPreferencesPage) {
      return;
    }
    if (this.props.onMobile) {
      this.props.updateToolbarInfo({
        title: strings.interventionRequest.serviceShippingTitle,
        icon: (<BuildIcon />),
        actions: [this.props.multipleInterventionEnabled && ToolbarActionsConstants.MULTIPLE_INTERVENTION_INSTRUMENTS]
      });
    } else {
      this.props.updateToolbarInfo({
        title: '',
        icon: null,
        actions: []
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.serviceShippingPage !== prevProps.serviceShippingPage && !this.props.fromPreferencesPage) {
      this.props.updateActiveStep({ index: this.props.activeStep.index, isValid: this.isValid() });
    }
    if (prevProps.multipleInterventionEnabled !== this.props.multipleInterventionEnabled || prevProps.activeStep.multipleInterventionIndex !== this.props.activeStep.multipleInterventionIndex) {
      if (this.props.onMobile) {
        this.props.updateToolbarInfo({
          title: strings.interventionRequest.dynamicStepTitle(strings.interventionRequest.serviceShippingTitle, this.props.activeStep.multipleInterventionIndex + 1, this.props.multipleInterventions.length),
          icon: (<BuildIcon />),
          actions: [this.props.multipleInterventionEnabled && ToolbarActionsConstants.MULTIPLE_INTERVENTION_INSTRUMENTS]
        });
      }
    }
  }

  isValid = () => {
    return (
      this.serviceTypeCardIsValid()
      && this.serviceCardIsValid()
      && this.shippingCardIsValid()
      && this.shipperCardIsValid()
      && this.carrierCardIsValid()
    );
  };

  serviceTypeCardIsValid() {
    return this.props.serviceShippingPage.serviceType !== undefined;
  }

  serviceCardIsValid() {
    const noEmptyFields = [
      this.props.serviceShippingPage.serviceLocation + '',
      this.props.serviceShippingPage.providerType,
      this.props.serviceShippingPage.provider.name,
      this.props.serviceShippingPage.providerAddress
    ]
      .map(field => Validator.checkValidity(field, [ValidatorRules.NOT_EMPTY]))
      .reduce(this.reducer);

    const validEmail = Validator.checkValidity(this.props.serviceShippingPage.providerMail, [ValidatorRules.EMAIL]);

    return noEmptyFields && validEmail;
  }

  shippingCardIsValid() {
    return (
      this.props.serviceShippingPage.shippingDate <= this.props.serviceShippingPage.returnDate
    );
  }

  shipperCardIsValid() {
    return (
      this.props.serviceShippingPage.shipperId !== ''
      && this.props.serviceShippingPage.shipperAddress !== ''
      && this.props.serviceShippingPage.shipperPhone !== ''
      && this.props.serviceShippingPage.shipperMail !== ''
    );
  }

  carrierCardIsValid() {
    return this.props.serviceShippingPage.carrierMode.id !== '' || this.props.serviceShippingPage.carrier !== '';
  }

  setLoading = value => {
    this.setState({ loading: value });
  };

  render() {
    if (this.props.onMobile) {
      return (
        <Paper elevation={2} id="serviceShipping" sx={{ "&.MuiPaper-root": {...styles(theme).stepper_pages_paper} }}>
          {this.state.loading && <LoadingSpinner />}
          <Scrollbars
            style={{...merge({}, styles(theme).stepper_pages_scrollArea, (isIOS && styles(theme).ios_sizing)) }}
            // hide horizontal scrollbar
            renderTrackHorizontal={props => <div {...props}
              style={{ display: 'none' }}
              className="track-horizontal" />}
          >
            <Grid container>
              {this.props.fromPreferencesPage &&
                <Grid item xs={12}>
                  <PreferenceNameCard
                    styling={style(theme)}
                    preferenceName={this.props.preferenceName}
                    handlePrefNameChange={this.props.handlePrefNameChange}
                  />
                </Grid>
              }
              <Grid item xs={12}>
                <ServiceTypeCard setLoading={this.setLoading} fromPreferencesPage={this.props.fromPreferencesPage} multipleInterventionEnabledOnSite={this.props.multipleInterventionEnabledOnSite} selectedInstruments={this.props.selectedInstruments} />
              </Grid>
              <Grid item xs={12}>
                <ServiceCard submitted={this.props.submitted} fromPreferencesPage={this.props.fromPreferencesPage} />
              </Grid>
              <Grid item xs={12}>
                {!this.props.fromPreferencesPage &&
                  <ShippingCard />
                }
              </Grid>
              <Grid item xs={12}>
                <ShipperCard fromPreferencesPage={this.props.fromPreferencesPage} />
              </Grid>
              <Grid item xs={12}>
                <CarrierCard />
              </Grid>
            </Grid>
          </Scrollbars>
        </Paper>
      );
    } else {
      return (
        <Paper elevation={2} id="serviceShipping" sx={{ "&.MuiPaper-root": {...styles(theme).stepper_pages_paper} }}>
          {this.state.loading && <LoadingSpinner />}
          <Scrollbars
            style={{...styles(theme).stepper_pages_scrollArea}}
            // hide horizontal scrollbar
            renderTrackHorizontal={props => <div {...props}
              style={{ display: 'none' }}
              className="track-horizontal" />}
          >
            <Grid container>
              <Grid item xs={6} sx={{...styles(theme).column}}>
                <ServiceTypeCard setLoading={this.setLoading} fromPreferencesPage={this.props.fromPreferencesPage} selectedInstruments={this.props.selectedInstruments} multipleInterventionEnabledOnSite={this.props.multipleInterventionEnabledOnSite} />
                <ServiceCard submitted={this.props.submitted} fromPreferencesPage={this.props.fromPreferencesPage} />
              </Grid>
              <Grid item xs={6} sx={{...styles(theme).column}}>
                {this.props.fromPreferencesPage
                  ?
                  <PreferenceNameCard
                    styling={styles(theme)}
                    preferenceName={this.props.preferenceName}
                    submitted={this.props.submitted}
                    handlePrefNameChange={this.props.handlePrefNameChange}
                  />
                  :
                  <ShippingCard />
                }
                <ShipperCard fromPreferencesPage={this.props.fromPreferencesPage} />
                <CarrierCard />
              </Grid>
            </Grid>
          </Scrollbars>
        </Paper>
      );
    }

  }

}

ServiceShippingStep.propTypes = {
  serviceShippingPage: PropTypes.object.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  activeStep: PropTypes.object.isRequired,
  onMobile: PropTypes.bool.isRequired,
  updateToolbarInfo: PropTypes.func.isRequired,
  fromPreferencesPage: PropTypes.bool,
  handlePrefNameChange: PropTypes.func,
  preferenceName: PropTypes.string,
  submitted: PropTypes.bool,
  multipleInterventionEnabled: PropTypes.bool,
  multipleInterventionEnabledOnSite: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ServiceShippingStep);
