const PAGES_IDS = {
  // Mes Instruments
  INSTRUMENTS: 0,
  // Interventions
  INTERVENTION: 1,
  // Inventaire
  INVENTORY: 2,
  // Instruments favoris
  FOLLOWED_INSTRUMENTS: 3,
  // Historique
  HISTORY: 4,
  // Préférences
  PREFERENCES: 5,
  // feedbacks
  FEEDBACKS: 6,
  // Accueil (Mobile)
  HOME: 7,
  // Profil utilisateur
  USER_PROFILE: 8,
  // Scan page
  SCAN: 9,
  // Help page
  HELP: 10,
  // Demandes d'interventions
  INTERVENTION_REQUEST: 11,
  // Création d'interventions
  INTERVENTION_CREATION: 12,
  // Notes de mise à jour
  UPDATE: 13,
  // Mode invité
  GUEST: 14,
  // Historique demandes d'intervention
  HISTORIC_DEMAND_INTERVENTION: 15
};

export default PAGES_IDS;
