import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  footerTitle: {
    textAlign: 'center',
    fontSize: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontWeight: 500,
    lineHeight: 1.5,
    margin: "0px",
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  buttonTitle: {
    textAlign: 'center',
    fontSize: theme.spacing(2),
    color: theme.palette.secondary.main,
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  buttonStep: {
    pointerEvents: 'auto!important',
    '&:disabled': {
      color: theme.palette.lightGrayTheme.main,
      visibility: 'hidden'
    },
    '&:disabled:hover': {
      cursor: 'not-allowed'
    },
    '&:hover': {
      cursor: 'pointer'
    },
    color: theme.palette.secondary.main,
    padding: '0 8px'
  },
  fullStepper: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '0 0 8px 8px',
    [theme.breakpoints.down('md')]: {
      height: '9vh',
    },
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.MainMenu.menu,
      position: 'absolute',
      bottom: "0px",
      borderRadius: "0px",
    }
  },
  stepper: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    padding: '0px 8px',
    borderRadius: '0 0 8px 8px',
    height: '6vh',
    marginTop: "8px",
    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.MainMenu.menu,
    }
  },
  divButton1: {
    marginRight: 'auto',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      width: "300px",
    }
  },
  buttonStep_disabled: {
    visibility: 'hidden'
  },
  divButton2: {
    marginLeft: 'auto',
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('md')]: {
      width: "300px",
    }
  },
  dots: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center'
  },
  dot: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(),
      height: theme.spacing(),
    },
  }
});
