import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { styles } from './StringDialog.style';
import { isIOS } from 'react-device-detect';
import { FILTER_FIELDS } from '../../../Utils/Constants/FilterConstants';
import CameraScan from '../../CameraScan/CameraScan';
import { ToastService } from '../../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../../Utils/Constants/ToastTypes';
import InputAdornmentWithCamera from '../../Utils/InputAdornmentCamera/InputAdornmentWithCamera';
import { isNotComputer } from "../../Utils/ResponsiveSelect/ResponsiveSelect";
import { theme } from '../../../Utils/Theme';

class StringDialog extends Component {

  state = {
    inputIsValid: true,
    currentValue: '',
    cameraOpen: false
  };

  handleChange = event => {
    this.setState({ currentValue: event.target.value });
  };

  handleOnValidate = () => {
    const value = this.state.currentValue.trim();

    if (value.length === 0) {
      this.setState({ inputIsValid: false });
      return;
    }

    // Only allow multiple data from some fields
    if ([
      FILTER_FIELDS.marquage.name,
      FILTER_FIELDS.numeroDeSerie.name
    ].includes(this.props.filter)) {
      if (this.handleMultipleValues(value)) {
        return;
      }
    }

    this.props.onAccept(value);
  }

  handleCameraScan = barcode => {
    if(barcode.length > 0)  {
      this.setState({ currentValue : barcode });
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.wrongBarcode);
    }
  }

  handleCamera = () => {
    this.setState({ cameraOpen: true });
  }
  onCameraClose = () => {
    this.setState({ cameraOpen: false });
  };
  handleMultipleValues = value => {
    const delimiters = [' ', ';', ','];
    const currentDelimiter = delimiters.find(d => value.includes(d));

    if (currentDelimiter) {
      const values = value.split(currentDelimiter);
      for (let val of values) {
        if (val !== '') {
          this.props.onAccept(val);
        }
      }
      return true;
    }
    return false;
  }


  render() {

    return (
      <SimpleDialog
        id='simpleDialog'
        open={this.props.open}
        onRefuse={this.props.onRefuse}
        title={this.props.title}
        onAccept={this.handleOnValidate}
        refuseLabel={this.props.refuseLabel}
        acceptLabel={this.props.acceptLabel}
        addContentPadding
      >
        {
          !this.state.inputIsValid && <Box component="b" sx={{...styles(theme).error}}>{strings.error.emptyTextField}</Box>
        }
        <TextField
          autoFocus={!isIOS}
          variant="standard"
          error={!this.state.inputIsValid}
          label={this.props.text}
          value={this.state.currentValue}
          onChange={this.handleChange}
          margin="normal"
          sx={{ "&.MuiTextField-root": {...styles(theme).textField}}}
          InputProps={{
            endAdornment: <InputAdornmentWithCamera camera={this.handleCamera} />,
            sx: {
              input: {...(isNotComputer && styles(theme).tabletFontSize)}
            }
          }}
          InputLabelProps={{
            sx: {
              root: {...(isNotComputer && styles(theme).tabletFontSize)}
            }
          }}
        />
        {this.state.cameraOpen &&
        <CameraScan
          id="cameraScan"
          open={this.state.cameraOpen}
          onClose={this.onCameraClose}
          onScan={this.handleCameraScan} />
        }
      </SimpleDialog>
    );
  }
}

StringDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onRefuse: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  refuseLabel: PropTypes.string.isRequired,
  acceptLabel: PropTypes.string,
  filter: PropTypes.string.isRequired
};

export default StringDialog;
