import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { updateFormServiceShipping } from '../../../../../store/actions/exportActions';
import { styles } from '../ServiceShippingStep.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import DateService from '../../../../../Providers/WebService/DateService';
import { addBusinessDays } from '../../../../../Utils/DateUtils';
import { InterventionRequestConstants as constants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    onMobile: state.generalReducer.onMobile
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    handleChange: (name, value) => dispatch(updateFormServiceShipping(name, value))
  };
};

class ShippingCard extends Component {

  async componentDidMount() {
    const dateNow = await DateService.getCurrentDate();
    this.props.handleChange({ name: 'shippingDate', value: dateNow });
    const dateReturn = addBusinessDays(this.props.serviceShippingPage.shippingDate, constants.STANDARD_CHECK_DAYS_TO_ADD);
    this.props.handleChange({ name: 'returnDate', value: dateReturn });
  }

  shouldComponentUpdate(prevProps) {
    return (prevProps.serviceShippingPage.shippingDate !== this.props.serviceShippingPage.shippingDate)
      || (prevProps.serviceShippingPage.returnDate !== this.props.serviceShippingPage.returnDate);
  }

  render() {
    const { serviceShippingPage } = this.props;

    return (
      <Paper elevation={2} id="shipping" sx={{...styles(theme).container}}>
        <Typography variant="h6" sx={{...styles(theme).area_title}} gutterBottom>
          {strings.interventionRequest.shippingTitle}
        </Typography>
        <Grid id="container" container spacing={this.props.onMobile ? 0 : 8}>
          <Grid item xs={12} sx={{ ...styles(theme).pickers}}>
            <MobileDatePicker
              id="shippingDatePicker"
              slotProps={{ textField: { variant: 'standard' } }}
              sx={{...styles(theme).datePicker}}
              keyboard={!this.props.onMobile}
              label={strings.interventionRequest.shippingDateLabel}
              value={serviceShippingPage.shippingDate}
              defaultValue={serviceShippingPage.shippingDate}
              onAccept={e => this.props.handleChange({ name: 'shippingDate', value: e })}
              animateYearScrolling
              format={strings.general.dateFormat}
              minDate={serviceShippingPage.shippingDate}
            />
            <MobileDatePicker
              id="returnDatePicker"
              slotProps={{ textField: { variant: 'standard' } }}
              sx={{...styles(theme).datePicker}}
              keyboard={!this.props.onMobile}
              label={strings.interventionRequest.returnDateLabel}
              value={serviceShippingPage.returnDate}
              defaultValue={serviceShippingPage.returnDate}
              onAccept={e => this.props.handleChange({ name: 'returnDate', value: e })}
              animateYearScrolling
              format={strings.general.dateFormat}
              minDate={serviceShippingPage.shippingDate}
            />
          </Grid>
          <Grid item xs={12} sx={{"&.MuiGrid-root": {...styles(theme).custom_input_grid}}}>
            <TextField
              id="observation"
              name="observation"
              label={strings.interventionRequest.observationLabel}
              multiline
              fullWidth
              rows="4"
              variant="outlined"
              sx={{...styles(theme).textField}}
              onChange={e => this.props.handleChange({ name: e.target.name, value: e.target.value })}
              margin="normal"
              InputProps={{
                sx: {...styles(theme).input_content}
              }}
              InputLabelProps={{
                sx: {...styles(theme).input_label}
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

ShippingCard.propTypes = {
  handleChange: PropTypes.func.isRequired,
  serviceShippingPage: PropTypes.object.isRequired,
  onMobile: PropTypes.bool
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ShippingCard);
