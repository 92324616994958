import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';
import { JwtHelperService } from '../../Utils/JwtHelperService';

class PreferencesService {

  static async getUserPreferences(typeID) {
    return HttpClient.get(routes.userPreferences + '/' + typeID + '/' + JwtHelperService.getUserLogin()).then(res => res.data);
  }

  static async addUserPreferences(userPref) {
    return HttpClient.post(routes.userPreferences, userPref).then(res => res.data);
  }

  static async updateUserPreferences(userPrefName, userPrefUpdate) {
    const formattedData = {
      name: userPrefName,
      preferencesData: userPrefUpdate
    };
    return HttpClient.put(routes.userPreferences, formattedData);
  }

  static async deleteUserPreferences(userPref) {
    return HttpClient.delete(routes.userPreferences, { data: userPref });

  }

}

export default PreferencesService;
