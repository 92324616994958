import React, { useEffect } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';

import BugService from '../../../Providers/WebService/BugService';
import applicationIconError from '../../../Assets/applicationIconError.gif';
import { styles } from './ErrorPage.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import PropTypes from 'prop-types';
import { theme } from '../../../Utils/Theme';

function ErrorPage(props) {

  useEffect(() => {
    BugService.postNewBug(props.error, props.stackTrace);
  }, []);

  return (
    <Box component="main" sx={{...styles(theme).main}}>
      <Paper elevation={2} sx={{...styles(theme).container}}>
        <Box component="img" src={applicationIconError} sx={{...styles(theme).logo}} alt={'Erreur'} />
        <h3>{strings.errorPage.errorHasOccured}</h3>
        <Typography variant="subtitle2">
          {strings.errorPage.errorDesc}
        </Typography>
        <Button 
          color="primary" 
          variant="contained" 
          size="large" 
          sx={{...styles(theme).reloadButton}} 
          onClick={() => window.location.reload(true)}
        >
          {strings.errorPage.reloadApp}
        </Button>
        {process.env.REACT_APP_ENV === 'dev' && (
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {props.error}
            <br />
            {props.stackTrace}
          </details>
        )}
      </Paper>
    </Box>
  );
}

ErrorPage.propTypes = {
  error: PropTypes.string,
  stackTrace: PropTypes.string,
};


export default ErrorPage;
