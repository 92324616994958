import React, { useState } from 'react';
import { Box, CircularProgress, Fab } from '@mui/material';
import { TouchApp } from '@mui/icons-material';
import PropTypes from 'prop-types';

import { styles } from './Indicator.style';
import SimpleDialog from '../../Dialogs/SimpleDialog/SimpleDialog';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { theme } from '../../../Utils/Theme';

function Indicator(props) {
  const [isOpen, openDialog] = useState(false);
  const progressSizeSmall = 52;
  const progressSizeLarge = 68;

  return (
    <>
      <Box component="div" sx={{...styles(theme).root}} onClick={() => openDialog(true)}>
        <div>
          <Fab size={props.onMobile ? 'small' : 'large'} aria-label="save" color="primary">
            <TouchApp />
          </Fab>
          {<CircularProgress
            size={props.onMobile ? progressSizeSmall : progressSizeLarge}
            sx={{...styles(theme).fabProgress}}
          />}
        </div>
      </Box>

      <SimpleDialog
        title={strings.guides.actionRequired}
        open={isOpen}
        acceptLabel={strings.general.ok}
        refuseLabel={strings.guides.leaveGuide}
        onAccept={() => openDialog(false)}
        onRefuse={() => props.leave()}
        highPriority
        addContentPadding
      >
        {props.action}
      </SimpleDialog>
    </>
  );
}

Indicator.propTypes = {
  leave: PropTypes.func.isRequired,
  action: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
  onMobile: PropTypes.bool,
};

export default Indicator;
