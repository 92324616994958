import { Typography } from '@mui/material';
import React from 'react';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import PropTypes from 'prop-types';
import { styles } from './StylizedField.style';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';


const StylizedField = props => {

  return (
    (props.field && props.field.trim() !== '') ?
      <Typography variant="body2"
        sx={{...styles(theme).otherInformation}}
        style={{
          display: 'inline',
          color: props.color
        }}>
        {props.field}
      </Typography> :
      <Typography variant="body2"
        sx={{...merge({}, styles(theme).otherInformation, styles(theme).emptyStyle)}}>
        {strings.general.emptyField}
      </Typography>);
};

StylizedField.propTypes = {
  field: PropTypes.string.isRequired,
  color: PropTypes.func.isRequired
};

export default StylizedField;
