import sharedStyles from '../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  pageCard: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(),
    },
  },
  headerIcons: {
    [theme.breakpoints.down('lg')]: {
      padding: "6px",
    },
    color: theme.palette.primary.main
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '95%',
    margin: 'auto',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    paddingTop: "10px",
    [theme.breakpoints.only('xs')]: {
      paddingTop: "0px",
    },
  },
  headerTitle: {
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      paddingLeft: "0px",
    },
  },
  interventionHeaderTitle: {
    color: theme.palette.primary.main,
    paddingLeft: "0px",
    fontSize: "18px",
    lineHeight: 'normal!important'
  },
  contentCard: {
    height: '100%',
    '&:last-child': {
      paddingBottom: "0px"
    },
    backgroundColor: theme.palette.pureWhite,
    [theme.breakpoints.down('md')]: {
      margin: "0px",
      padding: '16px 0px 0px 0px'
    },
    [theme.breakpoints.only('xs')]: {
      padding: "0px"
    }
  },
  dialogContent: {
    [theme.breakpoints.down('md')]: {
      height: '100%',
      margin: "0px",
      padding: '0 !important'
    },
  },
  validateBtn: {
    position: 'fixed',
    bottom: '3vh',
    right: theme.spacing(2),
  },
  headerRight: {
    display: 'flex'
  },
});

