import Routes from './Routes';
import HttpClient from '../Providers/HttpClient';

// Possibles values of connectivity status
const ConnectivityCheckerStatus = {
  OFFLINE: 1,
  WEBSERVER_UNAVAILABLE: 2,
  ONLINE: 3
};

const simpleServerApiRoute = Routes.checkVersion;

class ConnectivityChecker {
  currentStatus = ConnectivityCheckerStatus.OFFLINE;

  // Get information on current connectivity state
  static getStatus() {
    return ConnectivityChecker.isWebserverReachable().then(() => {
      // ONLINE
      this.currentStatus = ConnectivityCheckerStatus.ONLINE;
      return Promise.resolve(this.currentStatus);
    }).catch(() => {
      // Webserver unavailable
      this.currentStatus = ConnectivityCheckerStatus.WEBSERVER_UNAVAILABLE;
      return Promise.resolve(this.currentStatus);
    });
  }

  // Check if connected to ADM's server
  static isWebserverReachable() {
    return HttpClient.get(simpleServerApiRoute)
      .then(() => Promise.resolve())
      .catch(() => Promise.reject());
  }

  // Check if connected to both internet and ADM's server
  static isConnected() {
    return ConnectivityChecker.getStatus().then(connectivityStatus =>
      Promise.resolve(connectivityStatus === ConnectivityCheckerStatus.ONLINE));
  }

}

export { ConnectivityChecker, ConnectivityCheckerStatus };
