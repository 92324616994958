import { globalConstants } from '../../../Utils/Constants/GlobalConstants';

const { FULL_PERCENTAGE, FULL_VIEWPORT_WIDTH, CENTER_MARGIN } = globalConstants.style;

export let styles = theme => ({
  main: {
    height: FULL_PERCENTAGE,
    width: FULL_VIEWPORT_WIDTH,
    padding: '3% 0',
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    backgroundColor: '#eaeaea'
  },
  container: {
    [theme.breakpoints.only('xs')]: {
      width: '87vw'
    },
    [theme.breakpoints.only('sm')]: {
      width: '55vw'
    },
    margin: CENTER_MARGIN,
    width: '40vw',
    justifyContent: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    textAlign: 'center',
  },
  logo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    width: '25%',
    [theme.breakpoints.only('xs')]: {
      width: '30%'
    },
  },
  reloadButton: {
    margin: "16px"
  }
});