import dayjs from 'dayjs';

import HttpClient from '../HttpClient';
import routes from '../../Utils/Routes';

class DateService {

  static async getCurrentDate() {
    return HttpClient.simpleGet(routes.getCurrentDateFromServer).then(date => dayjs(date));
  }
}

export default DateService;

