import PropTypes from 'prop-types';
import React from 'react';

const PasswordForgottenModalContent = props => (
  <p style={{ textAlign: 'center' }}>
    {props.text}
    <a href={'mailto:' + props.mail}>
      {props.mail}
    </a>
  </p>
);

PasswordForgottenModalContent.propTypes = {
  text: PropTypes.string,
  mail: PropTypes.string
};

export default PasswordForgottenModalContent;
