import React from 'react';
import {
  Typography,
  IconButton,
  Paper,
  Box
} from '@mui/material';
import {
  NotificationsNone,
  Notifications
} from '@mui/icons-material';
import InstrumentImage from '../../../InstrumentCard/InstrumentImage/InstrumentImage';
import { styles } from './InstrumentItemCardTemplate.style';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import InstrumentHelper from '../../../../Helpers/InstrumentHelper';
import { isIOS } from 'react-device-detect';
import { theme } from '../../../../Utils/Theme';
import merge from 'lodash/merge';

const InstrumentItemCardTemplate = props => {

  const generateTypography = (variant, content, style) => {
    return (
      <Typography
        style={style}
        variant={variant}
        sx={{...styles(theme).defaultColorText}}
        noWrap
      >
        {content}
      </Typography>
    );
  };

  const styleBorderCardHorsDelai = props.horsDelais 
    ? {...merge({}, styles(theme).card, styles(theme).borderCard)} : {...styles(theme).card};

  const localisationContent = props.instrument.localisation ?
    props.instrument.localisation : strings.general.noInformation;

  const etatMetrologiqueText = props.instrument.etatMetrologique ?
    props.instrument.etatMetrologique : strings.general.noInformation;

  const periodiciteProchaineOperation = props.instrument.periodiciteProchaineOperation 
    ? strings.instrumentCard.prochaineIntervention +
    dayjs(props.instrument.periodiciteProchaineOperation).format(strings.general.dateFormat)
    : strings.general.noInformation;

  const styleHorsDelai = props.horsDelais ? theme.palette.redTheme.main : theme.palette.greyTheme.main;

  return (
    <Paper elevation={2}
      id="itemContainer"
      sx={styleBorderCardHorsDelai}
      onClick={() => props.onClick(props.instrument)}
    >
      <Box component="div" sx={{...styles(theme).header}}>
        {generateTypography('h6', props.instrument.designation, { lineHeight: 1.14 })}
        <IconButton
          id="favoriteButton"
          sx={{ "&.MuiIconButton-root": {...styles(theme).favorite} }}
          onClick={event => props.onFavoriteClick(event)}
          disableRipple={isIOS}
          size="large">
          {props.isFavorite ? <Notifications
            sx={{...styles(theme).defaultColorText}} /> : <NotificationsNone
            sx={{...styles(theme).defaultColorText}} />}
        </IconButton>
      </Box>
      <Box component="div" sx={{...styles(theme).center}}>
        <Box component="div" sx={{...styles(theme).left}}>
          {generateTypography('body1', props.instrument.marquage)}
          {generateTypography('body1', props.instrument.constructeur)}
          {generateTypography('body1', props.instrument.referenceConstructeur)}
          {generateTypography('body1', localisationContent)}
        </Box>
        <Box component="div" sx={{...styles(theme).instrumentImage}}>
          <InstrumentImage instrument={props.instrument} />
        </Box>
      </Box>
      <Box component="div" sx={{...styles(theme).bottom}}>
        {generateTypography('body1', etatMetrologiqueText, 
          { color: InstrumentHelper
            .generateEtatMetrologiqueColor(props.instrument.etatMetrologique, theme.palette) })}
        {generateTypography('caption', periodiciteProchaineOperation, { color: styleHorsDelai })}
        {generateTypography('body1', props.instrument.etat, { color: props.etatColor })}
      </Box>
    </Paper >
  );
};

InstrumentItemCardTemplate.propTypes = {
  instrument: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  horsDelais: PropTypes.bool.isRequired,
  isFavorite: PropTypes.bool.isRequired,
  etatColor: PropTypes.string.isRequired
};

export default React.memo(InstrumentItemCardTemplate);
