export const styles = theme => ({
  listItem: {
    margin: "8px",
    cursor: 'pointer'
  },
  header: {
    color: theme.palette.veryDarkBlueTheme.main,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.orangeTheme.main}`,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  dps: {
    fontSize: "20px",
    margin: '3px 0px',
  },
  body: {
    margin: theme.spacing(2)
  },
  grayText: {
    color: theme.palette.blackTheme.transparent
  },
  iconButton: {
    padding: "4px"
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: "8px"
  },
  separatorIcons: {
    marginRight: "8px"
  }
});
