const ValidatorRules = {
  EMAIL: 'EMAIL',
  NOT_EMPTY: 'NOT_EMPTY'
};

const regexp = {
  // eslint-disable-next-line
  EMAIL: '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'
};
const _ = require('lodash');

class Validator {

  static checkValidity(value, types) {
    if (!value || !types || types.length === 0) {
      return false;
    }

    const trimedValue = value.toString().trim();
    let isValid = true;

    for (const index in types) {
      if (types.hasOwnProperty(index)) {
        switch (types[index]) {
          case ValidatorRules.NOT_EMPTY:
            isValid = isValid && (trimedValue.length > 0);
            break;
          case ValidatorRules.EMAIL:
            isValid = isValid && new RegExp(regexp.EMAIL).test(trimedValue);
            break;
          default:
            isValid = false;
            break;
        }
      }
    }

    return isValid;
  }

  // Difference between two objets
  static difference(object, base) {
    function changes(object, base) {
      return _.transform(object, function (result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
        }
      });
    }

    return changes(object, base);
  }
}

export { Validator, ValidatorRules };
