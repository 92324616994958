import db from './Database';
import { JwtHelperService } from '../JwtHelperService';

class DBUtils {

  static async getPropertyValue(property) {
    let valueToReturn = 0;
    await db.usersProperties
      .where({
        userLogin: JwtHelperService.getUserLogin(),
        propertyName: property
      })
      .first(value => {
        valueToReturn = value === undefined ? undefined : value.value;
      });

    if (valueToReturn === undefined) {
      return 0;
    }
    return valueToReturn;
  }

  static async setPropertyValue(property, value) {
    const login = JwtHelperService.getUserLogin();
    try {
      await db.usersProperties.where({ userLogin: login, propertyName: property}).delete();
    } catch (e) {
      console.error(e);
    }
    await db.usersProperties.put(
      {
        userLogin: login,
        propertyName: property,
        value: value
      });
  }

  static getNull() {
    return 'null';
  }

  static getCurrentDateTime() {
    return new Date().toISOString();
  }

}

export default DBUtils;
