export let styles = () => ({
  /*** Image's intrument ***/
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 'auto',
    objectFit: 'contain',
    verticalAlign: 'middle'
  },
  modalContent: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

