import { jwtDecode } from "jwt-decode";
import crypto from 'crypto';
import { CryptoConstants } from "./Constants/CryptoConstants";

const STATUS_VALUE = 'true';

export class JwtHelperService {

  static STORAGE_ACCESS_TOKEN_KEY = 'access_token';
  static STORAGE_ACCESS_TOKEN_EXP_KEY = 'access_token_exp';
  static STORAGE_REFRESH_TOKEN_KEY = 'refresh_token';
  static STORAGE_REFRESH_TOKEN_EXP_KEY = 'refresh_token_exp';
  static STORAGE_LOGIN_TYPE_KEY = 'login_type';
  static STORAGE_OFFLINE_USER_LOGIN_KEY = 'user_login';
  static STORAGE_OFFLINE_USER_STATUS_KEY = 'offline_status';
  static STORAGE_URL_DTB = 'pathToDigitalTestBench';

  static EXTERNAL_SSO_THALES_LOGIN_TYPE = 'external_ssothales';
  static EXTERNAL_SSO_THALES_EXPIRATION = 'sso_expiration';

  static encrypt(text) {
    let iv = crypto.randomBytes(CryptoConstants.IV_LENGTH);
    let cipher = crypto.createCipheriv(CryptoConstants.ALGORITHM, Buffer.from(CryptoConstants.SECRET_PASSPHRASE), iv);
    let encrypted = cipher.update(text, 'utf8', 'hex');
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return iv.toString('hex') + ':' + encrypted.toString('hex');
  }
  
  static decrypt(text) {
    let textParts = text.split(':');
    let iv = Buffer.from(textParts.shift(), 'hex');
    let encryptedText = Buffer.from(textParts.join(':'), 'hex');
    let decipher = crypto.createDecipheriv(CryptoConstants.ALGORITHM, Buffer.from(CryptoConstants.SECRET_PASSPHRASE), iv);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();
  }

  static isUserOffline() {
    // comparison necessary because local storage only stores strings
    return (localStorage.getItem(JwtHelperService.STORAGE_OFFLINE_USER_STATUS_KEY) === STATUS_VALUE);
  }

  static setOfflineUserStatus(status) {
    localStorage.setItem(JwtHelperService.STORAGE_OFFLINE_USER_STATUS_KEY, status);
  }

  static isUserConnected() {
    const rawToken = JwtHelperService.getToken();
    return null !== rawToken && undefined !== rawToken;
  }

  static getUserLogin() {

    if (JwtHelperService.isLoggedAsGuest()) {
      return JwtHelperService.getSSOUserInformations().email;
    }

    if (JwtHelperService.isUserOffline()) {
      const encrypted = localStorage.getItem(JwtHelperService.STORAGE_OFFLINE_USER_LOGIN_KEY);
      return this.decrypt(encrypted);

    } else if (JwtHelperService.isUserConnected()) {
      const rawToken = localStorage.getItem(JwtHelperService.STORAGE_ACCESS_TOKEN_KEY);
      return jwtDecode(rawToken).login;
    }
    else {
      return null;
    }
  }

  static getToken() {
    return localStorage.getItem(JwtHelperService.STORAGE_ACCESS_TOKEN_KEY);
  }

  static getLoginType() {
    return localStorage.getItem(JwtHelperService.STORAGE_LOGIN_TYPE_KEY);
  }

  static isLoggedAsGuest() {
    return localStorage.getItem(JwtHelperService.STORAGE_LOGIN_TYPE_KEY)
      === JwtHelperService.EXTERNAL_SSO_THALES_LOGIN_TYPE;
  }

  static getSSOUserInformations() {
    if (JwtHelperService.isLoggedAsGuest()) {
      const rawToken = localStorage.getItem(JwtHelperService.STORAGE_ACCESS_TOKEN_KEY);
      const allInformations = jwtDecode(rawToken);

      const expirationDate = localStorage.getItem(JwtHelperService.EXTERNAL_SSO_THALES_EXPIRATION);

      return {
        firstName: allInformations.SMFIRSTNAME,
        lastName: allInformations.SMLASTNAME,
        email: allInformations.SMMAIL,
        company: allInformations.SMCOMPANY,
        country: allInformations.SMCOUNTRY,
        site: allInformations.SMSITE,
        expirationDate
      };
    }

    return null;
  }

  static clearSession() {
    localStorage.removeItem(JwtHelperService.STORAGE_ACCESS_TOKEN_KEY);
    localStorage.removeItem(JwtHelperService.STORAGE_LOGIN_TYPE_KEY);
    localStorage.removeItem(JwtHelperService.EXTERNAL_SSO_THALES_EXPIRATION);
    localStorage.removeItem(JwtHelperService.STORAGE_URL_DTB);
  }

  static setOfflineUserLogin(userLogin) {
    localStorage.setItem(JwtHelperService.STORAGE_OFFLINE_USER_LOGIN_KEY, this.encrypt(userLogin));
  }

  static removeOfflineUserData() {
    localStorage.removeItem(JwtHelperService.STORAGE_OFFLINE_USER_LOGIN_KEY);
    localStorage.removeItem(JwtHelperService.STORAGE_OFFLINE_USER_STATUS_KEY);
  }
}
