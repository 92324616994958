// REACT libraries
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI libraries
import { Typography, Grid, TextField } from '@mui/material';
import { styles } from './DynamicField.style';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import InputAdornmentWithCamera from '../../Utils/InputAdornmentCamera/InputAdornmentWithCamera';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

dayjs.extend(utc);

// Field including a name and a value for InstrumentCard
const DynamicField = props => {
  const inputProps = { readOnly: true };

  // When the user click on edit Button, readOnly = false to let the user edit the textFields
  inputProps.readOnly = !props.isEditTextChecked;

  return (
    <Grid container
      spacing={0}
      sx={{ "&.MuiGrid-container": {...styles(theme).container}}}>
      <Grid item xs={5} sx={{...merge({}, styles(theme).verticalAlign, styles(theme).endTextAlign)}}>
        <Typography variant="body2" sx={{...merge({}, styles(theme).textLabel,
          (isNotComputer && styles(theme).tabletFontSize))}}>
          {props.name}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{...styles(theme).verticalAlign}}>
        {props.isDate ?
          <MobileDatePicker
            minDate={props.minDate}
            slotProps={{ textField: { variant: 'standard' } }}
            onAccept={date => props.getValue(date)}
            value={props.value}
            defaultValue={props.value}
            InputLabelProps={{ shrink: true }}
            sx={{...merge({}, styles(theme).textField, (isNotComputer && styles(theme).tabletFontSize))}}
            format={strings.general.dateFormat}
          />
          :
          <TextField
            id={`${props.name}TextField`}
            variant="standard"
            error={props.error}
            name={props.name}
            sx={{ "&.MuiTextField-root": {...styles(theme).textField}}}
            InputProps={{
              inputProps,
              sx: {
                input: {...merge({}, styles(theme).input, (isNotComputer && styles(theme).tabletFontSize))}
              },
              endAdornment: props.isEditTextChecked ? (
                <InputAdornmentWithCamera name={props.name} camera={props.camera}/>
              ) : null
            }}
            value={props.value ? props.value : ''}
            onChange={e => props.getValue(e)}
          />
        }
      </Grid>
    </Grid>
  );
};


DynamicField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  isEditTextChecked: PropTypes.bool.isRequired,
  getValue: PropTypes.func.isRequired,
  isDate: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  error: PropTypes.bool,
  camera: PropTypes.func
};

export default DynamicField;
