import sharedStyles from '../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  dpsCard: {
    [theme.breakpoints.only('xs')]: {
      height: 'auto'
    },
    height: '26vh'
  },
  dpsCardContent: {
    height: 'calc(100% - 48px)',
  },
});
