import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { styles } from './ListPage.style';
import {
  Typography,
  List,
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from '@mui/material';
import { theme } from '../../../Utils/Theme';
import Scannable from '../../Utils/Scannable/Scannable';
import { logger } from '../../../Utils/Logger';
import { unlock } from '../../../store/actions/guideActions';
import { ArrowBack } from '@mui/icons-material';
import { LocalizedString, LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import ItemTemplate from '../../Inventory/InventoryListing/ItemTemplate/ItemTemplate';
import { isIOS } from 'react-device-detect';
import { trackScrolling } from '../../../Utils/ScrollUtils';
import Scrollbar from 'react-scrollbars-custom';
import InstrumentCard from '../../InstrumentCard/InstrumentCard';
import InstrumentDotDialog from '../../Dialogs/InstrumentDotDialog/InstrumentDotDialog';
import InstrumentHelper from '../../../Helpers/InstrumentHelper';
import InstrumentService from '../../../Providers/WebService/InstrumentService';
import merge from 'lodash/merge';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    guideStatus: state.guideReducer.status,
    guideMode: state.guideReducer.mode
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    unlock: data => dispatch(unlock(data))
  };
};

export class ListPage extends Component {
  state = {
    selectedInstruments: [],
    selectedInstrument: {},
    showInstrumentCard: false,
    isNotGuestMode: false,
    showInstrumentDot: false,
  };
  componentDidMount() {
    let fullName = `${this.props.userInformations.firstName} ${this.props.userInformations.lastName}`;
    InstrumentService.getBorrowedInstrument(fullName).then((res) => {
      this.setState({
        selectedInstruments: this.state.selectedInstruments.concat(res)
      });
    }).catch(logger);

  }

  onItemClick = instrument => {
    this.setState({ selectedInstrument: instrument, showInstrumentCard: true });
  };

  closeInstrumentCard = () => {
    this.setState({ showInstrumentCard: false });
  };

  onCloseDotDialog = () => {
    this.setState({ showInstrumentDot: false });
  };

  onDotClick = instrument => {
    this.setState({ selectedInstrument: instrument, showInstrumentDot: true });
  };

  render(){

    return <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            id="backButton"
            sx={{...styles(theme).menuButton}}
            color="inherit"
            aria-label="Menu"
            onClick={() => this.props.onBackPressed()}
            size="large">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit" sx={{...styles(theme).grow}}>
            {strings.traceability.listBorrow}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="div" sx={{...styles(theme).root}} >
        <InstrumentCard
          id="instrumentCard"
          open={this.state.showInstrumentCard}
          instrument={this.state.selectedInstrument}
          onClose={this.closeInstrumentCard}/>
        { this.state.selectedInstruments.length > 0 ? (
          <Scrollbar
            style={{...merge({}, styles(theme).scrollArea, (isIOS && styles(theme).classic_sized)) }}
            onScroll={scrollValues => trackScrolling(scrollValues, this.onBottomReach)}
            noScrollX>
            <div style={{ flex: 1 }}>
              <List>
                {this.state.selectedInstruments.map(item =>
                  (<ItemTemplate
                    id="item"
                    key={item.marquage}
                    item={item}
                    openInstrumentCard={() => this.onItemClick(item)}
                    isNotGuestMode={this.state.isNotGuestMode}
                    onDotClick={this.onDotClick}
                    dotColor={InstrumentHelper.getColorStatusOperation(item, theme)}
                    stateOperation={InstrumentHelper.getStatusOperation(item)}
                  />)
                )}
              </List>
            </div>
          </Scrollbar>
        ) :
          <div>{ LocalizedString.tracking.borrowNone }</div>
        }
        {this.state.selectedInstrument && <InstrumentDotDialog
          open={this.state.showInstrumentDot}
          onClose={this.onCloseDotDialog}
          instrument={this.state.selectedInstrument}
          isNotGuestMode={this.state.isNotGuestMode}
        />}
      </Box>
    </>;
  }
}

ListPage.propTypes = {
  userInformations: PropTypes.object,
  onBackPressed: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Scannable(ListPage, true));