export const LogTypes = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
};

export let logger = (message, type = LogTypes.INFO) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    switch (type) {
      case LogTypes.WARNING:
        console.warn(message);
        break;
      case LogTypes.ERROR:
        console.error(message);
        break;
      case LogTypes.INFO:
      default:
        console.log(message);
        break;
    }
  }
};
