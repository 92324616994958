import { LocalizedString as strings } from '../Utils/Constants/LocalizedString';
import dayjs from 'dayjs';
import { ScanAnalysis } from '../Utils/Constants/ScanAnalysis';
import { BarcodeType } from '../Utils/Constants/BarcodeType';
import { ScanPages } from '../Utils/Constants/ScanPage';

class HistoryHelper {

  /**
   * Headers préparation for CSV file
   */
  static headers = [
    { id: 'marquage', displayName: 'Marquage' },
    { id: 'date', displayName: 'Date' },
    { id: 'page', displayName: 'Page' },
    { id: 'type', displayName: 'Type' },
    { id: 'marque', displayName: 'Marque' },
    { id: 'modele', displayName: 'Modele' },
    { id: 'analysis', displayName: 'Analyse' },
  ];

  static getCSVFileName = () => {
    return strings.historyPage.downloadTitle +
      dayjs().format(strings.historyPage.fileNameExtension) +
      strings.historyPage.csvExtension;
  };


  /**
   * Right display in Page column when CSV download is done
   * @param {string} scanPage: Page where the scan has been taken
   */
  static generateScanPage = scanPage => {
    let page;
    switch (scanPage) {
      case ScanPages.INTERVENTION_REQUEST:
        page = strings.historyPage.pages.interventionRequestPage;
        break;
      case ScanPages.INVENTORY:
        page = strings.historyPage.pages.inventoryPage;
        break;
      case ScanPages.SCAN:
        page = strings.historyPage.pages.scanPage;
        break;
      case ScanPages.NONE:
      default:
        page = strings.historyPage.pages.nonePage;
        break;
    }
    return page;
  };

  /**
   * Right display in Analysis column when CSV download is done
   * @param {string} scanAnalysis: Instrument's state ("Archived", "Validated", etc.) when the scan has been taken
   */
  static generateStatusLabel = scanAnalysis => {
    let labelStatus = '';
    switch (scanAnalysis) {
      case ScanAnalysis.OK:
        labelStatus = strings.historyScan.validated;
        break;
      case ScanAnalysis.OUT_OF_BOUND:
        labelStatus = strings.historyScan.outOfBound;
        break;
      case ScanAnalysis.ARCHIVED:
        labelStatus = strings.historyScan.archived;
        break;
      case ScanAnalysis.LOST:
        labelStatus = strings.historyScan.lost;
        break;
      case ScanAnalysis.NOT_FOUND:
        labelStatus = strings.historyScan.notFound;
        break;
      case ScanAnalysis.NONE:
      default:
        labelStatus = strings.historyScan.noAnalyzed;
        break;
    }
    return labelStatus;
  }

  /**
   * Right display in "Type de scan" column when CSV download is done
   * @param {string} barcodeType: Barcode's type of the scan 
   */
  static generateBarcodeTypeLabel = barcodeType => {
    let barcodeTypeLabel;
    switch (barcodeType) {
      case BarcodeType.IDCRIS.toUpperCase():
        barcodeTypeLabel = strings.historyPage.barcodeType.idCris;
        break;
      case BarcodeType.MARQUAGE.toUpperCase():
        barcodeTypeLabel = strings.historyPage.barcodeType.marquage;
        break;
      case BarcodeType.OLDMARQUAGE.toUpperCase():
        barcodeTypeLabel = strings.historyPage.barcodeType.oldMarquage;
        break;
      case BarcodeType.RFID.toUpperCase():
        barcodeTypeLabel = strings.historyPage.barcodeType.rfid;
        break;
      case BarcodeType.NONE.toUpperCase():
      default:
        barcodeTypeLabel = strings.historyPage.barcodeType.none;
        break;
    }
    return barcodeTypeLabel;
  };
}

export default HistoryHelper;
