import React from 'react';
import { styles } from '../MainMenu.style';
import PropTypes from 'prop-types';
import {
  Tooltip,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../Utils/Theme';

const { mainMenuItems } = globalConstants;

const MainMenuItem = props => {

  const blueTheme = theme.palette.MainMenu;
  const { text, selected, onClick, children, customStyle } = props;

  let bgColor = blueTheme.otherMenuOptions;
  switch (customStyle) {
    case mainMenuItems.INSTRUMENTS:
      bgColor = (blueTheme.instrumentsMenuOption);
      break;
    case mainMenuItems.INTERVENTIONS:
      bgColor = (blueTheme.interventionMenuOption);
      break;
    case mainMenuItems.INVENTORY:
      bgColor = (blueTheme.inventoryMenuOption);
      break;
    default:
      bgColor = (blueTheme.otherMenuOptions);
  }
  return (
    <Tooltip key={text} title={text} enterDelay={globalConstants.tooltipDelay}>
      <ListItemButton
        id={props.id}
        selected={selected}
        onClick={() => onClick()}
        style={{ '--background-color': bgColor }}
        sx={{ "&.Mui-selected": {...styles(theme).defaultBtn}, "&.MuiListItemButton-root": {...styles(theme).menuItem} }}
        disabled={props.disabled}

      >
        <ListItemIcon sx={{ "&.MuiListItemIcon-root": {...styles(theme).whiteColor},
          ...((!props.menuIsOpen && isNotComputer) && styles(theme).itemIconClosed)
        }}>{children}</ListItemIcon>
        <ListItemText sx={{ "& .MuiListItemText-primary": {...styles(theme).whiteColor},
          ...(isNotComputer && styles(theme).tabletListItemFontSize) }}
        primary={text}/>
      </ListItemButton>
    </Tooltip>
  );
};

MainMenuItem.propTypes = {
  customStyle: PropTypes.string,
  selected: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  menuIsOpen: PropTypes.bool.isRequired
};

export default MainMenuItem;
