import React, { Component } from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { styles } from './InterventionHome.style';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { Build, Event, PictureAsPdf } from '@mui/icons-material';
import { updateToolbarInfo } from '../../store/actions/exportActions';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import Scrollbar from 'react-scrollbars-custom';
import InterventionItem from './InterventionItem/InterventionItem';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import DateFilterInfo from '../Utils/DateFilterInfo/DateFilterInfo';
import InterventionItemInfo from './InterventionItemInfo/InterventionItemInfo';
import InstrumentCard from '../InstrumentCard/InstrumentCard';
import InterventionService from '../../Providers/WebService/InterventionService';
import ValidationDialog from '../Dialogs/ValidationDialog/ValidationDialog';
import { trackScrolling } from '../../Utils/ScrollUtils';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import { InterventionRequestConstants } from '../../Utils/Constants/InterventionRequestConstants';
import LoadingDialog from '../Dialogs/LoadingDialog/LoadingDialog';
import { SnackbarMessages } from '../../Utils/Constants/SnackbarMessages';
import { logger } from '../../Utils/Logger';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../Utils/Theme';
import merge from 'lodash/merge';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter);

export const mapStateToProps = state => {
  return {
    selectedDate: state.toolbarReducer.selectedDate
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};

const INTERVENTION_STEP = 40;

class InterventionHome extends Component {

  state = {
    isLoading: true,
    activeDateFilter: null,
    interventionCountToShow: INTERVENTION_STEP,
    interventions: [],
    selectedItem: null,
    interventionToValidate: null,
    validationText: '',
    isDeleteValidation: false,
    pdfDocument: null,
    waitingForResult: false
  };

  componentDidMount() {
    // Display toolbar's content (icon, title, iconButtons)
    this.props.updateToolbarInfo({
      title: strings.mainMenu.interventions,
      icon: (<Build />),
      actions: [ToolbarActionsConstants.CALENDAR_FILTER]
    });

    this.refreshInterventionsRequests();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.setState({ activeDateFilter: this.props.selectedDate });
    }
  }

  refreshInterventionsRequests = () => {
    InterventionService.getAllInterventionsRequests().then(res => {
      // Sort by creation date
      res.data.sort((a, b) => dayjs(a.creationDate).isAfter(b.creationDate) ? -1 : 1);
      this.setState({ isLoading: false, interventions: res.data });
    }).catch(logger);
  };

  onUpdateIntervention = (data, isDelete) => {
    this.setState({
      interventionToValidate: data,
      isDeleteValidation: isDelete,
      validationText: isDelete ?
        strings.interventionHome.deleteValidationMsg : strings.interventionHome.updateValidationMsg
    });
  }

  deleteInterventionRequest = data => {
    this.setState({ waitingForResult: true });

    InterventionService.deleteInterventionRequest(data.interventionRequestID).then(() => {
      //remove intervention from list
      this.setState({
        interventionToValidate: null,
        interventions: this.state.interventions
          .filter(item => item.interventionRequestID !== data.interventionRequestID),
        waitingForResult: false
      });

      enqueueSnackbar(SnackbarMessages.deleteInterventionSuccess.msg,
        SnackbarMessages.deleteInterventionSuccess.type);

    }).catch(() => {
      enqueueSnackbar(SnackbarMessages.interventionUpdateError.msg,
        SnackbarMessages.interventionUpdateError.type);
      this.setState({
        interventionToValidate: null,
        waitingForResult: false
      });

    });
  };

  updateInterventionRequestDate = data => {
    this.setState({ waitingForResult: true });

    InterventionService.updateInterventionRequestDate(data.interventionRequestID).then(res => {
      this.setState({
        interventionToValidate: null,
        pdfDocument: res.data.pdf,
        waitingForResult: false,
        isLoading: true
      });

      enqueueSnackbar(SnackbarMessages.updateInterventionSuccess.msg,
        SnackbarMessages.updateInterventionSuccess.type);
      this.refreshInterventionsRequests();
    }).catch(() => {
      enqueueSnackbar(SnackbarMessages.interventionUpdateError.msg,
        SnackbarMessages.interventionUpdateError.type);

      this.setState({
        interventionToValidate: null,
        waitingForResult: false
      });
    });
  };

  getItems = () => {
    let count = 0;
    return this.state.interventions.map(item => {
      if (count < this.state.interventionCountToShow &&
        (!this.state.activeDateFilter || dayjs(item.creationDate).isSameOrAfter(this.props.selectedDate, 'day'))) {

        count++;

        return <InterventionItem
          key={item.interventionRequestID}
          interventionInfo={item}
          onClick={selectedItem => this.setState({ selectedItem })}
          onSend={data => this.onUpdateIntervention(data, false)}
          onRemove={data => this.onUpdateIntervention(data, true)} />;
      }
      return null;
    });
  };

  onBottomReach = () => {
    this.setState({ interventionCountToShow: this.state.interventionCountToShow + INTERVENTION_STEP });
  };

  render() {
    return (
      <Box component="div" sx={{...styles(theme).pageContent}}>
        <Box component="div" sx={{...styles(theme).header}}>
          <Box component="div" sx={{...styles(theme).buttons}}>
            <Button
              id="interventionRequestButton"
              sx={{ "&.MuiButton-root": {...styles(theme).button}}}
              color="primary"
              variant="contained"
              onClick={() => this.props.handleIntervention(PAGES_IDS.INTERVENTION_REQUEST)}>
              <Event sx={{ ...styles(theme).buttonIcon }} />
              {strings.interventionHome.newDemand}
            </Button>
            <Button
              id="interventionCreationButton"
              sx={{ "&.MuiButton-root": {...styles(theme).button}}}
              color="primary"
              variant="contained"
              onClick={() => this.props.handleIntervention(PAGES_IDS.INTERVENTION_CREATION)}>
              <Build sx={{ ...styles(theme).buttonIcon }} />
              {strings.interventionHome.newIntervention}
            </Button>
          </Box>
          {this.state.activeDateFilter &&
            <DateFilterInfo
              id="dateFilter"
              resetDatePicker={() => this.setState({ activeDateFilter: null })}
              date={this.state.activeDateFilter}
            />}
          {this.state.selectedItem &&
            <InterventionItemInfo
              id="item"
              interventionInfo={this.state.selectedItem}
              onClose={() => this.setState({ selectedItem: null })}
              onInstrumentClick={instrument => this.setState({ selectedInstrument: instrument })}
            />}
          {this.state.selectedInstrument &&
            <InstrumentCard
              id="instrumentCard"
              open
              instrument={this.state.selectedInstrument}
              onClose={() => this.setState({ selectedInstrument: null })}
            />
          }
        </Box>
        <Box component="div" sx={{...styles(theme).listContainer}}>
          {
            this.state.isLoading ?
              <Box component="div" sx={{...styles(theme).loadingMessage}}>
                <Typography variant='subtitle1'>{strings.interventionHome.loadingMessage}</Typography>
                <LinearProgress />
              </Box>
              :
              <Scrollbar
                style={{...merge({}, styles(theme).scrollArea, styles(theme).scan_sized)}}
                onScroll={scrollValues => trackScrolling(scrollValues, this.onBottomReach)}
                noScrollX>
                <Box component="div" sx={{...styles(theme).interventionList}}>
                  {this.getItems()}
                </Box>
              </Scrollbar>
          }
        </Box>
        <ValidationDialog
          id="validationDialog"
          open={this.state.interventionToValidate !== null}
          title={strings.dialog.title.areYouSure}
          text={this.state.validationText}
          refuseLabel={strings.dialog.answer.no}
          onRefuse={() => this.setState({ interventionToValidate: null })}
          acceptLabel={strings.dialog.answer.yes}
          onAccept={() => {
            if (this.state.isDeleteValidation) {
              this.deleteInterventionRequest(this.state.interventionToValidate);
            }
            else {
              this.updateInterventionRequestDate(this.state.interventionToValidate);
            }
          }} />

        <SimpleDialog
          id="simpleDialog"
          open={this.state.pdfDocument !== null}
          title={strings.dialog.title.success}
          acceptLabel={strings.general.ok}
          onAccept={() => this.setState({ pdfDocument: null })}
        >
          <Typography >{strings.interventionHome.demandSuccess}</Typography>
          <Button sx={{ "&.MuiButton-root": {...styles(theme).menuAction}}}
            target="_blank"
            color="primary"
            variant="contained"
            href={InterventionRequestConstants.PDF_DATA + this.state.pdfDocument}
            download={InterventionRequestConstants.PDF_TITLE}
          >
            <PictureAsPdf sx={{...styles(theme).pdfIcon}} />
            {strings.interventionHome.downloadPDF}
          </Button>
        </SimpleDialog>

        <LoadingDialog
          open={this.state.waitingForResult}
          title={strings.general.loading}
          label={strings.interventionHome.updateValidationLoading}
        />
      </Box>
    );
  }
}

InterventionHome.propTypes = {
  updateToolbarInfo: PropTypes.func,
  handleIntervention: PropTypes.func.isRequired,
  selectedDate: PropTypes.object
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(InterventionHome);
