import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  headerText: {
    color: theme.palette.secondary.main,
    fontSize: "16px"
  },
  headerButtons: {
    alignSelf: 'center',
    marginTop: "0px",
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center'
  },
  button: {
    color: theme.palette.secondary.main,
    width: "48px",
    cursor: 'pointer'
  },
  cardContent: {
    paddingBottom: '8px !important'
  },
  checkboxContainer: {
    display: 'flex',
    marginRight: "16px"
  },
  checkboxLabel: {
    color: 'white',
    paddingBottom: "2px",
    fontWeight: "600px"
  },
  checkbox: {
    color: 'white'
  }
});

