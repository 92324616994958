import sharedStyles from '../../../Utils/SharedStyles';
export let styles = theme => ({
  ...sharedStyles(theme),
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  icon: {
    flex: '0 0 auto',
    borderRadius: '50%',
    [theme.breakpoints.only('xs')]: {
      width: '0.75rem',
      height: '0.75rem',
    }
  },
  label: {
    flex: '1 1 auto',
  },
  secondary: {
    flex: '0 0 auto'
  },
  responsiveMobile: {
    [theme.breakpoints.only('xs')]: {
      fontSize: "12px"
    }
  },
});
