import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';

import { styles } from './PdfDownloadButton.style';
import { downloadMultiplePdfs } from '../../../Utils/FileUtils';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import { theme } from '../../../Utils/Theme';

function PdfDownloadButton(props) {
  return (
    <Tooltip title={strings.tooltips.savePDF} enterDelay={globalConstants.tooltipDelay}>
      <IconButton
        sx={{ "&.MuiIconButton-root": {...styles(theme).menuAction},
          "&.Mui-disabled": {...styles(theme).intervention_headerIcons_disabled},
          ...(props.onMobile
          ? styles(theme).intervention_headerIcons_mobile
          : styles(theme).intervention_headerIcons)
        }}
        id="pdfDownload"
        onClick={() => downloadMultiplePdfs(props.pdfDocuments)}
        disabled={props.pdfDocuments?.length === 0}
        size="large">
        <PictureAsPdf />
      </IconButton>
    </Tooltip>
  );
}

PdfDownloadButton.propTypes = {
  pdfDocuments: PropTypes.array.isRequired,
  onMobile: PropTypes.bool.isRequired,
};

PdfDownloadButton.defaultProps = {
  pdfDocuments: []
};

export default PdfDownloadButton;
