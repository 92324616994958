import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { Box, Dialog, DialogContent, Drawer, Typography } from '@mui/material';
import { styles } from './MainPage.style';
import {
  saveUserInformations,
  toggleSavedFilter,
  updateCurrentPage,
  updateFavorite,
  updateNetworkStatus,
  updateOnMobile
} from '../../store/actions/exportActions';
import PageNotFound from '../Misc/PageNotFound/PageNotFound';
import MainMenu from '../MainMenu/MainMenu';
import InstrumentsPage from '../InstrumentsPage/InstrumentsPage';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import InterventionRequest from '../InterventionRequest/InterventionRequest';
import { ConnectivityChecker, ConnectivityCheckerStatus } from '../../Utils/ConnectivityChecker';
import ProfilePage from '../ProfilePage/ProfilePage';
import MainToolbar from '../MainToolbar/MainToolbar';
import { PagesPaths } from '../../Utils/Constants/PagesPaths';
import HomePage from '../HomePage/HomePage';
import FeedbackPage from '../FeedbackPage/FeedbackPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InstrumentsProvider from '../../Providers/Database/InstrumentsProvider';
import FilterCreationDialog from '../FilterDialog/FilterCreationDialog';
import ScanPage from '../Scan/ScanPage';
import ScanHistoryPage from '../ScanHistory/ScanHistoryPage';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import LoadFilterDialog from '../FilterDialog/LoadFilterDialog/LoadFilterDialog';
import InventoryPage from '../Inventory/InventoryPage';
import PreferencesPage from '../Preferences/PreferencesPage';
import NotificationsPage from '../NotificationsPage/NotificationsPage';
import NotificationsProvider from '../../Providers/Database/NotificationsProvider';
import UserHelper from '../../Helpers/UserHelper';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import HelpPage from '../Help/HelpPage';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { WifiOff } from '@mui/icons-material';
import UserProvider from '../../Providers/Database/UserProvider';
import { JwtHelperService } from '../../Utils/JwtHelperService';
import InterventionHome from '../InterventionHome/InterventionHome';
import InterventionPage from '../Intervention/InterventionPage';
import LoadingSpinner from '../Utils/LoadingSpinner/LoadingSpinner';
import DBUtils from '../../Utils/Database/DBUtils';
import lastUpdatejson from '../../Assets/lastUpdate.json';
import UpdatePage from '../UpdatePage/UpdatePage';
import UpdateDialog from '../Dialogs/UpdateDialog/UpdateDialog';
import VersionService from '../../Providers/WebService/VersionService';
import ExternalFilterHelper from '../../Helpers/ExternalFilterHelper';
import dayjs from 'dayjs';
import HistoryInterventionHome from '../HistoryInterventionHome/HistoryInterventionHome';
import BannerService from '../../Providers/WebService/BannerService';
import { theme } from '../../Utils/Theme';

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

// maps Reducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    showFilterPage: state.toolbarReducer.showFilterPage,
    showSavedFilters: state.toolbarReducer.showSavedFilters,
    loggedUser: state.generalReducer.loggedUser,
    shouldRedirectToLogin: state.generalReducer.shouldRedirectToLogin,
    currentPage: state.generalReducer.currentPage,
    networkStatus: state.generalReducer.networkStatus
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateNetworkStatus: value => dispatch(updateNetworkStatus(value)),
    updateOnMobile: onMobile => dispatch(updateOnMobile(onMobile)),
    toggleSavedFilter: showSavedFilters => dispatch(toggleSavedFilter(showSavedFilters)),
    updateFavorite: favorites => dispatch(updateFavorite(favorites)),
    updateCurrentPage: pageID => dispatch(updateCurrentPage(pageID)),
    saveUserInformations: value => dispatch(saveUserInformations(value))
  };
};

const BEFORE_LAST_SYNCHRO_PROP = 'beforeLastSynchro';
const SCREEN_WIDTH = 600;
const DELAY_AUTO_CLOSE = 7500;
const TOASTS_POSITION = 'bottom-center';
const noToolbarPages = [PAGES_IDS.INTERVENTION_REQUEST, PAGES_IDS.INVENTORY, PAGES_IDS.INTERVENTION_CREATION];

class MainPage extends Component {

  state = {
    menuIsOpen: false,
    scanPageIsOpen: false,
    notificationCount: 0,
    showNoConnectivityDialog: false,
    loading: false,
    displayUpdateBadge: false,
    displayChangeLog: false,
    redirectionFromSynchro: false,
    version: '',
    bannerData: null,
  };

  isOnMobile = () => window.innerWidth <= SCREEN_WIDTH;

  async componentDidMount() {
    // Start loading to prevent user from doing anything during critical operations.
    this.setState({ loading: true });
    // check if on mobile at first start
    const isOnMobile = this.isOnMobile();
    if (isOnMobile) {
      // if external filter is set, redirect automatically on instruments page
      if (ExternalFilterHelper.isExternalFilterSet()) {
        this.props.updateCurrentPage(PAGES_IDS.INSTRUMENTS);
      } else {
        this.props.updateCurrentPage(PAGES_IDS.HOME);
      }
    }
    this.props.updateOnMobile(isOnMobile);

    const networkStatus = await ConnectivityChecker.getStatus();
    // Check network status at first start
    this.props.updateNetworkStatus(networkStatus);

    if (!this.props.loggedUser.userLogin) {
      if (networkStatus === ConnectivityCheckerStatus.ONLINE) {
        // Get user information from server
        UserHelper.getUserInfosFromServerAndUpdateDatabase(null, value => {
          this.props.saveUserInformations(value);
        });
      } else {
        // Get user information from local database
        const userInfos = await UserProvider.getUserInfosFromDatabase();
        this.props.saveUserInformations(userInfos);
      }
    }

    // bannerData is an array with one element
    let bannerData = await BannerService.getBanner();

    // Can stop loading here. Each operation done after this statement must be uncritical
    this.setState({ bannerData, loading: false });

    // Store user favorites instruments in redux
    let favorites = await InstrumentsProvider.getUserFavorites();
    this.props.updateFavorite(favorites);

    await this.checkUpdate();
    this.setupListeners();
    this.updateNotificationsCount();
  }

  setupListeners() {
    disableBrowserBackButton();

    // Offline network events
    window.addEventListener('offline', () => {
      this.props.updateNetworkStatus(ConnectivityCheckerStatus.OFFLINE);
    }, false);

    // Online network events
    window.addEventListener('online', async () => {
      const networkStatus = await ConnectivityChecker.getStatus();
      this.props.updateNetworkStatus(networkStatus);
      this.handleNetworkBack();
    }, false);
  }

  handleNetworkBack = () => {
    // If first logged in in offline mode (no token)
    if (!JwtHelperService.getToken()) {
      const login = JwtHelperService.getUserLogin();
      JwtHelperService.removeOfflineUserData();
      this.props.navigate(PagesPaths.root, {state: { login }});
    } else {
      // Else if already connected in online mode (token is present)
      this.props.navigate(PagesPaths.sync);
    }
  }

  checkUpdate = async () => {
    const lastSynchro = await DBUtils.getPropertyValue(BEFORE_LAST_SYNCHRO_PROP);
    const lastUpdate = lastUpdatejson.value;

    if (lastSynchro <= lastUpdate && lastSynchro > 0) {
      VersionService.getCurrentVersion().then(res =>
        this.setState({ version: res, displayUpdateBadge: true, displayChangeLog: true })
      );
    }
  }

  // Toogle the menu
  toggleMenu = () => {
    this.setState(state => ({ menuIsOpen: !state.menuIsOpen }));
  };

  redirectToHomeFromSynchronization = () => {
    this.setState(state => ({ redirectionFromSynchro: !state.redirectionFromSynchro }));
  };

  // Change the content with the page of the given ID
  changePageWithId = pageID => {
    // Block the intervention button if there is no internet
    if (pageID === PAGES_IDS.INTERVENTION && this.props.networkStatus !== ConnectivityCheckerStatus.ONLINE) {
      this.setState({ showNoConnectivityDialog: true });
    }
    else {
      this.props.updateCurrentPage(pageID);
      this.setState({ menuIsOpen: false });
    }
  };

  updateNotificationsCount = () => {
    NotificationsProvider.getNotificationsCount().then(count => this.setState({ notificationCount: count }));
  }

  buildContent = () => {
    let content;

    switch (this.props.currentPage) {
      case PAGES_IDS.USER_PROFILE:
        content = <ProfilePage activeUser={this.props.loggedUser} />;
        break;

      // First active page after the login because of its PagesIDs number
      case PAGES_IDS.INSTRUMENTS:
        content = <InstrumentsPage handleScanButtonClick={this.handleScanButtonClick} />;
        break;

      case PAGES_IDS.INTERVENTION:
        content = <InterventionHome handleIntervention={this.changePageWithId} />;
        break;

      case PAGES_IDS.HISTORIC_DEMAND_INTERVENTION:
        content = <HistoryInterventionHome handleIntervention={this.changePageWithId} />;
        break;

      case PAGES_IDS.INTERVENTION_CREATION:
        content = <InterventionPage activeUser={this.props.loggedUser} />;
        break;

      case PAGES_IDS.INTERVENTION_REQUEST:
        content = <InterventionRequest />;
        break;
      case PAGES_IDS.INVENTORY:
        content = <InventoryPage navigate={this.props.navigate} />;
        break;

      case PAGES_IDS.FOLLOWED_INSTRUMENTS:
        content = <NotificationsPage onNotificationUpdate={this.updateNotificationsCount} />;
        break;

      case PAGES_IDS.HISTORY:
        content = <ScanHistoryPage />;
        break;

      case PAGES_IDS.PREFERENCES:
        content = <PreferencesPage />;
        break;

      case PAGES_IDS.FEEDBACKS:
        content = <FeedbackPage />;
        break;

      case PAGES_IDS.HOME:
        content = <HomePage
          activeUser={this.props.loggedUser}
          handleMenuItemClick={this.changePageWithId}
          handleScanButtonClick={this.handleScanButtonClick}
          notificationCount={this.state.notificationCount} />;
        break;

      case PAGES_IDS.HELP:
        content = <HelpPage redirectUpdatePage={() => this.changePageWithId(PAGES_IDS.UPDATE)} />;
        break;

      case PAGES_IDS.UPDATE:
        content = <UpdatePage />;
        break;
      default:
        content = <PageNotFound />;
        break;
    }

    return content;
  };

  hasToolbar = () => !noToolbarPages.includes(this.props.currentPage);

  handleScanButtonClick = () => {
    this.setState(previousState => ({
      scanPageIsOpen: !previousState.scanPageIsOpen
    }));
  };

  render() {
    const { shouldRedirectToLogin } = this.props;
    const withToolBar = this.hasToolbar() || this.props.onMobile;
    const content = this.buildContent();
    // les conditions d'affiche de la bannière sont:
    // Si la date de fin existe, elle doit etre égale au jour du test ou après
    const bannerEndDateCondition = this.state.bannerData &&
      ((this.state.bannerData.endDate &&
      dayjs(this.state.bannerData.endDate).isSameOrAfter(dayjs()))
      || !this.state.bannerData.endDate);
    // Le showBanner doit être vrai
    // La date de début doit être égale au jour du test ou avant
    const showBanner = this.state.bannerData &&
      this.state.bannerData.showBanner &&
      dayjs(this.state.bannerData.startDate).isSameOrBefore(dayjs()) &&
      bannerEndDateCondition;
    const classNameToolBar = showBanner ?
      styles(theme).mainPage_toolbar_with_banner : styles(theme).mainPage_toolbar;

    if (shouldRedirectToLogin || this.state.redirectionFromSynchro) {
      return <Navigate id="redirect" exact to={PagesPaths.root} />;
    }
    return (
      <Box component="div" sx={{...styles(theme).mainPage_root}}>
        {
          withToolBar &&
          <MainToolbar
            menuIsOpen={this.state.menuIsOpen}
            toggleMenu={this.toggleMenu}
            bannerData={this.state.bannerData}
            showBanner={showBanner}
          />
        }
        {this.state.loading && <LoadingSpinner />}
        <MainMenu
          id="menu"
          redirectFromSynchro={this.redirectToHomeFromSynchronization}
          activeUser={this.props.loggedUser}
          selectedMenu={this.props.currentPage}
          handleMenuItemClick={this.changePageWithId}
          menuIsOpen={this.state.menuIsOpen}
          toggleMenu={this.toggleMenu}
          onMobile={this.props.onMobile}
          notificationCount={this.state.notificationCount}
          currentPage={this.props.currentPage}
          displayUpdateBadge={this.state.displayUpdateBadge}
          handleFooterIcon={() => {
            this.changePageWithId(PAGES_IDS.UPDATE);
            this.setState({ displayUpdateBadge: false });
          }}
        />
        <Box component="main"
          id="main"
          sx={{...styles(theme).mainPage_content}}
          style={{ justifyContent: this.props.currentPage === PAGES_IDS.INTERVENTION_REQUEST ? 'center' : 'initial' }}
        >
          {
            withToolBar && <Box component="div" sx={{...classNameToolBar}} />
          }

          {this.props.showSavedFilters &&
            <LoadFilterDialog
              open={this.props.showSavedFilters}
              onMobile={this.props.onMobile} />
          }

          {this.props.currentPage !== PAGES_IDS.PREFERENCES &&
            <FilterCreationDialog open={this.props.showFilterPage} />
          }
          {content}
        </Box>

        <Dialog fullScreen open={this.props.onMobile && this.state.scanPageIsOpen}>
          <DialogContent sx={{...styles(theme).mobileScanPage}}>
            <ScanPage onMobile onClose={this.handleScanButtonClick} />
          </DialogContent>
        </Dialog>

        {!this.props.onMobile && (
          <Drawer
            anchor="right"
            open={this.state.scanPageIsOpen}
            onClose={this.handleScanButtonClick}
            sx={{ "& .MuiDrawer-paper": {...styles(theme).scanDrawer}}}
          >
            <Box component="div" sx={{...styles(theme).scanDrawerContent}}>
              <ScanPage onClose={this.handleScanButtonClick} />
            </Box>
          </Drawer>)}

        <ToastContainer
          position={TOASTS_POSITION}
          autoClose={DELAY_AUTO_CLOSE}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          pauseOnHover
          draggable
          toastClassName="ReactToastify-toastContainer"
          progressClassName="ReactToastify-toastProgress"
          closeButton={false}
          theme='colored'
        />

        <SimpleDialog
          id="noConnectivityDialog"
          icon={<WifiOff />}
          open={this.state.showNoConnectivityDialog}
          title={strings.dialog.title.warningConnectivity}
          onAccept={() => this.setState({ showNoConnectivityDialog: false })}
          acceptLabel={strings.general.ok}
          addContentPadding
        >
          <Typography variant="body">
            {strings.noConnection.noConnectionLabel(strings.mainMenu.interventions)}
          </Typography>
        </SimpleDialog>

        <UpdateDialog
          title={strings.dialog.title.releaseNoteTitle + this.state.version}
          open={this.state.displayChangeLog}
          closeDialog={() => this.setState({ displayChangeLog: false })}
          seeMore={() => {
            this.changePageWithId(PAGES_IDS.UPDATE);
            this.setState({ displayChangeLog: false, displayUpdateBadge: false });
          }}
        />
      </Box>
    );
  }

}

MainPage.propTypes = {
  showFilterPage: PropTypes.bool,
  showSavedFilters: PropTypes.bool,
  updateNetworkStatus: PropTypes.func,
  updateOnMobile: PropTypes.func.isRequired,
  onMobile: PropTypes.bool.isRequired,
  loggedUser: PropTypes.object.isRequired,
  shouldRedirectToLogin: PropTypes.bool.isRequired,
  updateFavorite: PropTypes.func.isRequired,
  navigate: PropTypes.func,
  // redux
  updateCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  saveUserInformations: PropTypes.func.isRequired,
  networkStatus: PropTypes.number.isRequired
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(MainPage);
