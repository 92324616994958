import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';
import { DeviceInformations } from '../../Utils/Constants/DeviceInformations';

class FeedbackService {

  static sendFeedback(feedback) {
    const feedbackData = {
      message: feedback
    };
    return HttpClient.post(routes.sendFeedback, feedbackData).then(response => Promise.resolve(response));
  }

  static addDeviceInformationsToComment(comment) {
    const deviceInformations = Object.values(DeviceInformations);

    let commentWithDeviceInfo = comment;
    for (const key in deviceInformations) {
      if (deviceInformations.hasOwnProperty(key)) {
        commentWithDeviceInfo += '\n';
        commentWithDeviceInfo += localStorage.getItem(deviceInformations[key]);
      }
    }

    return commentWithDeviceInfo;
  }
}

export default FeedbackService;
