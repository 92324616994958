import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Portal extends Component {

  constructor(props) {
    super(props);

    if (!props.node) {
      this.node = document.createElement('div');
      document.body.appendChild(this.node);
    } else {
      this.node = props.node;
    }
  }

  componentWillUnmount() {
    document.body.removeChild(this.node);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.node
    );
  }
}

Portal.propTypes = {
  node: PropTypes.object,
  children: PropTypes.object
};

export default Portal;
