import db from '../../Utils/Database/Database';
import { UserConstants } from '../../Utils/Constants/UserConstants';
import { JwtHelperService } from '../../Utils/JwtHelperService';

class InventoryProvider {

  static async saveInstrumentsInventory(instrumentInventories) {
    const userLogin = JwtHelperService.getUserLogin();
    const inventories = instrumentInventories.map(instr => InventoryProvider.buildInstrumentInventoryItem(instr, userLogin));
    await db.inventories.bulkPut(inventories);
  }

  static async insertInstrumentInventory(instrumentInventory) {
    await db.inventories.put(instrumentInventory);
  }

  static async updateInstrumentInventory(instrumentInventory) {
    await db.inventories.where({ userLogin: JwtHelperService.getUserLogin(), marquage: instrumentInventory.marquage })
      .modify({ verifiedStatus: instrumentInventory.verifiedStatus });
  }

  static async getAllInventories() {
    const userLogin = JwtHelperService.getUserLogin();
    return db.inventories.where(UserConstants.userLoginDB).equals(userLogin);
  }

  static async removeInstrumentInventory(marquage) {
    await db.inventories.where({ userLogin: JwtHelperService.getUserLogin(), marquage }).delete();
  }

  static truncateInventory() {
    const userLogin = JwtHelperService.getUserLogin();
    if (userLogin) {
      return db.inventories.where(UserConstants.userLoginDB).equals(userLogin).delete();
    }

    return null;
  }

  static buildInstrumentInventoryItem(instrument, userLogin, verifiedStatus = null) {
    return {
      userLogin,
      marquage: instrument.marquage,
      verifiedStatus
    };
  }

}

export default InventoryProvider;
