import dayjs from 'dayjs';

import InterventionProvider from '../Providers/Database/InterventionProvider';
import { ConnectivityChecker } from '../Utils/ConnectivityChecker';
import Intervention from '../Utils/Database/Models/InterventionModel';
import store from '../store/store';
import { LocalizedString as strings } from '../Utils/Constants/LocalizedString';
import InterventionService from '../Providers/WebService/InterventionService';
import InventoryProvider from '../Providers/Database/InventoryProvider';
import { InterventionRequestConstants } from '../Utils/Constants/InterventionRequestConstants';
import InstrumentsProvider from '../Providers/Database/InstrumentsProvider';

class InterventionHelper {

  static async createManyInterventions(interventions, location, user, technician,
    typeID = InterventionRequestConstants.INTERVENTION_TYPES.INVENTORY) {

    const hasInternetAccess = await ConnectivityChecker.isConnected();
    let result = {};

    if (hasInternetAccess) {
      const tmpResult = await InterventionService.pushInterventions(interventions, location, user, typeID,
        technician);
      result = { inventoryResult: tmpResult, online: true };
    } else {
      await InterventionProvider.createManyInterventions(interventions, location, user);
      result = { inventoryResult: null, online: false };
    }

    // Empty local inventory
    if (typeID === InterventionRequestConstants.INTERVENTION_TYPES.INVENTORY) {
      await InventoryProvider.truncateInventory();
    }

    return result;
  }

  static formatInterventionsFromInstruments(instruments, saveUser, saveLocation, user, location, customComment) {

    return instruments.map(instr => {
      const intervention = new Intervention();
      let oldUser = null;
      let oldLoc = null;
      const oldInst = InstrumentsProvider.getInstrumentByMarquage(instr.marquage);
      if (oldInst) {
        oldUser = oldInst.utilisateur;
        oldLoc = oldInst.localisation;
      }
      const comment = customComment || InterventionHelper
        .generateComment(saveLocation, saveUser, location, user, ' - ', oldUser, oldLoc);
      intervention.setValues(true, instr.marquage, instr.etat, comment);
      return intervention;
    });
  }

  static formatMultipleInterventionsFromInstruments(instruments, saveUser, saveLocation, user, location, customComment,
    recipientsToSetAsUser) {
    let multipleInterventions = [];
    recipientsToSetAsUser.forEach(recipient => {
      const filteredInstruments = instruments.filter(inst => inst.destinataire === recipient);
      multipleInterventions.push({
        interventions: this.formatInterventionsFromInstruments(filteredInstruments, saveUser, saveLocation,
          recipient, location, customComment),
        recipient: recipient
      });
    });
    return multipleInterventions;
  }

  static generateComment(saveLocation, saveUser, location, user, separator = '\n', oldUser, oldLocation) {
    const now = dayjs();
    const currentDate = dayjs(now).format(strings.general.dateFormat);
    const currentTime = dayjs(now).format(strings.general.timeFormat);

    const state = store.getState();
    const technicianFullName =
      `${state.generalReducer.loggedUser.lastName} ${state.generalReducer.loggedUser.firstName}`;

    let comment = strings.inventory.comment(currentDate, currentTime, technicianFullName);

    if (saveLocation) {
      comment += separator + strings.inventory.locationLabel + location;
      if (oldLocation) comment += ' (valeur précédence: ' + oldLocation + ')';
    }
    if (saveUser) {
      comment += separator + strings.inventory.userLabel + user;
      if (oldUser) comment += ' (valeur précédence: ' + oldUser + ')';
    }

    comment += ' ' + strings.inventory.admLabel;

    return comment;
  }

  static buildGenericInterventionData(instruments, interventionData) {
    return instruments.map(instrument => {
      return {
        instrument: instrument.marquage,
        intervention: {
          typeID: interventionData.type.id,
          locationID: interventionData.location,
          providerType: interventionData.providerType,
          providerID: interventionData.provider.id,
          technician: interventionData.technician,
          date: interventionData.date,
          comment: interventionData.comment,
          status: instrument.intervention.status.id,
          state: instrument.intervention.state.id,
          requestIdentifier: instrument.intervention.requestIdentifier,
          documents: instrument.intervention.documents.map(document => {
            return document.binary
              ? {
                title: document.name,
                type: 1,
                document_FileName: document.name,
                document_Binary: document.binary
              }
              : {
                title: document.name,
                type: 1,
                link_Url: document.linkUrl,
              };
          })
        }
      };
    });
  }

}

export default InterventionHelper;
