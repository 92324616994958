import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { styles } from './InterventionFormStep.style';
import InterventionCard from './InterventionCard/InterventionCard';
import ConclusionCard from './ConclusionCard/ConclusionCard';
import DocumentsRepository from './DocumentsRepository/DocumentsRepository';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { updateToolbarInfo } from '../../../../store/actions/exportActions';
import DPSCard from './DPSCard/DPSCard';
import { theme } from '../../../../Utils/Theme';
import { Box } from '@mui/material';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo))
  };
};

class InterventionFormStep extends Component {

  componentDidMount() {
    this.props.updateToolbarInfo({
      title: this.props.onMobile ? '' : strings.intervention.formStep,
      icon: (null),
      actions: []
    });
  }

  generateConclusionAndDocumentCard = (props) => {
    return (
      <>
        <ConclusionCard
          updateInterventionStateField={props.updateInterventionStateField}
          intervention={props.intervention}
          submitted={this.props.submitted}
        />
        <DocumentsRepository
          update={props.updateInterventionStateField}
          documents={props.intervention.documents}
        />
      </>
    );
  };

  render() {
    if (this.props.onMobile) {
      return (
        <Scrollbars
          style={{...styles(theme).stepper_pages_scrollArea}}
          // hide horizontal scrollbar
          renderTrackHorizontal={props =>
            <div
              {...props}
              style={{ display: 'none' }}
              className="track-horizontal"
            />}
        >
          <Box component="div" sx={{...styles(theme).mobileContainer}}>
            <InterventionCard
              updateInterventionStateField={this.props.updateInterventionStateField}
              intervention={this.props.intervention}
              submitted={this.props.submitted}
            />
            {this.generateConclusionAndDocumentCard(this.props)}
            <DPSCard
              updateInstrumentsWithInterventions={this.props.updateInstrumentsWithInterventions}
              instruments={this.props.instruments}
              intervention={this.props.intervention}
              submitted={this.props.submitted}
            />
          </Box>
        </Scrollbars>

      );
    } else {
      return (
        <Box component="div" sx={{...styles(theme).container}}>
          <Box component="div" sx={{...styles(theme).row}}>
            <Box component="div" sx={{...styles(theme).column}}>
              <InterventionCard
                updateInterventionStateField={this.props.updateInterventionStateField}
                intervention={this.props.intervention}
                submitted={this.props.submitted}/>
            </Box>

            <Box component="div" sx={{...styles(theme).column}}>
              {this.generateConclusionAndDocumentCard(this.props)}
            </Box>
          </Box>

          <Box component="div" sx={{...styles(theme).column}}>
            <DPSCard
              updateInstrumentsWithInterventions={this.props.updateInstrumentsWithInterventions}
              instruments={this.props.instruments}
              intervention={this.props.intervention}
              submitted={this.props.submitted}
            />
          </Box>
        </Box>
      );
    }
  }
}

InterventionFormStep.propTypes = {
  updateInterventionStateField: PropTypes.func,
  updateInstrumentsWithInterventions: PropTypes.func,
  intervention: PropTypes.object.isRequired,
  onMobile: PropTypes.bool.isRequired,
  updateToolbarInfo: PropTypes.func.isRequired,
  submitted: PropTypes.bool,
  instruments: PropTypes.array.isRequired
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(InterventionFormStep);
