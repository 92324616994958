import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  menuFooterGif: {
    height: "40px",
    width: "40px",
    alignItems: 'center'
  },
  menuFooter: {
    alignItems: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0',
    cursor: 'pointer'
  },
  menuFooterText: {
    whiteSpace: 'normal',
    fontSize: "9px",
    textAlign: 'center'
  }
});
