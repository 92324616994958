const getS = value => value > 1 ? 's' : '';
const lstr = value => value.toLocaleString();

export const LocalizedString = {
  general: {
    exportButton: 'Export Excel',
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    dateFormatWithTime: 'DD/MM/YYYY HH:mm:ss',
    dateFormatWithoutSeconds: 'DD/MM/YYYY HH:mm',
    usDateFormat: 'YYYY-MM-DD',
    usDateFormatWithTime: 'YYYY-MM-DD HH:mm',
    concatDateFormat: 'DDMMYYYY',
    nbOfInstruments: count => `${lstr(count)} instrument${getS(count)}`,
    ok: 'OK',
    emptyField: 'Vide',
    versionStart: 'ADM ',
    versionEnd: ' By Thales',
    csvSeparator: ';',
    noInformation: '-',
    loading: 'Chargement...',
    instrumentLoading: 'Chargement des instruments...',
    ellipsis: '...',
    seeMore: 'Voir plus',
    timeZoneParis: 'Europe/Paris'
  },
  tooltips: {
    favoriteFilter: 'Filtrer sur les instruments suivis',
    createFilter: 'Appliquer/créer un filtre',
    savePDF: 'Télécharger au format PDF',
    resetInterventionRequest: 'Nouvelle demande d\'intervention',
    editInstrument: 'Editer l\'instrument',
    dateFilter: 'Filtrer sur la date',
    downloadCSV: 'Télécharger au format CSV',
    profilePage: 'Page de profil',
    clearAll: 'Tout effacer',
    redirectInterventionsHome: 'Retour à la page d\'accueil des interventions',
    interventionInfo: 'Afficher les informations de la création d\'intervention',
    helpInfo: 'Afficher la pop-up d\'aide.',
    digitalTestBenchLinkInfo:'Lien vers le digital test bench',
    digitalTestBenchLinkNotAvailable:'Digital not available en hors connexion',
    updateInstrumentInformation: 'Mettre à jour les informations de l\'instrument',
    updateInstrumentInformationNotAvailable: 'Fonctionnalité indisponible en mode hors ligne'
  },
  error: {
    emptyTextField: 'Le champ ne peut pas être vide.'
  },
  tracking: {
    trackingTitle: 'ADM',
    trackingSubtitle: 'Gestion des Appareils de Mesure',
    borrowTitle: 'EMPRUNTER DES ÉQUIPEMENTS',
    listBorrowTitle: 'AFFICHER LES EMPRUNTS',
    returnTitle: 'RENDRE DES ÉQUIPEMENTS',
    borrowingStateOperationOne: 'Aucune opération de prévue',
    borrowingStateOperationTwo: 'Une opération est prévue' ,
    borrowingStateOperationThree: 'Opération de prévue dans plus 1 mois',
    borrowingStateOperationFour: 'Opération de prévue ce mois çi',
    borrowNone: 'Aucun instrument emprunté',
  },
  auth: {
    authTitle: 'Connexion ADM',
    userLabel: 'Utilisateur',
    passwordLabel: 'Mot de passe',
    forgotPwdLabel: 'Mot de passe oublié ?',
    connectLabel: 'Se Connecter',
    modalForgotPassword: {
      title: 'Mot de passe oublié',
      text: 'Veuillez contacter MEMS2 à l\'adresse suivante : ',
      mail: 'mems2@thalesgroup.com'
    },
    guestModeLabel: 'MODE INVITÉ',
    orLabel: 'OU',
    noAppVersion: 'Impossible de récupérer la version de l\'application',
    offlineLabel: 'Vous êtes actuellement hors-ligne',
    reconnect: 'Merci de vous reconnecter',
    ssoHelp: 'Connectez-vous avez vos identifiants Thales pour accéder à des fonctionnalités réduites d\'ADM.'
  },
  snackbars: {
    authErrorMsg: 'Erreur d\'authentification: login et/ou mot de passe erroné(s).',
    authErrorServerMsg: 'Erreur d\'authentification: veuillez réessayer.',
    authOfflineErrorMsg: 'Veuillez vous connecter une première fois en ligne.',
    networkErrorMsg: 'Le serveur est inaccessible.',
    authSuccessMsg: 'Authentification effectuée avec succès.',
    capacityErrorMsg: 'Erreur, vous ne pouvez pas ajouter plus de 300 instruments.',
    expiredTokenErrorMsg: 'Le token a expiré, veuillez vous reconnecter.',
    noInstrumentsErrorMsg: 'Veuillez sélectionner au moins un instrument pour continuer.',
    allFieldsErrorMsg: 'Veuillez remplir tous les champs requis pour continuer.',
    interventionRequestErrorMsg: 'Une erreur est survenue lors de la demande d\'intervention.',
    expiredSessionErrorMsg: 'Votre session a expiré',
    feedbackError: 'Vous devez être en ligne pour envoyer votre commentaire',
    feedbackSuccess: 'Votre commentaire a bien été envoyé !',
    scanCreationError: 'Erreur lors de la création du scan.',
    instrumentModificationSuccess: 'La modification a bien été effectuée.',
    instrumentSynchronizationSuccess: 'La synchronisation a bien été effectuée.',
    instrumentSynchronizationInfo: 'L\' instrument est déjà à jour.',
    instrumentModificationWarning: 'Modification enregistrée localement. Veuillez synchroniser vos données.',
    instrumentModificationError: 'Une erreur est survenue lors de la modification des instruments.',
    inventoryFiltering: 'Application du filtre en cours...',
    inventoryNeedFiltering: 'Merci de filtrer votre liste d\'inventaire.',
    preferenceDuplicateName: 'Ce nom de préférence est déjà utilisé.',
    preferenceEmptyField: 'Veuillez remplir les champs requis.',
    eligibleInstrumentsError: 'Impossible de récupérer les instruments éligibles.',
    inventoryNeedToVerify: 'Veuillez vérifier au moins un instrument',
    emptyLocation: 'Veuillez indiquer le lieu de retour ou d\'emprunt des appareils',
    fileTooBig: fileName => `Le fichier "${fileName}" est trop lourd`,
    alreadyExistFile: fileName => `Le fichier "${fileName}" existe déjà dans la liste`,
    tooManyUploadedFiles: 'Il y a trop de fichiers chargés',
    deleteInterventionSuccess: 'La demande d\'intervention a bien été supprimée',
    updateInterventionSuccess: 'La demande d\'intervention a bien été mise à jour',
    interventionUpdateError: 'Une erreur est survenue lors de la mise à jour de la demande d\'intervention',
    errorAddInstrumentsToIntervention:
      count => `${lstr(count)} instrument${getS(count)} n'${count > 1 ? 'ont' : 'a'} pas
       été ajouté${getS(count)} pour droits insuffisants.`,
    guideSkipped: 'Mode tutoriel désactivé. Les actions que vous effectuerez seront répercutées sur le serveur.',
    noLongerAccessToInstrument: `Vous n'avez plus accès à cet instrument suite à sa mise à jour avec le serveur. Il est 
     donc retiré de votre liste d'instruments.`,
    downloadPrep: 'Préparation du téléchargement',
    downloadStart: 'Lancement du téléchargement',
    downloadFailed: 'Erreur lors du téléchargement'
  },
  mainMenu: {
    instruments: 'Mes Instruments',
    interventions: 'Interventions',
    historyIntervention: 'Historique d\' interventions',
    inventaire: 'Inventaire',
    historicIntervention: 'Historiqu demande d\'intervention',
    instrumentNotif: 'Instruments suivis',
    history: 'Historique',
    preferences: 'Préférences',
    feedback: 'Commentaires',
    home: 'Accueil',
    online: 'En ligne',
    offline: 'Hors ligne',
    serverOffline: 'Serveur inaccessible',
    logout: 'Déconnexion',
    profile: 'Profil',
    scan: 'Scan',
    help: 'Aide',
    update: 'Mise à jour'
  },
  interventionHome: {
    demandSuccess: 'Votre demande a été validée.',
    loadingMessage: 'Chargement des demandes d\'interventions...',
    newDemand: 'NOUVELLE DEMANDE D\'INTERVENTION',
    newIntervention: 'NOUVELLE INTERVENTION',
    expedition: 'Expedition: ',
    instrumentCount: count => `${lstr(count)} instrument${getS(count)}`,
    deleteValidationMsg: 'Êtes vous sûr de vouloir supprimer cette demande d\'intervention?',
    updateValidationMsg: 'Êtes vous sûr de vouloir expédier cette demande d\'intervention?',
    downloadPDF: 'Télécharger le PDF',
    noInstruments: 'Aucun instruments...',
    updateValidationLoading: 'Validation de votre demande en cours...'
  },
  historyInterventionHome: {
    loadingMessage: 'Chargement des demandes d\'interventions...',
    historyNewDemand: 'HISTORY DEMANDE D\'INTERVENTION',
    historyNewIntervention: 'HISTORY NOUVELLE INTERVENTION',
    expedition: 'Expedition: ',
    instrumentCount: count => `${lstr(count)} instrument${getS(count)}`,
    deleteValidationMsg: 'Êtes vous sûr de vouloir supprimer cette demande d\'intervention?',
    updateValidationMsg: 'Êtes vous sûr de vouloir expédier cette demande d\'intervention?',
    downloadPDF: 'Télécharger le PDF',
    noInstruments: 'Aucun instruments...',
    updateValidationLoading: 'Validation de votre demande en cours...'
  },
  interventionRequest: {
    serviceTypeTitle: 'TYPE DE PRESTATION',
    serviceTypeLabel: 'Nature de l\'intervention',
    serviceTitle: 'PRESTATION',
    serviceLocationLabel: 'Localisation de la prestation',
    providerTypeLabel: 'Type de prestataire',
    providerLabel: 'Prestataire',
    recipientLabel: 'Destinataire',
    addressLabel: 'Adresse',
    phoneLabel: 'Téléphone',
    faxLabel: 'Fax',
    emailLabel: 'Email',
    providerSelectTitle: 'Sélectionner un prestataire',
    validateLabel: 'Valider',
    shippingTitle: 'EXPÉDITION',
    shippingDateLabel: 'Date d\'expédition',
    returnDateLabel: 'Date de retour',
    observationLabel: 'Observation',
    shipperTitle: 'EXPÉDITEUR',
    shipperNameLabel: 'Nom de l\'expéditeur',
    carrierTitle: 'TRANSPORTEUR',
    carrierModeLabel: 'Mode de transport',
    carrierLabel: 'Transporteur',
    cartStep: 'PANIER',
    serviceShippingStep: 'PRESTATION ET EXPEDITION',
    confirmationStep: 'CONFIRMATION',
    responseStep: 'ENVOI DE LA DEMANDE',
    responseTitle: 'Demande envoyée',
    title: 'DEMANDE D\'INTERVENTION',
    instrumentsTitle: 'MES INSTRUMENTS',
    cartTitle: 'PANIER',
    serviceShippingTitle: 'Prestation/Expédition',
    confirmationTitle: 'Confirmation',
    nbOfInstruments: count => `${lstr(count)} instrument${getS(count)}`,
    nbOfValidInstruments: count => `${lstr(count)} instrument${getS(count)} validé${getS(count)}`,
    nbOfUnvalidInstruments: count => `${lstr(count)} instrument${getS(count)} invalidé${getS(count)}`,
    cancel: 'Annuler',
    submit: 'Valider',
    loadPreference: 'Charger une préférence',
    commentDialog: {
      title: 'Ajouter un commentaire',
      label: 'Commentaire...',
    },
    confirmDialog: {
      title: 'Confirmation',
      label: 'Envoyer la demande d\'intervention ?',
    },
    summaryCard: {
      typeOfIntervention: 'Nature de l\'intervention',
      shippingDate: 'Expédition',
      returnDate: 'Réception',
      observation: 'Observation',
      provider: 'Prestataire',
      carrier: 'Transporteur',
      instruments: 'Instruments'
    },
    loadingEligible: 'Récupération des instruments éligibles... cela peut prendre quelques instants.',
    interventionMultipleEnabling: 'Activation de la demande d\'intervention multi-prestataires',
    interventionMultipleEnablingDesc: 'ADM a détecté les instruments de votre panier correspondant et les' +
      ' a regroupé par prestataire associé. Vous devrez remplir la page de Prestation pour chacun des groupes.' +
      ' A tout moment, vous pourrez voir les instruments du groupe en cours en cliquant' +
      ' sur le bouton d\'aide en haut à droite.',
    interventionMultipleEnablingInterventionSelected: 'Intervention sélectionnée: ',
    instrumentNotGrouped: 'Prestataire régis par un contrat particulier',
    instrumentGroupInactive: (index) => `Groupe ${index}: Prestataire inconnu ou non actif. 
      Veuillez en sélectionner un nouveau ou contacter un administrateur.`,
    instrumentsGrouped: (index, provider) => `Groupe ${index}: ${provider}`,
    currentStepInstruments: 'L\'étape actuelle concerne les instruments suivants:',
    dynamicStepTitle: (text, current, total) => `${text} (${current}/${total})`,
    step: 'ÉTAPE',
    warningReset: 'Attention, revenir au panier va réinitialiser les informations saisies' +
      ' lors de la demande d\'intervention multiple.'
  },
  instrumentCard: {
    identification: 'IDENTIFICATION',
    smallid: 'Identification',
    idContent: {
      marquage: 'Marquage',
      designation: 'Désignation',
      marque: 'Marque',
      modele: 'Modèle',
      numeroDeSerie: 'Numéro de série',
      options: 'Options',
      ancienMarquage: 'Ancien Marquage',
      idCris: 'ID CRIS',
      rfid: 'RFID',
      observation: 'Observation',
      banc: 'Banc',
    },
    metrologie: 'METROLOGIE',
    smallMetrologie: 'Métrologie',
    metrologieContent: {
      status: 'Statut',
      etatMetrologie: 'État métrologique',
      operationPrecedente: 'Opération précédente',
      effectueLe: 'Effectué le',
      planning: 'Planning',
      periodicite: 'Périodicité',
      prochaineEcheance: 'Prochaine échéance',
      operation: 'Opération',
    },
    administration: 'ADMINISTRATION',
    smmallAdmin: 'Administration',
    adminContent: {
      site: 'Site',
      localisation: 'Localisation',
      identifiant3: 'Géolocalisation',
      utilisateur: 'Utilisateur',
      proprietaire: 'Propriétaire',
      parc: 'Parc',
      affectation: 'Affectation',
      section: 'Section',
      sousSection: 'Sous-section',
      destinataire: 'Destinataire',
      finPrevisionnelleLocation: 'Fin prévisionnelle location',
      prixLocation: 'Coût de location journalier',
      dateCreation: 'Création de la fiche',
      dateDerniereMiseAjour: 'Dernière mise à jour de l\'instrument'
    },
    demandeIntervention: 'DEMANDE D\'INTERVENTION',
    chargementImage: 'Chargement en cours...',
    prochaineIntervention: 'Prochaine intervention : ',
  },
  alt: {
    douchette: 'douchette',
    instrument: 'instrument',
    newRequest: 'new request',
    admLogo: 'ADM LOGO'
  },
  profile: {
    logout: 'Déconnexion',
    professionalPhone: 'Téléphone Professionnel',
    mobilePhone: 'Téléphone Mobile',
    organzationalUnitUser: 'Unité Organisationnelle',
    geographicalUnitUser: 'Unité Géographique',
    financialUnitUser: 'Unité Financière',
    complementaryRoles: 'Rôles Complémentaires',
  },
  dialog: {
    answer: {
      close: 'Fermer',
      quit: 'Quitter',
      accept: 'Accepter',
      yes: 'Oui',
      no: 'Non',
      cancel: 'Annuler',
      submit: 'Valider',
      save: 'Enregistrer'
    },
    title: {
      logoutTitle: 'Déconnexion',
      syncError: 'Problème de synchronisation',
      syncInstrument: 'Synchronisation des instruments',
      syncInProgress: 'Synchronisation en cours',
      chooseInstruments: 'Choisir un instrument',
      archived: 'Archivé',
      out_of_bound: 'Hors-périmètre',
      not_analyzed: 'Non analysé',
      warningProblem: 'Attention, un problème est survenu lors de l\'analyse du scan',
      areYouSure: 'Demande de validation',
      instrumentState: 'Etat de l\'instrument',
      warningConnectivity: 'Problème dû à la connexion',
      success: 'Succès',
      releaseNoteTitle: 'Dernière mise à jour: Version '
    },
    content: {
      logoutQuestion: 'Voulez-vous vous déconnecter ?',
      syncError: 'Une erreur lors de la synchronisation est survenue.',
      validateSuppression: 'Souhaitez-vous valider la suppression ?'
    }
  },
  sync: {
    init: 'Initialisation...',
    ended: 'Terminé',
    steps: {
      instrumentsDownload: 'Mise à jour des instruments dans la base locale',
      instrumentsUpload: 'Envoi de vos modifications instruments au serveur',
      connections: 'Envoi des connexions utilisateur au serveur',
      scans: 'Envoi des scans réalisés au serveur',
      interventions: 'Envoi des inventaires au serveur',
      interventionsRequests: 'Envoi des demandes d\'intervention au serveur',
      errorLogs: 'Envoi des rapports d\'erreurs',
      notifications: 'Synchronisation des notifications d\'instruments',
      favorites: 'Synchronisation des favoris',
      messagesScans: 'Synchronisation des alertes de scans',
    },
    retry: 'Réessayer',
    reset: 'Réinitialiser la base de données',
    redirection: page => `Retour vers la page de ${page}`,
    SynchroAllInstrument: 'Êtes-vous sûr de vouloir synchroniser tous les instruments?',
  },
  feedback: {
    yourFeedback: 'Votre Commentaire :',
    placeholder: 'Donnez-nous votre avis sur l\'application',
    send: 'Envoyer'
  },
  scanMessage: {
    startScan: 'Touchez pour commencer',
    messageArchived: instrument => `L'instrument ${instrument} est archivé`,
    messageOutOfBounds: instrument => `L'instrument ${instrument} est hors-périmètre`,
    messageLost: instrument => `L'instrument ${instrument} est perdu`,
    messageNotFound: instrument => `L'instrument ${instrument} est introuvable`,
    messageFallback: 'Aucune information n\'est disponible pour ce code-barre.',
    messageNotAnalyzed: 'L\'instrument est hors-périmètre, inconnu ou archivé. Informations supplémentaires' +
      ' non disponibles en mode hors-ligne.',
    messageError: 'Une erreur est survenue lors de l\'analyse du scan'
  },
  toastMessage: {
    notFound: instrument => `L'instrument ${instrument} n'existe pas!`,
    duplicate: 'Instrument déjà ajouté !',
    ineligibleInstrument: 'Instrument inéligible à une intervention',
    wrongBarcode: 'Le code-barre saisi est incorrect.',
    notUpdatedInstrument: 'L\'instrument sélectionné n\'a pas pu être mis a jour.',
    inventoryFailed: 'L\'inventaire n\'a pas pu être crée, veuillez réessayer. Si le problème persiste, veuillez contacter '
  },
  updateInstruments: {
    title : 'Modification d\'instruments',
    tooltip: 'Les instruments sélectionnés seront modifiés avec ces valeurs.',
    noEmptyFieldsMsg: 'Veuillez remplir au moins un champs.',
    utilisateur: 'Utilisateur',
    localisation: 'Localisation'
  },
  filter: {
    openSavedFilter: 'Charger un filtre enregistré',
    clearFilter: 'Tout effacer',
    marquage: 'MARQUAGE',
    sousFamille: 'SOUS FAMILLE',
    marque: 'MARQUE',
    modele: 'MODÈLE',
    lieuUtilisation: 'LIEU D\'UTILISATION',
    status: 'STATUT',
    soumisAPlanning: 'SOUMIS A PLANNING',
    proprietaire: 'PROPRIÉTAIRE',
    dateProchaineOpe: 'DATE PROCHAINE OPÉRATION',
    localisationComplementaire: 'LOCALISATION COMPLÉMENTAIRE',
    affectationFinanciere: 'AFFECTATION FINANCIÈRE',
    section: 'SECTION',
    sousSection: 'SOUS SECTION',
    destinataire: 'DESTINATAIRE',
    utilisateurFinal: 'UTILISATEUR FINAL',
    typeProchaineIntervention: 'TYPE PROCHAINE INTERVENTION',
    numeroDeSerie: 'NUMÉRO DE SÉRIE',
    etatMetrologique: 'ÉTAT MÉTROLOGIQUE',
    designation: 'DÉSIGNATION',
    organisationProprietaire: 'ORGANISATION PROPRIÉTAIRE',
    addFilter: 'AJOUTER UN FILTRE',
    createFilter: 'NOUVEAU FILTRE',
    dateFrom: 'Du',
    dateFromText: 'Date début',
    dateTo: 'au',
    dateToText: 'Date fin',
    dateFromTo: (from, to) => `Du: ${from} au: ${to}`,
    emptyListMessage: 'L\'élément que vous cherchez n\'existe pas...',
    saveFilter: 'Enregistrer le filtre',
    filterName: 'Nom du filtre',
    overrideFilter: 'Êtes-vous sûr de vouloir écraser ce filtre?',
    deleteFilter: 'Êtes-vous sûr de vouloir supprimer ce filtre?',
    noFilterSaved: 'Vous n\'avez pas enregistré de filtres.',
    loadFilter: 'Charger un filtre',
    filterNameError: 'Le nom du filtre ne peut pas contenir de caractères spéciaux ou être vide.',
    startTyping: 'Commencez à écrire pour filtrer la liste...',
    strictlyEqual: 'STRICT. ÉGAL',
    filter_id: 'filter_id',
    preference_filter_id: 'preference_filter_id',
    applyFilter: 'Appliquer',
    removeFilter: 'Effacer',
    banc: 'BANC'
  },
  scanPage: {
    scanTitle: 'SCAN',
    manualScanPlaceholder: 'Entrez un marquage...',
    quickscanPlaceholder: 'Commencez à scanner...',
    scannedInstrumentsLabel: ' INSTRUMENT(S) SCANNÉ(S)'
  },
  inventory: {
    buttunLastInventory: 'REPRENDRE L\'INVENTAIRE',
    inventoryTitle: 'INVENTAIRE',
    inventoryList: 'LISTE D\'INVENTAIRE',
    inventory: 'INVENTAIRE',
    validate: 'VALIDER',
    inventoryListingTitle: 'MA LISTE D\'INVENTAIRE',
    preparingInventoryTitle: 'Préparation de l\'inventaire',
    preparingInventoryText: 'Inventaire en cours de préparation...',
    validateInventoryTitle: 'Validation de l\'inventaire',
    saveLocation: 'Enregistrer la localisation',
    saveUser: 'Enregistrer l\'utilisateur',
    comment: (date, time, technician) => `Inventaire réalisé le ${date} à ${time} par ${technician}`,
    locationLabel: 'Localisation: ',
    userLabel: 'Utilisateur: ',
    admLabel: '(ADM)',
    toVerifyTitle: 'A VÉRIFIER',
    toVerifyLabel: count => `${lstr(count)} instrument${getS(count)} restant${getS(count)}`,
    verifiedTitle: 'VÉRIFIÉS',
    validationTitle: 'Validation de l\'inventaire',
    validationLabel: 'Création de l\'inventaire...',
    notVerified: number => `Non vérifié${getS(number)}`,
    verified: number => `Vérifié${getS(number)}`,
    unexpected: number => `Non attendu${getS(number)}`,
    outOfBound: 'Hors-périmètre',
    creationFail: 'Erreur lors de la création de l\'inventaire.',
    creationSuccess: 'Inventaire envoyé.',
    creationWarning: 'Inventaire sauvegardé localement. Il sera envoyé au serveur' +
      ' lors de votre prochaine synchronisation.',
    itemValidated: 'Validé',
    itemUnexpected: 'Imprévu',
    itemOoB: 'Hors-périmètre',
    advesoError: 'Erreur Adveso',
    warningLoseInventory: 'Voulez-vous commencer un nouvel inventaire ? L\'inventaire en cours sera perdu.',
    warningTitle: 'Attention',
    inventoryReview: 'Bilan de l\'inventaire réalisé le ',
    author: activeUser => ` par ${activeUser.firstName} ${activeUser.lastName} (${activeUser.userLogin})`,
    csvInventoryTitle: date => `inventaire_${date}`,
    instrument: number => `instrument${getS(number)}`
  },
  notifications: {
    newNotifications: 'Nouvelles notifications',
    notificationsHome: number => `Vous avez ${lstr(number)} notification${getS(number)}`,
    notifications: number => `${lstr(number)} notification${getS(number)}`,
    modification: ' ⇒ ',
    messagesScansTitle: 'Mes alertes de scan',
    messageScan: number => `${lstr(number)} alerte${getS(number)} de scan`,
    notificationTab: number => `Notification${getS(number)}`,
    messageScanTab: number => `Alerte${getS(number)} de scan`,
  },
  preferences: {
    title: 'Préférences',
    addPref: 'Ajouter une préférence',
    editPref: 'Editer une préférence',
    interventions: 'Interventions',
    filters: 'Filtres personnalisés',
    version: 'Version',
    unavailable: 'Veuillez vous connecter pour accéder à la gestion des préférences d\'intervention.',
    prefName: 'NOM DE MA PREFERENCE',
    labelMyPref: 'Ma préférence',
    interventionTypes: {
      calibration: 'Etalonnage accrédité',
      repair: 'Réparation',
      verification: 'Vérification Standard',
    },
    lockedOrientation: 'Mode paysage forcé (tablette) - prochainement'
  },
  historyScan: {
    validated: 'Validé',
    outOfBound: 'Hors-périmètre',
    lost: 'Introuvable',
    notFound: 'N\'existe pas',
    archived: 'Archivé',
    noAnalyzed: 'Non Analysé'
  },
  historyPage: {
    allScans: 'Tous les scans',
    oobScans: 'Hors-Périmètre',
    selectedDate: 'Date sélectionnée: ',
    fileNameExtension: 'DD-MM-YYYY HH_mm_ss',
    barcodeType: {
      marquage: 'MARQUAGE',
      idCris: 'IDCRIS',
      oldMarquage: 'ANCIENMARQUAGE',
      rfid: 'RFID',
      none: 'NONE'
    },
    downloadTitle: 'scan_',
    csvExtension: '.csv',
    pages: {
      interventionRequestPage: 'Demande d\'intervention',
      inventoryPage: 'Inventaire',
      scanPage: 'Scan',
      nonePage: 'none',
    },
  },
  traceability: {
    user: 'Utilisateur',
    location: 'Localisation',
    locationHint: 'Merci d\'indiquer la localisation de dépôt des appareils.',
    expectedReturnDate: 'Date de retour prévue',
    borrow: 'Emprunter',
    listBorrow: 'Liste des emprunts',
    return: 'Rendre',
    borrowComment: date => `Date de retour prévue: ${date}`,
    returnComment: date => `Date de retour ${date}`,
    thanks: 'Merci!',
    thanksSentence: 'Merci pour les informations que vous nous avez fournies.',
    dontForget: 'N\'oubliez pas de revenir lorsque vous aurez terminé avec vos appareils.',
    result: (success, fails) => `${lstr(success)} instrument${getS(success)} mis à jours. 
    ${lstr(fails)} échec${getS(fails)}...`,
    end: 'Terminer'
  },
  help: {
    flyerDownload: 'Télécharger le manuel utilisateur',
    appUserGuideDownload: 'Télécharger le manuel d\'installation',
    quickscanGuideDownload: 'Télécharger le manuel de configuration de la douchette',
    welcomeTitle: 'Bienvenue sur ADM v4',
    dialogTitle: 'ADM fait peau neuve ! Pour l\'occasion, la nouvelle version est disponible sur 3 supports:',
    continue: 'Continuer',
    pc: 'PC',
    tablet: 'Tablette (paysage)',
    smartphone: 'Smartphone (portrait)',
    optimalExperience: 'Pour une expérience optimale, nous recommandons l\'utilisation de ',
    or: ' ou ',
    edge: 'Edge',
    chrome: 'Google Chrome',
    textComments: 'Merci de nous laisser vos retours, problèmes rencontrés et idées dans la page Commentaires.',
    update: 'Notes de mise à jour'
  },
  noConnection: {
    noConnectionLabel: pageID => `Vous ne pouvez pas accéder à la page ${pageID} en mode hors-ligne`,
  },
  intervention: {
    title: 'RENSEIGNER UNE INTERVENTION',
    cartStep: 'PANIER',
    formStep: 'INTERVENTION',
    submitStep: 'CONCLUSION',
    answerStep: 'ENVOI',
    creatingInterventions: 'Création des interventions en cours...',
    tryAgain: 'Réessayer',
    validateTitle: 'Attention',
    validateLabel: 'Vous allez envoyer les interventions. Valider ?',
    interventionCard: {
      title: 'INTERVENTION',
      serviceTypeLabel: 'Nature de l\'intervention',
      location: 'Localisation',
      providerTypeLabel: 'Type de Prestataire',
      providerLabel: 'Prestataire',
      technician: 'Technicien',
      interventionDate: 'Date de l\'intervention',
      comments: 'Commentaires'
    },
    conclusionCard: {
      title: 'CONCLUSION',
      state: 'État',
      status: 'Status',
      requestId: 'DPS',
      updateInstrument: 'Modification de l\'instrument',
      notEditable: 'Le champ n\'est pas modifiable avec l\'intervention choisie'
    },
    DPSCard: {
      title: 'SAISIE DES DPS',
    },
    documentRepository: {
      title: 'DOCUMENTS ET LIENS',
      addDocumentLabel: 'AJOUTER UN DOCUMENT',
      addLienLabel: 'AJOUTER UN LIEN',
      limitFilesNumber: number => `Nombre limite de fichiers: ${number}`,
      limitLinkNumber: number => `Nombre limite de liens: ${number}`,
      updateDocuments: 'Modification des documents',
      updateLink: 'Modification des liens',
      linkPlaceholder: 'Veuillez saisir l\'url du lien',
      linkNamePlaceholder: 'Veuillez saisir le nom du lien',
      name: 'nom',
      link: 'lien',
      fieldsCannotBeEmpty: 'Ces champs ne peuvent être vides !',
      noDuplicateName: 'Ce nom est déjà utilisé !'
    },
  },
  guides: {
    startGuideLabel: 'LANCER LE GUIDE',
    close: 'Fermer',
    next: 'Suivant',
    last: 'Terminer',
    back: 'Précédent',
    skip: 'Passer le tutoriel',
    start: 'Commencer',
    mobile: {
      close: 'Fermer',
      next: 'Suivant',
      last: 'Terminer',
      back: 'Préc.',
      skip: 'Passer'
    },
    actionRequired: 'Une action de votre part est requise',
    leaveGuide: 'Quitter le tutoriel',
    modeClassic: 'Classique',
    modeReal: 'Réel',
    modeSelectionTitle: 'Choisir un mode pour le tutoriel',
    modeRealDesc: 'Mode réel: les données seront envoyées au serveur si validation de votre part, ' +
      'comme si vous utilisiez la fonctionnalité sans le mode tutoriel.',
    modeClassicDesc: 'Mode classique (défaut): vos données ne seront pas réellement validées ni envoyées au serveur.' +
      ' Utile pour découvrir une fonctionnalité sans craintes.',
    modeSelectionDesc: 'Lancer le guide en mode classique ou réel ?'
  },
  errorPage: {
    errorHasOccured: 'Une erreur est survenue.',
    errorDesc: 'Si vous êtes connecté à Internet, les détails du crash ont été envoyés aux administrateurs. Nous vous' +
      ' invitons à nous faire parvenir les circonstances qui ont amené à un bug dans la page "Commentaire",' +
      ' notamment si celui-ci se produit à plusieurs reprises.',
    reloadApp: 'Relancer l\'application'
  },
  versionDialog: {
    title: 'Une nouvelle version est disponible !',
    devMesssage: 'Les versions front et back doivent être exactement les mêmes (package.json).',
    update: 'Mettre à jour',
    skip: 'Continuer sans mettre à jour'
  },
  externalFilterDialog: {
    title: 'Attention !',
    description: 'Les instruments possédant les marquages suivants n\'appartiennent' +
      ' pas à votre parc et ne seront donc pas disponibles :'
  },
  banner:{
    criticalSeverity: 'CRITIQUE:',
    mediumSeverity: 'ATTENTION:',
    infoSeverity: 'INFORMATION:',
  }
};

export const Steps = [
  LocalizedString.interventionRequest.cartStep,
  LocalizedString.interventionRequest.serviceShippingStep,
  LocalizedString.interventionRequest.confirmationStep,
  LocalizedString.interventionRequest.validateLabel
];
