import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';

import { LocalizedString as strings } from './Constants/LocalizedString';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isoWeek);

/** Change a date by adding business days (week-end days are ignored) */
const addBusinessDays = (date, days) => {
  let newDate = dayjs(date);
  let daysLeft = days;
  while (daysLeft > 0) {
    newDate = newDate.add(1, 'days');
    // decrease "daysLeft" only if it's a weekday.
    if (newDate.isoWeekday() !== 6 && newDate.isoWeekday() !== 7) {
      daysLeft -= 1;
    }
  }
  return newDate;
};

const usDateToFrDate = (usDate, withHours = false, fixTimezone = false) => {
  let outputFormat = withHours ? strings.general.dateFormatWithoutSeconds : strings.general.dateFormat;
  let inputFormat = withHours ? strings.general.usDateFormatWithTime : strings.general.usDateFormat;
  if (usDate) {
    return fixTimezone
      ? dayjs(usDate).tz(strings.general.timeZoneParis).format(strings.general.dateFormatWithoutSeconds)
      : dayjs(usDate, inputFormat).format(outputFormat);
  }
  return '';
};

/** Convert timestamp to date like : DD/MM/YYYY MMM:SSS */
const getTimeStampToDate = timeToConvert => {
  if(timeToConvert > 0) {
    return dayjs(timeToConvert).tz(strings.general.timeZoneParis).format(strings.general.dateFormatWithTime);
  } else {
    return '';
  }
};

const getTimestampAsSeconds = () => Date.now() / 1000 | 0;

const getDateToTimestamp = dateValue => {
  // Check the date minute and second
  if (dateValue.includes(':') && dateValue.includes('T')) {
    return dayjs(dateValue).valueOf();
  } else {
    return dayjs(dateValue).format('YYYYMMDD'); // Format to specific timestamp format
  }
};

const getDayDiff = ( d1, d2 ) => {
  d1 = d1.getTime() / 86400000;
  d2 = d2.getTime() / 86400000;
  return Number(d2 - d1).toFixed(0);
};

export {
  addBusinessDays, getDateToTimestamp,
  getDayDiff, getTimeStampToDate, getTimestampAsSeconds, usDateToFrDate
};

