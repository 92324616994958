// REACT Library
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Material-UI libraries
import sharedStyles from '../../../../Utils/SharedStyles';
import Build from '@mui/icons-material/Build';
// Components libraries
import StaticField from '../../StaticField/StaticField';
// Utils libraries
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { Typography, CardHeader, CardContent } from '@mui/material';
import { usDateToFrDate } from '../../../../Utils/DateUtils';
import { isNotComputer } from '../../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../../Utils/Theme';
import merge from 'lodash/merge';

class InstrumentCardMetrologie extends Component {
  render() {
    const styles = sharedStyles;
    return (
      <>
        {this.props.showTitle &&
          <CardHeader
            sx={{...merge({}, styles(theme).cardHeader,
              (isNotComputer && styles(theme).tabletCardHeader))}}
            title={
              <Typography color="secondary"
                sx={{...merge({}, styles(theme).titleCardHeader,
                  (isNotComputer && styles(theme).tabletTitleFontSize))}}>
                {strings.instrumentCard.metrologie}
              </Typography>
            }
            avatar={<Build color="secondary"
              sx={{...merge({}, styles(theme).iconCardHeader,
                (isNotComputer && styles(theme).tabletIconCardHeader))}}/>}
          />
        }
        <CardContent sx={{ "&.MuiCardContent-root": {...styles(theme).cardContent}}}>
          <StaticField
            name={strings.instrumentCard.metrologieContent.status}
            value={this.props.instrument.etat} />
          <StaticField
            name={strings.instrumentCard.metrologieContent.etatMetrologie}
            value={this.props.instrument.etatMetrologique} />
          <StaticField
            name={strings.instrumentCard.metrologieContent.operationPrecedente}
            value={this.props.instrument.motifEnvoi} />
          <StaticField
            name={strings.instrumentCard.metrologieContent.effectueLe}
            value={usDateToFrDate(this.props.instrument.dateOperation)} />
          <StaticField
            name={strings.instrumentCard.metrologieContent.planning}
            value={this.props.instrument.planning} />
          <StaticField
            name={strings.instrumentCard.metrologieContent.periodicite}
            value={this.props.instrument.periodicitePeriode} />
          <StaticField
            name={strings.instrumentCard.metrologieContent.prochaineEcheance}
            value={usDateToFrDate(this.props.instrument.periodiciteProchaineOperation)} />
          <StaticField
            name={strings.instrumentCard.metrologieContent.operation}
            value={this.props.instrument.periodiciteOperation} />
        </CardContent>
      </>
    );
  }
}

InstrumentCardMetrologie.propTypes = {
  instrument: PropTypes.object,
  showTitle: PropTypes.bool
};
export default InstrumentCardMetrologie;
