import PAGES_IDS from '../Utils/Constants/PagesIDs';
import { theme } from '../Utils/Theme';

class ThemeHelper {

  /**
   * Return specific color according to id of the page
   * @param {number} page_id: Id of the page where is the user
   */
  static getPrimaryColor = (page_id) => {
    let color;
    switch (page_id) {
      case PAGES_IDS.INVENTORY:
        color = theme.palette.MainMenu.inventoryMenuOption;
        break;
      case PAGES_IDS.INTERVENTION_CREATION:
      case PAGES_IDS.INTERVENTION:
      case PAGES_IDS.INTERVENTION_REQUEST:
        color = theme.palette.MainMenu.interventionMenuOption;
        break;
      case PAGES_IDS.USER_PROFILE:
      case PAGES_IDS.FOLLOWED_INSTRUMENTS:
      case PAGES_IDS.HISTORY:
      case PAGES_IDS.PREFERENCES:
      case PAGES_IDS.FEEDBACKS:
      case PAGES_IDS.HELP:
      case PAGES_IDS.UPDATE:
        color = theme.palette.MainMenu.otherMenuOptions;
        break;
      case PAGES_IDS.HOME:
      case PAGES_IDS.SCAN:
      case PAGES_IDS.INSTRUMENTS:
      default:
        color = theme.palette.primary.main;
        break;
    }
    return color;
  };
}

export default ThemeHelper;
