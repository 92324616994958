import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import sharedStyles from '../../Utils/SharedStyles';

const { FULL_VIEWPORT_WIDTH } = globalConstants.style;

export let styles = theme => ({
  ...sharedStyles(theme),
  main: {
    width: FULL_VIEWPORT_WIDTH,
    padding: '3% 0',
    // Fix IE 11 issue.
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    backgroundColor: theme.palette.mainPageBackground,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      padding: "0px",
    },
  },
  paper: {
    maxHeight: '100%',
    minHeight: "450px",
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: '100%',
      margin: "0px",
      padding: '8px 12px 8px',
      flexDirection: 'column',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    overflow: 'auto'
  },
  logoADM: {
    minWidth: "0px",
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      maxWidth: '60%',
    },
  },
  tabletLogoADM: {
    [theme.breakpoints.up('lg')]: {
      width: '350px',
    }
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  authTitle: {
    textAlign: 'center',
    color: theme.palette.veryDarkBlueTheme.main
  },
  formInput: {
    [theme.breakpoints.only('xs')]: {
      marginTop: '1vh'
    },
  },
  input: {
    color: theme.palette.veryDarkBlueTheme.main,
  },
  input_label: {
    left: '-14px',
    top: '4px'
  },
  tabletLineHeight: {
    lineHeight: '1.25rem',
  },
  submit: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.veryDarkBlueTheme.main,
    color: theme.palette.secondary.main,
    width: '66%',
    borderRadius: "8px",
    '&:hover': {
      backgroundColor: theme.palette.veryDarkBlueTheme.secondary,
    },
    [theme.breakpoints.only('xs')]: {
      width: '80%',
      height: "50px"
    },
  },
  submitGuest: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.veryDarkBlueTheme.main,
    width: '66%',
    borderRadius: "8px",
    '&:hover': {
      backgroundColor: theme.palette.pureWhite,
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    marginTop: theme.spacing(4),
    textAlign: 'right'
  },
  textLink: {
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.veryDarkBlueTheme.secondary
    },
    color: theme.palette.veryDarkBlueTheme.main,
    textDecoration: 'none',
    cursor: 'pointer'
  },
  orLabel: {
    margin: '6px 4px 4px 4px',
    color: theme.palette.veryDarkBlueTheme.main,
    fontSize: '1rem'
  },
  reconnectLabel: {
    margin: '6px 4px 4px 4px',
    color: theme.palette.redTheme.main
  },
  version: {
    textAlign: 'center',
    marginTop: theme.spacing(3),
    marginBottom: "0px",
    color: theme.palette.veryDarkBlueTheme.main,
  },
  offlinePanel: {
    backgroundColor: theme.palette.toasts.warning,
    color: 'white',
    padding: "15px",
    fontWeight: 'bold',
    height: 'unset!important',
    position: 'fixed',
    width: '100%',
    textAlign: 'center'
  },
  ssoHelp: {
    position: 'absolute',
    right: '15px',
  }
});
