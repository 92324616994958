import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';

class UserService {

  static getUserInfosFromName(lastName, firstName) {
    return HttpClient.simpleGet(routes.getInfosFromName + '/' + lastName + '/' + firstName);
  }

  static getLoggedUserInformations() {
    return HttpClient.get(routes.userInfo);
  }

  static checkTokenValiditySSO() {
    return HttpClient.get(routes.checkTokenSSO);
  }

}

export default UserService;
