import HttpClient from '../HttpClient';
import routes from '../../Utils/Routes';
import { DeviceInformations } from '../../Utils/Constants/DeviceInformations';

class BugService {

  static async postNewBug(message, stack) {
    const bug = {
      message: message,
      stacktrace: stack,
      device: {
        os: localStorage.getItem(DeviceInformations.BROWSER_NAME) +
          localStorage.getItem(DeviceInformations.BROWSER_VERSION),
        model: localStorage.getItem(DeviceInformations.MOBILE_VENDOR) +
          localStorage.getItem(DeviceInformations.MOBILE_MODEL) + localStorage.getItem(DeviceInformations.OS_NAME) +
          localStorage.getItem(DeviceInformations.OS_VERSION),
        appversion: ''
      }
    };
    return HttpClient.post(routes.postNewBug, bug);
  }
}

export default BugService;

