import React from 'react';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { FILTER_DATE_FROM, FILTER_DATE_TO } from '../../../../Utils/Constants/FilterConstants';
import { Event } from '@mui/icons-material';
import { styles } from './DateFilterField.style';
import { MobileDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { theme } from '../../../../Utils/Theme';
import { Box } from '@mui/material';

dayjs.extend(utc);

const onDateChangeHandler = (date, id, props) => {

  if (date.isValid()) {
    props.onDateChange(props.fieldName, id, dayjs(date));
  }
};

const DateFilterField = props => {
  return (
    <Box component="div" sx={{...styles(theme).content}}>
      <Box component="div" sx={{...styles(theme).textAndIcon}}>{strings.filter.dateFrom} </Box>
      <Event color='primary' sx={{...styles(theme).textAndIcon}} />
      <MobileDatePicker
        label={strings.filter.dateFromText}
        slotProps={{ textField: { variant: 'standard' } }}
        maxDate={props.to}
        onAccept={date => onDateChangeHandler(date, FILTER_DATE_FROM, props)}
        value={props.from}
        defaultValue={props.from}
        InputLabelProps={{ shrink: true }}
        sx={{...styles(theme).textField}}
        format={strings.general.dateFormat}
      />
      <Box component="div" sx={{...styles(theme).textAndIcon}}>{strings.filter.dateTo} </Box>
      <Event color='primary' sx={{...styles(theme).textAndIcon}} />
      <MobileDatePicker
        label={strings.filter.dateToText}
        slotProps={{ textField: { variant: 'standard' } }}
        minDate={props.from}
        onAccept={date => onDateChangeHandler(date, FILTER_DATE_TO, props)}
        value={props.to}
        defaultValue={props.to}
        InputLabelProps={{ shrink: true }}
        format={strings.general.dateFormat}
      />
    </Box>
  );
};

DateFilterField.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
};

export default DateFilterField;
