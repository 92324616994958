import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, IconButton, List, ListItemButton, ListItemText, SvgIcon, Tooltip, Typography } from '@mui/material';
import { Event, Home, Visibility } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import {
  enableMultipleIntervention,
  initOpenConfirmationDialog,
  openMultipleInterventionsInstruments,
  resetFilters,
  resetInterventionRequest,
  setMultipleInterventions,
  setStatus,
  unlock,
  updateActiveStep,
  updateCurrentPage
} from '../../store/actions/exportActions';
import { InterventionRequestConstants } from '../../Utils/Constants/InterventionRequestConstants';
import { svgConstants } from '../../Utils/Constants/SvgConstants';
import { styles } from './InterventionRequest.style';
import StepWizard from '../../Containers/StepWizard/StepWizard';
import CartStep from './Steps/CartStep/CartStep';
import ServiceShippingStep from './Steps/ServiceShippingStep/ServiceShippingStep';
import ConfirmationStep from './Steps/ConfirmationStep/ConfirmationStep';
import { SnackbarMessages } from '../../Utils/Constants/SnackbarMessages';
import InterventionRequestService from '../../Providers/WebService/InterventionRequestService';
import LoadingDialog from '../Dialogs/LoadingDialog/LoadingDialog';
import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import { withGuide } from '../Guides/Guidable';
import { STATUSES } from '../../Utils/Constants/GuideConstants';
import StartGuideButton from '../Utils/StartGuideButton/StartGuideButton';
import PdfDownloadButton from '../Utils/PdfDownloadButton/PdfDownloadButton';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    activeStep: state.interventionReducer.activeStep,
    pdfDocument: state.interventionReducer.pdfDocument,
    pdfDocuments: state.interventionReducer.pdfDocuments,
    onMobile: state.generalReducer.onMobile,
    multipleInterventions: state.interventionReducer.multipleInterventions,
    multipleInterventionEnabled: state.interventionReducer.multipleInterventionEnabled,
    multipleInterventionsInstrumentsIsOpen: state.interventionReducer.openMultipleInterventionsInstruments
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    resetInterventionRequest: values => dispatch(resetInterventionRequest(values)),
    updateActiveStep: values => dispatch(updateActiveStep(values)),
    updateCurrentPage: pageID => dispatch(updateCurrentPage(pageID)),
    initOpenConfirmationDialog: values => dispatch(initOpenConfirmationDialog(values)),
    resetFilters: () => dispatch(resetFilters()),
    setStatus: value => dispatch(setStatus(value)),
    unlock: () => dispatch(unlock()),
    openMultipleInterventionsInstruments: value => dispatch(openMultipleInterventionsInstruments(value)),
    resetMultipleInterventionsData: () => dispatch(setMultipleInterventions([])),
    enableMultipleIntervention: value => dispatch(enableMultipleIntervention(value))
  };
};

class InterventionRequest extends Component {

  state = {
    eligibleInstrumentMarquages: [],
    loading: false,
    selectedInstruments: [],
    submitted: false,
    multipleInterventionEnabledOnSite: false,
    showResetModal: false
  };

  loadInstruments = async () => {
    this.setState({ loading: true, selectedInstruments: [] });
    InterventionRequestService.isMultipleInterventionEnabled().then(enabled =>
      this.setState({ multipleInterventionEnabledOnSite: enabled }));
    InterventionRequestService.getEligibleInstruments().then(marquages => {
      this.setState({
        eligibleInstrumentMarquages: marquages,
        loading: false
      }, () => this.props.setStatus(STATUSES.READY));
    }).catch(() => {
      this.setState({ loading: false });
      enqueueSnackbar(SnackbarMessages.eligibleInstrumentsError.msg,
        SnackbarMessages.eligibleInstrumentsError.type);
    });
  };

  componentDidMount() {
    this.loadInstruments();
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.activeStep && prevProps.activeStep.index === InterventionRequestConstants.RESPONSE_STEP
      && this.props.activeStep.index === InterventionRequestConstants.CART_STEP) {
      this.loadInstruments();
    }
  }

  componentWillUnmount() {
    this.props.resetFilters();
    this.props.resetInterventionRequest();
  }

  renderHeaderIcons = () => {
    if (this.props.activeStep.index === InterventionRequestConstants.RESPONSE_STEP) {
      const arePdfDocumentsAvailable = this.props.pdfDocuments && this.props.pdfDocuments.length > 0;
      return <>
        <Tooltip title={strings.tooltips.resetInterventionRequest} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="newInterventionButton"
            sx={{...styles(theme).intervention_headerIcons}}
            onClick={this.props.resetInterventionRequest}
            size="large">
            <SvgIcon>
              <path d={svgConstants.newIntervention} />
            </SvgIcon>
          </IconButton>
        </Tooltip>

        <PdfDownloadButton
          pdfDocuments={arePdfDocumentsAvailable
            ? this.props.pdfDocuments
            : [this.props.pdfDocument]}
          onMobile={this.props.onMobile} />

        <Tooltip title={strings.tooltips.redirectInterventionsHome} enterDelay={globalConstants.tooltipDelay}>
          <IconButton
            id="homeButton"
            sx={{ "&.MuiIconButton-root": {...styles(theme).intervention_headerIcons} }}
            onClick={() => this.props.updateCurrentPage(PAGES_IDS.INTERVENTION)}
            color="secondary"
            size="large">
            <Home />
          </IconButton>
        </Tooltip>
      </>;
    } else if (this.props.activeStep.index === InterventionRequestConstants.CART_STEP) {
      return <StartGuideButton withLabel />;
    } else if (this.props.activeStep.index === InterventionRequestConstants.SHIPPING_STEP
      && this.props.multipleInterventionEnabled) {
      return (
        <Button onClick={() => this.props.openMultipleInterventionsInstruments(true)}>
          <Typography variant="body2" color="secondary">
            {strings.interventionRequest
              .dynamicStepTitle(strings.interventionRequest.step,
                this.props.activeStep.multipleInterventionIndex + 1,
                this.props.multipleInterventions.length)}
          </Typography>
          <Visibility color="secondary" style={{ marginLeft: "8px" }}/>
        </Button>
      );
    }
  };

  handleNext = () => {
    if (this.props.activeStep.isValid) {
      // If Multiple intervention and in service shipping
      if (this.props.multipleInterventionEnabled &&
        this.props.activeStep.index === InterventionRequestConstants.SHIPPING_STEP &&
        this.props.activeStep.multipleInterventionIndex < (this.props.multipleInterventions.length - 1)) {
        this.props.updateActiveStep({
          index: this.props.activeStep.index,
          isValid: false,
          multipleInterventionIndex: this.props.activeStep.multipleInterventionIndex + 1
        });
      } else if (this.props.activeStep.index === InterventionRequestConstants.CONFIRMATION_STEP) {
        this.props.initOpenConfirmationDialog(true);
      } else {
        this.props.updateActiveStep({ index: this.props.activeStep.index + 1, isValid: false });
        this.props.unlock();
      }
    } else {
      this.setState({ submitted: true });
      enqueueSnackbar(
        SnackbarMessages.stepperValidationError[this.props.activeStep.index].msg,
        SnackbarMessages.stepperValidationError[this.props.activeStep.index].type
      );
    }
  };

  handlePrev = () => {
    if (this.props.multipleInterventionEnabled &&
      this.props.activeStep.index === InterventionRequestConstants.SHIPPING_STEP) {
      if (this.props.activeStep.multipleInterventionIndex > 0) {
        this.props.updateActiveStep({ index: this.props.activeStep.index, isValid: true,
          multipleInterventionIndex: this.props.activeStep.multipleInterventionIndex - 1 });
      } else {
        this.setState({ showResetModal: true });
      }
    } else if (this.props.activeStep.index !== InterventionRequestConstants.RESPONSE_STEP) {
      this.props.updateActiveStep({ index: this.props.activeStep.index - 1, isValid: true });
    }
  };

  addOrRemoveInstruments = (instruments, add = true) => {
    this.setState(prevState => ({
      selectedInstruments: add ? prevState.selectedInstruments.concat(instruments) :
        prevState.selectedInstruments.filter(instr => instr.marquage !== instruments.marquage)
    }));
  }

  render() {
    const { activeStep } = this.props;
    const onMultipeInterventionStep = this.props.multipleInterventionEnabled
      && activeStep.index === InterventionRequestConstants.SHIPPING_STEP;
    const headerProps = {
      title: strings.interventionRequest.title,
      icon: <Event />,
      renderHeaderIcons: () => this.renderHeaderIcons()
    };

    let stepsTitles = [
      (onMultipeInterventionStep && activeStep.multipleInterventionIndex > 0)
        ? strings.interventionRequest.serviceTitle + ' (' + (activeStep.multipleInterventionIndex) + '/'
        + this.props.multipleInterventions.length + ')' : strings.interventionRequest.cartStep,
      (onMultipeInterventionStep && activeStep.multipleInterventionIndex <= this.props.multipleInterventions.length - 1)
        ? strings.interventionRequest.serviceShippingStep + ' (' + (activeStep.multipleInterventionIndex + 1) + '/'
        + this.props.multipleInterventions.length + ')' : strings.interventionRequest.serviceShippingStep,
      (onMultipeInterventionStep && activeStep.multipleInterventionIndex < this.props.multipleInterventions.length - 1)
        ? strings.interventionRequest.serviceTitle + ' (' + (activeStep.multipleInterventionIndex + 2) + '/' +
        this.props.multipleInterventions.length + ')' : strings.interventionRequest.confirmationStep,
      strings.interventionRequest.responseStep,
    ];

    return (
      <>
        <StepWizard
          id="stepper"
          steps={stepsTitles}
          activeStep={activeStep.index}
          onNextClicked={() => this.handleNext()}
          onPrevClicked={() => this.handlePrev()}
          {...headerProps}
        >
          {this.state.loading &&
            <LoadingDialog
              open={this.state.loading}
              title={strings.general.loading}
              label={strings.interventionRequest.loadingEligible}
            />
          }

          {/* PAGE 1: CART PAGE */}
          {activeStep.index === InterventionRequestConstants.CART_STEP &&
            <CartStep
              addOrRemoveInstruments={this.addOrRemoveInstruments}
              selectedInstruments={this.state.selectedInstruments}
              eligibleInstruments={this.state.eligibleInstrumentMarquages}
            />
          }

          {/* PAGE 2: SHIPPING PAGE */}
          {activeStep.index === InterventionRequestConstants.SHIPPING_STEP &&
            <ServiceShippingStep
              submitted={this.state.submitted}
              selectedInstruments={this.state.selectedInstruments}
              multipleInterventionEnabledOnSite={this.state.multipleInterventionEnabledOnSite}
            />
          }

          {/* PAGE 3: CONFIRMATION PAGE */}
          {activeStep.index === InterventionRequestConstants.CONFIRMATION_STEP &&
            <ConfirmationStep
              addOrRemoveInstruments={this.addOrRemoveInstruments}
              selectedInstruments={this.state.selectedInstruments}
              multipleInterventionEnabledOnSite={this.state.multipleInterventionEnabledOnSite}
            />
          }

          {/* PAGE 4: RESPONSE PAGE */}
          {activeStep.index === InterventionRequestConstants.RESPONSE_STEP &&
            <ConfirmationStep response
              multipleInterventionEnabledOnSite={this.state.multipleInterventionEnabledOnSite}
              selectedInstruments={this.state.selectedInstruments} />
          }

        </StepWizard>
        {this.props.multipleInterventionsInstrumentsIsOpen &&
          <SimpleDialog
            open={this.props.multipleInterventionsInstrumentsIsOpen}
            title={strings.interventionRequest.dynamicStepTitle(strings.interventionRequest.serviceShippingStep,
              activeStep.multipleInterventionIndex + 1, this.props.multipleInterventions.length)}
            acceptLabel={strings.general.ok}
            onAccept={() => this.props.openMultipleInterventionsInstruments(false)}
            addContentPadding
          >
            <Typography variant="subtitle1"><em>{strings.interventionRequest.currentStepInstruments}</em></Typography>
            <List id="instrumentsList">
              {this.props.multipleInterventions[activeStep.multipleInterventionIndex]?.instruments.map(instrument =>
                <ListItemButton key={instrument.marquage}>
                  <ListItemText primary={instrument.marquage} />
                </ListItemButton>
              )}
            </List>
          </SimpleDialog>}
        {this.state.showResetModal &&
          <SimpleDialog
            open={this.state.showResetModal}
            title={strings.intervention.validateTitle}
            acceptLabel={strings.dialog.answer.submit}
            onAccept={() => {
              this.props.resetInterventionRequest();
              this.props.updateActiveStep({ index: 0, isValid: this.state.selectedInstruments.length > 0 });
              this.setState({ showResetModal: false });
            }}
            refuseLabel={strings.dialog.answer.cancel}
            onRefuse={() => this.setState({ showResetModal: false })}
            addContentPadding
          >
            <Typography variant="subtitle1"><em>{strings.interventionRequest.warningReset}</em></Typography>
          </SimpleDialog>}
      </>
    );
  }

}

InterventionRequest.propTypes = {
  activeStep: PropTypes.shape({
    index: PropTypes.number,
    isValid: PropTypes.bool,
    multipleInterventionIndex: PropTypes.number,
  }).isRequired,
  pdfDocument: PropTypes.string,
  resetInterventionRequest: PropTypes.func.isRequired,
  // redux
  updateActiveStep: PropTypes.func.isRequired,
  initOpenConfirmationDialog: PropTypes.func.isRequired,
  resetFilters: PropTypes.func,
  updateCurrentPage: PropTypes.func,
  setStatus: PropTypes.func,
  unlock: PropTypes.func,
  pdfDocuments: PropTypes.array,
  multipleInterventionEnabled: PropTypes.bool,
  openMultipleInterventionsInstruments: PropTypes.func,
  multipleInterventions: PropTypes.array,
  onMobile: PropTypes.bool,
  multipleInterventionsInstrumentsIsOpen: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(withGuide(InterventionRequest, false));
