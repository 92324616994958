import React from 'react';
import PropTypes from 'prop-types';
import { Icon, List, ListItem, ListItemText } from '@mui/material';
import { styles } from './InstrumentDotDialog.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import InstrumentHelper from '../../../Helpers/InstrumentHelper';
import { INSTRUMENTCOLORS } from '../../../Utils/Constants/EtatsTypes';
import { usDateToFrDate } from '../../../Utils/DateUtils';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import dayjs from 'dayjs';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

const InstrumentDotDialog = props => {

  const generateListGuestMode = instrument => {
    let label;
    let field;
    const dateOperation = new Date(instrument.periodiciteProchaineOperation);
    const todayDate = new Date(dayjs().format('YYYY-MM-DD'));
    if (dateOperation < todayDate) {
      label = strings.tracking.borrowingStateOperationOne;
      field = '';
    } else {
      label = strings.instrumentCard.metrologieContent.prochaineEcheance;
      field = usDateToFrDate(instrument.periodiciteProchaineOperation)
    }

    return (
      <ListItem
        sx={{...styles(theme).itemContainer}}
      >
        <ListItemText
          primary={label}
          sx={{ "& .MuiListItemText-primary": {
            ...merge({}, styles(theme).responsiveMobile,
            (isNotComputer && styles(theme).tabletFontSize))
            }, ...styles(theme).label }}/>
        <ListItemText
          secondary={field}
          sx={{ "& .MuiListItemText-secondary": {
            ...merge({}, styles(theme).responsiveMobile,
            (isNotComputer && styles(theme).tabletFontSize))
            }, ...styles(theme).secondary }}
        />
      </ListItem >);
   
  };

  const generateList = instrument => {
    const colorList = InstrumentHelper.instrumentColorArray(instrument);
    let color, label, field;
    return colorList.map(element => {
      switch (element) {
        case INSTRUMENTCOLORS.DORMANT:
          color = theme.palette.blueTheme.main;
          label = strings.instrumentCard.metrologieContent.status;
          field = instrument.etat;
          break;
        case INSTRUMENTCOLORS.INTROUVABLE:
          color = theme.palette.orangeTheme.main;
          label = strings.instrumentCard.metrologieContent.status;
          field = instrument.etat;
          break;
        case INSTRUMENTCOLORS.HORS_DATE_VERIF:
          color = theme.palette.redTheme.main;
          label = strings.instrumentCard.metrologieContent.prochaineEcheance;
          field = usDateToFrDate(instrument.periodiciteProchaineOperation);
          break;
        case INSTRUMENTCOLORS.FIN_LOCATION_PREVISIONNELLE:
          color = theme.palette.purpleTheme.main;
          label = strings.instrumentCard.adminContent.finPrevisionnelleLocation;
          field = usDateToFrDate(instrument.finPrevisionnelleLocation);
          break;
        default:
          break;
      }
      return (
        <ListItem
          key={element}
          sx={{...styles(theme).itemContainer}}
        >
          <Icon
            style={{ background: color }}
            sx={{ "&.MuiIcon-root": {...styles(theme).icon} }} />
          <ListItemText
            primary={label}
            sx={{ "& .MuiListItemText-primary": {
              ...merge({}, styles(theme).responsiveMobile,
              (isNotComputer && styles(theme).tabletFontSize))
              }, ...styles(theme).label }} />
          <ListItemText
            secondary={field}
            sx={{ "& .MuiListItemText-secondary": {
              ...merge({}, styles(theme).responsiveMobile,
              (isNotComputer && styles(theme).tabletFontSize))
              }, ...styles(theme).secondary }} />
        </ListItem >);

    });
  };

  return (
    <SimpleDialog
      open={props.open}
      title={strings.dialog.title.instrumentState}
      onRefuse={() => props.onClose()}
      refuseLabel={strings.general.ok}
    >
      <List>
        {!props.isNotGuestMode ?
          generateListGuestMode(props.instrument) : generateList(props.instrument)
        }
      </List>
    </SimpleDialog >
  );
};

InstrumentDotDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  instrument: PropTypes.object.isRequired,
  isNotGuestMode: PropTypes.bool,
};
InstrumentDotDialog.defaultProps = {
  isNotGuestMode: true
};
export default InstrumentDotDialog;
