export let styles = theme => ({
  chips_display: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0',
    flexWrap: 'wrap'
  },
  dialog: {
    [theme.breakpoints.only('xs')]: {
      padding: "0px"
    }
  },
  pref_card: {
    marginBottom: "8px"
  },
  pref_card_header: {
    backgroundColor: theme.palette.MainMenu.topMenu,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    color: theme.palette.secondary.main
  },
  pref_card_content: {
    backgroundColor: theme.palette.pureWhite,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  pref_header_title: {
    color: theme.palette.secondary.main,
    fontSize: "16px"
  },
  formInput: {
    margin: "5px"
  },
  pref_chip: {
    marginLeft: "8px",
    padding: '24px 16px',
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    fontSize: '1rem',
    [theme.breakpoints.only('xs')]: {
      margin: "5px"
    }
  },
  pref_add_button: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    }
  },
  pref_page: {
    height: '100%',
    padding: "8px",
    overflowY: 'auto'
  },
  circular_loader: {
    display: 'flex',
    justifyContent: 'center'
  },
  prefContent: {
    height: '100%'
  }
});
