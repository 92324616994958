import sharedStyles from '../../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  scrollArea: {
    height: '100%',
    overflowY: 'visible',
    display: 'flex',
    flexDirection: 'column'
  },
});
