import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  root: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'fixed',
    top: "4px",
    right: "4px",
    zIndex: 2500,
    [theme.breakpoints.only('xs')]: {
      top: '10px',
      right: '50%',
      left: '50%',
      backgroundColor: 'rgba(0,0,0,0.5)'
    },
  },
  fabProgress: {
    color: 'white',
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  }
});
