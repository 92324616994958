import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  instrumentCards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
  },
  instrumentList: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column'
  },
  scrollArea: {
    height: '100%',
    overflowY: 'visible',
    display: 'flex',
    flexDirection: 'column'
  },
  topBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(),
    [theme.breakpoints.only('xs')]: {
      paddingBottom: "0px"
    }
  },
  section:{
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(),
    [theme.breakpoints.only('xs')]: {
      paddingBottom: "0px"
    }
  },
  validButtonExport: {
    width: '100%',
    margin: "8px"
  },
  defaultHeight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column'

  },
  switchView: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing()
  },
  loader: {
    marginBottom: theme.spacing()
  },
  instrumentCounter: {
    margin: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2) - 2
    }
  },
  container: {
    padding: "4px",
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  listOfMarkings: {
    minHeight: '25vh',
  },
  marking: {
    marginBottom: theme.spacing(),
  },
  //region DO NOT REMOVE : empty classes that get overwritten according to chosen styling
  paper: {},
  content: {},
  list: {},
  header: {},
  headerLeft: {},
  title: {},
  headerRight: {},
  pageCard: {},
  headerIcons: {},
  headerTitle: {},
  interventionHeaderTitle: {},
  contentCard: {},
  validateBtn: {},
  //endregion
});

