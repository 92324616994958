import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Typography from '@mui/material/Typography';
import { styles } from './Role.style';
import PlayArrow from '@mui/icons-material/PlayArrow';
import { theme } from '../../../Utils/Theme';
import { Box } from '@mui/material';

// maps interventionReducer's state to Component's props
const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile
  };
};

class Roles extends Component {
  render() {
    const { role, onMobile } = this.props;
    const fontSizeChange = onMobile ? 'body2' : 'body1';
    return (
      <>
        <Box component="div" sx={{...styles(theme).displayValue}}>
          <PlayArrow sx={{...styles(theme).icon}} />
          <Typography id="typo" sx={{...styles(theme).valueRole}} variant={fontSizeChange} > {role.value}</Typography>
        </Box>
        <Box component="div" sx={{...styles(theme).displayUnit}}>
          <Typography variant={fontSizeChange}>{role.organizationalUnit}</Typography>
          <Typography variant={fontSizeChange}>{role.geographicalUnit}</Typography>
          <Typography variant={fontSizeChange}>{role.financialUnit}</Typography>
        </Box>
      </>
    );
  }
}

Roles.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  role: PropTypes.object.isRequired
};

export default compose(
  connect(mapStateToProps))(Roles);
