import generalReducer from './generalReducer';
import { combineReducers } from 'redux';
import interventionReducer from './interventionReducer';
import instrumentReducer from './instrumentReducer';
import toolbarReducer from './toolbarReducer';
import guideReducer from './guideReducer';
import { RESET_REDUX } from '../actions/actionTypes';

const appReducer = combineReducers({
  interventionReducer,
  generalReducer,
  instrumentReducer,
  toolbarReducer,
  guideReducer
});

export const rootReducer = (state, action) => {

  const newState = action.type === RESET_REDUX ? undefined : state;

  return appReducer(newState, action);
};
