import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import React from 'react';

import { styles } from './LoadingSpinner.style';
import { theme } from '../../../Utils/Theme';

const LoadingSpinner = props => {
  return (
    <Dialog open={true} sx={{ "& .MuiDialog-paper": {...styles(theme).paper}}}>
      <CircularProgress sx={{...styles(theme).progress}} />
    </Dialog>
  );
};

export default LoadingSpinner;
