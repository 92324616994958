import React, { Component } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import PropTypes from 'prop-types';
import LoadingSpinner from '../../Utils/LoadingSpinner/LoadingSpinner';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Paper,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Box
} from '@mui/material';
import { Help, PlaylistAddCheck } from '@mui/icons-material';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { styles } from './TrackingHomePage.style';
import { svgConstants } from '../../../Utils/Constants/SvgConstants';
import SvgIcon from '@mui/material/SvgIcon';
import { GUEST_PAGES } from '../../../Utils/Constants/GuestConstants';
import {
  setStatus
} from '../../../store/actions/guideActions';
import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import { STATUSES } from '../../../Utils/Constants/GuideConstants';
import { AttributConstants } from '../../../Utils/Constants/Guides/AttributConstants';
import { theme } from '../../../Utils/Theme';

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    setStatus: value => dispatch(setStatus(value)),
  };
};

class TrackingHomePage extends Component {

  state = {
    loading: false
  };

  generateLabel(variant, styling, label) {
    return (
      <Typography variant={variant} sx={{ "&.MuiTypography-root": {...styling} }}>
        {label}
      </Typography>
    );
  }

  render() {
    return (
      <Box component="main" sx={{...styles(theme).main}}>
        <CssBaseline />
        {this.state.loading && <LoadingSpinner />}
        <Paper elevation={2} id="guestContainer" sx={{...styles(theme).paper}}>
          <Box component="div" sx={{...styles(theme).header}}>
            <div>
              {this.generateLabel('h6', styles(theme).trackingTitle, strings.tracking.trackingTitle)}
              {this.generateLabel('subtitle2', styles(theme).trackingTitle, strings.tracking.trackingSubtitle)}
            </div>
            <Tooltip title={strings.tooltips.helpInfo} enterDelay={globalConstants.tooltipDelay}>
              <IconButton
                id="guestOpenGuide"
                color="primary"
                onClick={() => this.props.setStatus(STATUSES.INITIATED)}
                size="large">
                <Help />
              </IconButton>
            </Tooltip>
          </Box>
          <Box component="div" sx={{...styles(theme).cardArea}}>
            <Button
              id="borrowButton"
              sx={{ "&.MuiButton-root": {...styles(theme).track_buttons}, label: {...styles(theme).track_labels}, ...styles(theme).borrow }}
              onClick={() => this.props.updateGuestActivePage(GUEST_PAGES.BORROW)}
            >
              <SvgIcon sx={{ "&.MuiSvgIcon-root": {...styles(theme).icon_root} }}>
                <path d={svgConstants.tracking} />
              </SvgIcon>
              {this.generateLabel(AttributConstants.subtitle1,
                styles(theme).whiteColor, strings.tracking.borrowTitle)}
            </Button>
            <Button
              id="borrowedButton"
              sx={{ "&.MuiButton-root": {...styles(theme).track_buttons}, label: {...styles(theme).track_labels}, ...styles(theme).borrowed }}
              onClick={() => this.props.updateGuestActivePage(GUEST_PAGES.LIST)}
            >
              <SvgIcon sx={{ "&.MuiSvgIcon-root": {...styles(theme).icon_root} }}>
                <path d={svgConstants.newIntervention} />
              </SvgIcon>
              {this.generateLabel(AttributConstants.subtitle1,
                styles(theme).whiteColor, strings.tracking.listBorrowTitle)}
            </Button>
            <Button
              id="returnButton"
              sx={{ "&.MuiButton-root": {...styles(theme).track_buttons}, label: {...styles(theme).track_labels}, ...styles(theme).return }}
              onClick={() => this.props.updateGuestActivePage(GUEST_PAGES.RETURN)}
            >
              <PlaylistAddCheck sx={{ ...styles(theme).icon_root }} />
              {this.generateLabel(AttributConstants.subtitle1,
                styles(theme).whiteColor, strings.tracking.returnTitle)}
            </Button>
            <Button id="logoutButton" onClick={this.props.logout} sx={{...styles(theme).logOut_button}}>
              {strings.dialog.answer.quit}
            </Button>
          </Box>
        </Paper>
      </Box>
    );
  }
}

TrackingHomePage.propTypes = {
  logout: PropTypes.func.isRequired,
  updateGuestActivePage: PropTypes.func.isRequired,
  setStatus: PropTypes.func,
};

export default compose(
  connect(null, mapDispatchToProps)
)(TrackingHomePage);
