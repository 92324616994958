import React, { Component } from 'react';
import { AutoSizer, List } from 'react-virtualized';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';

const overscanRowCount = 3;

class VirtualizedList extends Component {

  componentDidMount() {
    this.list.Grid._scrollingContainer = this.scroll.view;
  }

  handleScroll = e => {
    this.list.Grid._onScroll(e);
  }

  render() {
    return (
      <AutoSizer>
        {({ width, height }) => (
          <Scrollbars ref={node => this.scroll = node} onScroll={this.handleScroll} style={{ height, width }}>
            <List
              {...this.props}
              style={{ overflowX: 'visible', overflowY: 'visible' }}
              width={width}
              height={height}
              rowCount={this.props.count}
              rowHeight={this.props.rowHeight}
              ref={node => this.list = node}
              rowRenderer={this.props.rowRenderer}
              overscanRowCount={overscanRowCount}
            />
          </Scrollbars>
        )}
      </AutoSizer>
    );
  }
}

VirtualizedList.propTypes = {
  rowRenderer: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired
};

export default VirtualizedList;
