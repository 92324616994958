const ETAT_METROLOGIQUE_TYPES = {
  CONFORME: 'Conforme',
  NON_CONFORME: 'Non conforme',
  INDEFINI: 'Indéfini',
  NON_ASSOCIE: 'N/A',
  CONFORME_AVEC_DECLASSEMENT: 'Conforme avec déclassement',
  CONFORME_AVEC_RESTRICTION: 'Conforme avec restriction',
};

const ETATS = {
  UTILISABLE: 'Utilisable',
  DORMANT: 'Dormant',
  INTROUVABLE: 'Introuvable',
  REFORME: 'Réformé',
  EN_PANNE: 'En panne',
  REBUTE: 'Rebuté',
  EN_ATTENTE_DECISION_CLIENT: 'En attente de décision client',
  EN_ATTENTE_CONFIRMATION_METROLOGIQUE: 'En attente de confirmation métrologique',
  EN_ATTENTE_PRISE_EN_CHARGE: 'En attente de prise en charge',
  CEDE_HORS_DU_PARC: 'Cédé hors du parc',
  STOCK_APPELE: 'Stock appelé',
  VERIF_EN_COURS: 'Vérification en cours',
  SOUMIS_A_VERIFICATION: 'OUI',
  PRETE_HORS_PARC: 'Prété hors du parc',
  IN_PROGRESS: 'En cours',
  EN_ATTENTE_DE_LIVRAISON: 'En attente de livraison',
};

const ETATS_HORS_DELAI = {
  UTILISABLE: 'Utilisable',
  INTROUVABLE: 'Introuvable',
  EN_ATTENTE_DECISION_CLIENT: 'En attente de décision client',
  EN_ATTENTE_CONFIRMATION_METROLOGIQUE: 'En attente de confirmation métrologique',
  STOCK_APPELE: 'Stock appelé',
};

const INSTRUMENTCOLORS = {
  HORS_DATE_VERIF: 'HORS_DATE_VERIF',
  INTROUVABLE: 'INTROUVABLE',
  DORMANT: 'DORMANT',
  FIN_LOCATION_PREVISIONNELLE: 'FIN_LOCATION_PREVISIONNELLE',
};

export {
  ETAT_METROLOGIQUE_TYPES,
  ETATS,
  ETATS_HORS_DELAI,
  INSTRUMENTCOLORS
};
