export const InterventionRequestConstants = {
  DOUCHETTE_ICON_PATH: 'M5.48 10.46a14.36 14.36 0 0 0 5.68 1.44C8.15 15.36 6.46 21.68 5.5 22c-.96.32-3.5-2.52-3.5-3a68.48 68.48 0 0 1 3.48-8.54zM21.24 9c.16-1.26-.57-3.87-5.21-5.75 3.56.83 5.78 2.45 5.78 4A3.49 3.49 0 0 1 21.24 9zm-7.07-5.84A8.73 8.73 0 0 0 12 3C7 3 3.89 4.89 3.89 7.25c0 2.36 4.05 3.89 9 3.89a16.35 16.35 0 0 0 6.8-1.25c2.31-3.64-4.84-6.5-5.52-6.73zm4.08 5.1s-6.81 1.81-10.13-.19c1.5.61 6.7 1.24 10-.84 0 0 .36.18.13 1.03z',
  REPAIR_DAYS_TO_ADD: 20,
  STANDARD_CHECK_DAYS_TO_ADD: 5,
  PROVIDER_TYPE: {
    EXTERNAL: 'external',
    INTERNAL: 'internal'
  },
  CARRIER_MODE: {
    OTHER: 'other'
  },
  SUPPORTED_LOCATION_IDS: [2, 3],
  MAX_INSTRUMENTS_TO_ADD: 300,
  TYPES_OF_DISPLAY: {
    SELECTION_DISPLAY: 'SELECTION_DISPLAY',
    CART_DISPLAY_REMOVE: 'CART_DISPLAY_REMOVE',
    RESPONSE_DISPLAY_VALID: 'RESPONSE_DISPLAY_VALID',
    RESPONSE_DISPLAY_UNVALID: 'RESPONSE_DISPLAY_UNVALID'
  },
  OFFSET: 11,
  LIMIT: 21,
  COMMENT_MAX_LINES: 4,
  NB_OF_INSTRUMENTS_TO_GET: 10,
  PDF_DATA: 'data:application/octet-stream;base64,',
  PDF_TITLE: 'intervention.pdf',
  CART_STEP: 0,
  SHIPPING_STEP: 1,
  CONFIRMATION_STEP: 2,
  RESPONSE_STEP: 3,
  AWAITING_TAKEOVER: 'En attente de prise en charge',
  STANDARD_CHECK_ID: 3,
  REPAIR_ID: 12,
  DISPATCH_NOW: 'now',
  DISPATCH_LATER: 'later',
  SERVICE_SHIPPING_PAGE_NAMES: {
    SERVICE_TYPE_NAME: 'serviceTypeName',
    RETURN_DATE: 'returnDate',
    SERVICE_TYPE: 'serviceType',
    RECIPIENT: 'recipient',
    PROVIDER_PHONE: 'providerPhone',
    PROVIDER_MAIL: 'providerMail',
    PROVIDER_FAX: 'providerFax',
    PROVIDER_ADDRESS: 'providerAddress',
    PROVIDER_TYPE: 'providerType',
    PROVIDER: 'provider',
    SERVICE_LOCATION: 'serviceLocation',
    SHIPPER_NAME: 'shipperName',
    SHIPPER_ADDRESS: 'shipperAddress',
    SHIPPER_PHONE: 'shipperPhone',
    SHIPPER_FAX: 'shipperFax',
    SHIPPER_MAIL: 'shipperMail',
    CARRIER: 'carrier',
    CARRIER_MODE: 'carrierMode',
    SHIPPER_ID: 'shipperId'
  },
  SERVICE_TYPE_IDS: {
    CALIBRATION: 2,
    REPAIR: 12,
    VERIF: 3
  },
  INTERVENTION_TYPES: {
    INVENTORY: 38,
    USER_UPDATE: 64
  },
  AWAITING_DISPATCH: 'awaiting_dispatch'
};
