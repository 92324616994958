export let styles = theme => ({
  detailsProfile: {
    marginBottom: theme.spacing(),
    display: 'flex',
    flexFlow: 'row nowrap'
  },
  text: {
    marginLeft: theme.spacing()
  },
  icon: {
    display: 'block',
    marginBottom: 'auto',
    marginTop: 'auto'
  }
});
