import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  header: {
    backgroundColor: theme.palette.lightGrayTheme.main,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: `1px solid ${theme.palette.dialogTheme.header}`
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '95%',
  },
  headerActions: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconButton: {
    paddingTop: "5px",
    paddingBottom: "5px",
    color: theme.palette.veryDarkBlueTheme.main
  },
  scanCount: {
    color: theme.palette.MainMenu.pageTitles
  }
});
