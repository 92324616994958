import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { InterventionRequestConstants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import InstrumentsList from '../InstrumentsList/InstrumentsList';
import { styles } from './CartFilling.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import ScanHeader from '../../../../Scan/ScanHeader/ScanHeader';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import { SearchOutlined } from '@mui/icons-material';
import { theme } from '../../../../../Utils/Theme';
import { Box } from '@mui/material';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    currentPage: state.generalReducer.currentPage
  };
};

const CartFilling = props => {

  const { selectedInstruments, onMobile } = props;
  const { CART_DISPLAY_REMOVE } = InterventionRequestConstants.TYPES_OF_DISPLAY;
  const NB_OF_INSTRUMENTS = strings.interventionRequest.nbOfInstruments(selectedInstruments.length);

  if (onMobile) {
    return (
      <>
        <Box component="div" id="cartFilling" sx={{...styles(theme).mobileCartContent}}>
          <ScanHeader
            onScan={props.handleScan}
            onSelectScanMethod={props.onSelectScanMethod}
            currentScanMethod={props.currentScanMethod}
            currentPage={props.currentPage}
            underLabel={NB_OF_INSTRUMENTS}
          />
          <Box component="div" sx={{...styles(theme).listContainer}} id="cartList">
            <InstrumentsList
              instruments={selectedInstruments}
              typeOfDisplay={CART_DISPLAY_REMOVE}
              addOrRemoveInstruments={props.addOrRemoveInstruments}
            />
          </Box>
        </Box>
        <Fab
          id="openInstrumentList"
          color="primary"
          sx={{...styles(theme).floating_button}}
          onClick={props.handleMobileSearchView}
        >
          <SearchOutlined />
        </Fab>
      </>
    );
  } else {
    return (
      <Card id="cartFilling" sx={{ "&.MuiCard-root": {...styles(theme).pageCard}}} >
        <ScanHeader
          onScan={props.handleScan}
          onSelectScanMethod={props.onSelectScanMethod}
          currentScanMethod={props.currentScanMethod}
          currentPage={props.currentPage}
          underLabel={NB_OF_INSTRUMENTS}
        />
        <Divider sx={{...styles(theme).alt_divider}} />
        <CardContent sx={{ "&.MuiCardContent-root": {...styles(theme).contentCard}}}>
          <InstrumentsList
            instruments={selectedInstruments}
            typeOfDisplay={CART_DISPLAY_REMOVE}
            addOrRemoveInstruments={props.addOrRemoveInstruments}
          />
        </CardContent>
      </Card>
    );
  }
};

CartFilling.propTypes = {
  selectedInstruments: PropTypes.array.isRequired,
  onMobile: PropTypes.bool.isRequired,
};

export default compose(
  connect(mapStateToProps)
)(CartFilling);
