import db from '../Database';
import { logger } from '../../Logger';

const Scan = db.scans.defineClass({
  serverId: Number,
  date: Date,
  method: String,
  isInPerimeter: Boolean,
  page: String,
  analysis: String,
  scannedBarcodeType: String,
  scannedBarcode: String,
  userLogin: String,
  marquage: String
});

Scan.prototype.setValues = function (serverId, date, method, isInPerimeter, page, analysis, scannedBarcodeType, scannedBarcode,
  userLogin, marquage) {
  this.serverId = serverId;
  this.date = date;
  this.method = method;
  this.isInPerimeter = isInPerimeter;
  this.page = page;
  this.analysis = analysis;
  this.scannedBarcodeType = scannedBarcodeType;
  this.scannedBarcode = scannedBarcode;
  this.userLogin = userLogin;
  this.marquage = marquage;
};

Scan.prototype.log = function () {
  logger(this);
};

Scan.prototype.save = function () {
  return db.scans.put(this);
};

export default Scan;
