import dayjs from 'dayjs';
import _ from 'lodash';

import { addBusinessDays } from '../../Utils/DateUtils';
import { InterventionRequestConstants as constants } from '../../Utils/Constants/InterventionRequestConstants';
import {
  UPDATE_ACTIVE_STEP,
  UPDATE_FORM_SERVICE_SHIPPING,
  UPDATE_VALID_INSTRUMENTS,
  INIT_OPEN_CONFIRMATION_DIALOG,
  RESET_INTERVENTION_REQUEST,
  ENABLE_MULTIPLE_INTERVENTION,
  SET_MULTIPLE_INTERVENTIONS,
  OPEN_MULTIPLE_INTERVENTIONS_INSTRUMENTS
} from '../actions/actionTypes';

const initialState = {
  activeStep: {
    index: 0,
    isValid: false,
    multipleInterventionIndex: 0
  },
  serviceShippingPage: {
    // Default service type set to 3
    serviceType: 3,
    serviceTypeName: '',
    // Default service location set to 3
    serviceLocation: 3,
    // Default provider type set to 'external'
    providerType: 'external',
    provider: { name: '', id: '' },
    recipient: '',
    providerAddress: '',
    providerPhone: '',
    providerFax: '',
    providerMail: '',
    shippingDate: dayjs(),
    returnDate: addBusinessDays(dayjs(), constants.STANDARD_CHECK_DAYS_TO_ADD),
    observation: '',
    shipperId: '',
    shipperName: '',
    shipperAddress: '',
    shipperPhone: '',
    shipperFax: '',
    shipperMail: '',
    carrierMode: { id: '', name: '' },
    // Default provider type set to 22
    carrier: 22
  },
  validInstruments: [],
  unvalidInstruments: [],
  pdfDocument: '',
  pdfDocuments: [],
  initOpenConfirmationDialog: false,
  multipleInterventions: [],
  multipleInterventionEnabled: false,
  openMultipleInterventionsInstruments: false
};

const initOpenConfirmationDialog = (state, action) => {
  return { ...state, initOpenConfirmationDialog: action.payload };
};

const updateActiveStep = (state, action) => {
  let newMultiplesInterventions, newServiceShippingPage;
  if (state.multipleInterventionEnabled) {
    // If multiple intervention index increases
    if (state.activeStep.multipleInterventionIndex < state.multipleInterventions.length && state.activeStep.index === 1
      && (action.payload.multipleInterventionIndex === (state.activeStep.multipleInterventionIndex + 1)
        || action.payload.index === state.activeStep.index + 1)) {
      newMultiplesInterventions = 
        state.multipleInterventions.map((interventionData, index) => {
          if (index !== state.activeStep.multipleInterventionIndex) {
            return interventionData;
          }
          return {
            ...interventionData,
            serviceShippingPage: state.serviceShippingPage
          };
        });
      if (!_.isEmpty(state.multipleInterventions[action.payload.multipleInterventionIndex]?.serviceShippingPage)
        && action.payload.multipleInterventionIndex < state.multipleInterventions.length) {
        newServiceShippingPage = 
          state.multipleInterventions[action.payload.multipleInterventionIndex]?.serviceShippingPage;
      } else {
        newServiceShippingPage = {
          ...initialState.serviceShippingPage,
          serviceType: state.serviceShippingPage.serviceType,
          serviceTypeName: state.serviceShippingPage.serviceTypeName,
          provider: state.multipleInterventions[action.payload.multipleInterventionIndex]?.provider,
          carrierMode: state.serviceShippingPage.carrierMode,
          carrier: state.serviceShippingPage.carrier,
        };
      }
      // If multiple intervention index decreases
    } else if (state.activeStep.multipleInterventionIndex > 0 && state.activeStep.index === 1 &&
      action.payload.multipleInterventionIndex === (state.activeStep.multipleInterventionIndex - 1)) {
      newServiceShippingPage = 
        state.multipleInterventions[action.payload.multipleInterventionIndex]?.serviceShippingPage;
    } else if (state.activeStep.index === 2 && action.payload.index === state.activeStep.index - 1) {
      newServiceShippingPage = state.multipleInterventions[state.multipleInterventions.length - 1]?.serviceShippingPage;
    }
  }

  return {
    ...state,
    activeStep: {
      index: action.payload.index,
      isValid: action.payload.isValid,
      multipleInterventionIndex: action.payload.multipleInterventionIndex ?? state.activeStep.multipleInterventionIndex
    },
    multipleInterventions: newMultiplesInterventions ?? state.multipleInterventions,
    serviceShippingPage: newServiceShippingPage ? newServiceShippingPage : state.serviceShippingPage
  };
};

const updateFormServiceShipping = (state, action) => {
  return {
    ...state, serviceShippingPage: {
      ...state.serviceShippingPage,
      [action.payload.name]: action.payload.value
    }
  };
};

const updateValidInstruments = (state, action) => {
  return {
    ...state,
    validInstruments: action.payload.instruments.filter(inst => inst.isValidate),
    unvalidInstruments: action.payload.instruments.filter(inst => !(inst.isValidate)),
    pdfDocument: action.payload.pdfDocument,
    pdfDocuments: action.payload.pdfDocuments
  };
};

const resetInterventionRequest = state => {
  return {
    ...state,
    ...initialState
  };
};

const enableMultipleIntervention = state => {
  const initialIndex = 0;
  const fillProvider = state.activeStep.multipleInterventionIndex === initialIndex 
    && state.multipleInterventions[initialIndex]?.provider?.id !== '';

  return {
    ...state,
    multipleInterventionEnabled: true,
    serviceShippingPage: {
      ...state.serviceShippingPage,
      provider: fillProvider ? state.multipleInterventions[initialIndex]?.provider : state.serviceShippingPage.provider
    }
  };
};

const setMultipleInterventions = (state, action) => {
  return {
    ...state,
    multipleInterventions: action.payload
  };
};

const openMultipleInterventionsInstruments = (state, action) => {
  return {
    ...state,
    openMultipleInterventionsInstruments: action.payload
  };
};

const interventionReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_STEP: return updateActiveStep(state, action);
    case UPDATE_FORM_SERVICE_SHIPPING: return updateFormServiceShipping(state, action);
    case UPDATE_VALID_INSTRUMENTS: return updateValidInstruments(state, action);
    case INIT_OPEN_CONFIRMATION_DIALOG: return initOpenConfirmationDialog(state, action);
    case RESET_INTERVENTION_REQUEST: return resetInterventionRequest(state);
    case ENABLE_MULTIPLE_INTERVENTION: return enableMultipleIntervention(state);
    case SET_MULTIPLE_INTERVENTIONS: return setMultipleInterventions(state, action);
    case OPEN_MULTIPLE_INTERVENTIONS_INSTRUMENTS: return openMultipleInterventionsInstruments(state, action);
    default: return state;
  }
};

export default interventionReducer;
