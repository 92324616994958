import { InterventionRequestConstants } from '../../Utils/Constants/InterventionRequestConstants';
import InstrumentProvider from './InstrumentsProvider';

class InterventionRequestProvider {

  /** Update instruments in locale database after an intervention request creation */
  static updateValidatedInstruments(interventionRequestResponse, isForToday) {
    const instruments = interventionRequestResponse.instruments;
    if (instruments.length > 0) {
      const validateInstruments = instruments.filter(instr => instr.isValidate);
      validateInstruments.forEach(instrument => {
        const dbInstrument = InstrumentProvider.getInstrumentByMarquage(instrument.instrumentID);
        if (isForToday) {
          // Update instrument 'status' to 'awaitingDispatch'
          dbInstrument.modify(inst => inst.etat = InterventionRequestConstants.AWAITING_TAKEOVER);
        }
        // Update instrument 'enCours' to true
        dbInstrument.modify(inst => inst.encoursBool = true);
      });
    }
  }

}

export default InterventionRequestProvider;
