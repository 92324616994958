import React, { Component } from 'react';
import {
  List,
  ListItemButton,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  List as ListIcon,
  Keyboard,
  RadioButtonChecked,
  DateRange
} from '@mui/icons-material';
import { FILTER_FIELDS, FILTER_TYPES } from '../../../Utils/Constants/FilterConstants';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import SimpleDialog from '../../Dialogs/SimpleDialog/SimpleDialog';
import { styles } from './AddFilterFieldDialog.style';
import { theme } from '../../../Utils/Theme';

class AddFilterFieldDialog extends Component {

  state = {
    filterFields: []
  };

  componentDidMount() {
    const fields = [];
    for (const field in FILTER_FIELDS) {
      if (Object.prototype.hasOwnProperty.call(FILTER_FIELDS, field)) {
        fields.push(FILTER_FIELDS[field]);
      }
    }

    this.setState({
      filterFields: fields
    });
  }

  render() {

    return (
      <SimpleDialog
        id="dialog"
        styledDialog={styles(theme).smallDialog}
        open={this.props.open}
        onRefuse={() => this.props.onClose()}
        title={strings.filter.addFilter}
        refuseLabel={strings.dialog.answer.cancel}>
        <List>
          {this.state.filterFields.map(fieldName => {
            return (this.props.exceptFilters.indexOf(fieldName.name) < 0) && (
              <ListItemButton
                key={fieldName.name}
                sx={{...styles(theme).item}}
                id={`leftFilter-${fieldName.name}`}
                onClick={() => this.props.onClose(fieldName.name)}>
                {fieldName.type === FILTER_TYPES.STRING && <Keyboard sx={{...styles(theme).icon}} />}
                {fieldName.type === FILTER_TYPES.LIST && <ListIcon sx={{...styles(theme).icon}} />}
                {fieldName.type === FILTER_TYPES.TOGGLE && <RadioButtonChecked sx={{...styles(theme).icon}} />}
                {fieldName.type === FILTER_TYPES.DATE_RANGE && <DateRange sx={{...styles(theme).icon}} />}
                {strings.filter[fieldName.name]}
              </ListItemButton>
            );
          })}
        </List>
      </SimpleDialog>
    );
  }
}

AddFilterFieldDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  exceptFilters: PropTypes.array,
};

export default AddFilterFieldDialog;
