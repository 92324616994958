import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';

class BannerService {

  static getBanner() {
    return HttpClient.get(routes.getBanner)
      .then(res => Promise.resolve(res.data))
      .catch(() => Promise.resolve(null));
  }
}

export default BannerService;
