import PAGES_IDS from './PagesIDs';
import { LocalizedString as strings } from './LocalizedString';

// POSSIBLE STATUS VALUE FOR A GUIDE
export const STATUSES = {
  PENDING: 0,
  READY: 1,
  INITIATED: 2,
  RUNNING: 3,
  SKIPPED: 4,
  FINISHED: 5
};

// DATABASE COLUMN NAME
export const DATABASE = {
  NAME: 'name',
  STATUS: 'status',
  TYPE: 'type'
};

// TYPE OF LOCK FOR ADVANCED GUIDE
export const LOCK = {
  // Default lock (no lock)
  NONE: 0,
  // Soft lock: Hide 'next' button so user has to do an action to automatically go to next step.
  // The action HAS to be a click on the target, WITHOUT any condition.
  // Moreover, the next step need to be immediately accessible.
  SOFT: 1,
  // Hard lock: Hide 'next' button so user has to do an action to automatically go to next step,
  // where he can't go back to previous. The action can be anything.
  HARD: 2
};

// TYPE OF GUIDE
export const TYPE = {
  SIMPLE: 0,
  ADVANCED: 1
};

// MODE OF RUNNING GUIDE
export const MODE = {
  // Classic mode: use mock and dummy datas. In this mode, nothing is sent to server.
  CLASSIC: 0,
  // Real mode: data might be sent to server.
  REAL: 1
};

// GUIDES LIST
export const GUIDES = [
  {
    id: PAGES_IDS.INVENTORY,
    name: 'inventory',
    type: TYPE.ADVANCED
  },
  {
    id: PAGES_IDS.SCAN,
    name: 'scan',
    type: TYPE.SIMPLE
  },
  {
    id: PAGES_IDS.FEEDBACKS,
    name: 'feedback',
    type: TYPE.ADVANCED
  },
  {
    id: PAGES_IDS.INSTRUMENTS,
    name: 'instruments',
    type: TYPE.ADVANCED,
    disableMessageOnSkip: true
  },
  {
    id: PAGES_IDS.INTERVENTION_REQUEST,
    name: 'intervention-request',
    type: TYPE.ADVANCED
  },
  {
    id: PAGES_IDS.HISTORY,
    name: 'scanHistory',
    type: TYPE.SIMPLE
  },
  {
    id: strings.filter.filter_id,
    name: 'filter',
    type: TYPE.ADVANCED,
    disableMessageOnSkip: true
  },
  {
    id: strings.filter.preference_filter_id,
    name: 'preference-filter',
    type: TYPE.ADVANCED,
    disableMessageOnSkip: true
  },
  {
    id: PAGES_IDS.FOLLOWED_INSTRUMENTS,
    name: 'notification',
    type: TYPE.SIMPLE
  },
  {
    id: PAGES_IDS.PREFERENCES,
    name: 'preference',
    type: TYPE.SIMPLE
  },
  {
    id: PAGES_IDS.INTERVENTION_CREATION,
    name: 'intervention',
    type: TYPE.ADVANCED
  },
  {
    id: PAGES_IDS.GUEST,
    name: 'guest',
    type: TYPE.ADVANCED,
    choice: true
  },
  {
    id: PAGES_IDS.HOME,
    name: 'home',
    type: TYPE.ADVANCED,
    disableMessageOnSkip: true
  }
];

export const PAGES_WITH_FILTER = [
  PAGES_IDS.INSTRUMENTS,
  PAGES_IDS.INVENTORY,
  PAGES_IDS.INTERVENTION_REQUEST,
  PAGES_IDS.INTERVENTION_CREATION,
];
