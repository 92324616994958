import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  typography: {
  },
  // HOW TO USE palette colors
  // * In style.js files:
  // Use theme.palette.blueTheme.main
  // * Using color attribute in material component:
  // <Button color="primary"></Button>
  palette: {
    // Blue
    primary: { main: '#3b5f83', light: '#4B79AC', hover: '#3B5F83DE' },
    secondary: { main: '#fafafa', dark: '#878787', secondary: '#F5F5F5' },
    tertiary: { main: '#3e7ec7' },
    // Main background color
    primaryBg: { main: '#f2f2f2' },
    blackTheme: { main: '#000', transparent: 'rgba(0, 0, 0, 0.54)' },
    blueTheme: { main: '#1565c0', hover: '#1565c0DE' },
    orangeTheme: { main: '#ef9538', disabled: '#EF953842', hover: '#EF9538DE' },
    greenTheme: { main: '#009688', dark: '#00635a', hover: '#009688DE' },
    purpleTheme: { main: '#673ab7' },
    lightGrayTheme: { main: '#ebeff2' },
    veryDarkBlueTheme: { main: '#153759', secondary: 'rgba(3, 33, 46, 0.62)' },
    redTheme: { main: '#be0000' },
    greyTheme: { main: '#909191', lighter: '#b9b9b9', dark: '#646464' },
    validationGreenTheme: { main: '#66bb6a' },
    error: { main: '#be0000' },
    transparent: 'rgb(0,0,0,0)',

    MainMenu: {
      // used as the top menu background,
      topMenu: '#253755',
      // used as the menu background,
      menu: '#1b2840',
      // used as the page titles,
      pageTitles: '#03212e',
      // used in the instruments option menu
      instrumentsMenuOption: 'rgba(21, 101, 192, 0.26)',
      // used in the interventions option menu
      interventionMenuOption: '#e4892f',
      // used in the inventory option menu
      inventoryMenuOption: '#067975',
      // used in other options from menu
      otherMenuOptions: '#3a4c70',
    },
    instrumentCardText: '#05384f',

    auth: {
      link: {
        light: '#5C6BC0',
        main: '#283593'
      },
      version: '#424242'
    },

    sync: {
      logs: {
        light: '#9e9e9e',
        main: '#212121'
      }
    },

    toasts: {
      success: '#418241',
      error: '#C62828',
      warning: '#D84315'
    },

    dialogTheme: { header: '#3b5f83', background: 'rgb(235,239,242)' },
    mainPageBackground: '#f1f4f8',
    pureWhite: '#fff',
    intervention: {
      formText: '#153759',
      formTextDisabled: 'rgba(0, 0, 0, 0.38)',
      inputLabel: 'rgba(21, 55, 89, 0.74)',
      mobile_divider: 'rgba(0,0,0,0.12)'
    }
  }
});
const { breakpoints } = defaultTheme;

export let theme = {
  ...defaultTheme
};

export let mobileScanPageTheme = {
  ...defaultTheme,
  overrides: {
    MuiSvgIcon: {
      root: {
        [breakpoints.only('xs')]: {
          fontSize: 22
        }
      }
    },
    MuiTypography: {
      h6: {
        '@media (orientation: landscape)': {
          [breakpoints.only('md')]: {
            fontSize: '1.2rem'
          }
        },
        [breakpoints.only('xs')]: {
          fontSize: '1.2rem'
        }
      },
      body2: {
        '@media (orientation: landscape)': {
          [breakpoints.only('md')]: {
            fontSize: '0.7rem',
            lineHeight: 'unset'
          }
        },
        [breakpoints.only('xs')]: {
          fontSize: '0.7rem',
          lineHeight: 'unset'
        }
      },
      body1: {
        '@media (orientation: landscape)': {
          [breakpoints.only('md')]: {
            fontSize: '0.775rem',
            lineHeight: 'unset'
          }
        },
        [breakpoints.only('xs')]: {
          fontSize: '0.775rem',
          lineHeight: 'unset'
        }
      }
    },
    MuiIconButton: {
      root: {
        '@media (orientation: landscape)': {
          [breakpoints.only('md')]: {
            padding: "6px"
          }
        },
        [breakpoints.only('xs')]: {
          padding: "6px"
        }
      }
    }
  }
};
