import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import InstrumentsList from '../CartStep/InstrumentsList/InstrumentsList';
import { InterventionRequestConstants } from '../../../../Utils/Constants/InterventionRequestConstants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SummaryCard from './SummaryCard/SummaryCard';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { styles } from './ConfirmationStep.style';
import InterventionRequestHelper from '../../../../Helpers/InterventionRequestHelper';
import InterventionRequestService from '../../../../Providers/WebService/InterventionRequestService';
import ConfirmRequestDialog from './ConfirmRequestDialog';
import Paper from '@mui/material/Paper';
import {
  updateValidInstruments,
  updateActiveStep,
  initOpenConfirmationDialog,
  updateToolbarInfo,
  unlock
} from '../../../../store/actions/exportActions';
import PropTypes from 'prop-types';
import { SnackbarMessages } from '../../../../Utils/Constants/SnackbarMessages';
import { ToolbarActionsConstants } from '../../../../Utils/Constants/ToolbarActionsConstants';
import BuildIcon from '@mui/icons-material/Build';
import LoadingSpinner from '../../../Utils/LoadingSpinner/LoadingSpinner';
import { STATUSES } from '../../../../Utils/Constants/GuideConstants';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../../Utils/Theme';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    validInstruments: state.interventionReducer.validInstruments,
    unvalidInstruments: state.interventionReducer.unvalidInstruments,
    pdfDocument: state.interventionReducer.pdfDocument,
    activeStep: state.interventionReducer.activeStep,
    isOpenConfirmationDialogInitiated: state.interventionReducer.initOpenConfirmationDialog,
    onMobile: state.generalReducer.onMobile,
    status: state.guideReducer.status,
    multipleInterventions: state.interventionReducer.multipleInterventions,
    multipleInterventionEnabled: state.interventionReducer.multipleInterventionEnabled
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    updateValidInstruments: value => dispatch(updateValidInstruments(value)),
    updateActiveStep: value => dispatch(updateActiveStep(value)),
    initOpenConfirmationDialog: value => dispatch(initOpenConfirmationDialog(value)),
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
    unlock: () => dispatch(unlock())
  };
};

class ConfirmationStep extends Component {

  state = {
    dialogOpen: false,
    loading: false
  };

  componentDidMount() {
    const { activeStep } = this.props;
    this.props.updateActiveStep({ index: activeStep.index, isValid: this.isValid() });
    if (this.props.onMobile) {
      this.props.updateToolbarInfo({
        title: strings.interventionRequest.confirmationTitle,
        icon: (<BuildIcon />),
        actions: []
      });
    } else {
      this.props.updateToolbarInfo({
        title: '',
        icon: (null),
        actions: []
      });
    }

    if (this.props.response && this.props.onMobile) {
      this.props.updateToolbarInfo({
        title: strings.interventionRequest.responseTitle,
        icon: (<BuildIcon />),
        actions: [
          ToolbarActionsConstants.DOWNLOAD_PDF,
          ToolbarActionsConstants.NEW_INTERVENTION_REQUEST,
          ToolbarActionsConstants.INTERVENTIONS_HOME
        ]
      });
    }
  }

  isValid = () => this.props.selectedInstruments.length > 0;

  componentDidUpdate(prevProps) {
    const {
      selectedInstruments, activeStep,
      isOpenConfirmationDialogInitiated
    } = this.props;

    if (selectedInstruments !== prevProps.selectedInstruments && selectedInstruments.length === 0) {
      this.props.updateActiveStep({ index: activeStep.index, isValid: false });
    } else {
      if (isOpenConfirmationDialogInitiated !== prevProps.isOpenConfirmationDialogInitiated
        && isOpenConfirmationDialogInitiated) {
        this.props.initOpenConfirmationDialog(false);
        this.handleConfirmDialog();
      }
    }
  }

  submitRequest = () => {
    this.setState({ loading: true });
    const { selectedInstruments, serviceShippingPage, activeStep } = this.props;
    const { interventionRequestError } = SnackbarMessages;

    const requestData = InterventionRequestHelper
      .createNewInterventionRequestJson(serviceShippingPage, selectedInstruments);

    // IF WE'RE IN A GUIDE, FAKE THE PROCESS
    if (this.props.status === STATUSES.RUNNING) {
      const fakeData = {
        instruments: selectedInstruments.map(instrument => {
          return { ...instrument, isValidate: true, instrumentID: instrument.marquage };
        })
      };
      this.props.updateValidInstruments(fakeData);
      this.props.updateActiveStep({ index: activeStep.index + 1, isValid: false });
      this.setState({ loading: false });
      this.props.unlock();
      return;
    }

    InterventionRequestService.createInterventionRequest(requestData)
      .then(returnData => {

        this.props.updateValidInstruments(returnData);
        this.props.updateActiveStep({ index: activeStep.index + 1, isValid: false });
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        enqueueSnackbar(interventionRequestError.msg, interventionRequestError.type);
      });
  };

  submitMultipleRequests = async () => {
    this.setState({ loading: true });
    const { multipleInterventions, activeStep } = this.props;
    const { interventionRequestError } = SnackbarMessages;

    let requestDatas = multipleInterventions.map(intervention => {
      const instruments = intervention.instruments
        .filter(i => this.props.selectedInstruments.map(s => s.marquage).includes(i.marquage));
      return instruments.length > 0
        ? InterventionRequestHelper.createNewInterventionRequestJson(intervention.serviceShippingPage, instruments)
        : null;
    });

    requestDatas = requestDatas.filter(data => data !== null);

    const createInterventionRequests = requestDatas.map(requestData => {
      return InterventionRequestService.createInterventionRequest(requestData)
        .then(returnData => {
          return returnData;
        })
        .catch(() => {
          return Promise.reject();
        });
    });

    let results;
    try {
      results = await Promise.all(createInterventionRequests);
    } catch (e) {
      this.setState({ loading: false });
      enqueueSnackbar(interventionRequestError.msg, interventionRequestError.type);
    }

    let mergedInstruments = [];
    const pdfDocuments = [];
    results.forEach(result => {
      mergedInstruments = mergedInstruments.concat(result.instruments);
      pdfDocuments.push(result.pdfDocument);
    });
    const customResult = {
      instruments: mergedInstruments,
      pdfDocuments: pdfDocuments
    };
    this.props.updateValidInstruments(customResult);
    this.props.updateActiveStep({ index: activeStep.index + 1, isValid: false });
    this.setState({ loading: false });
  }

  handleConfirmDialog = () => {
    this.setState(state => (
      { dialogOpen: !state.dialogOpen }
    ));
  };

  render() {

    const { response, validInstruments, selectedInstruments, unvalidInstruments, onMobile } = this.props;
    const { nbOfValidInstruments, nbOfUnvalidInstruments, nbOfInstruments } = strings.interventionRequest;
    const { RESPONSE_DISPLAY_VALID, RESPONSE_DISPLAY_UNVALID, CART_DISPLAY_REMOVE }
    = InterventionRequestConstants.TYPES_OF_DISPLAY;
    const NB_SELECTED_INSTRUMENTS = nbOfInstruments(selectedInstruments.length);
    if (response) {

      const valid = validInstruments.map(item =>
        selectedInstruments.find(inst => inst.marquage === item.instrumentID));
      const unvalid = unvalidInstruments.map(item =>
        selectedInstruments.find(inst => inst.marquage === item.instrumentID));

      const NB_VALID_INSTRUMENTS = nbOfValidInstruments(valid.length);
      const NB_UNVALID_INSTRUMENTS = nbOfUnvalidInstruments(unvalid.length);
      return (
        <Paper elevation={2} id="response" sx={{ "&.MuiPaper-root": {...styles(theme).stepper_pages_paper} }}>
          {this.state.loading && <LoadingSpinner />}
          <Grid container spacing={onMobile ? 4 : 5} sx={{...styles(theme).noHorizontalScroll}}>
            <Grid id="validInstruments" item xs={12} md={6}>
              <Box component="label"
                sx={{...styles(theme).instrumentsNbLabel}}
              >{NB_VALID_INSTRUMENTS}</Box>
              <InstrumentsList
                instruments={valid}
                typeOfDisplay={RESPONSE_DISPLAY_VALID}
              />
            </Grid>
            <Grid id="invalidInstruments" item xs={12} md={6}>
              <Box component="label"
                sx={{...styles(theme).instrumentsNbLabel}}>{NB_UNVALID_INSTRUMENTS}</Box>
              <InstrumentsList
                instruments={unvalid}
                typeOfDisplay={RESPONSE_DISPLAY_UNVALID} />
            </Grid>
          </Grid>
        </Paper>
      );
    } else {
      return (
        <Paper elevation={2} id="confirmation" sx={{ "&.MuiPaper-root": {...styles(theme).stepper_pages_paper} }}>
          {this.state.loading && <LoadingSpinner />}
          <Grid container spacing={onMobile ? 1 : 5} sx={{...styles(theme).noHorizontalScroll}}>
            <Grid item xs={12} md={6}>
              <SummaryCard selectedInstruments={this.props.selectedInstruments} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box component="label"
                sx={{...merge({}, styles(theme).top_label, styles(theme).instrumentsNbLabel)}}>{NB_SELECTED_INSTRUMENTS}</Box>
              <Paper elevation={2} id="cart" sx={{...styles(theme).container}}>
                <InstrumentsList
                  instruments={selectedInstruments}
                  addOrRemoveInstruments={this.props.addOrRemoveInstruments}
                  typeOfDisplay={CART_DISPLAY_REMOVE} />
              </Paper>
            </Grid>
          </Grid>
          <ConfirmRequestDialog
            id="dialog"
            submitRequest={() => { this.props.multipleInterventionEnabled
              ? this.submitMultipleRequests() : this.submitRequest(); }}
            open={this.state.dialogOpen}
            handleDialog={this.handleConfirmDialog}
          />
        </Paper>
      );
    }
  }
}

ConfirmationStep.propTypes = {
  selectedInstruments: PropTypes.array.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  unvalidInstruments: PropTypes.array.isRequired,
  validInstruments: PropTypes.array.isRequired,
  response: PropTypes.bool,
  updateValidInstruments: PropTypes.func.isRequired,
  serviceShippingPage: PropTypes.object.isRequired,
  activeStep: PropTypes.shape({
    index: PropTypes.number,
    isValid: PropTypes.bool
  }).isRequired,
  isOpenConfirmationDialogInitiated: PropTypes.bool,
  initOpenConfirmationDialog: PropTypes.func.isRequired,
  onMobile: PropTypes.bool.isRequired,
  updateToolbarInfo: PropTypes.func.isRequired,
  addOrRemoveInstruments: PropTypes.func,
  status: PropTypes.number,
  unlock: PropTypes.func,
  multipleInterventionEnabled: PropTypes.bool,
  multipleInterventions: PropTypes.array,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ConfirmationStep);
