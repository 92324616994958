import React from 'react';
import { Card, CardHeader, CardContent, Checkbox, Button, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { LocalizedString } from '../../../Utils/Constants/LocalizedString';
import { styles } from './FilterFieldCard.style';
import { FILTER_FIELDS, FILTER_TYPES } from '../../../Utils/Constants/FilterConstants';
import { theme } from '../../../Utils/Theme';

const FilterFieldCard = props => {


  return (
    <Card id={`card-${props.fieldName}`} sx={{...styles(theme).card}} >
      <CardHeader
        title={LocalizedString.filter[props.fieldName]}
        action={
          <>
            {(FILTER_FIELDS[props.fieldName].type === FILTER_TYPES.STRING
            && Object.prototype.hasOwnProperty.call(FILTER_FIELDS[props.fieldName], 'filterOnEquality')) &&
              <Box component="div" id='strictlyEqual' sx={{...styles(theme).checkboxContainer}}>
                <Box component="p" sx={{...styles(theme).checkboxLabel}}>{LocalizedString.filter.strictlyEqual}</Box>
                <Checkbox
                  color="secondary"
                  sx={{ "&.MuiCheckbox-root": {...styles(theme).checkbox} }}
                  checked={props.checked}
                  onChange={() => props.onCheck()}
                />
              </Box>
            }
            <Button id={`filterCardCloseIcon-${props.fieldName}`} onClick={() => props.onRemove(props.fieldName)}>
              <Close sx={{...styles(theme).button}} />
            </Button>

          </>
        }
        sx={{ "& .MuiCardHeader-title": {...styles(theme).headerText}, "& .MuiCardHeader-action": {...styles(theme).headerButtons}, ...styles(theme).cardHeader }}
      />
      <CardContent sx={{...styles(theme).cardContent}}>
        {props.children}
      </CardContent>
    </Card>
  );
};

FilterFieldCard.propTypes = {
  fieldName: PropTypes.string,
  children: PropTypes.element,
  onRemove: PropTypes.func,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
};

export default FilterFieldCard;
