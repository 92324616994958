import sharedStyles from '../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.pureWhite,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
    }
  }
});
