import sharedStyles from '../../Utils/SharedStyles';

export const styles = theme => ({
  ...sharedStyles,
  pageContent: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  loadingMessage: {
    textAlign: 'center'
  },
  interventionList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr));',
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    }
  },
  scrollArea: {
    height: '100%',
    display: 'flex',
    overflowY: 'visible'
  },
  buttons: {
    display: 'grid',
    marginTop: "4px"
  },
  button: {
    gridRow: '1',
    margin: "8px",
    [theme.breakpoints.down('md')]: {
      margin: "4px",
      marginTop: "8px",
      fontSize: "12px"
    }
  },
  buttonIcon: {
    marginRight: "4px"
  },
  listContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  pdfIcon: {
    marginRight: "4px"
  }
});

