import {
  ADD_INSTRUMENT_FAVORITE,
  REMOVE_INSTRUMENT_FAVORITE,
  UPDATE_INSTRUMENT_FAVORITE,
  TOGGLE_EDIT_INSTRUMENTS_DIALOG,
  INSTRUMENT_LIST_NEEDS_UPDATE,
  INSTRUMENT_NEEDS_REFRESH
} from '../actions/actionTypes';

const initialState = {
  favorites: [],
  editInstrumentsDialogOpened: false,
  shouldUpdateInstrumentList: false,
  instrumentMarquageToRefresh: null,
};

const updateFavorite = (state, action) => {
  return { ...state, favorites: action.payload };
};

const addFavorite = (state, action) => {
  return {
    ...state, favorites: state.favorites.concat(action.payload)
  };
};

const removeFavorite = (state, action) => {
  return {
    ...state, favorites: state.favorites.filter(value => value !== action.payload)
  };
};

const toggleEditInstrumentsDialog = (state, action) => {
  return {
    ...state, editInstrumentsDialogOpened: action.payload
  };
};

const instrumentListNeedsUpdate = (state, action) => {
  return {
    ...state, shouldUpdateInstrumentList: action.payload
  };
};

const setInstrumentMarquageToRefresh = (state, action) => {
  return {
    ...state, instrumentMarquageToRefresh: action.payload
  };
};

const instrumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INSTRUMENT_FAVORITE: return updateFavorite(state, action);
    case ADD_INSTRUMENT_FAVORITE: return addFavorite(state, action);
    case REMOVE_INSTRUMENT_FAVORITE: return removeFavorite(state, action);
    case TOGGLE_EDIT_INSTRUMENTS_DIALOG: return toggleEditInstrumentsDialog(state, action);
    case INSTRUMENT_LIST_NEEDS_UPDATE: return instrumentListNeedsUpdate(state, action);
    case INSTRUMENT_NEEDS_REFRESH: return setInstrumentMarquageToRefresh(state, action);
    default: return state;
  }
};

export default instrumentReducer;
