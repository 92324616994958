import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import sharedStyles from '../../Utils/SharedStyles';

const { FULL_PERCENTAGE, FULL_VIEWPORT_WIDTH, CENTER_MARGIN } = globalConstants.style;

export let styles = theme => ({
  ...sharedStyles(theme),
  syncPage_main: {
    height: FULL_PERCENTAGE,
    width: FULL_VIEWPORT_WIDTH,
    padding: '3% 0',
    // Fix IE 11 issue.
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
  },
  synchro_main: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'unset'

  },
  syncPage_container: {
    [theme.breakpoints.only('xs')]: {
      width: '87vw'
    },
    // desktop half-window and tablets portrait
    [theme.breakpoints.only('sm')]: {
      width: '55vw'
    },
    margin: CENTER_MARGIN,
    width: '35vw',
    justifyContent: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    textAlign: 'center',
  },
  syncPage_logo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    width: '20%',
    [theme.breakpoints.only('xs')]: {
      width: '30%'
    },
  },
  syncPage_counter: {
    fontSize: '1.1em',
    fontWeight: '500'
  },
  syncPage_logs: {
    fontSize: '0.9em',
    lineHeight: ' 1.1',
    color: theme.palette.sync.logs.light
  },
  syncPage_logs_current: {
    color: theme.palette.sync.logs.main
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly'
  },
  reset_button: {
    margin: "4px",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.secondary.main
  },
  retry_button: {
    margin: "4px"
  },
  tabletCardWidth: {
    [theme.breakpoints.up('lg')]: {
      width: '80vw',
    }
  },
  '@media (orientation: landscape)': {
    syncPage_main: {
      [theme.breakpoints.down('md')]: {
        // fix for centering issue on mobile landscape
        height: 'auto'
      }
    },
    syncPage_container: {
      [theme.breakpoints.only('md')]: {
        // landscape tablet and desktop window
        width: '45vw'
      },
    },
    syncPage_logo: {
      [theme.breakpoints.down('md')]: {
        width: '30%'
      },
    }
  }
});
