import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styles } from './BorrowingPage.style';
import {
  Typography,
  Paper,
  List,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material';
import StaticField from '../../InstrumentCard/StaticField/StaticField';
import DynamicField from '../../InstrumentCard/DynamicField/DynamicField';
import ScanHeader from '../../Scan/ScanHeader/ScanHeader';
import Scannable from '../../Utils/Scannable/Scannable';
import { ScanMethod } from '../../../Utils/Constants/ScanMethod';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { ScanPages } from '../../../Utils/Constants/ScanPage';
import ScannedInstrumentItem from './ScannedInstrument/ScannedInstrumentItem';
import HttpClient from '../../../Providers/HttpClient';
import routes from '../../../Utils/Routes';
import InstrumentCard from '../../InstrumentCard/InstrumentCard';
import { ArrowBack } from '@mui/icons-material';
import InterventionHelper from '../../../Helpers/InterventionHelper';
import { SnackbarMessages } from '../../../Utils/Constants/SnackbarMessages';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { unlock } from '../../../store/actions/exportActions';
import dayjs from 'dayjs';
import { InterventionRequestConstants } from '../../../Utils/Constants/InterventionRequestConstants';
import { logger } from '../../../Utils/Logger';
import { STATUSES, MODE } from '../../../Utils/Constants/GuideConstants';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    guideStatus: state.guideReducer.status,
    guideMode: state.guideReducer.mode
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    unlock: data => dispatch(unlock(data))
  };
};

export class BorrowingPage extends Component {


  state = {
    scannedInstruments: [],
    selectedInstruments: [],
    currentScanMethod: ScanMethod.QUICKSCAN,
    instrumentCardOpen: false,
    selectedInstrument: {},
    returnDate: dayjs(),
    isLoading: false,
    locationIsEmpty: false,
    fullName: null,
    croppedName: null,
    location: '',
  };

  componentDidMount() {
    this.setCroppedName();
    if (this.props.isReturn) {
      const url = HttpClient.buildUrl(routes.getBorrowedInstruments, [this.props.userInformations.email], []);
      HttpClient.get(url).then(res => {
        this.setState({
          scannedInstruments: this.state.scannedInstruments.concat(res.data),
          selectedInstruments: this.state.selectedInstruments.concat(res.data)
        });
      }).catch(logger);
    }
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    this.props.checkTokenValidity();
    if ((this.props.lastScannedInstrument
      && prevProps.lastScannedInstrument !== this.props.lastScannedInstrument)
      && (this.indexOf(this.state.scannedInstruments, this.props.lastScannedInstrument.marquage)) < 0) {
      this.setState({
        scannedInstruments: this.state.scannedInstruments.concat(this.props.lastScannedInstrument),
        selectedInstruments: this.state.selectedInstruments.concat(this.props.lastScannedInstrument)
      });
      this.props.unlock('#instrumentsContainer');
    }
  }

  onScan = barcode => {
    const data = { barcode, scanType: this.state.currentScanMethod, page: ScanPages.SCAN };
    this.props.processScan(data);
  }

  onSelectScanMethod = method => {
    this.setState({ currentScanMethod: method });
  };

  indexOf = (list, marquageToFind) => list.findIndex(instrument => instrument.marquage === marquageToFind);

  onInstrumentSelected = instrument => {
    const index = this.indexOf(this.state.selectedInstruments, instrument.marquage);

    if (index < 0) {
      this.setState({
        selectedInstruments: this.state.selectedInstruments.concat(instrument)
      });
    } else {
      this.setState({
        selectedInstruments: this.state.selectedInstruments.filter(inst => inst.marquage !== instrument.marquage)
      });
    }
  }

  onValidate = async () => {
    if (this.state.location.trim() === '') {
      this.setState({ locationIsEmpty: true });
      enqueueSnackbar(SnackbarMessages.emptyLocation.msg, SnackbarMessages.emptyLocation.type);
    } else if (this.state.selectedInstruments.length === 0) {
      enqueueSnackbar(SnackbarMessages.stepperValidationError[0].msg,
        SnackbarMessages.stepperValidationError[0].type);
    } else {
      const customComment = this.props.isReturn
        ? strings.traceability.returnComment(dayjs().format(strings.general.dateFormat))
        : strings.traceability.borrowComment(dayjs(this.state.returnDate).format(strings.general.dateFormat));

      // if returning, get the recipients of the instruments without duplicates
      const recipientsToSetAsUser = this.props.isReturn
        ? new Set(this.state.scannedInstruments.map(inst => inst.destinataire))
        : [];

      // if all instruments share the same recipient, set it back as the user for all instruments when returning
      const user = this.props.isReturn ? recipientsToSetAsUser.values().next().value : this.state.fullName;

      let interventions;
      // if instruments have different recipients
      if (recipientsToSetAsUser.size > 1) {
        interventions = InterventionHelper.formatMultipleInterventionsFromInstruments(this.state.selectedInstruments,
          true, true, this.state.fullName, this.state.location, customComment, recipientsToSetAsUser);
      } else {
        interventions = InterventionHelper.formatInterventionsFromInstruments(this.state.selectedInstruments,
          true, true, this.state.fullName, this.state.location, customComment);
      }

      // if the tutorial is on
      if (this.props.guideStatus === STATUSES.RUNNING && this.props.guideMode === MODE.CLASSIC) {
        this.props.onResult({
          result: {
            inventoryResult: {
              updated: this.state.selectedInstruments, notUpdated: []
            }
          },
          isReturn: this.props.isReturn
        });

      } else {
        let results = [];
        // if instruments have different recipients
        if (recipientsToSetAsUser.size > 1) {
          await Promise.all(interventions.map(interventionsWithSpecificRecipient =>
            InterventionHelper.createManyInterventions(interventionsWithSpecificRecipient.interventions,
              this.state.location, interventionsWithSpecificRecipient.recipient, this.state.fullName,
              InterventionRequestConstants.INTERVENTION_TYPES.USER_UPDATE, this.props.isReturn)
              .then(result => {
                results.push(result);
              }))
          );
          this.props.onResult({ results, isReturn: this.props.isReturn });
        } else {
          InterventionHelper.createManyInterventions(interventions, this.state.location, user, this.state.fullName,
            InterventionRequestConstants.INTERVENTION_TYPES.USER_UPDATE, this.props.isReturn)
            .then(result => {
              this.props.onResult({ result, isReturn: this.props.isReturn });
            });
        }
      }
      this.setState({ isLoading: true }, () => this.props.unlock());
    }
  }

  onInstrumentClick = instrument => {
    this.setState({ instrumentCardOpen: true, selectedInstrument: instrument });
  }

  setCroppedName = () => {
    // maximum 20 chars but we remove 3 because of ellipsis
    const maxChars = 17;
    let fullName = `${this.props.userInformations.firstName} ${this.props.userInformations.lastName}`;
    let stateUpdate = {
      fullName,
    };
    if (fullName.length > maxChars) {
      const croppedName = `${fullName.substr(0, maxChars)}...`;
      stateUpdate = {
        fullName,
        croppedName,
      };
    }
    this.setState(stateUpdate);
  }

  render() {
    const buttonText = this.props.isReturn ? strings.traceability.return : strings.traceability.borrow;

    return <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            id="backButton"
            sx={{...styles(theme).menuButton}}
            color="inherit"
            aria-label="Menu"
            onClick={() => this.props.onBackPressed()}
            size="large">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" color="inherit" sx={{...styles(theme).grow}}>
            {this.props.isReturn ? strings.traceability.return : strings.traceability.borrow}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="div" sx={{...styles(theme).root}} >
        <InstrumentCard
          id="instrumentCard"
          open={this.state.instrumentCardOpen}
          instrument={this.state.selectedInstrument}
          onClose={() => { this.setState({ instrumentCardOpen: false }); }} />
        <Box component="div" id="content" sx={{...styles(theme).content}}>
          <div id="instrumentsContainer" style={{ outline: 'none' }}>
            <Paper elevation={2} sx={{...styles(theme).scanPaper}}>
              <ScanHeader
                id="scanHeader"
                onScan={this.onScan}
                onSelectScanMethod={this.onSelectScanMethod}
                currentScanMethod={this.state.currentScanMethod} />
              <List sx={{...styles(theme).instList}} id="instrumentsList">
                {
                  this.state.scannedInstruments.map(instrument => {
                    return <ScannedInstrumentItem
                      key={instrument.marquage}
                      onClick={() => this.onInstrumentClick(instrument)}
                      instrument={instrument}
                      selected={this.indexOf(this.state.selectedInstruments, instrument.marquage) >= 0}
                      onSelected={this.onInstrumentSelected} />;
                  })
                }
              </List>
            </Paper>
          </div>
          <Box component="div" sx={{...styles(theme).rightPanel}}>
            <Paper elevation={2} id="infosPanel" sx={{...styles(theme).infoPaper}}>
              <StaticField
                name={strings.traceability.user}
                value={this.state.croppedName || this.state.fullName}
                tooltipTitle={this.state.fullName}
              />
              <DynamicField
                error={this.state.locationIsEmpty}
                isEditTextChecked
                name={strings.traceability.location}
                value={this.state.location}
                getValue={event => this.setState({ location: event.target.value })} />
              {this.props.isReturn && <Typography variant="body2">{strings.traceability.locationHint}</Typography>}
              {!this.props.isReturn &&
                <DynamicField
                  isEditTextChecked
                  isDate
                  name={strings.traceability.expectedReturnDate}
                  value={this.state.returnDate}
                  minDate={dayjs()}
                  getValue={value => this.setState({ returnDate: value })} />}
            </Paper>
            {this.state.isLoading ?
              <CircularProgress />
              :
              <Button
                id="validateButton"
                sx={{...styles(theme).validButton}}
                variant="contained"
                onClick={this.onValidate}
                color="primary">{buttonText}</Button>}
          </Box>
        </Box>
      </Box>
    </>;
  }
}

BorrowingPage.propTypes = {
  userInformations: PropTypes.object,
  processScan: PropTypes.func.isRequired,
  lastScannedInstrument: PropTypes.object,
  isReturn: PropTypes.bool,
  onBackPressed: PropTypes.func,
  onResult: PropTypes.func,
  checkTokenValidity: PropTypes.func.isRequired,
  guideMode: PropTypes.number,
  guideStatus: PropTypes.number,
  unlock: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Scannable(BorrowingPage, true));
