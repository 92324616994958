import { RoleConstants } from '../Utils/Constants/RoleConstants';
import _ from 'lodash';

class RoleHelper {

  static getUserInstrumentAccess(user, instrument) {
    let accessLevel = RoleConstants.NO_ACCESS;

    if (!user || !user.roles) {
      return accessLevel;
    }

    const roles = user.roles;
    const _rightNotTGS =
      _.uniq(
        roles
          .filter(r => !r.value.startsWith(RoleConstants.TGS_BASE_RIGHTS))
          .map(role => role.financialUnit.toLowerCase())
      );

    for (let i = 0; i < roles.length; i++) {
      const newAccessLevel = RoleHelper.getInstrumentAccessThroughUnitOfRole(instrument, roles[i], _rightNotTGS);
      accessLevel = newAccessLevel > accessLevel ? newAccessLevel : accessLevel;
    }

    return accessLevel;
  }

  static getInstrumentAccessThroughUnitOfRole(instrument, role, _rightNotTGS) {
    let accessLevel = RoleConstants.NO_ACCESS;

    /* For instrument own by THALES GLOBAL SERVICES or Externe Loueur,
     * the user must have the role "Droits de base sur parc TGS"
     * and the instrument Unit must contains one the other role (not "Droits de base sur parc TGS")
     * FinancialUnit value.
     */
    if ((instrument.proprietaire.includes(RoleConstants.TGS) ||
      instrument.proprietaire.includes(RoleConstants.EXTERNAL_RENTER)) &&
      role.value.startsWith(RoleConstants.TGS_BASE_RIGHTS)) {
      const checkInstrumentUnit = _rightNotTGS.filter(r => instrument.unit.toLowerCase().includes(r));
      if (checkInstrumentUnit.length > 0) {
        accessLevel = RoleConstants.C_LEVEL;
      }
    } else if (
      instrument.unit &&
      instrument.unit.toLowerCase().includes(role.financialUnit.toLowerCase()) &&
      instrument.emplacementReference &&
      instrument.emplacementReference.toLowerCase().includes(role.geographicalUnit.toLowerCase()) &&
      instrument.proprietaire &&
      instrument.proprietaire.toLowerCase().includes(role.organizationalUnit.toLowerCase())
    ) {
      const roleLowerCase = role.value.toLowerCase();
      if (
        roleLowerCase.includes(RoleConstants.ENTITY_ADMINISTRATOR.toLowerCase()) ||
        roleLowerCase.includes(RoleConstants.SITE_CORRESPONDENT.toLowerCase()) ||
        roleLowerCase.includes(RoleConstants.TECHNICIAN.toLowerCase())
      ) {
        accessLevel = RoleConstants.T_LEVEL;
      } else if (
        roleLowerCase.includes(RoleConstants.MONO_SECTION_USER.toLowerCase()) ||
        roleLowerCase.includes(RoleConstants.VALIDATOR.toLowerCase())
      ) {
        accessLevel = RoleConstants.U_LEVEL;
      }
      else {
        accessLevel = RoleConstants.NO_ACCESS;
      }
    }
    else {
      accessLevel = RoleConstants.NO_ACCESS;
    }

    return accessLevel;

  }

  /**
   * Return true if the user has U rights.
   * It means he can consult instrument cards.
   * @param {UserModel} user
   */
  static hasURight(user) {
    if (!user || !user.roles) {
      return false;
    }

    if (RoleHelper.hasCRight(user) || RoleHelper.hasTRight(user)) {
      return true;
    }

    const authorizedRoles = user.roles.filter(role =>
      role.value === RoleConstants.VALIDATOR ||
      role.value === RoleConstants.MONO_SECTION_USER
    );

    return authorizedRoles.length > 0;
  }

  /**
   * Return true if the user has C rights.
   * It means he can consult and modify instruments.
   * @param {UserModel} user
   */
  static hasCRight(user) {
    if (!user || !user.roles) {
      return false;
    }

    if (RoleHelper.hasTRight(user)) {
      return true;
    }

    const authorizedRoles = user.roles.filter(role =>
      role.value === RoleConstants.METROLOGY_CORRESPONDENT
    );

    return authorizedRoles.length > 0;
  }

  /**
   * Return true if the user has T rights.
   * It means he can create interventions.
   * @param {UserModel} user
   */
  static hasTRight(user) {
    if (!user || !user.roles) {
      return false;
    }

    const authorizedRoles = user.roles.filter(role =>
      role.value === RoleConstants.GLOBAL_ADMINISTRATOR ||
      role.value === RoleConstants.ENTITY_ADMINISTRATOR ||
      role.value === RoleConstants.SITE_CORRESPONDENT ||
      role.value === RoleConstants.TECHNICIAN
    );

    return authorizedRoles.length > 0;
  }

}

export default RoleHelper;
