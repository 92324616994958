import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { styles } from './InterventionSubmitStep.style';
import InterventionSummaryCard from './InterventionSummary/InterventionSummaryCard';
import InterventionInstrumentsCard from './InterventionInstruments/InterventionInstrumentsCard';
import { toggleInterventionSubmitInfo, updateToolbarInfo } from '../../../../store/actions/exportActions';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { ToolbarActionsConstants } from '../../../../Utils/Constants/ToolbarActionsConstants';
import SimpleDialog from '../../../Dialogs/SimpleDialog/SimpleDialog';
import { theme } from '../../../../Utils/Theme';
import { Box } from '@mui/material';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    showInterventionSubmitInfo: state.toolbarReducer.showInterventionSubmitInfo,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    toggleInterventionSubmitInfo:
        showInterventionSubmitInfo => dispatch(toggleInterventionSubmitInfo(showInterventionSubmitInfo)),
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo))
  };
};

class InterventionSubmitStep extends Component {

  componentDidMount() {
    if (this.props.onMobile) {
      this.props.updateToolbarInfo({
        title: strings.intervention.submitStep,
        icon: (null),
        actions: [ToolbarActionsConstants.INTERVENTION_SUBMIT_INFO]
      });
    } else {
      this.props.updateToolbarInfo({
        title: '',
        icon: (null),
        actions: []
      });
    }
  }

  render() {
    if (this.props.onMobile) {
      return (
        <>
          <Box component="div" sx={{...styles(theme).mobileContainer}}>
            <InterventionInstrumentsCard
              instruments={this.props.instruments}
              intervention={this.props.intervention}
              updateInstrumentsWithInterventions={this.props.updateInstrumentsWithInterventions}
            />
          </Box>
          <SimpleDialog
            id="dialog"
            open={this.props.showInterventionSubmitInfo}
            title={strings.intervention.submitStep}
            refuseLabel={strings.general.ok}
            onRefuse={() => this.props.toggleInterventionSubmitInfo(false)}
          >
            <InterventionSummaryCard intervention={this.props.intervention} />
          </SimpleDialog>
        </>
      );
    }
    else {
      return (
        <Box component="div" sx={{...styles(theme).container}}>
          <InterventionSummaryCard intervention={this.props.intervention} />
          <InterventionInstrumentsCard
            instruments={this.props.instruments}
            intervention={this.props.intervention}
            updateInstrumentsWithInterventions={this.props.updateInstrumentsWithInterventions}
          />
        </Box>
      );
    }
  }
}

InterventionSubmitStep.propTypes = {
  intervention: PropTypes.object.isRequired,
  instruments: PropTypes.array.isRequired,
  updateInstrumentsWithInterventions: PropTypes.func.isRequired,
  onMobile: PropTypes.bool.isRequired,
  showInterventionSubmitInfo: PropTypes.bool,
  toggleInterventionSubmitInfo: PropTypes.func,
  updateToolbarInfo: PropTypes.func,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(InterventionSubmitStep);
