import React, { Component } from 'react';
import { JwtHelperService } from '../../Utils/JwtHelperService';
import TrackingHomePage from '../Tracking/Home/TrackingHomePage';
import { GUEST_PAGES } from '../../Utils/Constants/GuestConstants';
import BorrowingPage from '../Tracking/Borrowing/BorrowingPage';
import BorrowingResumePage from '../Tracking/Response/BorrowingResumePage';
import { ToastContainer } from 'react-toastify';
import styles from '../../Utils/SharedStyles';
import PropTypes from 'prop-types';
import { PagesPaths } from '../../Utils/Constants/PagesPaths';
import UserService from '../../Providers/WebService/UserService';
import { getTimestampAsSeconds } from '../../Utils/DateUtils';
import { withGuide } from '../Guides/Guidable';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import { ListPage } from "../Tracking/List/ListPage";
import { theme } from '../../Utils/Theme';

const DELAY_AUTO_CLOSE = 7500;
const TOASTS_POSITION = 'bottom-center';

class GuestPage extends Component {

  state = {
    activePage: GUEST_PAGES.HOME,
    userInformations: {},
    resultPageData: {}
  };

  componentDidMount() {
    UserService.checkTokenValiditySSO().catch(() => this.logout());
    this.setState({ userInformations: JwtHelperService.getSSOUserInformations() });
  }

  componentDidUpdate() {
    this.checkTokenValidity();
  }

  checkTokenValidity = () => {
    const timestampAsSeconds = getTimestampAsSeconds();
    if (this.state.userInformations.expirationDate < timestampAsSeconds) {
      this.logout();
    }
  }

  logout = () => {
    JwtHelperService.clearSession();
    this.props.navigate(PagesPaths.root);
  }

  updateGuestActivePage = (activePage, data = {}) => {
    this.setState({ activePage, resultPageData: data });
  };

  getContent = () => {
    const activePage = this.state.activePage;
    if (activePage === GUEST_PAGES.BORROW || activePage === GUEST_PAGES.RETURN) {
      return <BorrowingPage
        logout={this.logout}
        isReturn={activePage === GUEST_PAGES.RETURN}
        userInformations={this.state.userInformations}
        onBackPressed={() => this.updateGuestActivePage(GUEST_PAGES.HOME)}
        onResult={data => this.updateGuestActivePage(GUEST_PAGES.RESULT, data)}
        checkTokenValidity={this.checkTokenValidity} />;
    } else if (activePage === GUEST_PAGES.RESULT) {
      return <BorrowingResumePage
        resultInformations={this.state.resultPageData}
        onHomePressed={() => this.updateGuestActivePage(GUEST_PAGES.HOME)} />;
    } else if (activePage === GUEST_PAGES.LIST){
      return <ListPage
        userInformations={this.state.userInformations}
        onBackPressed={() => this.updateGuestActivePage(GUEST_PAGES.HOME)}
        sx={{...styles(theme)}}/>;

    } else {
      return <TrackingHomePage logout={this.logout} updateGuestActivePage={this.updateGuestActivePage} />;
    }
  }

  render() {
    return (
      <div id="main">
        {this.getContent()}
        <ToastContainer
          position={TOASTS_POSITION}
          autoClose={DELAY_AUTO_CLOSE}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          pauseOnHover
          draggable
          toastClassName="ReactToastify-toastContainer"
          progressClassName="ReactToastify-toastProgress"
          closeButton={false}
          theme='colored'
        />
      </div>);
  }
}

GuestPage.propTypes = {
  navigate: PropTypes.func
};

export default withGuide(GuestPage, true, PAGES_IDS.GUEST);
