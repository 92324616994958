import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  instrumentContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    padding: "8px",
    borderBottom: `1px solid ${theme.palette.intervention.mobile_divider}`,
    cursor: 'pointer'
  },
  status: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 8px',
    flex: '0 0 110px',
    [theme.breakpoints.down('md')]: {
      flex: '0 0 50px',
      padding: "0px",
    },
  },
  labelStatus: {
    fontSize: "12px",
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: "10px",
    },
  },
  center: {
    fontSize: "16px",
    [theme.breakpoints.only('sm')]: {
      fontSize: "14px",
    },
  },
  right: {
    fontSize: "16px",
    [theme.breakpoints.only('sm')]: {
      fontSize: "14px",
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: "12px",
    },
  },
  scanInfos: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'end',
    justifyContent: 'center',
    flex: '0 0 auto',
    padding: '0px 8px',
  },
  instrumentInfos: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    padding: '0px 8px',
    flex: '1 1 auto',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

