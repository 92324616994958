import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Paper } from '@mui/material';

import { styles } from './StepWizard.style';
import Stepper from './Stepper/Stepper';
import WizardHeader from './WizardHeader/WizardHeader';
import { theme } from '../../Utils/Theme';

const StepWizard = props => {

  const getNextLabel = () => {
    if (props.activeStep === props.steps.length) {
      return null;
    }
    return props.steps[props.activeStep + 1];
  };

  const getPrevLabel = () => {
    if (props.activeStep === 0) {
      return null;
    }
    return props.steps[props.activeStep - 1];
  };

  const getCurrentLabel = () => {
    const steps = props.steps;
    return steps[props.activeStep];
  };

  return (
    <Paper elevation={2} id="stepperContainer" sx={{ "&.MuiPaper-rounded": {...styles(theme).paperRounded}}}>
      <Card sx={{ "&.MuiCard-root": {...styles(theme).pageCard}}}>

        <WizardHeader id="header" title={props.title} icon={props.icon} renderActions={() => props.renderHeaderIcons()} />

        <CardContent sx={{ "&.MuiCardContent-root": {...styles(theme).contentCard}}}>
          {props.children}
        </CardContent>

      </Card>
      <Stepper
        id="stepper"
        activeStep={props.activeStep}
        nextLabel={getNextLabel()}
        prevLabel={getPrevLabel()}
        onNext={props.onNextClicked}
        onPrev={props.onPrevClicked}
        numberOfSteps={props.steps.length}
        currentLabel={getCurrentLabel()}
      />
    </Paper>
  );
};

StepWizard.propTypes = {
  children: PropTypes.node.isRequired,
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  onNextClicked: PropTypes.func.isRequired,
  onPrevClicked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  renderHeaderIcons: PropTypes.func.isRequired
};

export default StepWizard;
