import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { styles } from './NotificationsPage.style';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, AppBar, Tabs, Tab, Box } from '@mui/material';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import NotificationsProvider from '../../Providers/Database/NotificationsProvider';
import InstrumentsProvider from '../../Providers/Database/InstrumentsProvider';
import { DeleteSweep, NotificationsNone } from '@mui/icons-material';
import { updateToolbarInfo } from '../../store/actions/exportActions';
import Scrollbar from 'react-scrollbars-custom';
import InstrumentCard from '../InstrumentCard/InstrumentCard';
import dayjs from 'dayjs';
import NotificationItem from './NotificationItem/NotificationItem';
import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import MessageScanProvider from '../../Providers/Database/MessageScanProvider';
import MessageScanItem from './MessageScanItem/MessageScanItem';
import ScanAlertDialog from '../Dialogs/ScanAlertDialog/ScanAlertDialog';
import GenericCard from '../../Containers/GenericCard/GenericCard';
import SwipeableViews from 'react-swipeable-views-react-18-fix';
import { withGuide } from '../Guides/Guidable';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import { theme } from '../../Utils/Theme';

const TYPES = {
  NOTIFICATION: 1,
  MESSAGE_SCAN: 2,
};

// maps interventionReducer's state to Component's props
const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile
  };
};

// maps redux's actions to Component's props
const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};

class NotificationsPage extends Component {

  state = {
    showInstrumentCard: false,
    instrumentToShow: null,
    notifications: [],
    messagesScans: [],
    showMessageScanCard: false,
    messageScanToShow: undefined,
    tab: 0
  };

  openInstrument = instrument => {
    this.setState({
      showInstrumentCard: true,
      instrumentToShow: instrument
    });
  }

  openMessageScan = (event, messageScan) => {
    event.stopPropagation();
    this.setState({
      showMessageScanCard: true,
      messageScanToShow: messageScan
    });
  }

  onClearNotifications = () => {
    NotificationsProvider.deleteNotifications(this.state.notifications.map(item => item.id));
    this.props.onNotificationUpdate();
    this.setState({ notifications: [] });
  }

  onNotificationRemoved = (event, notification) => {
    event.stopPropagation();
    NotificationsProvider.deleteNotifications([notification.id]);
    this.props.onNotificationUpdate();
    this.setState({ notifications: this.state.notifications.filter(item => item.id !== notification.id) });
  }

  handleChangeTab = (event, tab) => {
    this.setState({ tab });
  };

  handleChangeIndex = index => {
    this.setState({ tab: index });
  };

  componentDidMount() {
    this.props.updateToolbarInfo({
      title: strings.mainMenu.instrumentNotif,
      icon: (<NotificationsNone />),
      actions: [ToolbarActionsConstants.START_TUTORIAL]
    });

    NotificationsProvider.getNotifications().then(notifs => {
      InstrumentsProvider.getInstrumentsFromMarquages(notifs.map(notif => notif.marquage))
        .then(instruments => {
          const notifications = notifs.map(notif => {
            return {
              id: notif.serverId,
              marquage: notif.marquage,
              instrument: instruments.find(element => element.marquage === notif.marquage),
              oldValue: notif.oldValue,
              date: dayjs(new Date(notif.createAt)).format(strings.general.dateFormat),
              field: notif.field
            };
          });
          this.setState({ notifications });
        });
    });

    MessageScanProvider.getAllMessagesScansHistory().then(messagesScansHistory => {
      InstrumentsProvider.getInstrumentsFromMarquages(messagesScansHistory.map(messageScanHistory => messageScanHistory.marquage))
        .then(instruments => {
          const messagesScans = messagesScansHistory.map(msgScanHistory => {
            return {
              id: msgScanHistory.id,
              marquage: msgScanHistory.marquage,
              instrument: instruments.find(element => element.marquage === msgScanHistory.marquage),
              messageScan: msgScanHistory.messageScan,
              date: msgScanHistory.createdAt
            };
          });
          // Reverse the messagesScans array and save it in state 
          // Create an array filtered by date
          this.setState({ messagesScans: messagesScans.reverse() });
        });
    });
  }

  generateDialogs = () => {
    return (
      <>
        <ScanAlertDialog
          open={this.state.showMessageScanCard}
          messageScan={this.state.messageScanToShow}
          onRefuse={() => this.setState({ showMessageScanCard: false })}
        />
        <InstrumentCard
          open={this.state.showInstrumentCard}
          instrument={this.state.instrumentToShow}
          onClose={() => this.setState({ showInstrumentCard: false })} />
      </>
    );
  }

  generateGenericCard = (type, array) => {
    let title, subHeader, actionsHeader, content;
    switch (type) {
      case TYPES.NOTIFICATION:
        title = strings.notifications.newNotifications;
        subHeader = strings.notifications.notifications(this.state.notifications.length);
        actionsHeader =
          <Tooltip title={strings.tooltips.clearAll} enterDelay={globalConstants.tooltipDelay}>
            <IconButton onClick={() => this.onClearNotifications()} size="large">
              <DeleteSweep color="primary" />
            </IconButton>
          </Tooltip>;
        content = array.map(item => {
          return (<NotificationItem
            key={item.id}
            notification={item}
            onClick={() => this.openInstrument(item.instrument)}
            onRemove={event => this.onNotificationRemoved(event, item)}
          />);
        });
        break;
      case TYPES.MESSAGE_SCAN:
        title = strings.notifications.messagesScansTitle;
        subHeader = strings.notifications.messageScan(this.state.messagesScans.length);
        actionsHeader = null;
        content = array.map(item => {
          return (<MessageScanItem
            key={item.id}
            item={item}
            onClick={() => this.openInstrument(item.instrument)}
            onHelp={event => this.openMessageScan(event, item.messageScan)}
          />
          );
        });
        break;
      default:
        break;
    }
    return (
      <GenericCard
        cardHeader
        title={this.props.onMobile ? null : title}
        subheader={subHeader}
        actionsHeader={actionsHeader}
      >
        <Scrollbar
          style={{...styles(theme).fullHeight}}
          noScrollX>
          {content}
        </Scrollbar>
      </GenericCard>
    );
  }

  render() {
    if (this.props.onMobile) {
      return (
        <>
          {this.generateDialogs()}
          <AppBar position="static">
            <Tabs
              value={this.state.tab}
              onChange={this.handleChangeTab}
              variant="fullWidth"
            >
              <Tab label={strings.notifications.notificationTab(this.state.notifications.length)} />
              <Tab label={strings.notifications.messageScanTab(this.state.messagesScans.length)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            containerStyle={{ height: '100%' }}
            sx={{...styles(theme).fullHeight}}
            axis="x"
            index={this.state.tab}
            onChangeIndex={this.handleChangeIndex}
          >
            {/* Call notification GenericCard without title */}
            {this.generateGenericCard(TYPES.NOTIFICATION, this.state.notifications)}
            {/* Call messageScan GenericCard without title */}
            {this.generateGenericCard(TYPES.MESSAGE_SCAN, this.state.messagesScans)}
          </SwipeableViews>
        </>
      );
    }
    else {
      return (
        <Box component="div" sx={{...styles(theme).historicContainer}} >
          {this.generateDialogs()}
          <Box component="div" sx={{...styles(theme).column}}>
            {/* Call notification GenericCard with title */}
            {this.generateGenericCard(TYPES.NOTIFICATION, this.state.notifications)}
          </Box>
          <Box component="div" sx={{...styles(theme).column}}>
            {/* Call messageScan GenericCard with title */}
            {this.generateGenericCard(TYPES.MESSAGE_SCAN, this.state.messagesScans)}
          </Box>
        </Box >
      );
    }
  }
}

NotificationsPage.propTypes = {
  onMobile: PropTypes.bool,
  updateToolbarInfo: PropTypes.func,
  onNotificationUpdate: PropTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withGuide)(NotificationsPage);
