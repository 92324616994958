import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.pureWhite,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing() - 2,
      paddingBottom: theme.spacing(),
      paddingTop: theme.spacing(),
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },
  pickers: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  datePicker: {
    maxWidth: '50%',
    padding: "1px"
  },
  notDisplay: {
    display: 'none!important'
  },
  column: {
    padding: "10px",
  },
  custom_input_grid: {
    paddingTop: theme.spacing(0.5)
  }
});
