import PropTypes from 'prop-types';
import React from 'react';
import Paper from '@mui/material/Paper';
import { styles } from './PageNotFound.style';
import { theme } from '../../../Utils/Theme';
import { Box } from '@mui/material';

const PageNotFound = props => {

  // Fix issue #52 when sometimes the url is http://localhost:3006/sso-auth!#!
  // instead of http://localhost:3006/sso-auth#!
  // We just remove the '!'
  const pathLen = props.location.pathname.length - 1;
  if (props.location.pathname[pathLen] === '!') {
    props.navigate(props.location.pathname.substring(0, pathLen));
  }

  return (
    <Box component="main" sx={{...styles(theme).main}}>
      <Paper elevation={2} sx={{...styles(theme).paper}}>
        <h2>Oops ! Page not found.</h2>
        <p>La page {window.location.href} est introuvable</p>
      </Paper>
    </Box>
  );
};

PageNotFound.propTypes = {
  navigate: PropTypes.func,
  location: PropTypes.string
};

export default PageNotFound;
