import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setFilter, toggleSavedFilter } from '../../../store/actions/exportActions';
import FilterProvider from '../../../Providers/Database/FilterProvider';
import SimpleDialog from '../../Dialogs/SimpleDialog/SimpleDialog';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Typography, Avatar, Tooltip } from '@mui/material';
import { Delete, Done, FilterList, Clear } from '@mui/icons-material';
import ValidationDialog from '../../Dialogs/ValidationDialog/ValidationDialog';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { styles } from './LoadFilterDialog.style';
import { isNotComputer } from "../../Utils/ResponsiveSelect/ResponsiveSelect";
import { theme } from '../../../Utils/Theme';

// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    activeFilter: state.toolbarReducer.activeFilter
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    toggleSavedFilter: showSavedFilters => dispatch(toggleSavedFilter(showSavedFilters)),
    setFilter: filter => dispatch(setFilter(filter))
  };
};

class LoadFilterDialog extends Component {

  state = {
    filterList: [],
    showValidation: false,
    filterToDelete: ''
  };

  componentDidMount() {
    this.loadFilterList();
  }

  loadFilter = filter => {
    this.props.setFilter(filter);
    this.props.toggleSavedFilter(false);
  }

  deleteFilter = name => {
    FilterProvider.delete(name);
    this.setState({ showValidation: false });
    this.loadFilterList();
  }

  loadFilterList = () => {
    FilterProvider.getSavedFilters().then(list => {
      const filters = list.map(filter => {
        return { name: filter.name, filter: filter.filter };
      });

      this.setState({ filterList: filters });
    });
  }

  getFilterList = () => {
    let filterCount = 0;

    let content = (<List>
      {
        this.state.filterList.map(filter => {
          filterCount++;

          const filtersAreEquals = FilterProvider.areEquals(this.props.activeFilter, new FilterProvider(filter.filter));

          return (
            <Tooltip key={filter.name} title={FilterProvider.buildFilterPreview(filter.filter)}>
              <ListItem key={filter.name} sx={{...styles(theme).listItem}}>
                <Avatar sx={filtersAreEquals ? {...styles(theme).activeFilter} : {}}><FilterList /></Avatar>

                <ListItemText
                  primary={filter.name}
                  secondary={FilterProvider.buildShortFilterPreview(filter.filter)}
                  sx={{
                    "& .MuiListItemText-primary": {...(isNotComputer && styles(theme).tabletFontSize)},
                    "& .MuiListItemText-secondary": {...(isNotComputer && styles(theme).tabletFontSize)}
                  }}/>

                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() =>
                      this.setState({ showValidation: true, filterToDelete: filter.name })}
                    id="deleteFilter"
                    size="large">
                    <Delete />
                  </IconButton>

                  {filtersAreEquals ?
                    <IconButton onClick={() => this.loadFilter(null)} id="clearFilter" size="large">
                      <Clear />
                    </IconButton>
                    :
                    <IconButton
                      onClick={() => this.loadFilter(new FilterProvider(filter.filter))}
                      id="loadFilter"
                      size="large">
                      <Done />
                    </IconButton>}
                </ListItemSecondaryAction>
              </ListItem>
            </Tooltip>
          );
        })
      }
    </List>);

    if (filterCount === 0) {
      content = <Typography variant={isNotComputer ? 'h6' : 'body2'}>{strings.filter.noFilterSaved}</Typography>;
    }

    return content;
  }

  render() {
    return (

      <>
        <ValidationDialog
          id="validationDialog"
          open={this.state.showValidation}
          title={strings.dialog.title.areYouSure}
          text={strings.filter.deleteFilter}
          refuseLabel={strings.dialog.answer.no}
          onRefuse={() => this.setState({ showValidation: false })}
          acceptLabel={strings.dialog.answer.yes}
          onAccept={() => this.deleteFilter(this.state.filterToDelete)}
        />

        <SimpleDialog
          id="simpleDialog"
          styledDialog={this.props.onMobile ? {} : styles(theme).dialog}
          open={this.props.open}
          fullScreen={this.props.onMobile}
          icon={<FilterList />}
          title={strings.filter.loadFilter}
          refuseLabel={strings.dialog.answer.cancel}
          onRefuse={() =>  this.props.toggleSavedFilter(false)}>

          {this.getFilterList()}

        </SimpleDialog>
      </>
    );
  }

}

LoadFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onMobile: PropTypes.bool.isRequired,
  toggleSavedFilter: PropTypes.func.isRequired,
  activeFilter: PropTypes.instanceOf(FilterProvider),
  setFilter: PropTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(LoadFilterDialog);
