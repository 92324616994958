import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Chip,
  Box
} from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Check, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

import { styles } from './SequentialDialog.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

const ACTION = {
  NEXT: 1,
  PREVIOUS: -1
};

class SequentialDialog extends Component {

  state = {
    currentStep: 0,
  };

  onChangeStep = direction => {
    this.setState(state => ({ currentStep: state.currentStep + direction }));
  }

  creationButtons = (label, onClick, icon, idButton) => {
    return this.props.onMobile ?
      <IconButton
        id={idButton}
        onClick={onClick}
        sx={{...merge({}, styles(theme).buttons, (isNotComputer && styles(theme).tabletFontSize))}}
        size="large">
        {icon}
      </IconButton>
      :
      <Button
        id={idButton}
        sx={{...merge({}, styles(theme).buttons, (isNotComputer && styles(theme).tabletFontSize))}}
        variant="contained"
        color="primary"
        onClick={onClick}
      >
        {label}
      </Button>;
  }

  render() {
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle sx={{ "&.MuiDialogTitle-root": {...merge({}, styles(theme).dialogTitle, styles(theme).titleContainer)}}}>
          <Box component="div" sx={{...merge({}, styles(theme).inlineBlock, styles(theme).whiteColor, (isNotComputer && styles(theme).tabletTitleFontSize))}}>
            {this.props.icon &&
              <Box component="div" sx={{...styles(theme).icon}}>
                {this.props.icon}
              </Box>}
            {this.props.title}
          </Box>
        </DialogTitle >

        <DialogContent
          sx={{ "&.MuiDialogContent-root": {...merge({}, styles(theme).dialogContent, (isNotComputer && styles(theme).tabletFontSize))} }}>
          {this.props.steps[this.state.currentStep]}
        </DialogContent>

        <DialogActions sx={{ "&.MuiDialogActions-root": {...styles(theme).dialogActions}}}>
          
          <Box component="div" sx={{...styles(theme).pagination}}>
            <Chip label={this.state.currentStep + 1 + '/' + this.props.steps.length} />
          </Box>

          <div>
            {/**  PREVIOUS  button */}
            {this.state.currentStep !== 0 &&
              this.creationButtons(
                strings.guides.back,
                () => this.onChangeStep(ACTION.PREVIOUS),
                <KeyboardArrowLeft />,
                'previousButton')
            }

            {/**Next or finish button */}
            {this.state.currentStep !== this.props.steps.length - 1 ?
              this.creationButtons(
                strings.guides.next,
                () => this.onChangeStep(ACTION.NEXT),
                <KeyboardArrowRight />,
                'nextButton')
              :
              this.creationButtons(
                strings.guides.last,
                this.props.onClose,
                <Check />,
                'finishButton')
            }
          </div>
        </DialogActions>
      </Dialog >
    );
  }
}

SequentialDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  steps: PropTypes.array.isRequired,
  icon: PropTypes.object,
  onMobile: PropTypes.bool,
};

export default compose(
  connect(mapStateToProps))(SequentialDialog);
