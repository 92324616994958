import React from 'react';

import ErrorPage from '../../Components/Misc/ErrorPage/ErrorPage';
import PropTypes from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, stackTrace: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      stackTrace: errorInfo
    });
  }

  render() {
    // If an error has been caught
    if (this.state.stackTrace) {
      return <ErrorPage error={this.state.error.toString()} stackTrace={this.state.stackTrace.componentStack} />;
    }
    // Else, just render children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  //region props from parent
  children: PropTypes.object
  //endregion
};

export default ErrorBoundary;
