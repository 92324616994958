/**
 * Return if the filter is empty or not
 */
export const isFilterEmpty = (filter) => {
  let isEmpty = true;
  for (const field in filter) {
    if (field != null && filter[field] !== '' && filter[field].length !== 0) {
      isEmpty = false;
    }
  }
  return isEmpty;
};
