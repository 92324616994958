import sharedStyled from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyled(theme),
  staticfieldTextValue: {
    fontSize: "13px",
    lineHeight: '1.67',
    letterSpacing: 'normal',
    color: 'var(--color)!important',
    fontStyle: 'var(--fontStyle)!important',
    paddingLeft: "12px",
    [theme.breakpoints.only('xs')]: {
      fontSize: '1rem'
    },
  },
  hideTooltip: {
    display: 'none'
  },
  tooltip: {
    '&:hover': {
      cursor: 'pointer!important',
    }
  }
});
