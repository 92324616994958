import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Box, CssBaseline, LinearProgress, Paper } from '@mui/material';
import disableBrowserBackButton from 'disable-browser-back-navigation';

import logo from '../../Assets/appLogoAnimated.gif';
import ValidationDialog from '../Dialogs/ValidationDialog/ValidationDialog';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { PagesPaths } from '../../Utils/Constants/PagesPaths';
import { styles } from './SynchronizationPage.style';
import SynchroProvider, { SynchronizationStep } from '../../Providers/SynchroProvider/SynchroProvider';
import LoadingSpinner from '../Utils/LoadingSpinner/LoadingSpinner';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import { isNotComputer } from '../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../Utils/Theme';
import merge from 'lodash/merge';

class SynchronizationPage extends Component {

  state = {
    // State for error popup
    openPopup: false,
    contentPopup: '',

    // Progresse bar data
    progressBarValue: 0,
    currentSynching: 0,
    totalSynching: 0,
    logMessages: [],
    currentMessage: '',
    loading: false
  };

  async componentDidMount() {
    disableBrowserBackButton();
    this.syncProvider = new SynchroProvider();

    this.syncProvider.synchronize(
      (current, total) => this.updateProgressBar(current, total),
      nextStep => this.nextStep(nextStep)
    )
      .then(() => this.syncDone())
      .catch(err => {
        this.setState({
          openPopup: true,
          contentPopup: this.getErrorTextContent(err)
        });
      });
  }

  componentWillUnmount() {
    this.syncProvider.stopSync();
  }

  updateProgressBar(current, total) {
    this.setState({
      progressBarValue: Math.min(current * 100 / total),
      currentSynching: current,
      totalSynching: total
    });
  }

  /**
   * Update message based on current step of synchronization
   * @param {SynchronizationStep} newStep
   */
  nextStep(newStep) {
    let message;

    switch (newStep) {
      case SynchronizationStep.Step__download_instruments:
        message = strings.sync.steps.instrumentsDownload;
        break;
      case SynchronizationStep.Step__upload_instruments:
        message = strings.sync.steps.instrumentsUpload;
        break;
      case SynchronizationStep.Step__sync_connections:
        message = strings.sync.steps.connections;
        break;
      case SynchronizationStep.Step__sync_scans:
        message = strings.sync.steps.scans;
        break;
      case SynchronizationStep.Step__sync_interventions:
        message = strings.sync.steps.interventions;
        break;
      case SynchronizationStep.Step__sync_notification:
        message = strings.sync.steps.notifications;
        break;
      case SynchronizationStep.Step__sync_favorites:
        message = strings.sync.steps.favorites;
        break;
      case SynchronizationStep.Step__sync_messages_scans:
        message = strings.sync.steps.messagesScans;
        break;
      default:
        message = '';
        break;
    }

    if (this.state.currentMessage !== '') {
      const newLogMessage = `${this.state.currentMessage} (${this.state.totalSynching})`;

      this.setState({ logMessages: [...this.state.logMessages, newLogMessage] });
    }

    this.setState({ currentMessage: message });

    this.updateProgressBar(null, null);
  }

  syncDone() {
    if(this.props.showCards) {
      this.props.finishSynchro();
    }else {
      this.props.navigate(PagesPaths.home);
    }

  }

  getCurrentlySyncCount() {
    if (this.state.currentSynching === null && this.state.totalSynching === null) {
      return strings.sync.init;
    }

    if (this.state.totalSynching === 0) {
      return strings.sync.ended;
    }

    return `${this.state.currentSynching}/${this.state.totalSynching}`;
  }

  getErrorTextContent(error) {
    const content = strings.dialog.content.syncError;
    if (process.env.REACT_APP_ENV === 'dev') {
      throw error;
    }

    return content;
  }

  render() {

    const printLogMessages = () => this.state.logMessages.map((text, i) => (<p key={i}>{text}</p>));

    return (
      this.props.showCards ?
        <SimpleDialog
          styledDialog={styles(theme).synchro_main}
          title={strings.dialog.title.syncInProgress}
          open={this.props.showCards}
          alternateStyling={this.props.showCards}
          id="synchronizationInstrumentDialogFromUser">
          {this.state.loading && <LoadingSpinner/>}
          <CssBaseline/>
          <Paper elevation={2} sx={{...merge({}, styles(theme).syncPage_container, (isNotComputer && styles(theme).tabletCardWidth))}}>
            <Box component="img" sx={{...styles(theme).syncPage_logo}} src={logo} alt={strings.alt.admLogo}/>
            <Box component="p" sx={{...merge({}, styles(theme).syncPage_counter, (isNotComputer && styles(theme).tabletTitleFontSize))}}>
              {this.getCurrentlySyncCount()}</Box>
            <LinearProgress variant="determinate" value={this.state.progressBarValue}/>
            <Box component="div" sx={{...merge({}, styles(theme).syncPage_logs, (isNotComputer && styles(theme).tabletFontSize))}}>
              {printLogMessages()}
              <Box component="p" sx={{...styles(theme).syncPage_logs_current}}>{this.state.currentMessage}</Box>
            </Box>
          </Paper>
          <ValidationDialog
            id="dialog"
            open={this.state.openPopup}
            title={strings.dialog.title.syncError}
            text={this.state.contentPopup}
            refuseLabel={strings.sync.reset}
            onRefuse={async () => {
              this.setState({ loading: true });
              // Reset all synchronization-related data
              await SynchroProvider.resetSynchronizableTables();
              // Reload the page
              window.location.reload(true);
            }}
            acceptLabel={strings.sync.retry}
            onAccept={() => window.location.reload(true)}
            redirectLabel={strings.sync.redirection('home')}
            onRedirect={()=> this.props.redirectFromSynchro()}
          />
        </SimpleDialog>
        :
        <Box component="main" sx={{...styles(theme).syncPage_main}}>
          {this.state.loading && <LoadingSpinner/>}
          <CssBaseline/>
          <Paper elevation={2} sx={{...merge({}, styles(theme).syncPage_container, (isNotComputer && styles(theme).tabletCardWidth))}}>
            <Box component="img" sx={{...styles(theme).syncPage_logo}} src={logo} alt={strings.alt.admLogo}/>
            <Box component="p" sx={{...merge({}, styles(theme).syncPage_counter, (isNotComputer && styles(theme).tabletTitleFontSize))}}>
              {this.getCurrentlySyncCount()}</Box>
            <LinearProgress variant="determinate" value={this.state.progressBarValue}/>
            <Box component="div" sx={{...merge({}, styles(theme).syncPage_logs, (isNotComputer && styles(theme).tabletFontSize))}}>
              {printLogMessages()}
              <Box component="p" sx={{...styles(theme).syncPage_logs_current}}>{this.state.currentMessage}</Box>
            </Box>
          </Paper>
          <ValidationDialog
            id="dialog"
            open={this.state.openPopup}
            title={strings.dialog.title.syncError}
            text={this.state.contentPopup}
            refuseLabel={strings.sync.reset}
            onRefuse={async () => {
              this.setState({ loading: true });
              // Reset all synchronization-related data
              await SynchroProvider.resetSynchronizableTables();
              // Reload the page
              window.location.reload(true);
            }}
            acceptLabel={strings.sync.retry}
            onAccept={() => window.location.reload(true)}

            redirectLabel={strings.sync.redirection('login')}
            onRedirect={()=> this.props.navigate(PagesPaths.root)}
          />
        </Box>
    );
  }
}

SynchronizationPage.propTypes = {
  navigate: PropTypes.func,
  showCards: PropTypes.bool,
  finishSynchro: PropTypes.func,
  redirectFromSynchro: PropTypes.func
};

export default SynchronizationPage;
