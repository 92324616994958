import React, { PureComponent } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { styles } from './InstrumentsCountField.style';
import { theme } from '../../../Utils/Theme';

class InstrumentsCountField extends PureComponent {
  render() {
    return (<Typography sx={this.props.customClass ? {...this.props.customClass} : {...styles(theme).typography}} variant="subtitle1">
      {this.props.isCounting ?
        <CircularProgress size={20} /> :
        strings.general.nbOfInstruments(this.props.filteredCount)} / {strings.general.nbOfInstruments(this.props.totalCount)}
    </Typography>);
  }
}

InstrumentsCountField.propTypes = {
  isCounting: PropTypes.bool,
  filteredCount: PropTypes.number,
  totalCount: PropTypes.number,
  customClass: PropTypes.object
};

export default InstrumentsCountField;
