// Fix: finally does not exist with edge
// eslint-disable-next-line
Promise.prototype.finally = Promise.prototype.finally || {
  finally(fn) {
    const onFinally = cb => Promise.resolve(fn()).then(cb);
    return this.then(
      result => onFinally(() => result),
      reason => onFinally(() => { throw reason; })
    );
  }
}.finally;
