import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  listItem: {
    margin: theme.spacing(),
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottom: `2px solid ${theme.palette.mainPageBackground}`,
    [theme.breakpoints.down('sm')]: {
      margin: "0px"
    }
  },
  informations: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexGrow: 1,
    marginLeft: theme.spacing(),
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  removeBtn: {
    marginBottom: theme.spacing(5)
  },
  instrumentImage: {
    width: "100px",
    height: "100px",
    minWidth: "75px",
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: "50px",
      height: "50px"
    }
  },
  marquage: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(2)
    }
  }
});
