import React, { Component } from 'react';
import { Edit, PlaylistAddCheck, Autorenew } from '@mui/icons-material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import { compose } from 'redux';
import StepWizard from '../../Containers/StepWizard/StepWizard';
import InventoryCheckingPage from './InventoryChecking/InventoryCheckingPage';
import InventoryListingPage from './InventoryListing/InventoryListingPage';
import InventoryAnswerPage from './InventoryAnswer/InventoryAnswerPage/InventoryAnswerPage';
import InstrumentsProvider from '../../Providers/Database/InstrumentsProvider';
import InstrumentsService from '../../Providers/WebService/InstrumentService';
import UserProvider from '../../Providers/Database/UserProvider';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import {
  instrumentListNeedsUpdate,
  resetFilters,
  setStatus,
  toggleEditInstrumentsDialog,
  unlock
} from '../../store/actions/exportActions';
import { SnackbarMessages } from '../../Utils/Constants/SnackbarMessages';
import UpdateInstrumentsDialog from '../Dialogs/UpdateInstrumentsDialog/UpdateInstrumentsDialog';
import InterventionHelper from '../../Helpers/InterventionHelper';
import { InventoryResultStatus } from '../../Utils/Constants/InventoryResultStatus';
import LoadingDialog from '../Dialogs/LoadingDialog/LoadingDialog';
import { InventorySteps } from '../../Utils/Constants/InventorySteps';
import { InventoryConstants } from '../../Utils/Constants/InventoryConstants';
import InventoryProvider from '../../Providers/Database/InventoryProvider';
import { InventoryStatus } from '../../Utils/Constants/InventoryStatus';
import { ConnectivityChecker } from '../../Utils/ConnectivityChecker';
import { PagesPaths } from '../../Utils/Constants/PagesPaths';
import { withGuide } from '../Guides/Guidable';
import { MODE, STATUSES } from '../../Utils/Constants/GuideConstants';
import StartGuideButton from '../Utils/StartGuideButton/StartGuideButton';
import dayjs from 'dayjs';
import InventoryHelper from '../../Helpers/InventoryHelper';
import CsvIcon from '../Utils/CsvIcon/CsvIcon';
import { isNotComputer } from '../Utils/ResponsiveSelect/ResponsiveSelect';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../Utils/Theme';
import { ToastService } from '../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../Utils/Constants/ToastTypes';

export const mapStateToProps = state => {
  return {
    activeFilter: state.toolbarReducer.activeFilter,
    onMobile: state.generalReducer.onMobile,
    editInstrumentsIsOpen: state.instrumentReducer.editInstrumentsDialogOpened,
    activeUser: state.generalReducer.loggedUser,
    showFavorites: state.toolbarReducer.showFavorites,
    guideStatus: state.guideReducer.status,
    guideMode: state.guideReducer.mode
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    toggleEditInstrumentsDialog: value => dispatch(toggleEditInstrumentsDialog(value)),
    resetFilters: () => dispatch(resetFilters()),
    unlock: () => dispatch(unlock()),
    setStatus: status => dispatch(setStatus(status)),
    instrumentListNeedsUpdate: (msg, type) => dispatch(instrumentListNeedsUpdate(msg, type)),
  };
};

export class InventoryPage extends Component {

  state = {
    activeStep: 0,
    inventoryList: [],
    verifiedInstruments: [],
    creationDialogIsOpen: false,
    validateInventoryIsOpen: false,
    user: null,
    location: null,
    saveUser: false,
    saveLocation: false,
    validateInventoryDialogIsOpen: false,
    warningLoseInventoryDialog: false,
    inventoryResult: null,
    inventoryResultStatus: '',
    isLastInventory: null
  };

  isCounting = false;
  count = 0;

  async componentDidMount() {
    const localeInventories = await InventoryProvider.getAllInventories();

    if (localeInventories.length > 0) {
      // Load inventory from database
      this.setState({
        activeStep: InventorySteps.CHECKING,
        inventoryList: localeInventories.filter(inventory => !inventory.verifiedStatus)
          .map(notVerifiedInventory => notVerifiedInventory.instrument),
        verifiedInstruments:
          localeInventories
            .filter(inventory => inventory.verifiedStatus)
            .map(verifiedInventory => {
              return { ...verifiedInventory.instrument, inventoryStatus: verifiedInventory.verifiedStatus };
            }),
      });
    } else {
      this.init();
    }
  }

  async componentWillUnmount() {
    this.props.resetFilters();
    const isOnline = await ConnectivityChecker.isConnected();
    // If inventory is finished, and we have modified instruments, we need to run a sync
    if (isOnline && this.state.inventoryResult && (this.state.saveUser || this.state.saveLocation)) {
      this.props.navigate(PagesPaths.sync);
    }
  }

  async init() {
    this.props.setStatus(STATUSES.READY);
    const lastInventaire = await InstrumentsService.isLastInventory();
    this.setState({ isLastInventory: lastInventaire.isExist });
    UserProvider.userIsLocalAdmin().then(isAdmin => {
      if (!isAdmin) {
        InstrumentsProvider.getUserInstruments().then(instruments => {
          this.list = instruments;
        });
      }
    });
  }

  removeFromInventoryList = instrument => {
    this.setState(prevState => {
      return { inventoryList: prevState.inventoryList.filter(i => i.marquage !== instrument.marquage) };
    });
    InventoryProvider.removeInstrumentInventory(instrument.marquage);
  };

  removeFromVerifiedList = instrument => {
    this.setState(prevState => {
      return { verifiedInstruments: prevState.verifiedInstruments.filter(i => i.marquage !== instrument.marquage) };
    });
    InventoryProvider.removeInstrumentInventory(instrument.marquage);
  };

  saveItemFromInventory() {
    const data = {};
    const marquages = [];
    this.state.inventoryList.forEach(instrument => marquages.push(instrument.marquage));
    data.marquages = marquages.join(',');
    InstrumentsService.deleteAndSaveNewLastInventaire(data);
  }

  handleNext() {
    if (this.state.activeStep === InventorySteps.LISTING) {
      // Inventory filtering
      this.goToInventoryChecking();
    } else if (this.state.activeStep === InventorySteps.CHECKING) {
      // Inventory checking
      if (this.state.verifiedInstruments.length > 0) {
        this.setState({ validateInventoryIsOpen: true });
        this.props.unlock();
      } else {
        enqueueSnackbar(SnackbarMessages.inventoryNeedToVerify.msg,
          SnackbarMessages.inventoryNeedToVerify.type);
      }
    }
    return;
  }

  handlePrev() {
    if (this.state.activeStep === InventorySteps.CHECKING) {
      this.setState({ warningLoseInventoryDialog: true });
    }
  }

  goToInventoryListing = () => {
    InventoryProvider.truncateInventory();
    this.init();
    this.setState({ warningLoseInventoryDialog: false, activeStep: InventorySteps.LISTING, verifiedInstruments: [] });
  };

  async goToInventoryChecking() {
    if (this.isCounting) {
      enqueueSnackbar(SnackbarMessages.inventoryFiltering.msg,
        SnackbarMessages.inventoryFiltering.type);
    } else {
      if (this.count === 0 || this.count > InventoryConstants.MAX_INSTRUMENTS) {
        enqueueSnackbar(SnackbarMessages.inventoryNeedFiltering.msg,
          SnackbarMessages.inventoryNeedFiltering.type);
      } else {
        this.setState({ creationDialogIsOpen: true });
        let instruments;
        const marquages = this.props.showFavorites ? await InstrumentsProvider.getUserFavorites() : this.list;
        instruments = await InstrumentsProvider.getMoreUserInstruments(marquages, null, null, this.props.activeFilter);
        await InventoryProvider.saveInstrumentsInventory(instruments);
        this.setState({ inventoryList: instruments, activeStep: InventorySteps.CHECKING, creationDialogIsOpen: false });
        this.props.unlock();
      }
    }
  }

  goToInventoryAnswer = async () => {
    this.setState({ validateInventoryIsOpen: false, validateInventoryDialogIsOpen: true });
    let result;
    if (this.props.guideStatus === STATUSES.RUNNING && this.props.guideMode === MODE.CLASSIC) {
      // Fake data
      result = { online: true, inventoryResult: { notUpdated: [], updated: [] } };
      await InventoryProvider.truncateInventory();
    } else {
      const interventions = InterventionHelper.formatInterventionsFromInstruments(
        this.state.verifiedInstruments,
        this.state.saveUser,
        this.state.saveLocation,
        this.state.user,
        this.state.location);
      try {
        result = await InterventionHelper.createManyInterventions(
          interventions,
          this.state.saveLocation ? this.state.location : null,
          this.state.saveUser ? this.state.user : null
        );
        // check if the result is consistent
        if (result.inventoryResult === undefined) throw new Error();
        this.props.instrumentListNeedsUpdate(true);
      } catch {
        ToastService.enqueueToast(TOAST_TYPES.ERROR, null,
          strings.toastMessage.inventoryFailed + strings.auth.modalForgotPassword.mail);
        this.setState(
          {
            validateInventoryDialogIsOpen: false
          });
        return;
      }


      this.props.instrumentListNeedsUpdate(true);
    }
    this.saveItemFromInventory();
    this.setState(
      {
        validateInventoryDialogIsOpen: false,
        inventoryResultStatus: result.online ? InventoryResultStatus.SUCCESS : InventoryResultStatus.SAVED_LOCALLY,
        inventoryResult: result.inventoryResult,
        activeStep: InventorySteps.ANSWER,
      }, () => this.props.unlock());
  };

  handleUserAndLocationChange = (user, location) => {
    this.setState({ user, location });
    const instrumentToUpdate = [ ...this.state.verifiedInstruments ];
    instrumentToUpdate.forEach(inst => {
      if(user) {
        inst.utilisateur = user;
      }
      if (location) {
        inst.localisation = location;
      }
    });
    this.props.toggleEditInstrumentsDialog(false);
  };

  handleCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  verifyInstrument = (instrument, status) => {
    this.props.unlock();
    this.setState(prevState => {
      return {
        verifiedInstruments: prevState.verifiedInstruments.concat({ ...instrument, inventoryStatus: status }),
        inventoryList: prevState.inventoryList.filter(i => i.marquage !== instrument.marquage)
      };
    });
    const instrumentInventory = InventoryProvider
      .buildInstrumentInventoryItem(instrument, this.props.activeUser.userLogin, status);
    if (status !== InventoryStatus.VALIDATED) {
      InventoryProvider.insertInstrumentInventory(instrumentInventory);
    } else {
      InventoryProvider.updateInstrumentInventory(instrumentInventory);
    }
  };

  async loadLastInventory() {
    this.setState({ creationDialogIsOpen: true });
    let instruments;
    const marquages = await InstrumentsService.getLastInventory();
    instruments = await InstrumentsProvider.getMoreUserInstruments(marquages, null, null, this.props.activeFilter);
    await InventoryProvider.saveInstrumentsInventory(instruments);
    this.setState({ inventoryList: instruments, activeStep: InventorySteps.CHECKING, creationDialogIsOpen: false });
    this.props.unlock();
  }

  renderHeaderIcons = () => {
    if (this.state.activeStep === InventorySteps.CHECKING) {
      return (
        <IconButton
          id="headerIcon"
          onClick={() => this.props.toggleEditInstrumentsDialog(true)}
          size="large">
          <Edit color="secondary"/>
        </IconButton>
      );
    } else if (this.state.activeStep === InventorySteps.LISTING) {
      if(this.state.isLastInventory) {
        return( <div>
          <Button id="lastInventory" onClick={() => this.loadLastInventory()}>
            <Typography variant="body2" color="secondary">{strings.inventory.buttunLastInventory}</Typography>
            <Autorenew color="secondary" style={{ marginLeft: "4px" }}/>
          </Button>
          <StartGuideButton withLabel/>
        </div>
        );
      }else{
        return (<StartGuideButton withLabel/>);
      }
    } else if (this.state.activeStep === InventorySteps.ANSWER) {
      return <CsvIcon
        datas={InventoryHelper
          .csvDownloadData(this.state.inventoryList.concat(this.state.verifiedInstruments), this.props.activeUser)}
        color={theme.palette.secondary.main}
        headers={InventoryHelper.headers}
        fileName={strings.inventory.csvInventoryTitle(dayjs().format(strings.general.concatDateFormat))}
      />;
    }
  };

  render() {
    const headerProps = {
      title: strings.inventory.inventoryTitle,
      icon: <PlaylistAddCheck/>,
      renderHeaderIcons: () => this.renderHeaderIcons()
    };

    return (
      <>
        <StepWizard
          id="stepper"
          steps={[strings.inventory.inventoryList, strings.inventory.inventory, strings.inventory.validate]}
          activeStep={this.state.activeStep}
          onNextClicked={() => this.handleNext()}
          onPrevClicked={() => this.handlePrev()}
          {...headerProps}
        >
          {/* PAGE 1: FILTERING PAGE */}
          {this.state.activeStep === InventorySteps.LISTING &&
          <InventoryListingPage
            id="inventoryListing"
            onFiltering={(isCounting, count) => {
              this.isCounting = isCounting;
              this.count = count;
            }}
          />}

          {/* PAGE 2: CHECKING PAGE */}
          {this.state.activeStep === InventorySteps.CHECKING &&
          <InventoryCheckingPage
            id="inventoryChecking"
            instruments={this.state.inventoryList}
            verifyInstrument={this.verifyInstrument}
            verifiedInstruments={this.state.verifiedInstruments}
            removeFromInventoryList={this.removeFromInventoryList}
            removeFromVerifiedList={this.removeFromVerifiedList}
            onMobile={this.props.onMobile}
            guideStep={this.props.step}
          />}

          {/* PAGE 3: ANSWER PAGE */}
          {this.state.activeStep === InventorySteps.ANSWER &&
          <InventoryAnswerPage
            id="inventoryAnswer"
            notVerified={this.state.inventoryList}
            verified={this.state.verifiedInstruments}
            status={this.state.inventoryResultStatus}
            inventoryResult={this.state.inventoryResult}/>}
        </StepWizard>

        {/****************** DIALOGS ******************/}

        <LoadingDialog
          open={this.state.creationDialogIsOpen}
          title={strings.inventory.preparingInventoryTitle}
          label={strings.inventory.preparingInventoryText}
        />
        <LoadingDialog
          open={this.state.validateInventoryDialogIsOpen}
          title={strings.inventory.validationTitle}
          label={strings.inventory.validationLabel}
        />
        <UpdateInstrumentsDialog
          id="updateInstrumentsDialog"
          displayDialog={this.props.editInstrumentsIsOpen}
          handleDialog={() => this.props.toggleEditInstrumentsDialog(false)}
          onAccept={this.handleUserAndLocationChange}
          defaultLocation={this.state.location}
          defaultUser={this.state.user}
          instrumentsToUpdate={this.state.verifiedInstruments}
          shouldForceUpdate={false}
        />
        <SimpleDialog
          id="validateInventoryDialog"
          open={this.state.validateInventoryIsOpen}
          title={strings.inventory.validateInventoryTitle}
          acceptLabel={strings.dialog.answer.submit}
          refuseLabel={strings.dialog.answer.cancel}
          onAccept={() => this.goToInventoryAnswer()}
          onRefuse={() => this.setState({ validateInventoryIsOpen: false })}
          addContentPadding
        >
          <Typography variant={isNotComputer ? 'h6' : 'subtitle1'} style={{ whiteSpace: 'pre-wrap' }}>
            {InterventionHelper.generateComment(this.state.saveLocation, this.state.saveUser, this.state.location,
              this.state.user)}
          </Typography>
          {this.state.user && this.state.user.length > 0 &&
          <FormControlLabel
            id="saveUserFormControl"
            control={
              <Checkbox
                checked={this.state.saveUser}
                onChange={this.handleCheck('saveUser')}
              />
            }
            label={strings.inventory.saveUser}
          />}
          {this.state.location && this.state.location.length > 0 &&
          <FormControlLabel
            id="saveLocationFormControl"
            control={
              <Checkbox
                checked={this.state.saveLocation}
                onChange={this.handleCheck('saveLocation')}
              />
            }
            label={strings.inventory.saveLocation}
          />}
        </SimpleDialog>
        <SimpleDialog
          id="warningLoseInventoryDialog"
          open={this.state.warningLoseInventoryDialog}
          title={strings.inventory.warningTitle}
          acceptLabel={strings.dialog.answer.submit}
          refuseLabel={strings.dialog.answer.cancel}
          onAccept={() => this.goToInventoryListing()}
          onRefuse={() => this.setState({ warningLoseInventoryDialog: false })}
          addContentPadding
        >
          <Typography variant='subtitle1'>{strings.inventory.warningLoseInventory}</Typography>
        </SimpleDialog>
      </>
    );
  }
}

InventoryPage.propTypes = {

  //comes from router
  navigate: PropTypes.func.isRequired,

  //region props from redux
  activeFilter: PropTypes.object,
  onMobile: PropTypes.bool,
  editInstrumentsIsOpen: PropTypes.bool,
  activeUser: PropTypes.object.isRequired,
  showFavorites: PropTypes.bool,
  guideStatus: PropTypes.number,
  guideMode: PropTypes.number,

  toggleEditInstrumentsDialog: PropTypes.func,
  resetFilters: PropTypes.func,
  setStatus: PropTypes.func.isRequired,
  unlock: PropTypes.func.isRequired,
  instrumentListNeedsUpdate: PropTypes.func,
  //endregion

  //region props from parent
  step: PropTypes.object
  //endregion
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(withGuide(InventoryPage, false));
