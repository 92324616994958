// REACT libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Material-UI libraries
import { styles } from './InstrumentCard.style';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  BottomNavigation,
  BottomNavigationAction,
  Card,
  Box,
} from '@mui/material';
import {
  Build,
  Flag,
  LocalOffer
} from '@mui/icons-material';
// Components libraries
import InstrumentCardHeader from './Tabs/InstrumentCardHeader/InstrumentCardHeader';
import InstrumentCardIdentification from './Tabs/InstrumentCardIdentification/InstrumentCardIdentification';
import InstrumentCardMetrologie from './Tabs/InstrumentCardMetrologie/InstrumentCardMetrologie';
import InstrumentCardAdministration from './Tabs/InstrumentCardAdministration/InstrumentCardAdministration';
import InstrumentImage from './InstrumentImage/InstrumentImage';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';

import { instrumentListNeedsUpdate } from '../../store/actions/exportActions';
import LoadingSpinner from '../Utils/LoadingSpinner/LoadingSpinner';
import { isNotComputer } from '../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../Utils/Theme';
import merge from 'lodash/merge';

// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    instrumentListNeedsUpdate: (msg, type) => dispatch(instrumentListNeedsUpdate(msg, type))
  };
};

class InstrumentCard extends PureComponent {
  state = {
    fullWidth: true,
    selectedTab: 0,
    isLoading: false
  };

  handleChange = (event, value) => {
    this.setState({ selectedTab: value });
  };

  toggleInstrumentUpdateLoader = () => {
    this.setState(state => ({ isLoading: !state.isLoading }));
  };

  generateContent = (classes, instrument) => {
    if (this.props.onMobile) {

      let content = (<></>);

      switch (this.state.selectedTab) {
        case 0:
          content = (<InstrumentCardIdentification instrument={instrument}/>);
          break;
        case 1:
          content = (<InstrumentCardMetrologie instrument={instrument}/>);
          break;
        case 2:
          content = (<InstrumentCardAdministration instrument={instrument}/>);
          break;
        default:
          break;
      }
      return (
        <>
          <DialogContent sx={{ "&.MuiDialogContent-root": {...styles(theme).dialogContent}}}>
            {content}
          </DialogContent>
          <DialogActions sx={{
            "&.MuiDialogActions-root": {...styles(theme).dialogActions},
            "&.MuiDialogActions-spacing": {...styles(theme).dialogActions}
          }}>
            <BottomNavigation
              value={this.state.selectedTab}
              onChange={this.handleChange}
              showLabels
              sx={{...styles(theme).bottomNavigation}}
            >
              <BottomNavigationAction
                sx={{
                  "&.MuiBottomNavigationAction-root": {...styles(theme).bottomNavigationAction},
                  "& .Mui-selected": {...styles(theme).bottomNavigationActionSelected}
                }}
                label={strings.instrumentCard.smallid}
                icon={<LocalOffer/>}/>
              <BottomNavigationAction
                sx={{
                  "&.MuiBottomNavigationAction-root": {...styles(theme).bottomNavigationAction},
                  "& .Mui-selected": {...styles(theme).bottomNavigationActionSelected}
                }}
                label={strings.instrumentCard.smallMetrologie}
                icon={<Build/>}/>
              <BottomNavigationAction
                sx={{
                  "&.MuiBottomNavigationAction-root": {...styles(theme).bottomNavigationAction},
                  "& .Mui-selected": {...styles(theme).bottomNavigationActionSelected}
                }}
                label={strings.instrumentCard.smmallAdmin}
                icon={<Flag/>}/>
              {this.state.isLoading && <LoadingSpinner /> }
            </BottomNavigation>
          </DialogActions>
        </>
      );
    }
    return (
      <DialogContent sx={{ "&.MuiDialogContent-root": {...styles(theme).dialogContent}}}>
        <Box component="div" sx={{...styles(theme).cards}}>
          <Box component="div" sx={{...styles(theme).column}}>
            <Card sx={{...styles(theme).card}}>
              <InstrumentCardIdentification instrument={instrument} showTitle/>
            </Card>
            <Card sx={{...styles(theme).card}}>
              <InstrumentCardMetrologie instrument={instrument} showTitle/>
            </Card>
          </Box>
          <Box component="div" sx={{...merge({}, styles(theme).column, classes.rightColumn)}}>
            <Box component="div" sx={{...styles(theme).imageBlock}}>
              <InstrumentImage instrument={instrument}/>
            </Box>
            <Card sx={{...styles(theme).card}}>
              <InstrumentCardAdministration instrument={instrument} showTitle/>
            </Card>
          </Box>
          {this.state.isLoading && <LoadingSpinner /> }
        </Box>
      </DialogContent>);
  };

  // Render
  render() {
    return (
      <Dialog
        fullScreen={this.props.onMobile}
        fullWidth={this.state.fullWidth}
        sx={{
          "& .MuiDialog-paperWidthSm": isNotComputer ? {...styles(theme).tabletPopUp} : {...styles(theme).popUp},
          "& .MuiDialog-paperScrollPaper": {...styles(theme).paperScrollPaper},
          "& .MuiDialog-paper": {...styles(theme).paper}
        }}
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle sx={{ "&.MuiDialogTitle-root": {...styles(theme).dialogTitle}}}>
          <InstrumentCardHeader
            onClose={this.props.onClose}
            instrument={this.props.instrument}
            showImage={this.props.onMobile}
            toggleInstrumentUpdateLoader={this.toggleInstrumentUpdateLoader}
          />
        </DialogTitle>
        {this.generateContent(styles(theme), this.props.instrument)}
      </Dialog>
    );
  }
}

InstrumentCard.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  instrument: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps))(InstrumentCard);
