import React, { Component } from 'react';
import { Check, Home } from '@mui/icons-material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';

import StepWizard from '../../Containers/StepWizard/StepWizard';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { InterventionSteps as steps } from '../../Utils/Constants/InterventionSteps';
import CartStep from '../InterventionRequest/Steps/CartStep/CartStep';
import { unlock, updateCurrentPage, updateToolbarInfo } from '../../store/actions/exportActions';
import { SnackbarMessages } from '../../Utils/Constants/SnackbarMessages';
import InterventionFormStep from './Steps/InterventionFormStep/InterventionFormStep';
import InterventionSubmitStep from './Steps/InterventionSubmitStep/InterventionSubmitStep';
import InterventionAnswerStep from './Steps/InterventionAnswerStep/InterventionAnswerStep';
import ValidationDialog from '../Dialogs/ValidationDialog/ValidationDialog';
import RoleHelper from '../../Helpers/RoleHelper';
import { RoleConstants } from '../../Utils/Constants/RoleConstants';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import { Validator, ValidatorRules } from '../../Utils/Validator';
import { withGuide } from '../Guides/Guidable';
import StartGuideButton from '../Utils/StartGuideButton/StartGuideButton';
import { enqueueSnackbar } from 'notistack';

export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
    updateCurrentPage: pageID => dispatch(updateCurrentPage(pageID)),
    unlock: () => dispatch(unlock())
  };
};

export class InterventionPage extends Component {

  state = {
    activeStep: steps.CART,
    validationDialogIsOpen: false,
    instruments: [],
    intervention: {
      date: dayjs(),
      type: {
        id: '',
        value: '',
        canSelectStatus: true,
        canSelectState: true,
      },
      location: '',
      providerType: '',
      provider: {
        id: '',
        name: ''
      },
      technician: '',
      comment: '',
      state: {
        id: '',
        value: ''
      },
      status: {
        id: '',
        value: ''
      },
      documents: []
    },
    submitted: false,
  };

  isStepTwoValid = () => {
    // Element to check before submit button.
    let listToCheck = [
      this.state.intervention.type.id,
      this.state.intervention.location,
      this.state.intervention.providerType,
      this.state.intervention.provider.id,
      this.state.intervention.technician,
      this.state.intervention.status.id,
      this.state.intervention.state.id
    ];

    const indexStatus = listToCheck.indexOf(this.state.intervention.type.canSelectStatus);
    const indexState = listToCheck.indexOf(this.state.intervention.type.canSelectState);

    if (!this.state.intervention.type.canSelectStatus &&
      !this.state.intervention.type.canSelectState === false) {
      listToCheck.splice(indexStatus, 1);
      listToCheck.splice(indexState, 1);
    } else if (!this.state.intervention.type.canSelectState) {
      listToCheck.splice(indexState, 1);
    } else {
      listToCheck.splice(indexStatus, 1);
    }

    return listToCheck.map(field => Validator.checkValidity(field, [ValidatorRules.NOT_EMPTY]))
      .reduce((acc, curr) => acc && curr);
  };

  submitInterventions = () => {
    this.setState({ validationDialogIsOpen: false, activeStep: steps.ANSWER });
  };

  applyInterventionValues = callback => {
    const instrumentsWithIntervention = this.state.instruments.map(i => {
      i.intervention = {
        ...i.intervention,
        documents: this.state.intervention.documents,
        state: this.state.intervention.state,
        status: this.state.intervention.status
      };
      return i;
    });
    this.setState({ instruments: instrumentsWithIntervention }, callback);
  };

  // Update intervention fields for each selected instruments
  updateInterventionStateField = (name, value, addDocuments = false) => {
    this.setState(prevState => (
      {
        ...prevState,
        intervention: {
          ...prevState.intervention,
          [name]: addDocuments ? prevState.intervention.documents.concat(value) : value
        }
      }
    ));
  };

  updateInstrumentsWithInterventions = (name, value, instrument, addDocument = false) => {
    const instrumentIndex = this.state.instruments.map(i => i.marquage).indexOf(instrument.marquage);
    this.setState(prevState => (
      {
        ...prevState,
        instruments: prevState.instruments.map(inst =>
          inst.marquage === instrument.marquage
            ? {
              ...inst,
              intervention:
                {
                  ...inst.intervention,
                  [name]: addDocument
                    ? prevState.instruments[instrumentIndex].intervention.documents.concat(value) : value
                }
            }
            : inst
        )
      }
    ));
  };

  addOrRemoveInstruments = (instruments, add = true) => {
    const instrumentsToAdd = [];
    let errorsCount = 0;
    let targetInstruments = instruments;

    if (add) {
      targetInstruments = instruments.length > 1 ? targetInstruments : [targetInstruments];
      // Check if user has T right on each instrument
      targetInstruments.forEach(instrument => {
        const hasAccess = RoleHelper
          .getUserInstrumentAccess(this.props.activeUser, instrument) === RoleConstants.T_LEVEL;
        if (hasAccess) {
          instrumentsToAdd.push(instrument);
        } else {
          errorsCount++;
        }
      });

      if (errorsCount > 0) {
        enqueueSnackbar(
          SnackbarMessages.errorAddInstrumentsToIntervention.msg(errorsCount),
          SnackbarMessages.errorAddInstrumentsToIntervention.type
        );
      }
    }

    this.setState(prevState => ({
      instruments: add ?
        prevState.instruments.concat(instrumentsToAdd)
        : prevState.instruments.filter(instr => instr.marquage !== targetInstruments.marquage)
    }));
  };

  handleNext = () => {
    const currentStep = this.state.activeStep;
    switch (currentStep) {
      case steps.CART: {
        if (this.state.instruments.length > 0) {
          this.setState({ activeStep: steps.FORM });
          this.props.unlock();
        } else {
          // Error
          enqueueSnackbar(
            SnackbarMessages.stepperValidationError[currentStep].msg,
            SnackbarMessages.stepperValidationError[currentStep].type
          );
        }
        break;
      }
      case steps.FORM: {
        this.setState({ submitted: true });
        if (this.isStepTwoValid()) {
          this.applyInterventionValues(() => this.setState({ activeStep: steps.SUBMIT }));
          this.props.unlock();
        } else {
          // Error
          enqueueSnackbar(
            SnackbarMessages.stepperValidationError[currentStep].msg,
            SnackbarMessages.stepperValidationError[currentStep].type
          );
        }
        break;
      }
      case steps.SUBMIT: {
        this.setState({ validationDialogIsOpen: true });
        break;
      }
      default:
        break;
    }
  };

  handlePrev = () => {
    if (this.state.activeStep !== steps.ANSWER) {
      this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
    } else {
      return null;
    }
  };

  renderHeaderIcons = () => {
    if (this.state.activeStep === steps.ANSWER) {
      return (
        <IconButton
          id="newInterventionButton"
          onClick={() => this.props.updateCurrentPage(PAGES_IDS.INTERVENTION)}
          size="large">
          <Home color="secondary"/>
        </IconButton>
      );
    } else if (this.state.activeStep === steps.CART) {
      return <StartGuideButton withLabel/>;
    }
  };

  render() {
    const headerProps = {
      title: strings.intervention.title,
      icon: <Check/>,
      renderHeaderIcons: this.renderHeaderIcons,
    };

    return (
      <>
        {/* WIZARD */}
        <StepWizard
          id="stepper"
          steps={[strings.intervention.cartStep,
            strings.intervention.formStep,
            strings.intervention.submitStep,
            strings.intervention.answerStep]}
          activeStep={this.state.activeStep}
          onNextClicked={() => this.handleNext()}
          onPrevClicked={() => this.handlePrev()}
          {...headerProps}
        >
          {/* CART STEP */}
          {this.state.activeStep === steps.CART && (
            <CartStep
              addOrRemoveInstruments={this.addOrRemoveInstruments}
              selectedInstruments={this.state.instruments}
            />
          )}

          {/* FORM STEP */}
          {this.state.activeStep === steps.FORM && (
            <InterventionFormStep
              updateInstrumentsWithInterventions={this.updateInstrumentsWithInterventions}
              updateInterventionStateField={this.updateInterventionStateField}
              intervention={this.state.intervention}
              submitted={this.state.submitted}
              instruments={this.state.instruments}
            />
          )}

          {/* SUBMIT STEP */}
          {this.state.activeStep === steps.SUBMIT && (
            <InterventionSubmitStep
              instruments={this.state.instruments}
              intervention={this.state.intervention}
              updateInstrumentsWithInterventions={this.updateInstrumentsWithInterventions}
            />
          )}

          {/* ANSWER STEP */}
          {this.state.activeStep === steps.ANSWER && (
            <InterventionAnswerStep
              intervention={this.state.intervention}
              instruments={this.state.instruments}
              updateToolbarInfo={this.props.updateToolbarInfo}
            />
          )}
        </StepWizard>

        {/* DIALOGS */}
        <ValidationDialog
          id="validationDialog"
          open={this.state.validationDialogIsOpen}
          title={strings.intervention.validateTitle}
          text={strings.intervention.validateLabel}
          onRefuse={() => this.setState({ validationDialogIsOpen: false })}
          onAccept={() => this.submitInterventions()}
          refuseLabel={strings.dialog.answer.cancel}
          acceptLabel={strings.dialog.answer.submit}
        />
      </>
    );
  }
}

InterventionPage.propTypes = {
  updateToolbarInfo: PropTypes.func.isRequired,
  updateCurrentPage: PropTypes.func.isRequired,
  activeUser: PropTypes.object.isRequired,
  unlock: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(withGuide(InterventionPage));
