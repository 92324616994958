import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  closeBtn: {
    position: 'absolute',
    margin: "16px"
  },
  cameraDialog: {
    backgroundColor: 'black'
  },
  cameraContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  startDialog: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden'
  },
  startText: {
    color: theme.palette.pureWhite
  }
});
