import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  inlineBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: 'space-between'
  },
  pagination: {
    marginLeft: "16px"
  },
  buttons: {
    marginRight: "8px"
  },
  titleContainer: {
    backgroundColor: theme.palette.dialogTheme.header
  }
});
