import React, { Component } from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { styles } from './HistoryInterventionHome.style';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { Build, PictureAsPdf } from '@mui/icons-material';
import { updateToolbarInfo } from '../../store/actions/exportActions';
import Scrollbar from 'react-scrollbars-custom';
import HistoryInterventionItem from './HistoryInterventionItem/HistoryInterventionItem';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import DateFilterInfo from '../Utils/DateFilterInfo/DateFilterInfo';
import InterventionItemInfo from './HistoryInterventionItemInfo/InterventionItemInfo';
import InstrumentCard from '../InstrumentCard/InstrumentCard';
import InterventionService from '../../Providers/WebService/InterventionService';
import ValidationDialog from '../Dialogs/ValidationDialog/ValidationDialog';
import { trackScrolling } from '../../Utils/ScrollUtils';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import { InterventionRequestConstants } from '../../Utils/Constants/InterventionRequestConstants';
import LoadingDialog from '../Dialogs/LoadingDialog/LoadingDialog';
import { logger } from '../../Utils/Logger';
import { theme } from '../../Utils/Theme';
import merge from 'lodash/merge';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(isSameOrAfter);

export const mapStateToProps = state => {
  return {
    selectedDate: state.toolbarReducer.selectedDate
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};

const INTERVENTION_STEP = 40;

class HistoryInterventionHome extends Component {

  state = {
    isLoading: true,
    activeDateFilter: null,
    interventionCountToShow: INTERVENTION_STEP,
    interventions: [],
    selectedItem: null,
    interventionToValidate: null,
    validationText: '',
    isDeleteValidation: false,
    pdfDocument: null,
    waitingForResult: false,

  };

  componentDidMount() {
    // Display toolbar's content (icon, title, iconButtons)
    this.props.updateToolbarInfo({
      title: strings.mainMenu.historyIntervention,
      icon: (<Build />),
      actions: [ToolbarActionsConstants.CALENDAR_FILTER]
    });

    this.refreshInterventionsRequests();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedDate !== this.props.selectedDate) {
      this.setState({ activeDateFilter: this.props.selectedDate });
    }
  }

  refreshInterventionsRequests = () => {
    InterventionService.getAllInterventions().then(res => {
      // Sort by creation date
      res.data.sort((a, b) => a.id > b.id ? -1 : 1);
      this.setState({ isLoading: false, interventions: res.data });
    }).catch(logger);
  };

  getItemsIntervention = () => {
    let count = 0;
    return this.state.interventions.map(item => {
      if (count < this.state.interventionCountToShow &&
        (!this.state.activeDateFilter || dayjs(item.date).isSameOrAfter(this.props.selectedDate, 'day'))) {

        count++;
        return <HistoryInterventionItem
          key={item.id}
          interventionInfo={item}
          onClick={selectedItem => this.setState({ selectedItem })}
        />;
      }
      return null;
    });
  };

  onBottomReach = () => {
    this.setState({ interventionCountToShow: this.state.interventionCountToShow + INTERVENTION_STEP });
  };

  render() {
    return (
      <Box component="div" sx={{...styles(theme).pageContent}}>
        <Box component="div" sx={{...styles(theme).header}}>
          {this.state.activeDateFilter &&
            <DateFilterInfo
              id="dateFilter"
              resetDatePicker={() => this.setState({ activeDateFilter: null })}
              date={this.state.activeDateFilter}
            />}
          {this.state.selectedItem &&
            <InterventionItemInfo
              id="item"
              interventionInfo={this.state.selectedItem}
              onClose={() => this.setState({ selectedItem: null })}
              onInstrumentClick={instrument => this.setState({ selectedInstrument: instrument })}
            />}
          {this.state.selectedInstrument &&
            <InstrumentCard
              id="instrumentCard"
              open
              instrument={this.state.selectedInstrument}
              onClose={() => this.setState({ selectedInstrument: null })}
            />
          }
        </Box>
        <Box component="div" sx={{...styles(theme).listContainer}}>
          {
            this.state.isLoading ?
              <Box component="div" sx={{...styles(theme).loadingMessage}}>
                <Typography variant='subtitle1'>{strings.interventionHome.loadingMessage}</Typography>
                <LinearProgress />
              </Box>
              :
              <Scrollbar
                style={{...merge({}, styles(theme).scrollArea, styles(theme).scan_sized)}}
                onScroll={scrollValues => trackScrolling(scrollValues, this.onBottomReach)}
                noScrollX>
                <Box component="div" sx={{...styles(theme).interventionList}}>
                  {this.getItemsIntervention()}
                </Box>
              </Scrollbar>
          }
        </Box>
        <ValidationDialog
          id="validationDialog"
          open={this.state.interventionToValidate !== null}
          title={strings.dialog.title.areYouSure}
          text={this.state.validationText}
          refuseLabel={strings.dialog.answer.no}
          onRefuse={() => this.setState({ interventionToValidate: null })}
          acceptLabel={strings.dialog.answer.yes}
          onAccept={() => {
            if (this.state.isDeleteValidation) {
              this.deleteInterventionRequest(this.state.interventionToValidate);
            }
            else {
              this.updateInterventionRequestDate(this.state.interventionToValidate);
            }
          }} />

        <SimpleDialog
          id="simpleDialog"
          open={this.state.pdfDocument !== null}
          title={strings.dialog.title.success}
          acceptLabel={strings.general.ok}
          onAccept={() => this.setState({ pdfDocument: null })}
        >
          <Typography >{strings.interventionHome.demandSuccess}</Typography>
          <Button sx={{ "&.MuiButton-root": {...styles(theme).menuAction}}}
            target="_blank"
            color="primary"
            variant="contained"
            href={InterventionRequestConstants.PDF_DATA + this.state.pdfDocument}
            download={InterventionRequestConstants.PDF_TITLE}
          >
            <PictureAsPdf sx={{...styles(theme).pdfIcon}} />
            {strings.interventionHome.downloadPDF}
          </Button>
        </SimpleDialog>

        <LoadingDialog
          open={this.state.waitingForResult}
          title={strings.general.loading}
          label={strings.interventionHome.updateValidationLoading}
        />
      </Box>
    );
  }
}

HistoryInterventionHome.propTypes = {
  updateToolbarInfo: PropTypes.func,
  handleIntervention: PropTypes.func.isRequired,
  selectedDate: PropTypes.object
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(HistoryInterventionHome);
