import { osName, osVersion, browserName, browserVersion, mobileVendor, mobileModel } from 'react-device-detect';

import { UserConstants } from '../Utils/Constants/UserConstants';
import UserService from '../Providers/WebService/UserService';
import db from '../Utils/Database/Database';
import { JwtHelperService } from '../Utils/JwtHelperService';
import { DeviceInformations } from '../Utils/Constants/DeviceInformations';
import { logger } from '../Utils/Logger';

const userLoginField = UserConstants.userLoginDB;

class UserHelper {


  /**
   * @typedef RoleModel
   * @type {object}
   * @property {number} id
   * @property {string} financialUnit
   * @property {string} geographicalUnit
   * @property {string} organizationalUnit
   * @property {string} value
   */

  /**
   * @typedef UserModel
   * @type {object}
   * @property {Set<string>} instruments
   * @property {string} userLogin
   * @property {Array<RoleModel>} roles
   */

  static getUserInfosFromServerAndUpdateDatabase(password, callback) {
    UserService.getLoggedUserInformations()
      .then(res => {
        // add the informations in local db
        return db.usersInformations.add({
          userLogin: res.data.login,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          profile: res.data.profil,
          email: res.data.emailAdress,
          phone: res.data.workPhone,
          mobile: res.data.mobilePhone,
          geographicalUnitUser: res.data.geographicalUnitUser,
          organizationalUnitUser: res.data.organizationalUnitUser,
          financialUnitUser: res.data.financialUnitUser,
          site: res.data.site,
          entite: res.data.entite,
          section: res.data.section,
          refreshTokenCreatedAt: res.data.refreshTokenCreatedAt,
          desactivationDate: res.data.desactivationDate,
          address: res.data.address,
          fax: res.data.fax,
          roles: res.data.roles,
          instruments: new Set(),
          pwdHash: password
        })
          .catch(() => {
            // if the user info already exist, we update info instead
            db.usersInformations.where(userLoginField).equalsIgnoreCase(JwtHelperService.getUserLogin()).modify(user => {
              user.firstName = res.data.firstName;
              user.lastName = res.data.lastName;
              user.profile = res.data.profil;
              user.email = res.data.emailAdress;
              user.phone = res.data.workPhone;
              user.mobile = res.data.mobilePhone;
              user.geographicalUnitUser = res.data.geographicalUnitUser;
              user.organizationalUnitUser = res.data.organizationalUnitUser;
              user.financialUnitUser = res.data.financialUnitUser;
              user.site = res.data.site;
              user.entite = res.data.entite;
              user.section = res.data.section;
              user.refreshTokenCreatedAt = res.data.refreshTokenCreatedAt;
              user.desactivationDate = res.data.desactivationDate;
              user.address = res.data.address;
              user.fax = res.data.fax;
              user.roles = res.data.roles;
              user.pwdHash = password !== null ? password : user.pwdHash;
            });
          });
      })
      .catch(logger)
      .finally(() => {
        // return user informations
        return db.usersInformations
          .where(userLoginField).equalsIgnoreCase(JwtHelperService.getUserLogin()).first(callback)
          .catch(logger);
      });
  }

  static saveDeviceInformations() {
    localStorage.setItem(DeviceInformations.OS_NAME, osName);
    localStorage.setItem(DeviceInformations.OS_VERSION, osVersion);
    localStorage.setItem(DeviceInformations.BROWSER_NAME, browserName);
    localStorage.setItem(DeviceInformations.BROWSER_VERSION, browserVersion);
    localStorage.setItem(DeviceInformations.MOBILE_VENDOR, mobileVendor);
    localStorage.setItem(DeviceInformations.MOBILE_MODEL, mobileModel);
  }

}
export default UserHelper;
