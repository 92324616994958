import {
  LOCK_GUIDE,
  UNLOCK_GUIDE,
  SET_STATUS,
  SET_MODE
} from '../actions/actionTypes';
import { LOCK, STATUSES, MODE } from '../../Utils/Constants/GuideConstants';

const initialState = {
  locked: LOCK.NONE,
  status: STATUSES.PENDING,
  lockTarget: null,
  mode: MODE.CLASSIC
};

const lock = (state, action) => {
  return { ...state, locked: action.payload.type, lockTarget: action.payload.target };
};

const unlock = (state, action) => {
  if (state.status !== STATUSES.RUNNING || state.locked === LOCK.NONE
    || (action.payload && !Array.isArray(action.payload) && action.payload !== state.lockTarget)
    || (Array.isArray(action.payload) && !action.payload.includes(state.lockTarget))) {
    return state;
  }
  return { ...state, locked: LOCK.NONE };
};

const setStatus = (state, action) => {
  if (state.status === STATUSES.RUNNING && [STATUSES.READY, STATUSES.INITIATED].includes(action.payload)) {
    return state;
  }
  return { ...state, status: action.payload };
};

const setMode = (state, action) => {
  if (state.status === STATUSES.RUNNING || state.mode === action.payload) {
    return state;
  }
  return { ...state, mode: action.payload };
};

const guideReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCK_GUIDE: return lock(state, action);
    case UNLOCK_GUIDE: return unlock(state, action);
    case SET_STATUS: return setStatus(state, action);
    case SET_MODE: return setMode(state, action);
    default: return state;
  }
};

export default guideReducer;
