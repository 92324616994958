import {
  shape,
  number,
  string,
  bool,
  oneOfType
} from 'prop-types';

export const instrumentType = shape({
  periodiciteProchaineOperationTimestamp: number,
  periodiciteProchaineOperationDatetime: string,
  encoursBool: bool,
  id: number,
  marquage: string,
  constructeur: string,
  referenceConstructeur: string,
  numeroSerie: string,
  designation: string,
  domaine: string,
  affectation: string,
  site: string,
  section: string,
  sousSection: string,
  destinataire: string,
  utilisateur: string,
  localisation: string,
  planning: string,
  etat: string,
  periodicitePeriode: number,
  periodiciteUnite: string,
  periodiciteProchaineOperation: string,
  application: string,
  periodiciteOperation: string, dateCreation: string,
  options: string,
  proprietaire: string,
  etatMetrologique: string,
  parc: string,
  entite: string,
  unit: string,
  idCris: string,
  ancienMarquage: string,
  encours: oneOfType([bool, string]),
  coutJour: number,
  emplacementReference: string,
  finPrevisionnelleLocation: string,
  prochainEvenement: string,
  sousFamille: string,
  motifEnvoi: string,
  dateOperation: string
});
