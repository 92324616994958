import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import sharedStyles from '../../../Utils/SharedStyles';

const { FULL_VIEWPORT_WIDTH } = globalConstants.style;

export let styles = theme => ({
  ...sharedStyles(theme),
  main: {
    width: FULL_VIEWPORT_WIDTH,
    padding: '3% 0',
    // Fix IE 11 issue.
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.mainPageBackground
  },
  paper: {
    // desktop half-window and tablets portrait
    [theme.breakpoints.only('sm')]: {
      width: '55vw'
    },
    width: '20vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
    backgroundColor: theme.palette.secondary.main
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  trackingTitle: {
    textAlign: 'left',
    color: theme.palette.veryDarkBlueTheme.main
  },
  cardArea: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    [theme.breakpoints.only('xs')]: {
      height: '100%',
      justifyContent: 'center'
    }
  },
  paper_container: {
    display: 'flex',
    height: 'calc(100% - 64px)'
  },
  logOut_button: {
    alignSelf: 'center',
    // one-use color
    border: 'rgba(2, 55, 92, 0.39) solid 1px',
    padding: '5px 35px',
    borderRadius: "8px",
    marginTop: "40px",
    marginBottom: "10px",
    color: theme.palette.veryDarkBlueTheme.main,
  },
  borrow: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    }
  },
  borrowed: {
    backgroundColor: theme.palette.blueTheme.main,
    '&:hover': {
      backgroundColor: theme.palette.blueTheme.hover,
    }
  },
  return: {
    backgroundColor: theme.palette.greenTheme.main,
    '&:hover': {
      backgroundColor: theme.palette.greenTheme.hover,
    }
  },
  track_buttons: {
    padding: "30px",
    color: theme.palette.pureWhite,
    margin: '40px 0 10px 0'
  },
  track_labels: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.pureWhite,
  },
  icon_root: {
    fontSize: "60px"
  },
  buttons_area: {
    margin: 'auto'
  },
  '@media (orientation: landscape)': {
    main: {
      [theme.breakpoints.down('md')]: {
        // fix for centering issue on mobile landscape
        height: 'auto',
      }
    },
    paper: {
      [theme.breakpoints.only('md')]: {
        // landscape tablet and desktop window
        width: '45vw'
      },
    }
  }
});
