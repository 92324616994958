import React from 'react';
import {
  Typography,
  IconButton,
  Paper,
  Icon,
  Box,
} from '@mui/material';
import {
  NotificationsNone,
  Notifications
} from '@mui/icons-material';
import InstrumentImage from '../../../InstrumentCard/InstrumentImage/InstrumentImage';
import { styles } from './InstrumentItemListTemplate.style';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';
import { theme } from '../../../../Utils/Theme';

const InstrumentItemListTemplate = props => {

  return (
    <Paper elevation={2} id="itemContainer" sx={{...styles(theme).card}} onClick={() => props.onClick(props.instrument)}>
      <Box component="div" sx={{...styles(theme).instrumentImage}}>
        <InstrumentImage instrument={props.instrument} />
      </Box>
      <Box component="div" sx={{...styles(theme).center}}>
        <Typography
          sx={{...styles(theme).designation}}
          variant="h6"
          noWrap
        >
          {props.instrument.designation}
        </Typography>
        <Typography sx={{...styles(theme).secondaryInformation}} variant="body2">
          {props.instrument.referenceConstructeur}
        </Typography>
        <Typography sx={{...styles(theme).secondaryInformation,
          ...styles(theme).boldMarquage}}
        variant="body1">
          {props.instrument.marquage}
        </Typography>
      </Box>
      <Box component="div" sx={{...styles(theme).right}}>
        <Box component="div" sx={{...styles(theme).icons}}>
          {props.dotColor &&
            <Icon
              id="dot"
              style={{ '--backgroundColor': props.dotColor }}
              sx={{ "&.MuiIcon-root": {...styles(theme).dot}}}
              onClick={e => {
                e.stopPropagation();
                props.onDotClick(props.instrument);
              }} />
          }
          <IconButton
            id="favoriteButton"
            sx={{...styles(theme).favorite}}
            onClick={event => props.onFavoriteClick(event)}
            disableRipple={isIOS}
            size="large">
            {props.isFavorite ? <Notifications
              sx={{ ...styles(theme).defaultColorText }} /> : <NotificationsNone
              sx={{ ...styles(theme).defaultColorText }} />}
          </IconButton>
        </Box>
        <Typography sx={{...styles(theme).secondaryInformation}} variant="body2">
          {props.instrument.constructeur}
        </Typography>
      </Box>
    </Paper >
  );
};

InstrumentItemListTemplate.propTypes = {
  instrument: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  isFavorite: PropTypes.bool.isRequired,
  dotColor: PropTypes.string,
  onDotClick: PropTypes.func,
};

export default React.memo(InstrumentItemListTemplate);
