import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addFavorite, removeFavorite } from '../../../store/actions/exportActions';
import InstrumentHelper from '../../../Helpers/InstrumentHelper';
import InstrumentItemCardTemplate from './Templates/InstrumentItemCardTemplate';
import InstrumentItemListTemplate from './Templates/InstrumentItemListTemplate';
import { theme } from '../../../Utils/Theme';
const _ = require('lodash');
// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    favorites: state.instrumentReducer.favorites
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    addFavorite: favorites => dispatch(addFavorite(favorites)),
    removeFavorite: favorites => dispatch(removeFavorite(favorites))
  };
};

class InstrumentListItem extends Component {

  shouldComponentUpdate(nextProp) {
    const isNotFavoriteAnymore = this.props.favorites.includes(this.props.instrument.marquage)
      && !nextProp.favorites.includes(this.props.instrument.marquage);

    const isNewFavorite = !this.props.favorites.includes(this.props.instrument.marquage)
      && nextProp.favorites.includes(this.props.instrument.marquage);

    const changeCardFormat = this.props.isCard !== nextProp.isCard;

    //Update instrument if data are changed due sychronisation
    const instrumentNeedUpdate = _.isEqual(this.props.instrument, nextProp.instrument);

    return isNotFavoriteAnymore || isNewFavorite || changeCardFormat
      || (!instrumentNeedUpdate);
  }

  isFavorite = () => this.props.favorites.indexOf(this.props.instrument.marquage) >= 0;

  onFavoriteClick = event => {
    // prevent the whole card to catch the event
    event.stopPropagation();

    if (this.isFavorite()) {
      InstrumentHelper.removeInstrumentFromFavorites(this.props.instrument.marquage);
      this.props.removeFavorite(this.props.instrument.marquage);
    }
    else {
      InstrumentHelper.addInstrumentToFavorite(this.props.instrument.marquage);
      this.props.addFavorite(this.props.instrument.marquage);
    }
  }

  loadTemplate(isCard) {
    if (isCard) {
      return (
        <InstrumentItemCardTemplate
          instrument={this.props.instrument}
          onClick={this.props.onClick}
          onFavoriteClick={this.onFavoriteClick}
          isFavorite={this.isFavorite()}
          horsDelais={InstrumentHelper.isHorsDelai(this.props.instrument)}
          etatColor={InstrumentHelper.generateState(this.props.instrument.etat, theme.palette)}
        />);
    } else {
      return (
        <>
          <InstrumentItemListTemplate
            instrument={this.props.instrument}
            onClick={this.props.onClick}
            onFavoriteClick={this.onFavoriteClick}
            isFavorite={this.isFavorite()}
            dotColor={InstrumentHelper.getColorStatusInstrument(this.props.instrument, theme)}
            onDotClick={this.props.onDotClick}
          />
        </>);
    }
  }

  render() {
    return this.loadTemplate(this.props.isCard);
  }
}

InstrumentListItem.propTypes = {
  favorites: PropTypes.array,
  addFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
  instrument: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  isCard: PropTypes.bool.isRequired,
  onDotClick: PropTypes.func.isRequired,
};

export default (connect(mapStateToProps, mapDispatchToProps)(InstrumentListItem));
