import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { styles } from './SummaryCard.style';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    multipleInterventionEnabled: state.interventionReducer.multipleInterventionEnabled,
    multipleInterventions: state.interventionReducer.multipleInterventions
  };
};

const SummaryCard = props => {

  const { serviceShippingPage } = props;

  function generateFields(label, value) {
    return (
      <>
        <Grid item xs={6}>
          <Typography sx={{...styles(theme).input_content}}><strong>{label}</strong></Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{...styles(theme).input_content}}>{value}</Typography>
        </Grid>
      </>
    );
  }

  return props.multipleInterventionEnabled ? (
    props.multipleInterventions.map(intervention => {
      if (intervention.instruments.filter(i => props.selectedInstruments.map(s => s.marquage).includes(i.marquage)).length === 0) {
        return null;
      }
      return (
        <Paper elevation={2} id="summary" key={intervention} sx={{...styles(theme).container}}>
          <Grid container spacing={1}>
            {generateFields(strings.interventionRequest.summaryCard.typeOfIntervention,
              intervention.serviceShippingPage.serviceTypeName)}
            {generateFields(strings.interventionRequest.summaryCard.shippingDate,
              intervention.serviceShippingPage.shippingDate.format(strings.general.dateFormat))}
            {generateFields(strings.interventionRequest.summaryCard.returnDate,
              intervention.serviceShippingPage.returnDate.format(strings.general.dateFormat))}
            {generateFields(strings.interventionRequest.summaryCard.observation,
              intervention.serviceShippingPage.observation)}
            {generateFields(strings.interventionRequest.summaryCard.provider,
              intervention.serviceShippingPage.provider.name)}
            {generateFields(strings.interventionRequest.summaryCard.carrier,
              intervention.serviceShippingPage.carrierMode.name)}
            {generateFields(strings.interventionRequest.summaryCard.instruments,
              intervention.instruments.map(i => i.marquage).join(','))}
          </Grid>
        </Paper>);
    })
  ) :
    (
      <Paper elevation={2} id="summary" sx={{...styles(theme).container}}>
        <Grid container spacing={1}>
          {generateFields(strings.interventionRequest.summaryCard.typeOfIntervention,
            serviceShippingPage.serviceTypeName)}
          {generateFields(strings.interventionRequest.summaryCard.shippingDate,
            serviceShippingPage.shippingDate.format(strings.general.dateFormat))}
          {generateFields(strings.interventionRequest.summaryCard.returnDate,
            serviceShippingPage.returnDate.format(strings.general.dateFormat))}
          {generateFields(strings.interventionRequest.summaryCard.observation,
            serviceShippingPage.observation)}
          {generateFields(strings.interventionRequest.summaryCard.provider,
            serviceShippingPage.provider.name)}
          {generateFields(strings.interventionRequest.summaryCard.carrier,
            serviceShippingPage.carrierMode.name)}
        </Grid>
      </Paper >
    );

};

SummaryCard.propTypes = {
  serviceShippingPage: PropTypes.object.isRequired,
  activeStep: PropTypes.shape({
    index: PropTypes.number,
    isValid: PropTypes.bool,
    multipleInterventionIndex: PropTypes.number,
  }),
  multipleInterventionEnabled: PropTypes.bool,
  multipleInterventions: PropTypes.array,
  selectedInstruments: PropTypes.array,
};

export default compose(
  connect(mapStateToProps)
)(SummaryCard);
