import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  administrationContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  editButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cardHeaderAction: {
    marginTop: theme.spacing(-1)
  }
});
