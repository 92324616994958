import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';

import { updateFormServiceShipping } from '../../../../../store/actions/exportActions';
import UserService from '../../../../../Providers/WebService/UserService';
import InterventionRequestService from '../../../../../Providers/WebService/InterventionRequestService';
import { styles } from '../ServiceShippingStep.style';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import { InterventionRequestConstants as constants } from '../../../../../Utils/Constants/InterventionRequestConstants';
import ResponsiveSelect from '../../../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    activeUser: state.generalReducer.loggedUser,
    onMobile: state.generalReducer.onMobile,
    activeStep: state.interventionReducer.activeStep
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    handleChange: (name, value) => dispatch(updateFormServiceShipping(name, value))
  };
};

class ShipperCard extends Component {

  state = {
    shippers: [],
    loading: true
  }

  componentDidMount() {
    const shouldNotInitialize = this.props.fromPreferencesPage && this.props.serviceShippingPage.shipperId !== '';
    // Load the list of possible shippers
    InterventionRequestService.getShippers().then(shippers => {
      const names = shippers.map(shipper => {
        return { id: shipper.id, name: shipper.completeName, identifier: shipper.login };
      });
      this.setState(
        { shippers: names, loading: shouldNotInitialize },
        () => this.initialFillShipper(shouldNotInitialize)
      );
    });
  }

  shouldComponentUpdate(prevProps, prevState) {
    // Ony update component if one of these fields change
    return (prevProps.serviceShippingPage.shipperName !== this.props.serviceShippingPage.shipperName)
      || (prevProps.serviceShippingPage.shipperAddress !== this.props.serviceShippingPage.shipperAddress)
      || (prevProps.serviceShippingPage.shipperPhone !== this.props.serviceShippingPage.shipperPhone)
      || (prevProps.serviceShippingPage.shipperFax !== this.props.serviceShippingPage.shipperFax)
      || (prevProps.serviceShippingPage.shipperMail !== this.props.serviceShippingPage.shipperMail)
      || (prevState !== this.state)
      || prevProps.activeStep.multipleInterventionIndex !== this.props.activeStep.multipleInterventionIndex;
  }

  componentDidUpdate(prevProps) {
    // Auto fill carrier field in multiple intervention
    if (prevProps.activeStep.multipleInterventionIndex < this.props.activeStep.multipleInterventionIndex) {
      this.initialFillShipper(false);
    }
  }

  initialFillShipper = needed => {
    if (needed) {
      this.setState({ loading: false });
      return;
    }
    // Load informations about current logged user
    const data = [];
    const user = this.props.activeUser;
    if (!user || !user.userLogin) {
      return;
    }

    try {
      const id = this.getIdForShipper(user.userLogin);
      data.push({ name: 'shipperId', value: id });
      data.push({ name: 'shipperName', value: (user.lastName + ' ' + user.firstName) });
      data.push({ name: 'shipperMail', value: user.email });
      data.push({ name: 'shipperAddress', value: user.address });
      data.push({ name: 'shipperPhone', value: user.phone });
      data.push({ name: 'shipperFax', value: user.fax });
      this.updateShipperFields(data);
    } catch (error) {
      // If current user is not in trescal users list: dont preload data, exit the function.
      return;
    }

    this.setState({ loading: false });
  };

  /** Get Trescal ID for sender  */
  getIdForShipper = login => this.state.shippers.find(shipper => shipper.identifier === login).id;

  /** Load informations about selected shipper, and then fill them into corresponding fields */
  handleShipperSelected = e => {
    this.props.handleChange({ name: e.target.name, value: e.target.value });
    let [lastName, firstName] = (e.target.value).split(' ');
    UserService.getUserInfosFromName(lastName, firstName).then(infos => {
      const data = [];
      data.push({ name: 'shipperId', value: this.getIdForShipper(infos.login) });
      data.push({ name: 'shipperMail', value: infos.emailAdress });
      data.push({ name: 'shipperAddress', value: infos.address });
      data.push({ name: 'shipperPhone', value: infos.workPhone });
      data.push({ name: 'shipperFax', value: infos.fax });
      this.updateShipperFields(data);
    });
  };

  /** Fill data into fields (by updating redux tree) */
  updateShipperFields = data => {
    data.forEach(field => {
      this.props.handleChange({ name: field.name, value: field.value });
    });
  };

  generateTextfieldItem = (name, label, value) => {
    return (<Grid item xs={12} sx={{...styles(theme).input_grid}}>
      <TextField
        id={name}
        variant="standard"
        name={name}
        label={label}
        onChange={e => this.props.handleChange({ name: e.target.name, value: e.target.value })}
        fullWidth
        value={value}
        InputProps={{
          sx: {...styles(theme).input_content}
        }}
        InputLabelProps={{
          sx: {...styles(theme).input_label}
        }}
      />
    </Grid>);
  };

  render() {
    const { serviceShippingPage } = this.props;
    const { SERVICE_SHIPPING_PAGE_NAMES } = constants;

    return (
      <Paper elevation={2} id="shipper" sx={{...styles(theme).container}}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{...styles(theme).area_title}} gutterBottom>
            {strings.interventionRequest.shipperTitle}
          </Typography>
          {this.state.loading && <CircularProgress size={20} />}
        </div>
        <Grid container spacing={this.props.onMobile ? 0 : 0.5}>
          <Grid item xs={12} sx={{...styles(theme).input_grid}}>
            <FormControl required fullWidth sx={{...styles(theme).formControl}}>
              <InputLabel htmlFor={SERVICE_SHIPPING_PAGE_NAMES.SHIPPER_NAME} sx={{...styles(theme).input_label, ...styles(theme).select_label}}>
                {strings.interventionRequest.shipperNameLabel}
              </InputLabel>
              <ResponsiveSelect
                id="shipperSelect"
                value={serviceShippingPage.shipperName}
                onChange={e => this.handleShipperSelected(e)}
                name={SERVICE_SHIPPING_PAGE_NAMES.SHIPPER_NAME}
                data={this.state.shippers}
                native={this.props.onMobile}
                isForShipper
                styledSelect={styles(theme).input_content}
              />
            </FormControl>
          </Grid>
          {this.generateTextfieldItem(SERVICE_SHIPPING_PAGE_NAMES.SHIPPER_ADDRESS,
            strings.interventionRequest.addressLabel, serviceShippingPage.shipperAddress)}
          {this.generateTextfieldItem(SERVICE_SHIPPING_PAGE_NAMES.SHIPPER_PHONE,
            strings.interventionRequest.phoneLabel, serviceShippingPage.shipperPhone)}
          {this.generateTextfieldItem(SERVICE_SHIPPING_PAGE_NAMES.SHIPPER_FAX,
            strings.interventionRequest.faxLabel, serviceShippingPage.shipperFax)}
          {this.generateTextfieldItem(SERVICE_SHIPPING_PAGE_NAMES.SHIPPER_MAIL,
            strings.interventionRequest.emailLabel, serviceShippingPage.shipperMail)}
        </Grid>
      </Paper>
    );
  }
}

ShipperCard.propTypes = {
  handleChange: PropTypes.func.isRequired,
  serviceShippingPage: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
  fromPreferencesPage: PropTypes.bool,
  onMobile: PropTypes.bool,
  activeStep: PropTypes.shape({
    index: PropTypes.number,
    isValid: PropTypes.bool,
    multipleInterventionIndex: PropTypes.number,
  }),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ShipperCard);
