export const styles = theme => ({
  selectedDateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.secondary,
    padding: '0px 8px',
    margin: '-4px 13px 0px',
    borderRadius: '0 0 8px 8px',
    borderBottom: `1px solid ${theme.palette.MainMenu.otherMenuOptions}`,
    borderLeft: `1px solid ${theme.palette.MainMenu.otherMenuOptions}`,
    borderRight: `1px solid ${theme.palette.MainMenu.otherMenuOptions}`,
  },
  selectedDateText: {
    display: 'flex',
  }
});
