import React from 'react';
import PropTypes from 'prop-types';
import { DialogContentText } from '@mui/material';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { styles } from '../SimpleDialog/SimpleDialog.style';
import { theme } from '../../../Utils/Theme';

const ValidationDialog = props => {

  return (
    <SimpleDialog
      open={props.open}
      onClose={props.onRefuse}
      title={props.title}
      acceptLabel={props.acceptLabel}
      refuseLabel={props.refuseLabel}
      redirectLabel={props.redirectLabel}
      onAccept={props.onAccept}
      onRefuse={props.onRefuse}
      onRedirect={props.onRedirect}
      addContentPadding
    >
      <DialogContentText sx={{ "&.MuiDialogContentText-root": {...styles(theme).tabletFontSize}}}>
        {props.text}
      </DialogContentText>
    </SimpleDialog>
  );
};

ValidationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onRefuse: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onRedirect: PropTypes.func,
  refuseLabel: PropTypes.string.isRequired,
  acceptLabel: PropTypes.string,
  redirectLabel: PropTypes.string
};

export default ValidationDialog;
