import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  dialog: {
    width: '45vw',
    height: '75vh',
  },
  listItem: {
    paddingRight: "100px"
  },
  activeFilter: {
    backgroundColor: theme.palette.validationGreenTheme.main
  }
});
