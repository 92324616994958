import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';

class ScanService {

  /** Create a scan on the server
   * scan: the scan to create
   *  - marquage
   *  - userLogin
   *  - date
   *  - method: the method of scan
   *  - scannedBarcodeType: the type of barcode (BarcodeType)
   *  - scannedBarcode: the barcode as it was when it was scanned
   *  - isInPerimeter
   *  - page
  */
  static async createScan(scan) {
    return HttpClient.postWithResponse(routes.createScan, scan);
  }

  static async getAllScans(excludingScanIds) {
    return HttpClient.postWithResponse(routes.getAllScans, excludingScanIds);
  }

  static async createManyScans(scans) {
    return HttpClient.postWithResponse(routes.createManyScans, scans);
  }

}

export default ScanService;
