import React from 'react';
import dayjs from 'dayjs';
import db from '../../Utils/Database/Database';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { InstrumentConstants } from '../../Utils/Constants/InstrumentConstants';
import { FILTER_DATE_FROM, FILTER_DATE_TO, FILTER_FIELDS, FILTER_TYPES } from '../../Utils/Constants/FilterConstants';
import { JwtHelperService } from '../../Utils/JwtHelperService';
import { UserConstants } from '../../Utils/Constants/UserConstants';
import { isFilterEmpty } from '../../Utils/FilterUtils';

const MAX_CHAR_SHORT_PREVIEW = 64;

class FilterProvider {

  constructor(filter) {
    this.setFilter(filter);
  }

  static isInitialized = false;

  /**
   * Init all the values for some filter params
   * @param {user Instruments} userInstruments
   */
  static initFilterValues(userInstruments) {

    if (FilterProvider.isInitialized) {
      return;
    }

    // init values
    for (const fieldKey in FILTER_FIELDS) {
      if (Object.prototype.hasOwnProperty.call(FILTER_FIELDS, fieldKey)) {
        const field = FILTER_FIELDS[fieldKey];
        if (field.type === FILTER_TYPES.LIST) {
          FILTER_FIELDS[fieldKey].values = new Set();
          // Load nullable values
          if (field.dbKey === InstrumentConstants.siteDB) {
            FILTER_FIELDS[fieldKey].values.add(null);
          }
        }
      }
    }

    // get all possible values
    db.instruments.where(InstrumentConstants.marquageDB).anyOf(userInstruments).each(instrument => {

      for (const fieldKey in FILTER_FIELDS) {
        if (Object.prototype.hasOwnProperty.call(FILTER_FIELDS, fieldKey)) {
          const field = FILTER_FIELDS[fieldKey];
          if (field.type === FILTER_TYPES.LIST) {
            FILTER_FIELDS[fieldKey].values.add(instrument[field.dbKey]);
          }
        }
      }

      FilterProvider.isInitialized = true;
    });
  }

  static getAllValuesFor(key) {
    return Array.from(FILTER_FIELDS[key].values).sort((a, b) => {
      if (a === null) {
        return -1;
      } else if (b === null) {
        return 1;
      } else {
        return a < b ? -1 : 1;
      }
    });
  }

  setFilterValue = (key, value) => {
    this._filter[key] = value;
  }

  removeFilterField = key => {
    delete this._filter[key];
  }

  getFilterValue = key => this._filter[key];

  addValue = (key, value) => {
    if (!this._filter[key]) {
      this._filter[key] = [];
    }
    else if (this._filter[key].indexOf(value) >= 0) {
      return false;
    }
    else {
      this._filter[key].push(value);

      return true;
    }
  }

  removeValue = (key, value) => {
    this._filter[key] = this._filter[key].filter(currentValue => currentValue !== value);
  }

  setFilter = filter => {
    this._filter = Object.assign({}, filter);
  }

  getFilter = () => this._filter;

  getFields = () => {
    const fields = [];

    for (const field in this._filter) {
      if (Object.prototype.hasOwnProperty.call(this._filter, field)) {
        fields.push(field);
      }
    }

    return fields;
  }

  /**
   * removes empty fields
   */
  cleanFilter = () => {
    for (const field in this._filter) {
      if (this._filter[field] === '' || this._filter[field].length === 0) {
        delete this._filter[field];
      }
    }
  }

  /**
   * Return if the filter is empty or not
   */
  isEmpty = () => {
    return isFilterEmpty(this._filter);
  }

  /**
   * Reset the optimisation of the filter.
   * @param {if we want a total release} total
   */
  release = total => {

    if (total) {
      this.optimizedCount = 0;
    }
    else {
      this.optimizedCount--;
    }

    if (this.optimizedCount <= 0) {
      this.optimizedDBKey = null;
      this.optimizedCount = 0;
    }
  }

  optimizedCount = 0;

  /**
   * Optimize the filter before the apply
   * @param {dexie instruments table} table
   */
  optimize = table => {

    this.optimizedCount++;
    let optimizedRequest = table;

    const tryOptimizeAnyOf = field => {
      if (this._filter[field.name]) {
        optimizedRequest = table.where(field.dbKey).anyOf(this._filter[field.name]);
        this.optimizedDBKey = field.dbKey;
      }
    };

    const tryOptimize = filterKey => {
      const field = FILTER_FIELDS[filterKey];

      switch (field.type) {
        case FILTER_TYPES.LIST:
        case FILTER_TYPES.STRING:
          tryOptimizeAnyOf(field);
          break;
        default:
          // DO NOTHING
          break;
      }
    };

    const filterKeyToOptimize = this.findFieldToOptimize();
    if (filterKeyToOptimize) {
      tryOptimize(filterKeyToOptimize);
    }

    return optimizedRequest;
  }

  /**
   * Find and return the best filter field to optimize among fields provided
   */
  findFieldToOptimize = () => {
    let currentFieldFilterKey = null;
    let currentMaxLength = 0;

    for (const filterKey in this._filter) {
      if (Array.isArray(this._filter[filterKey]) && this._filter[filterKey].includes(null)) {
        continue;
      }
      if (Object.prototype.hasOwnProperty.call(this._filter, filterKey)) {
        let field = FILTER_FIELDS[filterKey];
        // If field is marquage with 'equals', we take it directly (highest priority)
        if (field.filterOnEquality && field.dbKey === InstrumentConstants.marquageDB) {
          currentFieldFilterKey = filterKey;
          return currentFieldFilterKey;
        }
        if (field.values && field.values.size > currentMaxLength) {
          currentMaxLength = field.values.size;
          currentFieldFilterKey = filterKey;
        }
      }
    }

    return currentFieldFilterKey;
  }


  /**
   * Provide this function to the filter function
   */
  apply = item => {

    let match = true;

    for (const filterKey in this._filter) {
      if (Object.prototype.hasOwnProperty.call(this._filter, filterKey)) {
        match = this.matchFilter(item, filterKey);
        if (!match) {
          break;
        }
      }
    }
    return match;
  }

  matchFilter = (item, filterKey) => {

    const field = FILTER_FIELDS[filterKey];

    if (this.optimizedDBKey === field.dbKey || !this._filter[field.name]) {
      return true;
    }

    switch (field.type) {
      case FILTER_TYPES.STRING:
        return this.matchStringFilter(item, field.dbKey, this._filter[field.name]);
      case FILTER_TYPES.TOGGLE:
        return this.matchStringEquals(item[field.dbKey], this._filter[field.name]);
      case FILTER_TYPES.DATE_RANGE:
        return this.matchDateFilter(item[field.dbKey], this._filter[field.name]);
      case FILTER_TYPES.LIST:
      default:
        return this.matchStringFilterEquals(item, field.dbKey, this._filter[field.name]);
    }
  }

  // Check if the value is equals to the filter value (only one string)
  matchStringEquals = (value, filter) => value === filter;

  // Check if the given value is between the two dates in the filter
  matchDateFilter = (value, dateFilter) => {
    if (dateFilter && value) {
      const current = dayjs(value, strings.general.usDateFormat).toDate();

      const formattedDates = FilterProvider
        .getFormatedDateForFilter(dateFilter[FILTER_DATE_FROM], dateFilter[FILTER_DATE_TO]);

      return (current >= formattedDates.from) && (current <= formattedDates.to);
    }

    return dateFilter === undefined;
  }

  /**
   * Check if the current value match one value in the filter
   * @param {any} item - current item
   * @param {string} dbKey - dbKey to check
   * @param {Array<string>} filter - filter values
   * @param {Function} compareFunc - function to compare
   */
  matchFilterList = (item, dbKey, filter, compareFunc) => {
    const currentItem = item[dbKey];

    for (let i = 0; i < filter.length; i++) {
      if (compareFunc(currentItem?.toUpperCase(), filter[i] ? filter[i].toUpperCase() : '')) {
        return true;
      }
    }

    return false;
  }

  // check if the given value is part of the given filter (list of string)
  matchStringFilter = (item, dbKey, filter) =>
    this.matchFilterList(item, dbKey, filter, (value, filter2) => value && value.includes(filter2));

  // check if the given value is part (strict equals) of the given filter (list of string)
  matchStringFilterEquals = (item, dbKey, filter) =>
    this.matchFilterList(item, dbKey, filter, (value, filter2) => value === filter2);

  /**
   * Save the current filter with the given name.
   * If the filter already exist it will override the previous one.
   * @param {string} filterName - name of the filter
   */
  save = filterName => {
    this.cleanFilter();
    db.filters.put({ name: filterName, userLogin: JwtHelperService.getUserLogin(), filter: this._filter });
  }

  static getFilterDB(filterName) {
    return db.filters.where({ name: filterName, userLogin: JwtHelperService.getUserLogin() });
  }

  /**
   * load the filter with the given name
   * @param {string} filterName - name of the filter
   */
  load = filterName => {
    FilterProvider.getFilterDB(filterName).first().then(filter => {
      this._filter = filter;
    });
  }

  /**
   * Delete the given filter name
   * @param {string} filterName - name of the filter
   */
  static delete(filterName) {
    FilterProvider.getFilterDB(filterName).delete();
  }

  /**
   * Return the list of all the filters of the user
   */
  static getSavedFilters() {
    return db.filters.where(UserConstants.userLoginDB).equals(JwtHelperService.getUserLogin()).toArray();
  }

  /**
   * Return the from & to dates for the filter
   * @param {date} from
   * @param {date} to
   */
  static getFormatedDateForFilter(from, to) {

    // TODO is working for france but can lead to problems if in an other UTC...
    // Set to the day before
    const fromModified = dayjs(from).hour(-1).toDate();
    // Set the current hour to 23
    const toModified = dayjs(to).hour(23).toDate();

    return { from: fromModified, to: toModified };
  }

  /**
   * check if the two filters are the same
   */
  static areEquals(fa, fb) {

    if (!fa && !fb) {
      return true;
    }

    if ((!fa && fb) || (fa && !fb)) {
      return false;
    }

    return JSON.stringify(fa._filter) === JSON.stringify(fb._filter);
  }

  static buildFilterPreview(filter) {
    const preview = [];

    for (const field in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, field)) {
        let values;
        if (FILTER_FIELDS[field].type === FILTER_TYPES.TOGGLE) {
          values = filter[field];
        }
        else if (FILTER_FIELDS[field].type === FILTER_TYPES.DATE_RANGE) {
          values = <p>{strings.filter.dateFromTo(filter[field].from.toLocaleDateString(),
            filter[field].to.toLocaleDateString())}</p>;
        }
        else {
          values = <ul>{filter[field].map(item => (<li key={item}>{item}</li>))}</ul>;
        }

        preview.push(<div key={field}>
          <b>{strings.filter[field]}: </b>
          {values}
        </div>);
      }
    }

    return <React.Fragment>{preview.map(item => item)}</React.Fragment>;
  }

  static buildShortFilterPreview(filter) {
    let preview = '';

    for (const field in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, field)) {
        preview += strings.filter[field] + ', ';
      }
    }

    // remove last ', '
    preview = preview.slice(0, -2);

    if (preview.length > MAX_CHAR_SHORT_PREVIEW) {
      preview = preview.slice(0, MAX_CHAR_SHORT_PREVIEW) + '...';
    }

    return preview;
  }
}

export default FilterProvider;
