import sharedStyles from '../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  scrollArea: {
    height: '100%',
    overflowY: 'visible'
  },
  scrollAreaDownMd: {
    minHeight: "250px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: '100%'
  },
  scrollAreaOnlyXs: {
    marginTop: "0px"
  },
  instrumentsList: {
    paddingTop: "8px",
    [theme.breakpoints.down('md')]: {
      paddingBottom: "56px"
    }
  }
});
