import db from '../../Utils/Database/Database';
import { STATUSES, DATABASE } from '../../Utils/Constants/GuideConstants';

class GuideProvider {

  /**
   * Create a guide completion in locale db
   * @param {name of the guide} guideName
   * @param {the guide's status report} status
   */
  static async createGuide(guideName, status = STATUSES.PENDING) {
    return db.guides.add({
      name: guideName,
      status: status
    });
  }

  /**
   * Update a given guide
   * @param {name of the guide} guideName
   * @param {the guide's status report} status
   */
  static async updateGuide(guideName, status) {
    return db.guides.update(guideName, { status });
  }

  /**
   * Get a guide from locale database
   * @param {name of the guide to retrieve} guideName
   */
  static async getGuide(guideName) {
    const existingGuide = await db.guides.where(DATABASE.NAME).equals(guideName).first();
    return existingGuide;
  }

  /**
   * End a guide
   * @param {name of the guide} guideName
   */
  static async endGuide(guideName) {
    const guide = await GuideProvider.getGuide(guideName);
    if (guide) {
      // End the guide
      return GuideProvider.updateGuide(guideName, STATUSES.FINISHED);
    } else {
      // Create the guide with the "finished" status
      return GuideProvider.createGuide(guideName, STATUSES.FINISHED);
    }
  }

  /**
   * Check if a guide doesn't exist yet or has the "pending" status
   * @param {name of the guide} guideName
   */
  static async shouldDisplayGuide(guideName) {
    const existingGuide = await GuideProvider.getGuide(guideName);
    if (!existingGuide || existingGuide.status === STATUSES.PENDING) {
      return true;
    }
    return false;
  }

}

export default GuideProvider;