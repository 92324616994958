import React, { Component } from 'react';
import { styles } from './CartStep.style';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CartFilling from './CartFilling/CartFilling';
import InstrumentsSelection from './InstrumentsSelection/InstrumentsSelection';
import {
  updateToolbarInfo,
  updateActiveStep
} from '../../../../store/actions/exportActions';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import BuildIcon from '@mui/icons-material/Build';
import { ToolbarActionsConstants } from '../../../../Utils/Constants/ToolbarActionsConstants';
import Scannable from '../../../Utils/Scannable/Scannable';
import { ScanMethod } from '../../../../Utils/Constants/ScanMethod';
import { ToastService } from '../../../Utils/Toast/Toast';
import { TOAST_TYPES } from '../../../../Utils/Constants/ToastTypes';
import { ScanPages } from '../../../../Utils/Constants/ScanPage';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { theme } from '../../../../Utils/Theme';
import { Box } from '@mui/material';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    updateActiveStep: value => dispatch(updateActiveStep(value)),
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo))
  };
};

const currentScanPage = ScanPages.INTERVENTION_REQUEST;

export class CartStep extends Component {

  state = {
    mobileSearchViewOpen: false,
    currentScanMethod: ScanMethod.QUICKSCAN
  };

  currentValue = '';

  componentDidMount() {
    if (this.props.onMobile) {
      this.props.updateToolbarInfo({
        title: strings.interventionRequest.cartTitle,
        icon: (<BuildIcon />),
        actions: [ToolbarActionsConstants.EMPTY_CART, ToolbarActionsConstants.START_TUTORIAL]
      });
    } else {
      this.props.updateToolbarInfo({
        title: '',
        icon: (null),
        actions: []
      });
    }
  }

  isValid = () => this.props.selectedInstruments.length > 0;

  componentDidUpdate(prevProps) {
    if (this.props.selectedInstruments !== prevProps.selectedInstruments) {
      this.props.updateActiveStep({ index: 0, isValid: this.isValid() });
    }
    // If an instrument has been successfully scanned
    if (this.props.lastScannedInstrument && this.props.lastScannedInstrument !== prevProps.lastScannedInstrument) {
      if (!this.props.eligibleInstruments ||
        this.props.eligibleInstruments.includes(this.props.lastScannedInstrument.marquage)) {
        // If instrument is eligible, add it to the list
        this.props.addOrRemoveInstruments(this.props.lastScannedInstrument);
      } else {
        ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.ineligibleInstrument);
      }
    }
  }

  handleScan = barcode => {
    const data = { barcode, scanType: this.state.currentScanMethod, page: currentScanPage };
    if (!this.props.selectedInstruments.some(instr => instr.marquage === barcode)) {
      this.props.processScan(data);
    } else {
      ToastService.enqueueToast(TOAST_TYPES.WARNING, null, strings.toastMessage.duplicate);
    }
  };

  handleMobileSearchView = () => {
    this.setState(state => ({
      mobileSearchViewOpen: !state.mobileSearchViewOpen,
    }));
  };

  handleScanMethodChange = method => {
    this.setState({ currentScanMethod: method });
  };

  render() {
    const { onMobile } = this.props;
    if (onMobile) {
      return (
        <>
          <Divider sx={{...styles(theme).classic_divider}} />
          <Paper elevation={2} sx={{ "&.MuiPaper-root": {...styles(theme).stepper_pages_paper} }}>
            <CartFilling
              id="mobileCartFilling"
              handleMobileSearchView={this.handleMobileSearchView}
              handleScan={this.handleScan}
              onSelectScanMethod={this.handleScanMethodChange}
              currentScanMethod={this.state.currentScanMethod}
              selectedInstruments={this.props.selectedInstruments}
              addOrRemoveInstruments={this.props.addOrRemoveInstruments}
            />
            <InstrumentsSelection
              id="mobileInstrumentsSelection"
              open={this.state.mobileSearchViewOpen}
              handleMobileSearchView={this.handleMobileSearchView}
              instruments={this.props.eligibleInstruments}
              addOrRemoveInstruments={this.props.addOrRemoveInstruments}
              selectedInstruments={this.props.selectedInstruments}
            />
          </Paper>
        </>
      );
    } else {
      return (
        <Paper elevation={2} sx={{ "&.MuiPaper-root": {...styles(theme).stepper_pages_paper} }}>
          <Box component="div" sx={{...styles(theme).cartStep}}>
            <InstrumentsSelection
              id="webInstrumentsSelection"
              instruments={this.props.eligibleInstruments}
              addOrRemoveInstruments={this.props.addOrRemoveInstruments}
              selectedInstruments={this.props.selectedInstruments}
            />
            <CartFilling
              id="webCartFilling"
              handleScan={this.handleScan}
              onSelectScanMethod={this.handleScanMethodChange}
              currentScanMethod={this.state.currentScanMethod}
              selectedInstruments={this.props.selectedInstruments}
              addOrRemoveInstruments={this.props.addOrRemoveInstruments}
            />
          </Box>
        </Paper>
      );
    }
  }
}

CartStep.propTypes = {
  selectedInstruments: PropTypes.array.isRequired,
  addOrRemoveInstruments: PropTypes.func.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
  onMobile: PropTypes.bool.isRequired,
  updateToolbarInfo: PropTypes.func.isRequired,
  eligibleInstruments: PropTypes.array,
  processScan: PropTypes.func.isRequired,
  lastScannedInstrument: PropTypes.object
};

export default compose(
  Scannable,
  connect(mapStateToProps, mapDispatchToProps)
)(CartStep);
