import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Box, Button, LinearProgress, List, Typography } from '@mui/material';
import Scrollbar from 'react-scrollbars-custom';
import { styles } from '../../../Components/InstrumentsPage/InstrumentsPage.style';
import InstrumentsProvider from '../../../Providers/Database/InstrumentsProvider';
import FilterProvider from '../../../Providers/Database/FilterProvider';
import UserProvider from '../../../Providers/Database/UserProvider';
import InstrumentCard from '../../../Components/InstrumentCard/InstrumentCard';
import InstrumentsCountField from '../../../Components/InstrumentsPage/InstrumentsCountField/InstrumentsCountField';
import { trackScrolling } from '../../../Utils/ScrollUtils';
import SwitchToggleButton from '../../../Components/SwitchToggleButton/SwitchToggleButton';
import Xlsx from 'xlsx';
import * as _ from 'lodash';
import {
  FilterList as FilterIcon,
  FilterList,
  Notifications,
  NotificationsNone,
  PlaylistAdd as AddAllIcon,
  ViewList,
  ViewModule
} from '@mui/icons-material';
import InstrumentDotDialog from '../../../Components/Dialogs/InstrumentDotDialog/InstrumentDotDialog';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { isIOS } from 'react-device-detect';
import ExternalFilterHelper from '../../../Helpers/ExternalFilterHelper';
import SimpleDialog from '../../../Components/Dialogs/SimpleDialog/SimpleDialog';
import {
  instrumentListNeedsUpdate,
  setFilter,
  toggleFavorites,
  toggleFilterPage,
  resetFilters,
  updateShouldForceSync
} from '../../../store/actions/exportActions';
import InstrumentListItem from '../../../Components/InstrumentsPage/InstrumentListItem/InstrumentListItem';
import Instrument from '../../../Components/InterventionRequest/Steps/CartStep/Instrument/Instrument';
import { InterventionRequestConstants } from '../../../Utils/Constants/InterventionRequestConstants';
import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import ItemTemplate from '../../../Components/Inventory/InventoryListing/ItemTemplate/ItemTemplate';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import LoadingSpinner from '../../../Components/Utils/LoadingSpinner/LoadingSpinner';
import { SnackbarMessages } from '../../../Utils/Constants/SnackbarMessages';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';


const ELEMENT_STEP = 40;
const CARD = 'card';
const LIST = 'list';
const { viewTypes } = globalConstants;

const mapStateToProps = state => {
  return {
    activeFilter: state.toolbarReducer.activeFilter,
    isActiveFilterSet: state.toolbarReducer.isActiveFilterSet,
    onMobile: state.generalReducer.onMobile,
    showFavorites: state.toolbarReducer.showFavorites,
    favorites: state.instrumentReducer.favorites,
    shouldUpdateInstrumentList: state.instrumentReducer.shouldUpdateInstrumentList,
    shouldForceSync: state.generalReducer.shouldForceSync,
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    setFilter: filter => dispatch(setFilter(filter)),
    instrumentListNeedsUpdate: value => dispatch(instrumentListNeedsUpdate(value)),
    toggleFavorites: value => dispatch(toggleFavorites(value)),
    toggleFilterPage: value => dispatch(toggleFilterPage(value)),
    forceSync: value => dispatch(updateShouldForceSync(value)),
    resetFilters: value => dispatch(resetFilters(value)),
  };
};

class InstrumentList extends Component {

  //region REACT LIFECYCLE
  state = {
    instrumentsToShow: [],
    selectedInstrument: {},
    showInstrumentCard: false,
    filteredListCount: 0,
    showCards: true,
    instrumentFormat: CARD,
    showInstrumentDot: false,
    showNotBelongingMarkingsDialog: false,
    notBelongingInst: [],
  };

  constructor(props) {
    super(props);
    this.isLoading = true;
    this.activeFilter = undefined;
    this.isCounting = true;
    this.list = new Set();
  }

  async componentDidMount() {
    await this.loadInstruments();
    this.initializeFilters();
    await this.loadExternalFilterIfSet();
    this.updateList();
  }

  shouldComponentUpdate(nextProps) {
    // Always re-render except if favorite change. Instrument card will self-rerender
    return (nextProps.favorites === this.props.favorites);
  }

  async componentDidUpdate(prevProps) {
    // if the user updated an instrument which he no longer has access to, refresh whole list of instruments
    if (this.props.shouldForceSync) {
      await this.loadInstruments();
      await this.updateList();
    }
    if (this.props.shouldUpdateInstrumentList) {
      await this.updateList();
      this.props.instrumentListNeedsUpdate(false);
    }
    // reset list if filter is updated
    const updateFilter = prevProps.showFavorites !== this.props.showFavorites
      || prevProps.activeFilter !== this.props.activeFilter;
    if (updateFilter) {
      this.scrollLoading = true;
      this.updateList();
    }
  }

  //endregion

  //region CLASS METHODS
  loadInstruments = async () => {
    if (this.props.instruments && this.props.instruments.length > 0) {
      const instruments = await InstrumentsProvider.getInstrumentsFromMarquages(this.props.instruments);
      this.list = new Set(instruments);
    } else {
      this.list = await InstrumentsProvider.getUserInstruments();
    }
  };

  /**
   * Apply the external filter if it exists on the session storage
   */
  loadExternalFilterIfSet = async () => {
    if (ExternalFilterHelper.isExternalFilterSet()) {
      let filter = JSON.parse(ExternalFilterHelper.getExternalFilter());
      // check if any instrument marking does not belong to the user
      let notBelongingInst = [];
      if (filter.marquage) {
        notBelongingInst = await InstrumentsProvider.filterInstrumentsBelongingToUser(filter.marquage);
        // update the marquage filter part with the ones the user possesses
        filter.marquage = filter.marquage.filter(marking => !notBelongingInst.some(inst => inst === marking));
      }
      // update the external filter on the session storage
      ExternalFilterHelper.saveExternalFilter(filter);
      // show dialog if any marquage does not belong to the user
      if (notBelongingInst.length > 0) {
        this.setState({ showNotBelongingMarkingsDialog: true, notBelongingInst: notBelongingInst });
      }
      // if the filter is not empty, set it
      if (ExternalFilterHelper.isExternalFilterSet()) {
        this.props.setFilter(new FilterProvider(filter));
      }
    }
  };

  onDotClick = instrument => {
    this.setState({ selectedInstrument: instrument, showInstrumentDot: true });
  };

  onCloseDotDialog = () => {
    this.setState({ showInstrumentDot: false });
  };

  onItemClick = instrument => {
    this.setState({ selectedInstrument: instrument, showInstrumentCard: true });
  };

  getInstrumentList = () => this.props.showFavorites ? new Set(this.props.favorites) : this.list;

  getAllInstruments = () => {
    InstrumentsProvider.getMoreUserInstruments(
      this.getListForInstrumentProvider(true, this.getInstrumentList()),
      null,
      null,
      this.props.activeFilter).then(values => {
      return values;
    });
  };

  getListForInstrumentProvider = (isAdmin, list) => {
    if (this.props.instruments) {
      return this.props.instruments;
    }
    if (!isAdmin || this.props.showFavorites) {
      return list;
    }

    return null;
  };

  initializeFilters() {
    this.setState({
      filteredListCount: this.list.size
    });
    FilterProvider.initFilterValues(this.list);
  }

  refreshCount = 0;

  updateCount = isAdmin => {
    // if a filter is active we need to count the filtered instruments
    if (this.props.activeFilter) {
      this.isCounting = true;
      InstrumentsProvider.getFilteredListCount(this.getListForInstrumentProvider(isAdmin, this.getInstrumentList()),
        this.props.activeFilter)
        .then(result => {
          this.isCounting = false;
          if (this.refreshCount !== 0) {
            this.refreshCount--;
            this.updateCount(isAdmin);
          } else {
            this.setState({
              filteredListCount: result
            });
          }
        });
    }
    // else the filtered list count is the list size
    else {
      this.isCounting = false;
      this.setState({
        filteredListCount: this.getInstrumentList().size
      });
    }
  };

  haveInstrumentsBeenAddedOrRemoved = (currentList, dbList) => {
    const currentArray = Array.from(currentList);
    const dbArray = Array.from(dbList);
    return dbArray.some(inst => !currentArray.includes(inst)) || currentArray.some(inst => !dbArray.includes(inst));
  };

  getExportCsv = () => {

    UserProvider.userIsLocalAdmin().then(isAdmin => {
      const fileName = 'Instruments.xlsx';
      let snackbar = SnackbarMessages.instrumentDownloadPrepare;
      if (this.props.activeFilter) {
        enqueueSnackbar(snackbar.msg, snackbar.type);
        InstrumentsProvider.getUserAllInstrumentsFiltered(
          this.getListForInstrumentProvider(isAdmin, this.getInstrumentList()), this.props.activeFilter)
          .then(values => {
            snackbar = SnackbarMessages.instrumentDownloadStart;
            const wb = InstrumentsProvider.getExportExcel(values);
            enqueueSnackbar(snackbar.msg, snackbar.type);
            wb.then((workbook) => {
              Xlsx.writeFile(workbook, fileName);
            });


          }).catch(err => {
            snackbar = SnackbarMessages.instrumentDownloadFailed;
            enqueueSnackbar(snackbar.msg, snackbar.type);
          });
      }
      else {
        const listString = [];
        this.list.forEach(values => {
          listString.push(values);
        });
        enqueueSnackbar(snackbar.msg, snackbar.type);
        InstrumentsProvider.getInstrumentsFromListOfMarquages(listString)
          .then(values => {
            snackbar = SnackbarMessages.instrumentDownloadStart;
            const wb = InstrumentsProvider.getExportExcel(values);
            enqueueSnackbar(snackbar.msg, snackbar.type);
            wb.then((workbook) => {
              Xlsx.writeFile(workbook, fileName);
            });
          })
          .catch(err => {
            snackbar = SnackbarMessages.instrumentDownloadFailed;
            enqueueSnackbar(snackbar.msg, snackbar.type);
          });
      }
    });

  };

  updateList = () => {
    UserProvider.userIsLocalAdmin().then(isAdmin => {

      // only do this if user has not requested update of instrument he no longer has access to
      if (!this.props.shouldForceSync) {
        // If we didn't have time to count and the user apply new filter we skip the last count & ask for a new one
        if (this.isCounting && !this.isLoading) {
          this.refreshCount++;
          if (this.props.activeFilter) {
            this.props.activeFilter.release(true);
          }
        } else {
          this.updateCount(isAdmin);
        }
      }
      // get more instruments and add it to the instrument list to show
      InstrumentsProvider.getMoreUserInstruments(this.getListForInstrumentProvider(isAdmin, this.getInstrumentList()),
        0, ELEMENT_STEP, this.props.activeFilter)
        .then(values => {
          this.isLoading = false;
          // reset any filter set if user has requested update of instrument he no longer has access to
          if (this.props.shouldForceSync && (this.activeFilter || this.props.activeFilter)) {
            this.props.resetFilters(true);
          }
          this.setState(state => ({
            instrumentsToShow: values,
            selectedInstrument: !_.isEmpty(state.selectedInstrument) ?
              values.filter(inst => inst.marquage === state.selectedInstrument.marquage)[0] || {} : {},
            showInstrumentCard: state.showInstrumentCard
                      && values.some(inst => inst.marquage === state.selectedInstrument.marquage),
          }), () => {
            this.scrollLoading = false;
            this.props.shouldForceSync && this.updateCount(isAdmin);
            this.props.shouldForceSync && this.props.forceSync(false);
          });
        });

      this.activeFilter = this.props.activeFilter;
    });
  };

  onBottomReach = () => {
    if (!this.scrollLoading && this.state.filteredListCount > this.state.instrumentsToShow.length) {

      // prevent multiple loading
      this.scrollLoading = true;

      UserProvider.userIsLocalAdmin().then(isAdmin => {
        InstrumentsProvider.getMoreUserInstruments(this.getListForInstrumentProvider(isAdmin, this.getInstrumentList()),
          this.state.instrumentsToShow.length, ELEMENT_STEP, this.props.activeFilter)
          .then(instruments => {
            this.scrollLoading = false;
            this.setState(state => {
              return {
                instrumentsToShow: state.instrumentsToShow.concat(instruments)
              };
            });
          });
      });
    }
  };

  closeInstrumentCard = () => {
    this.setState({ showInstrumentCard: false });
  };

  /**
   * Update instrument in remote database
   *
   * @param {object} event - The event source of the callback
   * @param {object} instrumentFormat - Selected button, "exclusive" is true so it is a single value
   *
   */
  handleDisplayCardList = (event, instrumentFormat) => {
    if (instrumentFormat) {
      this.setState(state => {
        return { instrumentFormat, showCards: !state.showCards };
      });
    }
  };

  /**
   * Check if an instrument's add button should be disabled because it has already been added to the selection
   * @param {string} marquage
   * @return {boolean}
   */
  isInstrumentsAddBtnDisabled = (marquage) => {
    return this.props.selectedInstruments.filter(instrument =>
      instrument.marquage === marquage).length > 0;
  };

  /**
   * Get the list of instruments with a specific display style according to the provided view type
   * @return {JSX.Element}
   */
  getStylizedList = () => {
    const isCardFormat = !this.props.onMobile && this.state.showCards;
    switch (this.props.viewType) {
      // stylized list for inventory view
      case globalConstants.viewTypes.inventory:
        return (
          <List>
            {this.state.instrumentsToShow.map(item =>
              (<ItemTemplate
                id="item"
                key={item.marquage}
                item={item}
                openInstrumentCard={() => this.onItemClick(item)}
              />)
            )}
          </List>
        );
      // stylized list for instruments selection
      case globalConstants.viewTypes.selection:
        return (
          <List>
            {this.state.instrumentsToShow.map(item => {
              return (
                <Instrument
                  onClick={this.onItemClick}
                  key={item.id}
                  item={item}
                  typeOfDisplay={InterventionRequestConstants.TYPES_OF_DISPLAY.SELECTION_DISPLAY}
                  disableAddBtn={this.isInstrumentsAddBtnDisabled(item.marquage)}
                  addOrRemoveInstrument={this.props.onAddOrRemoveInst}
                />
              );
            })}
          </List>
        );
      // stylized list for my instruments page
      default:
        return (
          <Box component="div" sx={isCardFormat ? styles(theme).instrumentCards : {...styles(theme).instrumentList}}>
            {this.state.instrumentsToShow.map(item =>
              <InstrumentListItem
                key={item.marquage}
                instrument={item}
                onClick={this.onItemClick}
                isCard={isCardFormat}
                onDotClick={this.onDotClick}
              />)
            }
          </Box>
        );
    }
  };

  /**
   * Render header according to the header type (view type) provided
   * @return {JSX.Element}
   */
  createHeader = () => {
    switch (this.props.viewType) {
      case globalConstants.viewTypes.selection:
        return <>
          <Box component="div" sx={{...(this.props.styledTheme.header)}}>
            <Box component="div" sx={{...(this.props.styledTheme.headerLeft)}}>
              <Typography variant="h6" sx={{...(this.props.styledTheme.interventionHeaderTitle)}}>
                {strings.interventionRequest.instrumentsTitle}
              </Typography>
              <InstrumentsCountField customClass={styles(theme).instrumentsNbLabel}
                isCounting={this.isCounting}
                filteredCount={this.state.filteredListCount}
                totalCount={this.list.size}/>
            </Box>
            {!this.isCounting && (
              <Box component="div" sx={{...(this.props.styledTheme.headerRight)}}>
                <IconButton
                  id="toggleFilterButton"
                  color="inherit"
                  sx={{...(this.props.styledTheme.headerIcons)}}
                  onClick={() => this.props.toggleFilterPage()}
                  size="large">
                  <Badge variant="dot" color="primary" invisible={!this.props.isActiveFilterSet}>
                    <FilterIcon/>
                  </Badge>
                </IconButton>
                <IconButton
                  id="addAllButton"
                  color="inherit"
                  sx={{...(this.props.styledTheme.headerIcons)}}
                  onClick={() => this.props.addAllInstruments(this.state.filteredListCount)}
                  size="large">
                  <AddAllIcon/>
                </IconButton>
              </Box>
            )}
          </Box>
          <Divider sx={{...styles(theme).alt_divider}}/>
          {this.state.loading && <LoadingSpinner/>}
        </>;
      case globalConstants.viewTypes.inventory:
        this.props.onFiltering(this.isCounting, this.state.filteredListCount);
        return <>
          <Box component="div" sx={{...(this.props.styledTheme.header)}}>
            <Box component="div" sx={{...(this.props.styledTheme.headerLeft)}}>
              <Typography variant="h6" sx={{ "&.MuiTypography-h6": {...(this.props.styledTheme.title)}}}>
                {strings.inventory.inventoryListingTitle}
              </Typography>
              <InstrumentsCountField
                style={{ padding: "0px" }}
                isCounting={this.isCounting}
                filteredCount={this.state.filteredListCount}
                totalCount={this.list.size}
              />
            </Box>
            <Box component="div" sx={{...(this.props.styledTheme.headerRight)}}>
              <IconButton
                color="inherit"
                id="favoriteButton"
                onClick={() => this.props.toggleFavorites()}
                size="large">
                {this.props.showFavorites ? <Notifications/> : <NotificationsNone/>}
              </IconButton>
              <IconButton
                color="inherit"
                id="filterButton"
                onClick={() => this.props.toggleFilterPage()}
                size="large">
                <Badge variant="dot" color="primary" invisible={!this.props.isActiveFilterSet}>
                  <FilterList/>
                </Badge>
              </IconButton>
            </Box>
          </Box>
          <Divider sx={{...merge({}, styles(theme).inventory_bg_theme, styles(theme).divider)}}/>
        </>;
      default:
        return null;
    }
  };

  //endregion

  render() {
    this.isLoading = this.isLoading || this.activeFilter !== this.props.activeFilter;

    if (this.isLoading) {
      return (
        <>
          <Typography
            variant="body1"
            sx={{...styles(theme).centerElement}}
          >
            {strings.general.instrumentLoading}
          </Typography>
          <LinearProgress/>
        </>
      );
    }

    return (
      <Box component="div" sx={{...styles(theme).container}} id="instrumentList">
        <InstrumentCard
          id="instrumentCard"
          open={this.state.showInstrumentCard}
          instrument={this.state.selectedInstrument}
          onClose={this.closeInstrumentCard}/>

        {/* Render basic header */}
        {!this.props.headerFromViewType &&
        <Box component="div" sx={{...styles(theme).topBar}}>
          <InstrumentsCountField
            isCounting={this.isCounting}
            filteredCount={this.state.filteredListCount}
            totalCount={this.list.size}/>
          <Box component="div" sx={{...styles(theme).section}}>
            <Button
              id="exportButton"
              sx={{...styles(theme).validButton}}
              variant="contained"
              onClick={this.getExportCsv}
              color="primary">{strings.general.exportButton}</Button>
            {!this.props.onMobile &&
          <SwitchToggleButton
            id="displayCardListButton"
            value={this.state.instrumentFormat}
            firstValue={CARD}
            firstValueIcon={<ViewModule/>}
            secondValue={LIST}
            secondValueIcon={<ViewList/>}
            onValueChanged={this.handleDisplayCardList}
          />
            }
          </Box>
        </Box>
        }

        {/* Render custom header */}
        {this.props.headerFromViewType && this.createHeader()}

        {/* Dialog to show instruments not belonging to user */}
        {
          <SimpleDialog
            open={this.state.showNotBelongingMarkingsDialog}
            title={strings.externalFilterDialog.title}
            addContentPadding={true}
            acceptLabel={strings.dialog.answer.close}
            onAccept={() => this.setState({ showNotBelongingMarkingsDialog: false })}
          >
            <div>
              <p>{strings.externalFilterDialog.description}</p>
              <Scrollbar
                style={{...merge({}, styles(theme).scrollArea, (isIOS &&styles(theme).classic_sized),
                  styles(theme).listOfMarkings)}}
                noScrollX
              >
                <ul>
                  {this.state.notBelongingInst.map(marking =>
                    <Box component="li" key={marking} sx={{...styles(theme).marking}}>
                      {marking}
                    </Box>
                  )}
                </ul>
              </Scrollbar>
            </div>
          </SimpleDialog>
        }

        {/* Render list of instruments */}
        <Box component="div" sx={{...styles(theme).defaultHeight}}>
          <Scrollbar
            style={{...merge({}, styles(theme).scrollArea, (isIOS && styles(theme).classic_sized)) }}
            onScroll={scrollValues => trackScrolling(scrollValues, this.onBottomReach)}
            noScrollX>
            <div style={{ flex: 1 }}>
              {this.getStylizedList()}
              {(this.state.filteredListCount > this.state.instrumentsToShow.length) &&
              <LinearProgress sx={{...styles(theme).loader}}/>}
            </div>
          </Scrollbar>
        </Box>

        {this.state.selectedInstrument && <InstrumentDotDialog
          open={this.state.showInstrumentDot}
          onClose={this.onCloseDotDialog}
          instrument={this.state.selectedInstrument}
        />}
      </Box>
    );
  }

}

const propKeys = {
  headerFromViewType: 'headerFromViewType',
  viewType: 'viewType'
};

InstrumentList.defaultProps = {
  headerFromViewType: false,
  viewType: null
};

InstrumentList.propTypes = {
  styledTheme: PropTypes.object,

  //region props from redux
  activeFilter: PropTypes.object,
  isActiveFilterSet: PropTypes.bool,
  showFavorites: PropTypes.bool,
  favorites: PropTypes.array,
  onMobile: PropTypes.bool,
  shouldUpdateInstrumentList: PropTypes.bool,
  shouldForceSync: PropTypes.bool,

  setFilter: PropTypes.func,
  toggleFavorites: PropTypes.func,
  toggleFilterPage: PropTypes.func,
  instrumentListNeedsUpdate: PropTypes.func,
  forceSync: PropTypes.func,
  resetFilters: PropTypes.func,
  //endregion


  //region props from parent
  instruments: PropTypes.array,
  // list of selected instruments
  selectedInstruments: PropTypes.array,
  // precises that a header adapted to view type is needed
  headerFromViewType: PropTypes.bool,

  // action to execute on addition or removal of instrument
  onAddOrRemoveInst: function (props, propName, componentName, _location, _propFullName) {
    // prop becomes required under specific circumstances
    if (props[propKeys.viewType] === viewTypes.selection && !props[propName]) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}: Setting ${propKeys.viewType} to
       ${viewTypes.selection} makes ${propName} required.`);
    }
    PropTypes.checkPropTypes({ [propName]: PropTypes.func }, props, propName, componentName);
  },
  // action to execute on addition of all instruments
  addAllInstruments: function (props, propName, componentName, _location, _propFullName) {
    // prop becomes required under specific circumstances
    if (props[propKeys.viewType] === viewTypes.selection && !props[propName]) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}: Setting ${propKeys.viewType} to
       ${viewTypes.selection} makes ${propName} required.`);
    }
    PropTypes.checkPropTypes({ [propName]: PropTypes.func }, props, propName, componentName);
  },
  // action to execute when filtering
  onFiltering: function (props, propName, componentName, _location, _propFullName) {
    // prop becomes required under specific circumstances
    if (props[propKeys.headerFromViewType] && props[propKeys.viewType] === viewTypes.inventory && !props[propName]) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}: Setting ${propKeys.headerFromViewType} 
      to ${true}and ${propKeys.viewType} to ${viewTypes.inventory} makes ${propName} required.`);
    }
    PropTypes.checkPropTypes({ [propName]: PropTypes.func }, props, propName, componentName);
  },
  // precises the type of view wanted for the list (and header if set)
  viewType: function (props, propName, componentName) {
    // prop becomes required under specific circumstances
    if (props[propKeys.headerFromViewType] && !props[propName]) {
      return new Error(`Invalid prop ${propName} supplied to ${componentName}: Setting ${propKeys.headerFromViewType}
       to ${true} makes ${propName} required.`);
    }
    PropTypes.checkPropTypes({ [propName]: PropTypes.oneOf([viewTypes.inventory, viewTypes.selection]) },
      props, propName, componentName);
  }
  //endregion

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(InstrumentList);
