import { LocalizedString as strings } from '../Utils/Constants/LocalizedString';
import { InventoryStatus } from '../Utils/Constants/InventoryStatus';
import dayjs from 'dayjs';

class InventoryHelper {

  /**
   * Headers préparation for CSV file
   */
  static headers = [
    { id: 'marquage', displayName: 'Marquage' },
    { id: 'status', displayName: 'Statut' },
    { id: 'separator', displayName: '' },
    { id: 'title', displayName: '' }
  ];

  /**
   * Right display in Statut column when CSV download is done
   * @param {string} status: Instrument's status of the inventory ("Verified", "Unexpected", etc.) when the scan has been taken
   */
  static generateStatusLabel = status => {
    let labelStatus = '';
    switch (status) {
      case InventoryStatus.VALIDATED:
        labelStatus = strings.inventory.verified(1);
        break;
      case InventoryStatus.UNEXPECTED:
        labelStatus = strings.inventory.unexpected(1);
        break;
      case InventoryStatus.OUT_OF_BOUND:
        labelStatus = strings.inventory.outOfBound;
        break;
      default:
        labelStatus = strings.inventory.notVerified(1);
        break;
    }
    return labelStatus;
  }

  /**
   * Data preparation for CSV file
   */
  static csvDownloadData(instrumentsList, activeUser) {
    const dateNow = dayjs().format(strings.general.dateFormatWithTime);
    return instrumentsList.map((instr, index) => {
      const csvLine = {
        marquage: instr.marquage,
        status: instr.inventoryStatus ? this.generateStatusLabel(instr.inventoryStatus) : strings.inventory.notVerified(1),
        separator: '',
        title: index === 0 ? strings.inventory.inventoryReview + dateNow + strings.inventory.author(activeUser) : ''
      };
      return csvLine;
    });
  }
}

export default InventoryHelper;
