// GENERAL
export const RESET_REDUX = 'RESET_REDUX';
export const UPDATE_ON_MOBILE = 'UPDATE_ON_MOBILE';
export const SAVE_USER_INFORMATIONS = 'SAVE_USER_INFORMATIONS';
export const UPDATE_NETWORK_STATUS = 'UPDATE_NETWORK_STATUS';
export const SHOULD_REDIRECT_TO_LOGIN = 'SHOULD_REDIRECT_TO_LOGIN';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const SET_HELP_DIALOG_OPEN = 'SET_HELP_DIALOG_OPEN';
export const SHOULD_FORCE_SYNC = 'SHOULD_FORCE_SYNC';

// INTERVENTION REQUEST
export const UPDATE_ACTIVE_STEP = 'UPDATE_ACTIVE_STEP';
export const UPDATE_FORM_SERVICE_SHIPPING = 'UPDATE_FORM_SERVICE_SHIPPING';
export const UPDATE_VALID_INSTRUMENTS = 'UPDATE_VALID_INSTRUMENTS';
export const INIT_OPEN_CONFIRMATION_DIALOG = 'INIT_OPEN_CONFIRMATION_DIALOG';
export const RESET_INTERVENTION_REQUEST = 'RESET_INTERVENTION_REQUEST';
export const ENABLE_MULTIPLE_INTERVENTION = 'ENABLE_MULTIPLE_INTERVENTION';
export const SET_MULTIPLE_INTERVENTIONS = 'SET_MULTIPLE_INTERVENTIONS';
export const OPEN_MULTIPLE_INTERVENTIONS_INSTRUMENTS = 'OPEN_MULTIPLE_INTERVENTIONS_INSTRUMENTS';

// INSTRUMENT
export const UPDATE_INSTRUMENT_FAVORITE = 'UPDATE_INSTRUMENT_FAVORITE';
export const ADD_INSTRUMENT_FAVORITE = 'ADD_INSTRUMENT_FAVORITE';
export const REMOVE_INSTRUMENT_FAVORITE = 'REMOVE_INSTRUMENT_FAVORITE';
export const TOGGLE_EDIT_INSTRUMENTS_DIALOG = 'TOGGLE_EDIT_INSTRUMENTS_DIALOG';
export const INSTRUMENT_LIST_NEEDS_UPDATE = 'INSTRUMENT_LIST_NEEDS_UPDATE';
export const INSTRUMENT_NEEDS_REFRESH = 'INSTRUMENT_NEEDS_REFRESH';

// TOOLBAR
export const UPDATE_TOOLBAR_INFO = 'UPDATE_TOOLBAR_INFO';
export const TOGGLE_FAVORITE_FILTER = 'TOGGLE_FAVORITE_FILTER';
export const TOGGLE_FILTER_PAGE = 'TOGGLE_FILTER_PAGE';
export const TOGGLE_SAVED_FILTERS = 'TOGGLE_SAVED_FILTERS';
export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const HANDLE_CHANGE_DATEPICKER = 'HANDLE_CHANGE_DATEPICKER';
export const SET_CSVDATA = 'SET_CSVDATA';
export const INTERVENTION_SUBMIT_INFO = 'INTERVENTION_SUBMIT_INFO';

// GUIDE
export const LOCK_GUIDE = 'LOCK_GUIDE';
export const UNLOCK_GUIDE = 'UNLOCK_GUIDE';
export const SET_STATUS = 'SET_STATUS';
export const SET_MODE = 'SET_MODE';
