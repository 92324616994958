export const InstrumentConstants = {
  newStatus: 'NEW',
  marquageDB: 'marquage',
  sousFamilleDB: 'sousFamille',
  constructeurDB: 'constructeur',
  referenceConstructeurDB: 'referenceConstructeur',
  siteDB: 'site',
  etatDB: 'etat',
  planningDB: 'planning',
  parcDB: 'parc',
  periodiciteProchaineOperationDB: 'periodiciteProchaineOperation',
  localisationDB: 'localisation',
  affectationDB: 'affectation',
  sectionDB: 'section',
  sousSectionDB: 'sousSection',
  destinataireDB: 'destinataire',
  utilisateurDB: 'utilisateur',
  motifEnvoiDB: 'motifEnvoi',
  numeroSerieDB: 'numeroSerie',
  idCrisDB: 'idCris',
  rfidDB: 'rfid',
  oldMarquageDB: 'ancienMarquage',
  etatMetrologiqueDB: 'etatMetrologique',
  designationDB: 'designation',
  organisationProprietaireDB: 'proprietaire',
  updateInstrumentsAnswer: {
    done: 'done',
    pending: 'pending'
  },
  banc: 'banc'
};
