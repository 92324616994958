import {
  ADD_INSTRUMENT_FAVORITE,
  REMOVE_INSTRUMENT_FAVORITE,
  UPDATE_INSTRUMENT_FAVORITE,
  TOGGLE_EDIT_INSTRUMENTS_DIALOG,
  INSTRUMENT_LIST_NEEDS_UPDATE,
  INSTRUMENT_NEEDS_REFRESH
} from './actionTypes';


export function updateFavorite(payload) {
  return { type: UPDATE_INSTRUMENT_FAVORITE, payload };
}

export function addFavorite(payload) {
  return { type: ADD_INSTRUMENT_FAVORITE, payload };
}

export function removeFavorite(payload) {
  return { type: REMOVE_INSTRUMENT_FAVORITE, payload };
}

export function toggleEditInstrumentsDialog(payload) {
  return { type: TOGGLE_EDIT_INSTRUMENTS_DIALOG, payload };
}

export function instrumentListNeedsUpdate(payload) {
  return { type: INSTRUMENT_LIST_NEEDS_UPDATE, payload };
}

export function setInstrumentMarquageToRefresh(payload) {
  return { type: INSTRUMENT_NEEDS_REFRESH, payload };
}
