import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  scanButtonArea: {
    position: 'fixed',
    right: "0px",
    bottom: "20px"
  },
  scanButton: {
    width: "80px",
    height: "80px",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    borderTopLeftRadius: "32px",
    borderBottomLeftRadius: "32px",
    backgroundColor: theme.palette.MainMenu.menu,
    '&:hover': {
      width: "90px",
      backgroundColor: theme.palette.dialogTheme.header,
    }
  },
  scanIcon: {
    fontSize: "32px"
  }
});
