import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import { Validator } from '../../../Utils/Validator';

const SmartTextField = props => {

  const valid = useMemo(() => Validator.checkValidity(props.value, props.types), [props.value, props.types]);

  return (
    <TextField {...props}
      id={props.name}
      variant="standard"
      name={props.name}
      error={(props.submitted && props.required) ? !valid : false}
    />
  );
};

SmartTextField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  submitted: PropTypes.bool,
  types: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]).isRequired,

};

export default React.memo(SmartTextField);
