import dayjs from 'dayjs';
import _ from 'lodash';

import { LocalizedString as strings } from '../Utils/Constants/LocalizedString';
import { InterventionRequestConstants } from '../Utils/Constants/InterventionRequestConstants';
import store from '../store/store';
import { updateFormServiceShipping } from '../store/actions/interventionActions';
import InterventionRequestService from '../Providers/WebService/InterventionRequestService';

const { DISPATCH_NOW, DISPATCH_LATER } = InterventionRequestConstants;

class InterventionRequestHelper {

  /** Create JSON data for new intervention request creation */
  static createNewInterventionRequestJson(data, instruments) {
    const today = dayjs().format(strings.general.usDateFormat);
    const formattedInstruments = instruments.map(item => {
      return {
        mode: 'cust',
        id: item.marquage,
        wantedDate: data.returnDate.format(strings.general.usDateFormat),
        remark: item.interventionComment,
        interventionTypeID: data.serviceType
      };
    });
    return {
      returnPDFDocument: true,
      instruments: formattedInstruments,
      supplierProperties: {
        interventionTypeID: data.serviceType,
        locationTypeID: data.serviceLocation,
        supplierType: data.providerType,
        supplierID: data.provider.id,
        recipientName: data.recipient,
        address: data.providerAddress,
        phone: data.providerPhone,
        fax: data.providerFax,
        email: data.providerMail,
        remarks: data.observation
      },
      dispatchProperties: {
        dispatchProjected: today === data.shippingDate.format(strings.general.usDateFormat) ? DISPATCH_NOW : DISPATCH_LATER,
        dispatchDateProjected: data.shippingDate.format(strings.general.usDateFormat),
        coordinates: {
          senderID: data.shipperId,
          senderAddress: data.shipperAddress,
          senderPhone: data.shipperPhone,
          senderFax: data.shipperFax,
          senderEmail: data.shipperMail,
          returnName: data.shipperName,
          returnAddress: data.shipperAddress,
          returnPhone: data.shipperPhone,
          returnFax: data.shipperFax,
          returnEmail: data.shipperMail,
          carrierMode: data.carrierMode.id,
          carrierID: data.carrier.length !== 0 ? data.carrier : 0,
          trackingNumber: ''
        }
      }
    };
  }

  static async fillServicePageWithPreferences(data, multipleIntervention) {

    let serviceTypeName;
    switch (data.interventionTypeID) {
      case InterventionRequestConstants.SERVICE_TYPE_IDS.CALIBRATION:
        serviceTypeName = strings.preferences.interventionTypes.calibration;
        break;
      case InterventionRequestConstants.SERVICE_TYPE_IDS.REPAIR:
        serviceTypeName = strings.preferences.interventionTypes.repair;
        break;
      case InterventionRequestConstants.SERVICE_TYPE_IDS.VERIF:
        serviceTypeName = strings.preferences.interventionTypes.verification;
        break;
      default:
        return;
    }

    const constants = InterventionRequestConstants.SERVICE_SHIPPING_PAGE_NAMES;

    InterventionRequestHelper.updateField(constants.SERVICE_TYPE, data.interventionTypeID);
    InterventionRequestHelper.updateField(constants.SERVICE_TYPE_NAME, serviceTypeName);
    InterventionRequestHelper.updateField(constants.SERVICE_LOCATION, data.locationTypeID);

    InterventionRequestHelper.updateField(constants.SHIPPER_ADDRESS, data.senderAddress);
    InterventionRequestHelper.updateField(constants.SHIPPER_ID, data.senderID);
    const shippers = await InterventionRequestService.getShippers();
    const shipperName = shippers.filter(s => s.id === data.senderID)[0];
    if (shipperName) {
      InterventionRequestHelper.updateField(constants.SHIPPER_NAME, shipperName.lastName + ' ' + shipperName.firstName);
    }
    InterventionRequestHelper.updateField(constants.SHIPPER_PHONE, data.senderPhone);
    InterventionRequestHelper.updateField(constants.SHIPPER_FAX, data.senderFax);
    InterventionRequestHelper.updateField(constants.SHIPPER_MAIL, data.senderEmail);

    if (!multipleIntervention) {
      const isExternalProvider = data.supplierType === InterventionRequestConstants.PROVIDER_TYPE.EXTERNAL;
      const providers = isExternalProvider ?
        await InterventionRequestService.getExternalProviders() : await InterventionRequestService.getInternalProviders();
      const provider = InterventionRequestHelper.getProvider(isExternalProvider ? { children: providers } : providers[0], data.supplierID);
      store.dispatch(updateFormServiceShipping({ name: constants.PROVIDER, value: { id: data.supplierID, name: provider.name } }));
    }

    InterventionRequestHelper.updateField(constants.PROVIDER_TYPE, data.supplierType);
    InterventionRequestHelper.updateField(constants.PROVIDER_ADDRESS, data.supplierAddress);
    InterventionRequestHelper.updateField(constants.PROVIDER_PHONE, data.supplierPhone);
    InterventionRequestHelper.updateField(constants.PROVIDER_FAX, data.supplierFax);
    InterventionRequestHelper.updateField(constants.PROVIDER_MAIL, data.supplierEmail);

    InterventionRequestHelper.updateField(constants.RECIPIENT, data.supplierName);

    const carrierModes = await InterventionRequestService.getCarrierModes();
    const selectedCarrierMode = carrierModes.filter(c => c.id === data.carrierMode)[0];
    InterventionRequestHelper.updateField(constants.CARRIER_MODE, selectedCarrierMode);
    InterventionRequestHelper.updateField(constants.CARRIER, data.carrierID);
  }

  static getProvider(node, id) {
    var provider;
    if (node.id === id) {
      return node;
    } else {
      if (node.hasOwnProperty('children') && node.children && node.children.length > 0) {
        node.children.some(child => provider = InterventionRequestHelper.getProvider(child, id));
        return provider;
      }
    }
  }

  static updateField(name, value) {
    if (value) {
      store.dispatch(updateFormServiceShipping({ name, value }));
    }
  }

  static async instrumentsMatchInterventionType(instruments, interventionType) {
    interventionType = InterventionRequestHelper.formatInterventionTypeString(interventionType);
    const matching =  [];
    const notMatching = [];
    instruments.forEach(instrument => {
      if (InterventionRequestHelper.compareInterventionTypes(instrument.interventionType, interventionType) && instrument.prestataireID !== null) {
        matching.push(instrument);
      } else {
        notMatching.push(instrument);
      }
    });

    if (matching.length === 0) {
      return [];
    }

    const providers = await InterventionRequestService.getExternalProviders();
    const groupedByProvider = _.groupBy(matching, i => i.prestataireID);
    const results = [];

    for (const providerId in groupedByProvider) {
      let providerData = InterventionRequestHelper.getProvider({ children: providers }, parseInt(providerId, 10));
      let tmpProvider = {id: providerId, name: providerData?.name};
      results.push({provider: tmpProvider, instruments: groupedByProvider[providerId], serviceShippingPage: {}});
    }

    if (notMatching.length > 0) {
      results.unshift({ provider: { id: '', name: '' }, instruments: notMatching, serviceShippingPage: {} });
    }
    return results;
  }

  static compareInterventionTypes(instrumentInterventionType, interventionType) {
    if (!instrumentInterventionType || instrumentInterventionType?.length === 0 || !interventionType || interventionType?.length === 0 ) {
      return false;
    }
    return (instrumentInterventionType.toLowerCase()).includes(interventionType.toLowerCase());
  }

  static formatInterventionTypeString(interventionTypeString) {
    if (interventionTypeString.toLowerCase() === strings.preferences.interventionTypes.verification.toLowerCase()) {
      return interventionTypeString.split(' ')[0];
    }
    return interventionTypeString;
  }

}

export default InterventionRequestHelper;
