import React, { PureComponent } from 'react';
import { styles } from './MainMenu.style';
import PropTypes from 'prop-types';
import {
  Typography,
  Drawer,
  List,
  Divider,
  IconButton,
  Badge,
  Tooltip,
  Box
} from '@mui/material';
import {
  ChevronLeft,
  Build,
  DevicesOther,
  PlaylistAddCheck,
  NotificationsNone,
  History,
  Settings,
  QuestionAnswer,
  ChevronRight,
  Home,
  Help,
  AccessTime
} from '@mui/icons-material';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import MainMenuItem from './MainMenuItem/MainMenuItem';
import PAGES_IDS from '../../Utils/Constants/PagesIDs';
import MainMenuStatusBar from './StatusBar/MainMenuStatusBar';
import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import MenuFooter from './MenuFooter/MenuFooter';
import RoleHelper from '../../Helpers/RoleHelper';
import Avatar from '@mui/material/Avatar';
import Scrollbar from 'react-scrollbars-custom';
import { isNotComputer } from '../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../Utils/Theme';
import merge from 'lodash/merge';

const { mainMenuItems } = globalConstants;

export class MainMenu extends PureComponent {

  state = {
    synchronizationInstrumentIsOpen: false
  }
  // Build the user informations UI
  buildUserInformations = classes => {
    let firstLetters = '';
    if (this.props.activeUser?.firstName && this.props.activeUser?.lastName) {
      firstLetters = this.props.activeUser.firstName[0] + this.props.activeUser.lastName[0];
    }
    return (
      <Box component="div" sx={{...styles(theme).topContent}}>
        <Box component="div"
          sx={{...styles(theme).toolbar}}
          id='toggleMenu'>
          <IconButton
            id="toggleMenuButton"
            onClick={() => this.props.toggleMenu()}
            sx={{ "&.MuiIconButton-root": {...styles(theme).whiteColor}}}
            size="large">
            {this.props.menuIsOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </Box>

        <Tooltip title={strings.tooltips.profilePage} enterDelay={globalConstants.tooltipDelay}>
          <Box component="div"
            id="profile"
            sx={{...merge({}, styles(theme).userInfo, (isNotComputer && classes.tabletFontSize))}}
            style={{ '--align-items': this.props.menuIsOpen ? 'flex-start' : 'center' }}
            onClick={() => this.props.handleMenuItemClick(PAGES_IDS.USER_PROFILE)}>
            <Avatar
              sx={{...merge({}, styles(theme).userAvatar,
                (this.props.menuIsOpen && styles(theme).userAvatarOpen),
                (!this.props.menuIsOpen && styles(theme).userAvatarClose),
                ((this.props.currentPage === PAGES_IDS.USER_PROFILE) && styles(theme).userMainMenuBorder))
              }}>
              {firstLetters}
            </Avatar>
            <Box component="div" sx={(!this.props.menuIsOpen && !this.props.onMobile) ? {...styles(theme).hide} : {}}>
              <Typography variant="h6" color="secondary">
                {this.props.activeUser.firstName + ' ' + this.props.activeUser.lastName}
              </Typography>
              <Typography variant="subtitle1" color="secondary">{this.props.activeUser.profile}</Typography>
            </Box>
          </Box>
        </Tooltip>
      </Box>
    );
  };

  // Toogle the menu
  toggleSynchronizationInstrument = () => {
    this.setState({ synchronizationInstrumentIsOpen: !this.state.synchronizationInstrumentIsOpen });
  };

  buildMenuItem = (pageId, text, style, icon, disabled = false) => {
    return (
      <MainMenuItem
        id={`menuItem-${pageId}`}
        selected={this.props.currentPage === pageId}
        text={text}
        onClick={() => this.props.handleMenuItemClick(pageId)}
        customStyle={style}
        disabled={disabled}
        menuIsOpen={this.props.menuIsOpen}
      >
        {icon}
      </MainMenuItem>
    );
  }

  // Build the list of menu items
  buildListItems = () => {
    const interventionForbidden = !this.props.activeUser.roles || !RoleHelper.hasCRight(this.props.activeUser);
    return (
      <>
        <div id='menuStatusBar'>
          <MainMenuStatusBar
            redirectFromSynchro={this.props.redirectFromSynchro}
            menuIsOpen={this.props.menuIsOpen}
            SynchroDialogIsOpen={this.toggleSynchronizationInstrument}
            valueSynchroDialog={this.state.synchronizationInstrumentIsOpen}
          />
        </div>
        <Divider sx={{...styles(theme).divider}} />
        <Scrollbar noScrollX style={{...styles(theme).scrollArea}}>
          {this.props.onMobile && (
            <>
              <List>
                {this.buildMenuItem(PAGES_IDS.HOME, strings.mainMenu.home, mainMenuItems.DEFAULT, <Home />)}
              </List>
              <Divider sx={{...styles(theme).divider}} />
            </>)
          }
          {!this.props.onMobile && (
            <>
              <List>

                {this.buildMenuItem(PAGES_IDS.INSTRUMENTS,
                  strings.mainMenu.instruments, mainMenuItems.INSTRUMENTS, <DevicesOther
                    sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>)}
                {this.buildMenuItem(PAGES_IDS.INTERVENTION,
                  strings.mainMenu.interventions, mainMenuItems.INTERVENTIONS, <Build
                    sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>, interventionForbidden)}
                {this.buildMenuItem(PAGES_IDS.INVENTORY,
                  strings.mainMenu.inventaire, mainMenuItems.INVENTORY, <PlaylistAddCheck
                    sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>, interventionForbidden)}
                {this.buildMenuItem(PAGES_IDS.HISTORIC_DEMAND_INTERVENTION,
                  strings.mainMenu.historicIntervention, mainMenuItems.DEFAULT, <AccessTime
                    sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>, interventionForbidden)}

              </List>

              <Divider sx={{...styles(theme).divider}} />

              <List>
                {this.buildMenuItem(PAGES_IDS.FOLLOWED_INSTRUMENTS,
                  strings.mainMenu.instrumentNotif, mainMenuItems.DEFAULT,
                  <Badge color="error" badgeContent={this.props.notificationCount}>
                    <NotificationsNone sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>
                  </Badge>)}
                {this.buildMenuItem(PAGES_IDS.HISTORY, strings.mainMenu.history, mainMenuItems.DEFAULT, <History
                  sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>)}
              </List>

              <Divider sx={{...styles(theme).divider}} />
            </>
          )}

          <List>
            {this.buildMenuItem(PAGES_IDS.PREFERENCES,
              strings.mainMenu.preferences, mainMenuItems.DEFAULT, <Settings
                sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>)}
            {this.buildMenuItem(PAGES_IDS.FEEDBACKS,
              strings.mainMenu.feedback, mainMenuItems.DEFAULT, <QuestionAnswer
                sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>)}
          </List>

          <Divider sx={{...styles(theme).divider}} />

          <List>
            {this.buildMenuItem(PAGES_IDS.HELP, strings.mainMenu.help, mainMenuItems.DEFAULT, <Help
              sx={isNotComputer ? {...styles(theme).tabletIconSize} : {}}/>)}
          </List>
        </Scrollbar>
        <div id='updateButton'>
          <MenuFooter
            onClick={this.props.handleFooterIcon}
            displayBadge={this.props.displayUpdateBadge}
            menuIsOpen={this.props.menuIsOpen} />
        </div>
      </>
    );
  };


  render() {
    const applyCustomClass = this.props.menuIsOpen ? {...styles(theme).drawerOpen} : {...merge({}, styles(theme).drawerClose, (isNotComputer && styles(theme).tabletDrawerClose))};
    return (
      <Drawer
        id="drawer"
        open={this.props.menuIsOpen}
        variant={this.props.onMobile ? 'temporary' : 'permanent'}
        sx={{ 
          "& .MuiDrawer-paper": {...applyCustomClass}, ...merge({}, styles(theme).drawer, applyCustomClass) }}
      >
        {this.buildUserInformations(styles(theme))}
        <Divider />
        {this.buildListItems()}
      </Drawer>
    );
  }
}

MainMenu.propTypes = {
  handleMenuItemClick: PropTypes.func.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  redirectFromSynchro: PropTypes.func.isRequired,
  onMobile: PropTypes.bool.isRequired,
  activeUser: PropTypes.object.isRequired,
  notificationCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  displayUpdateBadge: PropTypes.bool,
  handleFooterIcon: PropTypes.func.isRequired
};

export default MainMenu;
