//React libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
// Material-ui and styles libraries
import { Box, Tooltip } from '@mui/material';
import { styles } from './ScanHistoryList.style';
// Component libraries
import GenericCard from '../../../Containers/GenericCard/GenericCard';
import ScanHistoryItem from '../ScanHistoryItem/ScanHistoryItem';
import InstrumentCard from '../../InstrumentCard/InstrumentCard';
//Utils libraries
import DatePickerIcon from '../../Utils/DatePickerIcon/DatePickerIcon';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
// Container library
import VirtualizedList from '../../../Containers/Lists/VirtualizedList/VirtualizedList';
// Actions libraries
import { setCSVData } from '../../../store/actions/exportActions';
import HistoryHelper from '../../../Helpers/HistoryHelper';
import DateFilterInfo from '../../Utils/DateFilterInfo/DateFilterInfo';
import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import CsvIcon from '../../Utils/CsvIcon/CsvIcon';
import { theme } from '../../../Utils/Theme';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import utc from 'dayjs/plugin/utc';
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    selectedDate: state.toolbarReducer.selectedDate,
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    setCSVData: csvData => dispatch(setCSVData(csvData)),
  };
};

const itemHeight = 112;

class ScanHistoryList extends Component {

  state = {
    selectedInstrument: {},
    showInstrumentCard: false,
    activeDateFilter: null,
  };

  rowRenderer = ({ index, _isScrolling, key, style }) => {
    const scans = this.filteredScans();
    return (scans.length > 0 &&
      <ScanHistoryItem
        key={key}
        scan={scans[index]}
        style={style}
        generateScanPage={HistoryHelper.generateScanPage(scans[index].page)}
        generateStatusLabel={HistoryHelper.generateStatusLabel(scans[index].analysis)}
        onItemClick={this.onItemClick}
      />
    );
  };

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (this.props.actifForFilter) {
      this.props.setCSVData(this.csvDownloadData());
      if (prevProps.selectedDate !== this.props.selectedDate) {
        this.handleChangeDatePicker(this.props.selectedDate);
      }
    }
  }

  filteredScans = () => {
    if (!this.state.activeDateFilter) {
      return this.props.scans;
    }
    return this.props.scans.filter(
      scan => dayjs(scan.date).isSameOrAfter(dayjs(this.state.activeDateFilter), 'day'));
  }

  handleChangeDatePicker = selectedDate => {
    this.setState({ activeDateFilter: selectedDate });
  };

  resetDatePicker = () => {
    this.setState({ activeDateFilter: null });
  };

  /**
   * Data preparation for CSV file
   */
  csvDownloadData = () => {
    const scanList = this.filteredScans();

    return scanList.map(scan => {
      const csvLine = {
        marquage: scan.marquage ? scan.marquage : scan.scannedBarcode,
        date: dayjs(scan.date).format(strings.general.dateFormatWithTime),
        page: HistoryHelper.generateScanPage(scan.page),
        type: HistoryHelper.generateBarcodeTypeLabel(scan.scannedBarcodeType),
        marque: scan.instrument ? scan.instrument.constructeur : strings.general.noInformation,
        modele: scan.instrument ? scan.instrument.referenceConstructeur : strings.general.noInformation,
        analysis: HistoryHelper.generateStatusLabel(scan.analysis)
      };
      return csvLine;
    });
  };

  closeInstrumentCard = () => {
    this.setState({ showInstrumentCard: false });
  };

  onItemClick = instrument => {
    this.setState({ selectedInstrument: instrument, showInstrumentCard: true });
  };

  render() {
    return (
      <>
        <GenericCard
          cardHeader={this.props.cardHeader}
          title={this.props.titleList}
          actionsHeader={
            <>
              <DatePickerIcon
                id="datePickerIcon"
                selectedDate={this.state.activeDateFilter}
                handleChangeDatePicker={date => this.handleChangeDatePicker(date)} />
              <Tooltip title={strings.tooltips.downloadCSV} enterDelay={globalConstants.tooltipDelay}>
                <CsvIcon
                  id="csvDownloader"
                  datas={this.csvDownloadData()}
                  headers={HistoryHelper.headers}
                  color={theme.palette.primary.main}
                  fileName={HistoryHelper.getCSVFileName()}
                />
              </Tooltip>
            </>}
        >
          <Box component="div" sx={{...styles(theme).genericCardContent}}>
            {this.state.activeDateFilter &&
              <DateFilterInfo
                resetDatePicker={this.resetDatePicker}
                date={this.state.activeDateFilter}
              />}
            <Box component="div" sx={{...styles(theme).virtualizedListContainer}}>
              <VirtualizedList
                rowRenderer={this.rowRenderer}
                count={this.filteredScans().length}
                rowHeight={itemHeight}
              />
            </Box>
          </Box>
        </GenericCard>
        <InstrumentCard
          open={this.state.showInstrumentCard}
          instrument={this.state.selectedInstrument}
          onClose={this.closeInstrumentCard}
        />
      </>
    );
  }
}

ScanHistoryList.propTypes = {
  cardHeader: PropTypes.bool.isRequired,
  scans: PropTypes.array.isRequired,
  titleList: PropTypes.string,
  actifForFilter: PropTypes.bool,
  selectedDate: PropTypes.object,
  setCSVData: PropTypes.func
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(ScanHistoryList);
