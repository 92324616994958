import React, { Component } from 'react';
import { Badge, Box } from '@mui/material';
import { styles } from './MenuFooter.style';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import appLogo from '../../../Assets/applicationIcon.png';
import Typography from '@mui/material/Typography';
import VersionService from '../../../Providers/WebService/VersionService';
import { theme } from '../../../Utils/Theme';

class MenuFooter extends Component {

  state = {
    version: ''
  };

  componentDidMount() {
    VersionService.getCurrentVersion().then(res => this.setState({ version: res }));
  }

  render() {
    const { menuIsOpen } = this.props;
    return (
      <Box component="div" sx={{...styles(theme).menuFooter}} onClick={this.props.onClick}>
        <Badge color="error" badgeContent={this.props.displayBadge ? '!' : 0}>
          <Avatar
            alt={strings.alt.admLogo}
            src={appLogo}
            sx={{ "&.MuiAvatar-root": {...styles(theme).menuFooterGif}}}
          />
        </Badge>
        <Typography
          id="version"
          variant="caption"
          color="secondary"
          sx={{ "&.MuiTypography-root": menuIsOpen ? {...styles(theme).menuFooterText} : {...styles(theme).hide} }}
        >
          {strings.general.versionStart + this.state.version + strings.general.versionEnd}
        </Typography>
      </Box>
    );
  }
}

MenuFooter.propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  displayBadge: PropTypes.bool
};

export default MenuFooter;
