import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbar from 'react-scrollbars-custom';
import { Box, FormControl, InputLabel } from '@mui/material';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { styles } from './InterventionInstrumentsCard.style';
import InterventionItem from './InterventionItem/InterventionItem';
import SimpleDialog from '../../../../Dialogs/SimpleDialog/SimpleDialog';
import ResponsiveSelect from '../../../../Utils/ResponsiveSelect/ResponsiveSelect';
import { LocalizedString as strings } from '../../../../../Utils/Constants/LocalizedString';
import InterventionConstants from '../../../../../Utils/Constants/InterventionConstants';
import InstrumentService from '../../../../../Providers/WebService/InstrumentService';
import routes from '../../../../../Utils/Routes';
import DocumentsRepository from '../../InterventionFormStep/DocumentsRepository/DocumentsRepository';
import InstrumentCard from '../../../../InstrumentCard/InstrumentCard';
import DPSItem from '../../InterventionFormStep/DPSCard/DPSList/DPSItem/DPSItem';
import { theme } from '../../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,

  };
};

class InterventionInstrumentsCard extends Component {

  state = {
    instrumentCardIsOpen: false,
    documentDialogIsOpen: false,
    editionDialogIsOpen: false,
    currentInstrument: {},
    states: [],
    statuses: []
  };

  /**
   * Get state 'currentInstrument' from props, which include the intervention data
   */
  currentInstrumentFromProps = () =>
    this.props.instruments.filter(i => i.marquage === this.state.currentInstrument.marquage)[0];

  async componentDidMount() {
    const statuses = await InstrumentService.getStatesOrStatus(routes.getStatuses);
    const states = await InstrumentService.getStatesOrStatus(routes.getStates);
    this.setState({ statuses, states });
  }

  /**
   * Check if instrument has an updated state or status from general state/status of the intervention
   * @param {object} instrument: instrument to check
   */
  hasModifications = instrument => {
    return (instrument.intervention.state !== this.props.intervention.state) ||
      (instrument.intervention.status !== this.props.intervention.status);
  };

  /**
   * Update an intervention value in an instrument
   * @param {string} name: the field to update
   * @param {object} value: the new value
   * @param {object} instrument
   * @param {boolean} addDocument: either it's an add document operation or not
   */
  handleUpdateModifications = (name, value, instrument = null, addDocument = false) => {
    this.props.updateInstrumentsWithInterventions(name, value, instrument || this.state.currentInstrument, addDocument);
  };

  /**
   * Create the list of instruments to display
   * @param {array} instruments: instruments to display
   */
  buildList = instruments => {
    return instruments.map(instrument => {
      return <InterventionItem
        key={instrument.marquage}
        instrument={instrument}
        hasModifications={this.hasModifications(instrument)}
        openDocumentsDialog={() => this.setState({ currentInstrument: instrument, documentDialogIsOpen: true })}
        openEditionDialog={() => this.setState({ currentInstrument: instrument, editionDialogIsOpen: true })}
        onClick={() => this.setState({ currentInstrument: instrument, instrumentCardIsOpen: true })}
      />;
    });
  };

  render() {
    return (
      <Box component="div" id="interventionInstruments"
        sx={this.props.onMobile ?
          {...styles(theme).mobileContainer} : {...styles(theme).container}}
      >

        {/* INSTRUMENTS LIST */}
        <Scrollbar noScrollX style={{...styles(theme).scrollArea}}>
          <Box component="div" sx={{...styles(theme).instrumentsList}}>
            {this.buildList(this.props.instruments)}
          </Box>
        </Scrollbar>

        {/*********** DIALOGS ***********/}

        {/* MODIFYING THE STATE OR STATUS OF AN INSTRUMENT */}
        {this.state.editionDialogIsOpen && (
          <SimpleDialog
            id="editionDialog"
            title={strings.intervention.conclusionCard.updateInstrument}
            open={this.state.editionDialogIsOpen}
            onRefuse={() => this.setState({ editionDialogIsOpen: false })}
            refuseLabel={strings.dialog.answer.close}
            addContentPadding
          >
            {/* display STATE field according to canSelectState  */}
            {this.props.intervention.type.canSelectState ? <FormControl fullWidth
              required
              sx={{ "&.MuiSimpleDialog-root": {...styles(theme).responsiveSelectSeparator}}}>
              <InputLabel htmlFor={InterventionConstants.INTERVENTIONCARD_NAMES.STATE}
                sx={{...styles(theme).input_label}}>
                {strings.intervention.conclusionCard.state}
              </InputLabel>
              <ResponsiveSelect
                id="stateSelect"
                value={this.currentInstrumentFromProps().intervention.state.id}
                name={InterventionConstants.INTERVENTIONCARD_NAMES.STATE}
                onChange={e => this.handleUpdateModifications(e.target.name, {
                  id: e.target.value,
                  name: this.state.states.filter(d => d.id === e.target.value)[0].name
                })}
                data={this.state.states}
                styledSelect={styles(theme).input_content}
              />
            </FormControl> : null
            }
            {/* display STATUS field according to canSelectState  */}
            {this.props.intervention.type.canSelectStatus ?  <FormControl fullWidth required>
              <InputLabel htmlFor={InterventionConstants.INTERVENTIONCARD_NAMES.STATUS}
                sx={{...styles(theme).input_label}}>
                {strings.intervention.conclusionCard.status}
              </InputLabel>
              <ResponsiveSelect
                id="statusSelect"
                value={this.currentInstrumentFromProps().intervention.status.id}
                name={InterventionConstants.INTERVENTIONCARD_NAMES.STATUS}
                onChange={e => this.handleUpdateModifications(e.target.name, {
                  id: e.target.value,
                  name: this.state.statuses.filter(d => d.id === e.target.value)[0].name
                })}
                data={this.state.statuses}
                styledSelect={styles(theme).input_content}
              />
            </FormControl> : null}

            {/* display DPS field  */}
            <DPSItem
              key={`inst-item-${this.currentInstrumentFromProps().marquage}`}
              updateInstrumentsWithInterventions={this.handleUpdateModifications}
              instrument={this.currentInstrumentFromProps()}
              alternateStyling={true}
            />
          </SimpleDialog>
        )}

        {/* MODIFYING THE DOCUMENTS OF AN INSTRUMENT */}
        {this.state.documentDialogIsOpen && (
          <SimpleDialog
            id="documentDialog"
            title={strings.intervention.documentRepository.updateDocuments}
            open={this.state.documentDialogIsOpen}
            onRefuse={() => this.setState({ documentDialogIsOpen: false })}
            refuseLabel={strings.dialog.answer.close}
          >
            <DocumentsRepository
              id="documentsRepository"
              update={(name, value, addDocument) => this.handleUpdateModifications(name, value, null, addDocument)}
              documents={this.currentInstrumentFromProps().intervention.documents}
              noTitle/>
          </SimpleDialog>
        )}

        {/* INSTRUMENT CARD */}
        <InstrumentCard
          id="instrumentCard"
          open={this.state.instrumentCardIsOpen}
          instrument={this.state.currentInstrument}
          onClose={() => this.setState({ instrumentCardIsOpen: false })}/>

      </Box>
    );
  }
}

InterventionInstrumentsCard.propTypes = {
  instruments: PropTypes.array.isRequired,
  intervention: PropTypes.object.isRequired,
  updateInstrumentsWithInterventions: PropTypes.func.isRequired,
  onMobile: PropTypes.bool.isRequired
};

export default compose(
  connect(mapStateToProps, null)
)(InterventionInstrumentsCard);
