import PropTypes from "prop-types";
import React, { Component } from "react";
import * as ScanditSDK from "scandit-sdk";


class Scanner extends Component {
  scannerContainerRef = React.createRef();
  isInitialized = false;

  componentDidMount = () => {
    ScanditSDK.configure(this.props.licenseKey, {
      engineLocation: this.props.engineLocation
    }).then(() => {
      ScanditSDK.BarcodePicker.create(this.scannerContainerRef.current, {
        accessCamera: this.props.accessCamera,
        enableCameraSwitcher: this.props.enableCameraSwitcher,
        enableTorchToggle: this.props.enableTorchToggle,
        enablePinchToZoom: this.props.enablePinchToZoom,
        enableTapToFocus: this.props.enableTapToFocus,
        guiStyle: this.props.guiStyle,
        playSoundOnScan: this.props.playSoundOnScan,
        vibrateOnScan: this.props.vibrateOnScan,
        scanSettings: this.props.scanSettings,
        scanner: this.props.scanner,
        scanningPaused: this.props.paused,
        targetScanningFPS: this.props.targetScanningFPS,
        singleImageMode: this.props.singleImageMode
      }).then(picker => {
        picker.on("scan", result => this.props.onScan != null && this.props.onScan(result));
        picker.on("scanError", result => this.props.onScanError != null && this.props.onScanError(result));
        this.picker = picker;
      });
      this.isInitialized = true;
    })
  };

  componentWillUnmount = () => {
    if (this.picker) {
      this.picker.destroy();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.picker != null) {
      this.setScanSettings(prevProps, this.props);
      this.setScanning(prevProps, this.props);
      this.setAccessCamera(prevProps, this.props);
      this.setEnableCameraSwitcher(prevProps, this.props);
      this.setEnableTorchToggle(prevProps, this.props);
      this.setEnablePinchToZoom(prevProps, this.props);
      this.setEnableTapToFocus(prevProps, this.props);
      this.setGuiStyle(prevProps, this.props);
      this.setPlaySoundOnScanEnabled(prevProps, this.props);
      this.setVibrateOnScanEnabled(prevProps, this.props);
      this.setTargetScanningFPS(prevProps, this.props);
    }
  };

  setScanSettings = (prevProps, props) => {
    const shouldSet = prevProps == null || JSON.stringify(prevProps.scanSettings) != JSON.stringify(props.scanSettings);
    if (shouldSet) {
      this.picker.applyScanSettings(props.scanSettings);
    }
  };

  setScanning = (prevProps, props) => {
    if (this.picker) {
      if (props.paused && !this.picker.isScanningPaused()) {
        this.picker.pauseScanning(!props.accessCamera);
      } else if (!props.paused && this.picker.isScanningPaused()) {
        this.picker.resumeScanning();
      }
    }
  };

  setAccessCamera = (prevProps, props) => {
    const shouldSet = (prevProps == null || prevProps.accessCamera != props.accessCamera) && props.accessCamera;
    if (shouldSet) {
      this.picker.accessCamera();
    }
  };

  setEnableCameraSwitcher = (prevProps, props) => {
    const shouldSet = prevProps == null || prevProps.enableCameraSwitcher != props.enableCameraSwitcher;
    if (shouldSet) {
      this.picker.setCameraSwitcherEnabled(props.enableCameraSwitcher);
    }
  };

  setEnableTorchToggle = (prevProps, props) => {
    const shouldSet = prevProps == null || prevProps.enableTorchToggle != props.enableTorchToggle;
    if (shouldSet) {
      this.picker.setTorchToggleEnabled(props.enableTorchToggle);
    }
  };

  setEnablePinchToZoom = (prevProps, props) => {
    const shouldSet = prevProps == null || prevProps.enablePinchToZoom != props.enablePinchToZoom;
    if (shouldSet) {
      this.picker.setPinchToZoomEnabled(props.enablePinchToZoom);
    }
  };

  setEnableTapToFocus = (prevProps, props) => {
    const shouldSet = prevProps == null || prevProps.enableTapToFocus != props.enableTapToFocus;
    if (shouldSet) {
      this.picker.setTapToFocusEnabled(props.enableTapToFocus);
    }
  };

  setGuiStyle = (prevProps, props) => {
    const shouldSet = prevProps == null || prevProps.guiStyle != props.guiStyle;
    if (shouldSet) {
      this.picker.setGuiStyle(props.guiStyle);
    }
  };

  setPlaySoundOnScanEnabled = (prevProps, props) => {
    const shouldSet = prevProps == null || prevProps.playSoundOnScan != props.playSoundOnScan;
    if (shouldSet) {
      this.picker.setPlaySoundOnScanEnabled(props.playSoundOnScan);
    }
  };

  setVibrateOnScanEnabled = (prevProps, props) => {
    const shouldSet = prevProps == null || prevProps.vibrateOnScan != props.vibrateOnScan;
    if (shouldSet) {
      this.picker.setVibrateOnScanEnabled(props.vibrateOnScan);
    }
  };

  setTargetScanningFPS = (prevProps, props) => {
    const shouldSet = prevProps == null || prevProps.targetScanningFPS != props.targetScanningFPS;
    if (shouldSet) {
      this.picker.setTargetScanningFPS(props.targetScanningFPS);
    }
  };

  render() {
    return <div ref={this.scannerContainerRef} />;
  }
}

Scanner.propTypes = {
  licenseKey: PropTypes.string.isRequired,
  engineLocation: PropTypes.string.isRequired,
  accessCamera: PropTypes.bool,
  enableCameraSwitcher: PropTypes.bool,
  enableTorchToggle: PropTypes.bool,
  enablePinchToZoom: PropTypes.bool,
  enableTapToFocus: PropTypes.bool,
  guiStyle: PropTypes.any,
  playSoundOnScan: PropTypes.bool,
  vibrateOnScan: PropTypes.bool,
  scanSettings: PropTypes.object,
  scanner: PropTypes.object,
  paused: PropTypes.bool,
  targetScanningFPS: PropTypes.number,
  singleImageMode: PropTypes.object,

  onScan: PropTypes.func,
  onScanError: PropTypes.func,
};

export default Scanner;
