import React from 'react';
import { Box, Paper } from '@mui/material';
import { styles } from './HistoryInterventionItem.style';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { theme } from '../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    activeUser: state.generalReducer.loggedUser
  };
};

const HistoryInterventionItem = props => {


  return (
    <Paper elevation={2} id="container" sx={{...styles(theme).listItem}} onClick={() => props.onClick(props.interventionInfo)}>
      <Box component="div" sx={{...styles(theme).header}}>
        <Box component="div"  sx={{...styles(theme).dps}}>{props.interventionInfo.status}</Box>
      </Box>
      <Box component="div" sx={{...styles(theme).body}}>
        <Box component="div" sx={{...styles(theme).line}}>
          <Box component="div"  sx={{...styles(theme).grayText}}>{props.interventionInfo.generalComments}</Box>
        </Box>
        <Box component="div" sx={{...styles(theme).line}}>
          <Box component="div"  sx={{...styles(theme).grayText}}>{props.interventionInfo.marquage}</Box>
          <Box component="div"  sx={{...styles(theme).grayText}}>{props.interventionInfo.instrumentStatus}</Box>
        </Box>
      </Box>
    </Paper>
  );
};

HistoryInterventionItem.propTypes = {
  interventionInfo: PropTypes.object.isRequired,
  activeUser: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, null)
)(HistoryInterventionItem);
