import React, { Component } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import PropTypes from 'prop-types';

import InterventionService from '../../../../Providers/WebService/InterventionService';
import InterventionHelper from '../../../../Helpers/InterventionHelper';
import { styles } from './InterventionAnswerStep.style';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { ToolbarActionsConstants } from '../../../../Utils/Constants/ToolbarActionsConstants';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateToolbarInfo } from '../../../../store/actions/exportActions';
import { theme } from '../../../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo))
  };
};

class InterventionAnswerStep extends Component {

  state = {
    progress: 0,
    successes: [],
    errors: [],
  };

  constructor(props) {
    super(props);
    const numberOfRequests = props.instruments.length;
    this.step = 100 / numberOfRequests;
  }

  componentDidMount() {
    this.props.updateToolbarInfo({
      title: this.props.onMobile ? '' : strings.intervention.answerStep,
      icon: null,
      actions: [ToolbarActionsConstants.INTERVENTIONS_HOME]
    });
    this.trySendInterventions();
  }

  /**
   * Send interventions one by one to server
   */
  trySendInterventions() {
    this.setState({ progress: 0, successes: [], errors: [] });
    this.updateDocumentsAsBlob(this.props.instruments,
      (newInstruments => {
        const interventionsToSend = InterventionHelper
          .buildGenericInterventionData(newInstruments, this.props.intervention);
        interventionsToSend.map(async intervention => {
          await this.createIntervention(intervention);
        });
      }));
  }

  /**
   * Read a file asynchronously
   * @param {any} file - file to be read
   * @param {function} callbackMethod - callback method to be called once file is successfully read
   * @return {Promise<any>}
   */
  readFileAsync = (file, callbackMethod) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = (fileLoadedEvent) => {
        const binaryString = fileLoadedEvent.target.result;
        callbackMethod(binaryString, () => {
          resolve();
        });
      };

      reader.onerror = reject;

      reader.readAsBinaryString(file);
    });
  };

  /**
   * Get all out of bound scans for the current user with instruments information
   * @param {array} instruments: intervention instruments to update
   * @param {func} callback: callback method
   */
  async updateDocumentsAsBlob(instruments, callback) {
    const sumReducer = (acc, curr) => acc + curr;
    let count = instruments.map(instr => instr.intervention.documents.length).reduce(sumReducer);

    // If no documents, we return the instruments
    if (count === 0) {
      callback(instruments);
    }

    // Map each instrument to convert its attached file to binary data
    Promise.all(instruments.map(async instrument => {
      return Promise.all(instrument.intervention.documents.map(async document => {
        // if document is just a link, no need to process it as a file
        if (document.linkUrl) {
          // If we're done with all documents, exit the method
          count--;
          if (!count) {
            callback(instruments);
          }
          return null;
        } else {
          // if document is a file, process it to set the document.binary attribute
          await this.readFileAsync(document, (binaryString, resolveReading) => {
            document.binary = btoa(binaryString);
            // If we're done with all documents, exit the method
            count--;
            if (!count) {
              callback(instruments);
            }
            resolveReading();
          });
          return null;
        }
      }));
    }));
  }

  /**
   * Create an intervention on the server
   * @param {object} intervention: intervention
   */
  async createIntervention(intervention) {
    InterventionService.createGenericIntervention(intervention).then(() => {
      this.setState(prevState =>
        ({ progress: prevState.progress + this.step, successes: prevState.successes.concat(intervention) }));
    }).catch(() => {
      this.setState(prevState =>
        ({ progress: prevState.progress + this.step, errors: prevState.errors.concat(intervention) }));
    });
  }

  render() {
    return (
      <Box component="div" sx={{...styles(theme).container}}>
        <Box component="div" sx={{...styles(theme).progressContainer}}>

          {/* PROGRESS BAR */}
          {strings.intervention.creatingInterventions}
          {this.props.instruments.length > this.state.successes.length + this.state.errors.length && <LinearProgress />}
          <LinearProgress variant="determinate" value={this.state.progress} />
          {this.state.progress + '%'}

          {/* DISPLAY OF FAILED INTERVENTIONS */}
          {this.state.errors.map(error => (<p key={error.instrument} style={{ color: 'red' }}>{error.instrument}</p>))}

          {/* RETRY BUTTON IF ALL INTERVENTIONS FAILED */}
          {this.state.errors.length === this.props.instruments.length && (
            <Button
              id="retryButton"
              onClick={() => this.trySendInterventions()}
            >
              {strings.intervention.tryAgain}
            </Button>
          )}
        </Box>
      </Box>
    );
  }
}

InterventionAnswerStep.propTypes = {
  intervention: PropTypes.object.isRequired,
  instruments: PropTypes.array.isRequired,
  onMobile: PropTypes.bool.isRequired,
  updateToolbarInfo: PropTypes.func.isRequired
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(InterventionAnswerStep);
