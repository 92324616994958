import React from 'react';
import { Chip, Fab } from '@mui/material';
import PropTypes from 'prop-types';
import { Add, Clear } from '@mui/icons-material';
import { styles } from './ListFilterField.style';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import { theme } from '../../../../Utils/Theme';

const ListFilterField = props => {
  return (
    <>
      {
        props.filterValues.map(value => {
          return (<Chip
            key={props.fieldName + value}
            sx={{...styles(theme).chip}}
            label={value ? value : strings.general.emptyField}
            onDelete={() => props.onRemoveFilterValue(props.fieldName, value)}
            color="primary"
            variant="outlined"
            deleteIcon={<Clear sx={{...styles(theme).chipIcon}} />}
            style={{ fontStyle: !value && 'italic' }}
          />);
        })
      }

      <Fab
        id={`fab-${props.fieldName}`}
        sx={{...styles(theme).addButton}}
        size="small"
        onClick={() => props.onAddFilterValue()}>
        <Add />
      </Fab>
    </>
  );
};

ListFilterField.propTypes = {
  fieldName: PropTypes.string,
  filterValues: PropTypes.array,
  onRemoveFilterValue: PropTypes.func,
  onAddFilterValue: PropTypes.func,
};

export default ListFilterField;
