import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  banner_root: {
    display: 'flex',
    padding: '0px',
    overflow: 'hidden',
    justifyContent: 'center',
  },
  banner_text: {
    padding: '0px 5px',
    fontSize: '2rem',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'white',
  },
  severity_critical: {
    backgroundColor: theme.palette.redTheme.main,
  },
  severity_medium: {
    backgroundColor: theme.palette.orangeTheme.main,
  },
  severity_info: {
    backgroundColor: theme.palette.blueTheme.main,
  },
});

