import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircle, ErrorOutline, Warning } from '@mui/icons-material/index';
import sharedStyles from '../../../../Utils/SharedStyles';
import { TOAST_TYPES } from '../../../../Utils/Constants/ToastTypes';
import { theme } from '../../../../Utils/Theme';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

const ToastContent = props => {
  const styles = sharedStyles;

  let icon, customClass;
  switch (props.type) {
    case TOAST_TYPES.SUCCESS:
      icon = <CheckCircle />;
      customClass = styles(theme).toast_success;
      break;
    case TOAST_TYPES.WARNING:
      icon = <Warning />;
      customClass = styles(theme).toast_warning;
      break;
    case TOAST_TYPES.ERROR:
    default:
      icon = <ErrorOutline />;
      customClass = styles(theme).toast_error;
      break;
  }

  return (
    <Box component="div" sx={{...merge({}, styles(theme).toast_content, customClass)}}>
      {icon}
      <p>{props.message}</p>
      <Box component="div" sx={{...styles(theme).toast_message}}>
        {props.instrument &&
          <>
            <Box component="div" sx={{...styles(theme).toast_text}}>
              <Box component="p"  sx={{...styles(theme).bold}}>{props.instrument.designation}</Box>
              <p>{props.instrument.marquage}</p>
            </Box>
            <Box component="div" sx={{...styles(theme).toast_text}}>
              <Box component="p"  sx={{...styles(theme).bold}}>{props.instrument.constructeur}</Box>
              <p>{props.instrument.referenceConstructeur}</p>
            </Box>
          </>
        }
      </Box>
    </Box>
  );
};

ToastContent.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
  instrument: PropTypes.object
};

export default ToastContent;
