import sharedStyles from '../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  cartStep: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 'inherit',
    flex: 1
  }
});
