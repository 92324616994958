import React from 'react';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../../Utils/Constants/LocalizedString';
import Typography from '@mui/material/Typography';
import SimpleDialog from '../../../Dialogs/SimpleDialog/SimpleDialog';
import { isNotComputer } from "../../../Utils/ResponsiveSelect/ResponsiveSelect";

const ConfirmRequestDialog = props => {

  const validateRequest = () => {
    props.handleDialog();
    props.submitRequest();
  };

  return (
    <SimpleDialog
      id="dialog"
      open={props.open}
      title={strings.interventionRequest.confirmDialog.title}
      onRefuse={props.handleDialog}
      refuseLabel={strings.interventionRequest.cancel}
      acceptLabel={strings.interventionRequest.submit}
      onAccept={validateRequest}
    >
      <Typography variant={isNotComputer ? 'h6' : 'subtitle1'}>{strings.interventionRequest.confirmDialog.label}</Typography>
    </SimpleDialog>
  );
};

ConfirmRequestDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  submitRequest: PropTypes.func.isRequired,
  handleDialog: PropTypes.func.isRequired
};

export default ConfirmRequestDialog;
