import React from 'react';
import PropTypes from 'prop-types';
import { styles } from '../Borrowing/BorrowingPage.style';
import { Typography, Button, AppBar, Toolbar, IconButton, Box } from '@mui/material';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { Home } from '@mui/icons-material';
import { theme } from '../../../Utils/Theme';

const BorrowingResumePage = props => {

  const showResult = () => {
    if(props.resultInformations.results) {
      let updatedCount = 0;
      let notUpdatedCount = 0;
      props.resultInformations.results.forEach(res => {
        updatedCount += res.inventoryResult.updated.length;
        notUpdatedCount += res.inventoryResult.notUpdated.length;
      });
      return (
        <Typography variant="body2">
          {strings.traceability.result(updatedCount, notUpdatedCount)}
        </Typography>
      );
    } else {
      const { updated, notUpdated } = props.resultInformations.result.inventoryResult;
      return (
        <Typography variant="body2">
          {strings.traceability.result(updated.length, notUpdated.length)}
        </Typography>
      );
    }
  };

  return <>
    <AppBar position="static">
      <Toolbar>
        <IconButton
          id="homeIcon"
          sx={{...styles(theme).menuButton}}
          color="inherit"
          aria-label="Menu"
          onClick={() => props.onHomePressed()}
          size="large">
          <Home />
        </IconButton>
        <Typography variant="h6" color="inherit" sx={{...styles(theme).grow}}>
          {props.resultInformations.isReturn ? strings.traceability.return : strings.traceability.borrow}
        </Typography>
      </Toolbar>
    </AppBar>
    <Box component="div" sx={{...styles(theme).root}}>
      <Typography variant="h6" style={{ margin: "16px" }}>{strings.traceability.thanks}</Typography>
      <Typography variant="body2">{strings.traceability.thanksSentence}</Typography>
      {!props.resultInformations.isReturn &&
      <Typography variant="body2">{strings.traceability.dontForget}</Typography>
      }
      {showResult()}
      <Button
        id="homeButton"
        style={{ marginTop: "16px" }}
        variant="contained"
        color="primary"
        onClick={() => props.onHomePressed()}>{strings.traceability.end}</Button>
    </Box>
  </>;
};

BorrowingResumePage.propTypes = {
  onHomePressed: PropTypes.func,
  resultInformations: PropTypes.object
};

export default BorrowingResumePage;
