import sharedStyles from '../../Utils/SharedStyles';

export const styles = theme => ({
  ...sharedStyles(theme),
  feedbackPageContent: {
    padding: '2vh 1vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90vw',
    '@media (maxWidth:960px)': {
      width: '98vw'
    },
  },
  commentArea: {
    width: '50vw',
    minHeight: '50vh',
    '&:focus': {
      borderColor: 'MainMenu.pageTitles'
    },
    '@media (maxWidth:960px)': {
      width: '90vw'
    },
  },
  submit: {
    pointerEvents: 'auto!important',
    width: '10vw',
    backgroundColor: 'MainMenu.pageTitles',
    color: 'secondary.main',
    '&:hover': {
      backgroundColor: 'MainMenu.topMenu'
    },
    '&:disabled': {
      '&:hover': {
        cursor: 'not-allowed',
      }
    },
    '@media (maxWidth:960px)': {
      width: '50vw'
    },
  },
  // to style the textField area border on focus
  cssOutlinedInput: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'MainMenu.pageTitles',
    },
  },
});