// REACT libraries
import React from 'react';
import PropTypes from 'prop-types';
// Material-UI libraries
import { Typography, Grid, Tooltip } from '@mui/material';
import { styles } from './StaticField.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

// Field including a name and a value for InstrumentCard
const StaticField = props => {

  const getValue = () => {
    let params = {
      id: 'emptyValue',
      color: theme.palette.greyTheme.lighter,
      fontStyle: 'italic',
      value: strings.general.emptyField,
    };
    if(props.value) {
      params = {
        id: 'definedValue',
        color: theme.palette.MainMenu.pageTitles,
        fontStyle: 'normal',
        value: props.value,
      };
    }
    return (
      <Typography
        id={params.id}
        variant="body1"
        style={{
          '--color': params.color,
          '--fontStyle': params.fontStyle
        }}
        sx={{...merge({}, styles(theme).staticfieldTextValue,
          (isNotComputer && styles(theme).tabletFontSize))}}
      >
        {params.value}
      </Typography>
    );
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{ "&.MuiGrid-container": {...styles(theme).container} }}>
      <Grid item xs={5} sx={{...merge({}, styles(theme).verticalAlign, styles(theme).endTextAlign)}}>
        <Typography variant="body2" sx={{...merge({}, styles(theme).textLabel,
          (isNotComputer && styles(theme).tabletFontSize))}}>
          {props.name}
        </Typography>
      </Grid>
      <Grid item xs={7} sx={{...styles(theme).verticalAlign}}>
        {props.tooltipTitle
          ? <Tooltip
            title={props.tooltipTitle}
            sx={{...styles(theme).tooltip}}
            placement="top-start"
          >
            {getValue()}
          </Tooltip>
          : getValue()
        }


      </Grid>
    </Grid>
  );
};

StaticField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  tooltipTitle: PropTypes.string,
};

export default StaticField;
