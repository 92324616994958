import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import { Update } from '@mui/icons-material';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import { updateToolbarInfo } from '../../store/actions/exportActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { styles } from './UpdatePage.style';
import { compose } from 'redux';
import changelogContent from '../../Assets/CHANGE_LOG.md';
import { theme } from '../../Utils/Theme';
import { Box } from '@mui/material';

// maps Reducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
  };
};

export class UpdatePage extends Component {

  state = {
    changelogContent: ''
  };

  componentDidMount() {
    this.props.updateToolbarInfo({
      title: strings.mainMenu.update,
      icon: (<Update />),
      actions: []
    });

    fetch(changelogContent).then((res) => {
      return res.text();
    }).then((text) => {
      this.setState({ changelogContent: text });
    });
  }

  render() {
    return (
      <Box component="div" sx={{...styles(theme).scrollbarContent}}>

        <Scrollbars>
          <ReactMarkdown>{this.state.changelogContent}</ReactMarkdown>
        </Scrollbars>
      </Box>
    );
  }
}

UpdatePage.propTypes = {
  updateToolbarInfo: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(UpdatePage);
