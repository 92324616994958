import routes from '../../Utils/Routes';
import HttpClient from '../HttpClient';

class AuthenticationService {

  static async authenticate(login, password) {
    const loginData = {
      login,
      password
    };  
    return HttpClient.post(routes.login, loginData).then(response => Promise.resolve(response.data));
  }

  static getRefreshedAccessToken(refreshToken, oldToken) {
    const data = {
      refresh_token: refreshToken,
      old_access_token: oldToken
    };
    return HttpClient.post(routes.refreshAccessToken, data).then(token => Promise.resolve(token.data));
  }

}

export default AuthenticationService;
