import React, { Component } from 'react';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import SimpleDialog from '../../Dialogs/SimpleDialog/SimpleDialog';
import InstrumentsProvider from '../../../Providers/Database/InstrumentsProvider';

class InterventionItemInfo extends Component {

  state = {
    instruments: []
  };

  componentDidMount() {
    InstrumentsProvider.getInstrumentsFromListOfMarquages(this.props.interventionInfo.instruments).then(instruments => {
      this.setState({ instruments: instruments });
    });
  }

  render() {
    return (
      <SimpleDialog
        id="simpleDialog"
        open
        title={this.props.interventionInfo.interventionRequestNumber}
        acceptLabel={strings.general.ok}
        onRefuse={this.props.onClose}
        onAccept={this.props.onClose}>
        {this.state.instruments.length > 0 ?
          <List id="instrumentsList">
            {
              this.state.instruments.map(instrument => {
                return (<ListItemButton
                  key={instrument.marquage}
                  onClick={() => this.props.onInstrumentClick(instrument)}>
                  <ListItemText primary={instrument.designation} secondary={instrument.marquage} />
                </ListItemButton>);
              })
            }
          </List>
          :
          <Typography variant='subtitle1'>{strings.interventionHome.noInstruments}</Typography>}
      </SimpleDialog>
    );
  }
}

InterventionItemInfo.propTypes = {
  interventionInfo: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onInstrumentClick: PropTypes.func.isRequired
};

export default InterventionItemInfo;
