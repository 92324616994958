import React, { Component } from 'react';
import { styles } from './PreferencesPage.style';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import {
  resetInterventionRequest,
  toggleFilterPage,
  toggleSavedFilter,
  updateToolbarInfo
} from '../../store/actions/exportActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Add, Build, CropRotate, Info, Settings } from '@mui/icons-material';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import PreferencesService from '../../Providers/WebService/PreferencesService';
import SimpleDialog from '../Dialogs/SimpleDialog/SimpleDialog';
import ServiceShippingStep from '../InterventionRequest/Steps/ServiceShippingStep/ServiceShippingStep';
import IconButton from '@mui/material/IconButton';
import InterventionRequestHelper from '../../Helpers/InterventionRequestHelper';
import { InterventionRequestConstants } from '../../Utils/Constants/InterventionRequestConstants';
import FilterProvider from '../../Providers/Database/FilterProvider';
import FilterCreationDialog from '../FilterDialog/FilterCreationDialog';
import VersionService from '../../Providers/WebService/VersionService';
import SvgIcon from '@mui/material/SvgIcon';
import { svgConstants } from '../../Utils/Constants/SvgConstants';
import { SnackbarMessages } from '../../Utils/Constants/SnackbarMessages';
import Card from '@mui/material/Card';
import { ConnectivityChecker } from '../../Utils/ConnectivityChecker';
import InterventionRequestService from '../../Providers/WebService/InterventionRequestService';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Tooltip } from '@mui/material';
import LoadingSpinner from '../Utils/LoadingSpinner/LoadingSpinner';
import ValidationDialog from '../Dialogs/ValidationDialog/ValidationDialog';
import UserProvider from '../../Providers/Database/UserProvider';
import { withGuide } from '../Guides/Guidable';
import { ToolbarActionsConstants } from '../../Utils/Constants/ToolbarActionsConstants';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../Utils/Theme';

export const mapStateToProps = state => {
  return {
    serviceShippingPage: state.interventionReducer.serviceShippingPage,
    showFilterPage: state.toolbarReducer.showFilterPage,
    onMobile: state.generalReducer.onMobile
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    updateToolbarInfo: toolbarInfo => dispatch(updateToolbarInfo(toolbarInfo)),
    resetInterventionRequest: value => dispatch(resetInterventionRequest(value)),
    toggleSavedFilter: value => dispatch(toggleSavedFilter(value)),
    toggleFilterPage: showFilterPage => dispatch(toggleFilterPage(showFilterPage)),
  };
};

class PreferencesPage extends Component {

  state = {
    appVersion: null,
    filters: [],
    showEditPrefDialog: false,
    newPreference: null,
    oldPrefName: '',
    prefName: '',
    currentFilter: {},
    currentFilterName: null,
    isConnected: true,
    preferences: [],
    submitted: false,
    isLoadingPreferences: false,
    loading: false,
    showValidation: false,
    preferenceToDelete: null,
    spinnerLoading: false,
    landscapeLocked: true
  };

  async componentDidMount() {
    // Display toolbar's content (icon, title, iconButtons)
    this.props.updateToolbarInfo({
      title: strings.preferences.title,
      icon: (<Settings/>),
      actions: [ToolbarActionsConstants.START_TUTORIAL]
    });

    /// get application current version
    VersionService.getCurrentVersion()
      .then(version => {
        this.setState({
          appVersion: version
        });
      });

    if (!this.props.onMobile) {
      const lock = await UserProvider.hasLockedLandscapeMode();
      this.setState({ landscapeLocked: lock });
    }

    // check connection to display error message on interventions card
    ConnectivityChecker.isConnected()
      .then(async res => {
        this.setState({ isConnected: res });
        if (res) {
          // get all preferences
          await this.getPreferences();
        }
      });

    // get filters
    this.getSavedFilters();
  }

  async getPreferences() {
    this.setState({ isLoadingPreferences: true });
    const types = await InterventionRequestService.getInterventionTypes();
    const prefs = types.map(async type => {
      return { ...type, preferences: await PreferencesService.getUserPreferences(type.id) };
    });
    Promise.all(prefs).then(res => this.setState({ preferences: res, isLoadingPreferences: false }));
  }

  getSavedFilters = () => FilterProvider.getSavedFilters().then(res => this.setState({ filters: res }));

  togglePrefDialog = () => this.setState(state => ({ showEditPrefDialog: !state.showEditPrefDialog }));

  handlePrefNameChange = name => this.setState({ prefName: name });

  validatePref = () => {

    if (this.state.prefName.length !== 0 && this.props.serviceShippingPage.provider.name.length !== 0) {
      this.setState({ submitted: false, spinnerLoading: true });

      // check that the pref's name is unique
      const alreadyExists = this.state.preferences.filter(typePrefs =>
        typePrefs.id === this.props.serviceShippingPage.serviceType
        &&
        typePrefs.preferences.some(pref => pref.saveName === this.state.prefName)).length > 0;


      if (alreadyExists && this.state.newPreference) {
        enqueueSnackbar(SnackbarMessages.preferenceDuplicateName.msg,
          SnackbarMessages.preferenceDuplicateName.type);
        return;
      }

      const name = this.state.prefName;
      const data = {
        saveName: name,
        interventionTypeID: this.props.serviceShippingPage.serviceType,
        locationTypeID: this.props.serviceShippingPage.serviceLocation,
        supplierType: this.props.serviceShippingPage.providerType,
        supplierID: this.props.serviceShippingPage.provider.id,
        supplierName: this.props.serviceShippingPage.recipient,
        supplierAddress: this.props.serviceShippingPage.providerAddress,
        supplierPhone: this.props.serviceShippingPage.providerPhone,
        supplierFax: this.props.serviceShippingPage.providerFax,
        supplierEmail: this.props.serviceShippingPage.providerMail,
        senderID: this.props.serviceShippingPage.shipperId,
        senderAddress: this.props.serviceShippingPage.shipperAddress,
        senderPhone: this.props.serviceShippingPage.shipperPhone,
        senderFax: this.props.serviceShippingPage.shipperFax,
        senderEmail: this.props.serviceShippingPage.shipperMail,
        carrierMode: this.props.serviceShippingPage.carrierMode.id,
        carrierID: this.props.serviceShippingPage.carrier
      };

      if (this.state.newPreference) {
        PreferencesService.addUserPreferences(data)
          .then(() => {
            this.updateDisplay();
          });
      } else {
        PreferencesService.updateUserPreferences(this.state.oldPrefName, data)
          .then(() => {
            this.updateDisplay();
          });
      }
    } else {
      this.setState({ submitted: true });
      enqueueSnackbar(SnackbarMessages.preferenceEmptyField.msg, SnackbarMessages.preferenceEmptyField.type);
    }
  };

  updateDisplay = () => {
    this.getPreferences();
    this.clearState();
    this.setState({ spinnerLoading: false });
    this.togglePrefDialog();
  };

  toggleValidationPopUp = () => this.setState(state => ({ showValidation: !state.showValidation }));

  onDeletePreference = pref => {
    this.setState({
      preferenceToDelete: pref,
      showValidation: true
    });
  };

  deletePref = () => {
    this.toggleValidationPopUp();
    this.setState({ isLoadingPreferences: true });
    PreferencesService.deleteUserPreferences(this.state.preferenceToDelete)
      .then(() => {
        this.getPreferences();
        this.clearState();
        this.setState({ isLoadingPreferences: false });
      });
  };

  onDeleteFilter = filter => this.setState({ currentFilterName: filter.name, showValidation: true });

  deleteFilter = () => {
    this.toggleValidationPopUp();
    FilterProvider.delete(this.state.currentFilterName);
    this.getSavedFilters();
    this.clearState();
  };

  generateChips = prefs => {
    return prefs.map(pref => {
      return (
        <Chip
          label={pref.saveName}
          key={pref.id}
          icon={
            <Build/>
          }
          sx={{ "&.MuiChip-root": {...styles(theme).pref_chip}, iconColorPrimary: {...styles(theme).whiteColor} }}
          color="primary"
          onClick={() => this.updatePreference(pref)}
          onDelete={() => this.onDeletePreference(pref)}
        />
      );
    });
  };

  generateFilterChips = filters => {
    return filters.map(filter => {
      return (
        <Tooltip key={filter.name} title={FilterProvider.buildFilterPreview(filter.filter)}>
          <Chip
            label={filter.name}
            key={filter.name}
            sx={{ "&.MuiChip-root": {...styles(theme).pref_chip}, iconColorPrimary: {...styles(theme).whiteColor} }}
            color="primary"
            icon={
              <SvgIcon>
                <path d={svgConstants.customFilter}/>
              </SvgIcon>
            }
            onClick={() => this.updateFilter(filter)}
            onDelete={() => this.onDeleteFilter(filter)}
          />
        </Tooltip>
      );
    });
  };

  updateFilter = filter => {
    this.props.toggleFilterPage(!this.props.showFilterPage);
    this.setState({
      currentFilter: filter,
      currentFilterName: filter.name,
      newPreference: false
    });
  };

  addNewPreference = preferenceType => {
    const constants = InterventionRequestConstants.SERVICE_SHIPPING_PAGE_NAMES;
    InterventionRequestHelper.updateField(constants.SERVICE_TYPE, preferenceType.id);
    InterventionRequestHelper.updateField(constants.SERVICE_TYPE_NAME, preferenceType.name);
    this.setState({
      newPreference: true
    });
    this.togglePrefDialog();
  };

  updatePreference = preference => {
    this.setState({
      newPreference: false,
      oldPrefName: preference.saveName,
      prefName: preference.saveName
    }, () => {
      InterventionRequestHelper.fillServicePageWithPreferences(preference)
        .then(() => {
          this.togglePrefDialog();
        });
    });

  };

  clearState = () => {
    this.setState({
      newPreference: null,
      oldPrefName: '',
      prefName: '',
      currentFilter: {},
      currentFilterName: null,
      preferenceToDelete: null
    });
    this.props.resetInterventionRequest();
  };

  closePrefDialog = () => {
    this.clearState();
    this.togglePrefDialog();
  };

  handleCloseFilterDialog = () => {
    this.props.toggleFilterPage(!this.props.showFilterPage);
    this.setState({
      currentFilter: {},
      currentFilterName: null
    },
    () => this.getSavedFilters());
  };

  landscapeLockClick = () => {
    this.setState({ landscapeLocked: !this.state.landscapeLocked }, () => {
      UserProvider.setLockedLandscapeMode(this.state.landscapeLocked);
    });
  };

  generateCard = (title, avatar, content) => {
    return (
      <Card sx={{...styles(theme).pref_card}}>
        <CardHeader
          title={title}
          sx={{ title: {...styles(theme).pref_header_title}, ...styles(theme).pref_card_header }}
          avatar={avatar}
        />
        <CardContent sx={{...styles(theme).pref_card_content}}>
          {content}
        </CardContent>
      </Card>
    );
  };

  render() {
    const prefDialogTitle = this.state.newPreference ? strings.preferences.addPref : strings.preferences.editPref;

    // intervention types areas
    const areas = this.state.preferences.map(type => {
      return (
        <div id="preferencesInterventions" key={type.id}>
          <Typography variant="body2">{type.name}</Typography>
          <Box component="div" sx={{...styles(theme).chips_display}}>
            <IconButton
              id="newPreferenceButton"
              onClick={() => this.addNewPreference(type)}
              sx={{...styles(theme).pref_add_button}}
              size="large">
              <Add/>
            </IconButton>
            {this.generateChips(type.preferences)}
          </Box>
        </div>
      );
    });

    // card contents
    let interventionsContent;
    if (this.state.isConnected) {
      if (this.state.isLoadingPreferences) {
        interventionsContent = <Box component="div" sx={{...styles(theme).circular_loader}}><CircularProgress/></Box>;
      } else {
        interventionsContent = areas;
      }
    } else {
      interventionsContent = <Typography variant="body2" color="error">{strings.preferences.unavailable}</Typography>;
    }


    const filtersContent =
      <Box component="div" id="filters" sx={{...styles(theme).chips_display}}>
        <IconButton
          id="newFilterButton"
          onClick={() => this.props.toggleFilterPage(!this.props.showFilterPage)}
          sx={{...styles(theme).pref_add_button}}
          size="large">
          <Add/>
        </IconButton>
        {this.generateFilterChips(this.state.filters)}
      </Box>;

    const versionContent = this.state.appVersion ?
      <Typography variant="body2">{this.state.appVersion}</Typography>
      :
      <Typography variant="body2">{strings.auth.noAppVersion}</Typography>;

    const svgIconFilter =
      <SvgIcon sx={{ "&.MuiSvgIcon-root": {...styles(theme).whiteColor}}}>
        <path d={svgConstants.customFilter}/>
      </SvgIcon>;

    const landscapeRotationContent =
      <Switch
        id="switchLandscape"
        disabled={true}
        checked={this.state.landscapeLocked}
        onChange={() => this.landscapeLockClick()}
        color="primary"
      />;

    return (

      <Box component="div" sx={{...styles(theme).pref_page}}>

        {this.generateCard(strings.preferences.interventions, <Build/>, interventionsContent)}
        {this.generateCard(strings.preferences.filters, svgIconFilter, filtersContent)}
        {!this.props.onMobile && this.generateCard(strings.preferences.lockedOrientation,
          <CropRotate/>, landscapeRotationContent)}
        {this.generateCard(strings.preferences.version, <Info/>, versionContent)}

        <SimpleDialog
          open={this.state.showEditPrefDialog}
          onClose={this.closePrefDialog}
          title={prefDialogTitle}
          acceptLabel={strings.dialog.answer.submit}
          refuseLabel={strings.dialog.answer.cancel}
          onAccept={this.validatePref}
          onRefuse={this.closePrefDialog}
          fullScreen
        >
          <Box component="div" sx={{...styles(theme).prefContent}}>
            <ServiceShippingStep
              submitted={this.state.submitted}
              fromPreferencesPage
              handlePrefNameChange={this.handlePrefNameChange}
              preferenceName={this.state.prefName}
            />
          </Box>
        </SimpleDialog>
        {this.state.loading && <LoadingSpinner/>}

        <FilterCreationDialog
          hideApplyButton
          hideLoadFilterButton
          defaultFilter={this.state.currentFilter.filter}
          defaultSaveName={this.state.currentFilterName}
          onCloseOverride={this.handleCloseFilterDialog}
          open={this.props.showFilterPage}
        />

        <ValidationDialog
          title={strings.dialog.title.areYouSure}
          open={this.state.showValidation}
          onRefuse={this.toggleValidationPopUp}
          onAccept={this.state.preferenceToDelete !== null ? this.deletePref : this.deleteFilter}
          refuseLabel={strings.dialog.answer.cancel}
          acceptLabel={strings.dialog.answer.submit}
          text={strings.dialog.content.validateSuppression}
        />
        {this.state.spinnerLoading && <LoadingSpinner/>}
      </Box>
    );
  }
}

PreferencesPage.propTypes = {
  // REDUX
  updateToolbarInfo: PropTypes.func.isRequired,
  serviceShippingPage: PropTypes.object.isRequired,
  resetInterventionRequest: PropTypes.func.isRequired,
  toggleFilterPage: PropTypes.func.isRequired,
  showFilterPage: PropTypes.bool,
  onMobile: PropTypes.bool
};


export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withGuide
)(PreferencesPage);
