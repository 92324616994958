import sharedStyles from '../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  item: {
    padding: "8px",
    borderBottom: `1px solid ${theme.palette.intervention.mobile_divider}`,
    backgroundColor: theme.palette.pureWhite,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  actionButton: {
    color: theme.palette.greenTheme.main
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
  },
  middleContainer: {
    margin: "8px",
    flex: '1 1 auto',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  rightContainer: {
    flex: '0 0 auto'
  }
});
