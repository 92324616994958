import React from 'react';
import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import SmartTextField from '../Utils/SmartTextField/SmartTextField';
import { ValidatorRules } from '../../Utils/Validator';

const PreferenceNameCard = props => {

  return (
    <Paper elevation={2} sx={{...props.styling.container}}>
      <Typography variant="h6" sx={{...props.styling.area_title}} gutterBottom>
        {strings.preferences.prefName}
      </Typography>
      <SmartTextField
        required
        types={[ValidatorRules.NOT_EMPTY]}
        submitted={props.submitted}
        name="prefName"
        label={strings.preferences.labelMyPref}
        fullWidth
        value={props.preferenceName}
        onChange={e => props.handlePrefNameChange(e.target.value)}
        InputProps={{
          sx: {...props.styling.input_content}
        }}
        InputLabelProps={{
          sx: {...props.styling.input_label}
        }}
      />
    </Paper>
  );

};

PreferenceNameCard.propTypes = {
  styling: PropTypes.object.isRequired,
  preferenceName: PropTypes.string,
  submitted: PropTypes.bool,
  handlePrefNameChange: PropTypes.func.isRequired,
};

export default PreferenceNameCard;
