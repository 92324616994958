import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Validator } from '../../../Utils/Validator';
import { Tooltip } from '@mui/material';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';

export const isNotComputer = navigator.userAgent.includes('Android');

const ResponsiveSelect = props => {

  const maxItems = 100;
  const { styledSelect, value, native, onChange, name } = props;

  const valid = useMemo(() => Validator.checkValidity(props.value, props.types), [props.value, props.types]);

  const createValues = (forMobile = false) => {
    if (forMobile) {
      const options = props.data
        .map(item => (<option key={item.id} value={props.isForShipper ? item.name : item.id}>{item.name}</option>));
      if (props.noDefaultValue) {
        options.unshift(<option key={new Date().getTime()} value=""/>);
      }
      return options;
    } else {
      return props.data
        .map(item => (<MenuItem key={item.id} value={props.isForShipper ? item.name : item.id}>{item.name}</MenuItem>));
    }
  };


  return (
    props.tooltip === false ?
      <Tooltip title={strings.intervention.conclusionCard.notEditable}>
        <Select
          sx={{ "& .MuiNativeSelect-standard,& .MuiSelect-standard": {...styledSelect}}}
          variant="standard"
          value={value}
          error={(props.submitted && props.required) ? !valid : false}
          onChange={e => onChange(e)}
          inputProps={{
            name: name,
            id: name,
          }}
          native={props.data.length > maxItems || native}
          disabled={props.disabled}
        >
          {createValues(props.data.length > maxItems || native)}
        </Select>
      </Tooltip> :
      <Select
        sx={{ "& .MuiNativeSelect-standard,& .MuiSelect-standard": {...styledSelect}}}
        variant="standard"
        value={value}
        error={(props.submitted && props.required) ? !valid : false}
        onChange={e => onChange(e)}
        inputProps={{
          name: name,
          id: name,
        }}
        native={props.data.length > maxItems || native}
        disabled={props.disabled}
      >
        {createValues(props.data.length > maxItems || native)}
      </Select>
  );
};

ResponsiveSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  native: PropTypes.bool,
  tooltip: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  data: PropTypes.array,
  isForShipper: PropTypes.bool,
  styledSelect: PropTypes.object,
  noDefaultValue: PropTypes.bool,
  submitted: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  types: PropTypes.array
};

export default ResponsiveSelect;
