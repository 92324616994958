import db from '../Database';
import { logger } from '../../Logger';

const Intervention = db.interventions.defineClass({
  // bool
  inventaire: Number,
  marquage: String,
  resultingStatus: String,
  comment: String,
  updateInstrumentUser: String,
  updateInstrumentLocation: String
});

Intervention.prototype.setValues = function (inventaire, marquage, resultingStatus, comment, updateInstrumentUser = null, updateInstrumentLocation = null) {
  this.inventaire = inventaire;
  this.marquage = marquage;
  this.resultingStatus = resultingStatus;
  this.comment = comment;
  this.updateInstrumentUser = updateInstrumentUser;
  this.updateInstrumentLocation = updateInstrumentLocation;
};

Intervention.prototype.log = function () {
  logger(this);
};

Intervention.prototype.save = function () {
  return db.interventions.put(this);
};

export default Intervention;
