import React from 'react';
import PropTypes from 'prop-types';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { styles } from './Stepper.style';
import PAGES_IDS from '../../../Utils/Constants/PagesIDs';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { theme } from '../../../Utils/Theme';
import { Box } from '@mui/material';
import merge from 'lodash/merge';

export const mapStateToProps = state => {
  return {
    currentPage: state.generalReducer.currentPage,
    onMobile: state.generalReducer.onMobile,
  };
};

const Stepper = props => {

  let fullStepperStyle;
  let stepperStyle;
  let activeDotStyle;
  let newTheme;

  switch (props.currentPage) {
    case PAGES_IDS.INTERVENTION_REQUEST:
    case PAGES_IDS.INTERVENTION_CREATION:
      newTheme = styles(theme).intervention_bg_theme;
      break;
    case PAGES_IDS.INVENTORY:
    default:
      newTheme = styles(theme).inventory_bg_theme;
      break;
  }

  fullStepperStyle = merge({}, styles(theme).fullStepper, (props.onMobile && newTheme));
  stepperStyle = merge({}, styles(theme).stepper, (props.onMobile && newTheme));
  activeDotStyle = props.onMobile ? styles(theme).whiteBackground : newTheme;

  return (
    <Box component="div" sx={{...fullStepperStyle}}>
      <MobileStepper
        id="mobileStepper"
        steps={props.numberOfSteps}
        position="static"
        activeStep={props.activeStep}
        sx={{
          "&.MuiMobileStepper-root": {...stepperStyle},
          "& .MuiMobileStepper-dots": {...styles(theme).dots},
          "& .MuiMobileStepper-dot": {...styles(theme).dot},
          "& .MuiMobileStepper-dotActive": {...activeDotStyle}
        }}
        nextButton={
          <Box component="div" sx={{...styles(theme).divButton2}}>
            <Button
              id="nextButton"
              size="small"
              onClick={props.onNext}
              disabled={props.activeStep === props.numberOfSteps - 1}
              sx={{ "&.MuiButton-root": {...styles(theme).buttonStep}, "&.Mui-disabled": {...styles(theme).buttonStep_disabled} }}>
              <Box component="p" sx={{...styles(theme).buttonTitle}}>{props.nextLabel}</Box>
              <KeyboardArrowRight fontSize="large"/>
            </Button>
          </Box>
        }
        backButton={
          <Box component="div" sx={{...styles(theme).divButton1}}>
            <Button
              id="prevButton"
              size="small"
              onClick={props.onPrev}
              disabled={props.activeStep === 0}
              sx={{ "&.MuiButton-root": {...styles(theme).buttonStep}, "&.Mui-disabled": {...styles(theme).buttonStep_disabled} }}>
              <KeyboardArrowLeft fontSize="large"/>
              <Box component="p"  sx={{...styles(theme).buttonTitle}}>{props.prevLabel}</Box>
            </Button>
          </Box>
        }
      />
      <Box component="p"  sx={{...styles(theme).footerTitle}}>{props.currentLabel}</Box>
    </Box>
  );
};

Stepper.propTypes = {
  numberOfSteps: PropTypes.number.isRequired,
  activeStep: PropTypes.number.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  nextLabel: PropTypes.string,
  prevLabel: PropTypes.string,
  currentLabel: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  onMobile: PropTypes.bool.isRequired,
};

export default compose(
  connect(mapStateToProps))(Stepper);
