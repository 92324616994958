import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  paddingPaper: {
    padding: theme.spacing(2),
  },
  profileContent : {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  }
});
