import { theme } from '../../Utils/Theme';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';


export const style = {
  buttonClose: { display: 'none' },
  buttonSkip: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "4px"
  },
  options: {
    arrowColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    overlayColor: theme.palette.blackTheme.transparent,
    textColor: theme.palette.MainMenu.pageTitles,
    zIndex: 2000,
  },
};

export const localization = {
  web: {
    skip: strings.guides.skip,
    back: strings.guides.back,
    close: strings.guides.close,
    last: strings.guides.last,
    next: strings.guides.next
  },
  mobile: {
    skip: strings.guides.mobile.skip,
    back: strings.guides.mobile.back,
    close: strings.guides.mobile.close,
    last: strings.guides.mobile.last,
    next: strings.guides.mobile.next
  },
  start: strings.guides.start
};

