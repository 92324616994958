import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    padding: '4px 16px',
  },
  title: {
    color: theme.palette.MainMenu.pageTitles,
    [theme.breakpoints.down('md')]: {
      fontSize: "18px"
    }
  },
  actionsHeader: {
    margin: 'auto'
  },
  divider: {
    backgroundColor: theme.palette.MainMenu.otherMenuOptions,
    height: "2px"
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: "0px"
    },
    padding: "4px",
    [theme.breakpoints.only('xs')]: {
      height: '100%'
    },
    flex: 1
  },
});
