import sharedStyles from '../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  historicContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-around',
    height: '100%'
  },
  column: {
    width: '45%',
    [theme.breakpoints.only('sm')]: {
      width: '50%',
    },
  },
});
