export let styles = theme => ({
  statusBar: {
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "16px",
    color: '#ffffff',
    margin: `0px -${theme.spacing()}`,
    marginBottom: "10px"
  },
  statusBarClosed: {
    paddingLeft: "26px",
  },
  tabletStatusBarClosed: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: "33px"
    }
  },
  online: {
    backgroundColor: theme.palette.validationGreenTheme.main
  },
  offline: {
    backgroundColor: theme.palette.redTheme.main
  },
  unreachable: {
    backgroundColor: theme.palette.orangeTheme.main
  },
  text: {
    display: 'inline-block',
    paddingLeft: "34px",
    position: 'relative',
    bottom: "4px"
  },
  tabletText: {
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.20rem !important',
      paddingLeft: "39px",
    }
  },
  pref_add_button: {
    padding: "0px",
    verticalAlign: 'unset',
    color: theme.palette.secondary.main
  }

});
