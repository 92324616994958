import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText, ListItemSecondaryAction, Badge, IconButton, Typography, ListItemIcon } from '@mui/material';
import { PictureAsPdf, Edit } from '@mui/icons-material';
import Divider from '@mui/material/Divider';

import { styles } from './InterventionItem.style';
import InstrumentImage from '../../../../../InstrumentCard/InstrumentImage/InstrumentImage';
import { theme } from '../../../../../../Utils/Theme';

const InterventionItem = props => {

  const numberOfDocuments = instrument => {
    if (!instrument.intervention || !instrument.intervention.documents) {
      return 0;
    }
    return instrument.intervention.documents.length;
  };

  return <>
    <ListItem
      id="item"
      onClick={e => {
        e.stopPropagation();
        props.onClick();
      }}
      sx={{ "&.MuiListItem-root": {...styles(theme).listItem}}}
    >
      <ListItemIcon sx={{ "&.MuiListItemIcon-root": {...styles(theme).instrumentImage}}}>
        <InstrumentImage instrument={props.instrument} />
      </ListItemIcon>
      <ListItemText
        sx={{ "&.MuiListItemText-root": {...styles(theme).listItemText}}}
        disableTypography
        primary={
          <>
            <Typography noWrap variant="subtitle1" sx={{...styles(theme).item_typography}}>{props.instrument.designation}</Typography>
            <Typography noWrap variant="subtitle1" sx={{...styles(theme).item_typography}}>{props.instrument.marquage}</Typography>
            <Typography noWrap variant="subtitle1" sx={{...styles(theme).item_typography}}>{props.instrument.constructeur}</Typography>
          </>
        }
      />
      <ListItemSecondaryAction sx={{right: theme.spacing(0.5)}}>
        <>
          <IconButton
            id="documentButton"
            sx={{ "&.MuiIconButton-root": {...styles(theme).side_action_button} }}
            onClick={props.openDocumentsDialog}
            size="large">
            <Badge id="badge" color="primary" style={{ padding: "5px" }} badgeContent={numberOfDocuments(props.instrument)} >
              <PictureAsPdf color="primary" />
            </Badge>
          </IconButton>
          <IconButton
            id="instrumentInfosButton"
            sx={{ "&.MuiIconButton-root": {...styles(theme).side_action_button} }}
            onClick={props.openEditionDialog}
            size="large">
            <Badge color="primary" style={{ padding: "2px" }} variant="dot" invisible={!props.hasModifications}>
              <Edit color="primary" />
            </Badge>
          </IconButton>
        </>
      </ListItemSecondaryAction>
    </ListItem>
    <Divider sx={{...styles(theme).classic_divider}} />
  </>;

};

InterventionItem.propTypes = {
  instrument: PropTypes.object.isRequired,
  hasModifications: PropTypes.bool,
  openDocumentsDialog: PropTypes.func.isRequired,
  openEditionDialog: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default InterventionItem;
