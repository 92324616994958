import db from '../../Utils/Database/Database';
import { ScanAnalysis } from '../../Utils/Constants/ScanAnalysis';
import { UserConstants } from '../../Utils/Constants/UserConstants';
import { InstrumentConstants } from '../../Utils/Constants/InstrumentConstants';
import dayjs from 'dayjs';

const nbOfDays = 30;
const maxScans = 1000;
const days = 'days';

const dateField = 'date';

class ScanProvider {

  /**
   * Get not analyzed scans for the current user
   * @param {string} currentUser: Current user login
   */
  static async getNotAnalyzedScans(currentUser) {
    return db.scans.where({ userLogin: currentUser, analysis: ScanAnalysis.NONE }).toArray();
  }

  /**
   * Clean local database.
   * Keep scans newer than 30 days.
   * 1000 scans is maximum capacity
   * @param {string} currentUser: Current user login
   */
  static async cleanLocaleDatabase(currentUser) {
    // Delete scans older than 30 days
    const maxDate = dayjs().add(-nbOfDays, days).toDate();
    db.scans.where(dateField)
      .below(maxDate)
      .and(scan => scan.userLogin === currentUser)
      .delete();

    db.scans.where({ userLogin: currentUser, analysis: ScanAnalysis.NONE }).delete();
    // Only keep last 1000 scans
    await db.scans.orderBy(dateField).offset(maxScans).delete();
  }

  /**
   * Get all scans from the current user
   * @param {string} currentUser: Current user login
   */
  static async getAllScans(currentUser) {
    return db.scans.where(UserConstants.userLoginDB).equals(currentUser).toArray();
  }

  /**
   * Get all scans from the current user with instruments information
   * @param {string} currentUser: Current user login
   */
  static async getAllScansWithInstruments(currentUser) {
    return ScanProvider.getScansWithInstruments({ userLogin: currentUser });
  }

  /**
   * Get all out of bound scans for the current user with instruments information
   * @param {string} currentUser: Current user login
   */
  static async getOobScansWithInstruments(currentUser) {
    return ScanProvider.getScansWithInstruments({ userLogin: currentUser }, true);
  }

  static async getScansWithInstruments(whereClause, oobScan = false ) {
    return db.transaction('r', db.scans, db.instruments, async () => {
      let scans;
      if (oobScan) {
        scans = await db.scans.where(whereClause).and((item) => item.isInPerimeter == false).reverse().toArray();
      } else {
        scans = await db.scans.where(whereClause).reverse().toArray();
      }
      console.log("ResBD for clause :", oobScan);
      console.log(scans);
      const linkScansWithInstruments = scans.map(async scan => {
        if (!scan.marquage) {
          return scan;
        }
        const correspondingInstrument = await db.instruments.where(InstrumentConstants.marquageDB).equals(scan.marquage).first();
        if (correspondingInstrument) {
          return {
            ...scan,
            instrument: correspondingInstrument
          };
        }
        return scan;
      });
      const scansWithInsruments = await Promise.all(linkScansWithInstruments);
      return scansWithInsruments;
    });
  }
}

export default ScanProvider;
