import React, { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { styles } from './MainMenuStatusBar.style';
import PropTypes from 'prop-types';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { Cloud, CloudQueue, SyncProblem } from '@mui/icons-material';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ConnectivityCheckerStatus } from '../../../Utils/ConnectivityChecker';
import { globalConstants } from '../../../Utils/Constants/GlobalConstants';
import SynchronizationInstrumentDialog
  from '../../Dialogs/SynchronizationInstrumentDialog/SynchronizationInstrumentDialog';
import { instrumentListNeedsUpdate } from '../../../store/actions/exportActions';
import { SnackbarMessages } from '../../../Utils/Constants/SnackbarMessages';
import SynchronizationPage from '../../SynchronizationPage/SynchronizationPage';
import { isNotComputer } from '../../Utils/ResponsiveSelect/ResponsiveSelect';
import { enqueueSnackbar } from 'notistack';
import { theme } from '../../../Utils/Theme';
import merge from 'lodash/merge';

// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    networkStatus: state.generalReducer.networkStatus
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    instrumentListNeedsUpdate: (msg, type) => dispatch(instrumentListNeedsUpdate(msg, type))
  };
};


const MainMenuStatusBar = props => {

  const style = !props.menuIsOpen ? merge({}, styles(theme).statusBarClosed, (isNotComputer && styles(theme).tabletStatusBarClosed)) : {};
  const [showSynchronizationPage, setShowSynchronizationPage] = useState(false);

  const toggleSynchro = () => {
    setShowSynchronizationPage(!showSynchronizationPage);
  };

  const tweakSynchro = () => {
    setShowSynchronizationPage(!showSynchronizationPage);
    props.instrumentListNeedsUpdate(true);
    props.SynchroDialogIsOpen();
    enqueueSnackbar(SnackbarMessages.instrumentSynchronizationSuccess.msg,
      SnackbarMessages.instrumentSynchronizationSuccess.type);
  };

  switch (props.networkStatus) {
    case ConnectivityCheckerStatus.ONLINE:
      return <>
        <Tooltip title={strings.mainMenu.online} enterDelay={globalConstants.tooltipDelay}>
          <Box component="div" sx={{...merge({}, styles(theme).statusBar, style, styles(theme).online)}}>
            <IconButton
              sx={{...styles(theme).pref_add_button}}
              onClick={() => props.SynchroDialogIsOpen()}
              size="large">
              <SyncProblem/>
            </IconButton>
            <Box component="div" sx={{...merge({}, styles(theme).text, (isNotComputer && styles(theme).tabletText))}}>
              {strings.mainMenu.online}</Box>
          </Box>
        </Tooltip>

        {props.valueSynchroDialog &&
        <SynchronizationInstrumentDialog
          id="synchronizationInstrumentDialog"
          open={props.valueSynchroDialog}
          title={strings.dialog.title.syncInstrument}
          text={strings.sync.SynchroAllInstrument}
          refuseLabel={strings.dialog.answer.no}
          onRefuse={() => props.SynchroDialogIsOpen()}
          acceptLabel={strings.dialog.answer.yes}
          onAccept={() => toggleSynchro()}
        />
        }
        {
          showSynchronizationPage &&
          <SynchronizationPage
            redirectFromSynchro={props.redirectFromSynchro}
            showCards={showSynchronizationPage}
            finishSynchro={tweakSynchro}
          />
        }
      </>;
    case ConnectivityCheckerStatus.WEBSERVER_UNAVAILABLE:
      return (
        <Tooltip title={strings.mainMenu.serverOffline} enterDelay={globalConstants.tooltipDelay}>
          <Box component="div" sx={{...merge({}, styles(theme).statusBar, style, styles(theme).unreachable)}}>
            <CloudQueue/>
            <Box component="div"  sx={{...styles(theme).text}}>{strings.mainMenu.serverOffline}</Box>
          </Box>
        </Tooltip>
      );
    case ConnectivityCheckerStatus.OFFLINE:
    default:
      return (
        <Tooltip title={strings.mainMenu.offline} enterDelay={globalConstants.tooltipDelay}>
          <Box component="div" sx={{...merge({}, styles(theme).statusBar, style, styles(theme).offline)}}>
            <Cloud/>
            <Box component="div" sx={{...styles(theme).text}}>{strings.mainMenu.offline}</Box>
          </Box>
        </Tooltip>
      );
  }
};

MainMenuStatusBar.propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
  SynchroDialogIsOpen: PropTypes.func,
  redirectFromSynchro: PropTypes.func,
  valueSynchroDialog: PropTypes.bool,
  networkStatus: PropTypes.number.isRequired,
  instrumentListNeedsUpdate: PropTypes.func,

};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(MainMenuStatusBar);
