import Dexie from 'dexie';

let db = new Dexie('ADMDB');

db.version(1).stores({

  // Used to keep some user properties localy
  usersProperties: '++id,[userLogin+propertyName],value',

  usersInformations: '&userLogin,firstName,lastName,profile,email,phone,mobile,geographicalUnitUser,organizationalUnitUser,financialUnitUser,site,entite,section,refreshTokenCreatedAt,desactivationDate,address,fax,roles,instruments,pwdHash',

  favorites: '++id,[userLogin+instrumentMarquage],userLogin',

  connections: '++id,serverId,connectionHour,deconnectionHour,lastOperation,utilizations',

  instruments: '&marquage,periodiciteProchaineOperationTimestamp,periodiciteProchaineOperationDatetime,id,constructeur,referenceConstructeur,numeroSerie,designation,domaine,affectation,site,section,sousSectionLibelle,destinataire,utilisateur,localisation,planning,etat,periodicitePeriode,periodiciteUnite,periodiciteProchaineOperation,application,periodiciteOperation,options,etatMetrologique,proprietaire,parc,entite,unit,idCris,ancienMarquage,encours,coutJour,emplacementReference,finPrevisionnelleLocation,prochainEvenement,sousFamille,motifEnvoi,dateOperation,rfid',

  instrumentsUpdates: '++id,status,localisation,utilisateur,instruments,sync_timestamp_update,sync_timestamp_create,sync_updated,sync_sent,sync_deleted',

  instrumentNotifications: '&serverId,[userLogin+isDeleted],marquage,instrumentId,userLogin,createdAt,field,oldValue,isDeleted',

  scans: '++id,serverId,date,method,page,analysis,scannedBarcodeType,scannedBarcode,userLogin,[userLogin+analysis]',

  interventions: '++id,inventaire,marquage,resultingStatus,comment, updateInstrumentUser, updateInstrumentLocation',

  inventories: '++id, userLogin, verifiedStatus, marquage, [userLogin+marquage]',

  filters: '&name,[name+userLogin],userLogin'
});

db.version(2).stores({
  messagesScans: '&serverId,title,message,statuses,states',
  messageScanHistory: '++id, messageScan, marquage, createdAt, userLogin'
});

db.version(3).stores({
  guides: '&name, [name+status], status'
});

db.version(4).stores({
  instruments: '&marquage,periodiciteProchaineOperationTimestamp,periodiciteProchaineOperationDatetime,id,constructeur,referenceConstructeur,numeroSerie,designation,domaine,affectation,site,section,sousSectionLibelle,destinataire,utilisateur,localisation,planning,etat,periodicitePeriode,periodiciteUnite,periodiciteProchaineOperation,application,periodiciteOperation,options,etatMetrologique,proprietaire,parc,entite,unit,idCris,ancienMarquage,encours,coutJour,emplacementReference,finPrevisionnelleLocation,prochainEvenement,sousFamille,motifEnvoi,dateOperation,rfid,prestataireID,interventionType',
});

db.version(5).stores({
  instruments: '&marquage,periodiciteProchaineOperationTimestamp,periodiciteProchaineOperationDatetime,id,constructeur,referenceConstructeur,numeroSerie,designation,domaine,affectation,site,section,sousSectionLibelle,destinataire,utilisateur,localisation,planning,etat,periodicitePeriode,periodiciteUnite,periodiciteProchaineOperation,application,periodiciteOperation,options,etatMetrologique,proprietaire,parc,entite,unit,idCris,ancienMarquage,encours,coutJour,emplacementReference,finPrevisionnelleLocation,prochainEvenement,sousFamille,motifEnvoi,dateOperation,rfid,prestataireID,interventionType,identifiant3',
});

db.version(6).stores({
  instruments: '&marquage,periodiciteProchaineOperationTimestamp,periodiciteProchaineOperationDatetime,id,constructeur,referenceConstructeur,numeroSerie,designation,domaine,affectation,site,section,sousSection,destinataire,utilisateur,localisation,planning,etat,periodicitePeriode,periodiciteUnite,periodiciteProchaineOperation,application,periodiciteOperation,options,etatMetrologique,proprietaire,parc,entite,unit,idCris,ancienMarquage,encours,coutJour,emplacementReference,finPrevisionnelleLocation,prochainEvenement,sousFamille,motifEnvoi,dateOperation,rfid,prestataireID,interventionType,identifiant3',
});

db.version(7).stores({
  instruments: '&marquage,periodiciteProchaineOperationTimestamp,periodiciteProchaineOperationDatetime,id,constructeur,referenceConstructeur,numeroSerie,designation,domaine,affectation,site,section,sousSection,destinataire,utilisateur,localisation,planning,etat,periodicitePeriode,periodiciteUnite,periodiciteProchaineOperation,application,periodiciteOperation,options,etatMetrologique,proprietaire,parc,entite,unit,idCris,ancienMarquage,encours,coutJour,emplacementReference,finPrevisionnelleLocation,prochainEvenement,sousFamille,motifEnvoi,dateOperation,rfid,prestataireID,interventionType,identifiant3,lastModificationDate',
});

export default db;
