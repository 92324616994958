import React, { useState } from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Clear, AddCircleOutline } from '@mui/icons-material';

import { styles } from '../ItemTemplate.style';
import { theme } from '../../../../Utils/Theme';

const ToVerifyItemTemplate = props => {
  const [inProgress, setInProgress] = useState(false);
  const variant = props.onMobile ? 'body2' : 'subtitle2';
  return (
    <Box component="div" id="item" sx={{...styles(theme).item}} onClick={() => props.openInstrumentCard(props.item)} style={props.style}>
      <Box component="div" sx={{...styles(theme).leftContainer}}>
        <IconButton
          id="addButton"
          sx={{ "&.MuiIconButton-root": {...styles(theme).actionButton}}}
          onClick={e => {
            e.stopPropagation();
            if (inProgress) {
              return;
            }
            setInProgress(true);
            props.onAdd(props.item);
          }}
          size="large">
          <AddCircleOutline />
        </IconButton>
      </Box>
      <Box component="div" sx={{...styles(theme).middleContainer}}>
        <Typography variant={variant} noWrap>{props.item.designation}</Typography>
        <Typography variant={variant} noWrap>{props.item.referenceConstructeur}</Typography>
        <Typography variant={variant} noWrap>{props.item.marquage}</Typography>
        {!props.onMobile && (
          <Typography variant="subtitle2">{props.item.constructeur}</Typography>)}
      </Box>
      <Box component="div" sx={{...styles(theme).rightContainer}}>
        <IconButton
          id="removeButton"
          sx={{ "&.MuiIconButton-root": {...styles(theme).actionButton}}}
          onClick={e => {
            e.stopPropagation();
            props.onRemove(props.item);
          }}
          size="large">
          <Clear />
        </IconButton>
      </Box>
    </Box>
  );
};

ToVerifyItemTemplate.propTypes = {
  item: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  openInstrumentCard: PropTypes.func,
  style: PropTypes.object,
  onMobile: PropTypes.bool
};

export default ToVerifyItemTemplate;
