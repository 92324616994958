import sharedStyles from '../../../../../../Utils/SharedStyles';

export let styles = theme => ({
  ...sharedStyles(theme),
  instrumentImage: {
    height: "50px",
    width: "50px",
    margin: "0px"
  },
  listItem: {
    padding: "8px",
    width: '73%'
  },
  listItemText: {
    padding: '0px 0px 0px 8px',
    margin: '0px'
  },
  item_typography: {
    fontSize: "14px",
    lineHeight: 1.71,
    color: theme.palette.MainMenu.pageTitles,
    letterSpacing: 'normal'
  },
});