import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  setFilter,
  setStatus,
  toggleFavorites,
  toggleFilterPage,
  toggleSavedFilter,
  unlock
} from '../../store/actions/exportActions';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  SvgIcon,
  Tooltip
} from '@mui/material';
import { Add, AddToPhotos, ArrowBack, Check, DeleteSweep, Help } from '@mui/icons-material';
import { styles } from './FilterCreationDialog.style';
import FilterFieldCard from './FilterFieldCard/FilterFieldCard';
import AddFilterFieldDialog from './AddFilterFieldDialog/AddFilterFieldDialog';
import FilterProvider from '../../Providers/Database/FilterProvider';
import ListFilterField from './FilterTypes/ListFilterField/ListFilterField';
import DateFilterField from './FilterTypes/DateFilterField/DateFilterField';
import ToggleFilterField from './FilterTypes/ToggleFilterField/ToggleFilterField';
import { FILTER_FIELDS, FILTER_TYPES } from '../../Utils/Constants/FilterConstants';
import StringDialog from '../Dialogs/StringDialog/StringDialog';
import FilterableListDialog from '../Dialogs/StringDialog/FilterableListDialog/FilterableListDialog';
import { LocalizedString as strings } from '../../Utils/Constants/LocalizedString';
import SaveFilterDialog from './SaveFilterDialog/SaveFilterDialog';
import { svgConstants } from '../../Utils/Constants/SvgConstants';
import { globalConstants } from '../../Utils/Constants/GlobalConstants';
import { STATUSES } from '../../Utils/Constants/GuideConstants';
import ExternalFilterHelper from '../../Helpers/ExternalFilterHelper';
import { theme } from '../../Utils/Theme';

// maps interventionReducer's state to Component's props
export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
    showFavorites: state.toolbarReducer.showFavorites,
    activeFilter: state.toolbarReducer.activeFilter
  };
};

// maps redux's actions to Component's props
export const mapDispatchToProps = dispatch => {
  return {
    toggleFavorites: showFavorites => dispatch(toggleFavorites(showFavorites)),
    toggleFilterPage: () => dispatch(toggleFilterPage()),
    toggleSavedFilter: showSavedFilters => dispatch(toggleSavedFilter(showSavedFilters)),
    setFilter: filter => dispatch(setFilter(filter)),
    unlock: target => dispatch(unlock(target)),
    setStatus: status => dispatch(setStatus(status))
  };
};

const MAX_ITEMS_IN_LIST = 10;
const DEFAULT_FILTER = () => new FilterProvider({ marquage: [], sousFamille: [], marque: [] });

class FilterCreationDialog extends Component {

  state = {
    addFilterFieldDialogOpen: false,
    currentFilter: DEFAULT_FILTER(),
    showFilterValueDialog: false,
    showSaveFilterDialog: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.defaultFilter && this.props.open && prevProps.open !== this.props.open) {
      this.setState({ currentFilter: new FilterProvider(this.props.defaultFilter) });
    } else {
      if (!FilterProvider.areEquals(this.props.activeFilter, prevProps.activeFilter)) {
        if (this.props.activeFilter) {
          this.setState({ currentFilter: new FilterProvider(this.props.activeFilter.getFilter()) });
        } else {
          this.setState({ currentFilter: DEFAULT_FILTER() });
        }
      }
    }
  }

  /**
   * Callback used when the dialog to add a filter field is closed
   * @param {any} field - field to add to the filter (if null nothing is added)
   */
  onAddFilterFieldDialogClose = field => {

    const newState = { addFilterFieldDialogOpen: false };

    const newFilter = this.state.currentFilter;
    if (field) {
      if (FILTER_FIELDS[field].type === FILTER_TYPES.DATE_RANGE) {
        newFilter.setFilterValue(field, FILTER_FIELDS[field].default);
      } else if (FILTER_FIELDS[field].type === FILTER_TYPES.TOGGLE) {
        newFilter.setFilterValue(field, FILTER_FIELDS[field].default);
      } else {
        newFilter.setFilterValue(field, []);
      }
    }

    newState.currentFilter = newFilter;

    this.setState(newState);
  };

  /**
   * Callback used when a date filter is updated
   * @param {string} field - name of the field affected
   * @param {string} fromOrTo - string containing 'from' or 'to'
   * @param {date} date - new date value
   */
  onDateFilterChange = (field, fromOrTo, date) => {
    const newFilter = this.state.currentFilter;
    const newValue = newFilter.getFilterValue(field);
    newValue[fromOrTo] = date;
    newFilter.setFilterValue(field, newValue);

    this.setState({ currentFilter: newFilter });
  };

  /**
   * Callback used to change the value of a field in the filter
   * @param {string} field - name of the field affected
   * @param {any} value - new value
   */
  onToggleFilterChanged = (field, value) => {
    const newFilter = this.state.currentFilter;
    newFilter.setFilterValue(field, value);

    this.setState({ currentFilter: newFilter });
  };

  /**
   * Callback used to remove a field from the filter
   * @param {string} field - name of the field to remove
   */
  onRemoveFilterField = field => {
    const newFilter = this.state.currentFilter;
    newFilter.removeFilterField(field);

    this.setState({ currentFilter: newFilter });
  };

  /**
   * Callback used to remove a value from the given field
   * @param {string} field - name of the field affected
   * @param {any} value - value to remove
   */
  onRemoveFilterValue = (field, value) => {
    const newFilter = this.state.currentFilter;
    newFilter.removeValue(field, value);

    this.setState({ currentFilter: newFilter });
  };

  /**
   * Callback used to add multiple values to the filter
   * @param {string} field - name of the field affected
   * @param {Array} values - array of values to add
   * @param {boolean} removeAll - remove all values from filter
   */
  addMultipleFilterValue = (field, values, removeAll = true) => {
    const newFilter = this.state.currentFilter;
    if (removeAll) {
      newFilter.setFilterValue(field, []);
    }

    values.forEach(value => {
      newFilter.addValue(field, value);
    });
    this.setState({ showFilterValueDialog: false, currentFilter: newFilter });
  };

  /**
   * Callback used to add a value to the filter
   * @param {string} field - name of the field affected
   * @param {any} value - value to add
   */
  addFilterValue = (field, value) => {
    this.addMultipleFilterValue(field, [value], false);
  };

  /**
   * Callback used when the user click on the add button in a field card
   * It will set the state depending on the type of field
   * @param {string} field : name of the field affected
   */
  onAddFilterValue = field => {
    if (FILTER_FIELDS[field].type === FILTER_TYPES.STRING) {
      this.setState({ showFilterValueDialog: true, dialogType: FILTER_TYPES.STRING, dialogField: field });
    } else {
      this.setState({ showFilterValueDialog: true, dialogType: FILTER_TYPES.LIST, dialogField: field });
    }
  };

  toggleFilterSettings = field => {
    const currentField = FILTER_FIELDS[field];
    if (currentField.type === FILTER_TYPES.STRING) {
      FILTER_FIELDS[field].filterOnEquality = !FILTER_FIELDS[field].filterOnEquality;
    }
    this.forceUpdate();
  };

  /**
   * Return the dialog to use depending on the type of value to add
   */
  getFilterValueDialog = () => {
    if (!this.state.showFilterValueDialog) {
      return null;
    }

    if (this.state.showFilterValueDialog) {
      if (this.state.dialogType === FILTER_TYPES.STRING) {
        return (<StringDialog
          open
          title={strings.filter[this.state.dialogField]}
          text={strings.filter[this.state.dialogField]}
          filter={this.state.dialogField}
          onRefuse={() => this.setState({ showFilterValueDialog: false })}
          onAccept={value => this.addFilterValue(this.state.dialogField, value)}
          refuseLabel={strings.dialog.answer.cancel}
          acceptLabel={strings.dialog.answer.submit}/>);
      } else {
        return (<FilterableListDialog
          open
          title={strings.filter[this.state.dialogField]}
          text={strings.filter.startTyping}
          onRefuse={() => this.setState({ showFilterValueDialog: false })}
          onAccept={value => this.addMultipleFilterValue(this.state.dialogField, value)}
          refuseLabel={strings.dialog.answer.cancel}
          acceptLabel={strings.dialog.answer.submit}
          list={FilterProvider.getAllValuesFor(this.state.dialogField)}
          maxItemsToShow={MAX_ITEMS_IN_LIST}
          selectedValues={this.state.currentFilter.getFilterValue(this.state.dialogField)}
          emptyListMessage={strings.filter.emptyListMessage}/>);
      }
    }
  };

  /**
   * Reset the whole filter
   */
  resetFilter = () => {
    if (ExternalFilterHelper.isExternalFilterSet()) {
      ExternalFilterHelper.removeExternalFilter();
    }
    this.setState({ currentFilter: DEFAULT_FILTER() });
  };

  /**
   * Creates all the cards for the current filter
   */
  generateFilterCards = () => {
    return this.state.currentFilter.getFields().map(field => {
      const currentValues = this.state.currentFilter.getFilterValue(field);
      const type = FILTER_FIELDS[field].type;
      let filterField;

      if (type === FILTER_TYPES.TOGGLE) {
        const values = FILTER_FIELDS[field].values;
        filterField = (
          <ToggleFilterField
            fieldName={field}
            firstValue={values[0]}
            secondValue={values[1]}
            onValueChange={this.onToggleFilterChanged}
            defaultValue={currentValues}/>);
      } else if (type === FILTER_TYPES.DATE_RANGE) {
        filterField = (<DateFilterField
          fieldName={field}
          from={currentValues.from}
          to={currentValues.to}
          onDateChange={this.onDateFilterChange}
        />);
      } else {
        filterField = (<ListFilterField
          fieldName={field}
          filterValues={currentValues}
          onRemoveFilterValue={this.onRemoveFilterValue}
          onAddFilterValue={() => this.onAddFilterValue(field)}
        />);
      }

      return (<FilterFieldCard key={field}
        fieldName={field}
        onRemove={fieldName => this.onRemoveFilterField(fieldName)}
        checked={FILTER_FIELDS[field].filterOnEquality}
        onCheck={() => this.toggleFilterSettings(field)}>
        {filterField}
      </FilterFieldCard>);
    });
  };

  /**
   * Apply the filter and close the dialog
   */
  applyFilter = () => {
    this.state.currentFilter.cleanFilter();
    const filter = this.state.currentFilter.getFilter();

    this.props.setFilter(this.state.currentFilter.isEmpty() ? null : new FilterProvider(filter));
    if (ExternalFilterHelper.isExternalFilterSet()) {
      ExternalFilterHelper.saveExternalFilter(filter);
    }

    this.props.toggleFilterPage();
  };

  onFilterSaved = () => {
    if (this.props.closeOnSave) {
      this.props.toggleFilterPage();
    }
    this.setState({ showSaveFilterDialog: false });
  };

  handleOnClose = () => {
    if (this.props.onCloseOverride) {
      this.props.onCloseOverride();
      this.setState({ currentFilter: DEFAULT_FILTER() });
    } else {
      this.props.toggleFilterPage();
    }
  };

  render() {
    return <>
      <SaveFilterDialog
        id="saveFilterDialog"
        open={this.state.showSaveFilterDialog}
        onClose={() => {
          this.setState({ showSaveFilterDialog: false });
          // In case the user click on close button instead of save the filter
          this.props.unlock('#listDialog');
        }}
        onSave={() => {
          this.onFilterSaved();
          this.props.unlock('#listDialog');
        }}
        filterToSave={this.state.currentFilter}
        defaultSaveName={this.props.defaultSaveName}/>

      <AddFilterFieldDialog
        id="addFilterDialog"
        open={this.state.addFilterFieldDialogOpen}
        onClose={fieldName => {
          this.onAddFilterFieldDialogClose(fieldName);
          this.props.unlock(['#leftFilter-dateProchaineOpe', '#leftFilter-soumisAPlanning']);
        }}
        exceptFilters={this.state.currentFilter.getFields()}/>

      {this.getFilterValueDialog()}

      <Dialog
        fullScreen={this.props.onMobile}
        open={this.props.open}
        sx={{ "& .MuiDialog-paperWidthSm": {...styles(theme).dialog} }}
        keepMounted
        onClose={() => this.handleOnClose()}
        PaperProps={{ id: 'filterCreationDialog' }}
      >
        <DialogTitle
          sx={{...styles(theme).header}}>
          <Box component="div" sx={{...styles(theme).headerContent}}>
            <Box component="div" sx={{...styles(theme).title}}>
              <IconButton id="backButton" onClick={() => this.handleOnClose()} size="large">
                <ArrowBack sx={{ ...styles(theme).whiteColor }}/>
              </IconButton>
              {strings.filter.createFilter}
            </Box>
            <div style={{ display: 'flex' }}>
              <Tooltip title={strings.tooltips.helpInfo} enterDelay={globalConstants.tooltipDelay}>
                <IconButton
                  id="toolbarOpenGuide"
                  onClick={() => this.props.setStatus(STATUSES.INITIATED)}
                  size="large">
                  <Help sx={{ ...styles(theme).whiteColor }}/>
                </IconButton>
              </Tooltip>
              {!this.props.hideLoadFilterButton &&
              <Tooltip title={strings.filter.openSavedFilter} enterDelay={globalConstants.tooltipDelay}>
                <IconButton
                  id="showSavedFiltersButton"
                  onClick={() => this.props.toggleSavedFilter(true)}
                  size="large">
                  <SvgIcon sx={{ ...styles(theme).whiteColor }}>
                    <path d={svgConstants.customFilter}/>
                  </SvgIcon>
                </IconButton>
              </Tooltip>}
              <Tooltip title={strings.filter.saveFilter} enterDelay={globalConstants.tooltipDelay}>
                <IconButton
                  id="showSaveFilterConfirmation"
                  onClick={() => this.setState({ showSaveFilterDialog: true })}
                  size="large">
                  <AddToPhotos sx={{...styles(theme).whiteColor}}/>
                </IconButton>
              </Tooltip>
            </div>
          </Box>

        </DialogTitle>
        <DialogContent>
          <Box component="div" sx={{...styles(theme).filterCards}}>
            {this.generateFilterCards()}
          </Box>
          <Box component="div" sx={{...styles(theme).addFilterContainer}}>
            <Button id="addFilterDialogButton"
              variant="contained"
              color="primary"
              sx={{...styles(theme).addFilterButton}}
              onClick={() => this.setState({ addFilterFieldDialogOpen: true })}>
              <Add style={{ marginRight: "8px" }}/>
              {strings.filter.addFilter}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions sx={{...styles(theme).dialogActionContainer}}>
          <Button id="clearFilterButton"
            variant="contained"
            color="primary"
            sx={{...styles(theme).dialogActionButton}}
            onClick={() => this.resetFilter()}>
            <DeleteSweep style={{ marginRight: "8px" }}/>
            {strings.filter.removeFilter}
          </Button>
          {!this.props.hideApplyButton &&
          <Button id="applyButton"
            variant="contained"
            color="primary"
            sx={{...styles(theme).dialogActionButton}}
            onClick={() => this.applyFilter()}>
            <Check style={{ marginRight: "8px" }}/>
            {strings.filter.applyFilter}
          </Button>}
        </DialogActions>
      </Dialog>
    </>;
  }
}

FilterCreationDialog.propTypes = {
  onMobile: PropTypes.bool,
  toggleSavedFilter: PropTypes.func,
  toggleFilterPage: PropTypes.func,
  setFilter: PropTypes.func,
  open: PropTypes.bool.isRequired,
  activeFilter: PropTypes.instanceOf(FilterProvider),
  hideApplyButton: PropTypes.bool,
  hideLoadFilterButton: PropTypes.bool,
  closeOnSave: PropTypes.bool,
  defaultFilter: PropTypes.object,
  defaultSaveName: PropTypes.string,
  onCloseOverride: PropTypes.func,
  unlock: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps))(FilterCreationDialog);
