import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { Update } from '@mui/icons-material';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import changelogContent from '../../../Assets/CHANGE_LOG.md';

export const mapStateToProps = state => {
  return {
    onMobile: state.generalReducer.onMobile,
  };
};

export class UpdateDialog extends Component {

  state = {
    changelogContent: ''
  };

  componentDidMount() {
    fetch(changelogContent).then((res) => {
      return res.text();
    }).then((text) => {
      const lastUpdateText = text.slice(0, text.indexOf('###', 3));
      this.setState({ changelogContent: lastUpdateText });
    });
  }

  render() {
    return (
      <SimpleDialog
        icon={<Update />}
        open={this.props.open}
        title={this.props.title}

        onAccept={this.props.seeMore}
        acceptLabel={strings.general.seeMore}

        onRefuse={this.props.closeDialog}
        refuseLabel={strings.general.ok}

        fullScreen={this.props.onMobile}
        addContentPadding
        highPriority
      >
        <ReactMarkdown>{this.state.changelogContent}</ReactMarkdown>
      </SimpleDialog>
    );
  }
}

UpdateDialog.propTypes = {
  onMobile: PropTypes.bool.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  seeMore: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(UpdateDialog);
