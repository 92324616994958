export let styles = theme => ({
  content: {
    display: 'flex'
  },
  textAndIcon: {
    marginRight: theme.spacing(),
    marginTop: theme.spacing(3)
  },
  textField: {
    marginRight: theme.spacing(2)
  }
});
