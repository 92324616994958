import React from 'react';
import { Typography, Link, Box } from '@mui/material';
import { Tablet, PhoneAndroid, DesktopWindows, QuestionAnswer } from '@mui/icons-material';
import Divider from '@mui/material/Divider';

import { styles } from './HelpDialogContent.style';
import { LocalizedString as strings } from '../../../Utils/Constants/LocalizedString';
import { theme } from '../../../Utils/Theme';

const HelpDialogContent = props => {
  return (
    <>
      <Typography variant="h6" sx={{...styles(theme).title}}>
        {strings.help.dialogTitle}
      </Typography>
      <Box component="div" sx={{...styles(theme).platformsContainer}}>
        <Box component="div" sx={{...styles(theme).platform}}>
          <DesktopWindows sx={{...styles(theme).platformIcon}} />
          <Typography variant="subtitle2">
            {strings.help.pc}
          </Typography>
        </Box>
        <Box component="div" sx={{...styles(theme).platform}}>
          <Tablet sx={{...styles(theme).platformIcon}} />
          <Typography variant="subtitle2">
            {strings.help.tablet}
          </Typography>
        </Box>
        <Box component="div" sx={{...styles(theme).platform}}>
          <PhoneAndroid sx={{...styles(theme).platformIcon}} />
          <Typography variant="subtitle2">
            {strings.help.smartphone}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{...styles(theme).divider}} />
      <Box component="div" sx={{...styles(theme).bottomTextsContainer}}>
        <Typography variant="body2">
          {strings.help.optimalExperience}
          <Link href={'https://www.microsoft.com/fr-fr/edge/welcome'}>{strings.help.edge}</Link>
          {strings.help.or} <Link href={'https://www.google.com/intl/fr_fr/chrome/'}>{strings.help.chrome}</Link>
        </Typography>
        <Box component="div" sx={{...styles(theme).textsWithIconsContainer}}>
          <Typography variant="body1" sx={{...styles(theme).textWithIcon}}>
            <QuestionAnswer sx={{...styles(theme).textIcon}} />
            {strings.help.textComments}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default HelpDialogContent;
